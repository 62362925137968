import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { getLocalStorageItem } from '../utility/helper';
import { HEADER_KEYS } from '../utility/constants';

axios.interceptors.request.use(
  (axiosRequestConfig: AxiosRequestConfig) => {
    if (axiosRequestConfig.headers) {
      axiosRequestConfig.headers[HEADER_KEYS.X_USER_ID] = getLocalStorageItem('uid');
    }
  }
)

const requests = (axiosPromises: AxiosPromise[]) => {
  return axios.all(axiosPromises)
};

export default requests;