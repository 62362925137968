import * as Yup from 'yup';

const userSchema = Yup.object({
  users : Yup.array(
    Yup.object({
      firstName: Yup.string().required('First Name is required').trim(),
      lastName: Yup.string().required('Last Name is required').trim(),
      email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
      role: Yup.string().required('Role is required.').trim()
    })
  )
});

export default userSchema;
