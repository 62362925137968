import { Container } from '@mui/material';

const BorrowingBase = () => {
  return (
    <Container maxWidth="xl">
      <h3>Borrowing Base</h3>
    </Container>
  )
}

export default BorrowingBase;