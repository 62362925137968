import { Box, Paper, InputAdornment, Tooltip, Typography, LinearProgress, TextField, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from "@mui/material"
import DraggableTable from "../../../../pages/customer-setting/draggable-table"
import styles from "../styles"
import SearchIcon from '@mui/icons-material/Search';
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { IModalProps } from "../../../../interfaces/parentChildSetupInterface";
import { DraggableListItem } from "../../../../interfaces/draggableList";
import { IOption } from "../../../../interfaces/comboBox";
import { EditVendorCustomerSetupContext } from "../../../../context/EditVendorCustomerSetupProvider";
import { IEditVendorCustomerSetupContext } from "../../../../interfaces/editVendorCustomerSetupProvider";
import { IARVendor } from "../../../../interfaces";
import { SelectedClientContext } from "../../../../context/selectedClientContext";
import { IClearModalProps } from "../..";
import SearchParentChild from "../../search-parent-child";
import request from "../../../../service/request";
import { arCollateralAPI, arVendorsAPI } from "../../../../service/api";
import { GET } from "../../../../utility/constants";

const filters = ['All', 'New'];
interface IProps {
  selectedARCollateral: IOption | null
  borrowerId?: string
  modalProps: IModalProps
  setClearModal: React.Dispatch<React.SetStateAction<IClearModalProps>>
  setModalProps: React.Dispatch<React.SetStateAction<IModalProps>>
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
}

interface IParams {
  arCollateralId?: number;
  borrowerId?: number;
  vendorName?: string;
  isNew?: boolean;
  pageNo?: number;
  pageSize?: number;
  sortBy?: string;
}

const UnlinkedVendorSection = ({selectedARCollateral, borrowerId, modalProps, setClearModal, setModalProps, setIsDragging}: IProps) => {
  const selectedARCollateralId                                = selectedARCollateral?.recordId ?? 0;
  
  const {unlinkedVendorsList,
         setUnlinkedVendorsList,
         parentsWithChildrenList,
         orphansPage,
         setOrphansPage,
         apAmountMap,
       } = useContext(EditVendorCustomerSetupContext) as IEditVendorCustomerSetupContext;

  const { selectedClient }                                            = useContext(SelectedClientContext);
  const orphanObserver                                                = useRef<any>();
  const [isSelectedAll, setIsSelectedAll]                             = useState<boolean>(false);
  const [displayUnlinkedVendors, setDisplayUnlinkedVendors]           = useState<DraggableListItem[]>([]);
  const [filteredUnlinkedVendorsList, setFilteredUnlinkedVendorsList] = useState<DraggableListItem[]>([]);
 

  // HOOKS

  /**
   * This useEffect triggers when pagination was changed.
   */
  useEffect(() => {
    getOrphanDisplay();
  }, [unlinkedVendorsList])

  /**
   * This function is used to filter parent customers from orphan section
   */
  useEffect(() => {
    if(parentsWithChildrenList.length > 0){
     const temp = unlinkedVendorsList.filter(cust => parentsWithChildrenList.some(o => o.custSrcId !== cust.srcId))
     setUnlinkedVendorsList(temp)
    }

  }, [parentsWithChildrenList])

  /**
   * This function is used to fetch Orphans from paginated API
   */

  useEffect(() => {
    // check if AP Amounts has been loaded
    if (apAmountMap.size === 0) { return; }
    handleFetchOrphans();
  },[orphansPage.pageNo, orphansPage.searchKey, orphansPage.searchFilter, apAmountMap]);

  const handleFetchOrphans = async () => {
    const fetchedOrphans = await request({
      url: selectedClient?.parentClient ? arVendorsAPI.FIND_UNLINKED_BY_PARENT_BORROWER_ID : arVendorsAPI.FIND_UNLINKED_BY_AR_COLLATERAL_ID,
      method: GET,
      params: unlinkedVendorRequestParams(
        selectedClient?.parentClient,
        {
          vendorName: orphansPage.searchKey || undefined,
          isNew: orphansPage.searchFilter === 'New' ? true : undefined,
          pageNo: orphansPage.pageNo,
          pageSize: 10,
          sortBy: 'vendorName, ASC',
        }
      ),
    });

    const nextPageOrphans: DraggableListItem[] = fetchedOrphans.data.content.map((orphan) => ({
      recordId: orphan.recordId as number,
      value: (orphan.recordId as number).toString(),
      name: orphan.vendorName as string,
      srcId: orphan.vendorSrcId,
      checked: false,
      disabled: false,
      listName: 'orphans',
      isVendor: true,
      amount: apAmountMap[orphan.recordId],
      isNew: orphan.isNew,
    }));
    
    const displayList = [
      ...unlinkedVendorsList,
      ...nextPageOrphans,
    ]
    setUnlinkedVendorsList(displayList);
    setOrphansPage(prevState => ({...prevState, isLastPage: fetchedOrphans.data.last, isLoading: false }));
  }

  const unlinkedVendorRequestParams = (isParentClient: boolean | undefined, params: IParams) => {
    if (isParentClient) {
      params.borrowerId = selectedClient?.recordId;
    }
    else {
      params.arCollateralId = selectedARCollateralId;
    }
    
    return params;
  }

  const handleSearchBar = () => {
    setUnlinkedVendorsList([]);
  }

  // NON API FUNCTIONS

  /**
   * This function set all displayed orphans state to checked.
   */
  const handleSelectAll = async () => {
    setOrphansPage(prevState => ({...prevState, isLoading: true }));

    let newList: DraggableListItem[] = [];

    if (orphansPage.isLastPage) {
      newList = unlinkedVendorsList.map((vendor) => ({
        ...vendor,
        checked: !isSelectedAll,
      }))
    }
    else {
      const allVendorsRes = await request({
        url: selectedClient?.parentClient ? arVendorsAPI.FIND_UNLINKED_BY_PARENT_BORROWER_ID : arVendorsAPI.FIND_UNLINKED_BY_AR_COLLATERAL_ID,
        method: GET,
        params: unlinkedVendorRequestParams(
          selectedClient?.parentClient,
          {
            vendorName: orphansPage.searchKey || undefined,
            isNew: orphansPage.searchFilter === 'New' ? true : undefined,
            pageNo: orphansPage.pageNo,
            pageSize: 9999,
            sortBy: 'vendorName, ASC',
          }
        ),
      });
      newList = allVendorsRes.data.content.map((orphan) => ({
        recordId: orphan.recordId as number,
        value: (orphan.recordId as number).toString(),
        name: orphan.vendorName as string,
        srcId: orphan.vendorSrcId,
        checked: !isSelectedAll,
        disabled: false,
        listName: 'orphans',
        isVendor: true,
        amount: apAmountMap[orphan.recordId],
        isNew: orphan.isNew,
      }));
    }

    setUnlinkedVendorsList(newList);
    setFilteredUnlinkedVendorsList(newList);
    setOrphansPage({...orphansPage, isLastPage: true, isLoading: false });
    setIsSelectedAll(!isSelectedAll);
  }

  /**
   * This function set a specicic orphan state to checked.
   * 
   * @param updatedList The updated list of AR Customer formatted as DraggableListItem
   */
  const handleOnCheck = (updatedList: DraggableListItem[]) => {
    const newList = unlinkedVendorsList.map((originalObj) => {
      const updatedObj = updatedList.find((updatedObj) => updatedObj.recordId === originalObj.recordId);
      if (updatedObj) {
        return updatedObj;
      } else {
        return originalObj;
      }
    });

    setUnlinkedVendorsList(newList)
    setIsSelectedAll(
      unlinkedVendorsList.every(orphan => {
        return orphan.checked
      }))
  }


  //EVENT LISTENER - FOR INFINITE SCROLL

  /**
   * This useCallback is used to hanlde infinite scrolling on orphan section.
   */
  const lastRowOrphanElementRef = useCallback((node: any) => {
    if (orphansPage.isLoading) return
    if (orphanObserver.current) orphanObserver.current.disconnect()
    
    orphanObserver.current = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting && (!orphansPage.isLastPage)) {
        setOrphansPage((prevState) => ({
          ...prevState, 
          isLoading: true,
          pageNo: prevState.pageNo+1
        }))
      }
    })

    if (node) orphanObserver.current.observe(node)
  }, [])

  /**
   * This function filtered the orphan list to be displayed base on the pagination props.
   */
  const getOrphanDisplay = async () => {
    setDisplayUnlinkedVendors(unlinkedVendorsList);
    setFilteredUnlinkedVendorsList(unlinkedVendorsList);
  }

  /**
   * This function check if the dragged AR Customer from parent section is the last child.
   * 
   * @param parentId The ID of the parent where the dragged AR Customers came from.
   * @param tempId The temporarary ID of the Parent where the dragged AR Customers came from.
   * @returns A boolean that determine if the last dragged AR Customer is/are last.
   */
  const checkIfLast = (parentId: number, tempId?: number): boolean => {
    const parent = parentsWithChildrenList.find(parent => (isNaN(parentId) || parent.id === parentId) && parent.tempId === tempId)
    if(parent){
      return parent.children.length < 2
    }
    return false
  }

  return(
    <>
      <Box component={Paper} sx={styles.sectionBox}>
        <Box sx={styles.clearButtonBox}>
          <Link 
            component={'button'} 
            sx={styles.clearButton}
            onClick={() => {
              setClearModal({
                isOpen: true,
                type: 'vendor',
              });
            }}
          >
            Clear All New Vendors
          </Link>
        </Box>
        <Box sx={{ marginBottom: '1rem' }}>
          <SearchParentChild
            filters={filters}
            onChange={value => {
              handleSearchBar();
              setOrphansPage(prevState => ({
                ...prevState,
                pageNo: 0,
                searchKey: value,
                isLoading: true
              }));
            }}
            onSelect={selected => {
              handleSearchBar();
              setOrphansPage(prevState => ({
                ...prevState,
                pageNo: 0,
                searchFilter: selected,
                isLoading: true
              }));
            }}
          />
        </Box>
        <TableContainer sx={styles.unlinkedTableContainer}>
          <Table stickyHeader>
            <TableHead sx={styles.unlinkedTableHead}>
              <TableRow sx={styles.unlinkedTableHeaderRow}>
                <TableCell sx={styles.unlinkedTableSelectColumn}>
                  <Box sx={{ display: 'flex' }}>
                    <Box width={'28px'}/>
                    <Tooltip title='Select All'>
                      <Checkbox
                        id='checkbox'
                        data-testid='select-all-checkbox'
                        aria-label='Checkbox'
                        aria-labelledby='checkbox'
                        edge="end"
                        inputProps={{'aria-label':'Checkbox', 'aria-labelledby':'checkbox'}}
                        checked={isSelectedAll}
                        disableRipple
                        onClick={() => handleSelectAll()}
                        sx={{ px: 0 }}
                      />
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell sx={styles.unlinkedTableNameCell}>
                  <Typography tabIndex={0} sx={{...styles.unlinkedTableHeaderText, textAlign: 'left' }}>
                    Vendor Name
                  </Typography>
                </TableCell>
                <TableCell sx={styles.unlinkedTableHeaderCell}>
                  <Typography tabIndex={0} sx={{...styles.unlinkedTableHeaderText, textAlign: 'right' }}>
                    AP Amount
                  </Typography>
                </TableCell>
                <TableCell sx={styles.unlinkedTableHeaderDateCreated}>
                  <Typography tabIndex={0} sx={{...styles.unlinkedTableHeaderText, textAlign: 'right' }}>
                    Date Created
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={styles.tableSpacer}></TableRow>
            </TableBody>
            {
              <DraggableTable
                list={displayUnlinkedVendors}
                fullList={filteredUnlinkedVendorsList}
                multiple={true}
                name="orphans"
                selectedAll={isSelectedAll}
                onItemChecked={(list) => handleOnCheck(list)}
                onItemDrop={(list, from, to) => {
                    setIsDragging(false)
                    setModalProps({
                      ...modalProps,
                      isLastChild: checkIfLast(Number(from), modalProps.tempId),
                      isParent: false,
                      isOpen: true,
                      children: list,
                      parentId: Number(from),
                      from: Number(from),
                      to: Number(to)
                    })
                }
              }
                onItemDragOver={(list) => {}}
                onItemDragStart={() => setIsDragging(true)}
              > 
              {(!orphansPage.isLastPage && displayUnlinkedVendors.length > 0)?
                <LinearProgress ref={lastRowOrphanElementRef}/> : <></>
              }
              </DraggableTable>
            }
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

export default UnlinkedVendorSection

