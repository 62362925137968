import { FC } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, SxProps, Theme } from '@mui/material';
import styles from './styles';
import { generateRandomString } from '../../../utility/helper';

export interface IColumn {
  field       : string;
  headerName? : string;
}

interface ITableWithLeftPinnedHeaderProps {
  columns           : Array<IColumn>;
  rows              : Array<any>;
  styles?: {
    tableContainer? : SxProps<Theme>;
    table?          : SxProps<Theme>;
    tableHead?      : SxProps<Theme>;
    tableBody?      : SxProps<Theme>;
    tableRow?       : SxProps<Theme>;
    tableCell?      : SxProps<Theme>;
    tableHeadCell?  : SxProps<Theme>;
  }
  placeholder?      : string | number;
}

const TableWithLeftPinnedHeader: FC<ITableWithLeftPinnedHeaderProps> = (props) => {
  const getStyles = (defaultStyles: SxProps<Theme>, propStyles: SxProps<Theme> | undefined) => {
    if (propStyles === undefined) { return defaultStyles; }
    const mergedStyles = { ...defaultStyles, ...propStyles};
    return mergedStyles;
  };

  const getPlaceholder = () => {
    if (props.placeholder) { return props.placeholder }
    else { return '-' }
  };

  return (
    <TableContainer sx={getStyles(styles.tableContainer, props?.styles?.tableContainer)}>
      <Table sx={getStyles(styles.table, props?.styles?.table)}>
        <TableHead sx={getStyles(styles.tableHead, props?.styles?.tableHead)}>
          <TableRow sx={getStyles(styles.tableRow, props?.styles?.tableRow)}>
          {props.columns.map(column => (
            <TableCell tabIndex={0} key={column.headerName} sx={getStyles(styles.tableHeadCell, props?.styles?.tableHeadCell)}>
              {column.headerName ?? column.field}
            </TableCell>
          ))}
          </TableRow>
        </TableHead>
        <TableBody sx={getStyles(styles.tableBody, props?.styles?.tableBody)}>
        {props.rows.map(row => (
          <TableRow key={generateRandomString(10)} sx={getStyles(styles.tableRow, props?.styles?.tableRow)}>
          {props.columns.map(column => (
            <TableCell tabIndex={0} key={generateRandomString(10)} sx={getStyles(styles.tableCell, props?.styles?.tableCell)}>
              {row[column.field] === undefined ? getPlaceholder() : row[column.field]}
            </TableCell>
          ))}
          </TableRow>
        ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
};
export default TableWithLeftPinnedHeader;
