const styles = {
    pageNotFoundContainer: { 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
    },
    p5: {
        padding: 5
    },
    bold: {
        fontWeight: 700
    }
};

export default styles;