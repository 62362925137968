import { FC, useMemo } from 'react'
import { ICapDetails, IClientCapDetails, IClientWithCustIneligibles, IClientWithIneligibles, IClientWithRfv, IGrandTotal, IHeader, IIneligibleCapDetails } from '..';
import UpcIneligibleView from './ineligible-view';
import { IIneligibleDetailsProps } from '../body';
import UpcCustView from './cust-view';
import { Box, Typography } from '@mui/material';
import { formatCurrency } from '../../../../utility/helper';
import styles from './styles';
import { IBorrowerCustReport, IBorrowerReport, IGrandTotals } from '../../../../interfaces/arIneligibleReport';
import { IRfCalcSummary } from '../../../../interfaces/rollForwardReportInterface';

export interface IClientReportsProps extends IIneligibleDetailsProps {
  bbPeriodLabel         : string;
  collateralRatesMap    : {
    [key: number]: number;
  },
  rfvDisabled           : boolean;
  borrowerReport?       : IBorrowerReport[];
  grandTotals?          : IGrandTotals[];
  rfSummaries?          : IRfCalcSummary[];
  reportCodes?          : IHeader[];
  isChildCustomerOnly?  : boolean;
  endDate?              : string;
  borrowerCustReport?   : IBorrowerCustReport[];
}

const ClientReports: FC<IClientReportsProps> = (props) => {
  const { viewBy, grandTotals } = props;

  const totalIneligibleAmount = grandTotals?.reduce((acc: number, grandTotal: IGrandTotals) => {
    if (grandTotal.row === 'GRAND TOTAL') return acc + grandTotal.ineligibleAmount;
    return acc;
  }, 0.0);

  return (
    <>
    <Box sx={styles.grandTotalAmountContainer}>
      <Typography tabIndex={0} sx={styles.grandTotalAmountText} component='label' htmlFor='totalAmount'>
        Total Ineligible Amount
      </Typography>
      <Typography tabIndex={0} sx={styles.grandTotalAmount} component='label' id='totalAmount'>
        {formatCurrency(totalIneligibleAmount ?? 0.0, props.currencyCode)}
      </Typography>
    </Box>
    {viewBy === 'Ineligible' ?
      <UpcIneligibleView {...props} /> :
      <UpcCustView {...props} />}
    </>
  )
}

export default ClientReports;