import { Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { ICurrency, IRate } from "../../../../../interfaces/multiCurrencyInterface";
import styles from "./styles";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { APAgingReportContext } from "../../../../../context/apAgingReportContext";
import { IAPAgingReportContext, IAPAgingTableHeadCell, ISortOrder } from "../../../../../interfaces/apAgingReportInterface";
import { NON_EXISTING } from "../../../../../utility/constants";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { visuallyHidden } from '@mui/utils';
import APAgingSearchModal from "./search-modal";
import APAgingReportTableRow from "./row";
import { convertNumberBasedOnRate, formatNumberBasedOnCurrency, formatNumberBasedOnRate, isSelectedClientUPC } from "..";
import { IAccountsReceivableSetting } from "../../../../../components/client-settings/tabs/tab-panel-contents/accounts-receivable";
import { SelectedClientContext } from "../../../../../context/selectedClientContext";

export interface IAPAgingReportTableProps {
  selectedExchangeRate: IRate | null;
  arSetting?: IAccountsReceivableSetting;
};

const fixedAPAgingTableHeadCells: IAPAgingTableHeadCell[] = [
  { id: 'arVendorSrcId', label: 'Vendor Id', numeric: false },
  { id: 'arVendorName', label: 'Vendor Name', numeric: false },
  { id: 'total', label: 'Total', numeric: true },
];

const APAgingReportTable: React.FC<IAPAgingReportTableProps> = (props) => {
  const { selectedExchangeRate, arSetting  } = props;
  const { selectedClient } = useContext(SelectedClientContext);
  const {
    selectedCollateral, selectedAsOfDate, selectedType, selectedCurrency,
    buckets, reports, sortParams, setSortParams, filterText, setFilterText,
    isLoading, hasGeneratedReport
  }  = useContext(APAgingReportContext) as IAPAgingReportContext;

  const [scrolledRowIndex, setScrolledRowIndex] = useState(0);
  const NUMBER_OF_ROWS_PER_SCROLL = 20;

  const tableHeadCells = useMemo(() => {
    const bucketHeadCells: IAPAgingTableHeadCell[] = buckets.map(bucket => ({ id: bucket, label: bucket, numeric: true }));
    const headCells: IAPAgingTableHeadCell[] = [ ...fixedAPAgingTableHeadCells, ...bucketHeadCells ];
    return headCells;
  }, [buckets]);

  const filteredReports = useMemo(() => {
    const currentReports = isSelectedClientUPC(selectedClient) && arSetting !== undefined
      ? reports.filter(report =>
          (arSetting.arCollateral.recordId === report.arCollateralId) && (arSetting.arCollateral.borrowerId === report.borrowerId)
        )
      : reports;
    if (filterText === '') { return currentReports; }
    return currentReports.filter(report => {
      const toSearch = sortParams.orderBy === 'arVendorSrcId' ? report.arVendorSrcId : report.arVendorName;
      return toSearch.toLowerCase().includes(filterText.toLowerCase());
    });
  }, [selectedClient, arSetting, sortParams, filterText, reports]);

  const totalAmount = useMemo(() => filteredReports.reduce((totalAmount, currentReport) => totalAmount + currentReport.total, 0), [filteredReports]);

  const hasMoreRowsToRender = useMemo(() => {
    const lengthOfRenderedRows = reports.slice(0, scrolledRowIndex * NUMBER_OF_ROWS_PER_SCROLL + NUMBER_OF_ROWS_PER_SCROLL).length;
    return reports.length > lengthOfRenderedRows;
  }, [reports, scrolledRowIndex]);

  const observer = useRef<any>();
  const lastRowElementRef = useCallback((node: any) => {
    if (isLoading) return;
    if (observer.current) { observer.current.disconnect(); }
    observer.current = new IntersectionObserver((entries) => (entries[0].isIntersecting && hasMoreRowsToRender) && setScrolledRowIndex((prevValue) => prevValue + 1))
    if (node) observer.current.observe(node);
  }, [isLoading, hasMoreRowsToRender]);

  const nullIcon = useCallback(() => null, []);
  const unfoldMoreIcon = useCallback(() => <UnfoldMoreIcon sx={styles.defaultSortIcon}/>, []);

  const sortTable = (property: string) => {
    if (['arVendorSrcId', 'arVendorName'].includes(property) ) {
      const isAscending = sortParams.orderBy === property && sortParams.order === 'asc';
      setSortParams({ orderBy: property, order: isAscending ? 'desc' : 'asc' });
      return;
    }
    const isDescending = sortParams.orderBy === property && sortParams.order === 'desc';
    setSortParams({ orderBy: property, order: isDescending ? 'asc' : 'desc' });
  };

  const getTotalAmountPerBucket = useCallback((bucket: string) =>
    filteredReports.reduce((totalAmount, currentReport) => {
      if (currentReport.calcAgingBucket[bucket] === undefined) { return totalAmount; }
      const totalAmountByRate = convertNumberBasedOnRate(currentReport.calcAgingBucket[bucket], selectedExchangeRate);
      return totalAmount + totalAmountByRate;
    }, 0),
    [filteredReports, selectedExchangeRate]
  );

  const getTableHead = () => {
    const getStylesForTableHeaderCell = (index: number) => {
      const headerCellStyles = {
        vendorId: { ...styles.tableHeaderText, ...styles.widthVendorId },
        vendorName: { ...styles.tableHeaderText, ...styles.widthVendorName },
        default: { ...styles.tableHeaderText, ...styles.tableTextHeaderNumeric, ...styles.widthBuckets }
      };
      if (index === 0) { return headerCellStyles.vendorId; }
      if (index === 1) { return headerCellStyles.vendorName; }
      return headerCellStyles.default;
    };

    const getHeaderCellTooltip = (orderBy: string, sortOrder: ISortOrder, headCell: IAPAgingTableHeadCell) => {
      const SORT_ASCENDING_DESCRIPTION = 'Sort rows by this header in ascending order';
      const SORT_DESCENDING_DESCRIPTION = 'Sort rows by this header in descending order';
      const isCellActive = orderBy === headCell.id;
      const isCellNumeric = !['arVendorSrcId', 'arVendorName'].includes(headCell.id);
      if (isCellActive) { return sortOrder === 'asc' ? SORT_DESCENDING_DESCRIPTION : SORT_ASCENDING_DESCRIPTION; }
      return isCellNumeric ? SORT_DESCENDING_DESCRIPTION : SORT_ASCENDING_DESCRIPTION;
    };

    const getBucketLabel = (label: string) => {
      const position = label.search('Over');
      if (position === NON_EXISTING) { return `${label} Days`; }
      const regexResult = RegExp(/(\d+)/).exec(label);
      const numberPart = parseInt(regexResult ? regexResult[0] : '', 10);
      return `${numberPart + 1} and Over`;
    };

    const getIconComponent = (orderBy: string, columnId: string) => {
      if (columnId === 'arVendorName' || columnId === 'arVendorSrcId') { return { IconComponent: nullIcon }; }
      if (orderBy === columnId) { return; }
      return { IconComponent: unfoldMoreIcon };
    };

    const getTableCellContent = (tableHeadCell: IAPAgingTableHeadCell) => {
      return (
        <Tooltip title={ getHeaderCellTooltip(sortParams.orderBy, sortParams.order, tableHeadCell) }>
          <TableSortLabel
            active={ sortParams.orderBy === tableHeadCell.id }
            aria-label={ `${getBucketLabel(tableHeadCell.label)} sort` }
            direction={ sortParams.orderBy === tableHeadCell.id ? sortParams.order : 'asc' }
            hideSortIcon={ ['arVendorSrcId', 'arVendorName'].includes(tableHeadCell.id) }
            onClick={() => sortTable(tableHeadCell.id)}
            { ...getIconComponent(sortParams.orderBy, tableHeadCell.id) }
          >
            { fixedAPAgingTableHeadCells.some(headCell => headCell.id === tableHeadCell.id) ? tableHeadCell.label : getBucketLabel(tableHeadCell.label) }
            {
              sortParams.orderBy === tableHeadCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  { sortParams.order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                </Box>
              ) : null
            }
          </TableSortLabel>
        </Tooltip>
      );
    };

    const getSearchModal = (tableHeadCell: IAPAgingTableHeadCell) => {
      if (!['arVendorSrcId', 'arVendorName'].includes(tableHeadCell.id)) { return; }
      return (
        <APAgingSearchModal
          tableHeadCell={tableHeadCell}
          onSearch={(filterText) => {
            setFilterText(filterText);
            setSortParams({ orderBy: tableHeadCell.id, order: 'asc' });
          }}
        />
      );
    };

    return (
      <TableHead sx={styles.tableHeader}>
        <TableRow>
          <TableCell aria-label='Action Button Column' component='td' sx={{ ...styles.tableHeaderText, ...styles.widthActionButton }}></TableCell>
          {
            tableHeadCells.map((tableHeadCell, index) =>
              <TableCell
                key={tableHeadCell.id}
                sortDirection={sortParams.orderBy === tableHeadCell.id ? sortParams.order : false}
                sx={ getStylesForTableHeaderCell(index) }
              >
                { getTableCellContent(tableHeadCell) }
                { getSearchModal(tableHeadCell) }
              </TableCell>
            )
          }
        </TableRow>
      </TableHead>
    );
  };

  const getTableBody = () => {
    const getSortedAndFilteredReports = () => {
      if (['arVendorSrcId', 'arVendorName'].includes(sortParams.orderBy)) {
        return filteredReports.sort((a, b) => {
          const valueAToSort = sortParams.orderBy === 'arVendorSrcId' ? a.arVendorSrcId : a.arVendorName;
          const valueBToSort = sortParams.orderBy === 'arVendorSrcId' ? b.arVendorSrcId : b.arVendorName;
          if (valueAToSort < valueBToSort) { return sortParams.order === 'asc' ? -1 : 1; }
          if (valueAToSort > valueBToSort) { return sortParams.order === 'asc' ? 1 : -1; }
          return 0;
        });
      }
      if (sortParams.orderBy === 'total') { return filteredReports.sort((a, b) => sortParams.order === 'asc' ? a.total - b.total : b.total - a.total); }
      return filteredReports.sort((a, b) => {
        const valueAToSort = a.calcAgingBucket[sortParams.orderBy] ?? -Infinity;
        const valueBToSort = b.calcAgingBucket[sortParams.orderBy]  ?? -Infinity;
        return sortParams.order === 'asc' ? valueAToSort - valueBToSort : valueBToSort - valueAToSort;
      });
    };
    return (
      <TableBody>
        {
          getSortedAndFilteredReports()
            .slice(0, scrolledRowIndex * NUMBER_OF_ROWS_PER_SCROLL + NUMBER_OF_ROWS_PER_SCROLL)
            .map(report => <APAgingReportTableRow row={report} lastRowElementRef={lastRowElementRef} selectedExchangeRate={selectedExchangeRate} />)
        }
      </TableBody>
    );
  };

  const getTableFooter = () => {
    if (filterText !== '') { return <></>; }

    const formattedTotalAmount = formatNumberBasedOnRate(totalAmount, selectedCurrency as ICurrency, selectedExchangeRate);
    return (
      <TableFooter>
        <TableRow>
          <TableCell sx={{ ...styles.tableFooterText, ...styles.widthActionButton }}></TableCell>
          <TableCell tabIndex={0} sx={{ ...styles.tableFooterText, ...styles.widthVendorId }}>{ isSelectedClientUPC(selectedClient) ? 'Total' : 'Grand Total' }</TableCell>
          <TableCell sx={{...styles.tableFooterText, ...styles.widthVendorName }}></TableCell>
          <TableCell tabIndex={0} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }} align='right'>
            { formattedTotalAmount !== 0 ? formattedTotalAmount : <></> }
          </TableCell>
          {
            buckets.map(bucket => {
              const formattedBucketTotal = formatNumberBasedOnCurrency(getTotalAmountPerBucket(bucket), selectedCurrency as ICurrency);
              return (
                <TableCell tabIndex={0} align='right' key={bucket} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }}>
                  { formattedBucketTotal !== 0 ? formattedBucketTotal : <></> }
                </TableCell>
              );
            })
          }
        </TableRow>
      </TableFooter>
    );
  };

  const getTableContainer = () => {
    const isHidden = !hasGeneratedReport || isLoading || (
      isSelectedClientUPC(selectedClient)
        ? [selectedAsOfDate, selectedType, selectedCurrency].some(field => field === null)
        : [selectedCollateral, selectedAsOfDate, selectedType, selectedCurrency].some(field => field === null)
    );
    if (isHidden) { return <></>; }
    return (
      <TableContainer sx={styles.tableContainer}>
        <Table stickyHeader size='small'>
          { getTableHead() }
          { getTableBody() }
          { getTableFooter() }
        </Table>
      </TableContainer>
    );
  };

  return getTableContainer();
};

export default APAgingReportTable;
