const styles = {
  labelText: {
    fontSize: '14px',
    paddingTop: '15px',
    paddingBottom: '5px'
  },
  inputField: {
    '& .MuiFormHelperText-root.Mui-error': {
        marginLeft:0,
    },
    '& .MuiOutlinedInput-notchedOutline' : {
        borderColor: '#D4DCFF',
        color: 'black'
    },
    '&&&&& .MuiInputBase-root' : {
        backgroundColor: '#F9FAFF',
    },
    '&&&&& .MuiOutlinedInput-input' : {
        backgroundColor: '#F9FAFF',
        WebkitBoxShadow: "0 0 0 1000px #F9FAFF inset",
        height: '17px'
    },
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '30px',
    marginTop: '15px',
  },
  loginButton: {
    fontSize: '14px',
    fontWeight: 'normal',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
  disabledLoginButton:{
    cursor: 'default',
    backgroundColor: '#99BFED',
    '&:hover': {
      backgroundColor: '#99BFED',
    },
  },
  disabledLink: {
    textDecoration: 'none',
    cursor: 'default'
  }
};

export default styles;