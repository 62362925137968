import React, { useContext, useEffect, useRef, useState } from 'react';
import { Autocomplete, AutocompleteRenderInputParams, Box, TextField } from '@mui/material';
import { NOT_APPLICABLE, NON_EXISTING } from '../../../utility/constants';
import { ComboBoxProps, IIneligibleSettingsContext } from '../../../interfaces/ineligibleSettingInterface';
import { getDisplayedError } from '../ineligible-setting-details';
import styles from './styles';
import { IneligibleSettingsContext } from '../../../context/ineligibleSettingsContext';

/**
 * Component for handling numeric input with validation.
 * @param props The props for the ComboBox component.
 * @returns The ComboBox component JSX.
 */
const ComboBox: React.FC<ComboBoxProps> = (props) => {
  const { formik, options, filteredOptions, name, label, value, disabled } = props;
  const { hasPaymentTerms } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const inputRef = useRef<HTMLInputElement>(null);

  const numberFormatter = new Intl.NumberFormat('en-US');

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  /**
   * This effect updates input value based on value
   */
  useEffect(() => {
    if (value === NON_EXISTING ) { setInputValue(''); return; }
    if (value === NOT_APPLICABLE) { setInputValue('NA'); return; }
    setInputValue(value.toString());
  }, [value]);

  /**
   * This function handles the change event when the input value changes.
   * @param value The new value of the input.
   */
  const handleChange = (value: string) => {
    const isNumericRegex = /^[1-9]\d*$/;
    if (value === 'NA' && filteredOptions.includes(options[options.length - 1])) { // 2nd condition is to test pasted NA
      formik.setFieldValue(name, 0);
      formik.setFieldError(name, undefined);
      return;
    }
    if (value === '' || value === '0' || (!isNumericRegex.test(value))) { // 2nd and 3rd is to test pasted values
      formik.setFieldValue(name, NON_EXISTING);
      formik.setFieldError(name, `${label} is required`);
      return;
    }
    if (!hasPaymentTerms && label === 'DPID') { /* when dpid is changed, and there is no payment terms, dpdd should auto select to NA */
      const dpddFieldName = name.replace('dpid', 'dpdd');
      formik.setFieldValue(dpddFieldName, NOT_APPLICABLE);
      formik.setFieldError(dpddFieldName, undefined);
    }
    formik.setFieldValue(name, parseInt(value));
    formik.setFieldError(name, undefined);
  };

  /**
   * This function handles the key down event for input.
   * @param event The keyboard event.
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const allowedKeys = ['Backspace', 'Enter', 'ControlLeft', 'ControlRight', 'ShiftLeft', 'ShiftRight','Tab'];
    if (allowedKeys.includes(event.key) || event.ctrlKey) { return; }
    const isNumericRegex = /\d/;
    if (!isNumericRegex.test(event.key)) { event.preventDefault(); return; }
    if (value <= 0 && event.key === '0') { event.preventDefault(); }
  };

  /**
   * This function gets the rendered input element for Autocomplete.
   * @param params Autocomplete input parameters.
   * @returns The rendered input element JSX.
   */
  const getRenderedInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      inputRef={inputRef}
      id={`${name}`}
      label={label}
      name={`${name}`}
      inputProps={{ ...params.inputProps, 'data-testid': `rule-field-for-${label}` }}
      onKeyDown={(event) => handleKeyDown(event)}
      onChange={(event) => handleChange(event.target.value)}
      {...getDisplayedError(formik, name)}
    />
  );

  /**
   * This function gets the rendered option element for Autocomplete.
   * @param prop HTML attributes for the option element.
   * @param option The option value.
   * @returns The rendered option element JSX.
   */
  const getRenderedOption = (prop: React.HTMLAttributes<HTMLLIElement>, option: string) =>  (
    <Box
      component='li'
      {...prop}
      key={option}
      onClick={() => { handleChange(option); inputRef.current?.blur(); }}>
      {option}
    </Box>
  );

  return (
    <Autocomplete
      blurOnSelect
      disablePortal
      disableClearable // disable clearable because of weird behavior with dirty state
      options={filteredOptions}
      size='small'
      fullWidth
      freeSolo
      sx={styles.comboBox}
      value={inputValue}
      open={isOpen}
      disabled={Boolean(disabled)}
      onFocus={() => {
        setIsOpen(true);
        if (inputValue === '' || inputValue === 'NA') { return; }
        const inputValueWithoutCommas = inputValue.replace(/,/g, '');
        setInputValue(inputValueWithoutCommas);
      }}
      onBlur={(_event) => {
        setIsOpen(false);
        if (inputValue === '' || inputValue === 'NA') { return; }
        const commaSeparatedInputValue = numberFormatter.format(value);
        setInputValue(commaSeparatedInputValue);
      }}
      renderInput={params => getRenderedInput(params)}
      renderOption={(prop, option) => getRenderedOption(prop, option)}
    />
  )
};

export default ComboBox;
