import React, { useContext } from 'react';
import GeneralBreadcrumbs from '../../breadcrumb';
import { IneligibleAdjustmentsContext as IAContext, IIneligibleAdjustmentsContext as IAContextInterface } from '../../../context/ineligibleAdjustmentsContext';

const IneligibleAdjustmentsBreadcrumbs: React.FC = () => {
  const { selectedClient } = useContext(IAContext) as IAContextInterface;
  return (
    <GeneralBreadcrumbs
      selectedText='Ineligible Adjustments'
      breadcrumbLinks={[
        { linkText: 'Clients', route: '/clients' },
        { linkText: 'Client Settings', route: `/clients/${selectedClient.recordId}/settings` },
      ]}
    />
  );
};

export default IneligibleAdjustmentsBreadcrumbs;
