import axios, { AxiosRequestConfig } from 'axios';
import { getLocalStorageItem } from '../utility/helper';
import { HEADER_KEYS } from '../utility/constants';

const axiosInstance = axios.create({
  baseURL: `${(window as any).BASE_URL}`
});

axiosInstance.interceptors.request.use(
  (axiosRequestConfig: AxiosRequestConfig) => {
    /** This is commented out because it causes errors with the Keycloak-related api calls
    const token = getLocalStorageItem('token');

    if (token) {
      axiosRequestConfig.headers!.Authorization = BEARER + token;
    }

    **/

    if (axiosRequestConfig.headers) {
      axiosRequestConfig.headers[HEADER_KEYS.X_USER_ID] = getLocalStorageItem('uid');
    }

    return axiosRequestConfig;
  },
  (error) => {
    console.error('error : ', error.response);

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  }, 
  (error) => {
    console.error('error : ', error.response);

    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/expired';
    }
    
    return Promise.reject(error);
  }
);

export default axiosInstance;