import { useState, createContext, useMemo } from 'react';
import { IClientInfo, IInventory, IInventoryWorksheet, IInventoryWorksheetContext } from '../interfaces/inventoryWorksheetInterface';
import { LayoutProps } from './authContext';

export const InventoryWorksheetContext = createContext<IInventoryWorksheetContext | null>(null);

const InventoryWorksheetProvider = (props: LayoutProps) => {
  const { children }                                                      = props;
  const [clients, setClients]                                             = useState<IClientInfo[]>([]);
  const [selectedClient, setSelectedClient]                               = useState<IClientInfo>({ recordId: -1, borrowerName: 'Loading...' });
  const [selectedInventory, setSelectedInventory]                         = useState<IInventory>({ recordId: -1, invCollateralFk: -1, invCollateralName: 'Loading...' });
  const [lastSelectedClient, setLastSelectedClient]                       = useState<IClientInfo | null>(null);  // used for storing the last selected client for confirm navigation modal
  const [totalInventoryWorksheetAmount, setTotalInventoryWorksheetAmount] = useState(0);
  const [showPrompt, setShowPrompt]                                       = useState(false);
  const [inventoryWorksheets, setInventoryWorksheets]                     = useState<IInventoryWorksheet[]>([]);
  const [isLoading, setIsLoading]                                         = useState(false);
  const [canViewReport, setCanViewReport]                                 = useState<boolean>(false);
  const [canViewInventoryWorksheet, setCanViewInventoryWorksheet]         = useState<boolean>(false);
  const [canAddInventoryWorksheets, setCanAddInventoryWorksheets]         = useState<boolean>(false);
  const [canUpdateInventoryWorksheets, setCanUpdateInventoryWorksheets]   = useState<boolean>(false);
  const [canDeleteInventoryWorksheets, setCanDeleteInventoryWorksheets]   = useState<boolean>(false);

  const value = useMemo(() => ({
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    selectedInventory,
    setSelectedInventory,
    lastSelectedClient,
    setLastSelectedClient,
    totalInventoryWorksheetAmount,
    setTotalInventoryWorksheetAmount,
    showPrompt,
    setShowPrompt,
    isLoading,
    setIsLoading,
    inventoryWorksheets,
    setInventoryWorksheets,
    canViewReport,
    setCanViewReport,
    canViewInventoryWorksheet,
    setCanViewInventoryWorksheet,
    canAddInventoryWorksheets,
    setCanAddInventoryWorksheets,
    canUpdateInventoryWorksheets,
    setCanUpdateInventoryWorksheets,
    canDeleteInventoryWorksheets,
    setCanDeleteInventoryWorksheets
  }), [
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    selectedInventory,
    setSelectedInventory,
    lastSelectedClient,
    setLastSelectedClient,
    totalInventoryWorksheetAmount,
    setTotalInventoryWorksheetAmount,
    showPrompt,
    setShowPrompt,
    isLoading,
    setIsLoading,
    inventoryWorksheets,
    setInventoryWorksheets,
    canViewReport,
    setCanViewReport,
    canViewInventoryWorksheet,
    setCanViewInventoryWorksheet,
    canAddInventoryWorksheets,
    setCanAddInventoryWorksheets,
    canUpdateInventoryWorksheets,
    setCanUpdateInventoryWorksheets,
    canDeleteInventoryWorksheets,
    setCanDeleteInventoryWorksheets
  ])

  return (
    <InventoryWorksheetContext.Provider value={value}>
      {children}
    </InventoryWorksheetContext.Provider>
  );

};

export default InventoryWorksheetProvider;
