import React, { createContext, Dispatch, useEffect, useMemo, useReducer } from "react";
import { setLocalStorageItem } from "../utility/helper";
import FileImportProcessReducer, { FileImportProcessState, initialState } from "../reducer/fileImportProcessReducer";
import { FileImportProcess } from "../actions/fileImportProcessActions";

export interface FileImportProcessContextInterface {
  state     : FileImportProcessState;
  dispatch  : Dispatch<FileImportProcess>;
}

export const FileImportProcessContext = createContext<FileImportProcessContextInterface>({
  state     : initialState,
  dispatch  : () => undefined
});

export interface LayoutProps  { 
  children: React.ReactNode;
}

const FileImportProcessContextProvider = (props: LayoutProps) => {
  const children = props.children;

  const [state, dispatch] = useReducer(FileImportProcessReducer, initialState);

  useEffect(() => {
    if (state.show) {
      setLocalStorageItem('fileImportProcessLoader', JSON.stringify(state));
    } else {
      localStorage.removeItem('fileImportProcessLoader');
    }
  }, [state.show]);

  const value = useMemo(() => ({state, dispatch}), [state, dispatch]);

  return (
    <FileImportProcessContext.Provider value={value}>
      {
        children
      }
    </FileImportProcessContext.Provider>
  )
}

export default FileImportProcessContextProvider;