const styles = {
  container: {
    position: 'fixed',
    bottom: '3vw',
    right: '2vw',
    zIndex: '9999',
    maxWidth: '30rem'
  },
}

export default styles;