const styles = {
  reportHeaderContainer: {
    paddingBottom: '16px',
  },
  reportHeaderSubContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  reportHeader: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    color: '#212529',
    marginTop: '21px',
    marginBottom: '21px',
    padding: '0.625rem',
    textAlign: 'center',
  },
  reportTitleSubText: {
    fontSize: '1rem',
    textAlign: 'center'
  },
  reportTitleText: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  exchangeRateText: {
    font: '14px'
  },
  hidden: {
    display: 'none'
  },
};

export default styles;
