import { useContext, useState } from 'react';
import { Field } from 'formik';
import { Box, InputAdornment, TextField } from '@mui/material';
import { NON_EXISTING } from '../../../../../utility/constants';
import { IIneligibleRuleOverride, IIneligibleSettingsContext } from '../../../../../interfaces/ineligibleSettingInterface';
import { IneligibleSettingsContext } from '../../../../../context/ineligibleSettingsContext';
import { DecimalFormat, getDisplayedError } from '../../../ineligible-setting-details';
import { OverrideSettingsProps } from '../..';
import styles from '../../styles';

/**
 * Component responsible for rendering and handling the Credit Limit settings for an override.
 * @param props The props for the component.
 * @returns The rendered CreditLimitSettings component.
 */
const CreditLimitSettings = ({idx, formik}: OverrideSettingsProps) => {
  const { selectedIneligibleIndex: ineligibleIndex } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const [isSelected, setIsSelected] = useState(false);

  /**
   * This function gets the selected credit limit for the current override.
   * @returns The selected credit limit or NON_EXISTING if not set.
   */
  const getSelectedIneligibleRuleOverrideCreditLimit = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRuleOverrides as IIneligibleRuleOverride[])[idx]?.creditLimit ?? NON_EXISTING;

  /**
   * This function gets the Formik field name for the credit limit.
   * @returns The Formik field name for the credit limit.
   */
  const getFormikFieldNameForIneligibleRuleOverrideCreditLimit = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].creditLimit`;

  /**
   * This function gets the input adornment based on whether the field is selected or the value is non-existing.
   * @returns The input adornment (dollar sign) or null.
   */
  const getInputAdornment = () => {
    if (isSelected) { return null; }
    if (getSelectedIneligibleRuleOverrideCreditLimit() === NON_EXISTING) {
      return <InputAdornment position='end'>$</InputAdornment>;
    }
    return null;
  };

  /**
   * This function handles the change of the credit limit value.
   * @param event The input change or focus event.
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      formik.setFieldValue(getFormikFieldNameForIneligibleRuleOverrideCreditLimit(), NON_EXISTING);
      formik.setFieldError(getFormikFieldNameForIneligibleRuleOverrideCreditLimit(), `Credit Limit is required`);
      return;
    }
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleOverrideCreditLimit(), parseFloat(event.target.value));
    formik.setFieldError(getFormikFieldNameForIneligibleRuleOverrideCreditLimit(), undefined);
  };

  return (
    <Box sx={styles.specialSettingsContainer}>
      <Field
        as={TextField}
        label='Amount'
        id={`ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].creditLimit`}
        name={`ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].creditLimit`}
        value={getSelectedIneligibleRuleOverrideCreditLimit() === NON_EXISTING ? '' : getSelectedIneligibleRuleOverrideCreditLimit()}
        size='small'
        type='number'
        sx={{ ...styles.textField, ...styles.numberAlignment }}
        fullWidth
        InputProps={{ endAdornment: getInputAdornment(), inputComponent: DecimalFormat as any }}
        InputLabelProps={{ sx: styles.textFieldInputLabel }}
        onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => { setIsSelected(false); }}
        onFocus={(_event: React.FocusEvent<HTMLInputElement>) => setIsSelected(true)}
        {...getDisplayedError(formik, getFormikFieldNameForIneligibleRuleOverrideCreditLimit())}
      />
    </Box>
  );
}

export default CreditLimitSettings;
