import { Autocomplete, Button, Grid, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import styles from "./styles";
import DisabledComponentsContainer from "../../../../../components/common/disabled-components-container";
import { useContext, useEffect, useMemo, useState } from "react";
import { SelectedClientContext } from "../../../../../context/selectedClientContext";
import { ARAgingReportContext } from "../../../../../context/arAgingReportContext";
import { IARCollateral, IBBPeriod, IClient } from "../../../../../interfaces";
import { IARAgingReportContext, IBucketType } from "../../../../../interfaces/arAgingReportInterface";
import { useSearchParams } from "react-router-dom";
import { calculateARAging, calculateUPCARAging, checkHasDueDates, checkHasDueDatesForUPC, getARAgingBuckets, getARAgingReport, getARSettings, getAsOfDates, getExchangeRates, getUPCARAgingBuckets, getUPCARAgingReport, getUPCARSettings, getUPCAsOfDates } from "../../../../../api/reports/ar-aging";
import { checkUserPermissions, formatDate, getCurrencies, getUpcToCurrencyIds } from "../../../../../utility/helper";
import { AMERICAN_DATE_FORMAT, PERMISSIONS } from "../../../../../utility/constants";
import { IAccountsReceivableSetting } from "../../../../../components/client-settings/tabs/tab-panel-contents/accounts-receivable";
import { ICurrency, ICurrencyWithClientCurrency } from "../../../../../interfaces/multiCurrencyInterface";
import _ from "lodash";
import { AuthContext } from "../../../../../context/authContext";
import { isSelectedClientUPC } from "..";

export interface IARAgingFieldsContainerProps {
  childClients: IClient[];
}

const ARAgingFieldsContainer: React.FC<IARAgingFieldsContainerProps> = (props)  => {
  const { childClients } = props;
  const { state } = useContext(AuthContext);
  const { selectedClient } = useContext(SelectedClientContext);
  const {
    selectedCollateral, setSelectedCollateral,
    selectedAsOfDate, setSelectedAsOfDate,
    selectedType, setSelectedType,
    selectedCurrency, setSelectedCurrency,
    arCollaterals, setARCollaterals,
    asOfDates, setAsOfDates,
    bucketTypes, setBucketTypes,
    currencies, setCurrencies,
    exchangeRates, setExchangeRates,
    setBuckets, setReports,
    isLoading, setIsLoading,
    hasGeneratedReport, setHasGeneratedReport,
    setShowNoExchangeRate,
    setPermissions,
  }  = useContext(ARAgingReportContext) as IARAgingReportContext;

  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const belowSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedARCollateralToRender = useMemo(() => {
    if (selectedCollateral === null) { return null; }
    return arCollaterals.find(arCollateral => arCollateral.arCollateral.recordId === selectedCollateral.recordId) ?? null;
  }, [arCollaterals, selectedCollateral?.recordId]);

  const BUCKET_TYPES = ['Invoice Date', 'Due Date'] as IBucketType[];
  const NON_EXISTING = '-1';

  const PARAM_FOR_CLIENT_ID = searchParams.get('clientId') ?? NON_EXISTING;
  const PARAM_FOR_AR_COLLATERAL_ID = searchParams.get('arCollateralId') ?? NON_EXISTING;
  const PARAM_FOR_BB_PERIOD_ID = searchParams.get('bbPeriodId') ?? NON_EXISTING;
  const PARAM_FOR_END_DATE = searchParams.get('endDate') ?? NON_EXISTING;
  const PARAM_FOR_CURRENCY_ID = searchParams.get('currencyId') ?? NON_EXISTING;

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    loadPermissions();
  }, [state]);

  useEffect(() => {
    if (selectedClient?.recordId === undefined || selectedClient.parentClient) { setSelectedCollateral(null); setARCollaterals([]); }
    if (selectedClient?.recordId === undefined) {
      updateSearchParams({ key: 'clientId', value: NON_EXISTING, toDelete: true });
      updateSearchParams({ key: 'arCollateralId', value: NON_EXISTING, toDelete: true });
      setSelectedAsOfDate(null); setAsOfDates([]);
      return;
    }
    updateSearchParams({ key: 'clientId', value: (selectedClient as Required<IClient>).recordId.toString() });
    isSelectedClientUPC(selectedClient) ? loadUPCARCollateralsAndAsOfDates() : loadARCollaterals();
    loadExchangeRates();
  }, [selectedClient?.recordId]); /* using entire selectedClient as dependency causes double calls */

  useEffect(() => {
    setHasGeneratedReport(false);
    selectedCollateral === null
      ? updateSearchParams({ key: 'arCollateralId', value: NON_EXISTING, toDelete: true })
      : updateSearchParams({ key: 'arCollateralId', value: (selectedCollateral as IARCollateral).recordId.toString() });
    if (isSelectedClientUPC(selectedClient)) { return; }
    if (selectedCollateral === null) { setSelectedAsOfDate(null); setAsOfDates([]); return; }
    loadARAsOfDates();
  }, [selectedCollateral]);

  useEffect(() => {
    setHasGeneratedReport(false);
    if (selectedAsOfDate === null) {
      updateSearchParams({ key: 'bbPeriodId', value:  NON_EXISTING, toDelete: true });
      updateSearchParams({ key: 'endDate', value: NON_EXISTING, toDelete: true });
      setSelectedCurrency(null); setCurrencies([]);
      setSelectedType(null); setBucketTypes([]);
      return;
    }
    isSelectedClientUPC(selectedClient)
      ? updateSearchParams({ key: 'bbPeriodId', value:  NON_EXISTING, toDelete: true })
      : updateSearchParams({ key: 'bbPeriodId', value:  (selectedAsOfDate as Required<IBBPeriod>).recordId.toString()});
    updateSearchParams({ key: 'endDate', value: (selectedAsOfDate as Required<IBBPeriod>).endDate.replaceAll('-', '')});
    isSelectedClientUPC(selectedClient) ? loadUPCCurrencies() : loadARCurrencies();
    isSelectedClientUPC(selectedClient) && loadUPCARCollaterals(selectedAsOfDate);
    loadBucketTypes();
    !isSelectedClientUPC(selectedClient) && setShowNoExchangeRate(!hasExchangeRatesOnAsOfDate(selectedAsOfDate));
  }, [selectedAsOfDate]);

  useEffect(() => {
    setHasGeneratedReport(false);
    if (selectedCurrency === null) {
      updateSearchParams({ key: 'currencyId', value: NON_EXISTING, toDelete: true });
      return;
    }
    updateSearchParams({ key: 'currencyId', value: selectedCurrency.recordId.toString() });
  }, [selectedCurrency]);

  useEffect(() => {
    setHasGeneratedReport(false);
  }, [selectedType]);

  useEffect(() => {
    if (selectedAsOfDate === null || !isSelectedClientUPC(selectedClient)) { return; }
    setShowNoExchangeRate(!hasExchangeRatesOnAsOfDate(selectedAsOfDate));
  }, [arCollaterals]);

  useEffect(() => {
    if (!isFirstLoad) { return; }
    const isFieldsSelected = isSelectedClientUPC(selectedClient)
      ? [selectedClient, selectedAsOfDate, selectedType, selectedCurrency].every(field => field !== null)
      : [selectedClient, selectedAsOfDate, selectedCollateral, selectedType, selectedCurrency].every(field => field !== null);
    if (!isFieldsSelected || arCollaterals.length === 0) { return; }
    const hasIncompleteRates = !hasExchangeRatesOnAsOfDate(selectedAsOfDate as IBBPeriod);
    if (hasIncompleteRates) { setShowNoExchangeRate(true); setIsFirstLoad(false); return; }
    generateReport();
  }, [isFirstLoad, hasGeneratedReport, arCollaterals, selectedClient, selectedAsOfDate, selectedCollateral, selectedType, selectedCurrency]);

  const loadPermissions = async () => {
    const [canViewARAgingReport, canExportARAgingReport] = await Promise.all([
      checkUserPermissions(state.uid, PERMISSIONS.VIEW_AR_AGING_REPORT),
      checkUserPermissions(state.uid, PERMISSIONS.EXPORT_AR_AGING_REPORT),
    ]);
    setPermissions({
      viewARAgingReport: canViewARAgingReport ?? false,
      exportARAgingReport: canExportARAgingReport ?? false,
    });
  };

  const loadARCollaterals = async () => {
    const nonNullSelectedClient = selectedClient as Required<IClient>;
    const loadedARSettings = await getARSettings(nonNullSelectedClient);
    setARCollaterals(loadedARSettings);
    if (loadedARSettings.length === 0) { setSelectedCollateral(null); return; }

    if (PARAM_FOR_AR_COLLATERAL_ID !== NON_EXISTING) {
      const collateralIndex = loadedARSettings.findIndex(setting => setting.arCollateral.recordId === parseInt(PARAM_FOR_AR_COLLATERAL_ID));
      const collateral = loadedARSettings[collateralIndex] ? loadedARSettings[collateralIndex].arCollateral : null;
      setSelectedCollateral(collateral);
      return;
    }
    const setting = loadedARSettings.find(setting => setting.arCollateral.default) ?? loadedARSettings[0];
    const collateral = setting ? setting.arCollateral : null;
    setSelectedCollateral(collateral);
  };

  const loadUPCARCollateralsAndAsOfDates = async () => {
    const selectedAsOfDate = await loadUPCAsOfDates();
    selectedAsOfDate !== null && loadUPCARCollaterals(selectedAsOfDate);
  };

  const loadUPCARCollaterals = async (selectedUPCAsOfDate: IBBPeriod) => {
    const nonNullSelectedClient = selectedClient as Required<IClient>;
    const defaultChildClient = childClients.find(childClient => childClient.default);
    if (defaultChildClient === undefined) { setARCollaterals([]); return; }
    const [loadedARSettings, usedARCurrencyIDsFromTransactions] = await Promise.all([
      getUPCARSettings(nonNullSelectedClient, defaultChildClient as IClient),
      getUpcToCurrencyIds(nonNullSelectedClient.recordId, selectedUPCAsOfDate.endDate)
    ]);
    const arSettingsWithTransactions = usedARCurrencyIDsFromTransactions !== undefined
      ? loadedARSettings.filter(arSetting => usedARCurrencyIDsFromTransactions.includes(parseInt(arSetting.arBorrowerInput.currency)))
      : [];
    setARCollaterals(arSettingsWithTransactions);
  };

  const loadARAsOfDates = async () => {
    const loadedAsOfDates = await getAsOfDates(selectedClient as Required<IClient>, selectedCollateral as IARCollateral);
    setAsOfDates(loadedAsOfDates);
    if (loadedAsOfDates.length === 0) { setSelectedAsOfDate(null); return; }
    if (PARAM_FOR_BB_PERIOD_ID !== NON_EXISTING) {
      const asOfDateIndex = loadedAsOfDates.findIndex(asOfDate => asOfDate.recordId === parseInt(PARAM_FOR_BB_PERIOD_ID));
      setSelectedAsOfDate(loadedAsOfDates[asOfDateIndex] ?? loadedAsOfDates[0]);
      return;
    }
    const asOfDate = loadedAsOfDates[0];
    setSelectedAsOfDate(asOfDate);
  };

  const loadUPCAsOfDates = async () => {
    const loadedAsOfDates = await getUPCAsOfDates(selectedClient as Required<IClient>);
    setAsOfDates(loadedAsOfDates);
    if (loadedAsOfDates.length === 0) { setSelectedAsOfDate(null); return null; }
    if (PARAM_FOR_END_DATE !== NON_EXISTING) {
      const asOfDateIndex = loadedAsOfDates.findIndex(asOfDate => asOfDate.endDate === PARAM_FOR_END_DATE);
      const asOfDate = loadedAsOfDates[asOfDateIndex] ?? loadedAsOfDates[0];
      setSelectedAsOfDate(asOfDate);
      return asOfDate;
    }
    const asOfDate = loadedAsOfDates[0];
    setSelectedAsOfDate(asOfDate);
    return asOfDate;
  };

  const loadBucketTypes = async () => {
    const hasDueDates = await (
      isSelectedClientUPC(selectedClient)
        ? checkHasDueDatesForUPC(selectedClient as IClient, selectedAsOfDate as IBBPeriod)
        : checkHasDueDates(selectedCollateral as IARCollateral, selectedAsOfDate as IBBPeriod)
    );
    const loadedTypes = [ BUCKET_TYPES[0] ];
    hasDueDates && loadedTypes.push(BUCKET_TYPES[1]);
    setBucketTypes(loadedTypes);
    setSelectedType(BUCKET_TYPES[0]);
  };

  const loadARCurrencies = async () => {
    const loadedCurrencies = await getCurrencies() as ICurrencyWithClientCurrency;
    const nonNullSelectedClient = selectedClient as Required<IClient>;
    const nonNullSelectedARCollateral = arCollaterals.find(arCollateral => arCollateral.arCollateral.recordId === (selectedCollateral as Required<IARCollateral>).recordId) as IAccountsReceivableSetting;
    const nonNullSelectedAsOfDate = selectedAsOfDate as IBBPeriod;

    const borrowerCurrencyID = nonNullSelectedClient.currencyId;
    const collateralCurrencyID = nonNullSelectedARCollateral.arBorrowerInput.currency;
    const availableCurrencies = loadedCurrencies.currencies.filter(currency => {
      if (currency.recordId.toString() === borrowerCurrencyID) {
        setSelectedCurrency(currency);
        return true;
      }
      if (currency.recordId.toString() === collateralCurrencyID) {
        return exchangeRates.some(exchangeRate => {
          const asOfDateFromExchangeRate = formatDate(exchangeRate.asOfDate as string, AMERICAN_DATE_FORMAT);
          const formattedAsOfDate = formatDate(nonNullSelectedAsOfDate.endDate, AMERICAN_DATE_FORMAT);
          return exchangeRate.toCurrencyId.toString() === collateralCurrencyID &&
          asOfDateFromExchangeRate === formattedAsOfDate;
        });
      }
      return false;
    });
    setCurrencies(availableCurrencies);
  };

  const loadUPCCurrencies = async () => {
    const loadedCurrencies = await getCurrencies() as ICurrencyWithClientCurrency;
    const nonNullSelectedClient = selectedClient as Required<IClient>;
    const borrowerCurrencyID = nonNullSelectedClient.currencyId;
    const availableCurrencies = loadedCurrencies.currencies.filter(currency => {
      if (currency.recordId.toString() === borrowerCurrencyID) {
        setSelectedCurrency(currency);
        return true;
      }
      return false;
    });
    setCurrencies(availableCurrencies);
  };

  const loadExchangeRates = async () => {
    const nonNullSelectedClient = selectedClient as Required<IClient>;
    const loadedExchangeRates = await getExchangeRates(nonNullSelectedClient);
    setExchangeRates(loadedExchangeRates);
  };

  const generateReport = async () => {
    setIsFirstLoad(false);
    setIsLoading(true);
    try {
      const nonNullSelectedClient = selectedClient as Required<IClient>;
      const nonNullSelectedCollateral = selectedCollateral as IARCollateral;
      const nonNullSelectedAsOfDate = selectedAsOfDate as IBBPeriod;
      const nonNullSelectedType = _.camelCase(selectedType as IBucketType);
      await (
        isSelectedClientUPC(selectedClient)
        ? calculateUPCARAging(nonNullSelectedClient, nonNullSelectedAsOfDate)
        : calculateARAging(nonNullSelectedClient, nonNullSelectedCollateral, nonNullSelectedAsOfDate)
      );
      const [loadedBuckets, loadedReports] = await (
        isSelectedClientUPC(selectedClient)
        ? Promise.all([
          getUPCARAgingBuckets(nonNullSelectedClient, nonNullSelectedAsOfDate, nonNullSelectedType),
          getUPCARAgingReport(nonNullSelectedClient, nonNullSelectedAsOfDate, nonNullSelectedType, 'arCustomerName,ASC'),
        ])
        : Promise.all([
          getARAgingBuckets(nonNullSelectedClient, nonNullSelectedCollateral, nonNullSelectedAsOfDate, nonNullSelectedType),
          getARAgingReport(nonNullSelectedClient, nonNullSelectedCollateral, nonNullSelectedAsOfDate, nonNullSelectedType, 'arCustomerName,ASC'),
        ])
      );
      setBuckets(loadedBuckets);
      setReports(loadedReports);
    } catch (error) {
      console.log('CALCULATE AR AGING REPORT ERROR: ', error);
    } finally {
      setHasGeneratedReport(true);
      setIsLoading(false);
    }
  };

  const hasExchangeRatesOnAsOfDate = (asOfDate: IBBPeriod) => {
    if (selectedClient === null) { return true; }
    return arCollaterals.every(arSetting => {
      const isCurrencySimilarToClient = selectedClient.currencyId === arSetting.arBorrowerInput.currency;
      if (isCurrencySimilarToClient) { return true; }
      const exchangeRate = exchangeRates.find(exchangeRate =>
        exchangeRate.toCurrencyId.toString() === arSetting.arBorrowerInput.currency &&
        formatDate(exchangeRate.asOfDate as string, AMERICAN_DATE_FORMAT) === formatDate(asOfDate.endDate, AMERICAN_DATE_FORMAT)
      );
      const hasExchangeRate = exchangeRate !== undefined
      return hasExchangeRate;
    });
  };

  const updateSearchParams = (params: { key: string, value: string, toDelete?: boolean }) => {
    if (isFirstLoad) { return; }
    if (searchParams.has(params.key) && params.toDelete) {
      searchParams.delete(params.key);
      setSearchParams(searchParams);
      return;
    }
    searchParams.set(params.key, params.value);
    setSearchParams(searchParams);
  };

  const getGridForARCollateral = () => {
    return (
      <Grid item xs={12} lg={2.5}>
        <Typography
          component='label'
          htmlFor='ar-collateral-field'
          sx={styles.fieldLabel}
          tabIndex={0}
          variant='h6'
        >
          AR Collateral
        </Typography>
        <Autocomplete
          aria-label='AR Collateral Dropdown'
          blurOnSelect
          componentsProps={{
            clearIndicator:{ 'aria-label': 'Clear icon', tabIndex: 0 },
            popupIndicator: { 'aria-label': 'Dropdown icon',tabIndex: 0 },
          }}
          disabled={selectedClient === null || isLoading || isSelectedClientUPC(selectedClient)}
          disablePortal
          id='ar-collateral-field'
          getOptionLabel={setting => setting.arCollateral.arCollateralName}
          onChange={(_event, newValue: IAccountsReceivableSetting | null) => {
            setSelectedCollateral(newValue !== null ? newValue.arCollateral : null);
          }}
          options={arCollaterals}
          renderInput={(params) => (
            isSelectedClientUPC(selectedClient)
              ? (
                <Tooltip title='Cannot select a Collateral on the Parent Client Level'>
                  <span>
                    <TextField 
                      {...params}
                      placeholder='All'
                    />
                  </span>
                </Tooltip>
              )
              : <TextField {...params} placeholder={'Please Select'} />
          )}
          size='small'
          sx={styles.field}
          value={selectedARCollateralToRender}
        />
      </Grid>
    );
  };

  const getGridForAsOfDate = () => {
    return (
      <Grid item xs={12} lg={2.5}>
        <Typography
          component='label'
          htmlFor='as-of-date-field'
          sx={styles.fieldLabel}
          tabIndex={0}
          variant='h6'
        >
          As of Date
        </Typography>
        <Autocomplete
          aria-label='As of Date Dropdown'
          blurOnSelect
          componentsProps={{
            clearIndicator:{ 'aria-label': 'Clear icon', tabIndex: 0 },
            popupIndicator: { 'aria-label': 'Dropdown icon',tabIndex: 0 },
          }}
          disabled={(!isSelectedClientUPC(selectedClient) && selectedCollateral === null)  || isLoading}
          disablePortal
          getOptionLabel={(asOfDates) => formatDate(asOfDates.endDate, AMERICAN_DATE_FORMAT)}
          id='as-of-date-field'
          onChange={(_event, newValue: IBBPeriod | null) => {
            setSelectedAsOfDate(newValue);
          }}
          options={asOfDates}
          renderInput={(params) => <TextField {...params} placeholder={'Please Select'} /> }
          size='small'
          sx={styles.field}
          value={selectedAsOfDate}
        />
      </Grid>
    );
  };

  const getGridForType = () => {
    return (
      <Grid item xs={12} lg={2.5}>
        <Typography
          component='label'
          htmlFor='type-field'
          sx={styles.fieldLabel}
          tabIndex={0}
          variant='h6'
        >
          Type
        </Typography>
        <Autocomplete
          aria-label='Type Dropdown'
          blurOnSelect
          componentsProps={{
            clearIndicator:{ 'aria-label': 'Clear icon', tabIndex: 0 },
            popupIndicator: { 'aria-label': 'Dropdown icon',tabIndex: 0 },
          }}
          disabled={selectedAsOfDate === null || isLoading}
          disablePortal
          id='type-field'
          onChange={(_event, newValue: IBucketType | null) => setSelectedType(newValue)}
          options={bucketTypes}
          renderInput={(params) => <TextField {...params} placeholder={'Please Select'} /> }
          size='small'
          sx={styles.field}
          value={selectedType}
        />
      </Grid>
    );
  };

  const getGridForCurrency = () => {
    return (
      <Grid item xs={12} lg={2.5}>
        <Typography
          component='label'
          htmlFor='currency-field'
          tabIndex={0}
          variant='h6'
          sx={styles.fieldLabel}
        >
          Currency
        </Typography>
        <Autocomplete
          aria-label='Currency Dropdown'
          blurOnSelect
          componentsProps={{
            clearIndicator:{ 'aria-label': 'Clear icon', tabIndex: 0 },
            popupIndicator: { 'aria-label': 'Dropdown icon',tabIndex: 0 },
          }}
          disabled={selectedAsOfDate === null || isLoading || isSelectedClientUPC(selectedClient)}
          disablePortal
          getOptionLabel={currency => `${currency.currencyCode} - ${currency.currencyName}`}
          id='currency-field'
          onChange={(_event, newValue: ICurrency | null) => {
            setSelectedCurrency(newValue);
          }}
          options={currencies}
          renderInput={(params) => <TextField {...params} placeholder={'Please Select'} /> }
          size='small'
          sx={styles.field}
          value={selectedCurrency}
        />
      </Grid>
    );
  };

  const getGridForGenerateButton = () => {
    const hasIncompleteRates = selectedAsOfDate !== null ? !hasExchangeRatesOnAsOfDate(selectedAsOfDate) : true;
    const isDisabled = hasGeneratedReport || isLoading || hasIncompleteRates || (
      isSelectedClientUPC(selectedClient)
        ? [selectedAsOfDate, selectedType, selectedCurrency].some(field => field === null)
        : [selectedCollateral, selectedAsOfDate, selectedType, selectedCurrency].some(field => field === null)
    );
    return (
      <Grid item xs={12} lg={2}>
        <Grid container justifyContent={belowSmallBreakpoint ? 'center' : 'flex-end'}>
          <DisabledComponentsContainer isDisabled={isDisabled}>
            <Button
              variant='contained'
              disabled={isDisabled}
              aria-label={ isDisabled ? 'Generate Report button disabled' : 'Generate Report' }
              onClick={ generateReport }
              sx={styles.generateReportButton}
            >
              Generate Report
            </Button>
          </DisabledComponentsContainer>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container columnSpacing={2} sx={styles.fieldsContainer}>
        { getGridForARCollateral() }
        { getGridForAsOfDate() }
        { getGridForType() }
        { getGridForCurrency() }
        { getGridForGenerateButton() }
      </Grid>
    </>
  );
};

export default ARAgingFieldsContainer;