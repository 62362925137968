import colors from "../../../../../utility/colors";

const styles = {
  alert: {
    marginTop: '1.5rem',
    border: `1px solid ${colors.ERROR_LIGHT}`
  },
  info: {
    marginTop      : '1.5rem',
    backgroundColor: `#FFFCF4`,
  },
  tableContainer: {
		padding         : '1rem',
	},
  actionsIconButton: {
    marginLeft      : '10px',
    padding         : '5px',
  },
  actionsIcon: {
    color           : '#154A8A',
  },
  gridSelect: {
    width           : 'fit-content',
    '@media (max-width: 1535px)': {
      fontSize      : '13px',
    },
    '@media (max-width: 1400px)': {
      fontSize      : '11px',
    }
  },
  limitGridSelect: {
    '& .MuiOutlinedInput-input': {
      width         : '161.5px',
      paddingY      : '0',
      textAlign     : 'left',
    },
  },
  hidden: {
    display         : 'none',
  },
  comboBoxStyle: {
    display         : 'flex',
    justifyContent : 'space-evenly',
    marginTop       : '0.8rem',
  },
  comboBoxStyleModal: {
    display         : 'flex',
    placeContent    : 'end',
    paddingY        : '1rem',
  },
  rightAlignedText: {
    textAlign       : 'right',
  },
  centerAlignedText: {
    textAlign       : 'center',
  },
  actionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  actionLoading: {
    marginTop: '0.35rem',
  },
  actionDataMapLoading: {
    marginRight: '0.5rem',
  },
  actionDeleteLoading: {
    marginLeft: '0.5rem',
  },
  notStarted: {
    backgroundColor : '#FFC107',
  },
  completed: {
    backgroundColor : '#155724',
    color           : '#FFFFFF',
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loadingBackdrop: {
    color: '#fff',
    zIndex: 99
  },
  chip: {
    '&&&.MuiChip-root.MuiChip-colorDefault' : {
      backgroundColor: '#154A8A',
      fontWeight: '500',
      color: 'white'
    },
    marginLeft: '5px'
  },
  iconButton: {
    '&.Mui-disabled': {
      cursor: 'default',
      pointerEvents : 'auto',
    }
  },
  iconButtonDisabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  verbiageContainer: {
    py: '17px',
    px: '17px',
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    border: '1px solid #E0E0E0',
  },
  verbiage: {
    fontSize: '14px',
    textAlign: 'center'
  },  
  fileNameBody: {
    paddingX: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  warningIconButton: {
    padding: '0.25rem',
    marginRight: '0.1rem',
  },
  warningIconSpacer: {
    padding: '0.95rem'
  },
  fileNameHeader: {
    paddingLeft: '2.4rem'
  },
  tableHead: {
		backgroundColor : '#F7F7F7',
		color           : 'inherit',
		fontWeight      : 'bold',
		whiteSpace      : 'nowrap',
		border          : 0,
		height          : '2.5rem',
	},
  tableHeaderText: {
    '& .MuiFormLabel-asterisk': {
      color         : '#db3131',
    },
    '&.MuiFormLabel-root': {
      fontWeight    : 'bold',
      fontSize      : '14px',
      color         : 'black',
    },
  },
  tableRow: {
    '&&:nth-of-type(even)': {
      backgroundColor: '#F7F7F7',
    },
    ':hover': {
      cursor        : 'pointer',
    },
  },
  tableCell: {
    border          : 0,
    height          : '2rem',
  },
}

export default styles;