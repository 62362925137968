import { createContext, useMemo, useState } from "react";
import { LayoutProps } from "./authContext";

export interface ILenderSettingsContext {
  usedIneligibleCodes: string[];
  setUsedIneligibleCodes: (value: string[]) => void;
  canViewLenderSettings: boolean,
  setCanViewLenderSettings: (value : boolean) => void,
  canUpdateCompanyInformation: boolean,
  setCanUpdateCompanyInformation: (value : boolean) => void,
  canUploadCompanyLogo: boolean,
  setCanUploadCompanyLogo: (value : boolean) => void,
  canAddIneligible: boolean,
  setCanAddIneligible: (value : boolean) => void,
  canUpdateIneligible: boolean,
  setCanUpdateIneligible: (value : boolean) => void,
  canDeleteIneligible: boolean,
  setCanDeleteIneligible: (value : boolean) => void,
  canUpdateIneligiblePriority: boolean,
  setCanUpdateIneligiblePriority: (value : boolean) => void,
}

const lenderSettingsContext: ILenderSettingsContext = {
  usedIneligibleCodes: [],
  setUsedIneligibleCodes: (value) => {},
  canViewLenderSettings: true,
  setCanViewLenderSettings: (value) => {},
  canUpdateCompanyInformation: true,
  setCanUpdateCompanyInformation: (value) => {},
  canUploadCompanyLogo: true,
  setCanUploadCompanyLogo: (value) => {},
  canAddIneligible: true,
  setCanAddIneligible: (value) => {},
  canUpdateIneligible: true,
  setCanUpdateIneligible: (value) => {},
  canDeleteIneligible: true,
  setCanDeleteIneligible: (value) => {},
  canUpdateIneligiblePriority: true,
  setCanUpdateIneligiblePriority: (value) => {},
};



export const LenderSettingsContext = createContext<ILenderSettingsContext>(lenderSettingsContext);

const LenderSettingsProvider = (props : LayoutProps) => {
  const { children } = props;

  const [ usedIneligibleCodes, setUsedIneligibleCodes ] = useState<string[]>([]);
  const [ canViewLenderSettings, setCanViewLenderSettings ] = useState<boolean>(false);
  const [ canUpdateCompanyInformation, setCanUpdateCompanyInformation ] = useState<boolean>(false);
  const [ canUploadCompanyLogo, setCanUploadCompanyLogo ] = useState<boolean>(false);
  const [ canAddIneligible, setCanAddIneligible ] = useState<boolean>(false);
  const [ canUpdateIneligible, setCanUpdateIneligible ] = useState<boolean>(false);
  const [ canDeleteIneligible, setCanDeleteIneligible ] = useState<boolean>(false);
  const [ canUpdateIneligiblePriority, setCanUpdateIneligiblePriority ] = useState<boolean>(false);

  const value = useMemo(() => ({
    usedIneligibleCodes,
    setUsedIneligibleCodes,
    canViewLenderSettings,
    setCanViewLenderSettings,
    canUpdateCompanyInformation,
    setCanUpdateCompanyInformation,
    canUploadCompanyLogo,
    setCanUploadCompanyLogo,
    canAddIneligible,
    setCanAddIneligible,
    canUpdateIneligible,
    setCanUpdateIneligible,
    canDeleteIneligible,
    setCanDeleteIneligible,
    canUpdateIneligiblePriority,
    setCanUpdateIneligiblePriority
  }), [
    usedIneligibleCodes,
    setUsedIneligibleCodes,
    canViewLenderSettings,
    setCanViewLenderSettings,
    canUpdateCompanyInformation,
    setCanUpdateCompanyInformation,
    canUploadCompanyLogo,
    setCanUploadCompanyLogo,
    canAddIneligible,
    setCanAddIneligible,
    canUpdateIneligible,
    setCanUpdateIneligible,
    canDeleteIneligible,
    setCanDeleteIneligible,
    canUpdateIneligiblePriority,
    setCanUpdateIneligiblePriority
  ])

  return (
    <LenderSettingsContext.Provider
      value={value}
    >
      {children}
    </LenderSettingsContext.Provider>
  )

}

export default LenderSettingsProvider;