import { useState, useContext, useEffect } from 'react';
import { FormikProps } from 'formik';
import { Box, Paper, Typography, IconButton, Link } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowRightIcon from '@mui/icons-material/ChevronRight';
import { IFormikValuesForRuleAndOverridesPerSetting, IFormikValuesForUPCRuleAndOverridesPerSetting, IIneligibleSettingDetail, IIneligibleSettingsContext } from '../../../interfaces/ineligibleSettingInterface';
import { IneligibleSettingsContext } from '../../../context/ineligibleSettingsContext';
import DraggableList, { DraggableListItem } from '../draggable-list';
import styles from './styles';
import _ from 'lodash';

interface IIneligibleListProps {
  formik: FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>;
}

/**
 * Component represents the IneligibleList component.
 * @param props The component's props.
 * @returns The IneligibleList component.
 */
const IneligibleList: React.FC<IIneligibleListProps> = (props) => {
  const {
    ineligibleSettingDetails,
    fetchedIneligibleSettingDetails,
    setSelectedIneligibleIndex,
    ineligibleListContainerRef,
    isHidingInactiveIneligibles,
    hideInactiveIneligiblesToggle, setHideInactiveIneligiblesToggle,
  }                                                               = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const [isDrawerOpen, setIsDrawerOpen]                           = useState<boolean>(true);

  const [draggableListItems, setDraggableListItems]               = useState<DraggableListItem[]>(props.formik.values.ineligibleSettingDetails.map(({ ineligibleSetting }) => ({
                                                                      recordId: ineligibleSetting.ineligibleSettingId,
                                                                      name: ineligibleSetting.description,
                                                                      value: ineligibleSetting.code,
                                                                      disabled: ineligibleSetting.disabled
                                                                    })));

  /**
   * This hook updates the draggable list items with values fetched from the backend.
   */
  useEffect(() => {
    setDraggableListItems(
      ineligibleSettingDetails.map(({ ineligibleSetting }) => ({
        recordId: ineligibleSetting.ineligibleSettingId,
        name: ineligibleSetting.description,
        value: ineligibleSetting.code,
        disabled: ineligibleSetting.disabled
      }))
    );
  }, [ineligibleSettingDetails]);

  /**
   * This hook updates the draggable list items based on formik resets.
   */
  useEffect(() => {
    setDraggableListItems(
      props.formik.values.ineligibleSettingDetails.map(({ ineligibleSetting }) => ({
        recordId: ineligibleSetting.ineligibleSettingId,
        name: ineligibleSetting.description,
        value: ineligibleSetting.code,
        disabled: ineligibleSetting.disabled
      }))
    );
  }, [props.formik.values.ineligibleSettingDetails]);

  /**
   * This function toggles the visibility of the Ineligible Settings panel.
   */
  const toggleSettingsOpen = () => setIsDrawerOpen(!isDrawerOpen);

  /**
   * This function handles the drop of an item in the draggable list.
   * @param draggedListItem The dragged list item.
   */
  const handleItemDrop = async (draggedListItem: DraggableListItem) => {
    /** reorders formik values & edits each settings' order based on dragged item */
    const ineligibleSettingDetails = _.cloneDeep(props.formik.values.ineligibleSettingDetails);
    draggableListItems.forEach((draggedItem, draggedIndex) => {
      const formikDetail = ineligibleSettingDetails.find(detail => detail.ineligibleSetting.ineligibleSettingId === draggedItem.recordId) as NonNullable<IIneligibleSettingDetail>;
      formikDetail.ineligibleSetting.order = draggedIndex + 1;
      props.formik.setFieldValue(`ineligibleSettingDetails[${draggedIndex}]`, formikDetail);
      if (draggedItem.disabled) { return; }
      draggedListItem.recordId === draggedItem.recordId && setSelectedIneligibleIndex(draggedIndex);
    });

    const draggedListItemIndex = draggableListItems.findIndex(item => item.recordId === draggedListItem.recordId);
    if (!draggedListItem.disabled) { setSelectedIneligibleIndex(draggedListItemIndex); return; }
    const detailIndexToSelect = props.formik.values.ineligibleSettingDetails.findIndex(detail => !detail.ineligibleSetting.disabled && detail.ineligibleSetting.ineligibleSettingId !== draggedListItem.recordId);
    setSelectedIneligibleIndex(detailIndexToSelect); /** auto select another non-disabled setting if dragged setting is disabled */
  };

  /**
   * This function handles the toggling of an item in the draggable list.
   * @param listItem The list item to toggle.
   */
  const handleItemToggle = async (listItem: DraggableListItem) => {
    const toggledListItem = { ...listItem, disabled: !listItem.disabled } as DraggableListItem;
    const detailSettingIndex = draggableListItems.findIndex(item => item.recordId === toggledListItem.recordId);
    /** set changed ineligible setting's disabled state in formik */
    props.formik.setFieldValue(`ineligibleSettingDetails[${detailSettingIndex}].ineligibleSetting.disabled`, toggledListItem.disabled);

    if (!toggledListItem.disabled) {
      setSelectedIneligibleIndex(detailSettingIndex);
      /** update draggable list items to sync with formik values */
      setDraggableListItems(draggableListItems.map(item => item.recordId !== listItem.recordId ? item : toggledListItem));
      return;
    }
    /** to reset details after disabling ineligible*/
    const originalSettingIndex = fetchedIneligibleSettingDetails.findIndex(item => item.ineligibleSetting.ineligibleSettingId === toggledListItem.recordId);
    props.formik.setFieldValue(`ineligibleSettingDetails[${detailSettingIndex}].ineligibleRule`, fetchedIneligibleSettingDetails[originalSettingIndex].ineligibleRule);
    props.formik.setFieldValue(`ineligibleSettingDetails[${detailSettingIndex}].ineligibleRuleOverrides`, fetchedIneligibleSettingDetails[originalSettingIndex].ineligibleRuleOverrides);
    /** to reset validation errors after disabling ineligible */
    props.formik.setFieldError(`ineligibleSettingDetails[${detailSettingIndex}]`, undefined);
    const detailIndexToSelect = props.formik.values.ineligibleSettingDetails.findIndex(detail => !detail.ineligibleSetting.disabled && detail.ineligibleSetting.ineligibleSettingId !== toggledListItem.recordId);
    setSelectedIneligibleIndex(detailIndexToSelect); /** auto select another non-disabled setting if toggled setting is disabled */
    /** update draggable list items to sync with formik values */
    setDraggableListItems(draggableListItems.map(item => item.recordId !== listItem.recordId ? item : toggledListItem));
  };

  return (
    <Paper sx={isDrawerOpen ? styles.paperStyle : styles.closedPaperStyle} ref={ineligibleListContainerRef}>
      { isDrawerOpen ? (
        <>
          <Box sx={styles.titleBox}>
            <Box sx={styles.typographyBox}>
              <Box sx={styles.ineligiblesHeadingContainer}>
                <Typography tabIndex={0} variant='h6' component='h3' sx={styles.titleText}>Ineligibles</Typography>
                <Link component='button' type='button' sx={styles.hideInactiveIneligiblesButton} onClick={() => setHideInactiveIneligiblesToggle(!hideInactiveIneligiblesToggle)}>
                  { isHidingInactiveIneligibles ? 'Show Inactive' : 'Hide Inactive' }
                </Link>
              </Box>
              <Typography tabIndex={0} variant='subtitle1' sx={styles.subtitleText}>Drag and drop to rearrange columns.</Typography>
            </Box>
            <IconButton
              aria-label='Collapse icon'
              onClick={() => toggleSettingsOpen()}
              sx={styles.icon}
            >
              <ArrowLeftIcon />
            </IconButton>
          </Box>
          <DraggableList
            formik={props.formik}
            draggableListItems={draggableListItems}
            setDraggableListItems={setDraggableListItems}
            onItemToggle={handleItemToggle}
            onItemDrop={handleItemDrop}
          />
        </>
      ) : (
        <IconButton
          aria-label='Expand icon'
          onClick={() => toggleSettingsOpen()}
          sx={styles.overflowIcon}
        >
          <ArrowRightIcon />
        </IconButton>
      )}
    </Paper>
  )
};

export default IneligibleList;
