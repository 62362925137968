import { BrowserRouter, Route, Routes } from 'react-router-dom';
import APAgingReport from '../pages/reports/ap-aging';
import ARAgingReport from '../pages/reports/ar-aging';
import ARIneligibleCompare from '../pages/reports/ar-compare';
import ArIneligibleReport from '../pages/reports/ar-ineligible';
import { AuthContext } from '../context/authContext';
import BorrowingBase from '../pages/borrowing-base';
import BorrowingBaseReport from '../pages/reports/borrowing-base';
import CalcIneligibleContextProvider from '../context/calneligibleContext';
import ClientSetting from '../pages/client-setting';
import Clients from '../pages/clients';
import CustomerSetting from '../pages/customer-setting';
import DrawerContextProvider from '../context/drawerContext';
import ErrorPage from '../pages/error';
import FileImport from '../pages/file-import/landing-page';
import FileUploadStepper from '../pages/file-import/stepper';
import IneligibleAdjustments from '../pages/ineligible-adjustments';
import IneligibleSettings from '../pages/ineligible-settings';
import InventoryIneligibles from '../pages/inventory-ineligibles';
import InventoryWorksheet from '../pages/inventory-worksheet';
import Layout from '../components/layout';
import LenderSettings from '../pages/lender-settings';
import LoanBalances from '../pages/loan-balances';
import Reports from '../pages/reports';
import Reserve from '../pages/reserve';
import ResetPassword from '../pages/forgot-password/password-reset-request';
import RollForwardReport from '../pages/reports/roll-forward';
import SignIn from '../pages/sign-in';
import SignUp from '../pages/sign-up';
import UpdatePassword from '../pages/forgot-password/update-password';
import UpdateSuccess from '../pages/forgot-password/update-success';
import UserAndRoles from '../pages/users-and-roles';
import UserProfile from '../pages/users-profile';
import axiosInstance from '../service/axiosInstance';
import { API_DOMAIN, GET, POST } from '../utility/constants';
import { blobToBase64 } from '../utility/helper';
import { setNewLogoDataUrl } from '../actions/bankLogoActions';
import { BankLogoContext } from '../context/bankLogoContext';
import { useContext, useEffect, useState } from 'react';
import FileImportProcessContextProvider from '../context/fileImportProcessContext';
import ExchangeRate from '../pages/client-setting/exchange-rate';
import FileImportProvider from '../context/fileImportContext';
import FileImportPermissionProvider from '../context/fileImportPermissionsContext';
import RequestSuccess from '../pages/forgot-password/request-success';
import SelectedClientProvider from '../context/selectedClientContext';
import ExportReportContextProvider from '../context/exportReportContext';
import AuditTrailPage from '../pages/audit-trail';
import Analytics from '../pages/analytics';
import ForbiddenPage from '../pages/forbidden';

const Router = () => {
  const { state } = useContext(AuthContext);
  const { token } = state;
  const logoDispatch = useContext(BankLogoContext).dispatch;
  const [loading, setLoading] = useState<boolean>(true);

  // useEffect(() => {
  //   if (token === undefined || token === null) {
  //     setLoading(true);
  //     retrieveToken();
  //   } else {
  //     setLoading(false);
  //   }
  // }, [token])

  useEffect(() => {
    fetchLenderProfile();
  }, [])

  const fetchLenderProfile = async () => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/bankDetails/1`,
        method: GET,
    });
    const imageFile = await axiosInstance.request({
      url: `${API_DOMAIN}/bankDetails/logo/download`,
      method: POST,
      responseType: 'blob',
      data: {filename: response.data.bankImageFileName},
      headers: {'content-type': 'multipart/form-data'}
    })
      const logoDataUrl = await blobToBase64(imageFile.data);
      logoDispatch(setNewLogoDataUrl({logoDataUrl}));
    } catch (err) {
      console.log(err);
    }
    
  }
  
  return (
    <BrowserRouter>
      <SelectedClientProvider>
        {token ? (
          <Routes>
            <Route path='/' element={
              <CalcIneligibleContextProvider>
                <FileImportProcessContextProvider>
                  <DrawerContextProvider>
                    <ExportReportContextProvider>
                      <Layout /> 
                    </ExportReportContextProvider>
                  </DrawerContextProvider>
                </FileImportProcessContextProvider>
              </CalcIneligibleContextProvider>
            }>
              <Route index element={<Clients />} />
              <Route path='/sign-in' element={<SignIn />}></Route>
              <Route path='/create-password' element={<UpdatePassword type='create'/>}></Route>
              <Route path='/forgot-password' element={<ResetPassword />}></Route>
              <Route path='/forgot-password/new-password' element={<UpdatePassword type='update'/>}></Route>

              <Route path='/clients'>
                <Route index element={<Clients />} />
              </Route>
              
              {/* Start Clients */}
              <Route path='clients'>
                <Route
                  index
                  element={<Clients />}
                />
                <Route
                  path=':borrowerId/settings'
                >
                  <Route
                    index
                    element={<ClientSetting />}
                  />
                  <Route
                    path='exchangeRate'
                    element={<ExchangeRate/>} 
                  />
                </Route>
                <Route
                  path=':borrowerId/settings/:arCollateralId/customers'
                  element={<CustomerSetting />}
                />
                <Route
                  path=':borrowerId/settings/:arCollateralId/customers/edit'
                  element={<CustomerSetting />}
                />
                <Route
                  path=':borrowerId/settings/:arCollateralId/vendors/edit'
                  element={<CustomerSetting />}
                />
                <Route
                  path=':borrowerId/settings/ineligible-adjustments'
                  element={<IneligibleAdjustments />}
                />
                <Route
                  path=':borrowerId/settings/loan-balances'
                  element={<LoanBalances />}
                />
                <Route
                  path=':borrowerId/settings/reserves'
                  element={<Reserve />}
                />
                <Route
                  path=':borrowerId/settings/:invCollateralFk/inventory-worksheet'
                  element={<InventoryWorksheet path='clients' />}
                />
                <Route
                  path=':borrowerId/settings/:invCollateralId/ineligibles-inventory'
                  element={<InventoryIneligibles />}
                />
              </Route>
              {/* End Clients */}
              <Route path='reports'>
                <Route index element={<Reports />} />
                <Route
                  path='borrowing-base'
                  element={<BorrowingBaseReport />}
                ></Route>
                <Route
                  path='ar-ineligible'
                  element={<ArIneligibleReport />}
                />
                <Route
                  path='ar-aging'
                  element={<ARAgingReport />}
                ></Route>
                <Route
                  path='inventory'
                  element={<InventoryWorksheet path='reports' />}
                ></Route>
                <Route
                  path='roll-forward'
                  element={<RollForwardReport />}
                ></Route>
                <Route
                  path='ar-compare'
                  element={<ARIneligibleCompare />}
                />
                <Route
                  path='ap-aging'
                  element={<APAgingReport />}
                />
              </Route>
              <Route
                path='/borrowing-base'
                element={<BorrowingBase />}
              ></Route>
              <Route
                path='/analytics'
                element={<Analytics />}
              ></Route>
              <Route
                path='/general-settings'
                element={<LenderSettings />}
              ></Route>
              <Route
                path='/audit-trail'
                element={<AuditTrailPage />}
              ></Route>
              {/* users and roles */}
              <Route
                path='/users-and-roles'
              >
                <Route 
                  index
                  element={<UserAndRoles />}
                />
                <Route
                path=':id/users-profile'
                element={<UserProfile />}
                />
              </Route>
              {/* end users and role */}
              <Route
                path='/file-import'
                element={
                  <FileImportPermissionProvider>
                    <FileImportProvider>
                      <FileImport/>
                    </FileImportProvider>
                  </FileImportPermissionProvider>
                }
              >
              </Route>
              <Route path='/ineligible-settings' element={<IneligibleSettings/>}></Route>
              <Route
                path='/upload-files'
                element={
                  <FileImportProvider>
                    <FileUploadStepper />
                  </FileImportProvider>
                }
              >
              </Route>
            </Route>
            <Route path='/forbidden' element={<ForbiddenPage />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        ) : (
          <Routes>
            <Route path='/' element={<SignIn />}></Route>
            <Route path='/sign-in' element={<SignIn />}></Route>
            <Route path='/sign-up' element={<SignUp />}></Route>
            <Route path='/forgot-password' element={<ResetPassword />}></Route>
            <Route path='/forgot-password/request-success' element={<RequestSuccess />}></Route>
            <Route path='/forgot-password/new-password' element={<UpdatePassword type='update'/>}></Route>
            <Route path='/forgot-password/success' element={<UpdateSuccess type='update'/>}></Route>
            <Route path='/create-password' element={<UpdatePassword type='create'/>}></Route>
            <Route path='/create-password/success' element={<UpdateSuccess type='create'/>}></Route>
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        )}
      </SelectedClientProvider>
    </BrowserRouter>
  );
};

export default Router;
