const styles = {
  mainContainer: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F7F7F7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  paperStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '320px',
    borderRadius: '10px',
    padding: '30px',
    marginTop: '50px'
  },
  modalTitle: {
    paddingTop: '15px',
    paddingBottom: '15x',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#154A8A',
    fontFamily: 'Helvetica, Arial, sans-serif', 
  },
  descriptionText: {
    color: '#154A8A', 
    fontSize: '14px',
    paddingTop: '5px',
    paddingBottom: '30px',
    textAlign: 'center'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '10px',
    marginTop: '5px',
  },
  loginButton: {
    fontSize: '14px',
    fontWeight: 'normal',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
  checkIcon: {
    color: '#154A8A',
    fontSize: '72px',
    
  },
};

export default styles;