import { Box, Button, CircularProgress, Divider, IconButton, Modal, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Close } from '@mui/icons-material';
import styles from './styles';


export interface IWarningModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  description: string;
  isDeleting: boolean
}

const WarningModal = ({
  open,
  onClose,
  onConfirm,
  description,
  isDeleting
}: IWarningModalProps) => {

  /**
   * This function disabled the onClose function of the modal when the isDeleting prop is set to true.
   */
  const handleClose = () => {
    !isDeleting && onClose();
  };

  return (
    <Modal 
      open={open} 
      onClose={handleClose}
    >
      <Box sx={styles.modalBackground}>
        <Box sx={styles.headingContainer}>
          <Box sx={styles.titleContainer}>
            <WarningAmberIcon fontSize='large' sx={styles.warningIcon}/>
            <Typography tabIndex={0} sx={styles.headingTitle}>Warning!</Typography>
          </Box>
          <IconButton disabled={isDeleting} tabIndex={0} aria-label="Close icon" onClick={onClose} data-testid='close-button'>
            <Close fontSize="small" sx={styles.closeButton} />
          </IconButton>
          
        </Box>
        <Divider variant="middle" sx={{...styles.divider, ...styles.marginTop}} />
        <Box sx={{...styles.descriptionContainer}}>
          <Typography tabIndex={0} >{description}</Typography>
        </Box>
        <Divider variant="middle" sx={styles.divider} />
        <Box sx={styles.buttonContainer}>
          <Button disabled={isDeleting} variant="outlined" sx={styles.cancelButton} onClick={onClose} aria-label={'Cancel'} data-testid='cancel-button'>
            Cancel
          </Button>
          <Button
              disabled={isDeleting}
              variant="contained"
              aria-label={`Proceed Button`}
              data-testid='proceed-button'
              sx={styles.primaryButton}
              onClick={() => {
                onConfirm();
              }}
            >
              {isDeleting ? <CircularProgress size={20} /> : `Proceed`}
            </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default WarningModal;
