import { IPermission, IPermissionItem, IRole } from "../../../../interfaces/rolesPermissionInterface"

export const TempRoleList: IRole[] = [
  {
    roleName: 'Role 1',
    permissions: ['lw_add_user']
  },
  {
    roleName: 'Role 2',
    permissions: []
  }
]

export const TempPermissionItem: IPermissionItem[] = [
  {
    id: 0,
    name: 'Permission 100',
    enabled: false,
    value: 'permission100'
  },
  {
    id: 1,
    name: 'Permission 2',
    enabled: false,
    value: 'permission2'
  },
  {
    id: 2,
    name: 'Permission 3',
    enabled: false,
    value: 'permission3'
  },
  {
    id: 3,
    name: 'Permission 4',
    enabled: false,
    value: 'permission4'
  },
  {
    id: 4,
    name: 'Permission 5',
    enabled: false,
    value: 'permission5'
  },
  {
    id: 5,
    name: 'Permission 6',
    enabled: false,
    value: 'permission6'
  },
]

export const TempPermissionList: IPermission[] = [
  {
    permissionName: 'Clients',
    permissions: [
      {
        id: 3,
        name: 'View Clients',
        enabled: false,
        value: 'lw_view_clients'
      },
      {
        id: 0,
        name: 'Update Client',
        enabled: false,
        value: 'lw_update_client'
      },
      {
        id: 1,
        name: 'Add New Client',
        enabled: false,
        value: 'lw_add_new_client'
      },
      {
        id: 2,
        name: 'Delete/Archive Client',
        enabled: false,
        value: 'lw_delete_archive_client'
      },
    ]
  },
  {
    permissionName: 'Client Settings',
    permissions: [
      {
        id: 3,
        name: 'View Client Settings',
        enabled: false,
        value: 'lw_view_client_settings'
      },
      {
        id: 0,
        name: 'Calculate Ineligible',
        enabled: false,
        value: 'lw_clients_calculate_ineligible'
      },
      {
        id: 1,
        name: 'Calculate Borrowing Base',
        enabled: false,
        value: 'lw_clients_calculate_borrowing_base'
      },
    ]
  },
  {
    permissionName: 'Accounts Receivable',
    permissions: [
      {
        id: 3,
        name: 'View AR',
        enabled: false,
        value: 'lw_view_ar_collaterals'
      },
      {
        id: 4,
        name: 'Change Default AR',
        enabled: false,
        value: 'lw_change_default_ar_collateral'
      },
      {
        id: 0,
        name: 'Add AR',
        enabled: false,
        value: 'lw_add_ar'
      },
      {
        id: 1,
        name: 'Update AR',
        enabled: false,
        value: 'lw_update_ar'
      },
      {
        id: 2,
        name: 'Delete/Archive AR',
        enabled: false,
        value: 'lw_delete_archive_ar'
      },
    ]
  },
  {
    permissionName: 'Customer Settings',
    permissions: [
      {
        id: 0,
        name: 'View Customer List',
        enabled: false,
        value: 'lw_view_customer_list'
      },
      {
        id: 1,
        name: 'View Customer Information',
        enabled: false,
        value: 'lw_view_customer_information'
      },
      {
        id: 2,
        name: 'Update Customer Information',
        enabled: false,
        value: 'lw_update_customer_information'
      },
      {
        id: 3,
        name: 'View Parent List',
        enabled: false,
        value: 'lw_view_parent_list'
      },
      {
        id: 4,
        name: 'View Parent Information',
        enabled: false,
        value: 'lw_view_parent_information'
      },
      {
        id: 5,
        name: 'Update Parent Information',
        enabled: false,
        value: 'lw_update_parent_information'
      },
      {
        id: 6,
        name: 'Edit Parent/Child Relationship',
        enabled: false,
        value: 'lw_edit_parent_child_relationship'
      }
    ]
  },
  {
    permissionName: 'Inventory',
    permissions: [
      {
        id: 3,
        name: 'View Inventory',
        enabled: false,
        value: 'lw_view_inv_collaterals'
      },
      {
        id: 4,
        name: 'Change Default Inventory',
        enabled: false,
        value: 'lw_change_default_inv_collateral'
      },
      {
        id: 0,
        name: 'Add Inventory',
        enabled: false,
        value: 'lw_add_inventory'
      },
      {
        id: 1,
        name: 'Update Inventory',
        enabled: false,
        value: 'lw_update_inventory'
      },
      {
        id: 2,
        name: 'Delete/Archive Inventory',
        enabled: false,
        value: 'lw_delete_archive_inventory'
      },
    ]
  },
  {
    permissionName: 'Inventory Ineligibles',
    permissions: [
      {
        id: 0,
        name: 'View Inventory Ineligibles',
        enabled: false,
        value: 'lw_view_inventory_ineligibles'
      },
      {
        id: 1,
        name: 'Add Inventory Ineligibles',
        enabled: false,
        value: 'lw_add_inventory_ineligibles'
      },
      {
        id: 2,
        name: 'Update Inventory Ineligibles',
        enabled: false,
        value: 'lw_update_inventory_ineligibles'
      },
      {
        id: 3,
        name: 'Delete Inventory Ineligibles',
        enabled: false,
        value: 'lw_delete_inventory_ineligibles'
      }
    ]
  },
  {
    permissionName: 'Inventory Worksheet',
    permissions: [
      {
        id: 0,
        name: 'View Inventory Worksheet',
        enabled: false,
        value: 'lw_view_inventory_worksheet'
      },
      {
        id: 1,
        name: 'Add Inventory Worksheet',
        enabled: false,
        value: 'lw_add_inventory_worksheet'
      },
      {
        id: 2,
        name: 'Update Inventory Worksheet',
        enabled: false,
        value: 'lw_update_inventory_worksheet'
      },
      {
        id: 3,
        name: 'Delete Inventory Worksheet',
        enabled: false,
        value: 'lw_delete_inventory_worksheet'
      }
    ]
  },
  {
    permissionName: 'Other Loans',
    permissions: [
      {
        id: 3,
        name: 'View Other Loan',
        enabled: false,
        value: 'lw_view_other_loans'
      },
      {
        id: 0,
        name: 'Add Other Loan',
        enabled: false,
        value: 'lw_add_other_loan'
      },
      {
        id: 1,
        name: 'Update Other Loan',
        enabled: false,
        value: 'lw_update_other_loan'
      },
      {
        id: 2,
        name: 'Delete/Archive Other Loan',
        enabled: false,
        value: 'lw_delete_archive_other_loan'
      },
    ]
  },
  {
    permissionName: 'Ineligible Adjustments',
    permissions: [
      {
        id: 3,
        name: 'View Ineligible Adjustments',
        enabled: false,
        value: 'lw_view_ineligible_adjustments'
      },
      {
        id: 0,
        name: 'Add Ineligible Adjustments',
        enabled: false,
        value: 'lw_add_ineligible_adjustments'
      },
      {
        id: 1,
        name: 'Update Ineligible Adjustments',
        enabled: false,
        value: 'lw_update_ineligible_adjustments'
      },
      {
        id: 2,
        name: 'Delete/Archive Ineligible Adjustments',
        enabled: false,
        value: 'lw_delete_archive_ineligible_adjustments'
      },
    ]
  },
  {
    permissionName: 'Loan Balances',
    permissions: [
      {
        id: 3,
        name: 'View Loan Balances',
        enabled: false,
        value: 'lw_view_loan_balances'
      },
      {
        id: 0,
        name: 'Add Loan Balances',
        enabled: false,
        value: 'lw_add_loan_balances'
      },
      {
        id: 1,
        name: 'Update Loan Balances',
        enabled: false,
        value: 'lw_update_loan_balances'
      },
      {
        id: 2,
        name: 'Delete/Archive Loan Balances',
        enabled: false,
        value: 'lw_delete_archive_loan_balances'
      },
    ]
  },
  {
    permissionName: 'Reserves',
    permissions: [
      {
        id: 3,
        name: 'View Reserves',
        enabled: false,
        value: 'lw_view_reserves'
      },
      {
        id: 0,
        name: 'Add Reserves',
        enabled: false,
        value: 'lw_add_reserves'
      },
      {
        id: 1,
        name: 'Update Reserves',
        enabled: false,
        value: 'lw_update_reserves'
      },
      {
        id: 2,
        name: 'Delete/Archive Reserves',
        enabled: false,
        value: 'lw_delete_archive_reserves'
      },
    ]
  },
  {
    permissionName: 'File Import',
    permissions: [
      {
        id: 3,
        name: 'View File Import',
        enabled: false,
        value: 'lw_view_file_import'
      },
      {
        id: 0,
        name: 'Import File',
        enabled: false,
        value: 'lw_import_file'
      },
      {
        id: 1,
        name: 'Download File',
        enabled: false,
        value: 'lw_download_file'
      },
      {
        id: 2,
        name: 'Archive File',
        enabled: false,
        value: 'lw_archive_file'
      },
    ]
  },
  {
    permissionName: 'Ineligible Settings',
    permissions: [
      {
        id: 7,
        name: 'View Ineligible Settings',
        enabled: false,
        value: 'lw_view_ineligible_settings'
      },
      {
        id: 0,
        name: 'Reorder Ineligible List',
        enabled: false,
        value: 'lw_reorder_ineligible_list'
      },
      {
        id: 1,
        name: 'Activate/Deactivate Ineligible',
        enabled: false,
        value: 'lw_activate_deactivate_ineligible'
      },
      {
        id: 2,
        name: 'Add Criteria and Settings',
        enabled: false,
        value: 'lw_add_criteria_and_settings'
      },
      {
        id: 3,
        name: 'Update Criteria and Settings',
        enabled: false,
        value: 'lw_update_criteria_and_settings'
      },
      {
        id: 4,
        name: 'Delete Criteria and Settings',
        enabled: false,
        value: 'lw_delete_criteria_and_settings'
      },
      {
        id: 5,
        name: 'Update Additional Settings',
        enabled: false,
        value: 'lw_update_additional_settings'
      },
      {
        id: 6,
        name: 'Calculate Ineligible',
        enabled: false,
        value: 'lw_ineligible_settings_calculate_ineligible'
      },
    ]
  },
  {
    permissionName: 'Reports',
    permissions: [
      {
        id: 18,
        name: 'View Reports',
        enabled: false,
        value: 'lw_view_reports'
      },
      {
        id: 0,
        name: 'View Borrowing Base Report',
        enabled: false,
        value: 'lw_view_borrowing_base_report'
      },
      {
        id: 1,
        name: 'Export Borrowing Report',
        enabled: false,
        value: 'lw_export_borrowing_base_report'
      },
      {
        id: 2,
        name: 'View AR Ineligible Report',
        enabled: false,
        value: 'lw_view_ar_ineligible_report'
      },
      {
        id: 3,
        name: 'Export AR Ineligible Report',
        enabled: false,
        value: 'lw_export_ar_ineligible_report'
      },
      {
        id: 4,
        name: 'View AR Aging Report',
        enabled: false,
        value: 'lw_view_ar_aging_report'
      },
      {
        id: 5,
        name: 'Export AR Aging Report',
        enabled: false,
        value: 'lw_export_ar_aging_report'
      },
      {
        id: 6,
        name: 'View AR Ineligible Compare Report',
        enabled: false,
        value: 'lw_view_ar_ineligible_compare_report'
      },
      {
        id: 7,
        name: 'Export AR Ineligible Compare Report',
        enabled: false,
        value: 'lw_export_ar_ineligible_compare_report'
      },
      {
        id: 8,
        name: 'View Inventory Report',
        enabled: false,
        value: 'lw_view_inventory_report'
      },
      {
        id: 9,
        name: 'Export Inventory Report',
        enabled: false,
        value: 'lw_export_inventory_report'
      },
      {
        id: 10,
        name: 'View Roll Foward Report',
        enabled: false,
        value: 'lw_view_roll_forward_report'
      },
      {
        id: 11,
        name: 'Export Roll Forward Report',
        enabled: false,
        value: 'lw_export_roll_forward_report'
      },      
      {
        id: 10,
        name: 'View AP Aging Report',
        enabled: false,
        value: 'lw_view_ap_aging_report'
      },
      {
        id: 11,
        name: 'Export AP Aging Report',
        enabled: false,
        value: 'lw_export_ap_aging_report'
      },
      {
        id: 12,
        name: 'View Financial Mapping Report',
        enabled: false,
        value: 'lw_view_financial_mapping_report'
      },
      {
        id: 13,
        name: 'Export Financial Mapping Report',
        enabled: false,
        value: 'lw_export_financial_mapping_report'
      },
      {
        id: 14,
        name: 'View Balance Sheet Report',
        enabled: false,
        value: 'lw_view_balance_sheet_report'
      },
      {
        id: 15,
        name: 'Export Balance Sheet Report',
        enabled: false,
        value: 'lw_export_balance_sheet_report'
      },      
      {
        id: 16,
        name: 'View Profit and Loss Statement Report',
        enabled: false,
        value: 'lw_view_profit_and_loss_statement_report'
      },
      {
        id: 17,
        name: 'Export Profit and Loss Statement Report',
        enabled: false,
        value: 'lw_export_profit_and_loss_statement_report'
      },
    ]
  },
  {
    permissionName: 'Roles and Permissions',
    permissions: [
      {
        id: 7,
        name: 'View Users & Roles',
        enabled: false,
        value: 'lw_view_users_roles'
      },{
        id: 0,
        name: 'Add User',
        enabled: false,
        value: 'lw_add_user'
      },
      {
        id: 1,
        name: 'Update User',
        enabled: false,
        value: 'lw_update_user'
      },
      {
        id: 2,
        name: 'Deactivate User',
        enabled: false,
        value: 'lw_deactivate_user'
      },
      {
        id: 3,
        name: 'Reactivate User',
        enabled: false,
        value: 'lw_reactivate_user'
      },
      {
        id: 8,
        name: 'Delete User',
        enabled: false,
        value: 'lw_delete_user'
      },
      {
        id: 4,
        name: 'Add Role',
        enabled: false,
        value: 'lw_add_role'
      },
      {
        id: 5,
        name: 'Delete Role',
        enabled: false,
        value: 'lw_delete_role'
      },
      {
        id: 6,
        name: 'Update Role',
        enabled: false,
        value: 'lw_update_role'
      },
    ]
  },
  {
    permissionName: 'Lender Settings',
    permissions: [
      {
        id: 6,
        name: 'View Lender Settings',
        enabled: false,
        value: 'lw_view_lender_settings'
      },
      {
        id: 0,
        name: 'Update Company Information',
        enabled: false,
        value: 'lw_update_company_information'
      },
      {
        id: 1,
        name: 'Upload Company Logo',
        enabled: false,
        value: 'lw_update_company_logo'
      },
      {
        id: 2,
        name: 'Add Ineligible',
        enabled: false,
        value: 'lw_add_ineligible'
      },
      {
        id: 3,
        name: 'Update Ineligible',
        enabled: false,
        value: 'lw_update_ineligible'
      },
      {
        id: 4,
        name: 'Delete Ineligible',
        enabled: false,
        value: 'lw_delete_ineligible'
      },
      {
        id: 5,
        name: 'Update Ineligible Priority',
        enabled: false,
        value: 'lw_update_ineligible_priority'
      },
    ]
  },
  
  
]