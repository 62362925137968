import { AlertColor, Box, Button, Divider, Grid, List, ListItemButton, Typography } from "@mui/material"
import { FieldArray, FieldArrayRenderProps, Form, Formik, FormikProps } from "formik"
import { IUsersAndRolesContext, UsersAndRolesContext } from "../../../../context/usersAndRolesContext"
import { TempPermissionList } from "./tempDatas"
import { FC, useContext, useEffect, useState } from "react"
import AddRole from "../../modals/add-role-modal"
import { ArrowRight } from "@mui/icons-material"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PopUpModal from "../../../modals/pop-up-modal"
import styles from "../../styles"
import AddPermission from "../../modals/add-permission-modal"
import PermissionBox from "../permission-box"
import { IPermission, IPermissionAPI, IRole, IRoleAPI } from "../../../../interfaces/rolesPermissionInterface"
import axiosInstance from "../../../../service/axiosInstance"
import { rolePermissionAPI } from "../../../../service/api"
import { DELETE, GET, NO_PERMISSION_MSG, PERMISSIONS, POST, PUT } from "../../../../utility/constants"
import { checkUserPermissions, getLocalStorageItem, getPermissionsOfUser } from "../../../../utility/helper"
import ConfirmModal from "../../../modals/confirm-modal"
import Toaster from "../../../toaster"
import { DrawerContext, DrawerContextInterface } from "../../../../context/drawerContext"
import { usePrompt } from "../../../../utility/prompt"
import addNewRoleSchema from "../../../../schemas/addRoleSchema"

const tempRole : IRole = {
  roleName: '',
  permissions: []
};

/**
 * Component for showing the contents of the Roles and Permission page.
 */
const RolesAndPermission: FC = () => {
  const {isDirty, setIsDirty, 
         isAddRoleDirty, setIsAddRoleDirty,
         setShowPrompt, 
         canAddRole, canUpdateRole, canDeleteRole}          = useContext(UsersAndRolesContext) as IUsersAndRolesContext;
  const {setCanViewClients,
         setCanViewClientSettings,
         setCanViewFileImport,
         setCanViewIneligibleSettings,
         setCanViewLenderSettings,
         setCanViewReports,
         setCanViewUserRoles,
         setCanViewAssignedClients}                         = useContext<DrawerContextInterface>(DrawerContext);
  const [roleList, setRoleList]                             = useState<IRole[]>([]);
  const [permissionList]                                    = useState<IPermission[]>(TempPermissionList);
  const [selectedRole, setSelectedRole]                     = useState<string>('');
  const [selectedIndex, setSelectedIndex]                   = useState<number>(0);
  const [showAddRoleModal, setShowAddRoleModal]             = useState<boolean>(false);
  const [showAddPermissionModal, setShowAddPermissionModal] = useState<boolean>(false);
  const [isEditing, setIsEditing]                           = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen]                       = useState<boolean>(false);
  const [toasterOpen, setToasterOpen]                       = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]                 = useState<string>('');
  const [toasterSeverity, setToasterSeverity]               = useState<AlertColor>('success');
  
  /**
   * This useEffect hook calls the buildRolesAndPermissions function upon page load.
   */
  useEffect(() => {
    buildRolesAndPermissions();
  }, [])

  /**
   * This function closes the add role modal if the form is not dirty. 
   * If the form is dirty, the confirm navigation modal is shown first.
   * @param formik This is the formik object which contains the values of the form.
   */
  const handleCloseRoleModal = (formik: FormikProps<{roleList : IRole[]}>) => {
    if (isAddRoleDirty) {
      setShowPrompt(true)
    } else {
      setShowAddRoleModal(!showAddRoleModal);
    }
  };

  /**
   * This function closes the add permission modal if the form is not dirty. 
   * If the form is dirty, the confirm navigation modal is shown first.
   * @param formik This is the formik object which contains the values of the form.
   */
  const handleClosePermissionModal = (formik: FormikProps<{roleList : IRole[]}>) => {
    if (isAddRoleDirty) {
      setShowPrompt(true)
    } else {
      formik.resetForm();
      setShowAddPermissionModal(!showAddPermissionModal);
    }
  };

  /** 
   * This function calls the API endpoint for retrieving all of the roles.
   * @returns The available roles in the application. See IRoleAPI interface for more information.
   */
  const getAllRoles = async () => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.GET_ALL_ROLES,
        method: GET,
        headers: {
          token : token !== undefined ? token : ''
        }
      })
      const roles : IRoleAPI[] = response.data;
      return roles;
    } catch (error) {
      console.log('GET ALL ROLES ERROR:',error);
    }
  }

  /**
   * This function calls the API endpoint for deleting a role.
   * @param roleId The ID of the role.
   */
  const deleteRole = async (roleId : string) => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.DELETE_ROLE,
        method: DELETE,
        headers: {
          token : token !== undefined ? token : ''
        },
        params: {
          roleId : roleId
        }
      })
      return response.status;
    } catch (error) {
      console.log('GET ALL ROLES ERROR:',error);
    }
  }

  /**
   * This function handles the deletion of roles.
   * @param formikRoles The roles listed in the form.
   * @param arrayHelper The formik field array props.
   */
  const handleDelete = async (formikRoles : IRole[], arrayHelper : FieldArrayRenderProps) => {
    const roles = await getAllRoles();
    if (formikRoles[selectedIndex].roleName !== undefined) {
      const roleToBeDeleted = roles?.filter(role => role.name === formikRoles[selectedIndex].roleName)
      if (roleToBeDeleted !== undefined) {
        const status = await deleteRole(roleToBeDeleted[0].id);
        if (status === 200) {
          arrayHelper.remove(selectedIndex);
          setToasterOpen(true);
          setToasterMessage(`The role has been successfully deleted!`);
          setToasterSeverity('success');
        } else {
          setToasterOpen(true);
          setToasterMessage('Failed to save data. Please check your inputs!');
          setToasterSeverity('error');
        }
      }
    }
    setIsModalOpen(false);
  }

  /**
   * This function closes the delete modal.
   */
  const handleClose = () => {
    setIsModalOpen(false);
  }

  /** 
   * This function calls the API endpoint for retrieving all of the permissions.
   * @returns The available permissions in the application. See IPermissionAPI interface for more information.
   */
  const getAllPermissions = async () => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.GET_ALL_PERMISSIONS,
        method: GET,
        headers: {token : token !== undefined ? token : ''}
      })
      const permissions : IPermissionAPI[] = response.data;
      return permissions;
    } catch (error) {
      console.log('GET ALL PERMISSIONS ERROR:',error);
    }
  }

  /**
   * This function calls the API endpoint for updating the permissions of a role.
   * @param roleId The ID of the role to be updated.
   * @param permissions The permissions to be given to the role.
   * @returns The updated permissions.
   */
  const updatePermissionsOfRole = async (roleId : string, permissions : IPermissionAPI[]) => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.ADD_PERMISSION_TO_ROLE,
        method: PUT,
        params: {
          roleId: roleId
        },
        headers: {
          token : token !== undefined ? token : ''
        },
        data: permissions
      })
      return response;
    } catch (error) {
      console.log('UPDATE PERMISSION OF ROLE ERROR:',error);
    }
  }

  /**
   * This function calls the API endpoint for adding permissions to a role.
   * @param roleId The ID of the role to be updated.
   * @param permissions The permissions to be given to the role.
   * @returns The updated permissions.
   */
  const addPermissionToRole = async (roleId : string, permissions : IPermissionAPI[]) => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.ADD_PERMISSION_TO_ROLE,
        method: POST,
        params: {
          roleId: roleId
        },
        headers: {
          token : token !== undefined ? token : ''
        },
        data: permissions
      })
      return response;
    } catch (error) {
      console.log('ADD PERMISSION TO ROLE ERROR:',error);
    }
  }

  /**
   * This function retrieves all of the roles and their permissions, and then formatting them into an 
   * object that can be used by the component. See IRole for more information.
   * @returns The roles and permissions object that can be used by the roles and permissions component.
   */
  const buildRolesAndPermissions = async () => {
    let rolesAndPermissions : IRole[] = [];
    const roles = await getAllRoles();
    if (roles !== undefined) {
      roles.forEach(role => {
        const roleToBeAdded : IRole = {
          roleName: role.name,
          permissions: role.realmRoles
        };
        rolesAndPermissions.push(roleToBeAdded);
      })
      setRoleList(rolesAndPermissions);
      return rolesAndPermissions;
    } else {
      console.log("ERROR: NO ROLES AND PERMISSIONS SAVED")
      return [];
    }
  }

  /**
   * This function is called whenever the save button is called. 
   * This function calls all the functions needed to complete the updating of roles and permissions.
   * Afterwards, the functions for updating the side navigation bar and the 
   * @param values These are the values of the roles and their permissions to be submitted.
   */
  const handleSubmit = async (values: { roleList : IRole[]}) => {
    await Promise.all(values.roleList.map(async (role) => {
      const roleToBeFound = role.roleName;
      const permissionsToBeFound = role.permissions;
      const roles = await getAllRoles();
      const permissions = await getAllPermissions();
      if (roles !== undefined && permissions !== undefined) {
        const selectedRole = roles.filter(role => role.name === roleToBeFound);
        const permissionsToBeAdded = permissions.filter(permission => permissionsToBeFound.includes(permission.name));
        let response;
        if (selectedRole[0].realmRoles.length > 0) {
          response = await updatePermissionsOfRole(selectedRole[0].id, permissionsToBeAdded);     
        } else {
          response = await addPermissionToRole(selectedRole[0].id, permissionsToBeAdded);
        }
        if (response?.status === 200) {
          updateSideNav();
          setToasterOpen(true);
          setToasterMessage(`The role has been successfully updated!`);
          setToasterSeverity('success');
        } else {
          setToasterOpen(true);
          setToasterMessage('Failed to save data. Please check your inputs!');
          setToasterSeverity('error');
        }
        await buildRolesAndPermissions();
        setIsDirty(false);
        setIsEditing(false);
      }
    }))
  }

  /**
   * This function updates the side navigation bar based on the updated permissions of the user.
   */
  const updateSideNav = async () => {
    const permissions = await getPermissionsOfUser(getLocalStorageItem('uid'));
    setCanViewClients(permissions.includes(PERMISSIONS.VIEW_CLIENT));
		setCanViewAssignedClients(permissions.includes(PERMISSIONS.VIEW_ASSIGNED_CLIENT));
    setCanViewClientSettings(permissions.includes(PERMISSIONS.VIEW_CLIENT_SETTINGS));
    setCanViewIneligibleSettings(permissions.includes(PERMISSIONS.VIEW_INELIGIBLE_SETTINGS));
		setCanViewFileImport(permissions.includes(PERMISSIONS.VIEW_FILE_IMPORT));
		setCanViewReports(permissions.includes(PERMISSIONS.VIEW_REPORTS));
		setCanViewLenderSettings(permissions.includes(PERMISSIONS.VIEW_LENDER_SETTINGS));
		setCanViewUserRoles(permissions.includes(PERMISSIONS.VIEW_USERS_AND_ROLES));
  }

  /**
   * This function checks if the user has a specific permission.
   * @param func The function to be called if the user has the required permission.
   * @param permission The permission to be checked.
   * @param args The arguments to be given to the function.
   */
  const checkPermission = async (func: Function, permission: string, args: any[]) => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), permission)
    if(isPermitted){
      func(...args)
      return
    }
    setToasterOpen(true);
    setToasterMessage(NO_PERMISSION_MSG);
    setToasterSeverity('error');
  }

  /**
   * This handles the cancelling and resetting of the form.
   * @param formik This is the formik object which contains the values of the form.
   */
  const handleCancel = async (formik : FormikProps<{roleList: IRole[]}> ) => {
    await buildRolesAndPermissions();
    formik.setFieldValue('roleList', roleList)
    setIsEditing(false)
  };

  /**
   * This function sets the dirty status of the roles and permissions form.
   * @param node The formik reference object containing the dirty status.
   */
  const addRoleformikRef = (node: any) => {
    if (node !== null) {
      setIsAddRoleDirty(node.dirty)
    }
  };

  /**
   * This function sets the dirty status of the add role form.
   * @param node The formik reference object containing the dirty status.
   */
  const formikRef = (node: any) => {
    if (node !== null) {
      if (!isEditing) {
        setIsDirty(false);
      } else {
        setIsDirty(node.dirty)
      } 
    }
  };

  /**
   * This is a hook which handles the showing of the confirm navigation modal when the form is dirty.
   */
  usePrompt('You have unsaved changes. Are you sure you want to leave this page?', isDirty);
  
  return (
    <>
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setToasterOpen(false)}
      />
      <Formik
        enableReinitialize
        initialValues={{roleList}}
        onSubmit={(values) => {checkPermission(handleSubmit, PERMISSIONS.UPDATE_ROLE, [values])}}
        innerRef={formikRef}
      >{(formik) => (
        <FieldArray name='roleList'>
          {(roleListHelpers) => (
            <Form>
              <Box>
                <ConfirmModal
                  title={`Delete ${formik.values.roleList[selectedIndex]?.roleName}`}
                  description={`Are you sure you want to delete this item?`}
                  open={isModalOpen}
                  alignment="left"
                  onClose={handleClose}
                  onConfirm={() => checkPermission(handleDelete, PERMISSIONS.DELETE_ROLE, [formik.values.roleList, roleListHelpers])}
                  errorButton={true}
                  noButtonText={'Cancel'}
                  yesButtonText={`Delete`}
                />
                <Box>
                  <Grid item xs={10.5} sx={{...styles.searchStyle, justifyContent: 'end', marginY: '0'}}>
                    <Button variant='contained' color='primary' onClick={() => { setShowAddRoleModal(true) }} sx={{...styles.addButton, ...(!canAddRole && styles.hidden)}}>
                      <Typography variant='button'>
                        + Add Role
                      </Typography>
                    </Button>
                  </Grid>
                  <Formik
                    enableReinitialize
                    initialValues={{roleList : [
                      tempRole
                    ]}}
                    validationSchema={addNewRoleSchema}
                    onSubmit={() => {}}
                    innerRef={addRoleformikRef}
                  >
                    {(createdRoleFormik) => (
                      <>
                        <PopUpModal
                          open={showAddRoleModal}
                          onClose={() => {handleCloseRoleModal(createdRoleFormik)}}
                          isComponentEditable={false}
                          isEditableTrue={true}
                          title1='Add Role'
                          width='350px'
                        >
                          <AddRole 
                            setShowAddRoleModal={setShowAddRoleModal}
                            setShowAddPermissionModal={setShowAddPermissionModal}
                            createdRoleFormik={createdRoleFormik}
                            setToasterMessage={setToasterMessage}
                            setToasterOpen={setToasterOpen}
                            setToasterSeverity={setToasterSeverity}
                          />
                        </PopUpModal>
                        <PopUpModal
                          open={showAddPermissionModal}
                          onClose={() => {handleClosePermissionModal(createdRoleFormik)}}
                          isComponentEditable={false}
                          isEditableTrue={true}
                          title1='Role Name'
                          width='860px'
                        >
                          <AddPermission 
                            setShowAddPermissionModal={setShowAddPermissionModal}
                            roleList={roleList}
                            roleListHelpers={roleListHelpers}
                            permissionList={permissionList}
                            isEditing={true}
                            formik={formik}
                            setToasterMessage={setToasterMessage}
                            setToasterOpen={setToasterOpen}
                            setToasterSeverity={setToasterSeverity}
                            createdRoleFormik={createdRoleFormik}
                            setSelectedIndex={setSelectedIndex}
                            buildRolesAndPermissions={buildRolesAndPermissions}
                          />
                        </PopUpModal>
                      </>
                    )}
                  </Formik>
                </Box>  
                <Box position={'relative'} width={'100%'} >
                  <Box sx={styles.absoluteBox}>
                    <Grid container sx={styles.roleAndPersmissionParrentBox}>
                      {/* Roles component */}
                      <Grid xs={2.5} sx={styles.roleAndPermissionBox}>
                        <Typography variant='h6' component='h2'  sx={styles.allRolesLabel}>
                          All Roles
                        </Typography>
                        <Divider variant="fullWidth" sx={styles.horizontalDivider}/>
                        <List sx={{padding: '5px'}}>
                          {formik.values.roleList.map((role, idx) =>
                            <ListItemButton 
                              key={role.roleName}
                              selected={selectedIndex === idx}
                              onClick={() => {
                                setSelectedRole(role.roleName);
                                setSelectedIndex(idx);
                              }}
                              sx={styles.roleListItem}
                            >
                              <Grid justifyContent={'space-between'} container>
                                <Typography>
                                  {role.roleName}
                                </Typography>
                                <ArrowRight/>
                              </Grid>
                            </ListItemButton>
                          )}
                        </List>
                      </Grid>
                      {/* end of Roles component */}
                      
                      <Grid xs={9.44} sx={styles.roleAndPermissionBox}>
                        {/* action buttons  */}
                        <Box sx={styles.fixedActionButtons}>
                          <Grid container sx={{...styles.gridContainer}}>
                            <Button 
                              startIcon= {<EditOutlinedIcon sx={{...styles.actionsIcon, ...(isEditing && styles.disabled)}}/>}
                              aria-label='Edit button- Edit User Profile'
                              onClick={()=> {setIsEditing(true)}}
                              disabled={isEditing}
                              sx={{...(!canUpdateRole && styles.hidden), ...(selectedRole === 'Super Admin' && styles.hidden)}}
                            >
                              <Typography sx={{...styles.buttonText, ...(isEditing && styles.disabled)}}>
                                  Edit
                              </Typography>
                            </Button>
                            <Divider variant="middle" sx={{...styles.verticalDivider, ...(!canUpdateRole && styles.hidden), ...(selectedRole === 'Super Admin' && styles.hidden)}}/>
                            <Button 
                              startIcon= {<DeleteOutlineOutlinedIcon sx={styles.actionsIcon}/>}
                              aria-label='Delete button- Delete User Profile'
                              onClick={()=> {setIsModalOpen(true)}}
                              sx={{...(!canDeleteRole && styles.hidden), ...(selectedRole === 'Super Admin' && styles.hidden)}}
                            >
                              <Typography sx={styles.buttonText}>
                                  Delete
                              </Typography>
                            </Button>
                            <Box sx={{...styles.bottomActionsButtonContainer, ...(!canUpdateRole && styles.invisible)}}>
                              <Button
                                disabled={!isEditing}
                                variant='outlined'
                                sx={styles.cancelButton}
                                onClick={() => {handleCancel(formik)}}
                              >
                                Cancel
                              </Button>
                              <Button
                                disabled={!isEditing || !isDirty}
                                variant='contained'
                                type='submit'
                                sx={styles.saveButton}
                              >
                                Save
                              </Button>
                          </Box>
                          </Grid>
                        </Box>
                        <Box height={'65px'}/>
                        {/* end of action buttons */}
                        <PermissionBox 
                          permissionList={permissionList}
                          index={selectedIndex}
                          isEditing={isEditing}
                          formik={formik}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box> 
              </Box>
            </Form>
            
          )}
        </FieldArray>
      
      )}
      </Formik>     
    </>
  )
}

export default RolesAndPermission