import React, { useState, DragEvent, useContext } from "react";
import { Box, TableBody, TableRow, TableCell, TextField, Tooltip, Typography, IconButton, MenuItem, Select } from '@mui/material';
import { DeleteOutlined, DragIndicator } from '@mui/icons-material';
import { FieldArray, Field, getIn, FormikErrors, FormikTouched, FieldArrayRenderProps } from 'formik';
import ConfirmModal from "../../../components/modals/confirm-modal";
import { isObjectsEqual, parseBoolean, trimOnBlur } from '../../../utility/helper';
import { DraggableFormProps, DraggableRowProps, IFormikValue, IIneligibleCode } from '../../../interfaces/ineligibleCodeInterface';
import styles from './styles';
import { FIXED_CODES } from "../../../utility/constants";
import { LenderSettingsContext } from "../../../context/lenderSettingsContext";
import HelperTextComponent from "../../../components/common/helper-text-component";
import DisabledComponentsContainer from "../../../components/common/disabled-components-container";

// Headers for the table
const HEADERS = ['Priority', 'Ineligible Code', 'Description', 'Action'];

const INELIGIBLE_CODE_STATUSES = [
  { label: 'Active', value: 'true', },
  { label: 'Inactive', value: 'false', },
];

/**
 * Component that displays a row in a draggable table for Lender Ineligible Codes Table .
 * @param {DraggableRowProps} props Props for DraggableRow component.
 * @returns {JSX.Element} JSX element representing the DraggableRow component.
 */
const DraggableRow: React.FC<DraggableRowProps> = (props) => {
  const { formik, index, handleDelete, onDragStart, onDragEnd, onDragOver } = props;
  const {
    usedIneligibleCodes,
    canAddIneligible,
    canUpdateIneligible,
    canUpdateIneligiblePriority,
    canDeleteIneligible
  } = useContext(LenderSettingsContext);

  /**
   * This function gets the displayed error for a field.
   * @param errors Formik errors object.
   * @param touched Formik touched object.
   * @param name Field name.
   * @param index Index of the row.
   * @returns {object | null} Error object or null.
   */
  const getDisplayedError = (errors: FormikErrors<IFormikValue>, touched: FormikTouched<IFormikValue>, name: string, index: number) => {
    const fieldError = getIn(errors, `ineligibleCodes[${index}].${name}`);
    const isFieldTouched = getIn(touched, `ineligibleCodes[${index}].${name}`);

    if (fieldError && isFieldTouched) { return { error: true, helperText: <HelperTextComponent text={fieldError} />  }; }

    return null;
  };

  /**
   * This function checks if a code is editable.
   * @param ineligibleCodeIndex Index of the ineligible code.
   * @returns {boolean} True if code is editable, otherwise false.
   */
  const isCodeEditable = (ineligibleCodeIndex: number) => {
    const ineligibleCode = formik.values.ineligibleCodes[ineligibleCodeIndex].code;
    return !usedIneligibleCodes.includes(ineligibleCode) && !FIXED_CODES.includes(ineligibleCode);
  };

  /**
   * This function checks if a code is new.
   * @param ineligibleCodeIndex Index of the ineligible code.
   * @returns {boolean} True if code is new, otherwise false.
   */
  const isCodeNew = (ineligibleCodeIndex: number) => {
    return formik.values.ineligibleCodes[ineligibleCodeIndex].recordId === undefined;
  };

  /**
   * This function checks if the code is new and returns a boolean value for disabling a field.
   * @param index The index of the field.
   * @returns A boolean value if field is disabled.
   */
  const isFieldDisabled = (index: number) => {
    return isCodeNew(index) ? !canAddIneligible : !canUpdateIneligible;
  };

  /**
   * This function gets tooltip text for the delete button.
   * @param ineligibleCodeIndex Index of the ineligible code.
   * @returns {string} Tooltip text.
   */
  const getTooltipForDeleteButton = (ineligibleCodeIndex: number) => {
    const ineligibleCode = formik.values.ineligibleCodes[ineligibleCodeIndex].code;
    if(FIXED_CODES.includes(ineligibleCode)) { return 'Cannot Delete Default Ineligible Setting'; }
    if(usedIneligibleCodes.includes(ineligibleCode)) { return 'Cannot Delete Used Ineligible Setting'; }
    return 'Delete Ineligible Setting';
  };

  /**
   * This function checks if a code is active.
   * @param index Index of the ineligible code.
   * @returns {boolean} True if code is active, otherwise false.
   */
  const isActive = (index: number) => parseBoolean(formik.values.ineligibleCodes[index].visible);
  const displayedError = getDisplayedError(formik.errors, formik.touched, 'visible', index);

  return (
    <TableRow sx={styles.tableRow} key={index}>
      <TableCell sx={styles.tableCell}>
        <Typography
          tabIndex={0}
          align="center"
          sx={styles.typography}
        >
          {index + 1}
        </Typography>
      </TableCell>
      <TableCell sx={styles.tableCell}>
        <Box sx={styles.ineligibleCodeFieldBox}>
          <IconButton
            id='ineligible-code'
            draggable
            edge="end"
            aria-label='Drag and drop icon'
            onDragStart={(event) => onDragStart(event, index)}
            onDragEnd={() => onDragEnd()}
            onDragOver={() => onDragOver(index)}
            disabled={!canUpdateIneligiblePriority}
            data-testid={`drag-handler-${index}`}
          >
            <DragIndicator />
          </IconButton>
          <Field
            id='ineligible-code'
            aria-label='Ineligible Code Field'
            name={`ineligibleCodes[${index}].code`}
            as={TextField}
            sx={styles.textField}
            variant='outlined'
            tabIndex={!isCodeEditable(index) || isFieldDisabled(index) ? 0 : -1}
            onChange={(event: any) => { formik.handleChange(event); }}
            onBlur={(event: any) => {
              const trimmedValue = event.target.value.trim();
              formik.setFieldValue(`ineligibleCodes[${index}].code`, trimmedValue);
              formik.setFieldTouched(`ineligibleCodes[${index}].code`, true, true);
            
              trimmedValue === ''
                ? formik.setFieldError(`ineligibleCodes[${index}].code`, 'Ineligible Code is required')
                : formik.validateField(`ineligibleCodes[${index}].code`);
            }}
            disabled={!isCodeEditable(index) || isFieldDisabled(index)}
            inputProps={{ 'data-testid': `ineligible-code-${index}`, 'aria-label': 'Ineligible Code' }}
            {...getDisplayedError(formik.errors, formik.touched, 'code', index)}
            onDragEnd={() => onDragEnd()}
            onDragOver={() => onDragOver(index)}
          />
        </Box>
      </TableCell>
      <TableCell sx={styles.tableCell}>
        <Field
          id='description'
          aria-label='Ineligible Description Field'
          name={`ineligibleCodes[${index}].ineligibleDescription`}
          as={TextField}
          sx={styles.textField}
          variant='outlined'
          onChange={(event: any) => { formik.handleChange(event); }}
          onBlur={(event: any) => {
            const trimmedValue = event.target.value.trim();
            formik.setFieldValue(`ineligibleCodes[${index}].ineligibleDescription`, trimmedValue);
            formik.setFieldTouched(`ineligibleCodes[${index}].ineligibleDescription`, true, true);
        
            trimmedValue === ''
              ? formik.setFieldError(`ineligibleCodes[${index}].ineligibleDescription`, 'Description is required')
              : formik.validateField(`ineligibleCodes[${index}].ineligibleDescription`);
          }}
          inputProps={{ 'data-testid': `ineligible-description-${index}`, 'aria-label': 'Ineligible Description' }}
          {...getDisplayedError(formik.errors, formik.touched, 'ineligibleDescription', index)}
          onDragEnd={() => onDragEnd()}
          onDragOver={() => onDragOver(index)}
          disabled={isFieldDisabled(index)}
        />
      </TableCell>

      <TableCell sx={styles.tableCell}>
        <Field
          id={`status-${index}`}
          inputProps={{
            displayEmpty: true,
            'data-testid': `ineligible-status-${index}`
          }}
          name={`ineligibleCodes[${index}].visible`}
          as={Select}
          onChange={(event: React.ChangeEvent) => { formik.handleChange(event); }}
          sx={isActive(index) ? {...styles.active} : {...styles.dropdown, ...(formik.values.ineligibleCodes[index].visible === '' && styles.defaultValueColor)}}
          variant='outlined'
          {...getDisplayedError(formik.errors, formik.touched, 'visible', index)}
          disabled={isFieldDisabled(index)}
        >
          {formik.values.ineligibleCodes[index].visible === '' &&  
            <MenuItem value='' sx={styles.hidden} disabled>
              Select
            </MenuItem>
          }
          {
            INELIGIBLE_CODE_STATUSES.map((option) => (
              <MenuItem value={option.value} key={option.label}>
                {option.label}
              </MenuItem>
            ))
          }
        </Field>
        {displayedError?.helperText}
      </TableCell>
      <TableCell sx={{ ...styles.actionTableCell, ...styles.centerAlignedText }}>
        <Tooltip title={getTooltipForDeleteButton(index)}>
          <span>
            <DisabledComponentsContainer isDisabled={!isCodeEditable(index) || !canDeleteIneligible}>
              <IconButton
                onClick={() => handleDelete(formik.values, index)}
                sx={styles.deleteIconButton}
                data-testid={`delete-button-${index}`}
                color='primary'
                aria-label={!isCodeEditable(index) || !canDeleteIneligible ? 'delete icon button disabled' : 'delete icon'}
                disabled={!isCodeEditable(index) || !canDeleteIneligible}
              >
                <DeleteOutlined />
              </IconButton>
            </DisabledComponentsContainer>
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

/**
 * DraggableForm component displays a form with draggable rows for Lender Ineligible Codes Table.
 * @param {DraggableFormProps} props - Props for the DraggableForm component.
 * @returns {JSX.Element} JSX element representing the DraggableForm component.
 */
const DraggableForm: React.FC<DraggableFormProps> = (props) => {
  const { formik, allowAddingRow, addNewRow, handleDelete, handleConfirmDelete, deleteModal, setDeleteModal, initialIneligibles } = props;
  const { canAddIneligible } = useContext(LenderSettingsContext);
  const [draggedListItem, setDraggedListItem] = useState<IIneligibleCode | null>(null); 

  /**
   * This function handles the drag start event.
   * @param event Drag event.
   * @param index Index of the dragged item.
   */
  const handleDragStart = (event: DragEvent<HTMLButtonElement>, index: number) => {
    if (!event.currentTarget.parentElement || !event.currentTarget.parentNode?.parentElement) return;

    setDraggedListItem(formik.values.ineligibleCodes[index]);

    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/html', event.currentTarget.parentElement.outerHTML);
    event.dataTransfer.setDragImage(
      event.currentTarget.parentNode.parentElement,
      (event.currentTarget.parentNode.parentElement.clientWidth * 2) - 50,
      event.currentTarget.parentNode.parentElement.clientHeight
    );
  };

  /**
   * This function handles the drag end event.
   */
  const handleDragEnd = () => setDraggedListItem(null);

  /**
   * This function handles the drag over event.
   * @param hoveredIndex Index of the hovered item.
   */
  const handleDragOver = (hoveredIndex: number) => {
    const listItem = draggedListItem as IIneligibleCode;
    const hoveredItem = formik.values.ineligibleCodes[hoveredIndex];
    if (isObjectsEqual(listItem, hoveredItem)) { return; } // no change in items' order
    const rearrangedDraggedListItems = formik.values.ineligibleCodes.filter(item => !isObjectsEqual(item, listItem)); /* remove dragged item */
    rearrangedDraggedListItems.splice(hoveredIndex, 0, listItem); /* add dragged item back to the given index to be dropped */
    // start logic for swapping AC and DLQ if DLQ is after AC
    // determine if DLQ and AC is not in order 
    const acIndex = rearrangedDraggedListItems.findIndex((item => item.code === 'AC'));
    const dlqIndex = rearrangedDraggedListItems.findIndex((item => item.code === 'DLQ'));
    // check if both DLQ and AC exist in draggable list item
    if (![acIndex, dlqIndex].includes(-1)) {
      if (acIndex < dlqIndex) {
        // swapping with splice
        const dlqItem = rearrangedDraggedListItems.splice(dlqIndex, 1, rearrangedDraggedListItems[acIndex])[0];
        rearrangedDraggedListItems[acIndex] = dlqItem;
      }
    }
    // end logic for swapping AC and DLQ if DLQ is after AC
    formik.setValues({ ineligibleCodes: rearrangedDraggedListItems });
  }

  /**
   * This function is a callback function to add a new row to the form.
   * @param fieldArray Field array render props.
   * @returns {Function} Callback function to add a new row.
   */
  const addNewRowCallback = (fieldArray: FieldArrayRenderProps) => {
    return () => {
      addNewRow(fieldArray, formik.values);
    }
  }

  return (
    <FieldArray name='ineligibleCodes'>
      {(fieldArray) => (
        <TableBody sx={styles.tableBody}>
          {formik.values.ineligibleCodes.map((ineligibleCode, index) => {
            return (
              <DraggableRow
                initialIneligibles={initialIneligibles}
                formik={formik}
                index={index}
                key={ineligibleCode.recordId}
                handleDelete={handleDelete}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
              />
            );
          })}
          {/* rows that adds new form rows */}
          { (allowAddingRow && canAddIneligible) &&
            <TableRow sx={styles.tableRow} key={formik.values.ineligibleCodes.length}>
              {HEADERS.map((header, index) => (
                <TableCell sx={styles.tableCell} key={header}>
                  {index < HEADERS.length - 1 && index > 0 ? (
                  <Box sx={styles.ineligibleCodeFieldBox}>
                    { header === 'Ineligible Code' && (
                      <IconButton
                        edge="end"
                        aria-label='Drag and drop icon'
                        onFocus={addNewRowCallback(fieldArray)}
                      >
                        <DragIndicator />
                      </IconButton>
                    )}
                    <TextField
                      sx={styles.textField}
                      value=''
                      onClick={addNewRowCallback(fieldArray)}
                      onFocus={addNewRowCallback(fieldArray)}
                      onKeyDown={(e) => { if (e.key === 'Enter') { addNewRow(fieldArray, formik.values) } }}
                      inputProps={{ 'aria-label': 'Press enter to add new entry', 'data-testid': 'add-new-code-field' }}
                    />
                  </Box>
                  ) : null}
                </TableCell>
              ))}
            </TableRow>
          }
          <ConfirmModal
            open={deleteModal.isOpen}
            onClose={() => { setDeleteModal({ ...deleteModal, isOpen: false }); }}
            onConfirm={() => { handleConfirmDelete(formik.values.ineligibleCodes, fieldArray.remove); }}
            title={`Delete ${deleteModal.deleteName}`}
            description='Are you sure you want to delete this item?'
            errorButton
            yesButtonText='Delete'
            noButtonText='Cancel'
          />
        </TableBody>
      )}
    </FieldArray>
  );
};

export default DraggableForm;
