const styles = {
  dividerRow: {
    display: 'flex',
    width: '100%'
  },
  dividerBox: {
    width: '20%',
    paddingTop: '20px',
    paddingLeft: '8px',
    paddingRight: '8px'
  },
  divider: {
    borderBottomWidth: '4px'
  },
  dividerProgress : {
    borderColor: '#154A8A'
  }
};

export default styles;