import { useContext, useState } from 'react';
import { Field } from 'formik';
import { Box, InputAdornment, TextField } from '@mui/material';
import { NON_EXISTING_PERCENT } from '../../../../../utility/constants';
import { IIneligibleRuleOverride, IIneligibleSettingsContext } from '../../../../../interfaces/ineligibleSettingInterface';
import { IneligibleSettingsContext } from '../../../../../context/ineligibleSettingsContext';
import { getDisplayedError, PercentFormat } from '../../../ineligible-setting-details';
import { OverrideSettingsProps } from '../..';
import styles from '../../styles';

/**
 * Component responsible for rendering and handling the Cross Age settings for an override.
 * @param props The props for the component.
 * @returns The rendered CrossAgeSettings component.
 */
const CrossAgeSettings = ({idx, formik}: OverrideSettingsProps) => {
  const { selectedIneligibleIndex: ineligibleIndex } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const [isSelected, setIsSelected] = useState(false);

  /**
   * This function gets the selected cross age percentage for the current override.
   * @returns The selected cross age percentage or NON_EXISTING_PERCENT if not set.
   */
  const getSelectedIneligibleRuleOverrideCrossAgePercent = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRuleOverrides as IIneligibleRuleOverride[])[idx]?.crossAgePct ?? NON_EXISTING_PERCENT;

  /**
   * This function gets the Formik field name for the cross age percentage.
   * @returns The Formik field name for the cross age percentage.
   */
  const getFormikFieldNameForIneligibleRuleOverrideCrossAgePercent = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].crossAgePct`;

  /**
   * This function gets the input adornment based on whether the field is selected or the value is non-existing.
   * @returns The input adornment (percent sign) or null.
   */
  const getInputAdornment = () => {
    if (isSelected) { return null; }
    if (getSelectedIneligibleRuleOverrideCrossAgePercent() === NON_EXISTING_PERCENT) {
      return <InputAdornment position='end'>%</InputAdornment>;
    }
    return null;
  };

  /**
   * This function handles the change of the cross age percentage value.
   * @param event The input change or focus event.
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      formik.setFieldValue(getFormikFieldNameForIneligibleRuleOverrideCrossAgePercent(), NON_EXISTING_PERCENT);
      formik.setFieldError(getFormikFieldNameForIneligibleRuleOverrideCrossAgePercent(), `Cross Age is required`);
      return;
    }
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleOverrideCrossAgePercent(), parseFloat(event.target.value));
    formik.setFieldError(getFormikFieldNameForIneligibleRuleOverrideCrossAgePercent(), undefined);
  };

  return (
    <Box sx={styles.specialSettingsContainer}>
      <Field
        as={TextField}
        label='Cross Age'
        id={`ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].crossAgePct`}
        name={`ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].crossAgePct`}
        value={getSelectedIneligibleRuleOverrideCrossAgePercent() === NON_EXISTING_PERCENT ? '' : getSelectedIneligibleRuleOverrideCrossAgePercent()}
        size='small'
        type='number'
        sx={{ ...styles.textField, ...styles.numberAlignment }}
        fullWidth
        InputProps={{ endAdornment: getInputAdornment(), inputComponent: PercentFormat as any }}
        InputLabelProps={{ sx: styles.textFieldInputLabel }}
        onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => { setIsSelected(false); }}
        onFocus={(_event: React.FocusEvent<HTMLInputElement>) => setIsSelected(true)}
        {...getDisplayedError(formik, getFormikFieldNameForIneligibleRuleOverrideCrossAgePercent())}
      />
    </Box>
  );
}

export default CrossAgeSettings;
