import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Forbidden from '../../assets/images/403.png';
import LoanWatchLogo from '../../assets/images/logo.png';
import styles from './styles';
const ForbiddenPage = () => {
	const navigate = useNavigate();

  return (
    <Box>
      <Box sx={styles.pageNotFoundContainer}>
      <img src={Forbidden} alt='403 Image' aria-label='403 Image' width={389} tabIndex={0} />
      <img style={styles.p5} src={LoanWatchLogo} alt='Logo Image' aria-label='Logo Image' width={182} tabIndex={1} />
      <Typography
        variant='h1'
        color='primary'
        sx={styles.bold}
      >
        Restricted Access
      </Typography>
      <Typography
        variant='subtitle1'
        color='primary'
      >
        You lack permission to access this page.
      </Typography>
      <Button
        variant='contained'
        onClick={() => navigate(-2)} // -2 to skip the restricted page
      >
        Go Back
      </Button>
      </Box>
    </Box>
  )
}

export default ForbiddenPage;