import { Box } from '@mui/material';
import IneligibleAdjustmentsPanelHeader from '../panel-header';
import IneligibleAdjustmentsTable from '../table';

const InventoryPanel: React.FC = () => {
  return (
    <Box>
      <IneligibleAdjustmentsPanelHeader ineligibleType='Inventory' />
      <IneligibleAdjustmentsTable ineligibleType='Inventory' />
    </Box>
  );
};

export default InventoryPanel;
