const styles = {
  breadcrumb: {
    color: '#707070',
    textDecoration: 'none',
    pt: '24.04px',
    pl: '28.5px',
    pb: '14px',
  },

  linkText: {
    color: '#154A8A',
    fontSize: '12px',
  },

  selectedText: {
    color: '#707070',
    fontSize: '12px',
  }
};

export default styles;
