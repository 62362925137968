import colors from "../../utility/colors";

const styles = {
  buttonsContainer: {
    marginTop: '0.5rem'
  },
  cardButtonCommon: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    textAlign: 'left',
    overflow: 'hidden',
    fontSize: '18px',
    color: '#154A8A',
    height: '4rem',
    paddingLeft: '6.25rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '3.5% 50%',
    backgroundSize: '4rem',
  },
  clientContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginY: '0.9rem',
  },
  clientDropdownBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  commonDropdownBox: {
    maxWidth: '17rem',
    marginBottom: '1.5rem',
  },
  headerContainer: {
    backgroundColor: '#FEFEFE',
    paddingX: '1.5rem',
    paddingY: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.5rem'
  },
  headerTitle: {
    color: colors.PRIMARY_DARK,
    fontSize: '18px',
    fontWeight: 'bold',
  },
  pageContainer: {
    paddingX: '1.5rem',
  },
  sectionContainer: {
    marginTop: '1.5rem'
  },
};
export default styles;