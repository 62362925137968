const styles = {
  tableContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
  },

  table: {
    display: 'flex',
  },

  tableHead: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    background: '#F2F7F8',
    paddingBottom: '33px',
  },

  tableBody: {
    display: 'flex',
    overflowX: 'scroll',
    paddingBottom: '16.5px',
  },

  tableRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },

  tableHeadCell: {
    minWidth: 280,
    margin: 0,
    padding: '8px 8px 0',
    border: 'none',
    textAlign: 'right',
    lineHeight: '21px',
    fontWeight: '100',
  },

  tableCell: {
    minWidth: 20,
    margin: 0,
    padding: '8px 0 0 8px',
    border: 'none',
    textAlign: 'right',
    lineHeight: '21px',
  },
};

export default styles;
