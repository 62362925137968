const styles = {
  panelHeaderBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  comboBoxGrid: {
    width: '50%',
  },
  clientComboBox: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  labelStyle: {
    display: 'block',
    fontSize: '14px',
  },
  collateralNameDropdownText: {
    fontSize:'14px'
  },
  totalAmountContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  labelTotalAmount: {
    fontSize: '14px',
    fontWeight: '600',
    marginRight: '5px',
  },
  valueTotalAmount: {
    fontSize: '14px',
  },
};

export default styles;
