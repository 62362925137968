import { FC } from 'react';
import { Typography, Box, SxProps, Theme } from '@mui/material';
import styles from './styles';

interface INoDataPlaceholderProps {
  messageText?: string;
  messageContainerStyle?: SxProps<Theme>;
  messageTextStyle?: SxProps<Theme>;
};

const NoDataPlaceholder: FC<INoDataPlaceholderProps> = (props) => {
  const getStyles = (defaultStyles: SxProps<Theme>, propStyles: SxProps<Theme> | undefined) => {
    if (propStyles === undefined) { return defaultStyles; }
    const mergedStyles = { ...defaultStyles, ...propStyles};
    return mergedStyles;
  };

  return (
    <Box sx={getStyles(styles.messageContainer, props.messageContainerStyle)}>
      <Typography tabIndex={0} sx={getStyles(styles.messageText, props.messageTextStyle)}>
        {props.messageText ?? 'No data available'}
      </Typography>
    </Box>
  );
};

export default NoDataPlaceholder;
