import { Box, Button, CircularProgress, Grid, SxProps, TextField, Theme, Typography } from "@mui/material"
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { Dispatch, SetStateAction, useCallback, useState, forwardRef, FC, useContext } from "react";
import ConfirmModal from "../../../components/modals/confirm-modal";
import PopUpModal from "../../../components/modals/pop-up-modal";
import { checkUserPermissions, getLocalStorageItem, trimOnBlur } from "../../../utility/helper";
import parentSchema from "../../../schemas/parentSchema";
import NumberFormat, { InputAttributes } from "react-number-format";
import { IARVendor, ICustomProps } from "../../../interfaces";
import HelperTextComponent from "../../../components/common/helper-text-component";
import DisabledComponentsContainer from "../../../components/common/disabled-components-container";
import axiosInstance from "../../../service/axiosInstance";
import { POST } from "../../../utility/constants";
import vendorSchema from "../../../schemas/vendorSchema";
import styles from "./styles";
import { SelectedClientContext } from "../../../context/selectedClientContext";
import { arVendorsAPI } from "../../../service/api";

export interface IProps {
  open                : boolean;
  setOpen             : Dispatch<SetStateAction<boolean>>;
  newParentId?        : number;
  setNewParentId?     : React.Dispatch<SetStateAction<number>>;
  onSettings?         : boolean;
  isParent?           : boolean;
  onSave?             : (values: IARVendorFormValues) => void;
  vendorList          : IARVendor[];
  vendor?             : IARVendor;
  handleSuccess?      : (values: IARVendorFormValues) => void;
  handleFailed?       : (error: any) => void;
  permission?         : string;
  handleNoPermission? : () => void;
}

export interface IARVendorFormValues {
  custName?           : string;
  custSrcId?          : string;
  vendorName?         : string;
  vendorSrcId?        : string;
  creditLimit?        : number;
  dbRating?           : string;
  country?            : string;
  address1?           : string;
  address2?           : string;
  city?               : string;
  state?              : string;
  zipCode?            : string;
  phoneNumber?        : string;
  description?        : string;
}

/**
 * This reference formats a text field to a whole number format.
 */
const WholeNumberFormat = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({ target: { value: values.value, name: other.name, }, });
      }}
      type='tel'
      decimalScale={0}
      allowNegative={false}
      isAllowed={values => values.floatValue === undefined || values.floatValue <= 9999999999}
    />
  );
});

/**
 * This component renders a form modal to create/edit/view a new customer.
 * 
 * @param param IProps
 */
const VendorModal: FC<IProps> = (props) => {
  const {selectedClient}                       = useContext(SelectedClientContext);
  const [showPrompt, setShowPrompt]            = useState<boolean>(false);
  const [isDirty, setIsDirty]                  = useState<boolean>(false);
  const [edit, setEdit]                        = useState<boolean>(Boolean(props.onSave));
  const [isValidParent, setIsValidParent]      = useState<boolean>(true);
  const [isValidParentId, setIsValidParentId]  = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting]        = useState<boolean>(false);

  const initialValues: IARVendorFormValues = {
    custName            : props?.vendor?.custName               ?? undefined,
    custSrcId           : props?.vendor?.custSrcId              ?? undefined,
    vendorName          : props?.vendor?.vendorName             ?? undefined,
    vendorSrcId         : props?.vendor?.vendorSrcId            ?? undefined,
    country             : props?.vendor?.vendorCountry          ?? undefined,
    address1            : props?.vendor?.vendorAddress1         ?? undefined,
    address2            : props?.vendor?.vendorAddress2         ?? undefined,
    city                : props?.vendor?.vendorCity             ?? undefined,
    state               : props?.vendor?.vendorState            ?? undefined,
    zipCode             : props?.vendor?.vendorPostalCode       ?? undefined,
    phoneNumber         : props?.vendor?.vendorPhone            ?? undefined,
    description         : props?.vendor?.vendorDescription      ?? undefined,
  };

  const formikRef = (node: FormikProps<IARVendorFormValues>) => {
    if (node === null) { return; }
    setIsDirty(node.dirty);
  };

  /**
   * This useCallback sets the IsDirty state to true
   */
  const handleFormChange  = useCallback(() => setIsDirty(true), []);

  /**
   * This useCallback closes the showPrompt.
   */
  const handleClosePrompt = useCallback(() => setShowPrompt(false), []);

  /**
   * This function resets the form.
   * 
   * @param formik Form Values
   */
  const handleReset = (formik: FormikProps<IARVendorFormValues>) => {
    formik.resetForm();
    setEdit(false);
    setIsDirty(false);
  };

  /**
   * This function closes the form modal.
   * 
   * @param formik Form Values
   */
  const handleClose = (formik: FormikProps<IARVendorFormValues>) => {
    handleReset(formik);
    props.setOpen(false);
    setShowPrompt(false);
  }

  /**
   * This function checks if the form is dirty, if its dirty the warning prompt will be render. Else, the modal will close.
   */
  const handleDetailClose = () => {
    if (isSubmitting) {
      return;
    } else if (isDirty) {
      setShowPrompt(true);
    } else {
      props.setOpen(false);
      setEdit(Boolean(props.onSave));
    }
  }

  /**
   * This function generate an onClose funtion props.
   * 
   * @param formik Form values
   * @returns A props that has the handleClose funtion.
   */
  const getOnClose = (formik: FormikProps<IARVendorFormValues>) => {
    return { onClose: () => handleClose(formik) };
  }

  /**
   * This function creates a new Parent AR Customer and add it on the parentList locally.
   * 
   * @param values Form Values
   */
  const handleSubmit = (values: IARVendorFormValues) => {
    setIsSubmitting(true);
    
    if(props.onSave){
      props.onSave(values)
      handleSuccess(values)
      setIsSubmitting(false);
      return
    }
    axiosInstance.request({
      url: arVendorsAPI.MAIN_ENDPOINT,
      method: POST,
      data: {
        ...props.vendor,
        recordId                : props.vendor?.recordId,
        vendorName              : values.vendorName,
        vendorSrcId             : values.vendorSrcId,
        arCollateralId          : props?.vendor?.arCollateralId,
        borrowerId              : props?.vendor?.borrowerId,
        vendorCountry           : values.country,
        vendorAddress1          : values.address1,
        vendorAddress2          : values.address2,
        vendorCity              : values.city,
        vendorState             : values.state,
        vendorPostalCode        : values.zipCode,
        vendorPhone             : values.phoneNumber,
        vendorDescription       : values.description,
      }
    }).then(() => {
      handleSuccess(values)
    }).catch((error) => {
      props.handleFailed && props.handleFailed(error)
      console.log('UPDATE AR VENDOR: ', error)
    }).finally(() => setIsSubmitting(false));
  }

  /**
   * This function handles the business logics after successfully creating a parent.
   */
  const handleSuccess = (values: IARVendorFormValues) => {
    setEdit(false);
    setIsDirty(false);
    props.setOpen(false)
    props.handleSuccess && props.handleSuccess(values)
  };

  /**
   * This function dynamically renders a group of button components base on the form state.
   * 
   * @param formik Form state.
   * @returns A dynamic group of button component.
   */
  const getButtons = (formik: FormikProps<IARVendorFormValues>) => {
      return (
        <Box sx={styles.buttonGroup}>
          <Button
            data-testid={`cancel-button`}
            variant='outlined'
            disableElevation
            onClick={handleDetailClose}
            sx={styles.cancelButton}
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
          {formik.isSubmitting ? 
            <DisabledComponentsContainer isDisabled={formik.isSubmitting}>
              <Button
                data-testid={`submit-button`}
                variant='contained'
                type='submit'
                disableElevation
                disabled={formik.isSubmitting}
                aria-label={'Saving changes'}
                sx={styles.saveButton}
              >
                <CircularProgress size={15} />
              </Button>
            </DisabledComponentsContainer>
          :
            <DisabledComponentsContainer isDisabled={!(formik.dirty && formik.isValid && isValidParent && isValidParentId)}>
              <Button
                data-testid={`submit-button`}
                variant='contained'
                type='submit'
                disableElevation
                disabled={!(formik.dirty && formik.isValid && isValidParent && isValidParentId)}
                aria-label={!(formik.dirty && formik.isValid && isValidParent && isValidParentId) ? 'Save changes button disabled' : 'Save changes'}
                sx={styles.saveButton}
              >
                Save Changes
              </Button>
            </DisabledComponentsContainer>}
        </Box>
      );
  };

  /**
   * This function dynamically set the props of a textfield component.
   * 
   * @param name Name of the textfield.
   * @param style Style property of the textfield.
   * @param touched Touched state from the Form Value of the textfield.
   * @param errors Errors from the Form value of the textfield.
   * @param onBlur A function to for the onBlur event of the textfield.
   * @param InputProps The props fro the textField input element.
   * @returns A set of TextField props.
   */
  const getTextFieldProps = (name: string,
    style: SxProps<Theme>,
    touched?:boolean,
    errors?: string,
    onBlur?: (e: any) => void,
    InputProps?: any,
    SelectProps?: any
    ) => {
      return {
      id: `text-field-${name}`,
      name,
      size: 'small' as const,
      error: touched && Boolean(errors),
      helperText: touched && errors,
      InputProps,
      SelectProps,
      onClick: edit ? undefined : () => setEdit(true),
      onBlur: onBlur ?? undefined,
      sx: style,
      disabled: !edit
      }
  };

  /**
   * This function is used to generate spcific error message for specified field
   * 
   * @param formik Form values
   * @param value The formik fieldname of the textfield
   * @param message The error message for the given textfield
   * @returns An error message for a specified field
   */
  const getHelperText = (formik: FormikProps<IARVendorFormValues>, value: string, message: string, field?: 'vendorName' | 'vendorSrcId') => {
    const errorText = formik.touched[value] && (formik.errors[value]);
  
    return errorText && <HelperTextComponent text={errorText}/>;
  }

  /**
   * This function is used to check the current user's permission before performing a certain functionality.
   * 
   * @param func The function user want to execute.
   * @param permission The permission that the user need to execute the function.
   * @param args The aruements need for the function to run, if any.
   * @returns A toaster if the user has no permission.
   */  
  const checkPermission = async (values: IARVendorFormValues) => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), props.permission!)
    if(isPermitted){
      handleSubmit(values)
      return
    }

    props.handleNoPermission && props.handleNoPermission()
  }


  return (
    <PopUpModal
        open={props.open}
        onClose={handleDetailClose}
        isComponentEditable={!props.onSave}
        handleEditClick={() => setEdit(true)}
        isEditableTrue={edit}
        isNotDeletable={true}
        title1={props.onSettings? 'Edit Vendor' : 'Create Vendor'}
        title2={'View Vendor' }
        width='1070px'
        isCloseDisabled={isSubmitting}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={vendorSchema}
          onSubmit={props.permission? checkPermission : handleSubmit}
          innerRef={formikRef}
        >
          {(formik) => (
            <Form onChange={handleFormChange}>
              <Grid container marginTop='0.5rem'>
                <Grid container rowSpacing='2rem' sx={styles.settingsContainer}>
                  <Grid item>
                    <Typography variant='body1' sx={styles.contactTitle}>
                      VENDOR INFORMATION
                    </Typography>
                    <Box sx={styles.inputContainer}>
                        <Typography component='label' htmlFor='text-field-customer-name' sx={styles.label}>
                          Customer Name
                          {!props.onSettings &&
                          <span style={styles.asterisk}> *</span>
                          }
                        </Typography>
                        <TextField
                          id="text-field-customer-name"
                          name='custName'
                          value={formik.values.custName}
                          disabled
                          sx={styles.textfieldString}
                          size="small"
                          inputProps={{
                            'aria-label': 'Customer Name',
                            'aria-labelledby': 'custName',
                            'data-testid': 'text-field-cust-name',
                          }}
                        />
                    </Box>
                    <Box sx={styles.inputContainer}>
                        <Typography component='label' htmlFor='text-field-cust-id' sx={styles.label}>
                          Customer ID
                          {!props.onSettings &&
                          <span style={styles.asterisk}> *</span>
                          }
                        </Typography>
                        <TextField
                          id="text-field-cust-id"
                          name='custSrcId'
                          value={formik.values.custSrcId}
                          disabled
                          sx={styles.textfieldString}
                          size="small"
                          inputProps={{
                            'aria-label': 'Parent ID',
                            'aria-labelledby': 'custSrcId',
                            'data-testid': 'text-field-cust-id',
                          }}
                        />
                    </Box>
                    {props.onSettings &&
                      <>
                        <Box sx={styles.inputContainer}>
                            <Typography component='label' htmlFor='text-field-customer-name' sx={styles.label}>
                              Vendor Name<span style={styles.asterisk}> *</span>
                            </Typography>
                            <TextField
                              id="text-field-vendor-name"
                              name='vendorName'
                              value={formik.values.vendorName}
                              onChange={(e) => {
                                formik.handleChange(e)
                              }}
                              onBlur={(e) => {
                                trimOnBlur(e, formik, 'vendorName', formik.values.vendorName);
                                formik.handleBlur(e);
                              }}
                              error={formik.touched.custName && (Boolean(formik.errors.custName) || !isValidParent)}
                              helperText={getHelperText(formik, 'vendorName', 'Vendor Name should be unique', 'vendorName')}
                              sx={styles.textfieldString}
                              size="small"
                              inputProps={{
                                'aria-label': 'Vendor Name',
                                'aria-labelledby': 'vendorName',
                                'data-testid': 'text-field-vendor-name',
                              }}
                              disabled={!edit}
                            />
                        </Box>
                        <Box sx={styles.inputContainer}>
                            <Typography component='label' htmlFor='text-field-vendor-id' sx={styles.label}>
                              Vendor ID<span style={styles.asterisk}> *</span>
                            </Typography>
                            <TextField
                              id="text-field-vendor-id"
                              name='vendorSrcId'
                              value={formik.values.vendorSrcId}
                              onChange={(e) => {
                                formik.handleChange(e)
                              }}
                              onBlur={(e) => {
                                trimOnBlur(e, formik, 'vendorSrcId', formik.values.vendorSrcId);
                                formik.handleBlur(e);
                              }}
                              error={formik.touched.custSrcId && (Boolean(formik.errors.custSrcId) || !isValidParentId)}
                              helperText={getHelperText(formik, 'vendorSrcId', 'Vendor ID should be unique', 'vendorSrcId')}
                              sx={styles.textfieldString}
                              size="small"
                              inputProps={{
                                'aria-label': 'Vendor ID',
                                'aria-labelledby': 'vendorSrcId',
                                'data-testid': 'text-field-vendor-id',
                              }}
                              disabled={!edit}                              
                            />
                        </Box>
                      </>
                    }
                  </Grid>
                  <Grid item sx={styles.contactInfoContainer}>
                    <Typography variant='body1' sx={styles.contactTitle}>
                      CONTACT INFORMATION:
                    </Typography>
                    <Box sx={styles.contactInformation}>
                      {/* country */}
                      <Box sx={styles.contactInputContainer}>
                        <Typography component='label' htmlFor={`text-field-state`} sx={styles.contactLabel}>
                          Country
                        </Typography>
                        <TextField
                          {...getTextFieldProps(
                            'country',
                            styles.textFieldCommon,
                            formik.touched.country,
                            formik.errors.country,
                            (e: any) => trimOnBlur(e, formik, 'country', formik.values.country))
                          }
                          inputProps={{ 'data-testid': 'text-field-country' }}
                          value={formik.values.country}
                          onChange={formik.handleChange}
                        />
                      </Box>
                      {/* Address 1 */}
                      <Box sx={styles.contactInputContainer}>
                        <Typography component='label' htmlFor={`text-field-address1`} sx={styles.contactLabel}>
                          Address 1
                        </Typography>
                        <TextField
                          {...getTextFieldProps(
                            'address1',
                            styles.textFieldCommon,
                            formik.touched.address1,
                            formik.errors.address1,
                            (e: any) => trimOnBlur(e, formik, 'address1', formik.values.address1))
                          }
                          inputProps={{ 'data-testid': 'text-field-address1' }}
                          value={formik.values.address1}
                          onChange={formik.handleChange}
                        />
                      </Box>                      
                      <Box sx={styles.contactInputContainer}>
                        <Typography component='label' htmlFor={`text-field-parent-address2`} sx={styles.contactLabel}>
                          Address 2
                        </Typography>
                        <TextField
                          {...getTextFieldProps(
                            'address2',
                            styles.textFieldCommon,
                            formik.touched.address2,
                            formik.errors.address2,
                            (e: any) => trimOnBlur(e, formik, 'address2', formik.values.address2))
                          }
                          inputProps={{ 'data-testid': 'text-field-address2' }}
                          value={formik.values.address2}
                          onChange={formik.handleChange}
                        />
                      </Box>                      
                      <Box sx={styles.contactInputContainer}>
                        <Typography component='label' htmlFor={`text-field-city`} sx={styles.contactLabel}>
                          City
                        </Typography>
                        <TextField
                          {...getTextFieldProps(
                            'city',
                            styles.textFieldCommon,
                            formik.touched.city,
                            formik.errors.city,
                            (e: any) => trimOnBlur(e, formik, 'city', formik.values.city))
                          }
                          inputProps={{ 'data-testid': 'text-field-city' }}
                          value={formik.values.city}
                          onChange={formik.handleChange}
                        />
                      </Box>                      
                      <Box sx={styles.stateAndZipContainer}>
                        {/* state */}
                        <Box sx={styles.contactInputContainer}>
                          <Typography component='label' htmlFor={`text-field-state`} sx={styles.contactLabel}>
                            State
                          </Typography>
                          <TextField
                            {...getTextFieldProps(
                              'state',
                              styles.textFieldSmall,
                              formik.touched.state,
                              formik.errors.state,
                              (e: any) => trimOnBlur(e, formik, 'state', formik.values.state))
                            }
                            inputProps={{ 'data-testid': 'text-field-state' }}
                            value={formik.values.state}
                            onChange={formik.handleChange}
                          />
                        </Box>
                        {/* zip code */}
                        <Box sx={styles.contactInputContainer}>
                          <Typography component='label' htmlFor={`text-field-zipCode`} sx={styles.contactLabel}>
                            Zip Code
                          </Typography>
                          <TextField
                            {...getTextFieldProps(
                              'zipCode',
                              styles.textFieldSmall,
                              formik.touched.zipCode,
                              formik.errors.zipCode,
                              (e: any) => trimOnBlur(e, formik, 'zipCode', formik.values.zipCode))
                            }
                            inputProps={{ 'data-testid': 'text-field-zipCode' }}
                            value={formik.values.zipCode}
                            onChange={formik.handleChange}
                          />                          
                        </Box>
                      </Box>
                      {/* Phone number */}
                      <Box sx={styles.contactInputContainer}>
                        <Typography component='label' htmlFor={`text-field-phoneNumber`} sx={styles.label}>
                          Phone Number
                        </Typography>
                        <TextField
                          {...getTextFieldProps(
                            'phoneNumber',
                            styles.textFieldCommon,
                            formik.touched.phoneNumber,
                            formik.errors.phoneNumber,
                            (e: any) => trimOnBlur(e, formik, 'phoneNumber', formik.values.phoneNumber),
                            {inputComponent: WholeNumberFormat as any}
                            )
                          }
                          inputProps={{ 'data-testid': 'text-field-phoneNumber' }}
                          value={formik.values.phoneNumber}
                          onChange={formik.handleChange}
                        />
                      </Box>
                      <Box sx={styles.contactInputContainer}>
                        <Typography component='label' htmlFor={`text-field-description`} sx={styles.label}>
                          Description
                        </Typography>
                        <TextField
                          {...getTextFieldProps(
                            'description',
                            styles.textFieldCommon,
                            formik.touched.description,
                            formik.errors.description,
                            (e: any) => trimOnBlur(e, formik, 'description', formik.values.description))
                          }
                          inputProps={{ 'data-testid': 'text-field-description' }}
                          value={formik.values.description}
                          onChange={formik.handleChange}
                        />
                      </Box>                      
                    </Box>               
                </Grid>
              </Grid>
              <Grid container justifyContent='flex-end'>
                {getButtons(formik)}
              </Grid>
            </Grid>
            <ConfirmModal
              open={showPrompt}
              {...getOnClose(formik)}
              onConfirm={handleClosePrompt}
              onButtonClose={handleClosePrompt}
              promptChecker
              title={'Confirm Navigation'}
              description={'You have unsaved changes. Are you sure you want to leave this page?'}
              yesButtonText={'Keep Editing'}
              noButtonText={'Discard Changes'}
              confirmOnly
            />
          </Form>
        )}
      </Formik>
    </PopUpModal>
  )
}

export default VendorModal