import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import styles from './styles';
import ReportsProvider from '../../../context/reportsContext';
import BorrowingBaseReportProvider from '../../../context/borrowingBaseReportContext';
import BorrowingBaseReportTopContainer from '../../../components/reports/borrowing-base/top-container';
import BorrowingBaseReportHeading from '../../../components/reports/borrowing-base/report-heading';
import BorrowingBaseReportGridDetails from '../../../components/reports/borrowing-base/grid-details';
import { checkUserPermissions, getLocalStorageItem } from '../../../utility/helper';
import { PERMISSIONS } from '../../../utility/constants';

const BorrowingBaseReport = () => {
  const [canViewBBReport, setCanViewBBReport] = useState<boolean>(false);

/**
 * This useEffect hook to request permission when the component is mounted.
 */
  useEffect(() => {
    getPermission()
  }, [])

/**
 * This function asynchronously checks if the user has permission to view the BB report.
 *
 * Retrieves the user's ID from local storage and checks if they have the `VIEW_BB_REPORT`
 * permission. Updates the state to indicate whether the user can view the report.
 */
  const getPermission = async () => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_BB_REPORT)
    setCanViewBBReport(isPermitted ?? false);
  }

  return (
    <Box sx={{...styles.pageContainer, ...(!canViewBBReport && styles.hidden)}}>
      <ReportsProvider>
        <BorrowingBaseReportProvider>
          <BorrowingBaseReportTopContainer />
          <BorrowingBaseReportHeading />
          <BorrowingBaseReportGridDetails />
        </BorrowingBaseReportProvider>
      </ReportsProvider>
    </Box>
  );
}

export default BorrowingBaseReport;
