import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import styles from '../styles';

interface IInlineErrorProps {
  errorMessage?: string | null
}

const InlineError = React.forwardRef<HTMLDivElement, IInlineErrorProps>(
  (props, ref) => {
    useEffect(() => {
      if (ref && 'current' in ref && ref.current) {
        ref.current.focus();
      }
    }, [props?.errorMessage]);
    
    return (
      <Box>
        <Typography tabIndex={0} ref={ref} role="alert" sx={styles.errorMessage}>
          {props?.errorMessage}
        </Typography>
      </Box>
    );
  }
);

export default InlineError;
