import colors from "../../../../../../../../utility/colors"

const styles = {
  note: {
    color: '#154A8A',
  },
  fontNote: {
    fontSize: '0.875rem',
    marginBottom: '0.5rem'
  },
  requiredNote: {
    color: '#707070',
    fontSize: '0.875rem',
  },
  requiredNoteHighlight: {
    color: '#000000',
    fontSize: '0.875rem',
  },
  hidden: {
    display: 'none'
  },
  info: {
    border: '1px solid #010953',
    backgroundColor: '#D0DBE8',
    color: '#010953',
    marginTop: '8px',
    marginBottom: '8px',
    '.MuiAlert-icon': {
      color: '#010953',
      fontSize: '18px',
    },
    '.MuiAlert-message': {
      fontSize: '12px',
    },
  },
  alert: {
    marginTop: '8px',
    marginBottom: '8px',
    '.MuiAlert-icon': {
      fontSize: '18px',
    },
    '.MuiAlert-message': {
      fontSize: '12px',
    },
    border: `1px solid ${colors.ERROR_LIGHT}`
  },
  checkCircleStyle:{
    color: '#154A8A',
    fontSize: 'smaller'
  },
  checkBoxAmt2: {
    padding: '0.3rem',
    '& .MuiSvgIcon-root': {
      fontSize: '0.875rem'
    },
  },
  checkBoxAmt2Label: {
    fontSize: '0.875rem'
  },
  checkBoxAmt2Container: {
    paddingLeft: '0.3rem'
  },
  circleOutlinedStyle: {
    fontSize: 'smaller'
  },
  requiredFieldsStyles: {
    display: 'flex',
    columnGap: '15px'
  },
  noteContainer: {
    paddingX: '1rem',
    paddingY: '1.5rem', 
    boxShadow: `0px 3px 6px #00000029`,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  sheetNameDropdown: {
    '& .MuiOutlinedInput-root': {
      fontSize: '0.875rem',
    },
    width: '18rem',
  },
  sheetNameDropdownList: {
    fontSize: '0.875rem',
    width: '100%',
  },
  dropdownSkeleton: {
    width: '18rem',
    height: '2.3rem'
  },
  emptyContainer: {
    padding: '1rem',
    borderRadius: '3px',
    border: '1px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verbiage: {
    fontSize: '14px',
    textAlign: 'center'
  },
  gridField: {
    minWidth        : '171.5px',
    maxWidth        : '183.5px',
    height          : '30px',
    fontSize        : '14px',
    '& .MuiOutlinedInput-root': {
      width         : '202.95px',
      height        : '30px',
      backgroundColor: 'white',
      fontSize      : '14px',
    },
    '& .Mui-error': {
      fontSize      : '11px',
      marginTop     : '1px',
      marginX       : '0',
    }
  },
  dropdownDefault: {
    '& .MuiOutlinedInput-input': {
      width         : '161.5px',
      paddingY      : '0',
      textAlign     : 'left',
      color         : '#949494'
    },
  },
  gridMenuItem: {
    fontSize        : '14px',
  },
  addedFieldsContainer: {
    display: 'flex',
    justifyContent: 'left',
    position: 'relative',
  },
  addedFieldsDivider: {
    width: '100%',
    bottom: '50%',
    backgroundColor: '#010953',
    borderBottomWidth: 0.1,
  },
  addedFieldsText: {
    backgroundColor: colors.WHITE,
    marginX: '0.25rem',
    zIndex: 1,
    '& .MuiTypography-root': {
      fontSize: '10px',
    },
  },
  deleteIcon: {
    paddingY: 0,
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
  addNewFieldButton: {
    borderTop: '1px solid #707070',
    fontSize: '14px',
  },
  tableRow: {
    '&&:nth-of-type(even)': {
      backgroundColor: '#F7F7F7',
    },
    ':hover': {
      cursor        : 'pointer',
    },
  },
  tableCell: {
    border          : 0,
    height          : '2rem',
  },
  tableHead: {
		backgroundColor : '#F7F7F7',
		color           : 'inherit',
		fontWeight      : 'bold',
		whiteSpace      : 'nowrap',
		border          : 0,
		height          : '2.5rem',
	},
  tableHeaderText: {
    '& .MuiFormLabel-asterisk': {
      color         : '#db3131',
    },
    '&.MuiFormLabel-root': {
      fontWeight    : 'bold',
      fontSize      : '14px',
      color         : 'black',
    },
  },
  comboBoxStyle: {
    display         : 'flex',
    justifyContent : 'space-evenly',
    marginTop       : '0.8rem',
  },
  gridContainer: {
    maxWidth: '1232px',
  },
  paper: {
    width           : "100%", 
    overflow        : "hidden",
  },
  valuesTableContainer: {
    maxHeight: '17.6rem',
    maxWidth: '1232px',
  },
  bottomActionsButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: '10px',
    width: '100%',
  },
  saveAndCancelButtons: {
    display: 'flex'
  },
  cancelButton: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    width: '82px',
  },
  saveButton: {
    height: '31px',
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },
  buttonBack: {
    color: colors.PRIMARY_LIGHT,
    padding: 0,
    fontSize: '14px',
    cursor: 'pointer',
  },
  leftAlignedText: {
    textAlign       : 'start',
  },
  clearIconButton: {
    padding: '0.3rem',
    marginRight: '0.3rem',
  },
  clearIcon: {
    fontSize: '14px'
  },
}

export default styles