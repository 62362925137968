import { useContext } from 'react';
import { Box } from '@mui/material';
import { NON_EXISTING } from '../../../../../utility/constants';
import { IIneligibleRuleOverride, IIneligibleSettingsContext, IIneligibleSettingsPermissions } from '../../../../../interfaces/ineligibleSettingInterface';
import { IneligibleSettingsContext } from '../../../../../context/ineligibleSettingsContext';
import ComboBox from '../../../combo-box';
import { OverrideSettingsProps } from '../..';
import styles from '../../styles';

/**
 * Component responsible for rendering and handling the Delinquent settings for an override.
 * @param props The props for the component.
 * @returns The rendered DelinquentSettings component.
 */
const DelinquentSettings = ({idx, formik}: OverrideSettingsProps) => {
  const { selectedIneligibleIndex: ineligibleIndex, permissions, hasPaymentTerms } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  /**
   * This function gets the selected DPID (Days Past Invoice Date) value for the current override.
   * @returns The selected DPID value or NON_EXISTING if not set.
   */
  const getSelectedIneligibleRuleOverrideDPID = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRuleOverrides as IIneligibleRuleOverride[])[idx].dpid ?? NON_EXISTING;

  /**
   * This function gets the selected DPDD (Days Past Due Date) value for the current override.
   * @returns The selected DPDD value or NON_EXISTING if not set.
   */
  const getSelectedIneligibleRuleOverrideDPDD = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRuleOverrides as IIneligibleRuleOverride[])[idx].dpdd ?? NON_EXISTING;

   /**
   * This function gets the Formik field name for the DPID.
   * @returns The Formik field name for the DPID.
   */
  const getFormikFieldNameForIneligibleRuleOverrideDPID = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].dpid`;

  /**
   * This function gets the Formik field name for the DPDD.
   * @returns The Formik field name for the DPDD.
   */
  const getFormikFieldNameForIneligibleRuleOverrideDPDD = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].dpdd`;

  // Default options for DPID and DPDD settings.
  const options = ['30', '60', '90', '120', 'NA'];

  return (
    <Box sx={styles.specialSettingsContainer}>
      <ComboBox
        options={options}
        filteredOptions={options}
        label={'DPID'}
        name={getFormikFieldNameForIneligibleRuleOverrideDPID()}
        value={getSelectedIneligibleRuleOverrideDPID()}
        formik={formik}
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateCriteriaAndSettings}

      />
      <ComboBox
        options={options}
        filteredOptions={options}
        label={'DPDD'}
        name={getFormikFieldNameForIneligibleRuleOverrideDPDD()}
        value={getSelectedIneligibleRuleOverrideDPDD()}
        disabled={!hasPaymentTerms || !(permissions as IIneligibleSettingsPermissions).canUpdateCriteriaAndSettings}
        formik={formik}
      />
    </Box>
  );
}

export default DelinquentSettings;
