import React from 'react';
import GeneralBreadcrumbs from '../../../breadcrumb';

const BorrowingBaseReportBreadcrumbs: React.FC = () => {
  return (
    <GeneralBreadcrumbs
      selectedText='Borrowing Base Report'
      breadcrumbLinks={[
        { linkText: 'Reports', route: '/reports' }
      ]}
    />
  );
};

export default BorrowingBaseReportBreadcrumbs;
