import { createContext, useEffect, useMemo, useState } from 'react';
import { LayoutProps } from './authContext';
import { IClient } from '../interfaces';
import { GET, NON_EXISTING, PERMISSIONS } from '../utility/constants';
import request from '../service/request';
import { checkUserPermissions, getLocalStorageItem } from '../utility/helper';
import { borrowerAPI } from '../service/api';
import { useLocation } from 'react-router-dom';

export interface ISelectedClientContext {
  selectedClient: IClient | null;
  setSelectedClient: (client:  IClient | null) => void;
  clients: Array<IClient>;
  setClients: (clients: Array<IClient>) => void;
  getClients: () => void;
}

export const initialSelectedClientContext: ISelectedClientContext = {
  selectedClient: null,
  setSelectedClient: (client: IClient | null) => {},
  clients: [],
  setClients: (clients: IClient[]) => {},
  getClients: () => {}
};

export const SelectedClientContext = createContext<ISelectedClientContext>(initialSelectedClientContext);

const SelectedClientProvider = (props: LayoutProps) => {
  const { children } = props;

  const location = useLocation();

  const [selectedClient, setSelectedClient] = useState<IClient | null>(null);
  const [clients, setClients] = useState<IClient[]>([]);

  const LOCAL_STORAGE_KEY_FOR_SELECTED_CLIENT_RECORD_ID = 'selectedClientRecordId';

  useEffect(() => {
    if (clients.length === 0) {
      getClients();
    }
  }, [location.pathname, clients]);

  useEffect(() => {
    if (clients.length === 0) { return; }
    if (selectedClient?.recordId === undefined) {
      localStorage.setItem(LOCAL_STORAGE_KEY_FOR_SELECTED_CLIENT_RECORD_ID, NON_EXISTING.toString());
      return;
    }
    localStorage.setItem(LOCAL_STORAGE_KEY_FOR_SELECTED_CLIENT_RECORD_ID, selectedClient.recordId.toString());
  }, [clients, selectedClient]);

  const getClients = async () => {
    let requestParams;
    const canViewAssignedClientsOnly = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_ASSIGNED_CLIENT);
    if (canViewAssignedClientsOnly) {
      requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 99999, sortBy: 'borrowerName,ASC', crmName: `${getLocalStorageItem('firstName')} ${getLocalStorageItem('lastName')}`}
    } else {
      requestParams = {isVisible: true, isArchive: false, pageNo: 0, pageSize: 99999, sortBy: 'borrowerName,ASC'}
    }
    request({
      url: borrowerAPI.FIND_BY_CRITERIA,
      method: GET,
      params: requestParams
    })
      .then((response) => {
        const selectedClientRecordIdFromLocalStorage = getLocalStorageItem(LOCAL_STORAGE_KEY_FOR_SELECTED_CLIENT_RECORD_ID);
        const selectedClientRecordId = selectedClientRecordIdFromLocalStorage ? parseInt(selectedClientRecordIdFromLocalStorage) : NON_EXISTING;
        const clientsFromResponse = response.data.content as Array<IClient>;
        if (clientsFromResponse?.length > 0) setClients(clientsFromResponse);
        if (selectedClientRecordId === NON_EXISTING) { setSelectedClient(null); return; }

        const selectedClientFromResponse = clientsFromResponse.find(client => client.recordId === selectedClientRecordId);
        setSelectedClient(selectedClientFromResponse ?? null);
      })
      .catch(error => console.log('GET CLIENTS ERROR: ', error));
  };

  const value = useMemo(() => ({ selectedClient, setSelectedClient, clients, setClients, getClients }), [selectedClient, clients]);

  return (
    <SelectedClientContext.Provider
      value={value}>
      {children}
    </SelectedClientContext.Provider>
  );
};

export default SelectedClientProvider;
