import React, { useState, useContext, DragEvent } from 'react';
import { FormikErrors, FormikProps } from 'formik';
import { DragIndicator } from '@mui/icons-material';
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, Skeleton, Stack, Switch } from '@mui/material';
import { NON_EXISTING } from '../../../utility/constants';
import { isObjectsEqual } from '../../../utility/helper';
import { IFormikValuesForRuleAndOverridesPerSetting, IIneligibleSettingsContext, IIneligibleSettingDetail, IFormikValuesForUPCRuleAndOverridesPerSetting, IIneligibleSettingsPermissions } from '../../../interfaces/ineligibleSettingInterface';
import { IneligibleSettingsContext } from '../../../context/ineligibleSettingsContext';
import { validateIneligibleSetting } from '../../../schemas/ineligibleSettingsSchema';
import styles from './styles';
import { checkOverrideErrors } from '../ineligible-setting-details';

export interface DraggableListItem {
  recordId: number;
  name: string;
  value: string;
  disabled: boolean;
}

interface DraggableListProps {
  formik: FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>;
  draggableListItems: DraggableListItem[];
  setDraggableListItems: (draggableListItem: DraggableListItem[]) => void;
  onItemToggle: (item: DraggableListItem) => void;
  onItemDrop: (draggedItem: DraggableListItem) => void;
}

interface DraggableRowProps {
  formik: FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>;
  row: DraggableListItem;
  index: number;
  isDragging: boolean;
  hasError: boolean;
  onItemToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void;
  onDragStart: (event: DragEvent<HTMLButtonElement>, index: number) => void;
  onDragEnd: () => void;
  onDragOver: (hoveredIndex: number) => void;
  onDrop: () => void;
}

/**
 * Component represents a draggable row inside the DraggableList for Ineligibles.
 * @param props The props for the DraggableRow component.
 * @returns The DraggableRow component JSX.
 */
const Row: React.FC<DraggableRowProps> = (props) => {
  const { formik, row, index, isDragging, hasError, onItemToggle, onDragStart, onDragEnd, onDragOver, onDrop } = props;

  const {
    selectedIneligibleIndex: ineligibleIndex,
    setSelectedIneligibleIndex,
    permissions,
    isHidingInactiveIneligibles,
    setIsHidingInactiveIneligibles,
  }                                                   = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  /**
   * This function handles the click event for ineligible list item.
   */
  const handleListItemClick = () => {
    validateIneligibleSetting(formik, `ineligibleSettingDetails[${ineligibleIndex}]`);
    setSelectedIneligibleIndex(index);
  };

  /**
   * This function handles the click event for the drag and drop icon of an ineligible list item.
   */
  const handleDragAndDropIconClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (row.disabled) { event.stopPropagation(); }
  };

  /**
   * This function checks if the row is selected.
   * @returns True if the row is selected, false otherwise.
   */
  const isRowSelected = () => {
    if (ineligibleIndex === NON_EXISTING) { return false; }
    return props.formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.ineligibleSettingId === row.recordId;
  };

  /**
   * This function gets the styles for the ListItem based on its state.
   * @returns The styles object for the ListItem.
   */
  const getListItemStyles = () => {
    if (hasError && isDragging) { return styles.listItemForDraggingWithError; }
    if (hasError) { return styles.listItemWithError; }
    if (isDragging) { return styles.listItemForDragging; }
    return styles.listItem;
  };

  /**
   * This function renders the ineligible list item.
   * @returns The rendered component.
   */
  const renderListItem = () => {
    if (isHidingInactiveIneligibles && row.disabled) { return null }
    return (
      <ListItem dense disablePadding sx={getListItemStyles()}>
        <ListItemButton
          tabIndex={-1}
          selected={isRowSelected()}
          disabled={row.disabled}
          disableRipple
          disableTouchRipple
          sx={{ ...styles.listItemButton }}
          onClick={handleListItemClick}
        >
          <IconButton
            draggable
            edge='end'
            aria-label='Drag and drop icon'
            disabled={!(permissions as IIneligibleSettingsPermissions).canReorderList}
            onClick={(event) => handleDragAndDropIconClick(event)}
            onMouseOver={() => setIsHidingInactiveIneligibles(false) }
            onDragStart={(event) => { onDragStart(event, index); }}
            onDragEnd={() => { onDragEnd(); }}
            onDragOver={() => { onDragOver(index); }}
            onDrop={() =>  { onDrop(); }}
          >
            <DragIndicator/>
          </IconButton>
          <ListItemText tabIndex={0} id={`${row.name}`} primary={row.name} sx={styles.listItemText} />
            <Box
              tabIndex={0}
              role="switch"
              aria-checked={!row.disabled ? 'true' : 'false'} 
              aria-label={!row.disabled ? 'Toggle On button' : 'Toggle Off button'}
            >
              <Switch
                tabIndex={-1}
                checked={!row.disabled}
                inputProps={{
                  'aria-label': !row.disabled ? 'Toggle On button' : 'Toggle Off button',
                }}
                onClick={(event) => onItemToggle(event, index)}
                disabled={!(permissions as IIneligibleSettingsPermissions).canActivateDeactivateIneligible}
              />
            </Box>
        </ListItemButton>
      </ListItem>
    );
  };

  return renderListItem();
}

/**
 * This Component represents a draggable list for Ineligibles.
 * @param props The props for the DraggableList component.
 * @returns The DraggableList component JSX.
 */
const DraggableList = (props: DraggableListProps) => {
  const { formik, onItemToggle, onItemDrop, draggableListItems, setDraggableListItems } = props;
  const { isIneligibleDetailsLoading, hideInactiveIneligiblesToggle, setIsHidingInactiveIneligibles }  = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;
  const [draggedListItem, setDraggedListItem] = useState<DraggableListItem | null>(null);

  /**
   * This function handles the item toggle event.
   * @param event The mouse event.
   * @param index The index of the item.
   */
  const handleItemToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    event.stopPropagation(); /** prevents onClick of parent component (ListItemButton) */
    onItemToggle(draggableListItems[index]);
  };

  /**
   * This function handles the drag start event.
   * @param event The drag event.
   * @param index The index of the item.
   */
  const handleDragStart = (event: DragEvent<HTMLButtonElement>, index: number) => {
    const parentElement = event.currentTarget.parentElement as NonNullable<HTMLElement>;
    const parentNode = event.currentTarget.parentNode as NonNullable<ParentNode>;
    const parentElementOfParentNode = parentNode.parentElement as NonNullable<HTMLElement>;

    setDraggedListItem(draggableListItems[index]);

    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/html', parentElement.outerHTML);
    event.dataTransfer.setDragImage(
      parentElementOfParentNode,
      (parentElementOfParentNode.clientWidth * 2) - 50,
      parentElementOfParentNode.clientHeight
    );
  };

  /**
   * This function handles the drag end event.
   */
  const handleDragEnd = () => {
    draggedListItem !== null && onItemDrop(draggedListItem);
    setDraggedListItem(null);
  };

  /**
   * This function handles the drag over event.
   * @param hoveredIndex The index of the hovered item.
   */
  const handleDragOver = (hoveredIndex: number) => {
    const nonNullableDraggedListItem = draggedListItem as NonNullable<DraggableListItem>;
    const hoveredItem = draggableListItems[hoveredIndex];
    if (isObjectsEqual(nonNullableDraggedListItem, hoveredItem)) { return; } // no change in items' order
    const rearrangedDraggedListItems = draggableListItems.filter(item => !isObjectsEqual(item, nonNullableDraggedListItem)); /* remove dragged item */
    rearrangedDraggedListItems.splice(hoveredIndex, 0, nonNullableDraggedListItem); /* add dragged item back to the given index to be dropped */

    /* swap AC and DLQ if DLQ is after AC as AC should be always after DLQ */
    const acIndex = rearrangedDraggedListItems.findIndex((item => item.value === 'AC'));
    const dlqIndex = rearrangedDraggedListItems.findIndex((item => item.value === 'DLQ'));
    if (acIndex < dlqIndex) {
      const dlqItem = rearrangedDraggedListItems.splice(dlqIndex, 1, rearrangedDraggedListItems[acIndex])[0]; /* swapping with splice */
      rearrangedDraggedListItems[acIndex] = dlqItem;
    }
    setDraggableListItems(rearrangedDraggedListItems);
  };

  /**
   * This function handles the drop event.
   */
  const handleDrop = () => {
    const nonNullableDraggedListItem = draggedListItem as NonNullable<DraggableListItem>;
    onItemDrop(nonNullableDraggedListItem);
  };

  /**
   * This function checks if an ineligible item has errors.
   * @param item The draggable list item.
   * @returns True if the item has errors, false otherwise.
   */
  const isItemWithError = (item: DraggableListItem) => {
    const formikErrors = formik.errors.ineligibleSettingDetails as FormikErrors<IIneligibleSettingDetail>[];

    const ineligibleSettingDetailIndex = props.formik.values.ineligibleSettingDetails.findIndex(detail => detail.ineligibleSetting.code === item.value);

    if (formikErrors === undefined || ineligibleSettingDetailIndex === NON_EXISTING) { return false; }

    const formikErrorForIneligibleSettingDetail = formikErrors[ineligibleSettingDetailIndex];
    const hasIneligibleRuleErrors = checkRuleErrors(formikErrorForIneligibleSettingDetail);
    const hasIneligibleRuleOverrideErrors = checkOverrideErrors(formikErrorForIneligibleSettingDetail);
    if (formikErrorForIneligibleSettingDetail === undefined || formikErrorForIneligibleSettingDetail === null) { return false; }
    return hasIneligibleRuleErrors || hasIneligibleRuleOverrideErrors;
  };

  /**
   * This function checks if there are rule errors.
   * @param formikErrorForIneligibleSettingDetail Formik errors for an ineligible setting detail.
   * @returns True if there are rule errors, false otherwise.
   */
  const checkRuleErrors = (formikErrorForIneligibleSettingDetail: FormikErrors<IIneligibleSettingDetail>) => {
    return formikErrorForIneligibleSettingDetail?.ineligibleRule !== undefined && Object.keys(formikErrorForIneligibleSettingDetail.ineligibleRule).length > 0;
  };

  /**
   * This function gets the rendered list of draggable items.
   * @returns The rendered list of draggable items.
   */
  const getRenderedList = () => {
    if (isIneligibleDetailsLoading) {
      return (
        <Stack sx={styles.skeletonRowContainer} data-testid='loading-for-ineligible-list'>
          { [...Array(16).keys()].map(number => <Skeleton key={number} animation='wave' sx={styles.skeletonRow}/>) }
        </Stack>
      );
    }
    return (
      <List 
        onDragOver={(event) => event.preventDefault() /* prevent ghost image to bounce back */ }
        onMouseLeave={() => { setIsHidingInactiveIneligibles(hideInactiveIneligiblesToggle) }}
      >
        {
          draggableListItems
            .map((item: DraggableListItem, index) => (
              <Row
                formik={formik}
                key={item.recordId}
                row={item}
                index={index}
                isDragging={isObjectsEqual(item, draggedListItem)}
                hasError={isItemWithError(item)}
                onItemToggle={handleItemToggle}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              />
            ))
        }
      </List>
    );
  };

  return (
    <Box> { getRenderedList() } </Box>
  );
}

export default DraggableList;
