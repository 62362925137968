import * as Yup from 'yup';

const userProfileSchema = Yup.object({
  firstName: Yup.string().required('First Name is required').trim(),
  lastName: Yup.string().required('Lasst Name is required').trim(),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  invitedBy: Yup.string().trim(),
  role: Yup.string().required('Role is required').trim(),
  status: Yup.string().required('Status is required').trim(),
});

export default userProfileSchema;
