import { Box } from "@mui/material";
import { TabPanelProps } from "../../../../../interfaces/clientListInterface";
import styles from "./styles";
import { FC } from "react";

/**
 * Component for showing the contents of the tab panel component in the clients page.
 * @param props The props for the TabPanel component. See the TabPanelProps interface for more information.
 */
const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`client-settings-tabpanel-${index}`}
      aria-labelledby={`client-settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={styles.tabPanelBox}>{children}</Box>}
    </div>
  );
};

export default TabPanel;