const styles = {
    container: { 
        marginTop: '10px', 
        padding: '5px' 
    },
    iframe: { 
        display: 'block', 
        border: 'none', 
        height: '100vh', 
        width: '100vw' 
    }
};

export default styles;