import { AlertColor, Box, Button, Grid, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from "@mui/material"
import { IUsersAndRolesContext, UsersAndRolesContext } from "../../../../context/usersAndRolesContext";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import LoaderTable from "../../loader-table";
import PopUpModal from "../../../modals/pop-up-modal";
import SearchIcon from '@mui/icons-material/Search';
import AddUserModal from "../../modals/add-user-modal";
import UsersTableRow from "./users-table-row";
import styles from "../../styles";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import axiosInstance from "../../../../service/axiosInstance";
import api, { rolePermissionAPI } from "../../../../service/api";
import { GET, PERMISSIONS } from "../../../../utility/constants";
import { getLocalStorageItem, getPermissionsOfUser } from "../../../../utility/helper";
import { IRoleAPI, IUserDetailsAPI, IUserRowInfo, IUsersTable, IUserAPI } from "../../../../interfaces/rolesPermissionInterface";
import Toaster from "../../../toaster";
import _ from "lodash";

/**
 * Component for showing the Users table.
 * @param props The props for the UsersTable component. See the IUsersTable interface for more information.
 */
const UsersTable: FC<IUsersTable> = (props) => {
  const headerTitles                              = ['#', 'Full Name','Invited by', 'Role', 'Status', 'Actions'];
  const {rowsPerPage, page, setTotalElements}     = props;
  const {isDirty, 
    setShowPrompt,
    canAddUser, 
    setCanAddUser,
    setCanUpdateUser,
    setCanDeactivateUser,
    setCanReactivateUser,
    setCanDeleteUser}                              = useContext(UsersAndRolesContext) as IUsersAndRolesContext;
  const [users, setUsers]                          = useState<IUserRowInfo[]>([]);
  const [isLoading, setIsLoading]                  = useState<boolean>(false);
  const [addUserModal, setAddUserModal]            = useState<boolean>(false);
  const [sortKey, setSortKey]                      = useState<string>('Full Name');
  const [sortDirection, setSortDirection]          = useState<string>('ASC');
  const [toasterOpen, setToasterOpen]              = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]        = useState<string>('');
  const [toasterSeverity, setToasterSeverity]      = useState<AlertColor>('success');
  const [searchQuery, setSearchQuery]              = useState<string>('');

  /**
   * This useEffect hook calls the buildUserData function and the getPermissions method.
   */
  useEffect(() => {
    buildUserData();
    getPermissions();
  }, [rowsPerPage, page])
  
  /**
   * This function closes the add user modal if the form is not dirty. 
   * If the form is dirty, the confirm navigation modal is shown first.
   */
  const handleClose = () => {
    if (isDirty) {
      setShowPrompt(true)
    } else {
      setAddUserModal(!addUserModal);
    }
  };

  /**
   * This function retrieves the permissions of the user and sets their state.
   */
  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(getLocalStorageItem('uid'));
    setCanAddUser(permissions.includes(PERMISSIONS.ADD_USER));
    setCanUpdateUser(permissions.includes(PERMISSIONS.UPDATE_USER));
    setCanDeactivateUser(permissions.includes(PERMISSIONS.DEACTIVATE_USER));
    setCanReactivateUser(permissions.includes(PERMISSIONS.REACTIVATE_USER));
    setCanDeleteUser(permissions.includes(PERMISSIONS.DELETE_USER));
  };

  /**
   * This function creates a user object from the provided data. The resulting object can be readily used by the table component.
   * @param userInfo Additional details about the user.
   * @param user The information of the user.
   * @param role The role of the user.
   * @param invitedByInfo The details of the account that invited the user.
   * @returns A single user object that can be used by the users table component to show data. See IUserRowInfo for more information.
   */
  const createUserObject = (userInfo: IUserDetailsAPI | undefined, user: IUserAPI, role: IRoleAPI | undefined, invitedByInfo: IUserAPI | undefined) => {
    const userData : IUserRowInfo = {
      recordId: userInfo !== undefined ? userInfo.recordId : -1,
      keycloakId: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.firstName + ' ' + user.lastName,
      invitedBy: invitedByInfo !== undefined ? invitedByInfo.firstName + ' ' + invitedByInfo.lastName : '',
      role: role !== undefined ? role.name : '',
      status: userInfo !== undefined ? userInfo.status : ''
    }
    return userData; 
  }

  /**
   * This function maps the users in order to create an object containing all of the 
   * required information that can be used by the users table component. Additional API calls are 
   * also made in order to retrieve other information of the user such as their user details, their role, 
   * and the information of the user who invited them.
   * @param users The users to be mapped
   * @returns An array that can be used by the users table component to show data. See IUserRowInfo for more information.
   */
  const mapUsers = async (users: IUserAPI[]) => {
    let userDataArray : IUserRowInfo[] = [];
    await Promise.all(users.map(async (user) => {
      const role = await getRoleOfUser(user.id);
      const userInfo = await getDetailsOfUser(user.id);
      let invitedByInfo : IUserAPI | undefined;
      if (userInfo?.invitedBy !== null && userInfo?.invitedBy !== undefined) {
        const invitedBy = await getDetailsOfUserByRecordId(userInfo?.invitedBy);
        if (invitedBy !== undefined) {
          invitedByInfo = await getUserById(invitedBy.keycloakId);
        }
      }
      const userData = createUserObject(userInfo, user, role, invitedByInfo);
      userDataArray.push(userData);   
    }))
    return userDataArray;
  }

  /**
   * This function retrieves and processes the user data to be shown in the users table.
   */
  const buildUserData = async () => {
    /**
     * First, the loading state is set to true and the users are retrieved from the database.
     */
    setIsLoading(true);
    const users = await getUsers();

    /**
     * Then, the users are mapped to be processed into an object that can be used by the table component.
     */
    const userDataArray = await mapUsers(users);
    
    /**
     * Finally, the setUsers function is called in order to set the data to be used by the table component.
     * The loading state is also set to false.
     */
    setTotalElements(userDataArray.length);
    const tempUsers = [...userDataArray].sort((user1, user2) => sort(user1, user2, 'fullName'));
    setUsers(tempUsers);
    setIsLoading(false);
  };

  /**
   * This function calls the API endpoint for retrieving paged users.
   * @returns The paged users. See IUserAPI interface for more information.
   */
  const getUsers = async () => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: api.ALL_USERS_PAGED,
        method: GET,
        headers: {
          token : token !== undefined ? token : ''
        },
        params: {pageNo: page, pageSize: rowsPerPage}
      })
      const users : IUserAPI[] = response.data.content;
      return users;
    } catch (e) {
      console.log(e)
      return [];
    }
  }

  /**
   * This function calls the API endpoint for retrieving all users.
   * @returns TAll users. See IUserAPI interface for more information.
   */
  const getAllUsers = async () => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: api.ALL_USERS,
        method: GET,
        headers: {
          token : token !== undefined ? token : ''
        }
      })
      const users : IUserAPI[] = response.data;
      return users;
    } catch (e) {
      console.log(e)
      return [];
    }
  }

  /** 
   * This function calls the API endpoint for retrieving a single user by its ID.
   * @returns The user. See IUserAPI interface for more information.
   */
  const getUserById = async (userId: string) => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: api.USER_BY_UID,
        method: GET,
        headers: {
          token : token !== undefined ? token : ''
        },
        params: {userId: userId}
      })
      const users : IUserAPI = response.data;
      return users;
    } catch (e) {
      console.log(e)
    }
  }

  /** 
   * This function calls the API endpoint for retrieving the role of a user by its ID.
   * @returns The role of the user. See IRoleAPI interface for more information.
   */
  const getRoleOfUser = async (userId: string) => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.GET_ROLES_OF_USER,
        method: GET,
        headers: {
          token : token !== undefined ? token : ''
        },
        params: {userId: userId}
      })
      const role : IRoleAPI = response.data[0];
      return role;
    } catch (e) {
      console.log(e)
    }
  }

  /** 
   * This function calls the API endpoint for retrieving the details of a user by its ID.
   * @returns The details of the user. See IUserDetailsAPI interface for more information.
   */
  const getDetailsOfUser = async (userId: string) => {
    try {
      const response = await axiosInstance.request({
        url: api.GET_USER_INFO,
        method: GET,
        params: {userId: userId}
      })
      const details : IUserDetailsAPI = response.data;
      return details;
    } catch (e) {
      console.log(e)
    }
  }

  /** 
   * This function calls the API endpoint for retrieving the details of a user by it ID of the user details.
   * @returns The details of the user. See IUserDetailsAPI interface for more information.
   */
  const getDetailsOfUserByRecordId = async (recordId: number) => {
    try {
      const response = await axiosInstance.request({
        url: api.GET_USER_INFO_BY_ID,
        method: GET,
        params: {recordId: recordId}
      })
      const details : IUserDetailsAPI = response.data;
      return details;
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * This function sorts two users based on one of their properties.
   * @param user1 The first user.
   * @param user2 The second user.
   * @param index The property to be used as basis for comparison.
   * @returns An array of arranged users.
   */
  const sort = (user1 : any, user2 : any, index : string) => {
    return user1[index].localeCompare(user2[index]);
  }

  /**
   * This function sorts the data to be shown at the user table.
   * @param title The title of the header used as basis for sorting.
   * @param direction The direction of the sorting. ASC/DESC.
   */
  const sortData = (title : string, direction : string) => {
    /**
     * First, the sorting key is set depending on the title.
     */
    let key : string = '';
    switch(title) {
      case 'Full Name':
        key = 'fullName';
        break;
      case 'Invited by':
        key = 'invitedBy';
        break;
      case 'Role':
        key = 'role';
        break;
    }

    /**
     * Afterwards, the users are sorted based on the key.
     */
    const tempUsers = [...users].sort((user1, user2) => sort(user1, user2, key));
      if (direction === 'ASC') {
        setUsers(tempUsers); 
      } else {
        const reverse = [...tempUsers].reverse();
        setUsers(reverse);
      }
  }

  /**
   * This function returns the icon to be used for the sorting icon.
   */
  const handleIcon = useCallback(() => <UnfoldMoreIcon sx={styles.iconDefaultSort}/>, []);

  /**
   * This function is called when the user changes the sort parameters. The sortData function is then called upon setting the new parameters.
   * @param title The title of the header used as basis for sorting.
   */
  const handleSort = (title: string) => {
    if(sortKey === title){
      const newSortDirection = sortDirection === 'DESC' ? 'ASC' : 'DESC';
      setSortDirection(newSortDirection);
      sortData(title, newSortDirection);
    }else{
      setSortKey(title);
      setSortDirection('ASC');
      sortData(title, 'ASC');
    }

  };

  /**
   * This function is called the sorting parameters are changed. The icon is changed based on the new parameters.
   * @param title The title of the header used as basis for sorting.
   */
  const getIconComponent = (title: string) => {
    if (sortKey === title) {
      return;
    } else {
      return { IconComponent: handleIcon };
    }
  };

  /**
   * This function is called in order to handle searching of users in the user table component.
   * @param event This is the event triggered when the user changes the value of the search field.
   */
  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value !== '' && event.target.value.trim().length !== 0) {
      setIsLoading(true);
      const usersAPI = await getAllUsers();
      const user = await mapUsers(usersAPI);
      const filteredUsers = user.filter(user => {
        const name = user.fullName.toLowerCase();
        const query = event.target.value.toLowerCase();
        return name.includes(query);
      })
      setTotalElements(filteredUsers.length);
      const tempUsers = [...filteredUsers].sort((user1, user2) => sort(user1, user2, 'fullName'));
      setUsers(tempUsers);
      setIsLoading(false);
    } else {
      await buildUserData();
    }
  };

  /**
   * This function is called whenever the search query changes. 
   * This delays the actual search function by 500ms in order to prevent overloading the API endpoint.
   * @param event This is the event triggered when the user changes the value of the search field.
   */
  const triggerSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value);
    let debounce = _.debounce((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleSearch(event)
    }, 500);
    debounce(event);
  }
   
  return (
    <Box sx={{ justifyItems: 'end' }}>
      <Grid item xs={10.5} md={3.5} sx={styles.searchStyle}>
        <TextField
          inputProps={{ 'aria-label': 'Search Textfield' }}
          id={`input-with-id`}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon aria-label='Search Clients' />
              </InputAdornment>
            ),
          }}
          onChange={triggerSearch}
          value={searchQuery}
          placeholder='Find by name'
          size='small'
          sx={styles.searchField}
        />
        <Button variant='contained' color='primary' onClick={() => { setAddUserModal(true) }} sx={{...styles.addButton, ...(!canAddUser && styles.hidden)}}>
          <Typography variant='button'>
            + Add User
          </Typography>
        </Button>
      </Grid>

      <TableContainer component={Box}>
        {isLoading ?
          <LoaderTable /> :
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                {headerTitles.map((title, idx) =>
                  {if (title === 'Status' || title === 'Actions'  || title === '#') {
                  return (<TableCell
                    key={title}
                    sx={{
                      ...styles.tableCell,
                      ...styles.tableHeadCell,
                      textAlign: idx < 2 ? 'left' : 'center'
                    }}
                  >
                    {title}
                  </TableCell>) }
                     else {
                      return (<TableCell
                        key={title}
                        sx={{
                          ...styles.tableCell,
                          ...styles.tableHeadCell,
                          textAlign: idx < 2 ? 'left' : 'center'
                        }}
                      >
                        <TableSortLabel
                          active={sortKey === title}
                          direction={sortDirection === 'DESC' ? 'desc' : 'asc'}
                          onClick={() => handleSort(title)}
                          {...getIconComponent(title)}
                        >
                          {title}
                        </TableSortLabel>
                      </TableCell>)
                    } 
                  } 
                )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, idx) =>
                <UsersTableRow 
                  key={user.recordId} 
                  user={user} 
                  idx={idx} 
                  buildUserData={buildUserData}
                  setToasterMessage={setToasterMessage}
                  setToasterOpen={setToasterOpen}
                  setToasterSeverity={setToasterSeverity}
                />
              )}
            </TableBody>
          </Table>
        }
      </TableContainer>
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => {
          setToasterOpen(false);
        }}
      />
      <PopUpModal
        open={addUserModal}
        onClose={handleClose}
        isComponentEditable={false}
        isEditableTrue={true}
        title1='Add User'
        width='800px'
      >
        <AddUserModal
          setShowAddModal={setAddUserModal}
          setToasterMessage={setToasterMessage}
          setToasterOpen={setToasterOpen}
          setToasterSeverity={setToasterSeverity}
          buildUserData={buildUserData}
          presentUsers={users}
        />
      </PopUpModal>
    </Box>
  )
}

export default UsersTable