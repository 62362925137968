import { AlertColor, Autocomplete, AutocompleteRenderInputParams, Box, Button, createFilterOptions, Divider, FilterOptionsState, List, Paper, TextField, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { FC, HTMLAttributes, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import ConfirmModal from "../../../../../../components/modals/confirm-modal";
import PopUpModal from "../../../../../../components/modals/pop-up-modal";
import Toaster from "../../../../../../components/toaster";
import documentTypeSchema from "../../../../../../schemas/documentTypeSchema";
import { API_DOMAIN, GET, POST } from "../../../../../../utility/constants";
import { usePrompt } from "../../../../../../utility/prompt";
import styles from "./styles";
import { IDocumentType } from "../../../../../../interfaces/fileimport";
import axiosInstance from "../../../../../../service/axiosInstance";
import { FileImportContext, IFileImportContext } from "../../../../../../context/fileImportContext";

export interface IDocumentTypeDropdownProps {
  value         : IDocumentType | null;
  inputValue    : string;
  onChange      : (event: SyntheticEvent<Element, Event>, newValue: IDocumentType | null) => void;
  onInputChange : (event: SyntheticEvent<Element, Event>, newInput: string) => void;
  isDisabled    : boolean;
}

const otherDoc = {recordId: 0, name: 'Add Other Document'};
const filter   = createFilterOptions<IDocumentType>();
const allowedDocTypesForUpc = ['Customer List', 'Vendor List'];

/**
 * Paper component for the dropdown options.
 * @param props The MUI Paper component props of the HTML Element.
 * @returns An MUI Paper component.
 */
const PaperComponent: FC<HTMLAttributes<HTMLElement>> = (props) => {
  return <Paper sx={styles.paper}>{props.children}</Paper>;
};

/**
 * Component for the Document Type dropdown.
 * @param props The props for the Document Type dropdown.
 * @returns A component for the Document Type dropdown.
 */
const DocumentTypeDropdown: FC<IDocumentTypeDropdownProps> = (props) => {
  const { selectedClient }                      = useContext(FileImportContext) as IFileImportContext;
  const [openDocTypeModal, setOpenDocTypeModal] = useState<boolean>(false);
  const [isDirty, setIsDirty]                   = useState<boolean>(false);
  const [showPrompt, setShowPrompt]             = useState<boolean>(false);
  const [showToaster, setShowToaster]           = useState<boolean>(false);
  const [refresh, setRefresh]                   = useState<boolean>(false);
  const [severity, setSeverity]                 = useState<AlertColor>('success');
  const [toasterMessage, setToasterMessage]     = useState('');
  const [options, setOptions]                   = useState<IDocumentType[]>([]);
  const isUltimateParent                        = useMemo(() => Boolean(selectedClient?.parentClient), [selectedClient]);
  const isChildOfUltimateParent                 = useMemo(() => Boolean(selectedClient?.parentClientFk), [selectedClient]);

  const filteredOptions = useMemo(() => {
    if (isUltimateParent) {
      return options.filter(option => allowedDocTypesForUpc.includes(option.name))
    } else if (isChildOfUltimateParent) {
      return [...options.filter(option => !allowedDocTypesForUpc.includes(option.name)), otherDoc];
    } else {
      return [...options, otherDoc];
    }
  }, [options, isUltimateParent, isChildOfUltimateParent]);

  /**
   * This useEffect hook fetches the options of the dropdown.
   * Triggered on change of refresh state.
   */
  useEffect(() => {
    getOptions();
  }, [refresh])

  /**
   * This useCallback returns a function that shows Confirm Navigation Prompt if the Add Document Type form is dirty.
   * Else it closes the Add Document Type modal.
   */
  const handleDocTypeClose = useCallback(() => {
    if (isDirty) setShowPrompt(true);
    else closeModal();
  }, [isDirty]);

  /**
   * This useCallback returns a function that closes the Add Document Type modal.
   */
  const handleDocTypeCancel = useCallback(() => {
    closeModal();
  }, []);

  /**
   * This useCallback returns a function that closes the Add Document Type modal, and the Confirm Navigation Prompt.
   */
  const handlePromptAndModalClose = useCallback(() => {
    setShowPrompt(false);
    setOpenDocTypeModal(false);
  }, []);

  /**
   * This useCallback returns a function that closes the Confirm Navigation Prompt.
   */
  const handlePromptClose = useCallback(() => setShowPrompt(false), []);

  /**
   * This function asynchronously fetches the document types from the server.
   * Sets the options of the dropdown based on the fetching.
   */
  const getOptions = async () => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/documentTypes`,
        method: GET,
        params: { sortBy: 'recordId,ASC' }
      });
      const optionsArray = response.data.content;

      if (optionsArray.length) {
        setOptions(optionsArray);
      }
    } catch (error) {
      setOptions([]);
    }
  };

  /**
   * This function handles the saving of the newly created document type.
   * Shows a toaster based on success or fail.
   * Triggers the refresh state to add the new option to the dropdown.
   * Close the Add Document Type modal afterwards.
   * @param values The values of the form.
   */
  const handleDocTypeSave = async (values: { documentType: string }) => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/documentTypes`,
        method: POST,
        data: { name: values.documentType }
      });

      if (response.status === 201) {
        setShowToaster(true);
        setToasterMessage(`${values.documentType} has been added`);
        setSeverity('success');
        setRefresh(!refresh);
        closeModal();
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        setShowToaster(true);
        setToasterMessage('Document name already exists');
        setSeverity('error');
      } else {
        setShowToaster(true);
        setToasterMessage('Request failed. Please try again');
        setSeverity('error');
      }
    }
  };

  /**
   * This function closes the modal and sets the dirty state to false.
   */
  const closeModal = () => {
    setIsDirty(false);
    setOpenDocTypeModal(false);
  };

  /**
   * This function sets the additional props for the Autocomplete component of the Document Type Dropdown.
   * @returns The additional props for the Autocomplete component of the Document Type Dropdown.
   */
  const getOtherProps = () => {
    return {
      getOptionLabel: (option: IDocumentType) => option.name,
      isOptionEqualToValue: (option: IDocumentType, value: IDocumentType) => option.recordId === value.recordId,
      onChange: (e: SyntheticEvent<Element, Event>, newValue: IDocumentType | null) => newValue?.recordId === 0 ? setOpenDocTypeModal(true) : props.onChange(e, newValue),
      onInputChange: props.onInputChange,
      filterOptions: (options: IDocumentType[], params: FilterOptionsState<IDocumentType>) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        const isExisting = options.some((option: IDocumentType) => inputValue === option.name);
        if (inputValue !== '' && !isExisting && filtered.length === 0) {
          filtered.push(otherDoc);
        }

        return filtered;
      },
      renderInput: (params: AutocompleteRenderInputParams) => (<TextField {...params} placeholder='Please Select' />),
      renderOption: (props: HTMLAttributes<HTMLLIElement>, option: IDocumentType) => (
        <Box key={option.recordId}>
          {option.recordId === 0 ? <Divider /> : null}
          <List component={'li'} {...props}>{option.name}</List>
        </Box>
      ),
      PaperComponent: PaperComponent
    }
  };

  /**
   * This function returns the onChange props for the form component.
   * @param formik The formik component of the form.
   * @returns An object with the onChange function of the form component.
   */
  const getFormikOnChange = (formik: FormikProps<{documentType: string;}>) => {
    return {
      onChange: () => { setIsDirty(formik.dirty); }
    }
  }

  /**
   * This function returns the onSubmit props for the form component.
   * @returns An object with the onSubmit function of the form component.
   */
  const getFormikOnSubmit = () => {
    return {onSubmit: async (values: {documentType: string}) => await handleDocTypeSave(values)}
  }

  /**
   * This custom hook shows a confirm navigation modal if the form is dirty when the user tries to navigate away from the page/modal.
   */
  usePrompt(`You have unsaved changes. Are you sure you want to leave this page?`, isDirty);

  return (
    <>
    <Typography tabIndex={0} component='label' htmlFor={'document-type-dropdown'} sx={styles.label}>
      Document Type
    </Typography>
    <Autocomplete
      id={'document-type-dropdown'}
      disablePortal
      disabled={props.isDisabled}
      options={filteredOptions}
      value={props.value}
      inputValue={props.inputValue}
      {...getOtherProps()}
      size='small'
      aria-label='Document Type Dropdown'
      componentsProps={{
          popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
          clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}
      }}
      sx={{...styles.paper, ...styles.dropdown}}
    />
    <PopUpModal
      open={openDocTypeModal}
      onClose={handleDocTypeClose}
      isComponentEditable={false}
      isEditableTrue={false}
      isNotDeletable={true}
      isNotFromTable={true}
      title1=''
      title2='Add Other Document'
      width='442px'
    >
      <Box sx={styles.modalContainer}>
        <Formik
          enableReinitialize
          initialValues={{ documentType: '' }}
          validationSchema={documentTypeSchema}
          {...getFormikOnSubmit()}
        >
          {(formik) => (
            <Form {...getFormikOnChange(formik)}>
              <Box sx={styles.formContainer}>
                <Box sx={styles.fieldContainer}>
                  <Typography component='label' htmlFor='text-field-document-type' sx={styles.fieldLabel}>
                    Document Type
                  </Typography>
                  <TextField
                    id='text-field-document-type'
                    name='documentType'
                    size='small'
                    value={formik.values.documentType}
                    onChange={formik.handleChange}
                    error={formik.touched.documentType && Boolean(formik.errors.documentType)}
                    helperText={formik.touched.documentType && formik.errors.documentType}
                    sx={styles.field}
                  />
                </Box>
                <Box sx={styles.buttonGroup}>
                  <Button
                    variant='outlined'
                    disableElevation
                    type='reset'
                    onClick={handleDocTypeCancel}
                    sx={styles.buttonCommon}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    disableElevation
                    type='submit'
                    disabled={!formik.dirty}
                    sx={{...styles.buttonCommon, ...styles.buttonSave}
                  }>
                    Save
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </PopUpModal>
    <ConfirmModal
      open={showPrompt}
      onClose={handlePromptAndModalClose}
      onConfirm={handlePromptClose}
      onButtonClose={handlePromptClose}
      promptChecker
      title={`Confirm Navigation`}
      description={`You have unsaved changes. Are you sure you want to leave this page?`}
      yesButtonText={`Keep Editing`}
      noButtonText={`Discard Changes`}
      confirmOnly
    />
    <Toaster
      open={showToaster}
      message={toasterMessage}
      severity={severity}
      onCloseChange={() => setShowToaster(false)}
    />
    </>
  )
}
export default DocumentTypeDropdown;