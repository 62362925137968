import { CalcIneligibleState } from "../reducer/calcIneligibleReducer";

export enum ActionType {
  CalculateIneligible
}

export interface CalculateIneligible {
  type: ActionType.CalculateIneligible;
  payload: CalcIneligibleState;
}

export const setCalcIneligibleLoader = (payload: CalcIneligibleState): CalculateIneligible => {
  return {
      type: ActionType.CalculateIneligible,
      payload
  }
}

export type CalcIneligibleActions = CalculateIneligible;