import { useContext, useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material/';
import { formatCurrency, formatPercentage, getARBorrowingBaseTotals } from '../../../../utility/helper';
import { ReportsContext, IReportsContext } from '../../../../context/reportsContext';
import { BorrowingBaseReportContext, IBorrowingBaseReportContext } from '../../../../context/borrowingBaseReportContext';
import AccountsReceivableBBListTable from '../accounts-receivable-bb-list-table';
import InventoryBBListTable from '../inventory-bb-list-table';
import InventoryNOLVBBListTable from '../inventory-nolv-bb-list-table';
import styles from './styles';
import TableWithLeftPinnedHeader from '../../../common/table-with-left-pinned-header';
import { IARBorrowingBase, IARBorrowingBaseTotals } from '../../../../interfaces/reportsInterface';
import axiosInstance from '../../../../service/axiosInstance';
import { API_DOMAIN, GET } from '../../../../utility/constants';

const BorrowingBaseReportGridDetails = () => {
  const { selectedClient: client, 
          selectedDatePeriod: datePeriod, }                              = useContext(ReportsContext) as IReportsContext;
  const { isReportVisible, otherCollateralReport, arBorrowingBaseList, arBorrowingBaseTotals, invBorrowingBaseList } = useContext(BorrowingBaseReportContext) as IBorrowingBaseReportContext;

  /**
   * This method calculates the total available inventory by summing up the 'grossAvail' values in the provided array.
   */
    const totalAvailableInventory = invBorrowingBaseList.reduce((total,item) => total + (item?.grossAvail || 0), 0);

  /**
   * This method calculates the available amount before reserves and loan balance.
   *
   * @returns The total available amount before reserves and loan balance.
   */
  const getAvailBeforeReservesAndLb = () => {
    if (otherCollateralReport?.availBeforeReservesAndLb) {
        return otherCollateralReport.collateralAvailability + arBorrowingBaseTotals.totalAvailAr + totalAvailableInventory;
    } else {
        return (otherCollateralReport?.collateralAvailability || 0) + arBorrowingBaseTotals.totalAvailAr + totalAvailableInventory;
    }
  };


  /**
   * This method calculates the available amount after subtracting reserves.
   *
   * @returns The total available amount after reserves.
   */
  const getAvailAfterReserves = () => {
    return getAvailBeforeReservesAndLb() - (otherCollateralReport?.reserve ?? 0);
  };

  /**
   * This method calculates the net available amount not borrowed.
   *
   * @returns The formatted currency value of the net available amount not borrowed.
   */
  const getAvailNotBorrowed = () => {
    const availAfterReserves = otherCollateralReport?.availAfterReserve
      ? otherCollateralReport?.availAfterReserve + arBorrowingBaseTotals.totalAvailAr
      : getAvailAfterReserves();

    const loanBalance = otherCollateralReport?.loanBalance ?? 0;

    return availAfterReserves - loanBalance;
  }

  return (
    <Grid container spacing={1}
      sx={{
        ...styles.gridContainer,
        ...(!isReportVisible && styles.hidden),
      }}>
      <Grid item xs={12}>
        <Typography tabIndex={0} sx={styles.gridHeading}>Collateral Availability</Typography>
      </Grid>

      <Grid item xs={12}>
        <TableWithLeftPinnedHeader
          columns={[
            { field: 'clientName', headerName: 'Client Name' },
            { field: 'acctgSystem', headerName: 'Accounting System' },
            { field: 'lineOfCreditAmt', headerName: 'Line of Credit Amount' },
          ]}
          rows={[
            {
              clientName: client?.borrowerName,
              acctgSystem: client?.accountingSystem?.accountingSystemName,
              lineOfCreditAmt: formatCurrency(client?.lineLimit ?? null),
            }
          ]}
          styles={{
            tableHeadCell: {...styles.tableHeadCell, ...styles.tableHeadCellCommon },
            tableCell: {...styles.tableCell, ...styles.tableCellCommon, ...styles.tableCellLeftAlign },
            tableBody: {...styles.tableBody, ...styles.tableBodyCommon },
            tableHead: styles.tableHead,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography tabIndex={0} sx={styles.gridHeading}>
          Account Receivable Collateral
        </Typography>
      </Grid>

      {
        (arBorrowingBaseList.length > 0) && (
          <>
            <Grid item xs={12}>
              <AccountsReceivableBBListTable arBBList={arBorrowingBaseList} />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={styles.gridDivider} />
            </Grid>
          </>
        )
      }

      <Grid item xs={12}>
        <TableWithLeftPinnedHeader
          columns={[
            { field: 'totalGrossAR', headerName: 'Total Gross Accounts Receivable' },
            { field: 'totalAvailAR', headerName: 'Total Available Accounts Receivable' },
            { field: 'effectiveAdvancePct', headerName: 'Effective Advance Rate' },
          ]}
          rows={[
            {
              totalGrossAR: formatCurrency(arBorrowingBaseTotals.totalGrossAr),
              totalAvailAR: formatCurrency(arBorrowingBaseTotals.totalAvailAr),
              effectiveAdvancePct: formatPercentage(arBorrowingBaseTotals.effectiveAdvRate, 2),
            }
          ]}
          styles={{
            tableHeadCell: {...styles.tableHeadCell, ...styles.tableHeadCellCommon },
            tableCell: {...styles.tableCell, ...styles.tableCellCommon },
            tableBody: {...styles.tableBody, ...styles.tableBodyCommon },
            tableHead: styles.tableHead,
          }}
        />
      </Grid>

      {
        (invBorrowingBaseList.length !== null && invBorrowingBaseList.length > 0) && (
          <>
            <Grid item xs={12}>
              <Typography tabIndex={0} sx={styles.gridHeading}>
                Inventory Collateral, Standard (i)
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <InventoryBBListTable />
            </Grid>

            <Grid item xs={12}>
              <Typography tabIndex={0} sx={styles.gridHeading}>
                Inventory Collateral, NOLV (ii)
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <InventoryNOLVBBListTable />
            </Grid>
          </>
        )
      }

      {
        (typeof otherCollateralReport?.totalInventoryValue !== 'undefined') && (
          <>
            <Grid item xs={12}>
              <Typography tabIndex={0} sx={styles.gridHeading}>
                Inventory Collateral Cap (iii)
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography tabIndex={0} sx={{ ...styles.gridHeading, ...styles.gridHeadingForAvailableInventory }}>
                Available Finished Goods Inventory - Lesser of (i) (iii)
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ ...styles.gridDivider, ...styles.tighterGridDivider }} />
            </Grid>

            <Grid item xs={12}>
              <TableWithLeftPinnedHeader
                columns={[
                  { field: 'totalGrossInv', headerName: 'Total Gross Inventory Collateral' },
                  { field: 'totalAvailInv', headerName: 'Total Available Inventory' },
                  { field: 'suppressedAvail', headerName: 'Suppressed Availability' },
                  { field: 'effectiveAdvancePct', headerName: 'Effective Advance Rate' },
                ]}
                rows={[
                  {
                    totalGrossInv: formatCurrency(otherCollateralReport?.totalInventoryValue),
                    totalAvailInv: formatCurrency(totalAvailableInventory),
                    suppressedAvail: formatCurrency(0),
                    effectiveAdvancePct: `${otherCollateralReport?.effectiveAdvRate && otherCollateralReport?.effectiveAdvRate > 0 ?
                                          formatPercentage(otherCollateralReport?.effectiveAdvRate / 100, 2) : '0.00%'}`,
                  }
                ]}
                styles={{
                  tableHeadCell: {...styles.tableHeadCell, ...styles.tableHeadCellCommon },
                  tableCell: {...styles.tableCell, ...styles.tableCellCommon },
                  tableBody: {...styles.tableBody, ...styles.tableBodyCommon },
                  tableHead: styles.tableHead,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ ...styles.gridDivider, ...styles.gridDividerForAvailableInventory }} />
            </Grid>

            <Grid item xs={12}>
              <TableWithLeftPinnedHeader
                columns={[{ field: 'effectiveAdvancePct', headerName: 'Effective Advance Rate' }]}
                rows={[{effectiveAdvancePct: `${otherCollateralReport?.effectiveAdvRate && otherCollateralReport?.effectiveAdvRate > 0 ?
                                          formatPercentage(otherCollateralReport?.effectiveAdvRate / 100, 2) : '0.00%'}`}]}
                styles={{
                  tableHeadCell: {...styles.tableHeadCell, ...styles.tableHeadCellCommon },
                  tableCell: {...styles.tableCell, ...styles.tableCellCommon },
                  tableBody: {...styles.tableBody, ...styles.tableBodyCommon },
                  tableHead: styles.tableHead,
                }}
              />
            </Grid>
          </>
        )
      }

      {
        (typeof otherCollateralReport?.collateralTotal !== 'undefined') && (
          <>
            <Grid item xs={12}>
              <Typography tabIndex={0} sx={{ ...styles.gridHeading, ...styles.tighterGridHeading }}>Others</Typography>
            </Grid>

            <Grid item xs={12}>
              <TableWithLeftPinnedHeader
                columns={[
                  { field: 'sublimit', headerName: 'Sublimit' },
                  { field: 'collatTotal', headerName: 'Collateral Total' },
                  { field: 'ineligibleAmt', headerName: 'Ineligible Amount' },
                  { field: 'remaining', headerName: 'Remaining' },
                  { field: 'advancePct', headerName: 'Advance Rate' },
                  { field: 'collatAvail', headerName: 'Collateral Availability' },
                ]}
                rows={[
                  {
                    sublimit: formatCurrency(otherCollateralReport?.sublimit),
                    collatTotal: formatCurrency(otherCollateralReport?.collateralTotal),
                    ineligibleAmt: formatCurrency(otherCollateralReport?.ineligibleAmount),
                    remaining: formatCurrency(otherCollateralReport?.remaining),
                    advancePct: `${ otherCollateralReport?.otherLoanAdvanceRate && otherCollateralReport.otherLoanAdvanceRate > 0  ?
                                    otherCollateralReport.otherLoanAdvanceRate.toFixed(2) : '0.00'}%`,
                    collatAvail: formatCurrency(otherCollateralReport?.collateralAvailability),
                  }
                ]}
                styles={{
                  tableHeadCell: {...styles.tableHeadCell, ...styles.tableHeadCellCommon },
                  tableCell: {...styles.tableCell, ...styles.tableCellCommon },
                  tableBody: {...styles.tableBody, ...styles.tableBodyCommon },
                  tableHead: styles.tableHead,
                }}
              />
            </Grid>
          </>
        )
      }

      <Grid item xs={12}>
        <Typography tabIndex={0} sx={styles.gridHeading}>Availability</Typography>
      </Grid>

      <Grid item xs={12}>
        <TableWithLeftPinnedHeader
          columns={[
            { field: 'availBeforeReserveAndLoan', headerName: 'Availability before Reserves and Loan Balance' },
            { field: 'reserves', headerName: 'Reserves' },
            { field: 'availAfterReserves', headerName: 'Availability after Reserves' },
            { field: 'loanBalance', headerName: 'Loan Balance' }
          ]}
          rows={[
            {
              availBeforeReserveAndLoan: formatCurrency(getAvailBeforeReservesAndLb()),
              reserves: formatCurrency(otherCollateralReport?.reserve ?? null),
              availAfterReserves: otherCollateralReport?.availAfterReserve
                                  ? formatCurrency(otherCollateralReport?.availAfterReserve + arBorrowingBaseTotals.totalAvailAr)
                                  : formatCurrency(getAvailAfterReserves()),
              loanBalance: formatCurrency(otherCollateralReport?.loanBalance ?? null),
            }
          ]}
          styles={{
            tableHeadCell: {...styles.tableHeadCell, ...styles.tableHeadCellCommon },
            tableCell: {...styles.tableCell, ...styles.tableCellCommon },
            tableBody: {...styles.tableBody, ...styles.tableBodyCommon },
            tableHead: styles.tableHead,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ ...styles.gridDivider, ...styles.gridDividerForAvailableInventory }} />
      </Grid>

      <Grid item xs={12}>
        <TableWithLeftPinnedHeader
          columns={[{ field: 'availNotBorrowed', headerName: 'Availability Not Borrowed' }]}
          rows={[{availNotBorrowed: formatCurrency(getAvailNotBorrowed())}]}
          styles={{
            tableHeadCell: {...styles.tableHeadCell, ...styles.tableHeadCellCommon },
            tableCell: {...styles.tableCell, ...styles.tableCellCommon },
            tableBody: {...styles.tableBody, ...styles.tableBodyCommon },
            tableHead: styles.tableHead,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ ...styles.gridDivider, ...styles.gridDividerForAvailableInventory }} />
      </Grid>
    </Grid >
  );
};

export default BorrowingBaseReportGridDetails;
