import { FC, useContext } from 'react';
import { Box } from '@mui/material';
import IneligibleAdjustmentsHeader from '../../components/ineligible-adjustments/header';
import IneligibleAdjustmentsTabs from '../../components/ineligible-adjustments/tabs';
import styles from './styles';

// context
import IneligibleAdjustmentsProvider, { IIneligibleAdjustmentsContext, IneligibleAdjustmentsContext } from '../../context/ineligibleAdjustmentsContext';
import NavigationWarningProvider from '../../context/navigationWarningContext';


const IneligibleAdjustmentsPage: FC = () => {
  const {viewIA} = useContext(IneligibleAdjustmentsContext) as IIneligibleAdjustmentsContext;
  return (
    <Box sx={{...styles.backgroundBox, ...(!viewIA && styles.hidden)}}>
      <IneligibleAdjustmentsHeader />
      <IneligibleAdjustmentsTabs />
    </Box>
  );
};

const IneligibleAdjustments: FC = () => {
  return (
    <NavigationWarningProvider>
      <IneligibleAdjustmentsProvider>
        <IneligibleAdjustmentsPage />
      </IneligibleAdjustmentsProvider>
    </NavigationWarningProvider>
  );
};

export default IneligibleAdjustments;
