import './App.css';
import AuthContextProvider from './context/authContext';
import BankLogoContextProvider from './context/bankLogoContext';
import Router from './routes';

const App = () => {
  return (
    <AuthContextProvider>
      <BankLogoContextProvider>
        <Router />
      </BankLogoContextProvider>
    </AuthContextProvider>
  );
}

export default App;