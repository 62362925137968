import { useContext } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';
import { CalcIneligibleContext } from '../../context/calneligibleContext';
import { DrawerContext } from '../../context/drawerContext';
import Drawer from '../drawer';
import Footer from '../footer';
import Header from '../header';
import CalculationLoader from '../reports/borrowing-base/calculation-loader';
import styles from './styles';
import { FileImportProcessContext } from '../../context/fileImportProcessContext';
import ImportFileLoader from '../file-import/import-file-loader';
import { ExportReportContext } from '../../context/exportReportContext';
import ExportLoader from '../reports/export-loader';

const Layout = () => {
  const { state } = useContext(CalcIneligibleContext);
  const drawerState = useContext(DrawerContext).state;
  const fileImportProcessState = useContext(FileImportProcessContext).state;
  const showExportLoader = useContext(ExportReportContext).show;
  
  return (
    <>
    <div>
      <Header />
      <Drawer />
      <Box
        component="main"
        sx={drawerState.drawerWebOpen ? styles.drawerOpenBox : styles.drawerClosedBox}
      >
        <Container maxWidth='xl' sx={styles.container}>
          <Outlet />
        </Container>
        { state.show && <CalculationLoader {...state}/>}
        { fileImportProcessState.show && <ImportFileLoader {...{...fileImportProcessState, newDocuments: fileImportProcessState.documents}}/>}
        { showExportLoader && <ExportLoader /> }
      </Box>
    </div>
      <Footer />
    </>
  );
};
export default Layout;