import { Box, CircularProgress, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { IUploadedFile } from "../../../../../../../interfaces/fileimport";
import { FileImportContext, IFileImportContext } from "../../../../../../../context/fileImportContext";
import { CheckCircleOutline } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { formatBytes } from "../../../../../../../utility/helper";
import ErrorIcon from '@mui/icons-material/Error';
import ConfirmModal from "../../../../../../../components/modals/confirm-modal";
import { IUploadingSectionProps } from "..";
import axiosInstance from "../../../../../../../service/axiosInstance";
import { fileImportAPI } from "../../../../../../../service/api";
import { DELETE } from "../../../../../../../utility/constants";
import styles from "../../styles";

export interface IFileRowProps extends IUploadingSectionProps {
  uploadedFile: IUploadedFile;
}

/**
 * Component for the File Row of the Uploading Section.
 * @param props The props for the File Row of the Uploading Section.
 * @returns The component for the File Row of the Uploading Section.
 */
const FileRow: FC<IFileRowProps> = (props) => {
  const { uploadedFile, showToaster }                     = props;
  const {
    recentUploadedFiles,
    uploadedFiles, setUploadedFiles
  }                                               = useContext(FileImportContext) as IFileImportContext;
  const [deleteModalOpen, setDeleteModalOpen]     = useState<boolean>(false);

  /**
   * This function deletes an uploaded file and updates the list of uploaded files accordingly.
   * @param uploadedFile The uploaded file to be deleted.
   */
  const deleteFile = async (uploadedFile: IUploadedFile) => {
    if (!uploadedFile.recordId) {
      setDeleteModalOpen(false);
      showToaster('error', `Error in deleting ${uploadedFile.filename}`);
    } else {
      try {
        setDeleteModalOpen(false);
        uploadedFile.isOnDeletion = true;
        const onDeletion = recentUploadedFiles.map((uploaded: IUploadedFile) => {
          if (uploaded.recordId === uploadedFile.recordId) return uploadedFile;
          else return uploaded;
        });
        setUploadedFiles(onDeletion);
        
        const response = await axiosInstance.request({
          url: `${fileImportAPI.document.MAIN_ENDPOINT}/${uploadedFile.recordId}`,
          method: DELETE,
        });

        if (response.status === 200) {
          const filteredUploaded = uploadedFiles.filter(uploaded => uploaded.recordId !== uploadedFile.recordId);
          setUploadedFiles(filteredUploaded);

          showToaster('success', `${uploadedFile.filename} successfully deleted!`);
        } else {
          showToaster('error', `There was a problem in deleting ${uploadedFile.filename}.`);
        }
      } catch (error) {
        console.log('DELETE DOCUMENT ERROR: ', error);
        showToaster('error', `Failed to delete ${uploadedFile.filename}!`);
      } finally {
        uploadedFile.isOnDeletion = false;
      }
    }
  };

  /**
   * This function returns a status display component for an uploaded file based on its status.
   * @param uploadedFile The uploaded file for which to determine the status display.
   * @returns The React node representing the status display.
   */
  const getItemStatus = (uploadedFile: IUploadedFile) => {
    if (uploadedFile.isUploaded) {
      return (
        <Box tabIndex={0} sx={styles.uploadedFileCompleted}>
          <Typography sx={styles.statusText}>Completed</Typography>
          <span><CheckCircleOutline sx={styles.statusIcon} /> </span>
        </Box>
      )
    } else {
      return (
        <Box tabIndex={0} sx={styles.uploadedFileError}>
          <Typography sx={styles.statusText}>Error</Typography>
          <span><ErrorIcon sx={styles.statusIcon} /> </span>
        </Box>
      )
    }
  };

  return (
    <>
    <Paper sx={styles.uploadedFileItem}>
      <Grid item>
        <Box sx={styles.filenameContainer}>
          <Typography tabIndex={0} sx={styles.uploadedFileName}>
            {uploadedFile.filename} 
          </Typography>
          <Typography tabIndex={0} sx={styles.fileSize}> {formatBytes(uploadedFile.file?.size)} </Typography>
        </Box>
        <Typography tabIndex={0} sx={styles.uploadedFileDetails}>
          {uploadedFile.documentType}
          <br />
          {uploadedFile.arCollateralName}
          <br />
          {uploadedFile.endDate}
        </Typography>
      </Grid>
      <Grid item>
        <Stack direction={{ xs: "column", md: "row" }}>
          {uploadedFile.isOnDeletion || uploadedFile.isLoading
            ? <CircularProgress size={25} />
            : <>
              {getItemStatus(uploadedFile)}
              <IconButton aria-label='Delete icon' sx={styles.deleteIcon} onClick={() => setDeleteModalOpen(true)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          }
        </Stack>
      </Grid>
    </Paper>
    <ConfirmModal
      title={`Delete ${uploadedFile.filename}`}
      description='You are about to delete this item. Are you sure?'
      open={deleteModalOpen}
      alignment='left'
      errorButton={true}
      noButtonText='Cancel'
      yesButtonText='Delete'
      onClose={() => setDeleteModalOpen(false)}
      onConfirm={async () => await deleteFile(uploadedFile)}
    />
    </>
  )
}

export default FileRow;