import { useState, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Autocomplete, Box, Button, CircularProgress, Grid, IconButton, Modal, Paper, TextField, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AMERICAN_DATE_FORMAT, API_DOMAIN, GET, NON_EXISTING, POST} from '../../../../utility/constants';
import { usePrompt } from '../../../../utility/prompt';
import { checkRatesByToCurrencyIds, formatDate, getClientByIdRequest, getUpcToCurrencyIds } from '../../../../utility/helper';
import axiosInstance from '../../../../service/axiosInstance';
import { IBBPeriod, IClient } from '../../../../interfaces';
import { IIneligibleSettingsContext, IIneligibleSettingsPermissions, IUploadedDocumentsForBBPeriod, UPCCollateralDropdownProps } from '../../../../interfaces/ineligibleSettingInterface';
import { SelectedClientContext } from '../../../../context/selectedClientContext';
import { IneligibleSettingsContext } from '../../../../context/ineligibleSettingsContext';
import { CalcIneligibleContext } from '../../../../context/calneligibleContext';
import { setCalcIneligibleLoader } from '../../../../actions/calcIneligibleActions';
import ConfirmModal from '../../../modals/confirm-modal';
import { getErrors } from '../../form-container/upc-form-container';
import styles from './styles';
import WarningModal from '../../../file-import/modals/warning-modal';
import DisabledComponentsContainer from '../../../common/disabled-components-container';
import { getMostRecentlyUpdatedUPCAsOfDate, getUPCAsOfDates } from '../../../../api/ineligible-settings';
import { CalcIneligibleState } from '../../../../reducer/calcIneligibleReducer';

/**
 * This function fetches the uploaded AR aging documents for the selected collateral.
 * @param parentBorrowerId The parent borrower id.
 * @returns An array of uploaded documents from selected collateral.
 */
export const getUploadedARAgingDocuments = async (parentBorrowerId: number)  => {
  try {
    const response = await axiosInstance
      .request({
        url   : `${API_DOMAIN}/uploadedFile/search/findProcessedARAgingByParentBorrowerId`,
        method: GET,
        params: { parentBorrowerId: parentBorrowerId }
      })
    if (response.data === undefined) { throw new Error(); }
    const uploadedDocuments: IUploadedDocumentsForBBPeriod[] = response.data;
    return uploadedDocuments;
  } catch (error) { console.log('GET UPLOADED AR AGING DOCUMENTS ERROR: ', error); return []; }
}

/**
 * Component for rendering collateral header on Ineligible Settings page.
 */
const UPCCollateralHeader: React.FC<UPCCollateralDropdownProps> = (props) => {
  const { formik  }                                                                   = props;
  const { selectedClient, setSelectedClient }                                         = useContext(SelectedClientContext);
  const {
    ineligibleSettingDetails,
    fetchedIneligibleSettingDetails,
    isIneligibleDetailsLoading,
    lastSelectedClient,
    isConfirmNavigationModalOpen, setIsConfirmNavigationModalOpen,
    isFormikDirty,
    setIsInvalidIneligibleSettingsMessageModalOpen,
    asOfDates, setAsOfDates,
    mostRecentlyUpdatedAsOfDate, setMostRecentlyUpdatedAsOfDate,
    permissions
  }                                                                                   = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;
  const { state, dispatch: dispatchForCalcIneligible }                                = useContext(CalcIneligibleContext);
  const [searchParams, setSearchParams]                                               = useSearchParams();
  const [selectedAsOfDate, setSelectedAsOfDate]                                       = useState<IBBPeriod | null>(null);
  const [asOfDateInput, setAsOfDateInput]                                             = useState('');
  const [isPeriodSelectionModalOpen, setIsPeriodSelectionModalOpen]                   = useState(false);
  const [showNoExchangeRate, setShowNoExchangeRate]                                   = useState(false);
  const [isCalculatingIneligibles, setIsCalculatingIneligibles]                       = useState(false);
  const navigate                                                                      = useNavigate();

  useEffect(() => {
    if (selectedClient?.recordId === undefined) { return; }
    updateSearchParams('arCollateralId', `0`); /* upc level ineligible settings does not have selected collateral */
  }, [selectedClient]);

  useEffect(() => {
    if (!isPeriodSelectionModalOpen) { return; }
    async function fetchSelectedAsOfDate() {
      const asOfDates = await loadAsOfDates();
      const mostRecentlyUpdatedAsOfDate = await loadMostRecentlyUpdatedAsOfDate(asOfDates);
      setSelectedAsOfDate(mostRecentlyUpdatedAsOfDate);
    }
    fetchSelectedAsOfDate();
  }, [isPeriodSelectionModalOpen]);

  useEffect(() => {
    if (selectedClient === null || selectedAsOfDate === null) { return; }
    const nonNullableSelectedClient = selectedClient as Required<IClient>;
    const nonNullableSelectedAsOfDate = selectedAsOfDate as Required<IBBPeriod>;

    state.hasInitiatedSubscription && !isCalculatingIneligibles && calculateIneligibles(nonNullableSelectedClient, nonNullableSelectedAsOfDate);
    const titlesForFinishedCalculation = ['Calculation Finished', 'Calculation Failed'];
    !state.isLoading && titlesForFinishedCalculation.includes(state.title) && setIsCalculatingIneligibles(false);
  }, [state, selectedClient, selectedAsOfDate]);

  /**
   * This function initiates the calculation of the ineligible report.
   */
  const calculateIneligibles = async (selectedClient: Required<IClient>, selectedAsOfDate: Required<IBBPeriod>) => {
    const formattedDate = selectedAsOfDate.endDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
    await axiosInstance.request({
      url: `${API_DOMAIN}/ar/calculateIneligibles/ineligibleSettingsForUPC`,
      method: POST,
      params: {
        parentBorrowerId: selectedClient.recordId,
        endDate: formattedDate
      }
    });
    setIsCalculatingIneligibles(true);
  };

  const loadAsOfDates = async () => {
    if (asOfDates !== 'UNLOADED') { return asOfDates; }
    const loadedAsOfDates = await getUPCAsOfDates(selectedClient as Required<IClient>);
    setAsOfDates(loadedAsOfDates);
    return loadedAsOfDates;
  };

  const loadMostRecentlyUpdatedAsOfDate = async (asOfDates: IBBPeriod[]) => {
    if (mostRecentlyUpdatedAsOfDate !== 'UNLOADED') { return mostRecentlyUpdatedAsOfDate; }
    const loadedMostRecentlyUpdatedAsOfDate = await getMostRecentlyUpdatedUPCAsOfDate(selectedClient as Required<IClient>, asOfDates);
    setMostRecentlyUpdatedAsOfDate(loadedMostRecentlyUpdatedAsOfDate);
    return loadedMostRecentlyUpdatedAsOfDate;
  };

  /**
   * This function handles the closing of the period selection modal.
   */
  const handleClose = () => {
    setIsPeriodSelectionModalOpen(false);
  };

  /**
   * This function handles the action when the user decides to discard changes.
   * It closes the confirm navigation modal, resets the form, and reverts to the previous client and collateral selections
   */
  const handleDiscardChanges = () => {
    setIsConfirmNavigationModalOpen(false);
    formik.resetForm();
    setSelectedClient(lastSelectedClient);
  };

  /**
   * This function updates the search parameters in the URL.
   * @param key The key to update in the search parameters
   * @param value The new value to set for the key
   */
  const updateSearchParams = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  /**
   * This function handles the action when the user proceeds with the calculation of ineligibles.
   * It checks the existence of an exchange rate and then initiates the calculation process.
   */
  const handleProceed = async () => {
    const hasRate = await hasExchangeRate();
    if(!hasRate) return setShowNoExchangeRate(true)

    const nonNullableSelectedClient = selectedClient as NonNullable<IClient> & Required<IClient>;
    const nonNullableSelectedAsOfDate = selectedAsOfDate as NonNullable<IBBPeriod> & Required<IBBPeriod>;
    const initiatorPayLoad: CalcIneligibleState = { /* to initiate subscription to stomp client, before actual loading starts */
      title: 'Calculating Ineligible Report',
      content: `Ineligible Report - ${nonNullableSelectedClient.borrowerName}`,
      hasInitiatedSubscription: false,
      isLoading: true,
      show: true,
      link: `/reports/ar-ineligible?clientId=${nonNullableSelectedClient.recordId}&endDate=${nonNullableSelectedAsOfDate.endDate}`,
      cancelled: false,
      selectedClientId: nonNullableSelectedClient.recordId,
      selectedCollateralId: NON_EXISTING,
      isParentClientLevel: true,
      failed: false,
      ineligibleSettings: fetchedIneligibleSettingDetails.map(detail => detail.ineligibleSetting),
    };
    dispatchForCalcIneligible(setCalcIneligibleLoader({ ...initiatorPayLoad }));
    setIsPeriodSelectionModalOpen(false);
  };

  const hasExchangeRate = async () => {
    const nonNullSelectedClientId = selectedClient?.recordId as number;
    const nonNullSelectedAsOfDate = selectedAsOfDate as NonNullable<IBBPeriod>;
    const response = await getClientByIdRequest(nonNullSelectedClientId);
    const asOfDate = formatDate(nonNullSelectedAsOfDate.endDate, 'YYYYMMDD');
    const toCurrencyIds = await getUpcToCurrencyIds(nonNullSelectedClientId, asOfDate) as number[];

    if (response) {
      const rate = await checkRatesByToCurrencyIds(
        nonNullSelectedClientId,
        parseInt(response.data.currencyId),
        toCurrencyIds,
        asOfDate
      );

      return Boolean(rate);
    } else return false;
  };

  /**
   * This function checks if all ineligible settings are disabled.
   * @returns True if all ineligible settings are disabled, otherwise false
   */
  const isAllIneligiblesDisabled = () => ineligibleSettingDetails.every(detail => detail.ineligibleSetting.disabled);

  /**
   * This customized hook prompts user if there are unsaved changes
   */
  usePrompt(`You have unsaved changes. Are you sure you want to leave this page?`, isFormikDirty);

  return (
    <Paper sx={styles.paperStyle}>
      <Modal
        open={isPeriodSelectionModalOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Grid container sx={styles.modalContainer}>
          <Grid item xs={12} marginBottom={2}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Typography tabIndex={0} id='modal-modal-title' variant='h6' component='h2'>
                Select Period
              </Typography>
              <IconButton aria-label='Close icon' onClick={handleClose}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} borderTop={1} borderColor='#E5E2E2'></Grid>
          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <Grid item xs={12} sx={{display: 'flex', justifyContent: 'spacebetw-between'}}>
                <Typography 
                  tabIndex={0}
                  component='label'
                  variant='h6'
                  align='center'
                  htmlFor={`collateral-combo-box`}
                  sx={styles.labelStyle}
                >
                  AR Collateral
                </Typography>
                <Autocomplete
                  id={`collateral-combo-box`}
                  blurOnSelect
                  disablePortal
                  options={[]}
                  value={null}
                  size='small'
                  fullWidth
                  sx={styles.comboBox}
                  disabled
                  renderInput={(params) => (
                    <Tooltip title='Cannot select a Collateral on the Parent Client Level'>
                      <span>
                        <TextField 
                          {...params}
                          placeholder='All'
                        />
                      </span>
                    </Tooltip>
                  )}
                  aria-label='Collateral Dropdown'
                  componentsProps={{clearIndicator:{'aria-label':'Remove the selected item'}}}
                />
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <Grid item xs={12} sx={{display: 'flex', justifyContent: 'spacebetw-between'}}>
                <Typography 
                  tabIndex={0}
                  component='label'
                  variant='h6'
                  align='center'
                  htmlFor={`asOfDate-combo-box`}
                  sx={styles.labelStyle}
                  pr='1.1rem'
                >
                  As of Date
                </Typography>
                <Autocomplete
                  id={`asOfDate-combo-box`}
                  blurOnSelect
                  disablePortal
                  getOptionLabel={(asOfDates) => formatDate(asOfDates.endDate, AMERICAN_DATE_FORMAT)}
                  inputValue={asOfDateInput}
                  options={asOfDates !== 'UNLOADED' ? asOfDates : []}
                  value={selectedAsOfDate}
                  size='small'
                  fullWidth
                  sx={styles.comboBox}
                  disabled={selectedClient === null}
                  loading={[asOfDates, mostRecentlyUpdatedAsOfDate].includes('UNLOADED')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={'Please Select'}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            { [asOfDates, mostRecentlyUpdatedAsOfDate].includes('UNLOADED') ? <CircularProgress color='inherit' size={15} /> : null }
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  onInputChange={(_event, newInputValue) => setAsOfDateInput(newInputValue)}
                  onChange={(_event, newValue: IBBPeriod | null) => setSelectedAsOfDate(newValue)}
                  aria-label='Collateral Dropdown'
                  componentsProps={{clearIndicator:{'aria-label':'Remove the selected item'}}}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='flex-end'>
              <Button
                variant='outlined'
                size='small'
                disableElevation
                onClick={handleClose}
                sx={styles.cancelButton}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                size='small'
                disableElevation
                disabled={selectedAsOfDate === null || selectedClient === null}
                //check exchangeRate existence here
                onClick={() => handleProceed()}
                sx={styles.saveButton}
              >
                Proceed
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Box sx={styles.rowBox}>
        <Typography 
          tabIndex={0}
          component='label'
          variant='h6'
          align='center'
          htmlFor='collateral-combo-box'
          sx={styles.textStyle}
        >
          Collateral
        </Typography>
        <Autocomplete
          id={`collateral-combo-box`}
          blurOnSelect
          disablePortal
          options={[]}
          value={null}
          size='small'
          fullWidth
          sx={styles.comboBox}
          disabled={selectedClient === null || isIneligibleDetailsLoading || selectedClient?.parentClient}
          renderInput={(params) => (
            <Tooltip title='Cannot select a Collateral on the Parent Client Level'>
              <span>
                <TextField 
                  {...params}
                  placeholder='All'
                />
              </span>
            </Tooltip>
          )}
        />
        <DisabledComponentsContainer isDisabled={isIneligibleDetailsLoading || selectedClient === null || isFormikDirty || isAllIneligiblesDisabled() || !(permissions as IIneligibleSettingsPermissions).canCalculateIneligibles}>
          <Button
            variant='outlined'
            disabled={isIneligibleDetailsLoading || selectedClient === null || isFormikDirty || isAllIneligiblesDisabled() || !(permissions as IIneligibleSettingsPermissions).canCalculateIneligibles}
            aria-label={isIneligibleDetailsLoading || selectedClient === null || isFormikDirty || isAllIneligiblesDisabled() || !(permissions as IIneligibleSettingsPermissions).canCalculateIneligibles ? 'Calculate Ineligibles button disabled' : 'Calculate Ineligibles'}
            sx={styles.calculateButton}
            onClick={async () => {
              const hasError = await getErrors(formik);
              if (hasError) { setIsInvalidIneligibleSettingsMessageModalOpen(true); return; }
              setIsPeriodSelectionModalOpen(true);
            }}
          >
            Calculate Ineligibles
          </Button>
        </DisabledComponentsContainer>
      </Box>
      <ConfirmModal
        open={isConfirmNavigationModalOpen}
        onConfirm={() => setIsConfirmNavigationModalOpen(false)}
        onClose={() => handleDiscardChanges()}
        onButtonClose={() => setIsConfirmNavigationModalOpen(false)}
        promptChecker={true}
        title='Confirm Navigation'
        description='You have unsaved changes. Are you sure you want to leave this page?'
        yesButtonText='Keep Editing'
        noButtonText='Discard Changes'
        confirmOnly
      />
      <WarningModal
        open={showNoExchangeRate}
        onClose={() => setShowNoExchangeRate(false)}
        onConfirm={() => navigate(`/clients/${selectedClient?.recordId}/settings/exchangeRate`)}
        noDataIncluded
        issueType='error'
        issueMessages={["There is no exchange rate yet for the selected 'As of Date' and collateral currency. Click Proceed to visit Exchange Rate page"]}
      />
    </Paper>
  )
};

export default UPCCollateralHeader;
