import { Box } from '@mui/material';
import IneligibleAdjustmentsPanelHeader from '../panel-header';
import IneligibleAdjustmentsTable from '../table';

const AccountsReceivablePanel: React.FC = () => {
  return (
    <Box>
      <IneligibleAdjustmentsPanelHeader ineligibleType='AR' />
      <IneligibleAdjustmentsTable ineligibleType='AR' />
    </Box>
  );
};

export default AccountsReceivablePanel;
