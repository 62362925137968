const styles = {
  headerBackground: {
    backgroundColor: '#FEFEFE',
    py: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '24px'
  },
  headerTitleText: {
    fontWeight: 'bold',
    color: '#010953',
    fontSize: '18px',
    ml: '24px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  backIcon: {
    '&.MuiSvgIcon-root': {
      fontSize: '15px',
    },
  },
  buttonLeftSpace: {
    margin: '5px',
    marginRight: '0px',
    height: '32px'
  }
};

export default styles;
