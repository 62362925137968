import colors from "../../../utility/colors";

const styles = {
  breadcrumbsContainer: {
    marginLeft: '-1.5rem'
  },
  chip: {
    fontSize: '12px',
    height: '1.2rem'
  },
  clientDropdownContainer: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  clientDropdownBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdown: {
    fontSize: '14px',
    backgroundColor: 'white',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      fontSize: '14px',
      backgroundColor: 'white',
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  dropdownsContainer: {
    marginTop: '1rem',
    marginBottom: '1.5rem',
  },
  generateReportButton: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '10rem',
    height: '2rem',
    marginTop: '2.05rem'
  },
  headerActionWrapper: {
    display: 'flex',
    gap: '0.5rem'
  },
  headerButtons: {
    height: '2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  headerContainer: {
    backgroundColor: '#FEFEFE',
    paddingX: '1.5rem',
    paddingY: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerTitle: {
    color: colors.PRIMARY_DARK,
    fontSize: '18px',
    fontWeight: 'bold',
  },
  label: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  labelContainer: {
    marginBottom: '0.5rem',
  },
  linkButton: {
    marginRight: '1rem'
  },
  pageContainer: {
    paddingX: '1.5rem',
  },
  paperContainer: {
    paddingY: '1rem',
    boxShadow: '0px 3px 6px #00000029',
  },
  hidden: {
    display: 'none'
  }
};
export default styles;