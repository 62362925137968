const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FEFEFE',
    padding: 3,
    marginTop: '1rem',
    marginBottom: '1rem',
    marginLeft: '1.4rem',
    marginRight: '1.4rem'
  },
  gridSetting: {
    width: '50%',
  },
  fieldText: {
    color: '#212529',
    fontSize: '14px',
    display: 'block',
    marginBottom: 1,
  },

  dropdown: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
  },

  dropdownMenu: {
    minWidth: 'fit-content',
  },

  dropdownGrid: {
    width: '50%',
  },

  dropdownText: {
    fontSize:'14px'
  },

  totalLoanBalanceInput: {
    backgroundColor: '#EBEBEB',
    color: '#868D96',
    borderColor: '#E0E0E0',
    '& .MuiOutlinedInput-input': {
      padding: '0.5rem',
      textAlign: 'right',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '.9rem',
      backgroundColor: 'white',
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
      WebkitTextFillColor: "#000!important",
    },
    mt: '12px',
  },

  rightAlignedText: {
    textAlign: 'right',
  },

  totalValueGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  filler: {
    display: { md:'none', lg: 'block' }
  },

  totalAmountContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  labelTotalAmount: {
    fontSize: '14px',
    fontWeight: '600',
    marginRight: '5px',
    whiteSpace: 'nowrap',
  },
  valueTotalAmount: {
    fontSize: '14px',
  },
};

export default styles;
