import { useState, MouseEvent } from 'react';
import { Button, Menu, MenuItem, ButtonProps } from '@mui/material';
import styles from './styles';
import DisabledComponentsContainer from '../disabled-components-container';

interface IMenuItem {
  label: string;
  handleSelect: () => void;
}

interface IMenuButtonProps {
  label: string;
  options: IMenuItem[];
  buttonProps: ButtonProps;

}

const MenuButton = (props: IMenuButtonProps) => {

  const { label, options, buttonProps } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <>
      <DisabledComponentsContainer isDisabled={buttonProps.disabled ?? false}>
        <Button
          {...buttonProps}
          onClick={handleClick}
          data-testid='export-button'
          aria-label={buttonProps.disabled ? `${label} button disabled` : `${label}`}
        >
          { label }
        </Button>
      </DisabledComponentsContainer>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option.label}
              data-testid={option.label}
              onClick={() =>{
                option.handleSelect();
                handleClose();
              }}
              sx={styles.menuItem}
              >
              {option.label}</MenuItem>
          ))}
      </Menu>
    </>
  )
};

export default MenuButton;
