import { createContext, useMemo, useState } from 'react';
import { IARCollateral, IBBPeriod } from '../interfaces';
import { LayoutProps } from './authContext';
import { ICurrency, IRate } from '../interfaces/multiCurrencyInterface';
import { IAccountsReceivableSetting } from '../components/client-settings/tabs/tab-panel-contents/accounts-receivable';
import { IAPAgingReport, IAPAgingReportContext, IAPAgingReportVendorDetail, IAPAgingReportPermissions, IAPAgingSortParams, IBucketType, IUPCAPAgingReport, IUPCAPAgingReportVendorDetail } from '../interfaces/apAgingReportInterface';

export const APAgingReportContext = createContext<IAPAgingReportContext | null >(null);

const APAgingReportProvider = (props: LayoutProps) => {
  const { children }                                                          = props;

  /** SELECTED STATE VALUES */
  const [selectedCollateral, setSelectedCollateral]                           = useState<IARCollateral | null>(null);
  const [selectedAsOfDate, setSelectedAsOfDate]                               = useState<IBBPeriod | null>(null);
  const [selectedType, setSelectedType]                                       = useState<IBucketType | null>(null);
  const [selectedCurrency, setSelectedCurrency]                               = useState<ICurrency | null>(null);

  const [arCollaterals, setARCollaterals]                                     = useState<IAccountsReceivableSetting[]>([]);
  const [asOfDates, setAsOfDates]                                             = useState<IBBPeriod[]>([]);
  const [bucketTypes, setBucketTypes]                                         = useState<IBucketType[]>([]);
  const [currencies, setCurrencies]                                           = useState<ICurrency[]>([]);
  const [exchangeRates, setExchangeRates]                                     = useState<IRate[]>([]);
  const [buckets, setBuckets]                                                 = useState<string[]>([]);
  const [reports, setReports]                                                 = useState<(IAPAgingReport | IUPCAPAgingReport)[]>([]);
  const [reportVendorDetails, setReportVendorDetails]                         = useState<{[detailID: string]: (IAPAgingReportVendorDetail | IUPCAPAgingReportVendorDetail)[]}>({});

  const [isLoading, setIsLoading]                                             = useState<boolean>(false);
  const [hasGeneratedReport, setHasGeneratedReport]                           = useState<boolean>(false);
  const [sortParams, setSortParams]                                           = useState<IAPAgingSortParams>({ orderBy: 'arVendorName', order: 'asc' });
  const [filterText, setFilterText]                                           = useState<string>('');
  const [showNoExchangeRate, setShowNoExchangeRate]                           = useState(false);

  /**  PERMISSIONS */
  const [permissions, setPermissions]                                         = useState<IAPAgingReportPermissions | null>(null);

  const value = useMemo(() => ({
    selectedCollateral, setSelectedCollateral,
    selectedAsOfDate, setSelectedAsOfDate,
    selectedType, setSelectedType,
    selectedCurrency, setSelectedCurrency,
    arCollaterals, setARCollaterals,
    asOfDates, setAsOfDates,
    bucketTypes, setBucketTypes,
    currencies, setCurrencies,
    exchangeRates, setExchangeRates,
    buckets, setBuckets,
    reports, setReports,
    reportVendorDetails, setReportVendorDetails,
    isLoading, setIsLoading,
    hasGeneratedReport, setHasGeneratedReport,
    sortParams, setSortParams,
    filterText, setFilterText,
    showNoExchangeRate, setShowNoExchangeRate,
    permissions, setPermissions
  }), [
    selectedCollateral, setSelectedCollateral,
    selectedAsOfDate, setSelectedAsOfDate,
    selectedType, setSelectedType,
    selectedCurrency, setSelectedCurrency,
    arCollaterals, setARCollaterals,
    asOfDates, setAsOfDates,
    bucketTypes, setBucketTypes,
    currencies, setCurrencies,
    exchangeRates, setExchangeRates,
    buckets, setBuckets,
    reports, setReports,
    reportVendorDetails, setReportVendorDetails,
    isLoading, setIsLoading,
    hasGeneratedReport, setHasGeneratedReport,
    sortParams, setSortParams,
    filterText, setFilterText,
    showNoExchangeRate, setShowNoExchangeRate,
    permissions, setPermissions
  ]);

  return (
    <APAgingReportContext.Provider
      value={value}
    >
      {children}
    </APAgingReportContext.Provider>
  );
};

export default APAgingReportProvider;
