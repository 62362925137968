const styles = {
  detailsBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  form: {
    width: '100%',
    overflow: 'auto',
  },
  hidden: {
    display: 'none',
  },
  noDataPlaceHolderContainer: {
    marginX: '8px',
    marginTop: '8px',
  },
  noDataPlaceHolderText: {
    fontSize: '16px',
  }
};

export default styles;
