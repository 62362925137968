import { Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const OverflowText = ({ children }) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const textElementRef = useRef<any>();

  useEffect(() => {
    setIsOverflowed(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, []);

  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowText;
