import { ChangeEvent, MouseEvent, useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, CircularProgress, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tabs, Typography } from '@mui/material';
import { IARCustomerByContras, IARVendorByContras, IIneligibleSettingsContext } from '../../../../../interfaces/ineligibleSettingInterface';
import { IneligibleSettingsContext } from '../../../../../context/ineligibleSettingsContext';
import styles from './styles';
import { IARCollateral, IARCustomer, IARVendor, IClient } from '../../../../../interfaces';
import { getLabelDisplayedRows, getLabelRowsPerPage } from '../../../../client-settings/tabs/tab-panel-contents/pagination';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { ExpandMore } from "@mui/icons-material";
import _ from 'lodash';
import { formatDate } from '../../../../../utility/helper';
import { useNavigate } from 'react-router-dom';
import { SelectedClientContext } from '../../../../../context/selectedClientContext';
import NoDataPlaceholder from '../../../../common/no-data-placeholder';
import OverflowText from '../../../../common/overflow-text';
import { getCustomersByContrasByARCollateralId, getUPCCustomersByContrasByBorrowerId, getUPCVendorsByContrasByBorrowerId, getParentCustomersByARCollateralId, getVendorsByContrasByARCollateralId, getUPCParentCustomersByBorrowerId } from '../../../../../api/ineligible-settings';

interface TabPanelProps {
  children?           : React.ReactNode;
  index               : number;
  selectedTabIndex    : number;
}

interface ISortParams {
  sortBy: string;
  direction: string;
}

interface IARCustomersWithRelationships extends IARCustomer {
  childCustomers: IARCustomer[];
  linkedVendors: IARVendor[];
};

const TABS: { label: 'View By Customer' | 'View By Vendor'}[] = [ { label: 'View By Customer' }, { label: 'View By Vendor' } ];
  const HEADERS_FOR_VIEW_BY_CUSTOMER = [
    { id: 'cust_name', name: 'Customer Name', type: 'string' },
    { id: 'cust_src_id', name: 'Customer ID', type: 'string' },
    { id: 'created_at', name: 'Date Created', type: 'date'   },
  ];
  const HEADERS_FOR_VIEW_BY_VENDOR = [
    { id: 'vendor_name', name: 'Vendor Name', type: 'string' },
    { id: 'vendor_src_id', name: 'Vendor ID', type: 'string' },
    { id: 'created_at', name: 'Date Created', type: 'date'   },
    { id: 'cust_name', name: 'Customer Name', type: 'string' },
    { id: 'cust_src_id', name: 'Customer ID', type: 'string' },
  ];
  const VALUES_FOR_VIEW_BY_VENDOR = [ 'vendorName', 'vendorSrcId', 'createdAt', 'custName', 'custSrcId' ];
  const GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS = [
    {
      '&.MuiGrid-item': {
        flexBasis: '40%', maxWidth: '3408%', paddingLeft: '28px'
      }
    },
    {
      '&.MuiGrid-item': {
        flexBasis: '40%', maxWidth: '40%',
      }
    },
    {
      '&.MuiGrid-item': {
        flexBasis: '20%', maxWidth: '20%',
      }
    },
  ];
  const GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS_ACCORDION_SUMMARY = [
    {
      '&.MuiGrid-item': {
        flexBasis: 'calc(40% - 16px)', maxWidth: 'calc(40% - 16px)' /* subtrahend (number after minus), is offset for other elements occupying cell */
      }
    },
    {
      '&.MuiGrid-item': {
        flexBasis: 'calc(40% + 21px)', maxWidth: 'calc(40% + 21px)', /* right addend (number after plus), is offset for extra space from other cells */
      }
    },
    {
      '&.MuiGrid-item': {
        flexBasis: 'calc(20% - 5px)', maxWidth: 'calc(20% - 5px)', /* subtrahend (number after minus), is offset for other elements occupying cell */
      }
    },
  ];

const TabPanel = (props: TabPanelProps) => {
  const { children, selectedTabIndex, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={selectedTabIndex !== index}
      id={`contra-settings-tabpanel-${index}`}
      aria-labelledby={`contra-settings-tab-${index}`}
      {...other}
    >
      {selectedTabIndex === index && <Box sx={styles.tabPanelBox}>{children}</Box>}
    </div>
  );
};

/**
 * Component responsible for rendering and handling the Credit Limit settings for an override.
 * @param props The props for the component.
 * @returns The rendered CreditLimitSettings component.
 */
const ContraSettings = () => {
  const navigate = useNavigate();
  const { selectedClient } = useContext(SelectedClientContext);
  const { selectedCollateral, parentCustomers, setParentCustomers }       = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const [customers, setCustomers]                                           = useState<IARCustomerByContras[]>([]);
  const [vendors, setVendors]                                               = useState<IARVendorByContras[]>([]);
  const [selectedTabIndex, setSelectedTabIndex]                             = useState(TABS.findIndex(tab => tab.label === 'View By Customer'));
  const [page, setPage]                                                     = useState<number>(0);
  const [rowsPerPage, setRowsPerPage]                                       = useState<number>(10);
  const [count, setCount]                                                   = useState<number>(0);
  const [customerSortParams, setCustomerSortParams]                         = useState<ISortParams>({ sortBy: 'cust_name', direction: 'ASC' });
  const [vendorSortParams, setVendorSortParams]                             = useState<ISortParams>({ sortBy: 'vendor_name', direction: 'ASC' });
  const [isLoading, setIsLoading]                                           = useState<boolean>(false);
  const [scrolledRowIndex, setScrolledRowIndex]                             = useState(0);
  const NUMBER_OF_ROWS_PER_SCROLL                                           = 15;

  useEffect(() => {
    loadParentCustomers();
  });

  useEffect(() => {
    selectedTabIndex === TABS.findIndex(tab => tab.label === 'View By Customer')
      ? getPaginatedCustomers(page, rowsPerPage, customerSortParams)
      : getPaginatedVendors(page, rowsPerPage, vendorSortParams);
  }, [page, rowsPerPage, customerSortParams, vendorSortParams]);

  const hasMoreRowsToRender = useMemo(() => {
    const selectedList = selectedTabIndex === TABS.findIndex(tab => tab.label === 'View By Customer') ? customers : vendors;
    const lengthOfRenderedRows = selectedList.slice(0, scrolledRowIndex * NUMBER_OF_ROWS_PER_SCROLL + NUMBER_OF_ROWS_PER_SCROLL).length;
    return selectedList.length > lengthOfRenderedRows;
  }, [customers, scrolledRowIndex, selectedTabIndex, vendors]);

  const observer = useRef<any>();
  const rowElementRef = useCallback((node: any) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => (entries[0].isIntersecting && hasMoreRowsToRender) && setScrolledRowIndex((prevValue) => prevValue + 1))
    if (node) observer.current.observe(node);
  }, [isLoading, hasMoreRowsToRender]);

  const handleIcon     = useCallback(() => <UnfoldMoreIcon sx={styles.iconDefaultSort} aria-label='Sort option icon'/>, []);

  const parentCustomerIds = useMemo (() => parentCustomers !== 'UNLOADED' ? parentCustomers.map(customer => customer.recordId) : [], [parentCustomers]);

  const isSelectedClientUPC = () => (selectedClient as IClient).parentClient;

  const getPaginatedCustomers = async (page: number, rowsPerPage: number, customerSortParams: ISortParams) => {
    const pageSize = rowsPerPage === -1 ? count : rowsPerPage;
    setIsLoading(true);
    const paginatedCustomers = isSelectedClientUPC()
      ? await getUPCCustomersByContrasByBorrowerId(
        (selectedClient as IClient).recordId as number,
        page, pageSize,
        `${customerSortParams.sortBy},${customerSortParams.direction}`
      )
      : await getCustomersByContrasByARCollateralId(
        (selectedCollateral as IARCollateral).recordId,
        page, pageSize,
        `${customerSortParams.sortBy},${customerSortParams.direction}`
      );
    setCustomers(paginatedCustomers.content);
    setCount(paginatedCustomers.totalElements);
    setIsLoading(false);
    setScrolledRowIndex(0);
  };

  const getPaginatedVendors = async (page: number, rowsPerPage: number, vendorSortParams: ISortParams) => {
    const pageSize = rowsPerPage === -1 ? count : rowsPerPage;
    setIsLoading(true);
    const paginatedVendors = isSelectedClientUPC()
      ? await getUPCVendorsByContrasByBorrowerId(
        (selectedClient as IClient).recordId as number,
        page, pageSize,
        `${vendorSortParams.sortBy},${vendorSortParams.direction}`
      )
      : await getVendorsByContrasByARCollateralId(
        (selectedCollateral as IARCollateral).recordId,
        page, pageSize,
        `${vendorSortParams.sortBy},${vendorSortParams.direction}`
      );
    setVendors(paginatedVendors.content);
    setCount(paginatedVendors.totalElements);
    setIsLoading(false);
    setScrolledRowIndex(0);
  };

  const loadParentCustomers = async () => {
    if (parentCustomers !== 'UNLOADED') { return; }
    const loadedParentCustomers = isSelectedClientUPC()
      ? await getUPCParentCustomersByBorrowerId((selectedClient as Required<IClient>).recordId)
      : await getParentCustomersByARCollateralId((selectedCollateral as IARCollateral).recordId);
    setParentCustomers(loadedParentCustomers);
  };

  const handleSortOnVendors = (headerId: string) => {
    setVendorSortParams({
      sortBy: headerId,
      direction: vendorSortParams.direction === 'DESC' || vendorSortParams.sortBy !== headerId ? 'ASC' : 'DESC'
    });
  };

  const handleSortOnCustomers = (headerId: string) => {
    setCustomerSortParams({
      sortBy: headerId,
      direction: customerSortParams.direction === 'DESC' || customerSortParams.sortBy !== headerId ? 'ASC' : 'DESC'
    });
  };

  const getCellText = (text: string | undefined, formatToDate?: boolean) => {
    const formattedText = (text !== undefined && formatToDate) ? formatDate(text, 'MM/DD/YYYY') : text;
    return (
      <Typography sx={styles.cellText}>
        <OverflowText>
          {formattedText}
        </OverflowText>
      </Typography>
    );
  };

  /**
   * This useCallback is used to handle changes on pagination page.
   */
  const handleChangePage   = useCallback((_event: MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage), []);

  /**
   * This useCallback is used to handle the changes on the default size of the pagination.
   */
  const handleChangeRowsPerPage = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const isContraLoading = () => isLoading || parentCustomers === 'UNLOADED';

  const getLoadingIcon = () => <Box sx={styles.loadingContainer}><CircularProgress size={30} /></Box>;

  const getCustomerAccordion = (customer: IARCustomersWithRelationships, index: number) => {
    return (
      <Accordion
        elevation={0}
        disableGutters
        sx={{ bgcolor: index % 2 !== 0 ? 'background.paper' : '#F7F7F7', position: 'static' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={styles.accordionSummary}
        >
          <Grid container>
            <Grid item xs={5} sx={{ ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS_ACCORDION_SUMMARY[0] }}>
              <Box sx={styles.textContainer}>
                { getCellText(`${customer.custName} (${customer.childCustomers.length + customer.linkedVendors.length})`) }
                { parentCustomerIds.includes(customer.recordId) && <Chip size='small' sx={styles.parentIdentifierChip} />}
              </Box>
            </Grid>
            <Grid item xs={5} sx={{ ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS_ACCORDION_SUMMARY[1] }}>
              <Box  sx={styles.textContainer}>
                { getCellText(customer.custSrcId) }
              </Box>
            </Grid>
            <Grid item xs={2} sx={{ ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS_ACCORDION_SUMMARY[2] }}>
              <Box sx={styles.textContainer}>
                { getCellText(customer.createdAt, true) }
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={styles.gridMainCustomerAccordionDetail}>
          <Grid container>
            <Grid item xs={5} sx={{ ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS[0] }}>
              <Box tabIndex={0} sx={styles.gridSubHeader}>
                Accounts Name
              </Box>
            </Grid>
            <Grid item xs={5} sx={{  ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS[1] }}>
              <Box tabIndex={0} sx={styles.gridSubHeader}>
                Accounts ID
              </Box>
            </Grid>
            <Grid item xs={2} sx={{ ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS[2] }}>
              <Box tabIndex={0} sx={styles.gridSubHeader}>
                Date Created
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
        {
          customer.childCustomers.map(childCustomer => (
            <AccordionDetails sx={styles.gridChildCustomerAccordionDetail} key={`child-customer-${childCustomer.recordId}`}>
              <Grid container>
                <Grid item xs={5} sx={{ ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS[0] }}>
                  <Box tabIndex={0} sx={styles.textContainer}>
                    { getCellText(childCustomer.custName) }
                  </Box>
                </Grid>
                <Grid item xs={5} sx={{  ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS[1] }}>
                  <Box tabIndex={0} sx={styles.textContainer}>
                    { getCellText(childCustomer.custSrcId) }
                  </Box>
                </Grid>
                <Grid item xs={2} sx={{ ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS[2] }}>
                  <Box tabIndex={0} sx={styles.textContainer}>
                    { getCellText(childCustomer.createdAt, true) }
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          ))
        }
        {
          customer.linkedVendors.map(vendor => (
            <AccordionDetails sx={styles.gridVendorAccordionDetail} key={`linked-vendor-${vendor.recordId}`}>
              <Grid container>
                <Grid item xs={5} sx={{ ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS[0] }}>
                  <Box tabIndex={0} sx={styles.textContainer}>
                    { getCellText(vendor.vendorName) }
                  </Box>
                </Grid>
                <Grid item xs={5} sx={{  ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS[1] }}>
                  <Box tabIndex={0} sx={styles.textContainer}>
                    { getCellText(vendor.vendorSrcId) }
                  </Box>
                </Grid>
                <Grid item xs={2} sx={{ ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS[2] }}>
                  <Box tabIndex={0} sx={styles.textContainer}>
                    { getCellText(vendor.createdAt, true) }
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          ))
        }
      </Accordion>
    );
  };

  const getViewByCustomer = () => {
    if (isContraLoading()) { return getLoadingIcon();  }
    if (customers.length === 0) { return <NoDataPlaceholder />; }
    return (
      <>
        <Grid container sx={styles.gridContainer}>
          {HEADERS_FOR_VIEW_BY_CUSTOMER.map((header, index) => (
            <Grid
              item xs={3}
              sx={{ ...styles.gridHead, ...GRID_ITEM_STYLES_FOR_VIEW_BY_CUSTOMERS[index] }}
              key ={`header-for-${header.id}`}>
              <TableSortLabel
                active={customerSortParams.sortBy === header.id}
                direction={customerSortParams.direction === 'DESC' ? 'desc' : 'asc'}
                onClick={() => handleSortOnCustomers(header.id)}
                aria-label={`${header.name} sort`}
                { ...(customerSortParams.sortBy !== header.id && { IconComponent: handleIcon }) }
              >
                {header.name}
              </TableSortLabel>
            </Grid>
          ))}
        </Grid>
        <Box sx={styles.tableSpacer}></Box>
        {
          customers
            .slice(0, scrolledRowIndex * NUMBER_OF_ROWS_PER_SCROLL + NUMBER_OF_ROWS_PER_SCROLL)
            .map((customer: IARCustomersWithRelationships, index) => (
              <Box ref={rowElementRef} sx={styles.tableRow} key={customer.recordId}>
                { getCustomerAccordion(customer, index) }
              </Box>
            )
          )
        }
      </>
    );
  };

  const getViewByVendor = () => {
    if (isContraLoading()) { return getLoadingIcon(); }
    if (vendors.length === 0) { return <NoDataPlaceholder />; }
    return (
      <TableContainer sx={styles.tableContainerForVendor}>
        <Table aria-label='customized table' size='small' sx={styles.table}>
          <TableHead>
            <TableRow sx={styles.tableHeadRow}> 
              {HEADERS_FOR_VIEW_BY_VENDOR.map(header => (
                <TableCell width={'25%'} key={header.id} sx={styles.tableHead}>
                  <TableSortLabel
                    active={vendorSortParams.sortBy === header.id}
                    direction={vendorSortParams.direction === 'DESC' ? 'desc' : 'asc'}
                    onClick={() => handleSortOnVendors(header.id)}
                    aria-label={`${header.name} sort`}
                    { ...(vendorSortParams.sortBy !== header.id && { IconComponent: handleIcon }) }
                  >
                    {header.name}
                  </TableSortLabel>
                </TableCell>)
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={styles.tableSpacer}></TableRow>
          </TableBody>
          <TableBody>
            {
              vendors
                .slice(0, scrolledRowIndex * NUMBER_OF_ROWS_PER_SCROLL + NUMBER_OF_ROWS_PER_SCROLL)
                .map((vendor: IARVendor) => (
                  <TableRow ref={rowElementRef} sx={styles.tableRow} key={vendor.recordId}>
                    {VALUES_FOR_VIEW_BY_VENDOR.map((valueForView) =>
                      <TableCell
                        tabIndex={0}
                        key={`vendorId: ${vendor.recordId}, columnId: ${valueForView}`}
                        sx={styles.tableCell}
                      >
                        { getCellText(vendor[`${valueForView}`], valueForView === 'createdAt') }
                      </TableCell>
                    )}
                  </TableRow>
                )
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Box sx={styles.navigateButtonContainer}>
        <Button
          variant='contained'
          onClick={() => navigate(`/clients/${selectedClient?.recordId}/settings/${selectedCollateral?.recordId}/vendors/edit`) }
          sx={styles.navigateButton}
        >
          <Typography sx={styles.navigateButtonText}>
            Edit Vendor/Customer
          </Typography>
        </Button>
      </Box>
      <Box component={Paper} sx={styles.tabsContainer}>
        <Tabs
          sx={styles.buttonSubTabs}
          value={selectedTabIndex}
          onChange={(_e, value) => {
            setSelectedTabIndex(value);
            setPage(0);
            setRowsPerPage(10);
            setCustomerSortParams({ sortBy: 'cust_name', direction: 'ASC' });
            setVendorSortParams({ sortBy: 'vendor_name', direction: 'ASC' });
            setCustomers([]);
            setVendors([]);
          }}
        >
          <Tab
            tabIndex={0}
            label='View By Customer'
            id='0'
            aria-controls='vendor-by-customer-list-view'
          />
          <Tab
            tabIndex={0}
            label='View By Vendor'
            id='1'
            aria-controls='vendor-list-view'
          />
        </Tabs>
        <TabPanel selectedTabIndex={selectedTabIndex} index={0}>
          { getViewByCustomer() }
        </TabPanel>
        <TabPanel selectedTabIndex={selectedTabIndex} index={1}>
          { getViewByVendor()}
        </TabPanel>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        component='div'
        colSpan={7}
        rowsPerPage={rowsPerPage}
        page={page}
        count={count}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={getLabelDisplayedRows()}
        labelRowsPerPage={getLabelRowsPerPage()}
        backIconButtonProps={{'aria-label':'Previous page icon'}}
        nextIconButtonProps={{'aria-label':'Next page icon'}}
        SelectProps={{
          inputProps: {
            'aria-label': 'Expand below icon',
            'aria-labelledby': 'Expand below icon',
          },
          id:'expandBelowIcon'
        }}
      />
    </>
  );
}

export default ContraSettings;
