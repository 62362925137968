const colors = {
  PRIMARY         : '#154A8A',
  PRIMARY_DARK    : '#010953',
  PRIMARY_LIGHT   : '#0075AD',
  SECONDARY       : '#0091D6',
  ERROR           : '#AE2633',
  ERROR_DARK      : '#C82333',
  ERROR_LIGHT     : '#E97B86',
  WARNING         : '#FFC107',
  INFO            : '#6C757D',
  SUCCESS         : '#28A745',
  PRIMARY_TEXT    : '#000000',
  SECONDARY_TEXT  : '#707173',
  NEUTRAL         : '#E0E0E0',
  WHITE           : '#fff',
  BACKGROUND_COLOR: '#F2F7F8',
  DISABLED        : '#C0C0C0'
}

export default colors