import { useCallback, useContext, useMemo } from "react";
import { IBBPeriod, IClient } from "../../../../../interfaces";
import APAgingReportHeader from "../report-header";
import { APAgingReportContext } from "../../../../../context/apAgingReportContext";
import { IAPAgingReportContext } from "../../../../../interfaces/apAgingReportInterface";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { convertNumberBasedOnRate, formatNumberBasedOnCurrency } from "..";
import { ICurrency } from "../../../../../interfaces/multiCurrencyInterface";
import styles from "./styles";
import APAgingCollateralDetailContainer from "../collateral-detail-container";
import { formatDate } from "../../../../../utility/helper";
import { AMERICAN_DATE_FORMAT, NON_EXISTING } from "../../../../../utility/constants";
import { IAccountsReceivableSetting } from "../../../../../components/client-settings/tabs/tab-panel-contents/accounts-receivable";
import { WITHOUT_BUCKET_TYPE } from "../fields-container";

export interface IAPAgingChildClientDetailContainerProps {
  childClient: IClient
}

const APAgingChildClientDetailContainer: React.FC<IAPAgingChildClientDetailContainerProps> = (props)  => {
  const { childClient } = props;
  const {
    selectedAsOfDate, selectedCurrency, selectedType, arCollaterals, exchangeRates, reports, buckets, filterText
  }  = useContext(APAgingReportContext) as IAPAgingReportContext;

  const currentARSettings = useMemo(() =>
    arCollaterals.filter(setting => setting.arCollateral.borrowerId === childClient.recordId),
  [childClient, arCollaterals]);

  const getExchangeRateForCollateral = useCallback((arSetting: IAccountsReceivableSetting) => {
    if (selectedAsOfDate === null) { return null; }
    const exchangeRate = exchangeRates.find(exchangeRate =>
      exchangeRate.toCurrencyId.toString() === arSetting.arBorrowerInput.currency &&
      formatDate(exchangeRate.asOfDate as string, AMERICAN_DATE_FORMAT) === formatDate((selectedAsOfDate as IBBPeriod).endDate, AMERICAN_DATE_FORMAT)
    );
    return exchangeRate ?? null;
  }, [exchangeRates, selectedAsOfDate]);

  const getUPCTotal = useCallback(() => {
    const reportsForChildClient = reports.filter(report => report.borrowerId === childClient.recordId);
    const formattedReportsByCollateralRate = reportsForChildClient.map(report => {
      const arSetting = arCollaterals.find(arSetting => arSetting.arCollateral.recordId === report.arCollateralId);
      if (arSetting === undefined) { return report; }
      const exchangeRate = getExchangeRateForCollateral(arSetting);
      return ({
        ...report,
        total: convertNumberBasedOnRate(report.total, exchangeRate)
      });
    });
    return formattedReportsByCollateralRate.reduce((totalAmount, currentReport) => totalAmount + currentReport.total, 0);;
  }, [arCollaterals, childClient.recordId, getExchangeRateForCollateral, reports]);

  const getChildClientTotalPerBucket = useCallback(() => {
    if (selectedType === WITHOUT_BUCKET_TYPE) { return {};}
    const reportsForChildClient = reports.filter(report => report.borrowerId === childClient.recordId);
    const formattedReportsByCollateralRate = reportsForChildClient.map(report => {
      const arSetting = arCollaterals.find(arSetting => arSetting.arCollateral.recordId === report.arCollateralId);
      if (arSetting === undefined) { return report; }
      const exchangeRate = getExchangeRateForCollateral(arSetting);
      const convertedCalcAgingBucket = Object.fromEntries(
        Object.entries(report.calcAgingBucket).map(([bucket, amount]) => [bucket, convertNumberBasedOnRate(amount, exchangeRate) ])
      );
      return ({
        ...report,
        total: convertNumberBasedOnRate(report.total, exchangeRate),
        calcAgingBucket: convertedCalcAgingBucket,
      });
    });
    return formattedReportsByCollateralRate.reduce((totalAmountByBucket, currentReport) => {
      const totalAmountByBucketToUpdate = { ...totalAmountByBucket };
      Object.entries(currentReport.calcAgingBucket).forEach(([bucket, amount]) => {
        if (totalAmountByBucketToUpdate[bucket] === undefined) { totalAmountByBucketToUpdate[bucket] = amount }
        else { totalAmountByBucketToUpdate[bucket] = totalAmountByBucketToUpdate[bucket] + amount; }
      });
      return totalAmountByBucketToUpdate;
    }, {} as {[bucket: string]: number});
  }, [arCollaterals, childClient.recordId, getExchangeRateForCollateral, reports]);

  const getContentForClientTotal = () => {
    if (filterText !== '') { return <></>; }

    const getBucketLabel = (label: string) => {
      const position = label.search('Over');
      if (position === NON_EXISTING) { return `${label} Days`; }
      const regexResult = RegExp(/(\d+)/).exec(label);
      const numberPart = parseInt(regexResult ? regexResult[0] : '', 10);
      return `${numberPart + 1} and Over`;
    };

    const totalPerBucket = getChildClientTotalPerBucket();
    const childClientTotal = selectedType !== WITHOUT_BUCKET_TYPE
      ? Object.values(totalPerBucket).reduce((childClientTotal, bucketTotal) => childClientTotal + bucketTotal, 0)
      : getUPCTotal();
    const formattedChildClientTotal = formatNumberBasedOnCurrency(childClientTotal, selectedCurrency as ICurrency);

    return (
      <Table size='small' sx={styles.tableForClientTotal}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ ...styles.tableFooterText, ...styles.widthActionButton }}></TableCell>
            <TableCell sx={{ ...styles.tableFooterText, ...styles.widthVendorId }}></TableCell>
            <TableCell sx={{...styles.tableFooterText, ...styles.widthVendorName }}></TableCell>
            <TableCell tabIndex={0} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }} align='right'> Total </TableCell>
            {
              buckets.map(bucket => {
                return (
                  <TableCell tabIndex={0} align='right' key={bucket} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }}>
                    { getBucketLabel(bucket) }
                  </TableCell>
                );
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ ...styles.tableFooterText, ...styles.widthActionButton }}></TableCell>
            <TableCell tabIndex={0} sx={{ ...styles.tableFooterText, ...styles.widthVendorId }}> Client Total </TableCell>
            <TableCell sx={{...styles.tableFooterText, ...styles.widthVendorName }}></TableCell>
            <TableCell tabIndex={0} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }} align='right'>
              { formattedChildClientTotal !== 0 ? formattedChildClientTotal : <></> }
            </TableCell>
            {
              buckets.map(bucket => {
                const formattedBucketTotal = formatNumberBasedOnCurrency(totalPerBucket[bucket] ?? 0, selectedCurrency as ICurrency);
                return (
                  <TableCell tabIndex={0} align='right' key={bucket} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }}>
                    { formattedBucketTotal !== 0 ? formattedBucketTotal : <></> }
                  </TableCell>
                );
              })
            }
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const getMainContent = () => {
    const reportsForChildClient = reports.filter(report => report.borrowerId === childClient.recordId);
    if (reportsForChildClient.length === 0) { return <></>; }
    return (
      <>
        <APAgingReportHeader selectedExchangeRate={null} childClient={childClient} />
        { currentARSettings.map(arSetting => <APAgingCollateralDetailContainer arSetting={arSetting} /> ) }
        { getContentForClientTotal() }
      </>
    );
  };

  return getMainContent();
};

export default APAgingChildClientDetailContainer;