const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  name: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: 'text.secondary',
  },
  username: {
    fontSize: '0.7rem',
    letterSpacing: '0.55px',
    color: 'text.secondary',
  },
};
export default styles;
