import { Box, Grid, FormLabel, TextField, Autocomplete, Typography, Button, Tooltip, IconButton } from "@mui/material";
import { useFormik } from "formik";
import DisabledComponentsContainer from "../../../../components/common/disabled-components-container";
import PopUpModal from "../../../../components/modals/pop-up-modal";
import exchangeRateSchema from "../../../../schemas/exhangeRateSchema";
import axiosInstance from "../../../../service/axiosInstance";
import { API_DOMAIN, DELETE, ONE_MILLION, POST, ZERO } from "../../../../utility/constants";
import { getDateObject, getLocalStorageItem } from "../../../../utility/helper";
import styles from "../styles";
import ConfirmModal from "../../../../components/modals/confirm-modal";
import { ICurrency, IRate } from "../../../../interfaces/multiCurrencyInterface";
import HelperTextComponent from "../../../../components/common/helper-text-component";
import WarningModal from "../../../../components/file-import/modals/warning-modal";
import { IOption } from "../../../../interfaces/comboBox";
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FC, useContext, useRef, useState } from "react";
import { IToaster } from "..";
import { multiCurrencyAPI } from "../../../../service/api";
import { SelectedClientContext } from "../../../../context/selectedClientContext";

export interface IProps {
  clientCurrency: ICurrency | undefined;
  selectedClient: IOption | null;
  rate: IRate;
  idx: number;
  isoList: ICurrency[] | undefined;
  filteredRates: IRate[] | undefined;
  getCurrency: (recordId: number) => string;
  getCurrencyObject: (recordId?: string) => ICurrency | undefined;
  isOdd: (index: number) => boolean;
  fetchRate: (borrowerId: number, currencyId: number) => void;
  setToasterProps: (value: React.SetStateAction<IToaster>) => void;
}

/**
 * renders edit modal component for exchange rate
 */
const CurrencyRow: FC<IProps> = (props) => {
  const selectedClientContext                       = useContext(SelectedClientContext);
  const [showPrompt, setShowPrompt]                 = useState<boolean>(false);
  const [openWarning, setOpenWarning]               = useState<boolean>(false);
  const [isViewModalOpen, setIsViewModalOpen]       = useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete]             = useState<boolean>(false);
  const [isEditing, setIsEditing]                   = useState<boolean>(false);
  const [currencyHelperText, setCurrencyHelperText] = useState<string>();
  const [invalidIso, setInvalidIso]                 = useState<string>();
  const [currency, setCurrency]                     = useState<ICurrency | undefined>(props.rate.toCurrency);
  const currentCurrencyRef                          = useRef(props.rate.toCurrency?.recordId.toString());

  /**
   * This useFormik is used to easily access formik properties.
   */
  const formik = useFormik({
    initialValues: {
      asOfDate: props.rate.asOfDate,
      exchangeRate: props.rate.currencyRate,
      currencyCode: props.rate.toCurrency?.recordId.toString()
    },
    validateOnBlur: true,
    validationSchema: exchangeRateSchema,
    onSubmit: (values) => {
      axiosInstance.request({
        url: `${API_DOMAIN}/rate/addRate`,
        method: POST,
        data: {
          recordId: props.rate.recordId,
          borrowerId: props.selectedClient?.recordId,
          fromCurrencyId: props?.clientCurrency?.recordId,
          toCurrencyId: values.currencyCode,
          currencyRate: values.exchangeRate,
          asOfDate: props.rate.asOfDate,
          userName: `${getLocalStorageItem('firstName')} ${getLocalStorageItem('lastName')}`
        }}
      )
        .then(() => {
          setIsEditing(false)
          setShowPrompt(false)
          props.fetchRate(selectedClientContext?.selectedClient?.recordId ?? 0, props.clientCurrency?.recordId ?? 0)
          props.setToasterProps({
            isOpen: true,
            message: 'Exchange Rate Updated',
            severity: 'success'
          })
          formik.resetForm({ values });
          
          // Update currentCurrencyRef after saving
          currentCurrencyRef.current = values.currencyCode;
        }).catch((error) => {
          props.setToasterProps({
            isOpen: true,
            message: 'Error, Please Try again',
            severity: 'error'
          })
          console.log('POST RATE : ', error)
        })
    },
  });

  /**
   * This function gets the helper text for common fields.
   * @param name The name of the field.
   * @returns The helper text component if there are errors, or null if none.
   */
  const getCommonHelperText = (name: string) => {
    return formik.touched[name] && formik.errors[name]
      ? <HelperTextComponent text={formik.errors[name]} />
      : null
  };

  /**
   * This function checks if the currency texfield has an error
   * @returns A boolean value
   */
  const getCurrencyError = () => {
    return (formik.touched.currencyCode || Boolean(currencyHelperText))
      && (Boolean(formik.errors.currencyCode) || Boolean(invalidIso))
  };

  const sameCode = (code?: string) => ((code == currentCurrencyRef.current));

  /**
   * This function check if the currency that being created does exist on the selected asOfDate.
   * 
   * @param code ISO code of the currency to be added.
   * @returns Boolean, false if the given currency code exists on a specific as of date.
   */
  const checkUnique = (codeId?: string) => {
    if (codeId && props.rate.asOfDate) {
      return !props.filteredRates?.some(rate => rate.toCurrency?.recordId == parseInt(codeId));
    }
    return false
  };

  const sameCodeOrUnique = (code?: string) => {
    return sameCode(code) || checkUnique(code);
  };
  
  /**
   * This function delete a specific exchange rate by its record id
   */
  const handleDeleteRate = (rateId: number) => {
    axiosInstance.request({
      url:`${multiCurrencyAPI.DELETE_RATE}/${rateId}`,
      method: DELETE
    }).then(() => {
      props.fetchRate(selectedClientContext?.selectedClient?.recordId ?? 0, props.clientCurrency?.recordId ?? 0)
      props.setToasterProps({
        isOpen: true,
        message: 'Exchange Rate Deleted',
        severity: 'success'
      })
    }).catch((error) => {
      props.setToasterProps({
        isOpen: true,
        message: 'Error, Please Try again',
        severity: 'error'
      })
      console.log('DELETE EXCHANGE RATE: ', error)
    })
  }

  /**
   * This function resets all the value for the view/edit modal
   */
  const handleCloseModal = () => {
    formik.resetForm()
    setCurrency(props.rate.toCurrency)
    setIsEditing(false)
    setShowPrompt(false)
    setIsViewModalOpen(false)
  }

  /**
   * This function checks if there any changes on the form before closing it
   */
  const handleChanges = () => {
    if(formik.dirty){
      setShowPrompt(true)
      return
    }handleCloseModal()
  }

  return (
    <>
      <Grid
        key={props.rate.recordId}
        container
        justifyContent={'space-between'}
        sx={{
          bgcolor: props.isOdd(props.idx) ? '#F7F7F7' : 'background.paper',
          py: '1rem'
        }}
      >
        <Grid item xs={3.5}>
          <Box sx={styles.currencyNameContainer}>
            <Typography tabIndex={0} sx={styles.userNameSpace}>
              {`${props.rate.toCurrency?.currencyCode} - ${props.rate.toCurrency?.currencyName}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={styles.rateContainer}>
            <Typography tabIndex={0}>
              {`${props.rate.toCurrency?.symbol} ${props.rate.currencyRate}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={styles.asOfDateContainer}>
            <Typography tabIndex={0}>
              {getDateObject(props.rate.asOfDate.toString()).toLocaleDateString()}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <Box sx={styles.userContainer}>
            <Typography tabIndex={0}>
              {props.rate.userName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} sx={styles.actionsContainer}>
          <Tooltip title='View Exchange Rate'>
            <IconButton
              aria-label={'View icon'}
              onClick={() => {
                setIsViewModalOpen(true)
              }}
              sx={styles.viewActionIconButton}
            >
              <PreviewIcon sx={styles.actionIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete Exchange Rate'>
            <IconButton
              aria-label='Delete icon'
              onClick={() => {
                setIsOpenDelete(true)
              }}
            >
              <DeleteOutlineOutlinedIcon sx={styles.actionIcon} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <PopUpModal
        open={isViewModalOpen}
        onClose={handleChanges}
        handleEditClick={() => setIsEditing(true)}
        isComponentEditable={true}
        isEditableTrue={isEditing}
        isNotDeletable
        title1='Edit Currency'
        title2='View Currency'
        width='65rem'
      >
        <Box width={'100%'}>
          <form onSubmit={formik.handleSubmit}>
          <Box sx={{
            padding: '3rem',
            my: '1rem',
            border: '2px solid #E0E0E0',
            borderRadius: '8px'
          }}>
            <Grid container justifyContent={'space-between'}>
              <Grid
                item xs={2}
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <FormLabel
                  tabIndex={0}
                  required={true}
                  htmlFor='exchange-rate'
                >
                  Exchange Rate
                </FormLabel>
              </Grid>
              <Grid item xs={2.5}>
                <TextField
                  id='exchangeRate'
                  disabled={!isEditing}
                  value={formik.values.exchangeRate}
                  onChange={(e) => {
                    const newValue = e.target.value;
    
                    // Allow only numbers and decimal
                    if (/^\d*\.?\d*$/.test(newValue)) {
                      // Ensure the value is within min and max range
                      const numericValue = parseFloat(newValue);
                      if (!isNaN(numericValue) && numericValue >= ZERO && numericValue <= ONE_MILLION) {
                        // Limit decimal places to 8
                        const limitedDecimalValue = newValue.includes('.') 
                          ? newValue.slice(0, newValue.indexOf('.') + 9)  // 8 decimal places
                          : newValue;
                        formik.setFieldValue('exchangeRate', limitedDecimalValue);
                      } 
                      
                      if (newValue === "") {
                        formik.setFieldValue('exchangeRate', "");
                      }
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.exchangeRate && Boolean(formik.errors.exchangeRate)}
                  helperText={getCommonHelperText('exchangeRate')}
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                    'aria-label':'Exchange Rate',
                    'aria-labelledby': 'exchangeRate',
                  }}
                  sx={{ ...styles.exchangeRateField, width: '100%' }}
                />
              </Grid>
              <Grid item xs={4} paddingLeft={'1rem'}>
                <Autocomplete
                  id="currencyCode"
                  disabled={!isEditing}
                  size="small"
                  autoHighlight
                  value={currency}
                  options={props?.isoList ?? []}
                  getOptionLabel={(option) => `${option.currencyCode} - ${option.currencyName}`}
                  isOptionEqualToValue={(option, value) => option?.recordId === value?.recordId}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      title={`${option.currencyCode} - ${option.currencyName}`}
                      {...props}
                    >
                      {`${option.currencyCode} - ${option.currencyName}`}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={getCurrencyError()}
                      helperText={formik.touched.currencyCode && (formik.errors.currencyCode || currencyHelperText)}
                      inputProps={{
                        ...params.inputProps,
                        'aria-label': 'Currency Code',
                        'aria-labelledby': 'currencyCode',
                      }}
                      sx={styles.textField}
                    />
                  )}
                  onBlur={formik.handleBlur}
                  onChange={(_event, value) => {
                    if (value !== null && !sameCodeOrUnique(value?.recordId.toString())) {
                      setInvalidIso(props.getCurrency(value?.recordId ?? 0))
                      setCurrencyHelperText('This exchange rate is already saved')
                      setOpenWarning(true)
                    } else {
                      setInvalidIso(undefined)
                      setCurrencyHelperText(undefined)
                    }
                    const selected = props.getCurrencyObject(value?.recordId.toString() ?? '')
                    setCurrency(selected)
                    formik.setFieldValue('currencyCode', value?.recordId)
                  }}
                  aria-label='currency-code'
                  componentsProps={{
                    popupIndicator: { 'aria-label': 'Dropdown icon', tabIndex: 0 },
                    clearIndicator: { 'aria-label': 'Clear icon', tabIndex: 0 }
                  }
                  }
                />
              </Grid>
              <Grid
                item xs={3.5}
                container
                paddingLeft={'1rem'}
                direction="row"
                alignItems="center"
              >
                <Typography tabIndex={0} sx={styles.textFont}>
                  <Box component="span" fontWeight='fontWeightBold' fontSize={16}>= </Box>
                  {`1 ${props.clientCurrency?.currencyCode} - ${props.clientCurrency?.currencyName}`}
                </Typography>
              </Grid>
            </Grid>
            </Box>
            <Grid container justifyContent={'end'}>
              <Button
                aria-label={'Cancel'}
                variant='outlined'
                color='primary'
                sx={styles.saveButton}
                onClick={handleChanges}
              >
                Cancel
              </Button>
              <DisabledComponentsContainer isDisabled={!(formik.isValid && formik.dirty && sameCodeOrUnique(formik.values.currencyCode) && !invalidIso)}>
                <Button
                  disabled={!(formik.isValid && formik.dirty && sameCodeOrUnique(formik.values.currencyCode) && !invalidIso)}
                  aria-label={!(formik.isValid && formik.dirty && sameCodeOrUnique(formik.values.currencyCode) && !invalidIso) ? 'Save Currency Rate button disabled' : 'Save Currency Rate'}
                  variant='contained'
                  color='primary'
                  type='submit'
                  sx={{ ...styles.saveButton, ml: '1rem' }}
                >
                  Save Changes
                </Button>
              </DisabledComponentsContainer>
            </Grid>
          </form>
        </Box>
        <WarningModal
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          issueMessages={[`There is an existing ${invalidIso} exchange rate. The currency code cannot be added since it has already been saved.`]}
          issueType="error"
          displayOnly
        />
        <ConfirmModal
          open={showPrompt}
          onConfirm={() => setShowPrompt(false)}
          onButtonClose={() => setShowPrompt(false)}
          onClose={handleCloseModal}
          promptChecker
          title={'Confirm Navigation'}
          description={'You have unsaved changes. Are you sure you want to leave this page?'}
          yesButtonText={'Keep Editing'}
          noButtonText={'Discard Changes'}
          confirmOnly
        />
      </PopUpModal>
      <ConfirmModal
        open={isOpenDelete}
        onClose={() => setIsOpenDelete(false)}
        onConfirm={() => handleDeleteRate(props.rate.recordId)}
        title={`Delete ${props.rate.toCurrency?.currencyCode} - ${props.rate.toCurrency?.currencyName}`}
        description='Are you sure you want to delete this Exchange Rate?'
        errorButton
        yesButtonText='Delete'
        noButtonText='Cancel'
      />
    </>
  )
}

export default CurrencyRow