const styles = {
  container: {
    position: 'fixed',
    bottom: '3vw',
    right: '2vw',
    zIndex: '9999',
    visibility: 'visible'
  },
  hidden: {
    visibility: 'hidden',
    zIndex: '-9999',
  }
}

export default styles;