import { Button, Typography, Modal, Grid, Box, Divider, Tooltip, IconButton, FormLabel, MenuItem, TextField, Select, Autocomplete } from '@mui/material';
import { FormikProps, FormikValues, getIn, Formik, Form, Field } from 'formik';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HistoryIcon from '@mui/icons-material/History';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useMemo, useState } from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { IAccountsReceivableSetting } from '..';
import { ClientSettingsContext, IClientSettingsContext } from '../../../../../../context/clientSettingsContext';
import accountsReceivableSettingsSchema from '../../../../../../schemas/accountsReceivableSettingsSchema';
import { collateralIDs, CONTAINS_NUMBER_REGEX, NON_EXISTING_PERCENT } from '../../../../../../utility/constants';
import ConfirmModal from '../../../../../modals/confirm-modal';
import Prompt from '../../../../../modals/prompt';
import styles from '../styles';
import { ICurrency } from '../../../../../../interfaces/multiCurrencyInterface';
import BucketSpread from '../bucket-spread';
import HelperTextComponent from '../../../../../common/helper-text-component';
import DisabledComponentsContainer from '../../../../../common/disabled-components-container';
import { SelectedClientContext } from '../../../../../../context/selectedClientContext';

interface IARSettingModal {
  isModalOpen: boolean;
  onCloseModal: () => void;
  arSetting: IAccountsReceivableSetting;
  isEditable: 'always' | 'editable' | 'not editable';
  existingArCollateralNames: string[];
  onARSettingDelete?: () => void;
  onARSettingArchive?: () => void;
  onARSettingRestore?: () => void;
  onARSettingSave: (arSetting: IAccountsReceivableSetting) => void;
  isoList: ICurrency[];
}

export interface IFormikForIAccountsReceivableSetting extends IAccountsReceivableSetting {
  existingARCollateralNames: string[];
  invoiceDateBuckets: string[];
  dueDateBuckets: string[];
}

export interface IDeleteProps {
  title: string,
  index: number,
  bucketFieldName: string,
  bucketType: 'invoiceDateBucket' | 'dueDateBucket'
}

interface ICustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name    : string;
}

/**
 * This function format a textfield's value into currency
 */
const CurrencyFormat = React.forwardRef<NumberFormat<InputAttributes>, ICustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({ target: { value: values.value, name: other.name, }, });
        }}
        thousandSeparator
        allowNegative={false}
        type='tel'
        decimalScale={2}
        fixedDecimalScale={true}
        prefix='$'
      />
    );
  }
);

/**
 * This function format a textfield's value into percentage
 */
const PercentFormat = React.forwardRef<NumberFormat<InputAttributes>, ICustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({ target: { value: values.value, name: other.name, }, });
        }}
        thousandSeparator
        type='tel'
        decimalScale={2}
        fixedDecimalScale={false}
        allowNegative
        suffix='%'
        isAllowed={values => values.floatValue === undefined || (values.floatValue >= -100.00 && values.floatValue <= 100.00)}
      />
    );
  }
);

// This is needed to be explicitly defined because of an ar borrower input can have undefined values for some of the buckets
// thus having no way to get the bucket3 and bucket4 to display as an empty field when it is undefined in the beginning
const invoiceDateBucketFields = ['invoiceDateBucket1', 'invoiceDateBucket2', 'invoiceDateBucket3', 'invoiceDateBucket4', 'invoiceDateBucket5', 'invoiceDateBucket6'];
const dueDateBucketFields = ['dueDateBucket1', 'dueDateBucket2', 'dueDateBucket3', 'dueDateBucket4', 'dueDateBucket5', 'dueDateBucket6']

/**
 * Modal component for AR Collateral settings.
 * @param props IARSettingModal.
 * @returns a rendered Component Modal.
 */
const AccountReceivableSettingModal: React.FC<IARSettingModal> = (props) => {
  const {showPrompt, setShowPrompt, isDirty, setIsDirty, canUpdateAr, canArchiveDeleteAr } = useContext(ClientSettingsContext) as IClientSettingsContext;
  const { selectedClient, clients }             = useContext(SelectedClientContext);
  const [isDetailsEnabled, setIsDetailsEnabled] = useState(props.isEditable === 'always');
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad]       = useState<boolean>(true);
  const [deleteDetails, setDeleteDetails]       = useState<IDeleteProps>({title: '', index: 0, bucketFieldName: '', bucketType: 'invoiceDateBucket'});

  /**
   * This useMemo returns the DateBuckets of the selected AR Collateral
   */
  const getDateBuckets = useMemo(() => {
    const invoiceKeys = invoiceDateBucketFields
      .filter(key => props.arSetting.arBorrowerInput[key] !== undefined);
    const dueKeys = dueDateBucketFields
      .filter(key => props.arSetting.arBorrowerInput[key] !== undefined);
    return { invoiceKeys, dueKeys }
  }, [props.arSetting.arBorrowerInput])

  /**
   * This function reset the form of the modal.
   * @param formik object containing the values from AR Collateral and user input.
   */
  const handleCancel = (formik: FormikProps<IFormikForIAccountsReceivableSetting>) => {
    formik.resetForm();
    setIsDirty(false);
    if (props.isEditable === 'always') { props.onCloseModal(); return; }
    setIsDetailsEnabled(false);
  };

  /**
   * This function closes the modal.
   * @returns a toaster if the form has changes.
   */
  const handleClose = () => {
    if (!isDirty) {
      setIsDetailsEnabled(props.isEditable === 'always');
      props.onCloseModal();
      return;
    }
    setShowPrompt(true);
  };

  /**
   * This function adds a new field for DateBuckets.
   * @param formik object containing the values from AR Collateral and user input.
   * @param bucketType determine if the DateBucket is for 'invoiceDateBucket' or 'dueDateBucket'.
   */
  const handleAdd = (formik: FormikProps<IFormikForIAccountsReceivableSetting>, bucketType: 'invoiceDateBucket' | 'dueDateBucket') => {
    const additional = formik.values[`${bucketType}s`].length + 1;
    const newArr = [...formik.values[`${bucketType}s`], `${bucketType}${additional}`]
    formik.setFieldValue(`${bucketType}s`, newArr)
  };

  /**
   * This function delete a field for DateBuckets.
   * @param formik object containing the values from AR Collateral and user input.
   * @param index DateBucket's field index to be delete.
   * @param bucketFieldName DateBucket's field name to be delete.
   * @param bucketType DateBucket's field type to be delete.
   */
  const handleDelete = (formik: FormikProps<IFormikForIAccountsReceivableSetting>, index: number, bucketFieldName: string, bucketType: 'invoiceDateBucket' | 'dueDateBucket') => {
    const lastIndex = formik.values[`${bucketType}s`].length - 1;
    if (index === lastIndex) {
      const newArr = [...formik.values[`${bucketType}s`]]
      newArr.pop();
      formik.setFieldValue(`${bucketType}s`, newArr);
      formik.setFieldValue(`arBorrowerInput.${bucketFieldName}`, undefined);
    } else {
      const newArr = [...formik.values[`${bucketType}s`]];
      const bucketsToUpdate = newArr
        .map((_, upIndex) => upIndex + 1)
        .filter(item => item > index);

      bucketsToUpdate.forEach((bucketNum, bucketNumIndex, array) => {
        if (bucketNumIndex !== array.length - 1) {
          const nextVal = formik.values.arBorrowerInput[`${bucketType}${bucketNum + 1}`];
          formik.setFieldValue(`arBorrowerInput.${bucketType}${bucketNum}`, nextVal !== undefined ? nextVal : '');
        } else {
          formik.setFieldValue(`arBorrowerInput.${bucketType}${bucketNum}`, '');
        }
      });

      newArr.pop();
      formik.setFieldValue(`${bucketType}s`, newArr);
    }

    // currently needed for validation timing
    setTimeout(() => {
      formik.validateForm().then();
    }, 0);
  };

  /**
   * This function checks if the user intent is to add, edit or view an AR Collateral.
   * @returns a specific header name of the modal.
   */
  const getHeaderText = () => {
    if (props.isEditable === 'always') { return 'Add New Collateral'; }
    if (isDetailsEnabled) { return 'Edit AR Collateral'; }
    return 'View AR Collateral';
  };

  /**
   * This function get a specific field's values from formik.
   * @param formik object containing the values from AR Collateral and user input.
   * @param name formik's field.
   * @returns formik value and props.
   */
  const getFormikValues = (formik: FormikProps<FormikValues & IFormikForIAccountsReceivableSetting>, name: string) => {
    const nameSplitted = name.split('.');
    const nameConvertedToSnakeCase = nameSplitted[nameSplitted.length - 1].replace(/[A-Z]./g, letter => `-${letter.toLowerCase()}`);
    const error = getIn(formik.errors, name);
    const isTouched = getIn(formik.touched, name);
    const value = getIn(formik.values, name);
    return {
      name: name,
      id: nameConvertedToSnakeCase,
      value: value !== NON_EXISTING_PERCENT ? value : '',
      error: (isTouched && Boolean(error)),
      helperText: isTouched && error ? <HelperTextComponent text={error} /> : null
    };
  };

  /**
   * This function checks if there is an error on the DateBucket fields.
   * @param formik object containing the values from AR Collateral and user input.
   * @param bucketType determine if the bucket type is 'invoiceDateBucket' or 'dueDateBucket'.
   * @returns a boolean
   */
  const hasBucketErrors = (formik: FormikProps<FormikValues & IFormikForIAccountsReceivableSetting>, bucketType: 'invoiceDateBucket' | 'dueDateBucket') => {
    const dateBuckets = bucketType === 'invoiceDateBucket' ? formik.values.invoiceDateBuckets : formik.values.dueDateBuckets;

    return dateBuckets
      .map(bucket => `arBorrowerInput.${bucket}`)
      .some(name => {
        const error = getIn(formik.errors, name);
        const isTouched = getIn(formik.touched, name);
        return (isTouched && Boolean(error));
    });
  };

  /**
   * This function renders a JSX.Element containing the buttons for either creating, viewing, or editing an AR Collateral.
   * @returns a JSX.Element
   */
  const renderActionButton = () => {
    if (props.isEditable === 'always') { return null; }
    if (props.isEditable === 'not editable') {
      const restoreARSetting = props.onARSettingRestore as () => void;
      return (
        <Button
          startIcon={ <HistoryIcon sx={{...((selectedClient?.parentClient === true) && styles.disabledElement) }} /> }
          aria-label='Restore'
          onClick={() => restoreARSetting()}
          sx={styles.actionIconButton}
          disabled={selectedClient?.parentClient === true}
        >
          <Typography sx={{ ...styles.actionIconButtonText, ...(selectedClient?.parentClient === true && styles.disabledElement) }}>
            Restore
          </Typography>
        </Button>
      );
    }
    if (props.arSetting.arCollateral.canDelete === 0) {
      const archiveARSetting = props.onARSettingArchive as () => void;
      return (
        <DisabledComponentsContainer isDisabled={isDetailsEnabled}>
          <Button
            startIcon={<Inventory2OutlinedIcon sx={{ ...styles.actionIcon, ...((isDetailsEnabled || selectedClient?.parentClient === true) && styles.disabledElement) }} />}
            onClick={() => archiveARSetting()}
            disabled={isDetailsEnabled || selectedClient?.parentClient === true}
            aria-label={isDetailsEnabled ? 'Archive icon button disabled' : 'Archive icon'}
            sx={styles.actionIconButton}
          >
            <Typography sx={{ ...styles.actionIconButtonText, ...((isDetailsEnabled || selectedClient?.parentClient === true) && styles.disabledElement) }}>
              Archive
            </Typography>
          </Button>
        </DisabledComponentsContainer>
      );
    }
    const deleteARSetting = props.onARSettingDelete as () => void;
    return (
      <DisabledComponentsContainer isDisabled={isDetailsEnabled}>
        <Button
          startIcon={<DeleteOutlineOutlinedIcon sx={{ ...styles.actionIcon, ...((isDetailsEnabled || selectedClient?.parentClient === true) && styles.disabledElement) }}/>}
          onClick={() => deleteARSetting()}
          disabled={isDetailsEnabled || selectedClient?.parentClient === true}
          aria-label={isDetailsEnabled ? 'Delete icon button disabled' : 'Delete icon'}
          sx={styles.actionIconButton}
        >
          <Typography sx={{ ...styles.actionIconButtonText, ...((isDetailsEnabled || selectedClient?.parentClient === true) && styles.disabledElement) }}>
            Delete
          </Typography>
        </Button>
      </DisabledComponentsContainer>
    );
  };

  /**
   * This function generate a specific props for sublimit text field.
   * @param formik object containing the values from AR Collateral and user input.
   * @returns a props for sublimit textField.
   */
  const getSublimitFieldCustomProps = (formik: FormikProps<IFormikForIAccountsReceivableSetting>) => {
    const disabled = !isDetailsEnabled
      || formik.values.arBorrowerInput.sublimitType === ''
      || (formik.values.arBorrowerInput.sublimitType === 'Amount Limit' && formik.values.arBorrowerInput.amountLimitType === '');

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = 'arBorrowerInput.arSubLimit';
      if (event.target.value === '') { formik.setFieldValue(name, NON_EXISTING_PERCENT); return; }
      formik.setFieldValue(name, parseFloat(event.target.value));
    };

    const InputProps = {
      inputComponent:
        (formik.values.arBorrowerInput.sublimitType === 'Amount Limit' && formik.values.arBorrowerInput.amountLimitType === 'Currency')
          ? (CurrencyFormat as any)
          : (PercentFormat as any),
    };

    return { disabled, onChange, InputProps };
  };

  /**
   * This function checks if the form detects any changes
   * @param node formik props data.
   * @returns boolean or undefined.
   */
  const formikRef = (node: FormikProps<IFormikForIAccountsReceivableSetting>) => {
    if (node === null) { return; }
    setIsDirty(node.dirty);

    const editingCollateral = props.arSetting.arBorrowerInput && props.arSetting.arCollateral;
    if(editingCollateral) {
      setIsInitialLoad(false);
    }

    const usdCurrency = props.isoList.find(c => c.currencyCode === 'USD');
    if (isInitialLoad && usdCurrency && !node.values.arBorrowerInput.currency) {
      node.setFieldValue('arBorrowerInput.currency', usdCurrency.recordId.toString());
      node.setFieldTouched('arBorrowerInput.currency', true, false);
    }
  };

  /**
   * This function checks if the value is an an empty string or a hyphen (-).
   * @param value The string value to be checked
   * @returns A boolean whether the value is an empty string or a hyphen (-).
   */
  const isValueEmptyOrHyphen = (value: any) => {
    return value === '' || value === '-';
  };

  /**
   * This function gets the currency helper text based on the formik errors and formik touched.
   * @param formik The formik props data.
   * @returns A helper text component or null.
   */
  const getCurrencyHelperText = (formik: FormikProps<IFormikForIAccountsReceivableSetting>) => {
    if (formik.touched.arBorrowerInput?.currency && formik.errors.arBorrowerInput?.currency) {
      return (<HelperTextComponent text={formik.errors.arBorrowerInput?.currency} />);
    } else {
      return null;
    }
  };

  /**
   * This function gets the tab index depending if details are enabled.
   * @returns TabIndex as 0 if details are enabled. -1 otherwise.
   */
  const getTabIndexIfDetailsEnabled = () => isDetailsEnabled ? 0 : -1;

  return (
    <Modal open={props.isModalOpen} onClose={handleClose}>
      <Grid container sx={styles.modal}>
        <Grid item xs={12}>
          <Grid container justifyContent='flex-end' alignItems='center'>
            <Typography tabIndex={0} variant='h6' component='h2' sx={styles.modalHeaderText}>
              { getHeaderText() }
            </Typography>
            <Box sx={{ display: 'flex', ...((props.isEditable !== 'editable' || !canUpdateAr) && styles.hidden) }}>
              <DisabledComponentsContainer isDisabled={isDetailsEnabled}>
                <Button
                  startIcon={<EditOutlinedIcon sx={{ ...styles.actionIcon, ...((isDetailsEnabled || selectedClient?.parentClient) && styles.disabledElement), }} />}
                  disabled={isDetailsEnabled || selectedClient?.parentClient === true}
                  aria-label={isDetailsEnabled ? 'Edit icon button disabled' : 'Edit icon' }
                  sx={styles.actionIconButton}
                  onClick={() => setIsDetailsEnabled(true)}
                >
                  <Typography sx={{ ...styles.actionIconButtonText, ...((isDetailsEnabled || selectedClient?.parentClient === true) && styles.disabledElement) }}>
                      Edit
                  </Typography>
                </Button>
              </DisabledComponentsContainer>
              <Divider orientation='vertical' flexItem sx={{ ...styles.divider, ...(isDetailsEnabled && styles.disabledDivider), ...(!canArchiveDeleteAr && styles.hidden) }}/>
            </Box>
            { canArchiveDeleteAr && renderActionButton() }
            <Grid>
              <Tooltip title='Close the modal'>
                <IconButton onClick={handleClose} aria-label='Close icon'>
                  <CloseIcon fontSize='inherit'/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={{
            ...props.arSetting,
            existingARCollateralNames: props.existingArCollateralNames.filter(name => name !== props.arSetting.arBorrowerInput.arCollateralName),
            invoiceDateBuckets: getDateBuckets.invoiceKeys,
            dueDateBuckets: getDateBuckets.dueKeys,
          }}
          validationSchema={accountsReceivableSettingsSchema}
          onSubmit={(values) => { props.onARSettingSave(values); setIsDirty(false); props.onCloseModal(); }}>
          {(formik) => (
            <Form>
              <Grid container justifyContent='space-around'>
                <Prompt when={(formik.dirty)} isEditing={() => {}}/>
                <Grid item xs={12} sx={styles.sectionTitleContainer}>
                  <Typography tabIndex={0} component='h4' sx={styles.sectionTitle}>Collateral Information</Typography>
                </Grid>
                <Grid container sx={styles.modalFieldsContainer} >
                  <Grid item xs={12} lg={7}>
                    <Grid
                      container
                      rowSpacing={2.5}
                      columnSpacing={1}
                      sx={{...styles.modalGridContainer, ...styles.marginTop}}
                    >
                      {selectedClient?.parentClient &&
                        <>
                          <Grid item xs={5} sx={{...styles.rightAlignedText}}>
                            <FormLabel
                              tabIndex={0}
                              htmlFor='ar-client-name'
                              sx={styles.formLabel}>
                              Client Name
                            </FormLabel>
                          </Grid>
                          <Grid item xs={7} xl={7}>
                            <Field
                              as={TextField}
                              disabled
                              sx={styles.modalField}
                              InputLabelProps={{ shrink: true }}
                              value={clients.find(client => client.recordId === props.arSetting.arCollateral.borrowerId)?.borrowerName}
                              id='ar-client-name'
                            />
                          </Grid>
                        </>
                      }
                      <Grid item xs={5} sx={{...styles.rightAlignedText}}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='ar-collateral-id'
                          sx={styles.formLabel}>
                          Collateral ID<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </Grid>
                      <Grid item xs={7} xl={7}>
                        <Autocomplete
                          disabled={!isDetailsEnabled}
                          disableClearable={formik.values.arCollateral.collateralId ? false : true}
                          size='small'
                          options={collateralIDs}
                          ListboxProps={{style: styles.autocompleteHeight }}
                          noOptionsText={'No available Collateral IDs'}
                          onChange={(e, value) => {
                            formik.setFieldValue(
                              'arCollateral.collateralId',
                              value ?? null
                            );
                          }}
                          id={'arCollateral.collateralId'}
                          value={formik.values.arCollateral.collateralId}
                          onBlur={formik.handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{...params.inputProps, 'aria-label':'Collateral ID Field'}}
                              placeholder={'Select Collateral ID'}
                              sx={{...styles.autocompleteField}}
                              variant="outlined"
                              error={formik.touched.arCollateral?.collateralId && Boolean(formik.errors.arCollateral?.collateralId)}
                              helperText={formik.touched.arCollateral?.collateralId && formik.errors.arCollateral?.collateralId ? <HelperTextComponent text={formik.errors.arCollateral?.collateralId} /> : null}
                            />
                          )}
                          componentsProps={{
                            popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                            clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}
                          }}
                        />
                      </Grid>
                      <Grid item xs={5} sx={{...styles.rightAlignedText}}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='ar-collateral-name'
                          sx={styles.formLabel}>
                          Collateral Name<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </Grid>
                      <Grid item xs={7} xl={7}>
                        <Field
                          as={TextField}
                          disabled={!isDetailsEnabled}
                          sx={styles.modalField}
                          onChange={formik.handleChange}
                          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue('arBorrowerInput.arCollateralName', formik.values.arBorrowerInput.arCollateralName.trim());
                            formik.handleBlur(e);
                          }}
                          InputLabelProps={{ shrink: true }}
                          {...getFormikValues(formik, 'arBorrowerInput.arCollateralName')}
                        />
                      </Grid>
                      <Grid item xs={5} sx={styles.rightAlignedText}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='rate-of-advance'
                          sx={styles.formLabel}>
                          Advance Rate<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </Grid>
                      <Grid item xs={7} xl={7}>
                        <Field
                          as={TextField}
                          disabled={!isDetailsEnabled}
                          inputProps={{ sx: styles.rightAlignedText }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const name = 'arBorrowerInput.rateOfAdvance';
                            if (isValueEmptyOrHyphen(event.target.value)) {
                              formik.setFieldValue(name, NON_EXISTING_PERCENT);
                              formik.setFieldError(name, `Advance Rate is required`);
                              return;
                            }
                            formik.setFieldValue(name, parseFloat(event.target.value));
                            formik.setFieldError(name, undefined);
                          }}
                          sx={styles.modalField}
                          type='number'
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputComponent: PercentFormat as any }}
                          {...getFormikValues(formik, 'arBorrowerInput.rateOfAdvance')}
                        />
                      </Grid>
                      <Grid item xs={5} sx={{ ...styles.rightAlignedText, ...styles.marginBottom }}>
                        <Select
                          name='arBorrowerInput.sublimitType'
                          id='sublimit-type'
                          aria-label='Sublimit Type Field'
                          aria-labelledby='ar-subLimit'
                          inputProps={{ 'aria-label': 'Sublimit Type', 'aria-labelledby': 'ar-subLimit', 'data-testid' : 'sublimit-type' }}
                          disabled={!isDetailsEnabled}
                          displayEmpty
                          onChange={(event) => {
                            formik.handleChange(event);
                            formik.setFieldValue('arBorrowerInput.amountLimitType', event.target.value === 'Amount Limit' ? 'Currency' : 'Percentage');
                            formik.setFieldValue('arBorrowerInput.arSubLimit', NON_EXISTING_PERCENT, false);
                            formik.setFieldTouched('arBorrowerInput.arSubLimit', true, false);
                          }}
                          sx={{
                            ...styles.modalField,
                            ...styles.modalFieldSelect,
                            ...styles.modalFieldSelectForLimit,
                            ...(formik.values.arBorrowerInput.sublimitType === '' && styles.modalFieldSelectForEmptyLimit)
                          }}
                          value={formik.values.arBorrowerInput.sublimitType}>
                          {
                            formik.values.arBorrowerInput.sublimitType === '' &&
                            <MenuItem value='' sx={{ ...styles.modalFieldMenuItem, ...styles.hidden }} disabled>
                              Please Select
                            </MenuItem>
                          }
                          <MenuItem value='Amount Limit' sx={styles.modalFieldMenuItem}>
                            Amount Limit
                          </MenuItem>
                          <MenuItem value='Eligible Collateral Limit' sx={styles.modalFieldMenuItem}>
                            Eligible Collateral % Limit
                          </MenuItem>
                          <MenuItem value='Facility Limit' sx={styles.modalFieldMenuItem}>
                            Facility % Limit
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={7} xl={7} sx={styles.marginBottom}>
                        <Field
                          as={TextField}
                          aria-label='Sublimit Field'
                          fullWidth
                          tabIndex={getTabIndexIfDetailsEnabled()}
                          inputProps={{ 'aria-label': 'Sublimit', 'data-testid' : 'sublimit', sx: styles.rightAlignedText }}
                          sx={{...styles.modalField}}
                          InputLabelProps={{ shrink: true }}
                          {...getSublimitFieldCustomProps(formik)}
                          {...getFormikValues(formik, 'arBorrowerInput.arSubLimit')}
                        />
                        <Select
                          name='arBorrowerInput.amountLimitType'
                          id='amount-limit-type'
                          aria-label='Amount Limit Type Field'
                          aria-labelledby='sublimit'
                          inputProps={{ 'aria-label': 'Amount Limit Type', 'aria-labelledby': 'sublimit' }}
                          disabled={!isDetailsEnabled}
                          displayEmpty
                          onChange={(event) => {
                            formik.handleChange(event);
                            formik.setFieldValue('arBorrowerInput.arSubLimit', NON_EXISTING_PERCENT, false);
                            formik.setFieldTouched('arBorrowerInput.arSubLimit', true, false);
                          }}
                          sx={{
                            ...styles.modalField,
                            ...styles.modalFieldSelect,
                            ...styles.modalFieldSelectForAmount,
                            ...(formik.values.arBorrowerInput.sublimitType !== 'Amount Limit' && styles.hidden),
                            ...(formik.values.arBorrowerInput.amountLimitType === '' && styles.modalFieldSelectForEmptyAmount),
                          }}
                          value={formik.values.arBorrowerInput.amountLimitType}>
                          {
                            formik.values.arBorrowerInput.amountLimitType === '' &&
                            <MenuItem value='' sx={{ ...styles.modalFieldMenuItem, ...styles.hidden }} disabled>
                              Please Select
                            </MenuItem>
                          }
                          <MenuItem
                            value='Currency'
                            sx={styles.modalFieldMenuItem}>
                            Currency
                          </MenuItem>
                          <MenuItem
                            value='Percentage'
                            sx={styles.modalFieldMenuItem}>
                            Percentage
                          </MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* second column */}
                  <Grid item xs={12} lg={5}>
                    <Grid
                      container
                      rowSpacing={2.5}
                      columnSpacing={1}
                      sx={{...styles.modalGridContainer, ...styles.marginTop}}>
                      <Grid item xs={5} sx={styles.rightAlignedText}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='country-name'
                          aria-label='Country'
                          sx={styles.formLabel}>
                          Country<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </Grid>
                      <Grid item xs={7}>
                        <Field
                          as={TextField}
                          disabled={!isDetailsEnabled}
                          onChange={(event: { target: { value: any } }) => {
                            if (CONTAINS_NUMBER_REGEX.test(event.target.value)) { return; }
                            formik.handleChange(event);
                          }}
                          onBlur={(e:React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue('arBorrowerInput.countryName', formik.values.arBorrowerInput.countryName.trim())
                            formik.handleBlur(e);
                          }}
                          sx={styles.modalField}
                          InputLabelProps={{ shrink: true }}
                          {...getFormikValues(formik, 'arBorrowerInput.countryName')}
                        />
                      </Grid>
                      <Grid item xs={5} sx={styles.rightAlignedText}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='currency'
                          sx={styles.formLabel}>
                          Currency<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </Grid>
                      <Grid item xs={7} sx={{ height: '50px' }}>
                        <Autocomplete
                          id='currency'
                          options={props.isoList}
                          value={
                            props.isoList?.filter(currency => parseInt(formik.values.arBorrowerInput.currency) === currency.recordId).length
                              ? props.isoList?.filter(currency => parseInt(formik.values.arBorrowerInput.currency) === currency.recordId)[0]
                              : null
                          }
                          getOptionLabel={(currency) => `${currency.currencyCode} - ${currency.currencyName}`}
                          noOptionsText={'No available currencies'}
                          onChange={(e, value) => {
                            formik.setFieldValue(
                              'arBorrowerInput.currency',
                              value?.recordId.toString() ?? null
                            );
                          }}
                          disabled={!isDetailsEnabled}
                          onBlur={formik.handleBlur}
                          renderOption={(prop, option: ICurrency) => {
                            return (
                              <Box component='li' {...prop} key={option.recordId} 
                                sx={styles.modalFieldMenuItem}
                              >
                                {option.currencyCode} - {option.currencyName}
                              </Box>
                            );
                          }}
                          sx={styles.autoCompleteField}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{...params.inputProps, 'aria-label':'Currency Code Field'}} 
                              name='arBorrowerInput.currency'
                              error={formik.touched.arBorrowerInput?.currency && Boolean(formik.errors.arBorrowerInput?.currency)}
                              helperText={getCurrencyHelperText(formik)}
                              placeholder={'Please Select'}
                              onBlur={formik.handleBlur}
                              variant="outlined"
                            />
                          )}
                          componentsProps={{
                            popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                            clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={styles.sectionTitleContainer}>
                  <Typography tabIndex={0} component='h4' sx={styles.sectionTitle}>Invoice Date Spread</Typography>
                </Grid>
                <BucketSpread
                  bucketType='invoiceDateBucket'
                  formik={formik}
                  hasBucketErrors={hasBucketErrors}
                  isDetailsEnabled={isDetailsEnabled}
                  getFormikValues={getFormikValues}
                  setDeleteDetails={setDeleteDetails}
                  setShowDeletePrompt={setShowDeletePrompt}
                  handleAdd={handleAdd}
                />
                <Grid item xs={12} sx={styles.sectionTitleContainer}>
                  <Typography tabIndex={0} component='h4' sx={styles.sectionTitle}>Due Date Spread</Typography>
                </Grid>
                <BucketSpread
                  bucketType='dueDateBucket'
                  formik={formik}
                  hasBucketErrors={hasBucketErrors}
                  isDetailsEnabled={isDetailsEnabled}
                  getFormikValues={getFormikValues}
                  setDeleteDetails={setDeleteDetails}
                  setShowDeletePrompt={setShowDeletePrompt}
                  handleAdd={handleAdd}
                />
                <Grid item xs={12}>
                  {
                    isDetailsEnabled ? (
                      <Box sx={styles.modalBottomButtonContainer}>
                        <Button
                          variant='outlined'
                          sx={styles.cancelButton}
                          type='reset'
                          onClick={() => handleCancel(formik)}>
                          Cancel
                        </Button>
                        <DisabledComponentsContainer isDisabled={!formik.dirty || !formik.isValid}>
                          <Button
                            disabled={!formik.dirty || !formik.isValid}
                            aria-label={!formik.dirty || !formik.isValid ? 'Save button disabled' : 'Save'}
                            variant='contained'
                            type='submit'
                            sx={styles.saveOrBackButton}>
                            Save
                          </Button>
                        </DisabledComponentsContainer>
                      </Box>
                    ) : (
                      <Box sx={styles.modalBottomButtonContainer}>
                        <Button
                          onClick={() => props.onCloseModal()}
                          variant='contained'
                          sx={styles.saveOrBackButton}>
                          Back
                        </Button>
                      </Box>
                    )}
                </Grid>
              </Grid>
              <ConfirmModal
                open={showPrompt}
                onClose={() => {
                  formik.resetForm();
                  setIsDirty(false);
                  setShowPrompt(false);
                  setIsDetailsEnabled(props.isEditable === 'always');
                  props.onCloseModal();
                }}
                onConfirm={() => setShowPrompt(false)}
                onButtonClose={() => setShowPrompt(false)}
                promptChecker
                title='Confirm Navigation'
                description='You have unsaved changes. Are you sure you want to leave this page?'
                yesButtonText='Keep Editing'
                noButtonText='Discard Changes'
                confirmOnly
              />
              <ConfirmModal
                open={showDeletePrompt}
                onClose={() => setShowDeletePrompt(false)}
                onConfirm={() => {
                  handleDelete(formik, deleteDetails.index, deleteDetails.bucketFieldName, deleteDetails.bucketType);
                  setShowDeletePrompt(false);
                }}
                onButtonClose={() => setShowDeletePrompt(false)}
                promptChecker
                errorButton
                title={deleteDetails.title}
                description='Are you sure you want to delete this bucket?'
                yesButtonText='Delete'
                noButtonText='Cancel'
                confirmOnly
              />
            </Form>
          )}
        </Formik>
      </Grid>
    </Modal>
  );
};

export default AccountReceivableSettingModal;
