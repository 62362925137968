import { FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material/';
import { IInventoryWorksheetProps } from '../../../pages/inventory-worksheet';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import styles from './styles';
import { IInventoryWorksheetContext } from '../../../interfaces/inventoryWorksheetInterface';
import { InventoryWorksheetContext } from '../../../context/inventoryWorksheetContext';

const InventoryWorksheetHeaderTitle: FC<IInventoryWorksheetProps> = (props) => {
  const { selectedClient }                  = useContext(InventoryWorksheetContext) as IInventoryWorksheetContext;
  const navigate                            = useNavigate();
  const theme                               = useTheme();
  const belowMediumBreakpoint               = useMediaQuery(theme.breakpoints.down('md'));
  const handleNavigate                      = useCallback(() => navigate(`/clients/${selectedClient.recordId}/settings`), []);

  if(props.path !== 'clients'){
    return <></>
  }
  
  return (
    <>
      <Box sx={styles.headerBackground}>
        <Typography tabIndex={0} variant='h6' component='h3' sx={styles.headerTitleText}>
          Inventory Worksheet
        </Typography>
      </Box>
      <Box sx={styles.headerContainer}>
        <Typography tabIndex={0} variant='h6' component='h3' sx={styles.headerTitleText}>Inventory Report</Typography>
        <Box sx={styles.headerActionWrapper}>
          <Button
            size='medium'
            variant='outlined'
            aria-label='Go back icon'
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={handleNavigate}
            sx={styles.headerButtons}
          >
            {belowMediumBreakpoint ? null : 'Go back'}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default InventoryWorksheetHeaderTitle;