import colors from "../../../utility/colors";

const styles = {
  tableBody: {
    '&.MuiTableBody-root::before': {
      display: 'block',
      content: '""',
      height: '8px',
    },
  },
  
  tableRow: {
    '&.MuiTableRow-root': {
      verticalAlign: 'top',
    },
  },

  tableCell: {
    '&.MuiTableCell-root': {
      borderBottom: 'none',
      padding: '0.30rem 0.30rem 0 0rem',
    },
  },

  hidden: {
    display: 'none',
  },

  defaultValueColor:{
    color: '#707070'
  },

  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      fontSize: '.9rem',
      backgroundColor: 'white',
      textAlign: 'right'
    },
    '& .MuiOutlinedInput-input': {
      padding: '0.5rem 1rem',
    },
    '& .MuiFormHelperText-root': {
      backgroundColor: 'white',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: 0,
      lineHeight: '14px',
      paddingTop: '4px',
    },
  },

  dropdown: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      fontSize: '.9rem',
      backgroundColor: 'white',
      textAlign: 'right'
    },
    '& .MuiOutlinedInput-input': {
      padding: '0.5rem 1rem',
    },
    '& .MuiFormHelperText-root': {
      backgroundColor: 'white',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: 0,
      lineHeight: '14px',
      paddingTop: '4px',
    },
    '& .MuiSelect-select': {
      textAlign: 'left',
    },
    '& .MuiMenuItem-root': {
      fontSize: '14px',
    },
    '& .MuiSelect-select .notranslate::after': {
      content: `"Select"`,
      opacity: 0.42,
    },
  }, 

  active: {
    '& .MuiSelect-icon': {
      backgroundColor: 'transparent',
      color: colors.WHITE,
    },width: '100%',
    '& .MuiOutlinedInput-root': {
      fontSize: '.9rem',
      backgroundColor: 'white',
      textAlign: 'right'
    },
    '& .MuiOutlinedInput-input': {
      color: colors.WHITE,
      padding: '0.5rem 1rem',
      WebkitBoxShadow: 'none',
      backgroundColor: '#155724'
    },
    '& .MuiFormHelperText-root': {
      backgroundColor: 'white',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: 0,
      lineHeight: '14px',
      paddingTop: '4px',
    },
    '& .MuiSelect-select': {
      textAlign: 'left',
    },
    '& .MuiMenuItem-root': {
      fontSize: '14px',
    },
    '& .MuiSelect-select .notranslate::after': {
      content: `"Select"`,
      opacity: 0.42,
    },
  },

  actionTableCell: {
    '&.MuiTableCell-root': {
      borderBottom: 'none',
      paddingTop: '0.30rem',
      paddingBottom: '0.30rem',
    },
  },

  rightAlignedText: {
    textAlign: 'right',
  },

  centerAlignedText: {
    textAlign: 'center',
  },

  deleteIconButton: {
    paddingTop: '0.30rem',
    paddingBottom: '0.30rem',
  },

  typography: {
    margin: '0.5rem',
    fontSize: '14px',
  },

  ineligibleCodeFieldBox: {
    display: 'flex',
    alignItems: 'flex-start',
  }
}

export default styles;