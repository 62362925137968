import { Container } from '@mui/material';

const SignUp = () => {
  return (
    <Container maxWidth="xl">
      <h3>Sign Up</h3>
    </Container>
  )
}

export default SignUp;