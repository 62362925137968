import { Theme } from "@mui/material/styles/createTheme";

const styles = {
  outmostContainer: {
    py: '17px',
    px: '17px',
    backgroundColor: '#FFFFFF',
  },

  tableContainer: {
    '&.MuiTableContainer-root': {
      px: '6px', // space offset for tableHeadRow's boxShadow
      width: 'auto',
    },
  },

  table: {
    '&.MuiTable-root': {
      minWidth: '1046px',
    },
  },

  tableBody: {
    '&.MuiTableBody-root::before': {
      display: 'block',
      content: '""',
      height: '8px',
    },
  },

  tableHeadRow: {
    '&.MuiTableRow-root': {
      backgroundColor: '#F7F7F7',
      boxShadow: '0px 3px 6px #00000029',
    },
  },

  tableHeadCell: {
    '&.MuiTableCell-head': {
      fontWeight: 'bold',
      pl: '1rem',
      pr: '1.33rem',
      py: '1rem',
    },
  },

  tableHeadCellForPriority: {
    width: '10%'
  },

  tableHeadCellForIneligibleCode: {
    width: '24%'
  },

  tableHeadCellForDescription: {
    width: '50%'
  },

  tableHeadCellForStatus: {
    width: '10%',
  },

  tableHeadCellForAction: {
    width: '6%'
  },

  tableHeaderText: {
    '&.MuiFormLabel-root': {
      fontWeight: 'bold',
      fontSize: '14px',
      color: 'black',
    },
  },

  asterisk:{
    color: '#AE2633'
  },

  tableRow: {
    '&.MuiTableRow-root': {
      verticalAlign: 'top',
    },
  },

  tableCell: {
    '&.MuiTableCell-root': {
      borderBottom: 'none',
      padding: '0.30rem 0.30rem 0 0rem',
    },
  },

  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      fontSize: '.9rem',
      backgroundColor: 'white',
      textAlign: 'right'
    },
    '& .MuiOutlinedInput-input': {
      padding: '0.5rem 1rem',
    },
    '& .MuiFormHelperText-root': {
      backgroundColor: 'white',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: 0,
      lineHeight: '14px',
      paddingTop: '4px',
    },
  },

  textFieldForAsOfDate: {
    userSelect: 'none',
    width: '100%',
    'input[type="date"]::-webkit-calendar-picker-indicator': {
      minWidth: '500px',
      height: 'auto',
      background: 'transparent',
      color: 'transparent',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      cursor: 'pointer',
      position: 'absolute',
    },
    'input::-webkit-datetime-edit' : {
      display: 'none'
    },
    'input:before' : {
      content: 'attr(data-date)',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    'input::-webkit-datetime-edit-day-field:focus': {
      background: 'none',
      color: 'black',
      outline: 'none'
    },
    'input[value=""]:not(:focus)': {
      color: 'transparent',
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
    }
  },

  rightAlignedText: {
    textAlign: 'right',
  },

  centerAlignedText: {
    textAlign: 'center',
  },

  actionTableCell: {
    '&.MuiTableCell-root': {
      borderBottom: 'none',
      paddingTop: '0.30rem',
      paddingBottom: '0.30rem',
    },
  },

  deleteIconButton: {
    paddingTop: '0.30rem',
    paddingBottom: '0.30rem',
  },

  buttonsContainer : {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '8px',
    marginTop: '10px',
    px: '6px', // space offset for tableHeadRow's boxShadow
 },

  saveButton: {
    height: '31px',
    textTransform: 'capitalize',
    width: '82px',
    boxShadow: 'none',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },

  cancelButton: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    width: '82px',
  },

  loadingBackdrop: {
    color: '#fff',
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1
  },

  loadingIcon: {
    '&.MuiCircularProgress-root': {
      color: 'inherit',
    }
  },

}

export default styles;
