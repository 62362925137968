import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field, FormikProps } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams, Box, Checkbox, CircularProgress, FormControlLabel, Grid, InputAdornment, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { NON_EXISTING, NON_EXISTING_PERCENT, NOT_APPLICABLE } from '../../../utility/constants';
import { isObjectsEqual } from '../../../utility/helper';
import { AdditionalSettingsProps, IFormikValuesForRuleAndOverridesPerSetting, IFormikValuesForUPCRuleAndOverridesPerSetting, IIneligibleRuleCapType, IIneligibleRuleHoldType, IIneligibleSettingsContext, IIneligibleSettingsPermissions, IneligibleRuleAPI } from '../../../interfaces/ineligibleSettingInterface';
import { IneligibleSettingsContext } from '../../../context/ineligibleSettingsContext';
import { DecimalFormat, getDisplayedError, DecimalFormatWithNoPrefix, PercentFormat } from '../ineligible-setting-details';
import ComboBox from '../combo-box';
import styles from './styles';
import { getCurrencyForIneligibleCap } from '../../../api/ineligible-settings';

interface DefaultSettingsProps {
  formik: FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>;
}

type IAdditionalSettingCheckbox = { label: string, name: string };

const checkboxesPerIneligibleCodes: { [key: string]: IAdditionalSettingCheckbox[] } = {
  'DLQ': [],
  'XA': [],
  'AC': [],
  'FRN': [],
  'Intercompany': [],
  'CONTRA': [],
  'COD': [],
  'CHB': [],
  'NBAR': [],
  'GOVT': [],
  'XT': [],
  'BK': [],
  'EMP': [],
  'CN' : [],
  'CL': [],
  'RFV': [],
};

/**
 * Returns the checkboxes for a given ineligible code or the default checkboxes if not found.
 * @param code - The ineligible code.
 * @returns An array of checkboxes.
 */
const getCheckboxesFromCode = (code: string) => {
  return checkboxesPerIneligibleCodes[code] ?? [];
};

/**
 * Component that handles Delinauent ineligible's rule's settings.
 */
const DelinquentSettings: React.FC<DefaultSettingsProps> = (props) => {
  const { formik } = props;
  const { selectedIneligibleIndex: ineligibleIndex, permissions, hasPaymentTerms } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  /**
   * This function returns the selected DPID value from Formik
   * @returns The selected DPID value.
   */
  const getSelectedIneligibleRuleDPID = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule as IneligibleRuleAPI).dpid as number;

  /**
   * This function returns the selected DPDD value from Formik or defaults to NON_EXISTING.
   * @returns The selected DPDD value.
   */
  const getSelectedIneligibleRuleDPDD = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule as IneligibleRuleAPI).dpdd as number;

  /**
   * This function generates the Formik field name for DPID based on the current ineligible index.
   * @returns The Formik field name for DPID.
   */
  const getFormikFieldNameForIneligibleRuleDPID = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRule.dpid`;

  /**
   * This function generates the Formik field name for DPDD based on the current ineligible index.
   * @returns The Formik field name for DPDD.
   */
  const getFormikFieldNameForIneligibleRuleDPDD = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRule.dpdd`;

  // Default options for DPID and DPDD settings.
  const options = ['30', '60', '90', '120', 'NA'];

  /**
   * This function generates the options for DPID based on the selected DPDD value.
   * If DPDD is 0, it omits 'NA' from the options.
   * @returns An array of DPID options.
   */
  const getOptionsForDPID = () => {
    if (getSelectedIneligibleRuleDPDD() === NOT_APPLICABLE || !(hasPaymentTerms as boolean)) {  /* no payment terms means DPDD is NA, so DPID should not able to select NA */
      return options.slice(0, options.length - 1);
    }
    return options;
  };

  /**
   * This function generates the options for DPDD based on the selected DPID value.
   * If DPID is 0, it omits 'NA' from the options.
   * @returns An array of DPDD options.
   */
  const getOptionsForDPDD = () => {
    if (getSelectedIneligibleRuleDPID() === NOT_APPLICABLE) {
      return options.slice(0, options.length - 1);
    }
    return options;
  };

  return (
    <Box sx={styles.deliquentSettingsContainer}>
      <ComboBox
        options={options}
        filteredOptions={getOptionsForDPID()}
        label={'DPID'}
        name={getFormikFieldNameForIneligibleRuleDPID()}
        value={getSelectedIneligibleRuleDPID()}
        formik={formik}
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateAdditionalSettings}
      />
      <ComboBox
        options={options}
        filteredOptions={getOptionsForDPDD()}
        label={'DPDD'}
        name={getFormikFieldNameForIneligibleRuleDPDD()}
        value={getSelectedIneligibleRuleDPDD()}
        disabled={!(hasPaymentTerms as boolean) || !(permissions as IIneligibleSettingsPermissions).canUpdateAdditionalSettings}
        formik={formik}
      />
    </Box>
  );
}

/**
 * Component that handles Cross Age ineligible's rule's settings.
 */
const CrossAgeSettings: React.FC<DefaultSettingsProps> = (props) => {
  const { formik } = props;
  const { selectedIneligibleIndex: ineligibleIndex, permissions } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const [isSelected, setIsSelected] = useState(false);

  /**
   * This function returns the selected Cross Age percentage from Formik.
   * @returns The selected Cross Age percentage.
   */
  const getSelectedIneligibleRuleCrossAgePercent = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule as IneligibleRuleAPI).crossAgePct as number;

  /**
   * This function generates the Formik field name for Cross Age percentage based on the current ineligible index.
   * @returns The Formik field name for Cross Age percentage.
   */
  const getFormikFieldNameForIneligibleRuleCrossAgePercent = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRule.crossAgePct`;

  /**
   * This function determines whether to show an input adornment (e.g., '%') based on user interaction.
   * @returns The input adornment element or null.
   */
  const getInputAdornment = () => {
    if (isSelected) { return null; }
    if (getSelectedIneligibleRuleCrossAgePercent() === NON_EXISTING_PERCENT) {
      return <InputAdornment position='end'>%</InputAdornment>;
    }
    return null;
  };

  /**
   * This function handles the change event for the Cross Age input field.
   * It updates Formik values and handles errors.
   * @param event The input change or focus event.
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      formik.setFieldValue(getFormikFieldNameForIneligibleRuleCrossAgePercent(), NON_EXISTING_PERCENT);
      formik.setFieldError(getFormikFieldNameForIneligibleRuleCrossAgePercent(), `Cross Age is required`);
      return;
    }
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleCrossAgePercent(), parseFloat(event.target.value));
    formik.setFieldError(getFormikFieldNameForIneligibleRuleCrossAgePercent(), undefined);
  };

  return (
    <Box sx={styles.crossAgeContainer}>
      <Field
        as={TextField}
        label='Cross Age'
        name={getFormikFieldNameForIneligibleRuleCrossAgePercent()}
        value={getSelectedIneligibleRuleCrossAgePercent() === NON_EXISTING_PERCENT ? '' : getSelectedIneligibleRuleCrossAgePercent()}
        size='small'
        type='number'
        sx={{ ...styles.textField, ...styles.numberAlignment }}
        fullWidth
        InputProps={{ endAdornment: getInputAdornment(), inputComponent: PercentFormat as any }}
        InputLabelProps={{ sx: styles.textFieldInputLabel }}
        onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => { setIsSelected(false); }}
        onFocus={(_event: React.FocusEvent<HTMLInputElement>) => setIsSelected(true)}
        {...getDisplayedError(formik, getFormikFieldNameForIneligibleRuleCrossAgePercent())}
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateAdditionalSettings}
      />
    </Box>
  );
}

/**
 * Component that handles Concentration ineligible's rule's settings.
 */
const ConcentrationSettings: React.FC<DefaultSettingsProps> = (props) => {
  const { formik } = props;
  const { selectedIneligibleIndex: ineligibleIndex, permissions } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const [isPercentFieldSelected, setIsPercentFieldSelected] = useState(false);

  /**
   * This function returns the selected concentration percentage from Formik.
   * @returns The selected concentration percentage.
   */
  const getSelectedIneligibleRuleConcentrationPercent = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule as IneligibleRuleAPI).concentrationPct as number;

  /**
   * This function returns the selected Cap AR value from Formik or defaults to false.
   * @returns The selected Cap AR value.
   */
  const getSelectedIneligibleRuleCapAR = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule as IneligibleRuleAPI).capAr;

  /**
   * This function generates the Formik field name for the concentration percentage based on the current ineligible index.
   * @returns The Formik field name for concentration percentage.
   */
  const getFormikFieldNameForIneligibleRuleConcentrationPercent = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRule.concentrationPct`;

  /**
   * This function generates the Formik field name for Cap AR based on the current ineligible index.
   * @returns The Formik field name for Cap AR.
   */
  const getFormikFieldNameForIneligibleRuleCapAR = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRule.capAr`;

  /**
   * This function generates the Formik field name for AR > 0 based on the current ineligible index.
   * @returns The Formik field name for AR > 0.
   */
  const getFormikFieldNameForIneligibleRuleARGTZero = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRule.arGtZero`;

  /**
   * Dropdown values representing Eligible AR and Gross AR options.
   */
  const dropdownValues: IAdditionalSettingCheckbox[] = [
    { label: 'Eligible AR', name: 'capAr' as const },
    { label: 'Gross AR', name: 'arGtZero' as const },
  ];

  /**
   * This function determines the currently selected dropdown value based on Formik values.
   * @returns The selected dropdown value.
   */
  const getSelectedDropdownValue = () => {
    if (getSelectedIneligibleRuleCapAR()) { return dropdownValues[0]; }
    return dropdownValues[1];;
  };

  /**
   * This function determines whether to show an input adornment (e.g., '%') based on user interaction.
   * @returns The input adornment element or null.
   */
  const getInputAdornmentForPercentField = () => {
    if (isPercentFieldSelected) { return null; }
    if (getSelectedIneligibleRuleConcentrationPercent() === NON_EXISTING_PERCENT) {
      return <InputAdornment position='end'>%</InputAdornment>;
    }
    return null;
  };

  /**
   * This function generates the input field for the dropdown.
   * @param params The input parameters for Autocomplete.
   * @returns The rendered input field for the dropdown.
   */
  const getRenderedInputForDropdown = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        label='AR'
        size='small'
        sx={styles.textField}
        fullWidth
        placeholder={'AR'}
        InputLabelProps={{ sx: styles.textFieldInputLabel }}
      />
    );
  };

  /**
   * This function handles the dropdown value change and updates related Formik fields.
   * @param _event The synthetic event for dropdown change.
   * @param value The selected dropdown value.
   */
  const handleDropdownChange = (_event: React.SyntheticEvent<Element, Event>, value: IAdditionalSettingCheckbox | null) => {
    if (isObjectsEqual(value, dropdownValues[0])) {
      formik.setFieldValue(getFormikFieldNameForIneligibleRuleCapAR(), true);
      formik.setFieldValue(getFormikFieldNameForIneligibleRuleARGTZero(), false);
      return;
    }
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleCapAR(), false);
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleARGTZero(), true);
  };

  /**
   * This function handles the change event for the concentration input field.
   * It updates Formik values and handles errors.
   * @param event The input change or focus event.
   */
  const handlePercentChange = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      formik.setFieldValue(getFormikFieldNameForIneligibleRuleConcentrationPercent(), NON_EXISTING_PERCENT);
      formik.setFieldError(getFormikFieldNameForIneligibleRuleConcentrationPercent(), `Concentration is required`);
      return;
    }
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleConcentrationPercent(), parseFloat(event.target.value));
    formik.setFieldError(getFormikFieldNameForIneligibleRuleConcentrationPercent(), undefined);
  };

  return (
    <Box sx={styles.concentrationContainer}>
      <Field
        as={TextField}
        label='Concentration'
        name={getFormikFieldNameForIneligibleRuleConcentrationPercent()}
        value={getSelectedIneligibleRuleConcentrationPercent() === NON_EXISTING_PERCENT ? '' : getSelectedIneligibleRuleConcentrationPercent()}
        size='small'
        type='number'
        sx={{ ...styles.textField, ...styles.numberAlignment }}
        fullWidth
        InputProps={{ endAdornment: getInputAdornmentForPercentField(), inputComponent: PercentFormat as any }}
        onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handlePercentChange(event)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => { setIsPercentFieldSelected(false); }}
        onFocus={(_event: React.FocusEvent<HTMLInputElement>) => setIsPercentFieldSelected(true)}
        {...getDisplayedError(formik, getFormikFieldNameForIneligibleRuleConcentrationPercent())}
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateAdditionalSettings}
      />
      <Autocomplete
        blurOnSelect
        disableClearable
        disablePortal
        value={getSelectedDropdownValue()}
        options={dropdownValues}
        getOptionLabel={option => option.label}
        renderInput={getRenderedInputForDropdown}
        sx={styles.defaultSettingDropdownField}
        ListboxProps={{ style: styles.lisboxForDefaultSettingDropdownField }}
        onChange={handleDropdownChange}
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateAdditionalSettings}
        componentsProps={{
          popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 }
        }}
      />
    </Box>
  );
}

/**
 * Component that handles Contras ineligible's rule's settings.
 */
const ContraSettings: React.FC<DefaultSettingsProps> = (props) => {
  const { formik } = props;
  const { selectedIneligibleIndex: ineligibleIndex, permissions } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  /**
   * This function returns the selected hold type value from Formik.
   * @returns The selected hold type value.
   */
  const getSelectedIneligibleRuleHoldType = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule as IneligibleRuleAPI).holdType as IIneligibleRuleHoldType;

  /**
   * This function generates the Formik field name for Hold Type based on the current ineligible index.
   * @returns The Formik field name for Hold Type.
   */
  const getFormikFieldNameForIneligibleRuleHoldType = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRule.holdType`;

  /**
   * This function generates the input field for the dropdown.
   * @param params The input parameters for Autocomplete.
   * @returns The rendered input field for the dropdown.
   */
  const getRenderedInputForDropdown = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        name={getFormikFieldNameForIneligibleRuleHoldType()}
        size='small'
        sx={styles.textField}
        fullWidth
        placeholder={'Select Hold Type'}
        InputLabelProps={{ sx: styles.textFieldInputLabel }}
      />
    );
  };

  /**
   * This function handles the dropdown value change and updates related Formik fields.
   * @param _event The synthetic event for dropdown change.
   * @param value The selected dropdown value.
   */
  const handleDropdownChange = (_event: React.SyntheticEvent<Element, Event>, value: IIneligibleRuleHoldType | null) => {
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleHoldType(), value);
    formik.setFieldError(getFormikFieldNameForIneligibleRuleHoldType(), undefined);
  };

  return (
    <Box sx={styles.contrasContainer}>
      <Autocomplete
        blurOnSelect
        disableClearable
        disablePortal
        options={[ 'Available AR', 'Lesser of AR & AP' ] as IIneligibleRuleHoldType[]}
        value={getSelectedIneligibleRuleHoldType()}
        renderInput={getRenderedInputForDropdown}
        sx={styles.defaultSettingDropdownField}
        ListboxProps={{ style: styles.lisboxForDefaultSettingDropdownField }}
        onChange={handleDropdownChange}
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateAdditionalSettings}
        componentsProps={{
          popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 }
        }}
      />
    </Box>
  );
}

/**
 * Component that handles Credit Limit ineligible's rule's settings.
 */
const CreditLimitSettings: React.FC<DefaultSettingsProps> = (props) => {
  const { formik } = props;
  const { selectedIneligibleIndex: ineligibleIndex, permissions } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const [isSelected, setIsSelected] = useState(false);

  /**
   * This function returns the selected credit limit from Formik or defaults to NON_EXISTING.
   * @returns The selected credit limit.
   */
  const getSelectedIneligibleRuleCreditLimit = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule as IneligibleRuleAPI).creditLimit as number;

  /**
   * This function generates the Formik field name for the credit limit based on the current ineligible index.
   * @returns The Formik field name for the credit limit.
   */
  const getFormikFieldNameForIneligibleRuleCreditLimit = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRule.creditLimit`;

  /**
   * This function determines whether to show an input adornment (e.g., '$') based on user interaction.
   * @returns The input adornment element or null.
   */
  const getInputAdornment = () => {
    if (isSelected) { return null; }
    if (getSelectedIneligibleRuleCreditLimit() === NON_EXISTING) {
      return <InputAdornment position='start'>$</InputAdornment>;
    }
    return null;
  };

  /**
   * This function handles the change event for the credit limit input field.
   * It updates Formik values and handles errors.
   * @param event The input change or focus event.
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      formik.setFieldValue(getFormikFieldNameForIneligibleRuleCreditLimit(), NON_EXISTING);
      formik.setFieldError(getFormikFieldNameForIneligibleRuleCreditLimit(), `Credit Limit is required`);
      return;
    }
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleCreditLimit(), parseFloat(event.target.value));
    formik.setFieldError(getFormikFieldNameForIneligibleRuleCreditLimit(), undefined);
  };

  return (
    <Box sx={styles.creditLimitContainer}>
      <Field
        as={TextField}
        label='Amount'
        name={getFormikFieldNameForIneligibleRuleCreditLimit()}
        value={getSelectedIneligibleRuleCreditLimit() === NON_EXISTING ? '' : getSelectedIneligibleRuleCreditLimit()}
        size='small'
        type='number'
        sx={{ ...styles.textField, ...styles.numberAlignment }}
        fullWidth
        InputProps={{ startAdornment: getInputAdornment(), inputComponent: DecimalFormat as any }}
        InputLabelProps={{ sx: styles.textFieldInputLabel }}
        onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => { setIsSelected(false); }}
        onFocus={(_event: React.FocusEvent<HTMLInputElement>) => setIsSelected(true)}
        {...getDisplayedError(formik, getFormikFieldNameForIneligibleRuleCreditLimit())}
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateAdditionalSettings}
      />
    </Box>
  );
}

/**
 * Component that handles Criteria Based ineligible's rule's settings.
 */
const CriteriaBasedSettings: React.FC<DefaultSettingsProps> = (props) => {
  const { formik } = props;
  const { selectedIneligibleIndex: ineligibleIndex, permissions, currency, setCurrency } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  /**
   * This function returns the selected cap type from Formik.
   * @returns The selected cap type value.
   */
  const getSelectedIneligibleRuleCapType = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule as IneligibleRuleAPI).capType as IIneligibleRuleCapType;

  /**
   * This function returns the selected cap value from Formik.
   * @returns The selected cap value.
   */
  const getSelectedIneligibleRuleCapValue = () => (formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule as IneligibleRuleAPI).capValue as number;

  /**
   * This function generates the Formik field name for cap type based on the current ineligible index.
   * @returns The Formik field name for cap type.
   */
  const getFormikFieldNameForIneligibleRuleCapType = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRule.capType`;

  /**
   * This function generates the Formik field name for the cap value based on the current ineligible index.
   * @returns The Formik field name for cap value.
   */
  const getFormikFieldNameForIneligibleRuleCapValue = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRule.capValue`;

  const capTypeDropdownValues = [
    { label: 'Amount Limit', name: 'Amount Limit' as const },
    { label: '% Gross AR', name: 'Gross AR' as const },
  ];

  useEffect(() => {
    if (getSelectedIneligibleRuleCapType() !== 'Amount Limit') { return; }
    loadCurrency();
  }, [formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule]);

  const loadCurrency = async () => {
    if (currency !== 'UNLOADED') { return; }
    const loadedCurrency = await getCurrencyForIneligibleCap(formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.ineligibleSettingId);
    setCurrency(loadedCurrency);
  };

  /**
   * This function determines the currently selected cap type dropdown value based on Formik values.
   * @returns The selected cap type dropdown value.
   */
  const getSelectedCapTypeDropdownValue = () => {
    const selectedCapType = getSelectedIneligibleRuleCapType();
    return selectedCapType === 'Amount Limit' ? capTypeDropdownValues[0] : capTypeDropdownValues[1];
  };

  /**
   * This function shows an input adornment (e.g., '$').
   * @returns The input adornment element.
   */
  const getInputAdornmentForAmountField = () => {
    if (currency === 'UNLOADED') {
      return (
        <InputAdornment position='start'>
          <CircularProgress color='inherit' size={15} />
        </InputAdornment>
      );
    }
    return <InputAdornment position='start'>{currency.currencyCode}</InputAdornment>;
  };

  /**
   * This function generates the input field for the cap type dropdown.
   * @param params The input parameters for Autocomplete.
   * @returns The rendered input field for the dropdown.
   */
  const getRenderedInputForDropdown = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        name={getFormikFieldNameForIneligibleRuleCapType()}
        size='small'
        sx={styles.textField}
        fullWidth
        placeholder={'Select Cap Type'}
        inputProps={{ ...params.inputProps, 'data-testid': 'input-for-ineligible-cap-type' }}
        InputLabelProps={{ sx: styles.textFieldInputLabel }}
      />
    );
  };

  /**
   * This function handles the cap type dropdown value change and updates related Formik fields.
   * @param _event The synthetic event for dropdown change.
   * @param value The selected dropdown value.
   */
  const handleCapTypeDropdownChange = (_event: React.SyntheticEvent<Element, Event>, value: { label: string, name: string }) => {
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleCapType(), value.name);
    if (value.name === 'Amount Limit') {
      formik.setFieldValue(getFormikFieldNameForIneligibleRuleCapValue(), NON_EXISTING);
      formik.setFieldError(getFormikFieldNameForIneligibleRuleCapValue(), undefined);
      return;
    }
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleCapValue(), NON_EXISTING_PERCENT);
    formik.setFieldError(getFormikFieldNameForIneligibleRuleCapValue(), undefined);
  };

  /**
   * This function handles the change event for the cap value percentage input field.
   * It updates Formik values and handles errors.
   * @param event The input change or focus event.
   */
  const handlePercentChange = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    formik.setFieldError(getFormikFieldNameForIneligibleRuleCapValue(), undefined);
    if (event.target.value === '') {
      formik.setFieldValue(getFormikFieldNameForIneligibleRuleCapValue(), NON_EXISTING_PERCENT);
      return;
    }
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleCapValue(), parseFloat(event.target.value));
  };

  /**
   * This function handles the change event for the cap value amount input field.
   * It updates Formik values and handles errors.
   * @param event The input change or focus event.
   */
  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    formik.setFieldError(getFormikFieldNameForIneligibleRuleCapValue(), undefined);
    if (event.target.value === '') {
      formik.setFieldValue(getFormikFieldNameForIneligibleRuleCapValue(), NON_EXISTING);
      return;
    }
    formik.setFieldValue(getFormikFieldNameForIneligibleRuleCapValue(), parseFloat(event.target.value));
  };

  /**
   * This function returns a form field component based on the selected cap type.
   */
  const getCapValueField = () => {
    const selectedCapType = getSelectedCapTypeDropdownValue();
    if (selectedCapType.name === 'Amount Limit') {
      return (
        <Field
          as={TextField}
          name={getFormikFieldNameForIneligibleRuleCapValue()}
          value={getSelectedIneligibleRuleCapValue() === NON_EXISTING ? '' : getSelectedIneligibleRuleCapValue()}
          placeholder={`${currency !== 'UNLOADED' ? currency.symbol : ''}0`}
          size='small'
          type='number'
          sx={{ ...styles.textFieldForCriteriaBased, ...styles.numberAlignment }}
          fullWidth
          inputProps={{ prefix: currency !== 'UNLOADED' ? currency.symbol : '' }}
          InputProps={{ startAdornment: getInputAdornmentForAmountField(), inputComponent: DecimalFormatWithNoPrefix as any, 'data-testid': 'input-for-ineligible-cap-value' }}
          InputLabelProps={{ sx: styles.textFieldInputLabel }}
          onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handleAmountChange(event)}
          {...getDisplayedError(formik, getFormikFieldNameForIneligibleRuleCapValue())}
          disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateAdditionalSettings}
          currencySymbol={currency !== 'UNLOADED' ? currency.symbol : ''}
        />
      );
    }
    return (
      <Field
        as={TextField}
        name={getFormikFieldNameForIneligibleRuleCapValue()}
        value={getSelectedIneligibleRuleCapValue() === NON_EXISTING_PERCENT ? '' : getSelectedIneligibleRuleCapValue()}
        placeholder='0%'
        size='small'
        type='number'
        sx={{ ...styles.textFieldForCriteriaBased, ...styles.numberAlignment }}
        fullWidth
        InputProps={{ inputComponent: PercentFormat as any, 'data-testid': 'input-for-ineligible-cap-value' }}
        onChange={(event:  React.ChangeEvent<HTMLInputElement>) => handlePercentChange(event)}
        {...getDisplayedError(formik, getFormikFieldNameForIneligibleRuleCapValue())}
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateAdditionalSettings}
      />
    );
  }

  return (
    <Box sx={styles.criteriaBasedContainer} key={`ineligible-rule-container${ineligibleIndex}`}>
      <Autocomplete
        blurOnSelect
        disableClearable
        disablePortal
        value={getSelectedCapTypeDropdownValue()}
        options={capTypeDropdownValues}
        getOptionLabel={option => option.label}
        renderInput={getRenderedInputForDropdown}
        sx={styles.defaultSettingDropdownFieldForCriteriaBased}
        ListboxProps={{ style: styles.lisboxForDefaultSettingDropdownField }}
        onChange={handleCapTypeDropdownChange}
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateAdditionalSettings}
        componentsProps={{
          popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 }
        }}
      />
      { getCapValueField() }
    </Box>
  );
}

/**
 * This function renders special settings components based on the provided ineligible code and Formik props.
 * This function acts as a switch statement to determine which special settings component to render.
 * @function
 * @param code The ineligible code that determines which special settings to render.
 * @param formik - Formik props for the current form.
 * @returns The rendered special settings component or null if no matching component is found.
 */
const renderSpecialSettings = (code: string, formik: FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>) => {
  switch(code) {
    case 'DLQ':
      return (
        <SpecialSettings>
          <DelinquentSettings formik={formik} />
        </SpecialSettings>
      )
    case 'XA':
      return (
        <SpecialSettings>
          <CrossAgeSettings formik={formik} />
        </SpecialSettings>
      )
    case 'CN':
      return (
        <SpecialSettings>
          <ConcentrationSettings formik={formik} />
        </SpecialSettings>
      )
    case 'CONTRA':
      return (
        <SpecialSettings label='Hold Type'>
          <ContraSettings formik={formik} />
        </SpecialSettings>
      )
    case 'CL':
      return (
        <SpecialSettings>
          <CreditLimitSettings formik={formik} />
        </SpecialSettings>
      )
    case 'AC':
    case 'RFV':
      return null;
    default:
      return (
        <SpecialSettings label='Cap'>
          <CriteriaBasedSettings formik={formik} />
        </SpecialSettings>
      )
  }
}

/**
 * Layout Component that wraps and displays special settings content.
 * It is used to group and style additional settings specific to certain ineligible codes.
 * @param props The component's props.
 * @returns The rendered SpecialSettings component.
 */
const SpecialSettings = (props: { children: React.ReactNode, label?: string }) => {
  return (
    <Box sx={styles.centerBox}>
      <Typography 
          tabIndex={0}
          variant='h6'
          align='center'
          sx={styles.settingsTextStyle}
        >
          { props.label ?? 'Default Settings' }
      </Typography>
      <Box sx={styles.defaultSettingsContent}>
        { props.children }
      </Box>
    </Box>
  )
}

/**
 * Component represents ineligible rule for an ineligible.
 * It displays checkboxes for various settings and additional special settings based on the selected ineligible code.
 * @param props - The component's props.
 * @returns The rendered IneligibleRule component or null if the ineligible code is not recognized.
 */
const IneligibleRule: React.FC<AdditionalSettingsProps> = (props) => {
  const { formik }                                    = props;
  const {
    isIneligibleDetailsLoading,
    selectedIneligibleIndex: ineligibleIndex,
    hasPaymentTerms,
    permissions
  }                                                   = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  /**
   * This function generates the Formik field name for the ineligible rule based on the current ineligible index.
   * @returns The Formik field name for the ineligible rule.
   */
  const getFormikFieldNameForIneligibleRule = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRule`;

  /**
   * This function calculates the grid size for displaying checkboxes based on the number of checkboxes and index.
   * @param index The index of the checkbox.
   * @returns The grid size for the checkbox.
   */
  const getGridSizeForCheckbox = (index: number) => {
    if (getCheckboxesFromCode(formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code).length === 3 && index % 2 !== 0) { return 8; }
    return 4;
  };

  /**
   * This function determines the checked value for a checkbox based on the checkbox item and ineligible rule.
   * @param item The checkbox item.
   * @returns The checked value for the checkbox.
   */
  const getCheckValue = (item: IAdditionalSettingCheckbox) => {
    const ineligibleRule = formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule as IneligibleRuleAPI;
    if (item.name === 'includeCredit') { return !ineligibleRule[item.name as keyof Omit<IneligibleRuleAPI, 'childCustomerOnly'>]; }
    return ineligibleRule[item.name as keyof Omit<IneligibleRuleAPI, 'childCustomerOnly'>];
  };

  /**
   * This function maps and renders checkboxes based on the selected ineligible rule's code.
   * @returns An array of rendered checkboxes.
   */
  const mapRuleCheckboxes = () => {
    const checkboxes = getCheckboxesFromCode(formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code);
    if (checkboxes.length === 0) { return <Box sx={styles.emptyCheckboxesContainerSpace}></Box>}
    return checkboxes.map((item, index) => (
      <Grid
        item
        xs={getGridSizeForCheckbox(index)}
        sx={styles.checkboxGrid}
        key={`${getFormikFieldNameForIneligibleRule()}.${item.name}`}
      >
      <Field
        type='checkbox'
        name={`${getFormikFieldNameForIneligibleRule()}.${item.name}`}
        value={getCheckValue(item)}
        checked={getCheckValue(item)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const formikFieldValue =  item.name === 'includeCredit' ? !e.target.checked : e.target.checked;
          formik.setFieldValue(`ineligibleSettingDetails.${ineligibleIndex}.ineligibleRule.${item.name}`, formikFieldValue);
        }}
        as={FormControlLabel}
        control={<Checkbox size='small' aria-label='Checkbox' />}
        label={item.label}
        componentsProps={{ typography: styles.formControlCheckbox }}
        disabled={!(permissions as IIneligibleSettingsPermissions).canUpdateAdditionalSettings}
      />
      </Grid>
    ));
  };

  const gridForAdditionalSettings = useMemo(() =>
    isIneligibleDetailsLoading || ['DLQ', 'AC', 'XA', 'CN', 'CONTRA', 'CL', 'RFV'].includes(formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code) ? 9 : 8
  , [isIneligibleDetailsLoading, formik.values.ineligibleSettingDetails, ineligibleIndex]);

  const gridForSpecialSettings = useMemo(() => 
    isIneligibleDetailsLoading || ['DLQ', 'AC', 'XA', 'CN', 'CONTRA', 'CL', 'RFV'].includes(formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code) ? 3 : 4
  , [isIneligibleDetailsLoading, formik.values.ineligibleSettingDetails, ineligibleIndex]);

  const isIneligibleLoading = () => {
    if (isIneligibleDetailsLoading) { return true; }
    const isRuleLoading = formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule === undefined;
    if (isRuleLoading) { return true; }
    const isDelinquentLoading = formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code === 'DLQ' && hasPaymentTerms === 'UNLOADED';
    return isDelinquentLoading;
  };

  /**
   * This function generates the rendered content based on the component's state and props.
   * If `isIneligibleDetailsLoading` is true, it displays a loading skeleton; otherwise, it renders the ineligible rule and related settings.
   * @returns The rendered content.
   */
  const getRenderedContent = () => {
    if (isIneligibleLoading()) {
      return (
        <Stack sx={styles.skeletonLoaderContainer} data-testid='loading-for-ineligible-rule'>
          <Skeleton variant='rectangular' sx={styles.shortSkeletonLoader}  />
          <Skeleton variant='rectangular' sx={styles.skeletonLoader} />
          <Skeleton variant='rectangular' sx={styles.shortSkeletonLoader}  />
        </Stack>
      );
    }
    return (
      <>
        <Paper sx={styles.paperStyle}>
          <Grid container spacing={0}>
            <Grid item xs={gridForAdditionalSettings} sx={styles.additionalSettings}>
              <Typography
                tabIndex={0}
                variant='h6'
                align='center'
                sx={styles.titleTextStyle}
              >
                { formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.description }
              </Typography>
              <Grid
                container
                sx={styles.checkboxContainer}
              >
                { mapRuleCheckboxes() }
              </Grid>
            </Grid>
            <Grid item xs={gridForSpecialSettings} sx={styles.defaultSettings}>
              { renderSpecialSettings(formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code, formik) }
            </Grid>
          </Grid>
        </Paper>
        { formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code === 'AC' && (
          <Box sx={styles.infoPaperStyle}>
            <Box sx={styles.infoBoxStyle}>
              <InfoIcon tabIndex={0} sx={styles.infoTextStyle} />
              <Typography tabIndex={0} sx={styles.infoTextStyle}>{'Aged Credits is based on Delinquent.'}</Typography>
            </Box>
          </Box>
        )}
        { formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code === 'RFV' && (
          <Box sx={styles.infoPaperStyle}>
            <Box sx={styles.infoBoxStyle}>
              <InfoIcon tabIndex={0} sx={styles.infoTextStyle} />
              <Typography tabIndex={0} sx={styles.infoTextStyle}>{'Roll Forward Variance is specific to Roll Forward Analysis.'}</Typography>
            </Box>
          </Box>
        )}
      </>
    );
  };

  return getRenderedContent();
};

export default IneligibleRule;
