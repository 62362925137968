import { FC, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, IconButton, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import styles from './styles';
import CircleIcon from '@mui/icons-material/Circle';
import { DocumentIssueTypes } from '../../../../interfaces/fileimport';
import DisabledComponentsContainer from '../../../common/disabled-components-container';

export interface IWarningModal {
  open                : boolean;
  onClose             : () => void;
  onConfirm?          : (() => Promise<void>) | (() => void);
  displayOnly?        : boolean;
  issueType?          : DocumentIssueTypes;
  issueMessages?      : string[];
  general?            : boolean;
  noDataIncluded?     : boolean;
  warningModalSaving? : boolean;
}

/**
 * This component represents a warning modal.
 * @param props The props for the warning modal.
 * @returns A component representing the warning modal.
 */
const WarningModal: FC<IWarningModal> = (props) => {
  const { open, onClose, onConfirm, displayOnly, issueType, issueMessages, general, noDataIncluded, warningModalSaving } = props;

  const [checked, setChecked] = useState<boolean>(false);

  /**
   * This function generates an issue message component based on the provided array of issue messages.
   * @param issueMessages An array of issue messages.
   * @param general A flag indicating whether the issue messages are general.
   * @returns A React JSX element or an empty string representing the issue messages.
   */
  const createIssueMessage = (issueMessages?: string[], general?: boolean) => {
    if (issueMessages?.length) {
      if (issueMessages.length === 1 && !general) {
        return (<Typography tabIndex={0} sx={styles.issueMessage}>{issueMessages[0]}</Typography>)
      } else {
        return (
          <>
          {issueMessages.map(issueMessage => (
            <Box tabIndex={0} key={issueMessage} sx={styles.issueMessageContainer}>
              <CircleIcon sx={styles.bulletIcon} />
              <Typography tabIndex={0} sx={styles.issueMessage}>{issueMessage}</Typography>
            </Box>))}
          </>
        )
        
      }
    } else {
      return ''
    }
  }

  /**
   * This function gets an aria-label for the proceed button.
   * @returns An aria-label for Proceed Button if it is not disabled.
   */
  const getProceedAriaLabel = () => {
    if (noDataIncluded) {
      return undefined;
    } else if (!checked) {
      return 'Proceed button disabled';
    } else {
      return 'Proceed'
    }
  };

  /**
   * This function returns a save button component based on the current state of the warning modal.
   * @returns A React JSX element representing the save button.
   */
  const getSaveButton = () => {
    if (warningModalSaving) {
      return (
        <DisabledComponentsContainer isDisabled={warningModalSaving}>
          <Button
            variant='contained'
            disabled={warningModalSaving}
            sx={styles.buttonProceed}
          >
            <CircularProgress size={15} />
          </Button>
        </DisabledComponentsContainer>
      )
    } else {
      return (
      <DisabledComponentsContainer isDisabled={noDataIncluded ? false : !checked}>
        <Button
          variant='contained'
          autoFocus={open ? true : undefined}
          onClick={async () => {
            if (onConfirm) await onConfirm();
            onClose();
          }}
          disabled={noDataIncluded ? false : !checked}
          aria-label={getProceedAriaLabel()}
          sx={styles.buttonProceed}
        >
          Proceed
        </Button>
      </DisabledComponentsContainer>
      )
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.modalContainer}>
        <Box sx={issueType === 'error' ? styles.headingContainerError : styles.headingContainer}>
          <Box sx={styles.headingTitleAndIcon}>
            <WarningAmberRoundedIcon tabIndex={0} sx={issueType === 'error' ? styles.errorIcon : styles.warningIcon} />
            {issueType === 'error' ?
              (<Typography tabIndex={0} sx={styles.headingTitleError}>{'Error!'}</Typography>) :
              (<Typography tabIndex={0} sx={styles.headingTitle}>{'Warning!'}</Typography>)}
          </Box>
          <IconButton
            aria-label='Close icon'
            onClick={() => {
            setChecked(false);
            onClose();
            }}
          >
            <Close sx={styles.closeIcon}/>
          </IconButton>
        </Box>

        <Divider sx={styles.divider} />

        <Box sx={styles.bodyContainer}>
          {general && issueType === 'warning' &&
            <Typography tabIndex={0} sx={styles.issueMessage}>
              {'The following file/s have a warning on the data. Are you sure you want to proceed?'}
            </Typography>}
          {createIssueMessage(issueMessages, general)}
        </Box>

        <Divider sx={styles.divider} />
        
        {displayOnly ?
          <Box sx={styles.buttonsContainerDisp}>
            <Button
              variant='contained'
              autoFocus={open ? true : undefined}
              onClick={() => onClose()}
              sx={styles.buttonProceed}
            >
              Okay
            </Button>
          </Box>
        :
          <Box sx={styles.buttonsContainer}>
            <Box>
              <FormControlLabel
              sx={{display: noDataIncluded ? 'none' : 'flex'}}
                control={
                  <Checkbox
                    size='small'
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                }
                label={
                  <Typography tabIndex={0} variant="body2" fontSize='0.875rem'>
                    Ignore Data Issues
                  </Typography>
                }
              />
            </Box>
            <Box sx={styles.actionContainer}>
              <Button
                variant='outlined'
                onClick={() => {
                  setChecked(false);
                  onClose();
                }}
                sx={styles.buttonCancel}
              >
                Cancel
              </Button>
              {getSaveButton()}
            </Box>
          </Box>}
      </Box>
    </Modal>
  );
};
export default WarningModal;