import { List, ListItem, ListItemText } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import styles from './styles'
import { FC } from "react";

interface IProps {
  hasUppercase : boolean,
  hasLowercase : boolean,
  hasDigit : boolean,
  hasSpecialCharacter : boolean,
  hasMinCharacters : boolean
}

/**
 * Component for showing the Password Requirements component.
 * @param props The props for the PasswordRequirements component. See the IProps interface for more information.
 */
const PasswordRequirements: FC<IProps> = (props) => {
  const {hasUppercase, hasLowercase, hasDigit, hasSpecialCharacter, hasMinCharacters} = props;
  
  return (
    <List>
      <ListItem disablePadding>
        <CircleIcon sx={{...styles.bullet, ...(!hasMinCharacters && styles.disabledBullet)}}/>
        <ListItemText primaryTypographyProps={{sx:{...styles.listText, ...(!hasMinCharacters && styles.disabledText)}}}  primary="Minimum of 8 characters" />
      </ListItem>
      <ListItem disablePadding>
        <CircleIcon sx={{...styles.bullet, ...(!hasUppercase && styles.disabledBullet)}}/>
        <ListItemText primaryTypographyProps={{sx:{...styles.listText, ...(!hasUppercase && styles.disabledText)}}}  primary="At least 1 uppercase character (A-Z)" />
      </ListItem>
      <ListItem disablePadding>
        <CircleIcon sx={{...styles.bullet, ...(!hasLowercase && styles.disabledBullet)}}/>
        <ListItemText primaryTypographyProps={{sx:{...styles.listText, ...(!hasLowercase && styles.disabledText)}}}  primary="At least 1 lowercase character (a-z)" />
      </ListItem>
      <ListItem disablePadding>
        <CircleIcon sx={{...styles.bullet, ...(!hasDigit && styles.disabledBullet)}}/>
        <ListItemText primaryTypographyProps={{sx:{...styles.listText, ...(!hasDigit && styles.disabledText)}}}  primary="At least 1 numerical number" />
      </ListItem>
      <ListItem disablePadding>
        <CircleIcon sx={{...styles.bullet, ...(!hasSpecialCharacter && styles.disabledBullet)}}/>
        <ListItemText primaryTypographyProps={{sx:{...styles.listText, ...(!hasSpecialCharacter && styles.disabledText)}}}  primary="At least 1 special character" />
      </ListItem>
    </List>
  );
};

export default PasswordRequirements;