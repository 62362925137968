import { createContext, useMemo, useState } from 'react';
import { LayoutProps } from './authContext';
export interface IUsersAndRolesContext {
  isDirty: boolean,
  setIsDirty: (value : boolean) => void,
  isAddRoleDirty: boolean,
  setIsAddRoleDirty: (value : boolean) => void,
  showPrompt: boolean,
  setShowPrompt: (value : boolean) => void
  newTabIndex: number,
  setNewTabIndex: (value : number) => void,
  canViewUsersAndRoles: boolean,
  setCanViewUsersAndRoles: (value : boolean) => void,
  canAddRole: boolean,
  setCanAddRole: (value : boolean) => void,
  canUpdateRole: boolean,
  setCanUpdateRole: (value : boolean) => void,
  canDeleteRole: boolean,
  setCanDeleteRole: (value : boolean) => void,
  canAddUser: boolean,
  setCanAddUser: (value : boolean) => void,
  canUpdateUser: boolean,
  setCanUpdateUser: (value : boolean) => void,
  canDeactivateUser: boolean,
  setCanDeactivateUser: (value : boolean) => void,
  canReactivateUser: boolean,
  setCanReactivateUser: (value : boolean) => void,
  canDeleteUser: boolean,
  setCanDeleteUser: (value : boolean) => void
}
export const UsersAndRolesContext = createContext<IUsersAndRolesContext | null>(null);
const UsersAndRolesProvider = (props: LayoutProps) => { 
  const { children } = props;
  
  const [ isDirty, setIsDirty ] = useState<boolean>(false);
  const [ isAddRoleDirty, setIsAddRoleDirty ] = useState<boolean>(false);
  const [ showPrompt, setShowPrompt] = useState<boolean>(false);
  const [newTabIndex, setNewTabIndex] = useState<number>(0);
  const [ canViewUsersAndRoles, setCanViewUsersAndRoles] = useState<boolean>(false);
  const [ canAddRole, setCanAddRole] = useState<boolean>(false);
  const [ canUpdateRole, setCanUpdateRole] = useState<boolean>(false);
  const [ canDeleteRole, setCanDeleteRole] = useState<boolean>(false);
  const [ canAddUser, setCanAddUser] = useState<boolean>(false);
  const [ canUpdateUser, setCanUpdateUser] = useState<boolean>(false);
  const [ canDeactivateUser, setCanDeactivateUser] = useState<boolean>(false);
  const [ canReactivateUser, setCanReactivateUser] = useState<boolean>(false);
  const [ canDeleteUser, setCanDeleteUser] = useState<boolean>(false);
  const value = useMemo(() => ({ 
    isDirty,
    setIsDirty,
    isAddRoleDirty,
    setIsAddRoleDirty,
    showPrompt,
    setShowPrompt,
    newTabIndex,
    setNewTabIndex,
    canViewUsersAndRoles,
    setCanViewUsersAndRoles,
    canAddRole,
    setCanAddRole,
    canUpdateRole,
    setCanUpdateRole,
    canDeleteRole,
    setCanDeleteRole,
    canAddUser,
    setCanAddUser,
    canUpdateUser,
    setCanUpdateUser,
    canDeactivateUser,
    setCanDeactivateUser,
    canReactivateUser,
    setCanReactivateUser,
    canDeleteUser,
    setCanDeleteUser,
   }), [
    isDirty,
    setIsDirty,
    isAddRoleDirty,
    setIsAddRoleDirty,
    showPrompt,
    setShowPrompt,
    newTabIndex,
    setNewTabIndex,
    canViewUsersAndRoles,
    setCanViewUsersAndRoles,
    canAddRole,
    setCanAddRole,
    canUpdateRole,
    setCanUpdateRole,
    canDeleteRole,
    setCanDeleteRole,
    canAddUser,
    setCanAddUser,
    canUpdateUser,
    setCanUpdateUser,
    canDeactivateUser,
    setCanDeactivateUser,
    canReactivateUser,
    setCanReactivateUser,
    canDeleteUser,
    setCanDeleteUser
   ])
  return (
    <UsersAndRolesContext.Provider
      value={value}
    >
      {children}
    </UsersAndRolesContext.Provider>
  );
};
export default UsersAndRolesProvider;