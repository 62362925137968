const styles = {
  label: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    '&::after':{
      content:'"*"',
      color: '#AE2633',
    },
  },
  paper: {
    fontSize: '14px',
    backgroundColor: 'white',
    width: '17rem',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  dropdown: {
    marginTop: '0.5rem',
  },
  modalContainer: {
    width: '100%',
    marginTop: '-1rem'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    marginTop: '1rem',
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  fieldLabel: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '0.5rem',
    marginTop: '0.438rem'
  },
  field: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      'WebkitBoxShadow': 'none',
    },
    '& .Mui-error': {
      fontSize: '11px',
      marginTop: '1px',
      marginX: '0',
    },
  },
  buttonGroup: {
    display: 'flex',
    gap: '0.5rem',
    alignSelf: 'flex-end'
  },
  buttonCommon: {
    height: '2rem',
    width: '5rem'
  },
  buttonSave: {
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#99BFED',
    },
  },
}
export default styles;