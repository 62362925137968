const styles = {
  grandTotalAmountText: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '1rem',
    fontWeight: 'bold',
  },
  grandTotalAmount: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  grandTotalAmountContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}
export default styles;