import * as Yup from 'yup';

const signInSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email Address is required'),
  password: Yup.string()
    .min(8, 'Your password must be at least 8 characters')
    .required('Password is required'),
});

export default signInSchema