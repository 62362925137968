import * as React from 'react';
import { Avatar, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from './styles';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../../context/authContext';

const SignOutButton = styled(Button)({
  textTransform: 'none',
});

const MenuDrawer: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);

  const signOut = () => {
    navigate('/sign-in');
  };

  const avatar = {
    alt: 'JP Morgan',
    src: '/static/images/avatar/2.jpg',
  };

  return (
    <Grid container sx={styles.menuBox}>
      <Grid item marginLeft={2}>
        <Avatar alt={avatar.alt} src={avatar.src} sx={styles.avatarHolder}>
          {state.firstName[0]}{state.lastName[0]}
        </Avatar>
      </Grid>
      <Grid item marginLeft={3}>
        <Typography variant='body1' sx={styles.name}>
          {state.firstName} {state.lastName}
        </Typography>
        <Typography variant='body1'>{state.email}</Typography>
      </Grid>
      <Grid item xs={12} sx={styles.divider}></Grid>
      <Grid item xs={12} marginLeft={2.5}>
        <SignOutButton variant='text' sx={styles.signout} onClick={signOut}>
          Sign out
        </SignOutButton>
      </Grid>
    </Grid>
  );
};

export default MenuDrawer;
