import React, { createContext, Dispatch, useEffect, useMemo, useReducer } from "react";
import { AuthActions, setLoginToken } from "../actions/authActions";
import authReducer, { AuthState, initialState } from '../reducer/authReducer';
import { setLocalStorageItem } from "../utility/helper";
import axiosInstance from "../service/axiosInstance";
import api, { authAPI } from "../service/api";
import { GET, POST } from "../utility/constants";
import { IAccessResponseAPI, IUserAPI } from "../interfaces/rolesPermissionInterface";

export interface AuthContextInterface {
  state     : AuthState;
  dispatch  : Dispatch<AuthActions>;
}

export const AuthContext = createContext<AuthContextInterface>({
  state     : initialState,
  dispatch  : () => undefined
});

export interface LayoutProps  { 
  children: React.ReactNode;
}

const AuthContextProvider = (props: LayoutProps) => {
  const children = props.children;

  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    if (state.token && state.uid) {
      setLocalStorageItem('token', state.token);
      setLocalStorageItem('refreshToken', state.refreshToken);
      setLocalStorageItem('uid', state.uid);
      setLocalStorageItem('email', state.email);
      setLocalStorageItem('firstName', state.firstName);
      setLocalStorageItem('lastName', state.lastName);
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');      
      localStorage.removeItem('uid');
      localStorage.removeItem('email');
      localStorage.removeItem('firstName');      
      localStorage.removeItem('lastName');
    }
  }, [state.token, state.uid]);

  useEffect(() => {
    if (state.email === '') {
      setUserData(state.uid);
    }
  }, [state.email])

  const value = useMemo(() => ({state, dispatch}), [state, dispatch]);

  const setUserData = async (userId : string) => {
    try {
      const response = await axiosInstance.request({
        url: api.USER_BY_UID,
        method: GET,
        params: {userId : userId},
        headers: {
          token : state.token ?? ''
        }
      })
      const user : IUserAPI = response.data;
      setLocalStorageItem('email', user.email);
      setLocalStorageItem('firstName', user.firstName);
      setLocalStorageItem('lastName', user.lastName);
      const token : AuthState = {
        token: state.token,
        refreshToken: state.refreshToken,
        uid: state.uid,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      }
      dispatch(setLoginToken(token));
    } catch (e) {
      console.log(e)
    }
  };

  // const checkTokenValidity = async (token : string) => {
  //   const response = await axiosInstance.request({
  //     url: authAPI.CHECK_TOKEN_VALIDITY,
  //     method: POST,
  //     headers: {
  //       token: token
  //     }
  //   })

  //   const accessResponse : IAccessResponseAPI = response.data;
  //   setLocalStorageItem('token', accessResponse.loginToken.accessToken);
  //   setLocalStorageItem('uid', accessResponse.user.id);
  //   setLocalStorageItem('email', accessResponse.user.email);
  //   setLocalStorageItem('firstName', accessResponse.user.firstName);
  //   setLocalStorageItem('lastName', accessResponse.user.lastName);
  // }

  return (
    <AuthContext.Provider value={value}>
      {
        children
      }
    </AuthContext.Provider>
  )
}

export default AuthContextProvider;