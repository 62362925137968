import { createContext, useMemo, useState } from 'react';
import { IClient, IDatePeriod } from '../interfaces/reportsInterface';
import { LayoutProps } from './authContext';

export interface IReportsContext {
  selectedClient: IClient | null;
  setSelectedClient: (client: IClient | null) => void;
  selectedDatePeriod: IDatePeriod | null;
  setSelectedDatePeriod: (datePeriod: IDatePeriod | null) => void;
  clients: Array<IClient> | null;
  setClients: (clients: Array<IClient> | null) => void;
  datePeriods: Array<IDatePeriod>;
  setDatePeriods: (datePeriods: Array<IDatePeriod>) => void;
}

export const ReportsContext = createContext<IReportsContext | null>(null);

const ReportsProvider = (props: LayoutProps) => {
  const { children } = props;

  const [selectedClient, setSelectedClient] = useState<IClient | null>(null);
  const [selectedDatePeriod, setSelectedDatePeriod] = useState<IDatePeriod | null>(null);
  const [clients, setClients] = useState<Array<IClient> | null>(null);
  const [datePeriods, setDatePeriods] = useState<Array<IDatePeriod>>([]);

  const value = useMemo(() => ({
    selectedClient,
    setSelectedClient,
    selectedDatePeriod,
    setSelectedDatePeriod,
    clients,
    setClients,
    datePeriods,
    setDatePeriods,
  }), [
    selectedClient,
    setSelectedClient,
    selectedDatePeriod,
    setSelectedDatePeriod,
    clients,
    setClients,
    datePeriods,
    setDatePeriods
  ])

  return (
    <ReportsContext.Provider
      value={value}>
      {children}
    </ReportsContext.Provider>
  );
};

export default ReportsProvider;
