import { Box, Paper, Tooltip, Typography, LinearProgress, Checkbox, Table, TableHead, TableRow, TableCell, TableContainer, TableBody, Link } from "@mui/material"
import DraggableTable from "../../../../pages/customer-setting/draggable-table"
import styles from "../styles"
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { IModalProps, IParentChildSetupContext } from "../../../../interfaces/parentChildSetupInterface";
import { ParentChildSetupContext } from "../../../../context/parentChildSetupContext";
import { DraggableListItem } from "../../../../interfaces/draggableList";
import { IOption } from "../../../../interfaces/comboBox";
import { SelectedClientContext } from "../../../../context/selectedClientContext";
import { IClearModalProps } from "../..";
import SearchParentChild from "../../search-parent-child";

const filters = ['All', 'New'];

interface IProps {
  selectedARCollateral: IOption | null
  modalProps: IModalProps
  setModalProps: React.Dispatch<React.SetStateAction<IModalProps>>
  setClearModal: React.Dispatch<React.SetStateAction<IClearModalProps>>
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
}

const OrphanSection = (props: IProps) => {
  const {orphansList,
         setOrphansList,
         parentsWithChildrenList,
         orphansPage,
         setOrphansPage,
       } = useContext(ParentChildSetupContext) as IParentChildSetupContext;

  const orphanObserver                                        = useRef<any>();
  const [isSelectedAll, setIsSelectedAll]                     = useState<boolean>(false);
  const [displayOrphans, setDisplayOrphans]                   = useState<DraggableListItem[]>([]);
  const [filteredOrphanList, setFilteredOrphanList]           = useState<DraggableListItem[]>([]);

  // HOOKS

  /**
   * This useEffect triggers when pagination was changed.
   */
  useEffect(() => {
    getOrphanDisplay()
  }, [orphansPage.searchFilter, orphansPage.searchKey, orphansPage.pageNo, orphansList])

  /**
   * This function is used to filter parent customers from orphan section
   */
  useEffect(() => {
    if(parentsWithChildrenList.length > 0){
     const temp = orphansList.filter(cust => parentsWithChildrenList.some(o => o.custSrcId !== cust.srcId))
     setOrphansList(temp)
    }

  }, [parentsWithChildrenList])

  // NON API FUNCTIONS

  /**
   * This function set all displayed orphans state to checked.
   */
  const handleSelectAll = () => {
    setOrphansList(orphansList.map(orphan => {
      return {
        ...orphan,
        checked: !isSelectedAll
      }
    }))
    setIsSelectedAll(!isSelectedAll)
  }

  /**
   * This function set a specicic orphan state to checked.
   * 
   * @param updatedList The updated list of AR Customer formatted as DraggableListItem
   */
  const handleOnCheck = (updatedList: DraggableListItem[]) => {
    const newList = orphansList.map((originalObj) => {
      const updatedObj = updatedList.find((updatedObj) => updatedObj.recordId === originalObj.recordId);
      if (updatedObj) {
        return updatedObj;
      } else {
        return originalObj;
      }
    });

    setOrphansList(newList)
    setIsSelectedAll(
      orphansList.every(orphan => {
        return orphan.checked
      }))
  }


  //EVENT LISTENER - FOR INFINITE SCROLL

  /**
   * This useCallback is used to hanlde infinite scrolling on orphan section.
   */
  const lastRowOrphanElementRef = useCallback((node: any) => {
    if (orphansPage.isLoading) return
    if (orphanObserver.current) orphanObserver.current.disconnect()
    
    orphanObserver.current = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting && !orphansPage.isLastPage) {
        setOrphansPage((prevState) => ({
          ...prevState, 
          isLoading: true,
          pageNo: prevState.pageNo+1
        }))
      }
    })

    if (node) orphanObserver.current.observe(node)
  }, [])

  /**
   * This function filtered the orphan list to be displayed base on the pagination props.
   */
  const getOrphanDisplay = () => {
    const newList = orphansList.filter(orphan => {
      const conditions: boolean[] = [];
      if (orphansPage.searchFilter === 'New') {
        conditions.push(orphan.isNew as boolean);
      }
      conditions.push(orphan.name.toLowerCase().includes(orphansPage.searchKey.toLowerCase()));
      return conditions.reduce((acc, condition) => acc && condition);
    });
    const displayList = newList.slice(0, orphansPage.pageNo * 10)
    setDisplayOrphans(displayList)

    setFilteredOrphanList(newList);
    setOrphansPage(prevState => ({...prevState, isLastPage: newList.length === displayList.length}))
  }

  /**
   * This function check if the dragged AR Customer from parent section is the last child.
   * 
   * @param parentId The ID of the parent where the dragged AR Customers came from.
   * @param tempId The temporarary ID of the Parent where the dragged AR Customers came from.
   * @returns A boolean that determine if the last dragged AR Customer is/are last.
   */
  const checkIfLast = (parentId: number, tempId?: number): boolean => {
    const parent = parentsWithChildrenList.find(parent => (isNaN(parentId) || parent.id === parentId) && parent.tempId === tempId)
    if(parent){
      return parent.children.length < 2
    }
    return false
  }

  return(
    <Box component={Paper} sx={styles.sectionBox}>
      <Box sx={styles.clearButtonBox}>
        <Link 
          component={'button'} 
          sx={styles.clearButton}
          onClick={() => {
            props.setClearModal({
              isOpen: true,
              type: 'customer',
            });
          }}
        >
          Clear All New Customers
        </Link>
      </Box>
      <Box sx={{ marginBottom: '1rem' }}>
        <SearchParentChild
          filters={filters}
          onChange={value => {
            setOrphansPage({
              ...orphansPage,
              pageNo: 1,
              searchKey: value,
              isLoading: true
            })
          }}
          onSelect={selected => {
            setOrphansPage({
              ...orphansPage,
              pageNo: 1,
              searchFilter: selected,
              isLoading: true
            })
          }}
        />
      </Box>
      <TableContainer sx={styles.sectionTableContainer}>
        <Table stickyHeader>
          <TableHead sx={styles.sectionTableHead}>
            <TableRow sx={styles.sectionTableHeaderRow}>
              <TableCell sx={styles.orphanTableSelectColumn}>
                <Box sx={{ display: 'flex' }}>
                  <Box width={'28px'}/>
                  <Tooltip title='Select All'>
                    <Checkbox
                      id='checkbox'
                      data-testid='select-all-checkbox'
                      aria-label='Checkbox'
                      aria-labelledby='checkbox'
                      edge="end"
                      inputProps={{'aria-label':'Checkbox', 'aria-labelledby':'checkbox'}}
                      checked={isSelectedAll}
                      disableRipple
                      onClick={() => handleSelectAll()}
                      sx={{ px: 0 }}
                    />
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell sx={styles.orphanTableNameCell}>
                <Typography tabIndex={0} sx={{...styles.sectionTableHeaderText, textAlign: 'left' }}>
                  Customer Name
                </Typography>
              </TableCell>
              <TableCell sx={styles.orphanTableHeaderCell}>
                <Typography tabIndex={0} sx={{...styles.sectionTableHeaderText, textAlign: 'right' }}>
                  AR Amount
                </Typography>
              </TableCell>
              <TableCell sx={styles.orphanTableHeaderDateCreated}>
                <Typography tabIndex={0} sx={{...styles.sectionTableHeaderText, textAlign: 'right' }}>
                  Date Created
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={styles.tableSpacer}></TableRow>
          </TableBody>
          {
            <DraggableTable
              list={displayOrphans}
              fullList={filteredOrphanList}
              multiple={true}
              name="orphans"
              selectedAll={isSelectedAll}
              onItemChecked={(list) => handleOnCheck(list)}
              onItemDrop={(list, from, to) => {
                  props.setIsDragging(false)
                  props.setModalProps({
                    ...props.modalProps,
                    isLastChild: checkIfLast(Number(from), props.modalProps.tempId),
                    isParent: false,
                    isOpen: true,
                    children: list,
                    parentId: Number(from),
                    from: Number(from),
                    to: Number(to)
                  })
              }
            }
              onItemDragOver={(list) => {}}
              onItemDragStart={() => props.setIsDragging(true)}
            >
              {!orphansPage.isLastPage?
                <LinearProgress ref={lastRowOrphanElementRef}/> : <></>
              }
            </DraggableTable>
          }
        </Table>
      </TableContainer>
    </Box>
  )
}

export default OrphanSection

