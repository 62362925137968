import { useContext, useState } from 'react';
import { Autocomplete, Box , Chip, Grid, TextField,Typography } from '@mui/material';
import { SelectedClientContext } from '../../../../context/selectedClientContext';
import styles from './styles';
import GeneralBreadcrumbs from '../../../../components/breadcrumb';
import { IClient } from '../../../../interfaces';

/**
 * Component for rendering navigation header to AP Aging Report page.
 */
const APAgingReportNavigationHeader = () => {
  const { selectedClient, setSelectedClient, clients }    = useContext(SelectedClientContext);

  const [clientInput, setClientInput]                     = useState('');

  /**
   * This function handles the change event when a client is selected from the Autocomplete dropdown.
   * @param _event The event object.
   * @param newValue The selected client value.
   */
  const handleClientChange = (_event: any, newValue: IClient | null ) => {
    setSelectedClient(newValue);
  };

  /**
   * This function retrieves the label for a client option.
   *
   * @param option The client option for which the label is to be retrieved.
   * @returns The label associated with the client option, which is the 'borrowerName' property.
   */
  const getClientOptionLabel = (option: IClient) => {
    const nonNullOption = option as Required<IClient>;
    return nonNullOption.borrowerName;
  };

  /**
   * This function determines if a client is a a parent.
   * @param option The name of the selected client
   * @returns true if the client is a parent, otherwise, false.
   */
  const isSelectedFieldNameParentFieldValue = (option: string) => {
    const parentClients = clients.filter(client => client.parentClient).map(client => client.borrowerName);
    return parentClients.includes(option);
  };

  return (
    <Box sx={styles.navigationHeaderContainer}>
      <Grid container sx={styles.headerContainer}>
        <Grid item xs={12} md={6} lg={8} xl={8.3}>
          <GeneralBreadcrumbs
            selectedText='AP Aging Report'
            breadcrumbLinks={[{ linkText: 'Reports', route: '/reports' }]}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
          <Box sx={styles.clientBox}>
            <Typography
              tabIndex={0}
              component='label'
              htmlFor={`combo-box-clients`}
              sx={styles.clientDropdownTitle}
            >
              Client Name
            </Typography>
            <Autocomplete
              blurOnSelect
              disablePortal
              id='combo-box-clients'
              options={clients.filter(client => (client.parentClientFk === null || client.parentClientFk === undefined))}
              getOptionLabel={option => getClientOptionLabel(option)}
              isOptionEqualToValue={(option: IClient, value: IClient) => option.recordId === value.recordId}
              renderOption={(prop, option) => {
                return (
                  <Box component='li' {...prop} sx={{ fontSize:'14px' }}>
                    <Box tabIndex={0} sx={styles.labelChipContainer}>{option.borrowerName}</Box>
                      <Box sx={{ ...styles.parentChipContainer, ...(!isSelectedFieldNameParentFieldValue(option.borrowerName ?? '') && styles.hidden) }}>
                        <Chip size='small' sx={styles.parentIdentifierChip} />
                      </Box>
                  </Box>
                );
              }}
              size='small'
              fullWidth
              sx={styles.comboBox}
              renderInput={(params) =>
                <TextField
                  {...params}
                  placeholder={'Please Select'}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment:(
                      <>
                        <Box sx={{ ...styles.inputParentChipContainer, ...(!isSelectedFieldNameParentFieldValue(clientInput) && styles.hidden) }}>
                          <Chip size='small' sx={styles.parentIdentifierChip} />
                        </Box>
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              }
              value={selectedClient?.label ? null : selectedClient}
              onChange={handleClientChange}
              inputValue={clientInput}
              onInputChange={(_event, newInputValue) => setClientInput(newInputValue)}
              componentsProps={{
                popupIndicator: { 'aria-label': 'Dropdown icon', tabIndex: 0 },
                clearIndicator: { 'aria-label': 'Clear icon', tabIndex: 0}
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
};

export default APAgingReportNavigationHeader;
