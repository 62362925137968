import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import FieldMapping from './field-mapping';
import { Grid, IconButton, Modal, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { IFileRowProps } from '..';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles';
import CalculationMapping from './calculation-mapping';
import ConfirmModal from '../../../../../../../components/modals/confirm-modal';
import { PROMPT } from '../../../../../../../utility/constants';
import { usePrompt } from '../../../../../../../utility/prompt';
import ExcludeRows from './exclude-rows';
import { IUploadedFile } from '../../../../../../../interfaces/fileimport';

export interface IMappingModalProps extends IFileRowProps {
  open                      : boolean;
  handleClose               : () => void;
  requiredFields            : string[];
  optionalFields            : string[];
  setSelectedFile?          : Dispatch<SetStateAction<IUploadedFile | null>>;
  importedFiles?            : IUploadedFile[];
  setImportedFiles?         : Dispatch<SetStateAction<IUploadedFile[]>>;
  filteredRows?             : IUploadedFile[];
  setFilteredRows?          : Dispatch<SetStateAction<IUploadedFile[]>>;
}

const steps = ['Exclude Rows', 'Data Mapping'];
const glSteps = [...steps, 'Calculation Mapping'];

/**
 * Component for the Mapping Modal of the Data Map Step.
 * @param props The props for the Mapping Modal Component.
 * @returns A component for the Mapping Modal of the Data Map Step.
 */
const MappingModal: FC<IMappingModalProps> = (props) => {
  const { open, handleClose, uploadedFile }               = props;
  const [activeStep, setActiveStep]                       = useState<number>(0);
  const [isDirty, setIsDirty]                             = useState<boolean>(false);
  const [showPrompt, setShowPrompt]                       = useState<boolean>(false);
  const [selectedSheet, setSelectedSheet]                 = useState<string | null>(uploadedFile.selectedSheet ?? null);
  const [excludedRowsEndRowNum, setExcludedRowsEndRowNum] = useState<number | undefined>(uploadedFile.excludedRowsEndRowNum);
  const [isAutoMapped, setIsAutoMapped]                   = useState<boolean>(false);

  /**
   * This effect is triggered when the 'open' prop changes. If 'open' becomes false,
   * it resets the active step to 0 and sets 'isDirty' to false.
   */
  useEffect(() => {
    if (!open) {
      setActiveStep(0);
      setIsDirty(false);
      setSelectedSheet(null);
      setExcludedRowsEndRowNum(undefined);
      setIsAutoMapped(false);
    } else {
      setSelectedSheet(uploadedFile.selectedSheet ?? null);
      setExcludedRowsEndRowNum(uploadedFile.excludedRowsEndRowNum);
    }
  }, [open]);

  /**
   * This memoized value is based on the 'documentType' property of the 'uploadedFile' object.
   * It determines if the uploaded file is a 'GL Transaction' or not.
   */
  const isGLTransaction: boolean = useMemo(() => uploadedFile.documentType === 'GL Transaction', [uploadedFile]);

  /**
   * This function checks if any unsaved changes (dirty state) exist, and if so, prompts the user for confirmation before closing the modal.
   * If there are no unsaved changes, it directly closes the modal.
   */
  const handleModalClose = () => {
    if (isDirty) setShowPrompt(true);
    else handleClose();
  };

  /**
   * This custom hook shows a confirm navigation modal if the form is dirty when the user tries to navigate away from the page/modal.
   */
  usePrompt(`You have unsaved changes. Are you sure you want to leave this page?`, isDirty);

  return (
    <>
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="data-map"
    >
      <Grid container sx={styles.modal}>
        <Grid item xs={12} marginBottom={2} height={'2rem'}>
          <Grid container justifyContent='flex-end' alignItems='center' id='modal-title'>
            <Typography tabIndex={0} variant='h6' sx={styles.title}>
              Data Map
            </Typography>
            <Grid>
              <IconButton onClick={handleModalClose} aria-label='Close Data Map Modal'>
                <CloseIcon fontSize='inherit'/>
              </IconButton>
            </Grid>
          </Grid>
          <hr style={{ color: '#E9ECEF', width: '100%'}}/>
        </Grid>
        <Grid item xs={12} height={'5.5rem'} sx={styles.stepperContainer}>
          <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%'}}>
            {(isGLTransaction ? glSteps : steps).map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        {activeStep === 0 &&
          <Grid item xs={12} sx={styles.mappingContainer}>
            <ExcludeRows
              setActiveStep={setActiveStep}
              setIsDirty={setIsDirty}
              selectedSheet={selectedSheet}
              setSelectedSheet={setSelectedSheet}
              excludedRowsEndRowNum={excludedRowsEndRowNum}
              setExcludedRowsEndRowNum={setExcludedRowsEndRowNum}
              setIsAutoMapped={setIsAutoMapped}
              {...props}
            />
          </Grid>}
        {activeStep === 1 &&
          <Grid item xs={12} sx={styles.mappingContainer}>
            <FieldMapping
              setActiveStep={setActiveStep}
              isGLTransaction={isGLTransaction}
              setIsDirty={setIsDirty}
              selectedSheet={selectedSheet}
              excludedRowsEndRowNum={excludedRowsEndRowNum}
              isAutoMapped={isAutoMapped}
              setIsAutoMapped={setIsAutoMapped}
              {...props}
            />
          </Grid>}
        {activeStep === 2 &&
          <Grid item xs={12} sx={styles.mappingContainer}>
            <CalculationMapping
              setActiveStep={setActiveStep}
              isDirty={isDirty}
              setIsDirty={setIsDirty}
              {...props}
            />
          </Grid>}
      </Grid>
    </Modal>
    <ConfirmModal
      open={showPrompt}
      onClose={() => {
        setShowPrompt(false);
        handleClose();
      }}
      onConfirm={() => setShowPrompt(false)}
      onButtonClose={() => setShowPrompt(false)}
      promptChecker
      title={PROMPT.NAV_PROMPT.title}
      description={`${PROMPT.NAV_PROMPT.description}`}
      yesButtonText={PROMPT.NAV_PROMPT.keepEditing}
      noButtonText={PROMPT.NAV_PROMPT.discardChanges}
      confirmOnly
    />
    </>
  )
}

export default MappingModal;