import { createContext, useMemo, useState } from 'react';
import { DraggableListItem } from '../interfaces/draggableList';
import { LayoutProps } from './authContext';
import { clientColumnList } from '../utility/constants';
import { IClientInfo, IModalProps } from '../interfaces/clientListInterface';

export interface IClientContext { //ICreateReservesContext
  clientInfo: IClientInfo[],
  setClientInfo: (value : IClientInfo[]) => void,
  users: string[],
  setUsers: (value : string[]) => void,
  dirty: boolean,
  setDirty: (value : boolean) => void,
  list: DraggableListItem[],
  setList: (value : DraggableListItem[]) => void,
  canViewAssignedClients: boolean,
  setCanViewAssignedClients: (value : boolean) => void,
  canViewClients: boolean,
  setCanViewClients: (value : boolean) => void,
  canViewClientSettings: boolean,
  setCanViewClientSettings: (value : boolean) => void,
  canAddClient: boolean,
  setCanAddClient: (value : boolean) => void,
  canUpdateClient: boolean,
  setCanUpdateClient: (value : boolean) => void,
  canArchiveDeleteClient: boolean,
  setCanArchiveDeleteClient: (value : boolean) => void,
  modalProps: IModalProps,
  setModalProps: (value: IModalProps) => void,
}

const initialContext: IClientContext = {
  clientInfo: [],
  setClientInfo: () => {},
  users: [],
  setUsers: () => {},
  dirty: false,
  setDirty: () => {},
  list: clientColumnList,
  setList: () => {},
  canViewAssignedClients: false,
  setCanViewAssignedClients: () => {},
  canViewClients: false,
  setCanViewClients: () => {},
  canViewClientSettings: false,
  setCanViewClientSettings: () => {},
  canAddClient: false,
  setCanAddClient: () => {},
  canUpdateClient: false,
  setCanUpdateClient: () => {},
  canArchiveDeleteClient: false,
  setCanArchiveDeleteClient: () => {},
  modalProps: { open: false, title: '', description: '', confirmText: '', handleConfirm: () => {}, },
  setModalProps: () => {},
};

export const ClientContext = createContext<IClientContext>(initialContext);

const ClientProvider = (props: LayoutProps) => { 
  const { children } = props;
  
  const [ clientInfo, setClientInfo ] = useState<IClientInfo[]>([]);
  const [ users, setUsers ] = useState<string[]>([]);
  const [ dirty, setDirty ] = useState<boolean>(false);
  const [ canViewClients, setCanViewClients ] = useState<boolean>(false);
  const [ canViewClientSettings, setCanViewClientSettings ] = useState<boolean>(false);
  const [ canViewAssignedClients, setCanViewAssignedClients ] = useState<boolean>(false);
  const [ canAddClient, setCanAddClient ] = useState<boolean>(false);
  const [ canUpdateClient, setCanUpdateClient ] = useState<boolean>(false);
  const [ canArchiveDeleteClient, setCanArchiveDeleteClient ] = useState<boolean>(false);
  const [list, setList] = useState<DraggableListItem[]>(clientColumnList);
  const [modalProps, setModalProps] = useState<IModalProps>(initialContext.modalProps);

  const value = useMemo(() => ({ 
    clientInfo,
    setClientInfo,
    dirty,
    setDirty,
    list,
    setList,
    canViewClients,
    setCanViewClients,
    canViewAssignedClients,
    setCanViewAssignedClients,
    canAddClient,
    setCanAddClient,
    canUpdateClient,
    setCanUpdateClient,
    canArchiveDeleteClient,
    setCanArchiveDeleteClient,
    canViewClientSettings,
    setCanViewClientSettings,
    users,
    setUsers,
    modalProps,
    setModalProps,
   }), [
    clientInfo,
    setClientInfo,
    dirty,
    setDirty,
    list,
    setList,
    canViewClients,
    setCanViewClients,
    canViewAssignedClients,
    setCanViewAssignedClients,
    canAddClient,
    setCanAddClient,
    canUpdateClient,
    setCanUpdateClient,
    canArchiveDeleteClient,
    setCanArchiveDeleteClient,
    canViewClientSettings,
    setCanViewClientSettings,
    users,
    setUsers,
    modalProps,
    setModalProps,
   ])

  return (
    <ClientContext.Provider
      value={value}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientProvider;