const styles = {
  gridContainer: {
    alignItems: 'center',
    px: '24px',
    pt: 1,
    pb: 2,
  },
  gridTabsContainer: {
    justifyContent: 'space-between',
    bgcolor: '#F7F7F7',
    alignItems: 'center',
  },
  buttonTabs: {
    '.MuiTabs-Box': {
      border: '1px solid #E0E0E0',
    },
    '.MuiTabs-scroller': {
      background: '#F7F7F7',
    },
    '.MuiButtonBase-root.MuiTab-root': {
      borderRadius: '3px 3px 0 0',
      textTransform: 'none',
      width: '220px',
      marginRight: '5px',
      padding: '8px',
      boxShadow: '0 -5px 30px -7px #E0E0E0',
      color: '#000000',
    },
    '.MuiButtonBase-root.MuiTab-root.Mui-selected': {
      background: 'white',
      color: '#000000',
    },
    '.MuiTabs-indicator': {
      display: 'none',
    },
    '.MuiTabs-flexContainer': {
      paddingTop: '10px',
    },
  },
  tabBox: {
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    borderRadius: '3px 3px 0 0',
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  exchangeRateButtonContainer: {
    paddingRight: '1rem',
  }
};

export default styles;
