import { circularProgressClasses } from "@mui/material";
import colors from "../../../../utility/colors";

const styles = {
  action: {
    padding: 0,
    marginRight: '5px'
  },
  actionsContainer: {
    marginRight: '1rem'
  },
  actionsIconButton: {
    '&.MuiButtonBase-root': {
      margin: 0,
      padding: '5px',
    }
  },
  actionsIcon: {
    fontSize: 20,
    color: '#154A8A',
    padding: '0',
    ':hover': {
      cursor: 'pointer',
    },
  },
  content: {
    maxHeight: '8rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#B7B7B7',
    },
  },
  errorIcon: {
    marginRight: '3px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colors.WHITE,
    background: colors.PRIMARY,
    paddingY: '5px',
    paddingX: '15px',
    width: '100%',
  },
  list: {
    width: '100%',
  },
  listItemText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  spinner: {
    color: colors.PRIMARY,
    animationDuration: '550ms',
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
    },
    marginRight: '5px'
  },
  headerFont: {
    fontSize: '16px'
  },
}

export default styles;