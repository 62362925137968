import NavigationWarningProvider from '../../context/navigationWarningContext';
import InventoryIneligiblesProvider, { InventoryIneligibleContext } from '../../context/inventoryIneligibleContext';
import InventoryIneligibleHeader from '../../components/inventory-ineligibles/header';
import InventoryIneligiblesTable from '../../components/inventory-ineligibles/table';
import { Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { IInventoryIneligibleContext } from '../../interfaces/inventoryIneligibleInterface';
import { checkUserPermissions, getLocalStorageItem } from '../../utility/helper';
import { PERMISSIONS } from '../../utility/constants';
import styles from './styles';



const InventoryIneligiblesPage = () => {
  const {canViewInventoryIneligibles, setCanViewInventoryIneligibles} = useContext(InventoryIneligibleContext) as IInventoryIneligibleContext;

  useEffect(() => {
    getPermission();
  }, []);

  const getPermission = async () => {
    const canView = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.VIEW_INVENTORY_INELIGIBLES);
    setCanViewInventoryIneligibles(canView ?? false);
  };

  return (
    <Box sx={{...(!canViewInventoryIneligibles && styles.hidden)}}>
      <InventoryIneligibleHeader/>
      <InventoryIneligiblesTable/>
    </Box>
  )
}

const InventoryIneligibles = () => {
  return (
    <NavigationWarningProvider>
      <InventoryIneligiblesProvider>
        <InventoryIneligiblesPage />
      </InventoryIneligiblesProvider>
    </NavigationWarningProvider>
  )
}
 

export default InventoryIneligibles;