import { createContext, useMemo, useState } from "react";
import { IExportReportContext, IExportReportLayoutProps, IExportReportWithPayload } from "../interfaces/exportReportInterface";

const initialContextState: IExportReportContext = {
  exports   : [],
  setExports: () => undefined,
  show      : false,
  setShow   : () => undefined,
}

export const ExportReportContext = createContext<IExportReportContext>(initialContextState);

const ExportReportContextProvider = (props: IExportReportLayoutProps) => {
  const { children }                = props;
  const [exports, setExports]       = useState<IExportReportWithPayload[]>([]);
  const [show, setShow]             = useState<boolean>(false);

  const value = useMemo(() => ({
    exports,
    setExports,
    show,
    setShow,
  }), [
    exports,
    setExports,
    show,
    setShow,
  ]);

  return (
    <ExportReportContext.Provider
      value={value}
    >
      {children}
    </ExportReportContext.Provider>
  )
}

export default ExportReportContextProvider;