import {
  Alert,
  AlertColor,
  AlertTitle,
  Snackbar,
} from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import styles from './styles';
import { FC, useState, SyntheticEvent, useEffect } from 'react';

interface IToasterProps {
  open: boolean;
  message: string,
  severity?: AlertColor;
  onCloseChange: () => void;
}

 export interface ToasterState {
  open: boolean;
  message: string;
  severity?: AlertColor;
 }

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionRight(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}
const Toaster: FC<IToasterProps> = (props) => {
  const [open, setOpen] = useState(props.open);

  const getToasterTitle = () => {
    if (props.severity === undefined) { return 'Success'; }
    return props.severity.charAt(0).toUpperCase() + props.severity.slice(1)
  }

  const handleClose = (_event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') { return; }
    props.onCloseChange();
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={4000}
      onClose={handleClose}
      open={open}
      sx={styles.snackBar}
      TransitionComponent={TransitionRight}>
      <Alert
        onClose={handleClose}
        severity={props.severity}
        sx={{
          ...styles.alert,
          ...styles.success,
          ...(props.severity === 'info' && styles.info),
          ...(props.severity === 'warning' && styles.warning),
          ...(props.severity === 'error' && styles.error),
        }}
        variant="standard">
        <AlertTitle>{getToasterTitle()}</AlertTitle>
        {props.message}
      </Alert>
    </Snackbar >
  );
}

export default Toaster;
