const styles = {
  backgroundBox: {
    backgroundColor: '#F2F7F8',
    minHeight: 'calc(100vh - 3rem)',
  },
  hidden: {
    display: 'none'
  }
};

export default styles;
