import { Card, Typography } from "@mui/material";
import { FC } from "react";
import { IReportsTitle } from "../../interfaces/reportsTitle";
import { formatDate } from "../../utility/helper";
import styles from "./styles";

const ReportsTitle: FC<IReportsTitle> = (props) => {
  return (
    <Card sx={styles.reportTitle}>
      <Typography tabIndex={0} variant='h6' sx={styles.reportTitleText} data-testuid={`${props.reportName}-client-name`}>{props.clientName}</Typography>
      <Typography tabIndex={0} variant='h6' sx={styles.reportTitleText} data-testid={`${props.reportName}-title-id`}>{props.reportName}</Typography>
      {props.bbPeriod ? (
        <Typography tabIndex={0} variant='h6' sx={styles.reportTitleSubText} data-testid={`${props.reportName}-date-id`}>
          As of {formatDate(props.bbPeriod, 'MMMM DD, YYYY')}
        </Typography>
      ) : null}
    </Card>
  );
};

export default ReportsTitle;