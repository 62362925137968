import { Box } from '@mui/material';
import PageHeader from '../../components/users-and-roles/header';
import UsersAndRolesProvider from '../../context/usersAndRolesContext';
import UsersAndRolesTable from '../../components/users-and-roles/users-and-roles-table';
import styles from './styles'
import { FC } from 'react';

/**
 * Component for showing the Users and Roles Page.
 */
const UsersAndRoles: FC = () => {

  return (
    <UsersAndRolesProvider>
      <Box>
        <PageHeader title={'Users and Roles'}/>
        <Box sx={styles.boxContainer2}>
          <UsersAndRolesTable/>
        </Box>
      </Box>
    </UsersAndRolesProvider>
  )
}

export default UsersAndRoles;