import colors from "../../../utility/colors";

const styles = {
  title: {
    fontWeight: 'bold',
    color: '#010953',
    fontSize: '18px',
    ml: '24px',
  },
  headerCard: {
    backgroundColor: '#ffffff',
    my: '16px',
    mx: '24px',
    pb: '23.5px',
    pl: '21.79px',
    pr: '21.76px',
    pt: '21.5px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleCard: {
    backgroundColor: '#FEFEFE',
    py: 2.5
  },
  numericTextField: {
    textAlign: 'right',
    WebkitTextFillColor: '#000!important',
    fontSize: '14px'
  },
  clientComboBox: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  labelStyle: {
    display: 'block',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginBottom: 1,
  },
  breadCrumbBox: {
    paddingRight: '24px',
    borderRadius: '0.3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clientDropdown: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  clientDropdownText: {
    fontSize: '14px'
  },
  clientBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  clientLabelStyle: {
    display: 'block',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '16px'
  },
  totalAmountContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  labelTotalAmount: {
    fontSize: '14px',
    fontWeight: '600',
    marginRight: '5px',
  },
  valueTotalAmount: {
    fontSize: '14px',
  },
  labelChipContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  parentChipContainer: {
    width: '100px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  parentIdentifierChip: {
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"P"',
    },
    '& .MuiChip-label:hover:after': {
      content: '"Parent"',
    }
  },
  hidden: {
    display: 'none',
  }
};

export default styles;