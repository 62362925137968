import { Dispatch, FC, HTMLAttributes, SetStateAction, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AlertColor, Autocomplete, AutocompleteCloseReason, AutocompleteRenderInputParams, Box, ClickAwayListener, Container, TextField, Typography } from '@mui/material';
import styles from './styles'
import DocumentTypeDropdown from './document-type-dropdown';
import axiosInstance from '../../../../../service/axiosInstance';
import { AMERICAN_DATE_FORMAT, GET } from '../../../../../utility/constants';
import { IARCollateral, IBBPeriod } from '../../../../../interfaces';
import { formatDate, getARCollateralRequest } from '../../../../../utility/helper';
import DatePickerInput from './datepicker-input';
import dayjs from 'dayjs';
import { IBBPeriodOption, IOption } from '../../../../../interfaces/comboBox';
import { FileImportContext, IFileImportContext } from '../../../../../context/fileImportContext';
import { bbPeriodAPI } from '../../../../../service/api';
import { IDocumentType } from '../../../../../interfaces/fileimport';
import UploadingSection from './uploading-section';

export interface IUploadTabProps {
  selectedDocumentType    : IDocumentType | null;
  setSelectedDocumentType : Dispatch<SetStateAction<IDocumentType | null>>;
  selectedARCollateral    : IOption | null;
  setSelectedARCollateral : Dispatch<SetStateAction<IOption | null>>;
  selectedBBPeriod        : IBBPeriodOption | null;
  setSelectedBBPeriod     : Dispatch<SetStateAction<IBBPeriodOption | null>>;
  selectedDates           : Date[];
  setSelectedDates        : Dispatch<SetStateAction<Date[]>>;
  showToaster             : (severity: AlertColor, message: string) => void;
}

/**
 * Component for the Upload Step in the Upload Files Page
 * @param props The props for the Upload Tab Component
 * @returns A component for the Upload Step
 */
const UploadTab: FC<IUploadTabProps> = (props) => {
  const { selectedDocumentType, setSelectedDocumentType,
          selectedARCollateral, setSelectedARCollateral,
          selectedBBPeriod, setSelectedBBPeriod,
          selectedDates, setSelectedDates }             = props;
  const { selectedClient, hasLoadingRecent }            = useContext(FileImportContext) as IFileImportContext;
  const [arCollaterals, setARCollaterals]               = useState<IOption[]>([]);
  const [arCollateralInput, setARCollateralInput]       = useState<string>('');
  const [bbPeriods, setBBPeriods]                       = useState<IBBPeriodOption[]>([])
  const [documentTypeInput, setDocumentTypeInput]       = useState<string>('');
  const [open, setOpen]                                 = useState<boolean>(false);
  const [isDatePickerOpen, setIsDatePickerOpen]         = useState<boolean>(false);
  const [choseOldDate, setChoseOldDate]                 = useState<boolean>(false);
  const isUltimateParent                                = useMemo(() => Boolean(selectedClient?.parentClient), [selectedClient]);

  /**
   * This useEffect hook handles the selection of As of Date and triggered on change of selectedDates.
   * If there are selectedDates, it checks for the first date if existing.
   *    If the date exists, it sets the selectedBBPeriod as the found date from the existing dates.
   *    Else it checks if the new date is an empty string or not. If it is not an empty string, creates a new IBBPeriodOption object out of it.
   * Else sets the selected As of Date to null.
   */
  useEffect(() => {
    if (selectedDates.length > 0) {
      const dateExists = bbPeriods.filter(bbPeriod => bbPeriod.label === dayjs(selectedDates[0]).format('MM/DD/YYYY'));
      if (dateExists.length > 0) {
        setChoseOldDate(true)
        setSelectedBBPeriod(dateExists[0]);
      } else {
        const newBbPeriodDate = dayjs(selectedDates[0]).format('MM/DD/YYYY');
        setChoseOldDate(false)
        setSelectedBBPeriod({
          recordId: -1,
          label: newBbPeriodDate,
          group: '',
          default: false
        });
      }
    } else if (!choseOldDate) {
      setSelectedBBPeriod(null);
    }
  }, [selectedDates]);

  /**
   * This useEffect hook handles the edge case of selectedBBPeriod that is not in the MM/DD/YYYY format.
   * Triggered on change of the selectedBBPeriod.
   */
  useEffect(() => {
    if (selectedBBPeriod?.recordId !== -1 && selectedBBPeriod !== null) {
      if (selectedBBPeriod.label !== dayjs(selectedDates[0]).format('MM/DD/YYYY')) {
        setSelectedDates([]);
      }
      setChoseOldDate(true)
    }
  }, [selectedBBPeriod]);

  /**
   * This useEffect fetches the AR Collateral and As of Date options when the selectedClient is not null.
   * Triggered on change of the selectedClient.
   */
  useEffect(() => {
    (async () => {
      if (selectedClient?.recordId) {
        await getARCollaterals(selectedClient);
        await getBBPeriods(selectedClient);
      }
    })();
  }, [selectedClient]);

  /**
   * This function asynchronously fetches the AR Collaterals from the server based on the selected Client record id.
   * @param selectedClient The selected client on the client dropdown.
   */
  const getARCollaterals = async (selectedClient: IOption) => {
    try {
      const response = await getARCollateralRequest(selectedClient.recordId);
      const arCollaterals: IARCollateral[] = response.data.content;

      if (arCollaterals.length) {
        setARCollaterals(arCollaterals.map((arCollateral: IARCollateral) => {
          return {
            recordId: arCollateral.recordId,
            label: arCollateral.arCollateralName,
            default: false
          }
        }))
      }
    } catch (error) {
      console.log('AR COLLATERAL API ERROR: ', error);
      setARCollaterals([]);
    }
  };

  /**
   * This function asynchronously fetches the As of Dates from the server based on the selected Client record id.
   * Also formats the display of As of Dates to MM/DD/YYYY.
   * @param selectedClient The selected client on the client dropdown.
   */
  const getBBPeriods = async (selectedClient: IOption | null) => {
    try {
      const response = await axiosInstance.request({
        url: bbPeriodAPI.FIND_BY_BORROWER_ID,
        method: GET,
        params: { borrowerId: selectedClient?.recordId ?? 0, sortBy: 'endDate,DESC' }
      })
      const bbPeriods = response.data.content;
      const groupedBbPeriods: IBBPeriodOption[] = bbPeriods.map((bbPeriod: IBBPeriod) => {
        const bbPeriodDate = formatDate(bbPeriod.endDate, 'MM/DD/YYYY')
        const formattedBbPeriod = {
          recordId: bbPeriod.recordId,
          label: bbPeriodDate,
          group: 'As of Date',
          default: false,
        }
        return formattedBbPeriod;
      });

      groupedBbPeriods.unshift({
        recordId: -1,
        label: '',
        group: 'Create New As of Date',
        default: false,
      });

      setBBPeriods(groupedBbPeriods);
    } catch (error) {
      console.log('BB PERIODS API ERROR: ', error);
      setBBPeriods([]);
    }
  };

  /**
   * This function checks if the action is not a valid reason to close the target element.
   * @param target The target html element of the function.
   * @returns A boolean value if the action is not a valid reason to close the target element.
   */
  const isNotValidCloseReason = (target: HTMLElement) => {
    return target.classList.contains("MuiSvgIcon-root") || target.classList.contains("MuiPickersCalendarHeader-label") ||
      target.classList.contains("PrivatePickersYear-yearButton") || target.classList.contains("MuiIconButton-edgeEnd") ||
      target.classList.contains("MuiIconButton-edgeStart") || target.classList.contains("MuiPickersCalendarHeader-switchViewButton") ||
      target.classList.contains("MuiDayPicker-weekContainer") || target.classList.contains("MuiDayPicker-slideTransition") ||
      target.classList.contains("MuiCalendarPicker-root") || target.classList.contains("MuiDayPicker-monthContainer") ||
      target.classList.contains("MuiDayPicker-weekDayLabel") || target.classList.contains("MuiDayPicker-header") ||
      target.classList.contains("MuiPickersCalendarHeader-root") || target.classList.contains("MuiPickersArrowSwitcher-root") ||
      target.nodeName === 'path' || target.nodeName === 'svg';
  };

  /**
   * This function handles the closing of the Autocomplete component.
   * @param event The action/event of the target element.
   * @param reason The reason of the Autocomplete component to close.
   */
  const handleAsOfDateClose = (event: SyntheticEvent<Element, Event>, reason: AutocompleteCloseReason) => {
    const target = event.target as HTMLElement

    const invalids = (reason !== 'toggleInput' && reason !== 'selectOption') ||
                    (reason === 'selectOption' && isNotValidCloseReason(target))
    
    setOpen(invalids);
  };

  /**
   * This function handles the event of the user clicking away from the component.
   */
  const handleClickAway = () => {
    if (!isDatePickerOpen) setOpen(false);
  };

  /**
   * This useCallback hook returns a function that sets the selected of the document type dropdown to the new value selected.
   * This also resets the following dropdowns: AR Collateral and As of Date. 
   */
  const onDocTypeChange = useCallback((_event: SyntheticEvent<Element, Event>, newValue: IDocumentType | null) => {
    setSelectedDocumentType(newValue);
    setSelectedARCollateral(null);
    setSelectedBBPeriod(null);
    setSelectedDates([]);
  }, []);

  /**
   * This useCallback hook returns a function that sets the input of the document type dropdown to the new input typed.
   */
  const onDocTypeInputChange = useCallback((_event: SyntheticEvent<Element, Event>, newInputValue: string) => {
    setDocumentTypeInput(newInputValue);
  }, []);

  /**
   * This function sets the selected As of Date of the As of Date picker.
   * @param _event The event of the target component. Unused in this function, but required for the Autocomplete component's onChange.
   * @param newValue The newValue selected.
   */
  const handleAsOfDateChange = (_event: any, newValue: IBBPeriodOption | null) => {
    if (newValue === null) {
      setSelectedDates([]);
      setSelectedBBPeriod(null)
    }

    if (newValue?.group === 'Create New As of Date') {
      setSelectedBBPeriod(null)
    } else {
      setSelectedBBPeriod(newValue)
    }
  };

  /**
   * This function returns the component to be used in rendering the options for As of Date dropdown.
   * @returns The component to be used in rendering the options for As of Date dropdown.
   */
  const getRenderOption = () => {
    return {
      renderOption: (props: HTMLAttributes<HTMLLIElement>, option: IBBPeriodOption) => {
        if (option.group === 'As of Date') {
          return (
            <Box component='li' {...props}>
              {formatDate(option.label, AMERICAN_DATE_FORMAT)}
            </Box>
          );
        } else {
          return (
            <Box component='li' {...props}>
              <DatePickerInput
                width='13rem'
                popperOffset={[-10, 20]}
                defaultValue={selectedDates}
                onSelectDate={setSelectedDates}
                limitTags={1}
                isNotCalculatedDate={() => false}
                isNotCalculatedYear={() => false}
                asOfDateCreator={true}
                isOpen={isDatePickerOpen}
                setIsOpen={setIsDatePickerOpen}
              />
            </Box>
          );
        }
      }
    }
  };
  
  return (
    <>
      <Container maxWidth='xl' >
        <Box sx={styles.comboBoxStyle}>
          <Box sx={styles.filterChildBoxMargin}>
            <DocumentTypeDropdown
              value={selectedDocumentType}
              inputValue={documentTypeInput}
              onChange={onDocTypeChange}
              onInputChange={onDocTypeInputChange}
              isDisabled={selectedClient === null || hasLoadingRecent}
            />
          </Box>
          {!isUltimateParent &&
          <Box sx={selectedDocumentType ? styles.filterChildBoxMargin : { visibility: 'hidden' }}>
            <Typography tabIndex={0} component='label' htmlFor={`combo-box-collateral`} sx={styles.dropdownTitle}>
              AR Collateral
            </Typography>
            <Autocomplete
              disablePortal
              id={`combo-box-collateral`}
              getOptionLabel={(option: IOption) => option.label}
              isOptionEqualToValue={(option: IOption, value: IOption) => option.recordId === value.recordId}
              options={arCollaterals}
              size='small'
              fullWidth
              disabled={hasLoadingRecent}
              sx={styles.comboBox}
              renderInput={(params: AutocompleteRenderInputParams) => (<TextField {...params} placeholder={'Please Select'} />)}
              value={selectedARCollateral}
              inputValue={arCollateralInput}
              onChange={(_event: any, newValue: IOption | null) => setSelectedARCollateral(newValue)}
              onInputChange={(_event: any, newInputValue: string) => setARCollateralInput(newInputValue)}
              componentsProps={{
                popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}
              }}
            />
          </Box>}
          <Box sx={!selectedDocumentType || ['Customer List', 'Vendor List'].includes(selectedDocumentType?.name) ? { visibility: 'hidden' } : styles.filterChildBoxMargin}>
            <Typography tabIndex={0} component='label' htmlFor={`combo-box-bbPeriod`} sx={styles.dropdownTitle}>
              As of Date
            </Typography>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Autocomplete
                disablePortal
                id={`combo-box-bbPeriod`}
                options={bbPeriods}
                groupBy={(option: IBBPeriodOption) => option.group}
                size='small'
                fullWidth
                open={open}
                disabled={hasLoadingRecent}
                sx={styles.comboBox}
                renderInput={(params: AutocompleteRenderInputParams) =>
                  (<TextField {...params}
                      placeholder={'Please Select'}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />)}
                value={selectedBBPeriod}
                onChange={handleAsOfDateChange}
                onOpen={() => setOpen(true)}
                onClose={handleAsOfDateClose}
                componentsProps={{
                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0},
                  paper: { sx: styles.groupedList }
                }}
                {...getRenderOption()}
              />
            </ClickAwayListener>
          </Box>
        </Box>
      </Container>
      <UploadingSection
        refreshAsOfDates={async () => await getBBPeriods(selectedClient)}
        {...props}
      />
    </>
  )
}

export default UploadTab;