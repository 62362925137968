import { useContext, useState, useEffect, SyntheticEvent, useMemo } from 'react';
import { Box, Button, Step, Stepper, StepLabel, Typography, Grid, AlertColor } from '@mui/material';
import UploadTab from './stepper-content/upload-tab';
import DataMap from './stepper-content/datamap-tab';
import styles from './styles';
import GeneralBreadcrumbs from '../../../components/breadcrumb';
import Toaster, { ToasterState } from '../../../components/toaster';
import ImportTab from './stepper-content/import-tab';
import { PERMISSIONS, PROMPT } from '../../../utility/constants';
import { checkUserPermissions, getClientRequest, getLocalStorageItem, getOptions } from '../../../utility/helper';
import { IBBPeriodOption, IComboBoxIds, IComboBoxSetStates, IOption } from '../../../interfaces/comboBox';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import ComboBox from '../../../components/common/combo-box';
import ConfirmModal from '../../../components/modals/confirm-modal';
import { useSearchParams } from 'react-router-dom';
import WarningModal from '../../../components/file-import/modals/warning-modal';
import CircleIcon from '@mui/icons-material/Circle';
import { FileImportContext, IFileImportContext } from '../../../context/fileImportContext';
import { DocumentIssueTypes, IDocumentType, IUploadedFile } from '../../../interfaces/fileimport';
import DisabledComponentsContainer from '../../../components/common/disabled-components-container';

const steps = ['Upload', 'Data Map', 'Import'];

export const initToasterState: ToasterState = {
  open    : false,
  message : '',
  severity: 'success',
};

/**
 * Component for the File Upload Stepper.
 * @returns The component File Upload Stepper.
 */
const FileUploadStepper = () => {
  const [searchParams, setSearchParams]                = useSearchParams();
  const stepQuery                                      = searchParams.get('step') ?? '0';
  const clientId                                       = searchParams.get('clientId') ?? '0';

  const { 
    selectedClient, setSelectedClient,
    clientInput, setClientInput,
    recentUploadedFiles,
    uploadedFiles,
    hasLoadingRecent, hasLoadingUploaded
  }                                                    = useContext(FileImportContext) as IFileImportContext;
  const selectedClientContext                          = useContext(SelectedClientContext);
  const [activeStep, setActiveStep]                     = useState<number>(parseInt(stepQuery));
  const [skipped, setSkipped]                           = useState(new Set<number>());
  const [toasterProps, setToasterProps]                 = useState<ToasterState>(initToasterState);
  const [clients, setClients]                           = useState<IOption[]>([]);
  const [openModal, setOpenModal]                       = useState<boolean>(false);
  const [duplicateModalOpen, setDuplicateModalOpen]     = useState<boolean>(false);
  const [warningModalOpen, setWarningModalOpen]         = useState<boolean>(false);
  const [canImportFile, setCanImportFile]               = useState<boolean>(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState<IDocumentType | null>(null);
	const [selectedARCollateral, setSelectedARCollateral] = useState<IOption | null>(null);
	const [selectedBBPeriod, setSelectedBBPeriod]         = useState<IBBPeriodOption | null>(null);
  const [selectedDates, setSelectedDates]               = useState<Date[]>([])

  /**
   * This useEffect hook fetches permission, get parameters using localStorage or useSearchParams,
   * then get options for the client dropdown.
   */
  useEffect(() => {
    getPermission();
		const params = { borrowerId: localStorage.getItem('selectedClientRecordId') ?? clientId, invCollateralId: '0', bbPeriodId: '0', arCollateralId: '0', customerId: '0' };
    getOptions({ type: 'client', params, getSetStatesByType, setSelectedByType, mainRequest: getClientRequest()});
	}, []);

  /**
   * This function asynchronously check the user permission if the user can import a file.
   */
  const getPermission = async () => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.IMPORT_FILE)
    if(isPermitted !== undefined){
      setCanImportFile(isPermitted)
    }
  };

  /**
   * This function gets the loading state based on the active step of the stepper content.
   * @param activeStep The active step of the stepper content.
   * @returns The boolean value of loading state.
   */
  const getLoadingState = (activeStep: number) => {
    const loadingStates = { 0: hasLoadingRecent, 1: hasLoadingUploaded }
    return loadingStates[activeStep] ?? false;
   };

  /**
   * This useMemo hook returns a boolean value if some of the files are mapped
   * and recalculates when uploadedFiles state changes.
   */
  const hasCompletedDocs = useMemo(() => 
    uploadedFiles.some(file => file.mapped)
  , [uploadedFiles]);

  /**
   * This useMemo hook returns a boolean value if some of the files are mapped
   * and recalculates when uploadedFiles state changes.
   */
  const hasUnmappedDocuments = useMemo(() =>
    uploadedFiles.some(file => !file.mapped)
  , [uploadedFiles]);

  /**
   * This useMemo hook returns a boolean value based if there is no selected client record id,
   * and recalculates when selectedClient state changes.
   */
  const noSelectedClient = useMemo(() => !(selectedClient?.recordId), [selectedClient]);
  
  /**
   * This useMemo hook return a boolean value if Next button must be disabled,
   * and recalculates when there are changes in the following:
   * selectedClient, activeStep, uploadedFiles, hasLoadingRecent, hasLoadingUploaded.
   */
  const disabledNext = useMemo(() => {
    const emptyRecent = activeStep === 0 && recentUploadedFiles.length === 0;
    const emptyUploaded = activeStep === 1 && uploadedFiles.length === 0;
    const hasNoCompletedMapping = activeStep === 1 && !hasCompletedDocs;
    return emptyRecent || emptyUploaded || hasNoCompletedMapping || noSelectedClient || getLoadingState(activeStep);
  }, [selectedClient, activeStep, uploadedFiles, hasLoadingRecent, hasLoadingUploaded])

  /**
   * This function sets the Confirm Navigation's openModal state to false.
   */
  const keepEditing = () => {
    setOpenModal(false);
  };

  /**
   * This function checks if there are some files that has duplicates and has warnings.
   * If there are duplicates, the Duplicate Modal Warning will open.
   * If there are warnings, the Warning Modal will open.
   * If there are no duplicates and no warnings, the step query will update to 'next'
   * @param uploadedFiles The uploaded files to be checked.
   */
  const checkExisting = (uploadedFiles: IUploadedFile[]) => {
    setOpenModal(false);
    const hasDuplicates = uploadedFiles.some(file => file.mapped && file.shouldReplace && file.hasPrevious);
    const hasWarnings = uploadedFiles.some(file => file.mapped && file.documentIssues?.length);

    if (hasDuplicates) {
      setDuplicateModalOpen(true);
    } else if (hasWarnings) {
      setWarningModalOpen(true);
    } else updateStepQuery('next');
  };

  /**
   * This function checks if the step is skipped.
   * @param step The step number.
   * @returns A boolean value if the step is skipped.
   */
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  
  /**
   * This function handles the onClick function of the skip button.
   */
  const handleSkipBtn = () => {
    updateStepQuery('next');
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  /**
   * This function handles the checking of warnings of the current uploaded files.
   * If some files have warnings, the Warning Modal will open.
   * Else update the step query to 'next'.
   * @param uploadedFiles The current uploaded files.
   */
  const handleConfirmReplace = (uploadedFiles: IUploadedFile[]) => {
    const hasWarnings = uploadedFiles.some(file => file.mapped && file.documentIssues?.length);
    if (hasWarnings) {
      setWarningModalOpen(true);
    } else updateStepQuery('next');
  };

  /**
   * This function gets the general issue type (warning) and issue message.
   * @param uploadedFiles The current uploaded files.
   * @returns An object containing the issueType and issueMessages.
   */
  const getGeneralIssueTypeAndMessage = (uploadedFiles: IUploadedFile[]) => {
    const issueType: DocumentIssueTypes = 'warning';
    const issueMessages: string[] = uploadedFiles
      .filter(file => file.mapped && file.documentIssues?.length)
      .map(file => file.filename);
    return { issueType, issueMessages }
  };

  /**
   * This function return a callback function that handles the action onClick of the Next button.
   * If the activeStep = 1 (Data Map Step), checks if it has unmapped documents.
   *    If it has unmapped documents, opens the Confirm Modal.
   *    Else proceed to checking existing uploadedFiles.
   * Else update the step query to 'next'.
   * @param uploadedFiles The current uploaded files.
   * @returns A callback function in handling onClick of the Next button.
   */
  const handleNext = (uploadedFiles: IUploadedFile[]) => () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 1) {
      if (hasUnmappedDocuments) {
        setOpenModal(true);
      } else checkExisting(uploadedFiles);
    } else updateStepQuery('next');

    setSkipped(newSkipped);
  };

  /**
   * This function updates the step query to 'back'.
   */
  const handleBack = () => {
    updateStepQuery('back');
  };

  /**
   * This function sets the active step based on the direction, and updates the step parameter.
   * If next, advance 1 to the step.
   * Else, reverse 1 to the step.
   * @param dir The direction of the step. Either 'next' and 'back'.
   */
  const updateStepQuery = (dir: 'next' | 'back') => {
    if (dir === 'next') {
      setActiveStep((step: number) => step + 1);
      updateSearchParams('step', `${activeStep + 1}`);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      updateSearchParams('step', `${activeStep - 1}`);
    }
  };

  /**
   * This function setup the neccessary dispatches needed for handling client field.
   * @param _type Always has the value of string 'client'.
   * @returns A set of dispatch actions that sets the state variables related to client.
   */
  const getSetStatesByType = (_type: IComboBoxIds) => {
    return { setOptions: setClients, setSelected: setSelectedClient, setInput: setClientInput };
  };

  /**
   * This function handles the changes on state variables used by specific fields.
   * @param selected The Option that is selected.
   * @param type Determine which field is being updated.
   * @param setStates The state variable to be updated.
   */
  const setSelectedByType = (selected: IOption, _type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    selectedClientContext?.setSelectedClient(selected);
    setStates.setSelected(selected);
    setStates.setInput(selected.label);
  };

  /**
   * This function returns the onChange function for the change event for combo boxes,
   * and the onInputChange function for the change event on when the input in a combo box changes.
   */
  const getHandleChange = () => {
    return {
      onChange: (_e: SyntheticEvent<Element, Event>, newValue: IOption | null) => {
        if (newValue) {
          const mapped = {
            recordId: newValue?.recordId,
            borrowerName: newValue?.label,
            parentClient: newValue?.parentClient,
            parentClientFk: newValue?.parentClientFk
          }
          selectedClientContext?.setSelectedClient(mapped);
        } else {
          selectedClientContext?.setSelectedClient(null);
        }
        
        setSelectedClient(newValue);
        updateSearchParams('clientId', `${newValue?.recordId ?? 0}`);
        resetStates();
      },
      onInputChange: (_e: SyntheticEvent<Element, Event>, newInputValue: string) => setClientInput(newInputValue)
    }
  };

  /**
   * This function reset the states of the selected document type, ar collateral, bb period, and dates.
   */
  const resetStates = () => {
    setSelectedDocumentType(null);
    setSelectedARCollateral(null);
    setSelectedBBPeriod(null);
    setSelectedDates([]);
  }

  /**
   * This function shows the toaster with the appropriate severity and message.
   * @param severity The severity of the toaster.
   * @param message The message of the toaster.
   */
  const showToaster = (severity: AlertColor, message: string) => {
    const toaster = { open: true, severity, message };
    setToasterProps(toaster);
  };

  /**
   * This function this gets the stepper content based on the active step
   * 0 - Upload Step
   * 1 - Data Map Step
   * 2 - Import Step
   * @param activeStep The current step of the stepper content.
   * @returns The step content component based on the active step.
   */
  const stepContent = (activeStep : number) =>{
    switch(activeStep){
      case 0:
        return <UploadTab
                selectedDocumentType={selectedDocumentType}
                setSelectedDocumentType={setSelectedDocumentType}
                selectedARCollateral={selectedARCollateral}
                setSelectedARCollateral={setSelectedARCollateral}
                selectedBBPeriod={selectedBBPeriod}
                setSelectedBBPeriod={setSelectedBBPeriod}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                showToaster={showToaster}
              />;
      case 1:
        return <DataMap showToaster={showToaster} />;
      case 2:
        return <ImportTab
                showToaster={showToaster}
              />;
    }
  }

  /**
   * This function updates the search parameters by setting the key-value pair of the parameter.
   * @param key The key of for the search parameters.
   * @param value The value of the given key for the search parameters.
   */
  const updateSearchParams = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  return (
  <>
  {canImportFile &&
  <Box>
    <Box sx={styles.pageStyle}>
      <Grid container flexDirection='row' justifyContent='space-between' alignItems='center' columnSpacing={1}>
        <Grid item xs={12} md={6} lg={8} xl={8.3}>
          <GeneralBreadcrumbs
            selectedText='Upload Files'
            breadcrumbLinks={[{ linkText: 'File Import', route: '/file-import' }]}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3.7}  sx={styles.clientContainer}>
          <Box sx={styles.clientDropdownBox}>
          <ComboBox
            id='client'
            options={clients}
            value={selectedClient}
            inputValue={clientInput}
            disabled={activeStep === 1 || activeStep === 2}
            {...getHandleChange()}
          />
          </Box>
        </Grid>
      </Grid>
      <Box sx={styles.titleContainer}>
        <Typography tabIndex={0} variant='h6' component='h3' sx={styles.title}>
          Upload Files
        </Typography>
      </Box>
      <Box component='div' sx={styles.pageMargin}>
        <Box sx={styles.stepperMargin}>
          <Stepper activeStep={activeStep} sx={styles.stepperCircle}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel tabIndex={0} {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
          {stepContent(activeStep)}
          {activeStep !== 2 &&
          <Box sx={styles.stepperButtonContainer}>
            <DisabledComponentsContainer isDisabled={noSelectedClient || getLoadingState(activeStep)}>
              <Button variant='outlined' disabled={noSelectedClient || getLoadingState(activeStep)} aria-label={noSelectedClient || getLoadingState(activeStep) ? 'Back button disabled' : 'Back'} onClick={handleBack} sx={{...styles.secondaryButton, ...(activeStep === 0 && styles.hidden)}}>
                Back
              </Button>
            </DisabledComponentsContainer>
          <Box/>
            {!hasLoadingRecent && recentUploadedFiles.length !== 0 ? null :
            <DisabledComponentsContainer isDisabled={noSelectedClient || getLoadingState(activeStep)}>
              <Button variant='outlined' disabled={noSelectedClient || getLoadingState(activeStep)} aria-label={noSelectedClient || getLoadingState(activeStep) ? 'Skip button disabled' : 'Skip'} onClick={handleSkipBtn} sx={{...styles.secondaryButton, ...(activeStep !== 0 && styles.hidden)}}>
                Skip
              </Button>
            </DisabledComponentsContainer>}
            <DisabledComponentsContainer isDisabled={disabledNext}>
              <Button data-testid='stepper-next-btn' disabled={disabledNext} aria-label={disabledNext ? 'Next button disabled' : 'Next'} variant='contained' onClick={handleNext(uploadedFiles)} sx={styles.primaryButton}>
                Next
              </Button>
            </DisabledComponentsContainer>
          </Box>}
      </Box>
    </Box>
    <ConfirmModal
      open={openModal}
      onClose={keepEditing}
      onConfirm={() => checkExisting(uploadedFiles)}
      confirmOnly={true}
      onButtonClose={keepEditing}
      promptChecker={true}
      title={PROMPT.CONFIRM_PROMPT.title}
      description={PROMPT.CONFIRM_PROMPT.description}
      yesButtonText={PROMPT.CONFIRM_PROMPT.proceed}
      noButtonText={PROMPT.CONFIRM_PROMPT.cancel}
    />
    <WarningModal
      open={warningModalOpen}
      onClose={() => setWarningModalOpen(false)}
      onConfirm={() => updateStepQuery('next')}
      general
      {...getGeneralIssueTypeAndMessage(uploadedFiles)}
    />
    <ConfirmModal
      title='File Import'
      description={`The following files you're about to import will replace the current data under the same collateral and as of date. Are you sure you want to proceed?`}
      subtext='Note: You can find the previous data under the Archived tab.'
      children={
        uploadedFiles
        .filter(document => document.mapped && document.shouldReplace && document.hasPrevious)
        .map(document => (
          <Box key={document.recordId} sx={styles.confirmMessageContainer}>
            <CircleIcon sx={styles.bulletIcon} />
            <Typography sx={styles.confirmMessage}>{document.filename}</Typography>
          </Box>
        ))}
      open={duplicateModalOpen}
      alignment='left'
      noButtonText='Cancel'
      yesButtonText='Proceed to Import'
      onClose={() => setDuplicateModalOpen(false)}
      onConfirm={() => handleConfirmReplace(uploadedFiles)}
    />
    <Toaster
      onCloseChange={() => setToasterProps({...toasterProps, open: false})}
      {...toasterProps}
    />
  </Box>}
  </>
  );
}

export default FileUploadStepper;