import { Box, Paper, Typography, Button } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import LoanWatchLogo from '../../../assets/images/logo.png';
import { FC } from "react";

interface IProps {
  type: 'create' | 'update'
}
/**
 * Component for showing the Update Success Page.
 * @param props The props for the UpdateSuccess component. See the IProps interface for more information.
 */
const UpdateSuccess: FC<IProps> = (props) => {
  const {type}   = props;
  const navigate = useNavigate();

  return (
    <Box sx={styles.mainContainer}>
      <img src={LoanWatchLogo} alt='LoanWatch Logo' aria-label='LoanWatch logo image' width={500} tabIndex={0} />
      <Paper elevation={0} sx={styles.paperStyle}>
        <CheckCircleIcon sx={styles.checkIcon} />
        <Typography sx={styles.modalTitle}>Success!</Typography>
        <Typography sx={styles.descriptionText}>Your password has been successfully {type === 'create' ? 'created' : 'updated'}.</Typography>
        <Box sx={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={styles.loginButton}
            onClick={() => navigate('/sign-in')}
          >
            Login
          </Button>
        </Box>
      </Paper>
    </Box>
  )
};

export default UpdateSuccess;