import * as Yup from 'yup';
import { getLocalStorageItem } from '../utility/helper';
import axiosInstance from '../service/axiosInstance';
import { rolePermissionAPI } from '../service/api';
import { CONTAINS_ALPHANUMERIC_ONLY, GET } from '../utility/constants';
import { IRoleAPI } from '../interfaces/rolesPermissionInterface';
const getAllRoleNames = async () => {
  try {
    const token = getLocalStorageItem('token');
    const response = await axiosInstance.request({
      url: rolePermissionAPI.GET_ALL_ROLES,
      method: GET,
      headers: {
        token : token !== undefined ? token : ''
      }
    })
    const roles : IRoleAPI[] = response.data;
    const roleNames = roles.map(role => role.name.toLowerCase());
    return roleNames;
  } catch (error) {
    console.log('GET ALL ROLES ERROR:',error);
  }
}
const addNewRoleSchema = Yup.object({
  roleList: Yup
    .array(
      Yup.object({
        roleName: Yup.string()
          .required('Role Name is required')
          .test('checkDuplRole', 'Role Name already exists.', async (value) => {
            const roleNames = await getAllRoleNames();
            if (value !== undefined && roleNames !== undefined) {
              const alreadyExists = roleNames?.includes(value?.toLocaleLowerCase());
              return !alreadyExists;
            } else {
              return false;
            }
          })
          .matches(CONTAINS_ALPHANUMERIC_ONLY, 'Role Name must consist of alphanumeric characters only.')
          .trim()
      })
    )
})
export default addNewRoleSchema;