const styles = {
  mainContainer: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F7F7F7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  paperStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '10px',
    width: '340px',
    borderRadius: '10px',
    paddingLeft: '40px',
    paddingRight: '40px',
    marginTop: '50px'
  },
  modalTitle: {
    textAlign: 'left',
    paddingTop: '15px',
    paddingBottom: '30x',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  descriptionText: {
    color: '#080808', 
    fontSize: '14px',
  },
};

export default styles;