const styles = {
  comboBox: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },

  comboBoxStyle: {
    display: 'flex',
    justifyContent: 'space-between' 
  },

  filterGridContainer: {
    marginY        : '16px',
    paddingBottom  : '1rem',
  },

  searchStyle: {
    alignSelf      : 'center',
    marginTop      : '1.89rem',
    marginLeft     : '16px',
    flexBasis      : 'auto',
  },

  uploadFileStyle: {
    alignSelf: 'center',
    textAlign: 'end',
    textTransform  : 'none',
    width: 'max-content',
  },

  searchField: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
    width          : '100%',
  },

  filterChildBoxMargin: {
    margin         : '1rem',
  },

  dropdownTitle: {
    display: 'block',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginBottom: '8px'
  },

  modalOpenButton: {
    py             : '2px',
    textTransform  : 'none',
    px             : '1rem',
  },

  gridContainer: {
    mb: '16px',
  },

  alertContainer: {
    mx: '24px',
    mb: '16px',
    paddingLeft: '8px',
    paddingRight: '8px',
    border: '1px solid #F4CA64',
    backgroundColor: '#FAE29F',
    width: '95.7%',
  },

  alertIcon: {
    position: 'relative',
    top:'10px',
    color: '#5C4813'
  },

  alertContent: {
    display: 'flex',
    position: 'relative',
    bottom:'15px',
    left: '35px',
    justifyContent: 'space-between',
    width: '97.5%',
    alignItems: 'center',
    height: '100%',
    color: '#5C4813',
  },

  alertButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  alertArrow: {
    pl: '5px',
    fontSize: '16px'
  },

  outmostContainer: {
    py: '17px',
    px: '17px',
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    border: '1px solid #E0E0E0',
  },

  tableContainer: {
    '&.MuiTableContainer-root': {
      px: '6px', // space offset for tableHeadRow's boxShadow
      width: '100%',
    },
  },

  tableGroup: {
    px: '1.5rem'
  },

  buttonTabs: {
    paddingX: '6px',
    marginBottom: '10px',
    '.MuiTabs-flexContainer': {
      borderBottom: '1px solid #E5E2E2',
    },
  },

  blockBox: {
    marginRight: 'auto'
  },

  tableHeaderText: {
    '& .MuiFormLabel-asterisk': {
      color: '#db3131',
    },
    '&.MuiFormLabel-root': {
      fontWeight: 'bold',
      fontSize: '14px',
      color: 'black',
    },
  },

  tableHeaderTextFileName: {
    marginLeft: '2.5rem'
  },

  rightAlignedText: {
    textAlign: 'right',
  },

  centerAlignedText: {
    textAlign: 'center',
  },

  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F7F7F7',
    },

    '&:nth-of-type(even)': {
      backgroundColor: '#FEFEFE',
    },
  },

  tableHeadRow: {
    '&.MuiTableRow-root': {
      backgroundColor: '#F7F7F7',
      boxShadow: '0px 3px 6px #00000029',
    },
  },

  tableCell: {
    border: 0,
    paddingY: '0.5rem',
  },

  tableCellEmpty: {
    border: 0,
    paddingX: 0
  },

  tableCellDocumentIssue: {
    paddingX: 0
  },

  tableWidthFileName: {
    width: '26%'
  },

  tableWidthUpcGeneral: {
    width: '22%'
  },

  tableWidthGeneral: {
    width: '13.2%'
  },

  tableWidthAction: {
    width: '8%'
  },

  boxContainerFileName: {
    display: 'block',
    width: '15rem', 
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  boxContainerGeneral: {
    display: 'block',
    width: '10rem', 
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },

  actionGroupEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%'
  },

  actionsIconButton: {
    '&.MuiButtonBase-root': {
      margin: 0,
      padding: '5px',
    }
  },

  actionsIconButtonDisabled: {
    '&.MuiButtonBase-root': {
      margin: 0,
      padding: '5px',
      opacity: 0.5,
      pointerEvents: 'none'
    }
  },

  actionsIcon: {
    fontSize: 25,
    color: '#154A8A',
    padding: '0',
    ':hover': {
      cursor: 'pointer',
    },
  },

  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    mx: '24px',
  },

  processSelectedFilesButton: {
    backgroundColor: '#D0DBE8',
    border: '1px solid #010953',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: '#BBCADD',
    }
  },

  processSelectedFilesButtonText: {
    alignSelf: 'center',
    textAlign: 'end',
    textTransform  : 'none',
    width: 'max-content',
    color: '#010953',
  },

  invisible: {
    visibility: 'hidden',
  },

  hidden: {
    display: 'none',
  },

  errorText: {
    color: '#AE2633',
    fontSize: '11px'
  },
  
  verbiageContainer: {
    py: '17px',
    px: '17px',
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    border: '1px solid #E0E0E0',
  },

  verbiage: {
    textAlign: 'center'
  },

  warningIconButton: {
    padding: '0.25rem',
    marginLeft: '0.1rem',
  },

  warningIconSpacer: {
    padding: '0.95rem'
  },

  issueCell: {
    padding: 0,
    border: 0,
  },

  loaderContainer: {
    paddingX: '6px',
    paddingY: '2px',
  }
};

export default styles;
