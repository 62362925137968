import { Box, CircularProgress, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react'
import styles from './styles';
import { RollForwardTableProps } from '..';
import { IRollForwardReport, IRollForwardReportDetails } from '../../../../../interfaces/rollForwardReportInterface';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import axiosInstance from '../../../../../service/axiosInstance';
import { reportsAPI } from '../../../../../service/api';
import { GET } from '../../../../../utility/constants';
import { handleFormatCurrency } from '../..';

export interface RollForwardTableRowProps extends RollForwardTableProps {
  rollForward: IRollForwardReport;
}

const RollForwardTableRow: FC<RollForwardTableRowProps> = (props) => {
  const { rollForward, currencyLabel, exchangeRate }= props;
  const [open, setOpen]                             = useState<boolean>(false);
  const [isDetailsLoading, setIsDetailsLoading]     = useState<boolean>(false);
  const [rollForwardDetails, setRollForwardDetails] = useState<IRollForwardReportDetails[]>([]);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [open, isDetailsLoading]);

  /**
   * This function opens a row for detailed information by setting a loading state and fetching details for the specified roll forward report.
   * @param rollForward The roll forward report for which to fetch details.
   */
  const openRow = async (rollForward: IRollForwardReport) => {
    setIsDetailsLoading(true);
    await getDetails(rollForward);
  };

  /**
   * This function closes a detailed row, resetting the state by closing it and clearing the roll forward details.
   */
  const closeRow = async () => {
    setOpen(false);
    setRollForwardDetails([]);
  };

  /**
   * This function fetches detailed information for a specific roll forward report and updates the state with the fetched details.
   * @param rollForward The roll forward report for which to fetch details.
   */
  const getDetails = async (rollForward: IRollForwardReport) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.rollForwardReport.GET_DETAILS,
        method: GET,
        params: {
          borrowerId: rollForward.borrowerId,
          bbPeriodId: rollForward.bbPeriodId,
          arCollateralId: rollForward.arCollateralId,
          rfCategoryId: rollForward.rfCategoryId,
          sortBy: 'glTrxType,ASC'
        }
      });
      setRollForwardDetails(response.data.content);
    } catch (error) {
      console.log('ROLL FORWARD DETAILS ERROR: ', error);
      setRollForwardDetails([]);
    } finally {
      setIsDetailsLoading(false);
      setOpen(true);
    }
  };

  /**
   * This function returns an icon element based on the current loading state and row status.
   * @returns An icon element to represent the loading state and row status.
   */
  const getIcon = () => {
    if (isDetailsLoading) {
      return (<CircularProgress size={20}/>);
    } else if (open) {
      return (
        <IconButton aria-label='Collapse icon' ref={buttonRef} sx={styles.addButton} onClick={() => closeRow()} data-testid='close-row'>
          <ArrowDropUpIcon sx={styles.addIcon} color='primary' />
        </IconButton>
      )
    } else {
      return (
        <IconButton aria-label='Expand icon' ref={buttonRef} sx={styles.addButton} onClick={() => openRow(rollForward)} data-testid='open-row'>
          <ArrowDropDownIcon sx={styles.addIcon} color='primary' />
        </IconButton>
      )
    }
  }

  return (
    <>
    <TableRow>
      <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyLeftAction}}>
        {getIcon()}
      </TableCell>
      <TableCell tabIndex={0} sx={{...styles.tableTextBody, ...styles.tableTextBodyCategory}}>
        {rollForward.rfCategoryName}
      </TableCell>
      <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyAmount}}>
        <TextField
          tabIndex={0}
          id={`gl-trx-amt-field-${rollForward.rfCategory}`}
          defaultValue={handleFormatCurrency(rollForward.glTrxAmt, currencyLabel, exchangeRate)}
          disabled={true}
          size='small'
          inputProps={{
            sx: styles.amtTextFieldInput,
            'aria-label': `${rollForward.rfCategory}: ${handleFormatCurrency(rollForward.glTrxAmt, currencyLabel, exchangeRate)}`
          }}
          fullWidth
        />
      </TableCell>
      <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyRightAction}}></TableCell>
    </TableRow>

    <TableRow>
    <TableCell colSpan={4} sx={styles.tableCollapseBody}>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Table aria-label='Roll Forward Report Details Table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell sx={{...styles.tableCollapseTextHeader, ...styles.tableCollapseTextHeaderLeftActionButton}}></TableCell>
              <TableCell sx={{...styles.tableCollapseTextHeader, ...styles.tableCollapseTextHeaderType}}>
                <Box sx={styles.detailsHeaderContainer}>
                  <Box tabIndex={0}>Type</Box>
                  <Box tabIndex={0}>Amount</Box>
                </Box>
              </TableCell>
              <TableCell sx={{...styles.tableCollapseTextHeader, ...styles.tableCollapseTextHeaderType}}></TableCell>
              <TableCell sx={{...styles.tableCollapseTextHeader, ...styles.tableCollapseTextHeaderRightActionButton}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell sx={{...styles.tableCollapseTextBody}}></TableCell>
            <TableCell sx={{...styles.tableCollapseTextBody}}>
              {rollForwardDetails.map(detail => (
                <Box key={detail.rfDetailId} sx={styles.detailsHeaderContainer}>
                  <Box tabIndex={0}>{detail.glTrxType}</Box>
                  <Box tabIndex={0}>{handleFormatCurrency(detail.glTrxTypeAmt, currencyLabel, exchangeRate)}</Box>
                </Box>
              ))}
            </TableCell>
            <TableCell sx={{...styles.tableCollapseTextBody}}></TableCell>
            <TableCell sx={{...styles.tableCollapseTextBody}}></TableCell>
          </TableBody>
        </Table>
      </Collapse>
    </TableCell>
    </TableRow>
    </>
  )
}

export default RollForwardTableRow;