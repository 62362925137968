import { useEffect } from 'react';
import { Box, InputAdornment, MenuItem, Select, TextField } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';

import styles from "./styles";
import { useState } from "react";
import _ from 'lodash';

export interface IProps {
  filters : string[]
  onChange: (value: string) => any
  onSelect: (selected: string) => void
  width?: string
}

/**
 * This component renders a search component that is used on searchin or filetering ar customers.
 * 
 * @param params IProps
 * @returns A search component.
 */
const SearchParentChild = ({filters, onChange, onSelect, width}: IProps) => {
  const [selectedVal, setSelectedVal] = useState<string>(filters[0]);
  const [searchVal, setSearchVal]  = useState<string>('');

  /**
   * This useEffect is called when filter options is updated.
   */
  useEffect(() => {
    setSelectedVal(filters[0]);
  }, [filters]);

  /**
   * This function handle changes on the filter component.
   * 
   * @param selected The selected filter value.
   */
  const handleChangeSelect = (selected: string) => {
    setSelectedVal(selected)
    onSelect(selected)
  };


  /**
   * This debounce is used to ensure that the onChange props is called when the new searchValue is set.
   */
  const triggerOnChange = _.debounce((searchTerm: string) => {
    onChange(searchTerm)
  }, 500);

  /**
   * This function handle changes on the search input component.
   * 
   * @param e SyntheticEvent: onChange
   */
  const handleOnChange = (e: any) => {
    setSearchVal(e.target.value)
    triggerOnChange(e.target.value)
  }


  return (
    <Box sx={{...styles.searchStyle, width: width}}>
      <Select
        id='filter-dropdown'
        value={selectedVal}
        onChange={e => handleChangeSelect(e.target.value)}
        sx={styles.filterStyle}
        aria-labelledby="filter-dropdown"
        inputProps={{ 'aria-label': 'Filter Dropdown', 'aria-labelledby': 'filter-dropdown' }}
      >
        {
          filters.map(filter => <MenuItem key={filter} value={filter}>{filter}</MenuItem>)
        }
      </Select>
      <TextField
        inputProps={{ 'aria-label': 'Search-Textfield' }}
        id={`input-with-id`}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start' tabIndex={0} aria-label='Search icon'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={searchVal}
        onChange={handleOnChange}
        placeholder='Search'
        size='small'
        sx={styles.searchField}
      />
    </Box>
  )
}

export default SearchParentChild