import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import styles from './styles';

interface IInvalidIneligibleSettingsMessageModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

interface IRemoveItemModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

/**
 * Component that displays a message modal for invalid ineligible settings.
 * @param props The component's props.
 * @returns The rendered component.
 */
export const InvalidIneligibleSettingsMessageModal = (props : IInvalidIneligibleSettingsMessageModalProps) => {
  return (
    <Modal open={props.isOpen}>
      <Box sx={styles.modalBackground}>
        <Box sx={styles.headingContainer}>
          <Typography sx={styles.headingTitle}>Ineligible Settings</Typography>
          <Close fontSize='small' onClick={props.handleClose} sx={styles.closeButton} />
        </Box>
        <Divider sx={styles.divider} />
        <Typography sx={styles.messageText}>There are empty required field(s). Please fill out the highlighted fields.</Typography>
        <Divider sx={styles.divider} />
        <Box sx={styles.rightButtonsContainer}>
          <Button variant='contained' sx={styles.okayButton} onClick={props.handleClose}>
            Okay
          </Button>
          </Box>
      </Box>
    </Modal>
  );
};

/**
 * Component that displays the remove confirmation modal.
 * @param props The component's props.
 * @returns The rendered component.
 */
export const RemoveItemModal = (props : IRemoveItemModalProps) => {
  return (
    <Modal open={props.isOpen}>
      <Box sx={styles.modalBackground}>
        <Box sx={styles.headingContainer}>
          <Typography sx={styles.headingTitle}>Remove Confirmation</Typography>
          <Close fontSize='small' onClick={props.handleClose} sx={styles.closeButton} aria-label='Close icon' />
        </Box>
        <Divider sx={styles.divider} />
        <Typography sx={styles.messageText}>Are you sure you want to remove this item?</Typography>
        <Divider sx={styles.divider} />
        <Box sx={styles.rightButtonsContainer}>
          <Button variant='outlined' sx={styles.cancelButton} onClick={props.handleClose}>
            Cancel
          </Button>
          <Button
            variant='contained'
            sx={styles.okayButton}
            onClick={() => {
              props.handleClose();
              props.handleConfirm();
            }}
          >
            Remove
          </Button>
          </Box>
      </Box>
    </Modal>
  );
};