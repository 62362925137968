const styles = {
  listItem: {
    bgcolor: 'background.paper',
    marginY: 1,
    border: 'solid',
    borderWidth: '1px',
    borderColor: '#E5E2E2',
    boxShadow: '0px 3px 6px #00000029',
  },
  noTooltip: {
    '&&&& .MuiTooltip-tooltip': {
      backgroundColor: 'transparent'
    }
  },
  widthBox: {
    width: '1.1rem'
  },
  checkBox: {
    minWidth: 0, 
    pl: '0.5rem', 
    pr: '1rem'
  },
  overflowBox: {
    maxHeight: '80%', 
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#B7B7B7',
    },
  },
  tableDataDragCell: {
    paddingY: '5px',
    paddingRight: '5px',
    borderBottom: 'none',
    textAlign: 'center',
    '& .MuiTypography-root': {
      textAlign: 'right',
    },
  },
  tableDataNameCell: {
    display: 'flex',
    justifyContent: 'left',
    paddingY: '5px',
    paddingLeft: 0,
    borderBottom: 'none',
    textAlign: 'left'
  },
  tableDataCell: {
    paddingY: '5px',
    borderBottom: 'none',
    textAlign: 'center',
    '& .MuiTypography-root': {
      textAlign: 'right',
    },
  },
  tableDataTypography: {
    fontSize: '14px',
    color:"inherit", 
  },
  tableDataNameBox: {
    display: 'flex', 
    flexDirection: 'column',
  },
  custName: {
    fontSize: '0.9rem',
    color: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '37ch'
  },
}

export default styles;