import { useState, useEffect, useContext, forwardRef, FC } from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { Formik, Form, Field, FieldArray, FormikErrors, FormikTouched, getIn, FormikProps } from 'formik';
import { TableRow, TableCell, TextField, Table, TableBody, TableHead, Typography, Box, TableContainer, IconButton, Button, FormLabel, Tabs, Tab, AlertColor, Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HistoryIcon from '@mui/icons-material/History';
import { API_DOMAIN, GET, NO_PERMISSION_MSG, PERMISSIONS, POST, PUT } from '../../../utility/constants';
import { getPermissionsOfUser, isObjectsEqual, trimOnBlur, checkUserPermissions } from '../../../utility/helper';
import { usePrompt } from '../../../utility/prompt';
import request from '../../../service/request';
import requests from '../../../service/requests';
import createReservesSchema from '../../../schemas/createReserverSchema';
import { CreateReservesContext as CRContext } from '../../../context/createReservesContext';
import { NavigationWarningContext, INavigationWarningContext } from '../../../context/navigationWarningContext';
import { ICreateReservesContext as ICRContextInterface, IReserve, IResponseContent, IFormikValue, INumberFormatProps, IResetFormForReserves } from '../../../interfaces/reservesInterface';
import Toaster from '../../toaster';
import ConfirmModal from '../../modals/confirm-modal';
import styles from './styles';
import { SkeletonRow, SkeletonTableHead } from '../../skeleton';
import { AuthContext } from '../../../context/authContext';
import HelperTextComponent from '../../common/helper-text-component';
import DisabledComponentsContainer from '../../common/disabled-components-container';
import axiosInstance from '../../../service/axiosInstance';

const CurrencyFormat = forwardRef<NumberFormat<InputAttributes>, INumberFormatProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      type='tel'
      decimalScale={2}
      fixedDecimalScale={true}
      prefix='$'
    />
  );
});

const ReservesTable: FC = () => {
  const headers                                     = ['Description', 'Reserve Amount', 'Action'];
  const { isDirty, setIsDirty }                     = useContext(NavigationWarningContext) as INavigationWarningContext;
  const { selectedClient, setTotalAmount,
          setViewReserves,
          addReserves, setAddReserves,
          updateReserves, setUpdateReserves,
          deleteArchiveReserves, 
          setDeleteArchiveReserves }                = useContext(CRContext) as ICRContextInterface;
  const {state}                                     = useContext(AuthContext)
  const [tabIndex, setTabIndex]                     = useState<number>(0);
  const [tempTabIndex, setTempTabIndex]             = useState<number>(0);
  const [reserves, setReserves]                     = useState<IReserve[]>([]);
  const [isLoading, setIsLoading]                   = useState<boolean>(false);
  const [allowAddingRow, setAllowAddingRow]         = useState<boolean>(true);
  const [isToasterOpen, setIsToasterOpen]           = useState(false);
  const [toasterMessage, setToasterMessage]         = useState<string>('');
  const [toasterSeverity, setToasterSeverity]       = useState<AlertColor>('success');
  const [deleteModal, setDeleteModal]               = useState({ isOpen: false, isNew: false, deleteRecordId: '', deleteName: '', deleteIndex: -1 });
  const [formikValues, setFormikValues]             = useState<IFormikValue | null>(null);
  const [isFormikResetting, setIsFormikResetting]   = useState(false);
  const [isModalOpen, setIsModalOpen]               = useState<boolean>(false);
  const [isDeleteModal, setIsDeleteModal]           = useState<boolean>(false);
  const [modalTitle, setModalTitle]                 = useState<string>('');
  const [modalDescription, setModalDescription]     = useState<string>('');
  const [modalConfirmText, setModalConfirmText]     = useState<string>('');
  const [selectedReserve, setSelectedReserve]       = useState<IReserve | null>(null);
  const [showPrompt, setShowPrompt]                 = useState<boolean>(false);

  const tabData = [
    {label: 'Active'}, 
    {label: 'Archived'}
  ]

  useEffect(() => {
    getPermissions();
    if (isLoading) { return; }
    !isFormikResetting && setIsFormikResetting(true);
    fetchReserves();
  }, [selectedClient]);

  useEffect(() => {
    if (formikValues === null || formikValues.reserves === undefined) { return; }
    const { savedFormikValues, emptyFormikValues } = getSavedAndEmptyFormikValues(formikValues.reserves);
    calcTotalAmount(savedFormikValues);
    emptyFormikValues.length > 0 ? setAllowAddingRow(false) : setAllowAddingRow(true);
  }, [formikValues]);

  useEffect(() => {
    getPermissions();
    fetchReserves();
  }, [tabIndex]);

  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(state.uid);
    setViewReserves(permissions.includes(PERMISSIONS.VIEW_RESERVES));
    setAddReserves(permissions.includes(PERMISSIONS.ADD_RESERVES));
    setUpdateReserves(permissions.includes(PERMISSIONS.UPDATE_RESERVES));
    setDeleteArchiveReserves(permissions.includes(PERMISSIONS.DELETE_ARCHIVE_RESERVES))
  }

  const checkPermission = async (func: Function, permission: string, ...args: any[]) => {
    const isPermitted = await checkUserPermissions(state.uid, permission)
    if(isPermitted){
      func(...args)
      return
    }
    setToasterMessage(NO_PERMISSION_MSG)
    setToasterSeverity('error')
    setIsToasterOpen(true)
  }

  const tabProps = (index: number) => {
    return {
      id: `reserves-tab-${index}`,
      'aria-controls': `reserves-tabpanel-${index}`,
    };
  };

  const handleDiscardChanges = () => {
    setIsDirty(false);
    setReserves([]);
    setIsFormikResetting(true);
    setTabIndex(tempTabIndex);
    setShowPrompt(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onArchiveClick = (values: IReserve[], index: number) => {
    setSelectedReserve(values[index]);
    setModalTitle(`Archive ${values[index].description}`);
    setModalDescription('Are you sure you want to archive this item?');
    setModalConfirmText('Archive')
    setIsDeleteModal(false)
    setIsModalOpen(true)
  }

  const onRestoreClick = (values: IReserve[], index: number) => {
    setSelectedReserve(values[index]);
    setModalTitle(`Restore ${values[index].description}`);
    setModalDescription('Are you sure you want to restore this item?');
    setModalConfirmText('Restore')
    setIsDeleteModal(false)
    setIsModalOpen(true)
  }

  const handleConfirm = () => {
    if (modalConfirmText === 'Archive') {
      return archiveReserve();
    } else if (modalConfirmText === 'Restore') {
      return restoreReserve();
    }
  }

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (isDirty) {
      setShowPrompt(true);
      setTempTabIndex(newValue);
    } else {
      setReserves([]);
      setTabIndex(newValue);
    } 
  };

  const archiveReserve = () => {
    if (selectedReserve !== null) {
      axiosInstance.request({
        url: `${API_DOMAIN}/bb/reserves/${selectedReserve.recordId}/archive`,
        method: PUT
      })
      .then((response) => {
        setToasterMessage(`${selectedReserve.description} has been archived.`);
        setToasterSeverity('success');
        setIsToasterOpen(true);
        fetchReserves();
      })
      .catch((error) => {
        console.log(error);
        setToasterMessage(`Failed to archive ${selectedReserve.description}. Please check your inputs!`);
        setToasterSeverity('error');
        setIsToasterOpen(true);
      });
    }
    closeModal();
  };

  const restoreReserve = () => {
    if (selectedReserve !== null) {
      axiosInstance.request({
        url: `${API_DOMAIN}/bb/reserves/${selectedReserve.recordId}/archive?isArchived=false`,
        method: PUT
      })
      .then((response) => {
        setToasterMessage(`${selectedReserve.description} has been restored.`);
        setToasterSeverity('success');
        setIsToasterOpen(true);
        fetchReserves();
      })
      .catch((error) => {
        console.log(error);
        setToasterMessage(`Failed to restore ${selectedReserve.description}. Please check your inputs!`);
        setToasterSeverity('error');
        setIsToasterOpen(true);
      });
    }
    closeModal();
  };

  const fetchReserves = () => {
    if (selectedClient.recordId === undefined || selectedClient.recordId === -1) {
      setReserves([]);
      return;
    }
    setIsLoading(true);
    const isArchived = tabIndex === 1;
    request({
      url: `${API_DOMAIN}/bb/reserves/search/findIsArchived?borrowerId=${selectedClient.recordId}&pageNo=0&pageSize=9999&sortBy=recordId,ASC&isArchived=${isArchived}`,
      method: GET,
    })
      .then((response) => {
        const data = response.data.content.filter((item : any) => item.current === 1);
        const reserves: IReserve[] = data.map((row: IResponseContent) => {
          const fetchedReserve = {
            recordId: row.recordId,
            borrower: row.borrower.toString(),
            description: row.description,
            amount: row.amount.toFixed(2),
            obsoleteAt: row.obsoleteAt,
            current: '1',
            canDelete: row.canDelete === 1
          };
          return fetchedReserve;
        });

        setIsDirty(false);
        setReserves(_ => reserves);
      })
      .catch((error) => console.log('RESERVES GET ERROR: ', error))
      .finally(() => setIsLoading(false));
  };

  const deleteReserve = (recordId: string, isFetchingAfter?: boolean) => {
    const deletedReserve = reserves.filter(reserve => reserve.recordId === recordId)[0];
    const deleteConfig = {
      url: `${API_DOMAIN}/bb/reserves/${deletedReserve.recordId}`,
      method: PUT,
      data: {
        recordId: parseInt(deletedReserve.recordId),
        borrower: parseInt(deletedReserve.borrower),
        description: deletedReserve.description,
        amount: parseFloat(deletedReserve.amount),
        current: 0,
      },
    };

    request(deleteConfig)
      .then((_response) => {
        const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
        setToasterMessage(`${itemName} has been deleted`);
        setToasterSeverity('success');
        setIsToasterOpen(true); // display delete toaster
        if (isFetchingAfter) { fetchReserves(); }
      })
      .catch((error) => console.log('RESERVES DELETE ERROR: ', error));
  };

  const calcTotalAmount = (createReservesArr: IReserve[]) => {
    let total = 0;
    for (const item of createReservesArr) {
      if (item.amount === undefined || item.amount === '') { continue; }
      total += parseFloat(item.amount);
    }
    setTotalAmount(total);
  };

  const handleDelete = (values: IFormikValue, index: number) => {
    const isReserveToDeleteNew = values.reserves[index].recordId === undefined;
    if (isReserveToDeleteNew) {
      setDeleteModal({
        isOpen: true,
        isNew: isReserveToDeleteNew,
        deleteRecordId: '',
        deleteName: values.reserves[index].description,
        deleteIndex: index,
      });
      return;
    }
    setDeleteModal({
      isOpen: true,
      isNew: isReserveToDeleteNew,
      deleteRecordId: values.reserves[index].recordId,
      deleteName: values.reserves[index].description,
      deleteIndex: index,
    });
  };

  const handleConfirmDelete = (formikValues: IReserve[], remove: (index: number) => void) => {
    if (deleteModal.isNew) {
      remove(deleteModal.deleteIndex);
      const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
      setToasterMessage(`${itemName} has been deleted`);
      setToasterSeverity('success');
      setIsToasterOpen(true);
    }

    const { hasUnsavedChanges, presentFormikValues } = getPresentFormikValues(formikValues);
    if (hasUnsavedChanges && deleteModal.isNew) {
      setFormikValues({ reserves: presentFormikValues });
      return;
    }
    if (hasUnsavedChanges && !deleteModal.isNew) {
      deleteReserve(deleteModal.deleteRecordId);
      setFormikValues({ reserves: presentFormikValues });;
      return;
    }
    if (!hasUnsavedChanges && !deleteModal.isNew) {
      deleteReserve(deleteModal.deleteRecordId, true);
      setFormikValues({ reserves: presentFormikValues });
      return;
    }
    setReserves(presentFormikValues);
  };

  const handleCancel = (resetForm: IResetFormForReserves) => {
    if (formikValues === null) { return; }
    const { savedFormikValues } = getSavedAndEmptyFormikValues(formikValues.reserves);
    setIsDirty(false);
    resetForm({ values: { reserves: savedFormikValues } });
    setReserves(savedFormikValues);
    setAllowAddingRow(true);
  };

  const handleSave = async (reservesToSave: IReserve[], setSubmitting: (isSubmitting: boolean) => void) => {
    const isPermittedAdd = await checkUserPermissions(state.uid, PERMISSIONS.ADD_RESERVES)
    const isPermittedUpdate = await checkUserPermissions(state.uid, PERMISSIONS.UPDATE_RESERVES)

    if(!isPermittedAdd && !isPermittedUpdate){
      setToasterMessage(NO_PERMISSION_MSG);
      setToasterMessage('error');
      setIsToasterOpen(true);
      return
    }
    setSubmitting(true);

    const reservesToEdit = reservesToSave
      .filter((reserve) => {
        const isReserveNew = reserve.hasOwnProperty('recordId');
        return isReserveNew;
      })
      .filter((reserve) => {
        const [originalReserve] = reserves.filter(currentReserve => currentReserve.recordId === reserve.recordId);
        if (originalReserve === undefined) { return false; }
        const isReserveNotEdited = !isObjectsEqual(reserve, originalReserve);
        return isReserveNotEdited;
      });

    // 1st part of update request (hide values to update)
    const editRequestPutConfigs = reservesToEdit.map((reserve) => {
      return {
        url: `${API_DOMAIN}/bb/reserves/${reserve.recordId}`,
        method: PUT,
        data: {
          recordId: parseInt(reserve.recordId),
          borrower: parseInt(reserve.borrower),
          description: reserve.description,
          amount: parseFloat(reserve.amount),
          current: 0,
        },
      };
    });

    // part 2 of update request (set current to true for updated values)
    const editRequestPostConfigs = reservesToEdit.map((reserve) => {
      return {
        url: `${API_DOMAIN}/bb/reserves`,
        method: POST,
        data: {
          borrower: parseInt(reserve.borrower),
          description: reserve.description,
          amount: parseFloat(reserve.amount),
          current: 1,
        },
      };
    });

    // add new reserve
    const postRequestConfigs = reservesToSave
      .filter((reserve) => {
        return !reserve.hasOwnProperty('recordId');
      })
      .map((reserve) => {
        return {
          url: `${API_DOMAIN}/bb/reserves`,
          method: POST,
          data: {
            borrower: selectedClient.recordId,
            description: reserve.description,
            amount: parseFloat(reserve.amount),
            current: 1,
          },
        };
      });

    const requestsConfigs = [
      ...(isPermittedUpdate ? editRequestPutConfigs: []), 
      ...(isPermittedAdd ? editRequestPostConfigs: []), 
      ...(isPermittedAdd ? postRequestConfigs: [])
      ];
    const updatedToasterMessage = getUpdateToasterMsg(isPermittedAdd ? postRequestConfigs : [], isPermittedUpdate ? editRequestPostConfigs: []);
    requests(requestsConfigs.map((requestConfig) => request(requestConfig)))
      .then((_response) => {
        setIsDirty(false);
        fetchReserves();
        setToasterMessage(updatedToasterMessage);
        setToasterSeverity('success');
        setIsToasterOpen(true);
        setSubmitting(false);
      })
      .catch((error) => console.log('RESERVES UPDATE ERROR: ', error));
  };

  const getDisplayedError = (errors: FormikErrors<IFormikValue>, touched: FormikTouched<IFormikValue>, name: string, index: number) => {
    const fieldError = getIn(errors, `reserves[${index}].${name}`);
    const isFieldTouched = getIn(touched, `reserves[${index}].${name}`);

    if (fieldError && isFieldTouched) { return { error: true, helperText: <HelperTextComponent text={fieldError} /> }; }

    return null;
  };

  const addNewRow = (push: any) => {
    const newReserve = { description: '', amount: '' };
    push(newReserve);
    setAllowAddingRow(false);
  };

  const getUpdateToasterMsg = (addItems: any, updateItems: any) => {
    const addLength = addItems.length;
    const updateLength = updateItems.length;
    if (addLength > 0 && updateLength > 0) {
      return 'Changes in Reserves have been saved';
    }
    if (addLength > 0 && updateLength <= 0) {
      const phrase = addLength > 1 ? 'Items have been' : `${addItems[0].data.description} has been`;
      return `${phrase} added`;
    }
    if (addLength <= 0 && updateLength > 0) {
      const phrase = updateLength > 1 ? 'Items have been' : `${updateItems[0].data.description} has been`;
      return `${phrase} updated`;
    }
    return '';
  };

  const getPresentFormikValues = (formikValues: IReserve[]) => {
    let hasUnsavedChanges = false;
    const presentFormikValues = formikValues.filter((currentValue: IReserve, currentIndex: number) => {
      if (currentIndex === deleteModal.deleteIndex) { return false; }
      if (currentValue.recordId === deleteModal.deleteRecordId) { return false; }

      const hasNewRecord = currentValue.recordId === undefined;
      const hasUpdatedRecord = reserves.some(reserve => {
        if (reserve.recordId !== currentValue.recordId) { return false; }
        return !isObjectsEqual(currentValue, reserve);
      });
      if (hasNewRecord || hasUpdatedRecord) { hasUnsavedChanges = true; }

      return true;
    });

    return { hasUnsavedChanges, presentFormikValues };
  };

  const getSavedAndEmptyFormikValues = (formikValuesForIneligibles: IReserve[]) => {
    const [savedFormikValues, emptyFormikValues] = formikValuesForIneligibles.reduce((separatedFormikValues: IReserve[][], reserve: IReserve) => {
      let currentSavedFormikValues = [...separatedFormikValues[0]];
      let currentEmptyFormikValues = [...separatedFormikValues[1]];

      const isIneligibleSaved = reserve.recordId !== undefined && reserve.recordId !== '';
      if (isIneligibleSaved) {
        const [savedReserve] = reserves.filter(originalReserve => originalReserve.recordId === reserve.recordId);
        savedReserve !== undefined && currentSavedFormikValues.push(savedReserve);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      const isReserveEmpty = (
        (reserve.description === undefined || reserve.description === '') &&
        (reserve.amount === undefined || reserve.amount === '')
      );
      if (isReserveEmpty) {
        currentEmptyFormikValues.push(reserve);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
    }, [[], []]);

    return { savedFormikValues, emptyFormikValues };
  };

  const formikRef = (node: FormikProps<IFormikValue>) => {
    if (node === null || isLoading) { return; }
    setFormikValues(node.values);
    !isObjectsEqual(formikValues, node.values) && setIsDirty(node.dirty);
    if (!isFormikResetting) { return; }
    node.resetForm();
    setIsFormikResetting(false);
  };
  
  const renderButton = (reserve: IReserve, formik: FormikProps<{reserves: IReserve[]}>, index: number) => {
    if (!deleteArchiveReserves) {
      return null;
    } else if (tabIndex === 0) {
      if (reserve.canDelete !== false) {
        return (
          <Tooltip title='Delete the item'>
            <IconButton
              onClick={() => checkPermission(handleDelete, PERMISSIONS.DELETE_ARCHIVE_LOAN_BALANCES, formik.values, index)}
              sx={styles.actionButton}
              color='primary'
              aria-label='Delete icon'
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title='Archive the item'>
            <IconButton
              onClick={() => checkPermission(onArchiveClick, PERMISSIONS.DELETE_ARCHIVE_LOAN_BALANCES, formik.values.reserves, index)}
              sx={styles.actionButton}
              color='primary'
              aria-label='Archive icon'
            >
              <Inventory2OutlinedIcon />
            </IconButton>
          </Tooltip>
        );
      }
    } else {
      return (
        <Tooltip title='Restore the item'>
          <IconButton
            onClick={() => checkPermission(onRestoreClick, PERMISSIONS.DELETE_ARCHIVE_LOAN_BALANCES, formik.values.reserves, index)}
            sx={styles.actionButton}
            color='primary'
            aria-label='Restore icon'
          >
            <HistoryIcon />
          </IconButton>
        </Tooltip>
      );
    }
  }

  const isFieldDisabled = (index: number) => {
    return tabIndex === 1 || (formikValues?.reserves[index]?.recordId === undefined ? !addReserves : !updateReserves);
  }

  const getActionButtons = (formik: FormikProps<IFormikValue>) => {
    if (addReserves || updateReserves || deleteArchiveReserves) {
      return (
        <Box sx={styles.buttonsContainer}>
          {formik.dirty ? (
            <Button
              onClick={() => handleCancel(formik.resetForm)}
              variant='outlined'
              sx={styles.cancelButton}
            >
              <Typography variant='body2' component='p'>
                Cancel
              </Typography>
            </Button>
          ) : null}
           <DisabledComponentsContainer isDisabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}>
            <Button
              disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}
              aria-label={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading ? 'Save button disabled' : 'Save'}
              variant='contained'
              sx={styles.saveButton}
              type='submit'
            >
              <Typography variant='body2' component='p'>
                Save
              </Typography>
            </Button>
          </DisabledComponentsContainer>
        </Box>
      )
    } 
  }

  usePrompt('You have unsaved changes. Are you sure you want to leave this page?', isDirty);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{ reserves }}
      validationSchema={createReservesSchema}
      onSubmit={(values, { setSubmitting }) => handleSave(values.reserves, setSubmitting)}
    >
      {
        formik => (
          <Form onSubmit={formik.handleSubmit}>
            <Box sx={styles.outmostContainer}>
              <Box sx={styles.blockBox}>
                <Tabs
                  sx={styles.buttonTabs}
                  value={tabIndex}
                  onChange={handleChange}
                  aria-label="reserves tab"
                >
                  {tabData.map((tab, index) => (
                    <Tab tabIndex={0} label={tab.label} key={tab.label} {...tabProps(index)} />
                  ))}
                </Tabs>
              </Box>
              {isLoading && (
                <Table sx={styles.table}>
                  <SkeletonTableHead/>
                  <TableBody/>
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                    <SkeletonRow />
                </Table>
              )}
              <TableContainer sx={styles.tableContainer} hidden={isLoading}>
                <Table sx={styles.table}>
                  <TableHead>
                    <TableRow sx={styles.tableHeadRow}>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForDescription }}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='description-box'
                          sx={styles.tableHeaderText}
                        >
                          Description<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForAmount, ...styles.rightAlignedText }}>
                        <FormLabel
                          tabIndex={0}
                          htmlFor='reserve-amount-box'
                          sx={styles.tableHeaderText}
                        >
                          Reserve Amount<span style={styles.asterisk}> *</span>
                        </FormLabel>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForAction, ...styles.centerAlignedText }}>
                        <FormLabel tabIndex={0} sx={styles.tableHeaderText}>
                          Action
                        </FormLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <FieldArray name='reserves'>
                    {({ push, remove }) => (
                      <TableBody sx={styles.tableBody}>
                        {formik.values.reserves.map((reserve: IReserve, index) => {
                          return (
                            <TableRow sx={styles.tableRow} key={reserve.recordId}>
                              <TableCell sx={styles.tableCell}>
                                <Field
                                  disabled={isFieldDisabled(index)}
                                  tabIndex={isFieldDisabled(index) ? 0 : null}
                                  id='description-box'
                                  aria-label={`Description ${formik.values.reserves[index].description}`}
                                  name={`reserves[${index}].description`}
                                  as={TextField}
                                  sx={styles.textField}
                                  onBlur={(event:any) => {
                                     trimOnBlur(event, formik, `reserves[${index}].description`, formik.values.reserves[index].description)
                                  }}
                                  variant='outlined'
                                  onChange={(event: any) => { formik.handleChange(event); }}
                                  inputProps={{ 'aria-label': 'Description', 'data-testid': `reserves[${index}].description` }}
                                  {...getDisplayedError(formik.errors, formik.touched,  'description', index)}
                                />
                              </TableCell>
                              <TableCell sx={styles.tableCell}>
                                <Field
                                  disabled={isFieldDisabled(index)}
                                  tabIndex={isFieldDisabled(index) ? 0 : null}
                                  id='reserve-amount-box'
                                  aria-label={`Reserve Amount ${formik.values.reserves[index].description}`}
                                  name={`reserves[${index}].amount`}
                                  as={TextField}
                                  sx={styles.textField}
                                  variant='outlined'
                                  onChange={(event: any) => { formik.handleChange(event); }}
                                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => formik.handleBlur(event)}
                                  InputProps={{ inputComponent: CurrencyFormat as any }}
                                  inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Reserve Amount', 'data-testid': `reserves[${index}].amount` }}
                                  {...getDisplayedError(formik.errors, formik.touched, 'amount', index)}
                                />
                              </TableCell>
                              <TableCell sx={{ ...styles.actionTableCell, ...styles.centerAlignedText }}>
                                {renderButton(reserve, formik, index)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {/* rows that adds new form rows */}
                        { (allowAddingRow && tabIndex === 0 && addReserves) &&
                          <TableRow sx={styles.tableRow} key={formik.values.reserves.length}>
                            {headers.map((header, index) => (
                              <TableCell sx={styles.tableCell} key={header}>
                                {index < headers.length - 1 ? (
                                  <TextField
                                    sx={styles.textField}
                                    value=''
                                    onClick={() => addNewRow(push)}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { addNewRow(push) } }}
                                    inputProps={{ 'aria-label': 'Press enter to add new entry' }}
                                  />
                                ) : null}
                              </TableCell>
                            ))}
                          </TableRow>
                        }
                        <ConfirmModal
                          open={deleteModal.isOpen}
                          onClose={() => { setDeleteModal({ ...deleteModal, isOpen: false }); }}
                          onConfirm={() => { handleConfirmDelete(formik.values.reserves, remove); }}
                          title={`Delete ${deleteModal.deleteName}`}
                          description={'Are you sure you want to delete this item?'}
                          errorButton
                          yesButtonText='Delete'
                          noButtonText='Cancel'
                        />
                      </TableBody>
                    )}
                  </FieldArray>
                </Table>
              </TableContainer>
              {getActionButtons(formik)}
            </Box>
            <Toaster
              open={isToasterOpen}
              message={toasterMessage}
              severity={toasterSeverity}
              onCloseChange={() => setIsToasterOpen(false)}
            />
            <ConfirmModal
              open={showPrompt}
              onConfirm={() => setShowPrompt(false)}
              onClose={handleDiscardChanges}
              onButtonClose={() => setShowPrompt(false)}
              promptChecker={true}
              title={`Confirm Navigation`}
              description={`You have unsaved changes. Are you sure you want to leave this page?`}
              yesButtonText="Keep Editing"
              noButtonText="Discard Changes"
              confirmOnly
            />
            <ConfirmModal
              title={modalTitle}
              description={modalDescription}
              open={isModalOpen}
              alignment="left"
              onClose={closeModal}
              onConfirm={handleConfirm}
              errorButton={isDeleteModal}
              noButtonText={'Cancel'}
              yesButtonText={modalConfirmText}
            />
          </Form>
        )
      }
    </Formik>
  );
};

export default ReservesTable;
