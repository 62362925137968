import * as React from 'react';
import { useState, useContext } from 'react';
import { AppBar, Avatar, Box, IconButton, Menu, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HeaderName from './header-name';
import MenuDrawer from './menu-drawer';
import LoanWatchLogo from '../../assets/images/logo.png';
import styles from './styles';
import { DrawerContext } from '../../context/drawerContext';
import { toggleHardOpen, toggleMobileOpen } from '../../actions/drawerActions';
import { BankLogoContext } from '../../context/bankLogoContext';
import { AuthContext } from '../../context/authContext';
const Header: React.FC = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { dispatch } = useContext(DrawerContext);
  const { state } = useContext(BankLogoContext);
  const authContext = useContext(AuthContext);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const avatar = {
    alt: 'User Profile Image',
    src: '/static/images/avatar/2.jpg',
  };

  return (
    <AppBar position='fixed' color='inherit' sx={styles.appBar}>
      <Toolbar sx={styles.toolbar}>
        <Box>
          <IconButton
            aria-label="Menu icon"
            data-testid="web-menu-icon"
            size="large"
            color="primary"
            onClick={() => { dispatch(toggleHardOpen()) }}
            sx={styles.webDrawerToggleButton}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            aria-label="Menu icon"
            data-testid="mobile-menu-icon"
            size="large"
            color="primary"
            onClick={() => { dispatch(toggleMobileOpen()) }}
            sx={styles.mobileDrawerToggleButton}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box>
        {
          state.logoDataUrl && 
          <img style={styles.logo} src={state.logoDataUrl} alt='Lender Logo' aria-label='Lender Logo' width={200} tabIndex={0}/>
        }
        </Box>
        <Box sx={styles.box}></Box>
        <Box sx={styles.loanWatchLogo}>
          <Typography
            tabIndex={0}
            variant='subtitle1'
            noWrap
            component='div'
            sx={styles.loanWatchSubtitle}
          >
            Powered by:
          </Typography>
          <img src={LoanWatchLogo} alt='LoanWatch Logo' aria-label='LoanWatch logo image' width={120} tabIndex={0} />
        </Box>
        <Box sx={styles.divider}></Box>
        <Box sx={styles.settings}>
          <Tooltip title='Open settings'>
            <IconButton onClick={handleOpenUserMenu} sx={styles.iconButton}>
              <Box sx={styles.menuButton}>
                <Avatar
                  alt={avatar.alt}
                  src={avatar.src}
                  sx={styles.avatarHolder}
                  aria-label='User Profile Image'
                >
                  {authContext.state.firstName[0]}{authContext.state.lastName[0]}
                </Avatar>
                <HeaderName />
              </Box>
            </IconButton>
          </Tooltip>
          <Menu
            sx={styles.menu}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuDrawer />
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
