import { FormikProps } from "formik";
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IneligibleSettingsContext } from "../../../../context/ineligibleSettingsContext";
import { IFormikValuesForRuleAndOverridesPerSetting, IFormikValuesForUPCRuleAndOverridesPerSetting, IIneligibleRuleConditionGroup, IIneligibleRuleOverride, IIneligibleSettingsContext } from "../../../../interfaces/ineligibleSettingInterface";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, Grid, IconButton, InputAdornment, Link, MenuItem, Modal, Paper, Select, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Tooltip, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styles from "./styles";
import { formatDate, getApplicableCustomersRequest, getApplicableCustomersUpcRequest } from "../../../../utility/helper";
import { AMERICAN_DATE_FORMAT, PROMPT } from "../../../../utility/constants";
import { IARCollateral, IARCustomer, IBBPeriod, IClient } from "../../../../interfaces";
import SearchIcon from '@mui/icons-material/Search';
import NoDataPlaceholder from "../../../common/no-data-placeholder";
import { ExpandMore } from "@mui/icons-material"
import ConfirmModal from "../../../modals/confirm-modal";
import { SelectedClientContext } from "../../../../context/selectedClientContext";
import OverflowText from "../../../common/overflow-text";
import { getAsOfDates, getMostRecentlyUpdatedAsOfDate, getMostRecentlyUpdatedUPCAsOfDate, getParentCustomersByARCollateralId, getUPCAsOfDates, getUPCParentCustomersByBorrowerId } from "../../../../api/ineligible-settings";

export interface IViewApplicableCustomersProps {
  formik                : FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>;
  idx                   : number;
}

/**
 * Component displays a list of applicable customers for an ineligible rule override.
 * It allows the user to include or exclude customers and provides a search and filter functionality.
 * @param props The component's props.
 * @returns {JSX.Element} The rendered View Applicable Customers button and its Customer List View modal component.
 */
const ViewApplicableCustomers: FC<IViewApplicableCustomersProps> = (props) => {
  const { selectedClient } = useContext(SelectedClientContext);
  const {
    selectedIneligibleIndex: ineligibleIndex,
    asOfDates, setAsOfDates,
    mostRecentlyUpdatedAsOfDate, setMostRecentlyUpdatedAsOfDate,
    selectedCollateral,
    selectedAsOfDate, setSelectedAsOfDate,
    parentCustomers, setParentCustomers
  } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const isSelectedClientUPC = () => (selectedClient as IClient).parentClient;

  const selectedIneligibleRuleOverride = useMemo(() => (
    (props.formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRuleOverrides as IIneligibleRuleOverride[])[props.idx]
  ), [ineligibleIndex, props.formik.values.ineligibleSettingDetails, props.idx]);

  const [applicableCustomers, setApplicableCustomers] = useState<IARCustomer[]>([]);
  const [excludedCustomerIds, setExcludedCustomerIds] = useState(selectedIneligibleRuleOverride.excludedCustomers);
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomerListViewModalOpen, setIsCustomerListViewModalOpen] = useState(false);
  const [isConfirmationModalForUnsavedChangesOpen, setIsConfirmationModalForUnsavedChangesOpen] = useState(false);
  const [isViewByCustomer, setIsViewByCustomer] = useState(true);
  const [selectedFilterType, setSelectedFilterType] = useState<'All' | 'Parent' | 'Child'>('All');
  const [filterText, setFilterText] = useState('');
  const [scrolledRowIndex, setScrolledRowIndex] = useState(0);
  const NUMBER_OF_ROWS_PER_SCROLL = 15;

  const getFormikFieldNameForExcludedCustomersOnSelectedOverride = () => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${props.idx}].excludedCustomers`;

  useEffect(() => {
    if (!isCustomerListViewModalOpen) { return; }
    setApplicableCustomers([]); /* reset last sessions' applicable customers */
    async function fetchSelectedAsOfDateAndCustomers() {
      const [ asOfDates ] = await Promise.all([ loadAsOfDates(), loadParentCustomers() ]);
      const mostRecentlyUpdatedAsOfDate = await loadMostRecentlyUpdatedAsOfDate(asOfDates);
      setSelectedAsOfDate(mostRecentlyUpdatedAsOfDate);
      await getApplicableCustomers(mostRecentlyUpdatedAsOfDate);
    }
    fetchSelectedAsOfDateAndCustomers();
  }, [isCustomerListViewModalOpen]);

  const selectedIneligibleDescription = useMemo(() => (
    props.formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.description
  ), [ineligibleIndex, props.formik.values.ineligibleSettingDetails]);

  const filteredApplicableCustomers = useMemo(() => {
    if (filterText === '') { return applicableCustomers; }
    return applicableCustomers.filter(customer => {
      if (selectedFilterType === 'Child' && isSelectedClientUPC() && customer.upcParentCustId === undefined) { return false; }
      if (selectedFilterType === 'Child' && !isSelectedClientUPC() && customer.parentARCustomerId === undefined) { return false; }
      const customerAttributeValuesToMatch = selectedFilterType === 'All' || selectedFilterType === 'Child' ? [ customer.custName, customer.custSrcId ] : [];
      const collateralLevelParentCustomerAttributes = [ customer.parentCustName, customer.parentCustSrcId ];
      const upcLevelParentCustomerAttributes = [ customer.upcParentCustName, customer.upcParentCustSrcId ];
      const parentCustomerAttributes = isSelectedClientUPC() ? upcLevelParentCustomerAttributes : collateralLevelParentCustomerAttributes ;
      const parentCustomerAttributeValuesToMatch = (selectedFilterType === 'All' || selectedFilterType === 'Parent') ? parentCustomerAttributes : [];
      return [ ...customerAttributeValuesToMatch, ...parentCustomerAttributeValuesToMatch ].some(text => text?.toLowerCase()?.includes(filterText.toLowerCase()));
    });
  }, [applicableCustomers, filterText, selectedFilterType]);

  const hasMoreRowsToRender = useMemo(() => {
    const lengthOfRenderedRows = filteredApplicableCustomers.slice(0, scrolledRowIndex * NUMBER_OF_ROWS_PER_SCROLL + NUMBER_OF_ROWS_PER_SCROLL).length;
    return applicableCustomers.length > lengthOfRenderedRows;
  }, [applicableCustomers, filteredApplicableCustomers, scrolledRowIndex]);

  const observer = useRef<any>();
  const rowElementRef = useCallback((node: any) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => (entries[0].isIntersecting && hasMoreRowsToRender) && setScrolledRowIndex((prevValue) => prevValue + 1))
    if (node) observer.current.observe(node);
  }, [isLoading, hasMoreRowsToRender]);

  const openCustomerListViewModal = async () => {
    setIsViewByCustomer(true);
    setSelectedFilterType('All');
    setExcludedCustomerIds(selectedIneligibleRuleOverride.excludedCustomers);
    setIsCustomerListViewModalOpen(true);
  };

  const getApplicableCustomers = async(asOfDate: IBBPeriod | null) => {
    if (asOfDate === null) { setApplicableCustomers([]); return; }
    try {
      setIsLoading(true);
      const nonNullSelectedClientRecordId = selectedClient?.recordId as NonNullable<number>;
      const nonNullSelectedCollateral = selectedCollateral as NonNullable<IARCollateral>;
      const endDate = formatDate(asOfDate.endDate, 'YYYY-MM-DD');
      const payload = selectedIneligibleRuleOverride.ineligibleRuleConditionGroup.map((condition: IIneligibleRuleConditionGroup) => {
        return {
          irConditions: condition.ineligibleRuleCondition,
          irConditionGroupId: condition.irConditionGroupId,
          irOverrideId: condition.irOverrideId,
          logicalOperator: condition.logicalOperator,
          order: condition.order
        }
      });
      const applicableCustomers = (
        isSelectedClientUPC()
          ? await getApplicableCustomersUpcRequest(nonNullSelectedClientRecordId, endDate, payload)
          : await getApplicableCustomersRequest(nonNullSelectedCollateral.recordId, asOfDate.recordId, payload)
      ).data as IARCustomer[];
      const parentCustomerIds = applicableCustomers.map(customer => isSelectedClientUPC() ? customer.upcParentCustId : customer.parentARCustomerId).filter(customerId => customerId !== undefined);
      setApplicableCustomers(applicableCustomers.filter(customer => !parentCustomerIds.includes(customer.recordId as number))); /* filter out parent customers for excluding */
    } catch (error) {
      console.log('APPLICABLE CUSTOMERS API', error);
      setApplicableCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAsOfDates = async () => {
    if (asOfDates !== 'UNLOADED') { return asOfDates; }
    const loadedAsOfDates = isSelectedClientUPC()
      ? await getUPCAsOfDates(selectedClient as Required<IClient>)
      : await getAsOfDates(selectedClient as Required<IClient>, selectedCollateral as IARCollateral);
    setAsOfDates(loadedAsOfDates);
    return loadedAsOfDates;
  };

  const loadMostRecentlyUpdatedAsOfDate = async (asOfDates: IBBPeriod[]) => {
    if (mostRecentlyUpdatedAsOfDate !== 'UNLOADED') { return mostRecentlyUpdatedAsOfDate; }
    const loadedMostRecentlyUpdatedAsOfDate = isSelectedClientUPC()
      ? await getMostRecentlyUpdatedUPCAsOfDate(selectedClient as Required<IClient>, asOfDates)
      : await getMostRecentlyUpdatedAsOfDate(selectedCollateral as IARCollateral, asOfDates);
    setMostRecentlyUpdatedAsOfDate(loadedMostRecentlyUpdatedAsOfDate);
    return loadedMostRecentlyUpdatedAsOfDate;
  };

  const loadParentCustomers = async () => {
    if (parentCustomers !== 'UNLOADED') { return; }
    const loadedParentCustomers = isSelectedClientUPC()
      ? await getUPCParentCustomersByBorrowerId((selectedClient as Required<IClient>).recordId)
      : await getParentCustomersByARCollateralId((selectedCollateral as IARCollateral).recordId);
    setParentCustomers(loadedParentCustomers);
  };

  const handleSelectCustomerToExclude = (isChecked: boolean, customerId: number) => {
    (!isChecked && !excludedCustomerIds.includes(customerId)) && setExcludedCustomerIds([ ...excludedCustomerIds, customerId ]);
    (isChecked && excludedCustomerIds.includes(customerId)) && setExcludedCustomerIds( [ ...excludedCustomerIds.filter(excludedCustomerId => excludedCustomerId !== customerId) ]);
  };

  const handleSaveChanges = () => {
    props.formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(), excludedCustomerIds);
    setIsCustomerListViewModalOpen(false);
  };

  const hasUnsavedChangesOnExcludedCustomers = () => {
    const excludedCustomerIdsToSort = [ ...excludedCustomerIds ];
    const excludedCustomerIdsOnFormikToSort = [ ...selectedIneligibleRuleOverride.excludedCustomers ];
    excludedCustomerIdsToSort.sort((a, b) => (a - b));
    excludedCustomerIdsOnFormikToSort.sort((a, b) => (a - b));
    return JSON.stringify(excludedCustomerIdsToSort) !== JSON.stringify(excludedCustomerIdsOnFormikToSort);
  };

  const isModalLoading = () => isLoading || asOfDates === 'UNLOADED' || parentCustomers === 'UNLOADED';

  const renderViewApplicableCustomersLink = () => {
    const field = selectedIneligibleRuleOverride.ineligibleRuleConditionGroup[0].ineligibleRuleCondition[0].field ?? '';
    const operation = selectedIneligibleRuleOverride.ineligibleRuleConditionGroup[0].ineligibleRuleCondition[0].operation ?? '';
    const values = selectedIneligibleRuleOverride.ineligibleRuleConditionGroup[0].ineligibleRuleCondition[0].fieldValues[0] ?? '';
    const isDisabled = [field, operation, values].some(text => text === '');
    if (isDisabled) {
      return (
        <Tooltip title='Please Set an Override Criteria' placement='bottom-start'>
          <Link
            component='button' type='button'
            variant='body2' underline='none'
            sx={styles.disabledLinkForViewApplicableCustomers}
            disabled>
            View Applicable Customers
          </Link>
        </Tooltip>
      );
    }
    return (
      <Link
        component='button' type='button'
        variant='body2' underline='hover'
        sx={styles.linkForViewApplicableCustomers}
        onClick={openCustomerListViewModal}>
        View Applicable Customers
      </Link>
    );
  };

  const renderCustomerListViewModal = () => {
    const handleClose = () => {
      if (hasUnsavedChangesOnExcludedCustomers()) {
        setIsConfirmationModalForUnsavedChangesOpen(true);
        return;
      }
      setIsCustomerListViewModalOpen(false);
    };

    return (
      <Modal
        open={isCustomerListViewModalOpen}
        onClose={handleClose}>
        <Grid container sx={styles.modalContainer}>
          <Grid item xs={12} sx={styles.gridContainerForHeadingAndCloseButton}>
            { renderGridForHeadingAndCloseButton()}
          </Grid>
          <Grid item xs={12}>
            <Divider orientation='vertical' flexItem sx={styles.divider} />
          </Grid>
          <Grid item xs={12} sx={styles.gridContainerForFilterFields}>
            <Box sx={styles.viewByLabel}>View By</Box>
            { renderGridForFilterFields() }
          </Grid>
          <Grid item xs={12} sx={styles.gridContainerForMainContent}>
            { renderGridForMainContent() }
          </Grid>
          <Grid item xs={12} sx={styles.gridContainerForCancelAndSaveButtons}>
            { renderGridForCancelAndSaveButtons() }
          </Grid>
        </Grid>
      </Modal>
    );
  };

  const renderConfirmationModalForUnsavedChanges = () => (
    <ConfirmModal
      open={isConfirmationModalForUnsavedChangesOpen}
      onClose={() => { setIsConfirmationModalForUnsavedChangesOpen(false); setIsCustomerListViewModalOpen(false); }}
      onConfirm={() => setIsConfirmationModalForUnsavedChangesOpen(false)}
      onButtonClose={() => setIsConfirmationModalForUnsavedChangesOpen(false)}
      promptChecker
      title={PROMPT.NAV_PROMPT.title}
      description='You have unsaved changes. Are you sure you want to leave this modal?'
      yesButtonText={PROMPT.NAV_PROMPT.keepEditing}
      noButtonText={PROMPT.NAV_PROMPT.discardChanges}
      confirmOnly
    />
  );

  const renderGridForHeadingAndCloseButton = () => (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Typography variant='h6' component='h2' sx={styles.modalHeading}>
        Customer List View: { selectedIneligibleDescription }
      </Typography>
      <IconButton aria-label='Close icon' onClick={() => setIsCustomerListViewModalOpen(false)} sx={styles.closeIconButton}>
        <CloseIcon fontSize='inherit' />
      </IconButton>
    </Grid>
  );

  const renderGridForFilterFields = () => (
    <Box sx={styles.containerForFilterFields}>
      <Select
        name='view'
        aria-label='view'
        inputProps={{ 'aria-label': 'view', 'aria-labelledby': 'view' }}
        defaultValue='Customer'
        onChange={(event) => { setIsViewByCustomer(event.target.value === 'Customer'); setScrolledRowIndex(0); }}
        sx={styles.inputForViewByFilter}
        disabled={isModalLoading()}>
        <MenuItem value='Customer' sx={styles.menuItemForViewByFilter}>  Customer </MenuItem>
        <MenuItem value='Parent' sx={styles.menuItemForViewByFilter}> Parent </MenuItem>
      </Select>
      <Autocomplete
        value={selectedAsOfDate}
        options={asOfDates !== 'UNLOADED' ? asOfDates : []}
        onChange={(_event, asOfDate: IBBPeriod | null) => { setSelectedAsOfDate(asOfDate); getApplicableCustomers(asOfDate); }}
        getOptionLabel={(asOfDates) => formatDate(asOfDates.endDate, AMERICAN_DATE_FORMAT)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={'Select As of Date'}
            inputProps={{ ...params.inputProps, 'aria-label': 'As of Date' }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  { asOfDates === 'UNLOADED' ? <CircularProgress color='inherit' size={15} /> : null }
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        blurOnSelect
        disablePortal
        fullWidth
        size='small'
        componentsProps={{
          popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
          clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
        }}
        sx={styles.inputForAsOfDate}
        disabled={isModalLoading()}
        loading={asOfDates === 'UNLOADED'}
      />
      <Box sx={styles.containerForSearchFields}>
        <Select
          defaultValue='All'
          onChange={e => setSelectedFilterType(e.target.value as 'All' | 'Parent' | 'Child')}
          sx={styles.inputForSearchBasedOnType}
          inputProps={{ 'aria-label': 'Filter Dropdown', 'aria-labelledby': 'filter-dropdown' }}
          disabled={isModalLoading()}
        >
          <MenuItem value='All' sx={styles.menuItemForSearchBasedOnType}> All </MenuItem>
          <MenuItem value='Parent' sx={styles.menuItemForSearchBasedOnType}> Parent </MenuItem>
          <MenuItem value='Child' sx={styles.menuItemForSearchBasedOnType}> Child </MenuItem>
        </Select>
        <TextField
          value={filterText}
          onChange={(event) => setFilterText(event.target.value)}
          inputProps={{ 'aria-label': 'Search-Textfield' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' tabIndex={0} aria-label='Search icon'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder='Search'
          size='small'
          sx={styles.inputForSearch}
          disabled={isModalLoading()}
        />
      </Box>
    </Box>
  );

  const renderGridForMainContent = () => {
    if (isModalLoading()) { return <Box sx={styles.loadingContainer}><CircularProgress size={30} /></Box> }
    if (filteredApplicableCustomers.length === 0) { return <NoDataPlaceholder messageText='No Applicable Customers' messageContainerStyle={styles.noDataPlaceholderContainer} />; }
    return isViewByCustomer ? renderTableForViewByCustomer() : renderAccordionsForViewByParent();
  };

  const renderTableForViewByCustomer = () => {
    const tableHeadCells = [
      { label: 'Customer Name', style: { ...styles.viewByCustomerTableHeadCell } },
      { label: 'Customer ID', style: { ...styles.viewByCustomerTableHeadCell } },
      { label: 'Parent Name', style: { ...styles.viewByCustomerTableHeadCell } },
      { label: 'Parent ID', style: { ...styles.viewByCustomerTableHeadCell } },
      { label: 'Selected', style: { ...styles.viewByCustomerTableHeadCell, textAlign: 'center' } }
    ];

    const renderTableCell = (text: string | number | undefined, textStyle: SxProps<Theme>) => {
      const renderedText = typeof text === 'number' ? text.toString() : text ?? '';
      return (
        <TableCell align='left' sx={styles.viewByCustomerTableCell}>
          <Typography tabIndex={0} sx={textStyle}>
            <OverflowText>
              { renderedText }
            </OverflowText>
          </Typography>
        </TableCell>
      );
    };

    return (
      <TableContainer sx={styles.viewByCustomerTableContainer}>
        <Table aria-label='Table for View By Customer'>
          <TableHead sx={styles.viewByCustomerTableHead}>
            <TableRow>
              { tableHeadCells.map(tableHeadCell => (<TableCell tabIndex={0} key={tableHeadCell.label} sx={tableHeadCell.style}> { tableHeadCell.label } </TableCell>)) }
            </TableRow>
          </TableHead>
          <TableBody>
          {
            filteredApplicableCustomers.slice(0, scrolledRowIndex * NUMBER_OF_ROWS_PER_SCROLL + NUMBER_OF_ROWS_PER_SCROLL).map(customer => (
              <TableRow ref={rowElementRef} sx={styles.viewByCustomerTableRow} key={customer.recordId}>
                { renderTableCell(customer.custName, styles.viewByCustomerTableCellText) }
                { renderTableCell(customer.custSrcId, styles.viewByCustomerTableCellText) }
                { renderTableCell(isSelectedClientUPC() ? customer.upcParentCustName : customer.parentCustName, styles.viewByCustomerTableCellText) }
                { renderTableCell(isSelectedClientUPC() ? customer.upcParentCustSrcId : customer.parentCustSrcId, styles.viewByCustomerTableCellText) }
                <TableCell sx={styles.viewByCustomerTableCellForCheckbox}>
                  <Checkbox
                    checked={!excludedCustomerIds.includes(customer.recordId as number)}
                    onChange={event => handleSelectCustomerToExclude(event.target.checked, customer.recordId as number)}
                    inputProps={{ 'aria-label': 'Include customer?' }} />
                </TableCell>
              </TableRow>
            ))
          }
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderAccordionsForViewByParent = () => {
    const loadedParentCustomers = parentCustomers as IARCustomer[];
    const applicableParentCustomers = loadedParentCustomers.filter(parentCustomer => filteredApplicableCustomers.some(applicableCustomer => (isSelectedClientUPC() ? applicableCustomer.upcParentCustId : applicableCustomer.parentARCustomerId) === parentCustomer.recordId));
    const customersByParent = applicableParentCustomers.map(parentCustomer => ({
      ...parentCustomer,
      childCustomers: filteredApplicableCustomers.filter(childCustomer => (isSelectedClientUPC() ? childCustomer.upcParentCustId : childCustomer.parentARCustomerId) === parentCustomer.recordId)
    }));
    const orphanCustomers = filteredApplicableCustomers.filter(customer => (isSelectedClientUPC() ? customer.upcParentCustId : customer.parentARCustomerId) === undefined);

    const getTimeout = (numberOfChild: number) => ({
      enter: numberOfChild > 50 ? 1000 : 250,
      exit: numberOfChild > 50 ? 500 : 250,
    });

    const renderAccordionSummary = (accordionCustomerName: string, accordionCustomerID?: string) => (
      <AccordionSummary
        aria-controls={`accordion-parent-customer-content(${accordionCustomerName})`}
        id={`accordion-parent-customer-header(${accordionCustomerName})`}
        expandIcon={ <ExpandMore/> }
        sx={styles.viewByParentAccordionSummary}
      >
        <Box sx={styles.viewByParentAccordionSummaryTextContainer}>
          <Typography sx={styles.viewByParentAccordionSummaryCustomerNameText}>
            { accordionCustomerName }
          </Typography>
          { accordionCustomerID !== undefined && (
            <Typography sx={styles.viewByParentAccordionSummaryCustomerIDText}>
              Customer ID: { accordionCustomerID }
            </Typography>
          )}
        </Box>
      </AccordionSummary>
    );

    const renderAccordionDetails = (customers: IARCustomer[]) => (
      <AccordionDetails sx={styles.viewByParentAccordionDetails}>
        <TableContainer sx={styles.viewByParentTableContainer}>
          <Table aria-label='Table for View By Parent'>
            { renderAccordionDetailTableHeadRows() }
            <TableBody>
              { customers.slice(0, scrolledRowIndex * NUMBER_OF_ROWS_PER_SCROLL + NUMBER_OF_ROWS_PER_SCROLL).map(childCustomer => renderAccordionDetailCustomerRows(childCustomer)) }
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    );

    const renderAccordionDetailTableHeadRows = () => {
      const tableHeadCells = [
        { label: 'Customer Name', style: styles.viewByParentTableHeadCell },
        { label: 'Customer ID', style: styles.viewByParentTableHeadCell },
        { label: 'Selected', style: { ...styles.viewByParentTableHeadCell, ...styles.viewByParentTableHeadCellForSelected } },
      ];
      return (
        <TableHead sx={styles.viewByParentTableHead}>
          <TableRow>
            { tableHeadCells.map(tableHeadCell => (<TableCell tabIndex={0} key={tableHeadCell.label} sx={tableHeadCell.style}> { tableHeadCell.label } </TableCell>)) }
          </TableRow>
        </TableHead>
      );
    };

    const renderAccordionDetailCustomerRows = (customer: IARCustomer) => {
      const renderTableCell = (text: string | number | undefined, textStyle: SxProps<Theme>) => {
        const renderedText = typeof text === 'number' ? text.toString() : text ?? '';
        return (
          <TableCell align='left' sx={styles.viewByParentTableCell}>
            <Typography tabIndex={0} sx={textStyle}>
              <OverflowText>
                { renderedText }
              </OverflowText>
            </Typography>
          </TableCell>
        );
      };
      return (
        <TableRow ref={rowElementRef} sx={styles.viewByParentTableRow} key={customer.recordId}>
          { renderTableCell(customer.custName, styles.viewByParentTableCellTextForCustomerName) }
          { renderTableCell(customer.custSrcId, styles.viewByParentTableCellTextForCustomerID) }
          <TableCell sx={styles.viewByParentTableCellForSelected}>
            <Checkbox
              checked={!excludedCustomerIds.includes(customer.recordId as number)}
              onChange={event => handleSelectCustomerToExclude(event.target.checked, customer.recordId as number)}
              inputProps={{ 'aria-label': 'Include customer?' }} />
          </TableCell>
        </TableRow>
      );
    };

    return (
      <Paper sx={styles.viewByParentMainContainer}>
        <Paper sx={styles.viewByParentSubContainer}>
          <Box sx={styles.containerForParentListHeadingLabelContainer}>
            <Typography sx={styles.containerForParentListHeadingLabel}>Parent List</Typography>
          </Box>
          {
            customersByParent.map((parentCustomer, idx) =>
              <Accordion
                key={parentCustomer.recordId}
                elevation={0}
                disableGutters
                TransitionProps={{ timeout: getTimeout(parentCustomer.childCustomers.length) }}
                sx={{ backgroundColor: idx % 2 === 0 ? '#FEFEFE' : '#F0F0F0' }}
              >
                { renderAccordionSummary(`${(parentCustomer.custName as string)} (${parentCustomer.childCustomers.length})`, parentCustomer.custSrcId) }
                { renderAccordionDetails(parentCustomer.childCustomers) }
              </Accordion>
            )
          }
          <Accordion
            elevation={0}
            disableGutters
            TransitionProps={{ timeout: getTimeout(orphanCustomers.length) }}
            sx={{ backgroundColor: applicableParentCustomers.length % 2 === 0 ? '#FEFEFE' : '#F0F0F0' }}
            hidden={orphanCustomers.length === 0}
          >
            { renderAccordionSummary('No Parent') }
            { renderAccordionDetails(orphanCustomers) }
          </Accordion>
        </Paper>
      </Paper>
    );
  };

  const renderGridForCancelAndSaveButtons = () => {
    return (
      <Grid container justifyContent='flex-end'>
        <Button
          variant='outlined' size='small'
          onClick={() => setIsCustomerListViewModalOpen(false)}
          sx={styles.cancelButton}>
          Cancel
        </Button>
        <Button
          variant='contained' size='small' sx={styles.saveButton}
          onClick={handleSaveChanges}
          disabled={!hasUnsavedChangesOnExcludedCustomers()}>
          Save Changes
        </Button>
      </Grid>
    );
  };

  return (
    <>
      { renderViewApplicableCustomersLink() }
      { renderCustomerListViewModal() }
      { renderConfirmationModalForUnsavedChanges() }
    </>
  );
};

export default ViewApplicableCustomers;
