import * as Yup from 'yup';
import { IARBorrowerInput } from '../interfaces';
import { MAX_POSITIVE_INTEGER, NON_EXISTING_PERCENT } from '../utility/constants';

const compareBuckets = (comparison: 'less than' | 'greater than', bucketToCompareTo: any[], currentBucket: number | undefined) => {
  const filteredBucketToCompareTo = bucketToCompareTo.filter(bucket => !(bucket === undefined || bucket === null));

  if (currentBucket !== undefined && currentBucket !== null) {
      if (comparison === 'less than') {
        return filteredBucketToCompareTo.every(bucketToCompareTo => currentBucket < bucketToCompareTo);
      }
      else return filteredBucketToCompareTo.every(bucketToCompareTo => currentBucket > bucketToCompareTo);
  }
  return true;
};

const requireBucket = (bucketsToCheck: any[], value: number | undefined) => {
  if (bucketsToCheck.some(bucket => bucket !== undefined && bucket !== null)) {
    return value !== undefined && value !== null;
  }
  return true;
}

const accountsReceivableSettingsSchema = Yup.object({
  arCollateral: Yup.object({
    collateralId: Yup.string()
      .nullable()
      .required('Collateral ID is required'),
  }),
  arBorrowerInput: Yup.object({
    arCollateralName: Yup.string()
      .required('Collateral Name is required').trim()
      .test({
        name: 'arCollateralName', message: 'Collateral Name must be unique',
        test() {
          const { from } = this as Yup.TestContext & { from: { value: IARBorrowerInput | { existingARCollateralNames: string[] } }[]; };
          const { value: arBorrowerInput } = from[0] as { value: IARBorrowerInput };
            const { value: { existingARCollateralNames } } = from[1] as { value: { existingARCollateralNames: string[] } };
          return !existingARCollateralNames.includes(arBorrowerInput.arCollateralName);
        }
      }),
    rateOfAdvance: Yup.number()
      .notOneOf([NON_EXISTING_PERCENT], 'Advance Rate is required')
      .required('Advance Rate is required'),
    countryName: Yup.string()
      .required('Country is required').trim()
      .max(56, 'Country name is too long!'),
    currency: Yup.string()
      .nullable()
      .required('Currency is required'),
    invoiceDateBucket1: Yup.number()
      .required('First Bucket is required')
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('lessThanNext', 'Bucket should be less than the next bucket', function (value) {
        const nextBucket = [
          this.parent.invoiceDateBucket2,
          this.parent.invoiceDateBucket3,
          this.parent.invoiceDateBucket4,
          this.parent.invoiceDateBucket5,
          this.parent.invoiceDateBucket6
        ];
        return compareBuckets('less than', nextBucket, value);
      }),
    invoiceDateBucket2: Yup.number()
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('isRequired', 'Bucket is required', function (value) {
        const bucketsToCheck = [
          this.parent.invoiceDateBucket3,
          this.parent.invoiceDateBucket4,
          this.parent.invoiceDateBucket5,
          this.parent.invoiceDateBucket6
        ];
        return requireBucket(bucketsToCheck, value);
      })
      .test('greaterThanPrevious', 'Bucket should be greater than the previous bucket', function (value) {
        const prevBucket = [this.parent.invoiceDateBucket1];
        return compareBuckets('greater than', prevBucket, value);
      })
      .test('lessThanNext', 'Bucket should be less than the next bucket', function (value) {
        const nextBucket = [
          this.parent.invoiceDateBucket3,
          this.parent.invoiceDateBucket4,
          this.parent.invoiceDateBucket5,
          this.parent.invoiceDateBucket6
        ];
        return compareBuckets('less than', nextBucket, value);
      }),
    invoiceDateBucket3: Yup.number()
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('isRequired', 'Bucket is required', function (value) {
        const bucketsToCheck = [
          this.parent.invoiceDateBucket4,
          this.parent.invoiceDateBucket5,
          this.parent.invoiceDateBucket6
        ];
        return requireBucket(bucketsToCheck, value);
      })
      .test('greaterThanPrevious', 'Bucket should be greater than the previous bucket', function (value) {
        const prevBucket = [
          this.parent.invoiceDateBucket1,
          this.parent.invoiceDateBucket2
        ];
        return compareBuckets('greater than', prevBucket, value);
      })
      .test('lessThanNext', 'Bucket should be less than the next bucket', function (value) {
        const nextBucket = [
          this.parent.invoiceDateBucket4,
          this.parent.invoiceDateBucket5,
          this.parent.invoiceDateBucket6
        ];
        return compareBuckets('less than', nextBucket, value);
      }),
    invoiceDateBucket4: Yup.number()
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('isRequired', 'Bucket is required', function (value) {
        const bucketsToCheck = [
          this.parent.invoiceDateBucket5,
          this.parent.invoiceDateBucket6
        ];
        return requireBucket(bucketsToCheck, value);
      })
      .test('greaterThanPrevious', 'Bucket should be greater than the previous bucket', function (value) {
        const prevBucket = [
          this.parent.invoiceDateBucket1,
          this.parent.invoiceDateBucket2,
          this.parent.invoiceDateBucket3
        ];
        return compareBuckets('greater than', prevBucket, value);
      })
      .test('lessThanNext', 'Bucket should be less than the next bucket', function (value) {
        const nextBucket = [
          this.parent.invoiceDateBucket5,
          this.parent.invoiceDateBucket6
        ];
        return compareBuckets('less than', nextBucket, value);
      }),
    invoiceDateBucket5: Yup.number()
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('isRequired', 'Bucket is required', function (value) {
        const bucketsToCheck = [this.parent.invoiceDateBucket6];
        return requireBucket(bucketsToCheck, value);
      })
      .test('greaterThanPrevious', 'Bucket should be greater than the previous bucket', function (value) {
        const prevBucket = [
          this.parent.invoiceDateBucket1,
          this.parent.invoiceDateBucket2,
          this.parent.invoiceDateBucket3,
          this.parent.invoiceDateBucket4
        ];
        return compareBuckets('greater than', prevBucket, value);
      })
      .test('lessThanNext', 'Bucket should be less than the next bucket', function (value) {
        const nextBucket = [this.parent.invoiceDateBucket6];
        return compareBuckets('less than', nextBucket, value);
      }),
    invoiceDateBucket6: Yup.number()
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('greaterThanPrevious', 'Bucket should be greater than the previous bucket', function (value) {
        const prevBucket = [
          this.parent.invoiceDateBucket1,
          this.parent.invoiceDateBucket2,
          this.parent.invoiceDateBucket3,
          this.parent.invoiceDateBucket4,
          this.parent.invoiceDateBucket5
        ];
        return compareBuckets('greater than', prevBucket, value);
      }),
    dueDateBucket1: Yup.number()
      .required('First Due Bucket is required')
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('lessThanNext', 'Bucket should be less than the next bucket', function (value) {
        const nextBucket = [
          this.parent.dueDateBucket2,
          this.parent.dueDateBucket3,
          this.parent.dueDateBucket4,
          this.parent.dueDateBucket5,
          this.parent.dueDateBucket6
        ];
        return compareBuckets('less than', nextBucket, value);
      }),
    dueDateBucket2: Yup.number()
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('isRequired', 'Bucket is required', function (value) {
        const bucketsToCheck = [
          this.parent.dueDateBucket3,
          this.parent.dueDateBucket4,
          this.parent.dueDateBucket5,
          this.parent.dueDateBucket6
        ];
        return requireBucket(bucketsToCheck, value);
      })
      .test('greaterThanPrevious', 'Bucket should be greater than the previous bucket', function (value) {
        const prevBucket = [this.parent.dueDateBucket1];
        return compareBuckets('greater than', prevBucket, value);
      })
      .test('lessThanNext', 'Bucket should be less than the next bucket', function (value) {
        const nextBucket = [
          this.parent.dueDateBucket3,
          this.parent.dueDateBucket4,
          this.parent.dueDateBucket5,
          this.parent.dueDateBucket6
        ];
        return compareBuckets('less than', nextBucket, value);
      }),
    dueDateBucket3: Yup.number()
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('isRequired', 'Bucket is required', function (value) {
        const bucketsToCheck = [
          this.parent.dueDateBucket4,
          this.parent.dueDateBucket5,
          this.parent.dueDateBucket6
        ];
        return requireBucket(bucketsToCheck, value);
      })
      .test('greaterThanPrevious', 'Bucket should be greater than the previous bucket', function (value) {
        const prevBucket = [
          this.parent.dueDateBucket1,
          this.parent.dueDateBucket2
        ];
        return compareBuckets('greater than', prevBucket, value);
      })
      .test('lessThanNext', 'Bucket should be less than the next bucket', function (value) {
        const nextBucket = [
          this.parent.dueDateBucket4,
          this.parent.dueDateBucket5,
          this.parent.dueDateBucket6
        ];
        return compareBuckets('less than', nextBucket, value);
      }),
    dueDateBucket4: Yup.number()
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('isRequired', 'Bucket is required', function (value) {
        const bucketsToCheck = [
          this.parent.dueDateBucket5,
          this.parent.dueDateBucket6
        ];
        return requireBucket(bucketsToCheck, value);
      })
      .test('greaterThanPrevious', 'Bucket should be greater than the previous bucket', function (value) {
        const prevBucket = [
          this.parent.dueDateBucket1,
          this.parent.dueDateBucket2,
          this.parent.dueDateBucket3
        ];
        return compareBuckets('greater than', prevBucket, value);
      })
      .test('lessThanNext', 'Bucket should be less than the next bucket', function (value) {
        const nextBucket = [
          this.parent.dueDateBucket5,
          this.parent.dueDateBucket6
        ];
        return compareBuckets('less than', nextBucket, value);
      }),
    dueDateBucket5: Yup.number()
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('isRequired', 'Bucket is required', function (value) {
        const bucketsToCheck = [this.parent.dueDateBucket6];
        return requireBucket(bucketsToCheck, value);
      })
      .test('greaterThanPrevious', 'Bucket should be greater than the previous bucket', function (value) {
        const prevBucket = [
          this.parent.dueDateBucket1,
          this.parent.dueDateBucket2,
          this.parent.dueDateBucket3,
          this.parent.dueDateBucket4
        ];
        return compareBuckets('greater than', prevBucket, value);
      })
      .test('lessThanNext', 'Bucket should be less than the next bucket', function (value) {
        const nextBucket = [this.parent.dueDateBucket6];
        return compareBuckets('less than', nextBucket, value);
      }),
    dueDateBucket6: Yup.number()
      .typeError('Bucket must be a number')
      .min(0, 'Cannot be below 0')
      .max(MAX_POSITIVE_INTEGER, `Cannot exceed ${MAX_POSITIVE_INTEGER}`)
      .test('greaterThanPrevious', 'Bucket should be greater than the previous bucket', function (value) {
        const prevBucket = [
          this.parent.dueDateBucket1,
          this.parent.dueDateBucket2,
          this.parent.dueDateBucket3,
          this.parent.dueDateBucket4,
          this.parent.dueDateBucket5
        ];
        return compareBuckets('greater than', prevBucket, value);
      }),
  })
});

export default accountsReceivableSettingsSchema;
