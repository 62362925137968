import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertColor, Box, Button, CircularProgress, Typography } from '@mui/material/';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CustomizeReportDrawer from '../customize-report-drawer';
import MenuButton from '../../../common/menu-button';
import { IHeaderProps } from '../../../../interfaces/reportsInterface';
import styles from './styles';
import { AuthContext } from '../../../../context/authContext';
import { PERMISSIONS, NO_PERMISSION_MSG } from '../../../../utility/constants';
import { checkUserPermissions } from '../../../../utility/helper';
import Toaster from '../../../toaster';

const BorrowingBaseReportHeaderTitle: React.FC<IHeaderProps> = (props) => {
  const [isCustomizeReportDrawerOpen, setIsCustomizeReportDrawerOpen] = useState(false);
  const {state}    = useContext(AuthContext)
  const [canExport, setCanExport]                   = useState<boolean>(false);
  const [isToasterOpen, setIsToasterOpen]           = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]         = useState<string>('');
  const [toasterSeverity, setToasterSeverity]       = useState<AlertColor>('success');

  /**
   * This useEffect hook retrieves user permissions on initialization.
   */
  useEffect(() => {
    getPermission()
  }, [])

  /**
 * - This method initiates an asynchronous call to 'checkUserPermissions' with the user's unique identifier (uid) and the required permission for exporting BB report.
   */
  const getPermission = async () => {
    const isPermitted = await checkUserPermissions(state.uid, PERMISSIONS.EXPORT_BB_REPORT)
    if(isPermitted){
      setCanExport(isPermitted)
    }
  }

  /**
   * This method checks if the user has the necessary permissions to export Borrowing Base report.
   * @param func The function to be executed after the permission check.
   * @param args The arguments to be passed to the 'func' when executed.
   * @returns 
   */
  const checkPermission = (func: Function, ...args: any[]) => {
    (async () => {
      const isPermitted = await checkUserPermissions(state.uid, PERMISSIONS.EXPORT_BB_REPORT)
      if(isPermitted){
        func(...args)
        return
      }
      setToasterMessage(NO_PERMISSION_MSG);
      setToasterSeverity('error');
      setIsToasterOpen(true);
    })();
  }

  const navigate = useNavigate();

  return (
    <>
      <Box sx={styles.headerBackground}>
        <Typography tabIndex={0} component='h1' sx={styles.headerTitleText} data-testid="Borrowing Base Report-header-title">
          Borrowing Base Report
        </Typography>
        <Box sx={styles.buttonsContainer}>
          <Button
            variant="outlined"
            onClick={() => setIsCustomizeReportDrawerOpen(true)}
            disabled
            sx={{...styles.buttonLeftSpace, visibility: 'hidden'}}>
            Customize Report
          </Button>
          {canExport &&
          <MenuButton 
            label="Export"
            options={[
              { 
                label: 'Excel', 
                handleSelect: () => checkPermission(props.exportBBReportAsExcel), 
              }, 
              { 
                label: 'PDF', 
                handleSelect: () => checkPermission(props.exportBBReportAsPDF),
              }
            ]}
            buttonProps={{ 
              disabled: props.isLoading || props.exportDisabled, 
              endIcon: props.isLoading ? <CircularProgress size={15} /> : <FileDownloadOutlinedIcon />, 
              variant: 'outlined', 
              sx: styles.buttonLeftSpace, 
              'aria-label': 'Export icon' 
            }}
          />
          }
          <Button
            startIcon={<ArrowBackIosNewRoundedIcon sx={styles.backIcon} />}
            variant="outlined"
            sx={styles.buttonLeftSpace}
            onClick={() => navigate('/reports')}
            aria-label='Go back icon'>
            Go back
          </Button> 
        </Box>
        <CustomizeReportDrawer
          isOpen={isCustomizeReportDrawerOpen}
          onCloseChange={() => setIsCustomizeReportDrawerOpen(false)}
        />
      </Box >
      <Toaster
        open={isToasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setIsToasterOpen(false)}
      />
    </>
  );
};

export default BorrowingBaseReportHeaderTitle;
