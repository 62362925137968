
const styles = {
  lenderAvatarContainer: {
   display:'flex', 
   justifyContent:'center',
   alignItems: 'center',
   minHeight: '30vh',
  },
  lenderAvatar:{
    height: '64px',
    width: '200px',
    maxHeight: 64,
    display: 'block',
    objectPosition: '50% 50%',
    'object-fit': 'contain'
  },
  editAvatar:{
    height: 25,
    width: 25,
    position:'absolute',
    color: '#fff',
  },
  textError:{
    color:'red', 
    display:'flex', 
    justifyContent:'center',
    position: 'absolute',
    marginTop: '250px',
    marginLeft: '2rem',
  },
  helperText:{
    color: '#707070',
    display:'flex', 
    justifyContent:'center',
    position: 'absolute',
    marginTop: '15.625rem',
    marginBottom: '3rem',
    marginLeft: '2rem',
  },
  uploadBtn:{
      marginLeft: '2rem',
      borderColor: '#d2d2d2',
      height: 65,
      width: 200,
      padding: 0,
      '&:hover':{
        opacity: 0.5,
        backgroundColor: '#9EA6AD'
      }
  }
};

export default styles;