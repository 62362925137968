import { FC, forwardRef, useContext, useEffect, useState } from 'react';
import { Formik, Form, FieldArray, FormikErrors, FormikTouched, getIn, Field, FormikProps } from 'formik';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { TableRow, TableCell, TextField, Table, TableBody, TableHead, Typography, Box, TableContainer, IconButton, Button, FormLabel, Backdrop, CircularProgress, Tooltip, AlertColor, Skeleton } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { API_DOMAIN, GET, NO_PERMISSION_MSG, PERMISSIONS, POST, PUT } from '../../../utility/constants';
import { checkUserPermissions, getLocalStorageItem, getPermissionsOfUser, isObjectsEqual, trimOnBlur } from '../../../utility/helper';
import { usePrompt } from '../../../utility/prompt';
import inventoryIneligiblesSchema from '../../../schemas/inventoryIneligiblesSchema';
import { InventoryIneligibleContext as InvIneContext } from '../../../context/inventoryIneligibleContext';
import { NavigationWarningContext, INavigationWarningContext } from '../../../context/navigationWarningContext';
import { IInventoryIneligibleContext as IInvIneContextInterface, IResponseContent, IInventoryIneligible, IFormikValue, INumberFormatProps, IResetFormForInvIneligible } from '../../../interfaces/inventoryIneligibleInterface';
import Toaster from '../../toaster';
import ConfirmModal from '../../modals/confirm-modal';
import styles from './styles';
import axiosInstance from '../../../service/axiosInstance';
import HelperTextComponent from '../../common/helper-text-component';
import DisabledComponentsContainer from '../../common/disabled-components-container';
import LoadingBackdrop from '../../common/loading-backdrop';

const CurrencyFormat = forwardRef<NumberFormat<InputAttributes>, INumberFormatProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      type='tel'
      decimalScale={2}
      fixedDecimalScale={true}
      prefix='$'
    />
  );
});

const InventoryIneligiblesTable: FC = () => {
  const headers = ['Description', 'Ineligible Amount', 'Action'];
  const { isDirty, setIsDirty } = useContext(NavigationWarningContext) as INavigationWarningContext;
  const { selectedClient, selectedInventory, setTotalAmount,
    setCanViewInventoryIneligibles,
    canAddInventoryIneligibles, setCanAddInventoryIneligibles,
    canUpdateInventoryIneligibles, setCanUpdateInventoryIneligibles,
    canDeleteInventoryIneligibles, setCanDeleteInventoryIneligibles } = useContext(InvIneContext) as IInvIneContextInterface;
  const [inventoryIneligibles, setInventoryIneligibles] = useState<IInventoryIneligible[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allowAddingRow, setAllowAddingRow] = useState(true);
  const [isToasterOpen, setIsToasterOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('');
  const [toasterSeverity, setToasterSeverity] = useState<AlertColor>('success');
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, isNew: false, deleteRecordId: '', deleteName: '', deleteIndex: -1, });
  const [formikValues, setFormikValues] = useState<IFormikValue | null>(null);
  const [isFormikResetting, setIsFormikResetting] = useState(false);

  useEffect(() => {
    !isFormikResetting && setIsFormikResetting(true);
    getPermissions();
    fetchInventoryIneligibles();
  }, [selectedInventory]);

  useEffect(() => {
    if (formikValues === null || formikValues.inventoryIneligibles === undefined) { return; }
    const { savedFormikValues, emptyFormikValues } = getSavedAndEmptyFormikValues(formikValues.inventoryIneligibles);
    calcTotalAmount(savedFormikValues);
    emptyFormikValues.length > 0 ? setAllowAddingRow(false) : setAllowAddingRow(true);
  }, [formikValues]);

  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(getLocalStorageItem('uid'));
    setCanViewInventoryIneligibles(permissions.includes(PERMISSIONS.VIEW_INVENTORY_INELIGIBLES));
    setCanAddInventoryIneligibles(permissions.includes(PERMISSIONS.ADD_INVENTORY_INELIGIBLES));
    setCanUpdateInventoryIneligibles(permissions.includes(PERMISSIONS.UPDATE_INVENTORY_INELIGIBLES));
    setCanDeleteInventoryIneligibles(permissions.includes(PERMISSIONS.DELETE_INVENTORY_INELIGIBLES));
  };

  const fetchInventoryIneligibles = async () => {
    if (selectedClient.recordId === undefined || selectedClient.recordId === -1) {
      setInventoryIneligibles([])
      return;
    }
    if (selectedInventory.invCollateralFk === undefined || selectedInventory.invCollateralFk === -1) {
      setInventoryIneligibles([]);
      return;
    }
    setIsLoading(true);

    await axiosInstance.request({
      url: `${API_DOMAIN}/inv/ineligibles/search/findByBorrowerIdAndInvCollateralId`,
      method: GET,
      params: {
        borrowerId: selectedClient.recordId,
        invCollateralId: selectedInventory.invCollateralFk,
        sortBy: 'recordId,ASC'
      }
    })
      .then((response) => {
        const { data } = response;
        const mappedInventoryIneligibles: IInventoryIneligible[] = data.content
          .map((row: IResponseContent) => {
            const parsedInventoryIneligible = {
              recordId: row.recordId ? row.recordId?.toString() : '',
              borrowerFk: row.borrowerFk,
              invCollateralFk: row.invCollateralFk,
              description: row.description,
              amount: row.amount.toFixed(2),
              current: true,
            };
            return parsedInventoryIneligible;
          });

        setIsDirty(false);
        setInventoryIneligibles(_ => mappedInventoryIneligibles);
      })
      .catch((error) => console.log('INVENTORY INELIGIBLES GET ERROR: ', error))
      .finally(() => setIsLoading(false));
  };

  const deleteInventoryIneligible = async (recordId: string, isFetchingAfter?: boolean) => {
    const [deletedInventoryIneligible] = inventoryIneligibles.filter(ineligible => ineligible.recordId === recordId);

    const deleteRequestConfig = {
      url: `${API_DOMAIN}/inv/ineligibles/${deletedInventoryIneligible.recordId}`,
      method: PUT,
      data: {
        recordId: parseInt(deletedInventoryIneligible.recordId),
        borrowerFk: deletedInventoryIneligible.borrowerFk,
        invCollateralFk: selectedInventory.invCollateralFk,
        description: deletedInventoryIneligible.description,
        amount: parseFloat(deletedInventoryIneligible.amount),
        current: false,
      }
    };

    await axiosInstance.request(deleteRequestConfig)
      .then((_response) => {
        const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
        setToasterMessage(`${itemName} has been deleted`);
        setToasterSeverity('success');
        setIsToasterOpen(true);
        if (isFetchingAfter) { fetchInventoryIneligibles(); }
      })
      .catch((error) => console.log('INVENTORY INELIGIBLES DELETE ERROR: ', error));
  };

  const calcTotalAmount = (inventoryIneligibles: IInventoryIneligible[]) => {

    let total = 0;
    for (const item of inventoryIneligibles) {
      total += parseFloat(item.amount);
    }
    setTotalAmount(total);
  };

  const handleDelete = (values: IFormikValue, index: number) => {
    const isIneligibleToDeleteNew = values.inventoryIneligibles[index].recordId === undefined;
    if (isIneligibleToDeleteNew) {
      setDeleteModal({
        isOpen: true,
        isNew: isIneligibleToDeleteNew,
        deleteRecordId: '',
        deleteName: values.inventoryIneligibles[index].description,
        deleteIndex: index,
      });
      return;
    }
    setDeleteModal({
      isOpen: true,
      isNew: isIneligibleToDeleteNew,
      deleteRecordId: values.inventoryIneligibles[index].recordId,
      deleteName: values.inventoryIneligibles[index].description,
      deleteIndex: index,
    });
  };

  const handleConfirmDelete = async (formikValues: IInventoryIneligible[], remove: (index: number) => void) => {
    const canDelete = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.DELETE_INVENTORY_INELIGIBLES);
    if (canDelete) {
      handleNewDeleteModal(remove);

      const { hasUnsavedChanges, presentFormikValues } = getPresentFormikValues(formikValues);
      const handled: boolean = handlePresentFormikValues(hasUnsavedChanges, presentFormikValues);
      if (handled) return;

      setInventoryIneligibles(presentFormikValues);
    } else {
      setToasterMessage(NO_PERMISSION_MSG);
      setToasterSeverity('error');
      setIsToasterOpen(true);
    }
  };

  const handleNewDeleteModal = (remove: (index: number) => void) => {
    if (deleteModal.isNew) {
      remove(deleteModal.deleteIndex);
      const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
      setToasterMessage(`${itemName} has been deleted`);
      setToasterSeverity('success');
      setIsToasterOpen(true);
    }
  }

  const handlePresentFormikValues = (hasUnsavedChanges: boolean, presentFormikValues: IInventoryIneligible[]) => {
    if (hasUnsavedChanges && deleteModal.isNew) {
      setFormikValues({ inventoryIneligibles: presentFormikValues });
      return true;
    }

    if (hasUnsavedChanges && !deleteModal.isNew) {
      deleteInventoryIneligible(deleteModal.deleteRecordId);
      setFormikValues({ inventoryIneligibles: presentFormikValues });
      return true;
    }

    if (!hasUnsavedChanges && !deleteModal.isNew) {
      deleteInventoryIneligible(deleteModal.deleteRecordId, true);
      setFormikValues({ inventoryIneligibles: presentFormikValues });
      return true;
    }

    return false;
  }

  const handleCancel = (resetForm: IResetFormForInvIneligible) => {
    if (formikValues === null) { return; }
    const { savedFormikValues } = getSavedAndEmptyFormikValues(formikValues.inventoryIneligibles);
    setIsDirty(false);
    resetForm({ values: { inventoryIneligibles: savedFormikValues } });
    setInventoryIneligibles(savedFormikValues);
    setAllowAddingRow(true);
  };

  const handleSave = async (ineligiblesToSave: IInventoryIneligible[], setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);

    const ineligiblesToEdit = ineligiblesToSave.filter((ineligible) => ineligible.hasOwnProperty('recordId')).filter((ineligible) => {
      const [originalIneligible] = inventoryIneligibles.filter(currentIneligible => currentIneligible.recordId === ineligible.recordId);
      if (originalIneligible === undefined) { return false; }
      const isIneligibleNotEdited = isObjectsEqual(ineligible, originalIneligible);
      return !isIneligibleNotEdited;
    });

    // 1st part of update request (hide values to update)
    const editRequestPutConfigs = ineligiblesToEdit.map((ineligible) => {
      return axiosInstance.request({
        url: `${API_DOMAIN}/inv/ineligibles/${ineligible.recordId}`,
        method: PUT,
        data: {
          recordId: parseInt(ineligible.recordId),
          borrowerFk: ineligible.borrowerFk,
          invCollateralFk: selectedInventory.invCollateralFk,
          description: ineligible.description.trim(),
          amount: parseFloat(ineligible.amount),
          current: false,
        },
      })
    });

    // 2nd part of update request (set isCurrent to true for updatedValues)
    const editRequestPostConfigs = ineligiblesToEdit.map((ineligible) => {
      return axiosInstance.request({
        url: `${API_DOMAIN}/inv/ineligibles`,
        method: POST,
        data: {
          borrowerFk: ineligible.borrowerFk,
          invCollateralFk: selectedInventory.invCollateralFk,
          description: ineligible.description.trim(),
          amount: parseFloat(ineligible.amount),
          current: true,
        },
      })
    });

    // add new inventory ineligible
    const postRequestConfigs = ineligiblesToSave.filter(ineligible => !ineligible.hasOwnProperty('recordId')).map((ineligible) => {
      return axiosInstance.request({
        url: `${API_DOMAIN}/inv/ineligibles`,
        method: POST,
        data: {
          borrowerFk: selectedClient.recordId,
          invCollateralFk: selectedInventory.invCollateralFk,
          description: ineligible.description.trim(),
          amount: parseFloat(ineligible.amount),
          current: true,
        },
      })
    });

    const getUpdatedDescriptions = (ineligibles) => {
      return ineligibles
        .filter(ineligible => ineligible.hasOwnProperty('recordId'))
        .map(ineligible => ineligible.description.trim())
        .join(', ');
    };

    let addedName = ineligiblesToSave.filter(ineligible => !ineligible.hasOwnProperty('recordId')).map(ineligible => ineligible.description.trim())[0] || '';
    let updatedName = getUpdatedDescriptions(ineligiblesToEdit);

    const canEdit = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.UPDATE_INVENTORY_INELIGIBLES);
    const canAdd = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.ADD_INVENTORY_INELIGIBLES);

    const requestsConfigs = [...editRequestPutConfigs, ...editRequestPostConfigs, ...postRequestConfigs];
    const updatedToasterMessage = getUpdateToasterMsg(postRequestConfigs, editRequestPostConfigs, updatedName, addedName);

    if ((!canAdd && postRequestConfigs.length > 0) || (!canEdit && editRequestPostConfigs.length > 0)) {
      setToasterMessage(NO_PERMISSION_MSG);
      setToasterSeverity('error');
      setIsToasterOpen(true);
    } else {
      await Promise.all(requestsConfigs)
        .then((_response) => {
          setIsDirty(false);
          fetchInventoryIneligibles();
          setToasterMessage(updatedToasterMessage);
          setToasterSeverity('success');
          setIsToasterOpen(true);
          setSubmitting(false);
        })
        .catch((error) => console.log('INVENTORY INELIGIBLE REQUESTS ERROR: ', error));
    }
  };

  const getDisplayedError = (errors: FormikErrors<IFormikValue>, touched: FormikTouched<IFormikValue>, name: string, index: number) => {
    const fieldError = getIn(errors, `inventoryIneligibles[${index}].${name}`);
    const isFieldTouched = getIn(touched, `inventoryIneligibles[${index}].${name}`);

    if (fieldError && isFieldTouched) { return { error: true, helperText: <HelperTextComponent text={fieldError} /> }; }

    return null;
  };

  const addNewRow = (push: any) => {
    const newIneligible = { description: '', amount: '' };
    push(newIneligible);
    setAllowAddingRow(false);
  };

  const getUpdateToasterMsg = (addItems: any, updateItems: any, updatedName: string, addedName: string) => {
    const addLength = addItems.length;
    const updateLength = updateItems.length;

    if (addLength > 0 && updateLength > 0) {
      return 'Changes in Ineligible Inventory Setting have been saved';
    }
    else if (addLength > 0 && updateLength <= 0) {
      const phrase = addLength > 1 ? 'Items have been' : `${addedName} has been`;
      return `${phrase} added`;
    }
    else if (addLength <= 0 && updateLength > 0) {
      const phrase = updateLength > 1 ? 'Items have been' : `${updatedName} has been`;
      return `${phrase} updated`;
    }
    else {
      return '';
    }
  };

  const getPresentFormikValues = (formikValues: IInventoryIneligible[]) => {
    let hasUnsavedChanges = false;
    const presentFormikValues = formikValues.filter((currentValue: IInventoryIneligible, currentIndex: number) => {
      if (currentIndex === deleteModal.deleteIndex) { return false; }
      if (currentValue.recordId === deleteModal.deleteRecordId) { return false; }

      const hasNewRecord = currentValue.recordId === undefined;
      const hasUpdatedRecord = inventoryIneligibles.some(ineligible => {
        if (ineligible.recordId !== currentValue.recordId) { return false; }
        return !isObjectsEqual(currentValue, ineligible);
      });
      if (hasNewRecord || hasUpdatedRecord) { hasUnsavedChanges = true; }

      return true;
    });

    return { hasUnsavedChanges, presentFormikValues };
  };

  const getSavedAndEmptyFormikValues = (formikValuesForInvIneligibles: IInventoryIneligible[]) => {
    const [savedFormikValues, emptyFormikValues] = formikValuesForInvIneligibles.reduce((separatedFormikValues: IInventoryIneligible[][], ineligible: IInventoryIneligible) => {
      let currentSavedFormikValues = [...separatedFormikValues[0]];
      let currentEmptyFormikValues = [...separatedFormikValues[1]];

      const isIneligibleSaved = ineligible.recordId !== undefined && ineligible.recordId !== '';
      if (isIneligibleSaved) {
        const [savedIneligible] = inventoryIneligibles.filter(originalIneligible => originalIneligible.recordId === ineligible.recordId);
        savedIneligible !== undefined && currentSavedFormikValues.push(savedIneligible);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      const isIneligibleEmpty = (
        (ineligible.description === undefined || ineligible.description === '') &&
        (ineligible.amount === undefined || ineligible.amount === '')
      );
      if (isIneligibleEmpty) {
        currentEmptyFormikValues.push(ineligible);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
    }, [[], []]);

    return { savedFormikValues, emptyFormikValues };
  };

  const formikRef = (node: FormikProps<IFormikValue>) => {
    if (node === null || isLoading) { return; }
    setFormikValues(node.values);
    !isObjectsEqual(formikValues, node.values) && setIsDirty(node.dirty);
    if (!isFormikResetting) { return; }
    node.resetForm();
    setIsFormikResetting(false);
  };

  const isFieldDisabled = (index: number) => {
    return formikValues?.inventoryIneligibles[index]?.recordId === undefined ? !canAddInventoryIneligibles : !canUpdateInventoryIneligibles;
  }

  const getActionButtons = (formik: FormikProps<IFormikValue>) => {
    if (selectedInventory && selectedInventory.invCollateralName !== '') {
      return (
        <Box sx={styles.buttonsContainer}>
          {(formik.dirty) ? (
            <Button
              onClick={() => handleCancel(formik.resetForm)}
              variant='outlined'
              sx={styles.saveButton}
            >
              <Typography variant='body2' component='p'>
                Cancel
              </Typography>
            </Button>
          ) : null}
          <DisabledComponentsContainer isDisabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}>
            <Button
              disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}
              aria-label={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading ? 'Save button disabled' : 'Save'}
              variant='contained'
              sx={styles.saveButton}
              type='submit'
            >
              <Typography variant='body2' component='p'>
                Save
              </Typography>
            </Button>
          </DisabledComponentsContainer>
        </Box>
      )
    }
  }

  const getTable = (formik: FormikProps<{
    inventoryIneligibles: IInventoryIneligible[];
  }>) => {
    if (selectedInventory && selectedInventory.invCollateralName !== '') {
      return (
        <TableContainer sx={styles.tableContainer}>
          <Table sx={styles.table}>
            <TableHead>
              <TableRow sx={styles.tableHeadRow}>
                <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForDescription }}>
                  <FormLabel
                    tabIndex={0}
                    htmlFor='description'
                    sx={styles.tableHeaderText}
                  >
                    Description<span style={styles.asterisk}> *</span>
                  </FormLabel>
                </TableCell>
                <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForAmount, ...styles.rightAlignedText, }} >
                  <FormLabel
                    tabIndex={0}
                    htmlFor='ineligible-amount'
                    sx={styles.tableHeaderText}
                  >
                    Ineligible Amount<span style={styles.asterisk}> *</span>
                  </FormLabel>
                </TableCell>
                <TableCell sx={{ ...styles.tableHeadCell, ...styles.tableHeadCellForAction, ...styles.centerAlignedText, ...(!canDeleteInventoryIneligibles && styles.hidden) }}>
                  <FormLabel tabIndex={0} sx={styles.tableHeaderText} >
                    Action
                  </FormLabel>
                </TableCell>
              </TableRow>
            </TableHead>
  
            <FieldArray name='inventoryIneligibles'>
              {({ push, remove }) => (
                <TableBody sx={styles.tableBody}>
                  {formik.values.inventoryIneligibles.map((invIneligible, index) => {
                    return (
                      <TableRow sx={styles.tableRow} key={invIneligible.recordId}>
                        <TableCell sx={styles.tableCell}>
                          <Field
                            disabled={isFieldDisabled(index)}
                            id='description'
                            aria-label={`Description ${formik.values.inventoryIneligibles[index].description}`}
                            name={`inventoryIneligibles[${index}].description`}
                            as={TextField}
                            sx={styles.textField}
                            variant='outlined'
                            onChange={(event: any) => {
                              formik.handleChange(event);
                            }}
                            inputProps={{ 'aria-label': 'Description' }}
                            {...getDisplayedError(formik.errors, formik.touched, 'description', index)}
                            onBlur={(event: any) => trimOnBlur(event, formik, `inventoryIneligibles[${index}].description`, formik.values.inventoryIneligibles[index].description)}
                          />
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          <Field
                            disabled={isFieldDisabled(index)}
                            id='ineligible-amount'
                            aria-label={`Ineligible Amount ${formik.values.inventoryIneligibles[index].amount}`}
                            name={`inventoryIneligibles[${index}].amount`}
                            as={TextField}
                            sx={{ ...styles.textField, ...styles.rightAlignedText }}
                            variant='outlined'
                            onChange={(event: any) => { formik.handleChange(event); }}
                            InputProps={{ inputComponent: CurrencyFormat as any }}
                            inputProps={{ 'aria-label': 'Ineligible Amount', sx: styles.rightAlignedText }}
                            {...getDisplayedError(formik.errors, formik.touched, 'amount', index)}
                          />
                        </TableCell>
                        <TableCell align='center' sx={{ ...styles.actionTableCell, ...(!canDeleteInventoryIneligibles && styles.hidden) }}>
                          <Tooltip title='Delete the item'>
                            <IconButton
                              onClick={() => handleDelete(formik.values, index)}
                              sx={styles.deleteIconButton}
                              color='primary'
                              aria-label='Delete icon'
                            >
                              <DeleteOutlined />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* rows that adds new form rows */}
                  {selectedInventory && selectedInventory.invCollateralName !== '' && allowAddingRow && canAddInventoryIneligibles && (
                    <TableRow key={formik.values.inventoryIneligibles.length}>
                      {headers.map((header, index) => (
                        <TableCell sx={styles.tableCell} key={header}>
                          {index < headers.length - 1 ? (
                            <TextField
                              sx={styles.textField}
                              value=''
                              onClick={() => addNewRow(push)}
                              onKeyDown={(e) => { if (e.key === 'Enter') { addNewRow(push) } }}
                              inputProps={{ 'aria-label': 'Press enter to add new entry' }}
                            />
                          ) : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                  <ConfirmModal
                    open={deleteModal.isOpen}
                    onClose={() => { setDeleteModal({ ...deleteModal, isOpen: false }) }}
                    onConfirm={() => { handleConfirmDelete(formik.values.inventoryIneligibles, remove) }}
                    title={`Delete ${deleteModal.deleteName}`}
                    description='Are you sure you want to delete this item?'
                    errorButton
                    yesButtonText='Delete'
                    noButtonText='Cancel'
                  />
                </TableBody>
              )}
            </FieldArray>
          </Table>
        </TableContainer>
      )
    } else {
      return (
        <Typography tabIndex={0} sx={styles.centerAlignedText}>No data available</Typography>
      )
    }
  }

  usePrompt('You have unsaved changes. Are you sure you want to leave this page?', isDirty);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{ inventoryIneligibles }}
      validationSchema={inventoryIneligiblesSchema}
      onSubmit={(values, { setSubmitting }) => handleSave(values.inventoryIneligibles, setSubmitting)}
    >
      {
        formik => (
          <Form onSubmit={formik.handleSubmit}>
            <Box sx={styles.outmostContainer}>
              {
                isLoading ?
                <>
                  <Skeleton variant="rectangular" height={40} />
                  <Skeleton variant="rectangular" height={40} sx={{marginTop: 2}}/>
                </>
                :
                getTable(formik)
              }
              {
                isLoading ?
                <></>
                :
                getActionButtons(formik)
              }
            </Box>
            <Toaster
              open={isToasterOpen}
              message={toasterMessage}
              severity={toasterSeverity}
              onCloseChange={() => setIsToasterOpen(false)}
            />
            <LoadingBackdrop
                    isLoading={isLoading}
                  />
          </Form>
        )
      }
    </Formik>
  );
};

export default InventoryIneligiblesTable;
