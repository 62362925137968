import { AlertColor, IconButton, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Dispatch, FC, forwardRef, KeyboardEvent, SetStateAction, useEffect, useMemo, useState } from 'react';
import { IClientVariances, IRfBeginningBalance, IRfCalcSummary, IRollForwardReport, IUpcClientRollForward } from '../../../../interfaces/rollForwardReportInterface';
import { IAlignment } from '../../../../interfaces';
import styles from './styles';
import { Form, Formik, FormikProps } from 'formik';
import NumberFormat, { InputAttributes } from 'react-number-format';
import axiosInstance from '../../../../service/axiosInstance';
import { reportsAPI } from '../../../../service/api';
import { PUT } from '../../../../utility/constants';
import RollForwardTableRow from './row';
import { handleFormatCurrency } from '..';

export interface RollForwardTableProps {
  rfCalcSummary?                  : IRfCalcSummary;
  setRfCalcSummary?               : Dispatch<SetStateAction<IRfCalcSummary | undefined>>
  rollForwards                    : IRollForwardReport[];
  beginningBalance?               : IRfBeginningBalance;
  setBeginningBalance?            : Dispatch<SetStateAction<IRfBeginningBalance | undefined>>;
  showToaster                     : (severity: AlertColor, message: string) => void;
  currencyLabel                   : string;
  isUltimateParent                : boolean;
  clientVariances?                : IClientVariances;
  setClientVariances?             : Dispatch<SetStateAction<IClientVariances>>;
  handleInitialClientVariances?   : (collateralVariance: number[]) => void;
  exchangeRate                    : number;
  clientRfReport?                 : IUpcClientRollForward;
  setClientRfReport?              : Dispatch<SetStateAction<IUpcClientRollForward | undefined>>;
}

interface FormProps {
  beginningBalance: IRfBeginningBalance | undefined;
  rfCalcSummary   : IRfCalcSummary | undefined
}

interface IHeadCell {
  id        : keyof IRollForwardReport | 'leftActionButton' | 'rightActionButton';
  label     : string;
  alignment : IAlignment;
  style     : SxProps<Theme>;
  hiddenText: boolean;
}

interface INumberFormatProps {
  onChange: (event: { target: { name: string; value: number } }) => void;
  name: string;
}

const headCells: IHeadCell[] = [
  {id: 'leftActionButton', label: 'Left Action Button', alignment: 'right', style: {...styles.tableTextHeader, ...styles.tableTextHeaderLeftAction}, hiddenText: true},
  {id: 'rfCategoryName', label: 'Roll Forward Category', alignment: 'left', style: {...styles.tableTextHeader, ...styles.tableTextHeaderCategory}, hiddenText: false}, 
  {id: 'glTrxAmt', label: 'Amount', alignment: 'right', style: {...styles.tableTextHeader, ...styles.tableTextHeaderAmount}, hiddenText: false},
  {id: 'rightActionButton', label: 'Right Action Button', alignment: 'left', style: {...styles.tableTextHeader, ...styles.tableTextHeaderRightAction}, hiddenText: true},
]

/**
 * This functional component renders a custom input field for currency formatting using the `NumberFormat` library.
 */
const CurrencyFormat = forwardRef<NumberFormat<InputAttributes>, INumberFormatProps>((props, ref) => {
  const { onChange, ...other } = props;
  
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => onChange({ target: { name: other.name, value: values.floatValue ?? 0 }, })}
      thousandSeparator
      type='tel'
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
});

/**
 * Component for the Roll Forward Table of the Roll Forward Report page.
 * @param props The props for the Roll Forward Table of the Roll Forward Report page.
 * @returns A component for the Roll Forward Table of the Roll Forward Report page.
 */
const RollForwardTable: FC<RollForwardTableProps> = (props) => {
  const { rfCalcSummary, setRfCalcSummary, 
          rollForwards,
          beginningBalance, setBeginningBalance,
          showToaster, currencyLabel,
          isUltimateParent, exchangeRate,
          clientVariances, setClientVariances,
          handleInitialClientVariances,
          clientRfReport, setClientRfReport }               = props;
  const [disableBeginningBal, setDisableBeginningBal]       = useState<boolean>(true);
  const [collatRfSummary, setCollatRfSummary]               = useState<IRfCalcSummary | undefined>(rfCalcSummary);
  const [collatBeginningBalance, setCollatBeginningBalance] = useState<IRfBeginningBalance | undefined>(beginningBalance);
  const [initialized, setInitialized]                       = useState<boolean>(false);

  /**
   * This useEffect calculates the initial client variances.
   */
  useEffect(() => {
    if (isUltimateParent && rfCalcSummary) {
      const appliedRate = rfCalcSummary.variance / exchangeRate;

      if (handleInitialClientVariances && !initialized) {
        const clientId = rfCalcSummary.borrowerId;
        const arCollateralId = rfCalcSummary.arCollateralId;
        const collateralVariance = [clientId, arCollateralId, appliedRate];
        handleInitialClientVariances(collateralVariance);
        setInitialized(true);
      }
    }
  }, [isUltimateParent, rfCalcSummary, initialized])

  /**
   * This function gets the states based on being an Ultimate Parent.
   */
  const getStates = useMemo(() => {
    if (isUltimateParent) {
      return ({
        rfCalcSummary: collatRfSummary,
        setRfCalcSummary: setCollatRfSummary,
        beginningBalance: collatBeginningBalance,
        setBeginningBalance: setCollatBeginningBalance
      });
    } else {
      return ({
        rfCalcSummary,
        setRfCalcSummary: setRfCalcSummary ?? (() => null),
        beginningBalance,
        setBeginningBalance: setBeginningBalance ?? (() => null),
      });
    }
  }, [isUltimateParent, rfCalcSummary, beginningBalance, collatRfSummary, collatBeginningBalance])

  /**
   * This function handles keyboard events, specifically 'Enter' and 'Escape' key presses, within an input field.
   * @param event The keyboard event object.
   * @param formik The Formik props for the form.
   */
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, formik: FormikProps<FormProps>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSave(formik);
    }
    
    if (event.key === 'Escape') {
      event.preventDefault();
      handleCancel(formik);
    }
  };

  /**
   * This function triggers a form submission if the form is dirty (has unsaved changes) and sets a flag to disable the beginning balance.
   * @param formik The Formik props for the form.
   */
  const handleSave = (formik: FormikProps<FormProps>) => {
    setDisableBeginningBal(true);
    formik.dirty && formik.submitForm();
  };

  /**
   * This function cancels any changes made in the form by resetting the form to its initial state and setting a flag to disable the beginning balance.
   * @param formik The Formik props for the form.
   */
  const handleCancel = (formik: FormikProps<FormProps>) => {
    setDisableBeginningBal(true);
    formik.resetForm();
  };

  /**
   * This function submits form values to update the beginning balance, handles the response, and displays a toaster notification accordingly.
   * @param values The form values containing the beginning balance data.
   */
  const handleSubmit = async (values: FormProps) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.rollForwardReport.BEGINNING_BALANCE_ENDPOINT,
        method: PUT,
        params: { rfBeginningBalanceId: values.beginningBalance?.rfBeginningBalanceId },
        data: {...values.beginningBalance, amount: values.beginningBalance?.amount ? values.beginningBalance?.amount : 0}
      });
      const updated: IRfBeginningBalance = response.data;
      getStates.setBeginningBalance(updated);

      if (values.rfCalcSummary) {
        const endingBalance = updated.amount + (values.rfCalcSummary.rollForwardBalance ?? 0);
        const variance = endingBalance - (values.rfCalcSummary.balanceFromAging ?? 0);

        const updatedSummary: IRfCalcSummary | undefined = {
          ...values.rfCalcSummary,
          beginningBalance: updated.amount,
          endingBalance,
          variance
        };
        getStates.setRfCalcSummary(updatedSummary);

        const borrowerId = updatedSummary.borrowerId;
        const arCollateralId = updatedSummary.arCollateralId;

        if (clientVariances && setClientVariances) {
          const updatedVariances = [...clientVariances].filter((clientVariance: number[]) => clientVariance[1] !== arCollateralId);
          updatedVariances.push([borrowerId, arCollateralId, variance / exchangeRate]);
          setClientVariances(updatedVariances);
        }

        if (clientRfReport && setClientRfReport) {
          const shallowRfReport = {...clientRfReport};
          shallowRfReport[updated.borrowerId][updated.arCollateralId].beginningBalance = updated;
          shallowRfReport[updated.borrowerId][updated.arCollateralId].rfCalcSummary = updatedSummary;
          setClientRfReport(shallowRfReport);
        }
      }

      showToaster('success', 'Successfully updated beginning balance!');
    } catch (error) {
      console.log('UPDATE BEGINNING BALANCE ERROR: ', error);
      showToaster('error', 'Error updating beginning balance!');
    }
  };
  
  return (
    <TableContainer sx={styles.tableContainer}>
      <Formik
        enableReinitialize
        initialValues={{beginningBalance: getStates.beginningBalance, rfCalcSummary: getStates.rfCalcSummary}}
        onSubmit={async (values) => await handleSubmit(values)}
      >
        {formik => (
          <Form>
            <Table aria-label='Roll Forward Report Table' size='small'>
              <TableHead sx={styles.tableHeader}>
                <TableRow>
                {headCells.map((headCell: IHeadCell) =>
                  <TableCell key={headCell.id} sx={headCell.style}>
                    <Typography
                      tabIndex={0}
                      sx={headCell.hiddenText ?
                        {...styles.tableTextHeaderTypography, visibility: 'hidden'} :
                        styles.tableTextHeaderTypography
                      }
                    >
                      {headCell.label}
                    </Typography>
                  </TableCell>
                )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={styles.tableRowSpacer}></TableRow>
                <TableRow>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyLeftAction}}>
                  </TableCell>
                  <TableCell tabIndex={0} sx={{...styles.tableTextBody, ...styles.tableTextBodyCategory}}>
                    Beginning Balance
                  </TableCell>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyAmount}}>
                    {disableBeginningBal ? 
                      <TextField
                        tabIndex={0}
                        id='rf-calc-summary-beginning-balance'
                        defaultValue={handleFormatCurrency(formik.values.beginningBalance?.amount ?? 0, currencyLabel, exchangeRate)}
                        disabled={true}
                        size='small'
                        inputProps={{
                          sx: styles.amtTextFieldInput,
                          'data-testid': 'beginning-balance-disabled-field',
                          'aria-label': `Beginning Balance ${handleFormatCurrency(formik.values.beginningBalance?.amount ?? 0, currencyLabel, exchangeRate)}`,
                        }}
                        fullWidth
                      /> :
                      <TextField
                        id='rf-calc-summary-beginning-balance'
                        name='beginningBalance.amount'
                        value={formik.values.beginningBalance?.amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.beginningBalance && Boolean(formik.errors.beginningBalance)}
                        helperText={formik.touched.beginningBalance && formik.errors.beginningBalance}
                        disabled={disableBeginningBal}
                        size='small'
                        fullWidth
                        inputProps={{
                          'data-testid': 'beginning-balance-field',
                          sx: styles.amtTextFieldInput,
                        }}
                        InputProps={{
                          inputComponent: CurrencyFormat as any,
                        }}
                        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => handleKeyDown(event, formik)}
                      />}
                  </TableCell>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyRightAction}}>
                    {disableBeginningBal ? 
                      <IconButton aria-label='Edit icon' sx={styles.editButton} onClick={() => setDisableBeginningBal(false)} data-testid='edit-beginning-balance'>
                        <EditIcon sx={styles.editIcon} color='primary' />
                      </IconButton> :
                      <>
                      <IconButton aria-label='Success icon' sx={styles.editButton} onClick={() => handleSave(formik)} data-testid='save-beginning-balance'>
                        <CheckIcon sx={styles.editIcon} color='success' />
                      </IconButton>
                      <IconButton aria-label='Error icon' sx={styles.editButton} onClick={() => handleCancel(formik)} data-testid='cancel-beginning-balance'>
                        <ClearIcon sx={styles.editIcon} color='error' />
                      </IconButton>
                      </>}
                  </TableCell>
                </TableRow>
              {rollForwards.map((rollForward: IRollForwardReport) =>
                <RollForwardTableRow
                  key={rollForward.rfCategoryId}
                  rollForward={rollForward}
                  {...props}
                />
              )}
                <TableRow>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyLeftAction}}>
                  </TableCell>
                  <TableCell tabIndex={0} sx={{...styles.tableTextBody, ...styles.tableTextBodyCategory}}>
                    Ending Balance
                  </TableCell>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyAmount}}>
                    <TextField
                      tabIndex={0}
                      id='rf-calc-summary-ending-balance'
                      value={handleFormatCurrency(formik.values.rfCalcSummary?.endingBalance ?? 0, currencyLabel, exchangeRate)}
                      disabled={true}
                      size='small'
                      inputProps={{
                        sx: styles.amtTextFieldInput,
                        'aria-label': `Ending Balance ${handleFormatCurrency(formik.values.rfCalcSummary?.endingBalance ?? 0, currencyLabel, exchangeRate)}`
                      }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyRightAction}}>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyLeftAction}}>
                  </TableCell>
                  <TableCell tabIndex={0} sx={{...styles.tableTextBody, ...styles.tableTextBodyCategory}}>
                    AR Balance from Aging
                  </TableCell>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyAmount}}>
                    <TextField
                      tabIndex={0}
                      id='rf-calc-summary-balance-from-aging'
                      value={handleFormatCurrency(formik.values.rfCalcSummary?.balanceFromAging ?? 0, currencyLabel, exchangeRate)}
                      disabled={true}
                      size='small'
                      inputProps={{
                        sx: styles.amtTextFieldInput,
                        'aria-label': `AR Balance from Aging ${handleFormatCurrency(formik.values.rfCalcSummary?.balanceFromAging ?? 0, currencyLabel, exchangeRate)}`,
                      }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyRightAction}}>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyLeftAction}}>
                  </TableCell>
                  <TableCell tabIndex={0} sx={{...styles.tableTextBody, ...styles.tableTextBodyCategory}}>
                    Variance
                  </TableCell>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyAmount}}>
                    <TextField
                      tabIndex={0}
                      id='rf-calc-summary-variance'
                      value={handleFormatCurrency(formik.values.rfCalcSummary?.variance ?? 0, currencyLabel, exchangeRate)}
                      disabled={true}
                      size='small'
                      inputProps={{
                        sx: styles.amtTextFieldInput,
                        'aria-label': `${handleFormatCurrency(formik.values.rfCalcSummary?.variance ?? 0, currencyLabel, exchangeRate)}`,
                      }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell sx={{...styles.tableTextBody, ...styles.tableTextBodyRightAction}}>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Form>
        )}
      </Formik>
      
    </TableContainer>
  )
}

export default RollForwardTable