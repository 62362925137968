import { textAlign, width } from "@mui/system";

const styles = {
  boxContainer1: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    paddingY: '24.2px',
    marginY: '1rem',
    paddingX: '32.62px',
  },
  title: {
    fontSize: '18px',
    fontWeight: "bold",
    color: "#010953"
  },
  outmostContainer: {
    backgroundColor: '#FFFFFF',
  },

  tableContainer: {
    '&.MuiTableContainer-root': {
      px: '6px', // space offset for tableHeadRow's boxShadow
      width: 'auto',
    },
  },
  table: {
    '&.MuiTable-root': {
      minWidth: '1046px',
    },
  },
  tableBody: {
    '&.MuiTableBody-root::before': {
      display: 'block',
      content: '""',
      height: '8px',
    },
  },
  tableHeadRow: {
    '&.MuiTableRow-root': {
      backgroundColor: '#F7F7F7',
      boxShadow: '0px 3px 6px #00000029',
    },
  },
  tableHeadCell: {
    '&.MuiTableCell-head': {
      fontWeight: 'bold',
      pl: '1rem',
      pr: '1.33rem',
    },
    '& div': { 
      display: 'flex', 
      flexDirection: 'row',
      whiteSpace: 'nowrap'
    }
  },
  filterGridContainer: {
		padding: '1rem'
	},
  filterChildBoxMargin: {
		margin: '1rem',
	},
  optionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start !important'
  },
  dateRangeBtn: {
    height: '2.2rem',
    whiteSpace: 'nowrap',
    border: 1,
    backgroundColor: 'white'
  },
  searchStyle: {
    alignSelf: 'center'
  },
  searchField: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
    width: '100%'
  },
  tablePaginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  iconDefaultSort: {
    color: '#767272',
    fontSize: '16px'
  },
  headerButtons: {
    height: '2.3rem',
    whiteSpace: 'nowrap'
  },
  refreshBtn: {
    height: '40px'
  },
  tableRow: {
    '&:nth-of-type(even)': {
      '&:hover': {
        backgroundColor: '#F7F7F7',
        color: 'unset',
        textDecoration: 'none'
      }
    },
    '&:nth-of-type(odd)': {
      '&:hover': {
        backgroundColor: 'unset',
        color: 'unset',
        textDecoration: 'none'
      }
    }
  },
  dateRangeSelection: {
    backgroundColor: 'white',
    height: '2.3rem',
    width: '12rem',
    font: 'inherit'
  },
  emptyRecord: {
    textAlign: 'center',
    width: '100%'
  }
};

export default styles;