import { Box, Typography, TextField, Button, AlertColor } from "@mui/material";
import { ErrorMessage, Field, Formik, FormikHelpers, FormikValues } from "formik";
import forgotPasswordSchema from "../../../schemas/forgotPasswordSchema";
import styles from "./styles";
import axiosInstance from "../../../service/axiosInstance";
import api from "../../../service/api";
import { GET, POST } from "../../../utility/constants";
import Toaster from "../../toaster";
import { FC, useState } from "react";
import InlineError from "../../../pages/sign-in/inline-error";
import { useNavigate } from "react-router-dom";

const initialValues = {
  email: ''
};

interface IUser {
  email: string,
  firstName: string,
  lastName: string,
  userId: string,
  host: string
}

/**
 * Component for showing the Reset Password Form.
 */
const ResetPasswordForm: FC = () => {
  const navigate                              = useNavigate();
  const [toasterOpen, setToasterOpen]         = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]   = useState<string>('');
  const [toasterSeverity, setToasterSeverity] = useState<AlertColor>('success');

  /**
   * This function calls the API endpoint for creating a public access token that can be used to create a new user.
   * @returns The public access token.
   */
  const createPublicAccessToken = async () => {
    try {
      const response = await axiosInstance.request({
        url: api.CREATE_PUBLIC_TOKEN,
        method: GET
      })
      const token : string = response.data.access_token;
      return token;
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * This function calls the API endpoint for finding a user by their email.
   * @param email The email of the user.
   * @param token The public access token.
   * @returns The queried user.
   */
  const getUserByEmail = async (email : string, token : string) => {
    try {
      const response = await axiosInstance.request({
        url: api.USER_BY_EMAIL,
        method: GET,
        params: { email: email},
        headers: {
          token: token
        }
      })
      const user : IUser = {
        firstName : response.data.firstName,
        lastName : response.data.lastName,
        email : email,
        userId : response.data.id,
        host: window.location.origin
      }
      return user;
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * This function calls the API endpoint for sending a reset password email.
   * @param email The email of the user.
   */
  const sendResetPasswordEmail = async (email: string, action: FormikHelpers<{ email: string; }>) => {
    const {setErrors} = action;

    try {
      const token = await createPublicAccessToken();
      if (token !== undefined) {
        const user = await getUserByEmail(email, token);
        if (user) {
          const response = await axiosInstance.request({
            url: api.RESET_PASSWORD_EMAIL,
            method: POST,
            data: user,
          })
          if (response.data === true) {
            navigate('/forgot-password/request-success?email=' + email);
          } else {
            setToasterOpen(true);
            setToasterSeverity('error');
            setToasterMessage('There has been an error in processing your request. Please try again.');
          }
        } else {
          throw new Error("Email not registered.")
        }    
      }
    } catch (e : any) {
      console.log(e)
      e.message === 'Email not registered.' && setErrors({
        email: 'Email Address is not yet registered.'
      })
    }
  }

  /**
   * This function handles the submission of the reset password form.
   * @param values The values of the form.
   */
  const handleSubmit = (values : FormikValues, action: FormikHelpers<{ email: string; }>) => {
    sendResetPasswordEmail(values.email, action);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={forgotPasswordSchema}
      onSubmit={(values, action) => {handleSubmit(values, action)}}
    >
      {
        ({values, errors, touched, isValid, dirty, handleChange, handleBlur, submitForm}) => (
          <Box>
            <Toaster
              open={toasterOpen}
              message={toasterMessage}
              severity={toasterSeverity}
              onCloseChange={() => setToasterOpen(false)}
            />
            <Typography tabIndex={0} sx={styles.labelText}>
              Enter your email address
            </Typography>
            <Field
              as={TextField}
              margin="normal"
              id="email"
              name="email"
              value={values.email}
              error={touched.email && Boolean(errors.email)}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={styles.inputField}
              inputProps={{ 'data-testid': 'forgot-pass-email', 
                            'aria-label':'Email Address',
              }}
            />
            <ErrorMessage
              name='email' 
              render={msg => (
                msg ? 
                <InlineError errorMessage={msg} /> : <></>
              )}
            />
            <Box sx={styles.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                aria-label={`Send ${!(isValid && dirty) ? 'disabled':'Send'}`}
                sx={{...styles.loginButton, backgroundColor:!(isValid && dirty) ? styles.disabledLoginButton : '#154A8A'}}
                tabIndex={0}
                onClick={()=> { if(isValid && dirty) submitForm()}}
              >
                SEND
              </Button>
            </Box>
          </Box>
        )
      }
    </Formik>
  );
};

export default ResetPasswordForm;