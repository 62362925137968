const styles = {
  previousPageIcon: {
    '&:not(.Mui-disabled)': {
      color: '#154A8A', 
    },
  },
  nextPageIcon: {
    '&:not(.Mui-disabled)': {
      color: '#154A8A', 
    },
  }
};

export default styles;
