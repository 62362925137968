import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { formatCurrency, formatPercentage } from '../../../../utility/helper';
import { BorrowingBaseReportContext, IBorrowingBaseReportContext } from '../../../../context/borrowingBaseReportContext';
import TableWithLeftPinnedHeader from '../../../common/table-with-left-pinned-header';
import styles from '../grid-details/styles';
import { IReportsContext, ReportsContext } from '../../../../context/reportsContext';

const InventoryBBListTable = () => {
  const { invBorrowingBaseList } = React.useContext(BorrowingBaseReportContext) as IBorrowingBaseReportContext;

  const {
    selectedClient: client,
  } = React.useContext(ReportsContext) as IReportsContext;

  const [inventoryBorrowingBaseList, setInventoryBorrowingBaseList] = useState(invBorrowingBaseList);

  React.useEffect(() => {
    setInventoryBorrowingBaseList(invBorrowingBaseList);
  }, [invBorrowingBaseList]);

  return (
    (inventoryBorrowingBaseList !== null && inventoryBorrowingBaseList.length > 0) ? (
      <TableWithLeftPinnedHeader
        columns={[
          { field: 'collateralName', headerName: 'Collateral Name' },
          { field: 'collateralBalance', headerName: 'Collateral Balance' },
          { field: 'lessCollateralIneligible', headerName: 'Less Collateral Ineligibles' },
          { field: 'eligibleCollateral', headerName: 'Eligible Collateral' },
          { field: 'advanceRate', headerName: 'Advance Rate' },
          { field: 'grossAvailable', headerName: 'Gross Available' },
          { field: 'collateralCaps', headerName: 'Collateral Caps' },
          { field: 'netAvailable', headerName: 'Net Available' },
          { field: 'suppressedAvailability', headerName: 'Suppressed Availability' },
          { field: 'effectiveAdvanceRate', headerName: 'Effective Advance Rate' },
          { field: 'inventory', headerName: 'Inventory' },
        ]}
        rows={
          inventoryBorrowingBaseList.map((row, index) => ({
            collateralName: <div title={row.invCollateralName}>{row.invCollateralName}</div>,
            collateralBalance: `${formatCurrency(row.collateralBalance)}`,
            lessCollateralIneligible: `${formatCurrency(row.lessCollateralIneligible)}`,
            eligibleCollateral: `${formatCurrency(row.eligibleCollateral)}`,
            advanceRate: formatPercentage(row.advRate, 2),
            grossAvailable: `${formatCurrency(row.grossAvail)}`,
            collateralCaps: `${formatCurrency(row.collateralCaps)}`,
            netAvailable: `${formatCurrency(row.netAvail)}`,
            suppressedAvailability: `${formatCurrency(row.suppressedAvail)}`,
            effectiveAdvanceRate: formatPercentage(row.effectiveAdvRate, 2),
            inventory: (
              <Tooltip title='Go to Inventory Report'>
                <IconButton tabIndex={-1} data-testid={`invBBList-inventory-redirect-${index}`} sx={styles.linkButton} onClick={() =>{
                  if(client?.recordId === undefined){
                    return;
                  }       
                  window.open(`/reports/inventory?clientId=${client.recordId}&invCollateralId=${row.invCollateralId}`)} 
                  }>
                  <OpenInNewOutlinedIcon sx={styles.linkIcon} />
                </IconButton>
              </Tooltip>
            ),
          }))
        }
        styles={{
          tableHeadCell: styles.tableHeadCell,
          tableCell: styles.tableCell,
          tableBody: styles.tableBody,
          tableHead: styles.tableHead,
        }}
      />
    ) : <></>
  );
};

export default InventoryBBListTable;
