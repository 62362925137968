import { getUploadedARAgingDocuments } from "../../components/ineligible-settings/collateral-header/ar-collateral-header";
import { getUploadedARAgingDocuments as getUploadedARAgingDocumentsForUPC } from "../../components/ineligible-settings/collateral-header/upc-collateral-header";
import { IARCollateral, IARCustomer, IBBPeriod, IClient } from "../../interfaces";
import { IDocumentAttributePayload } from "../../interfaces/dataMap";
import { FieldList, FieldTable, FieldValue, IARCustomerByContras, IARVendorByContras, IIneligibleRuleOverride, IIneligibleSettingAPI, IUPCIneligibleSettingAPI, IneligibleRuleAPI, IneligibleRuleConditionAPI, IneligibleRuleConditionGroupAPI, IneligibleRuleOverrideAPI, UPCIneligibleRuleAPI, UserDefinedFieldList } from "../../interfaces/ineligibleSettingInterface";
import { ICurrency } from "../../interfaces/multiCurrencyInterface";
import { arCustomersAPI } from "../../service/api";
import axiosInstance from "../../service/axiosInstance";
import { API_DOMAIN, DELETE, GET, NON_EXISTING, NON_EXISTING_PERCENT, NOT_APPLICABLE, POST, PUT } from "../../utility/constants";
import { getParentCustomerByParentBorrowerId, getFieldMatchingLabel, getFieldMatchingValue, validateObjects, getDocumentAttributesByBorrowerId, checkHasPaymentTermsRequest, getRuleConditionFieldListByParentId, getRuleConditionValueListByParentId, checkHasPaymentTermsOnParentBorrowerRequest, getBBPeriodWithTransactionsRequestByARCollateralId, getAvailableUpcDatesForCalc, formatDate } from "../../utility/helper";

/**
 * This function retrieves the available as of dates for the selected collateral.
 */
export const getAsOfDates= async (selectedClient: Required<IClient>, selectedCollateral: IARCollateral) => {
  const response = await axiosInstance.request({
    url   : `${API_DOMAIN}/bbPeriods/search/findByBorrowerId`,
    method: GET,
    params: {borrowerId: selectedClient.recordId, pageNo: 0, pageSize: 99999, isDefault: true}
  });
  const asOfDates: Required<IBBPeriod>[] = response.data.content;
  const bbPeriodsWithTransactionsResponse = await getBBPeriodWithTransactionsRequestByARCollateralId(selectedCollateral.recordId);
  const bbPeriodsWithTransactions: number[] = bbPeriodsWithTransactionsResponse.data;
  return asOfDates.filter(asOfDate => bbPeriodsWithTransactions.includes(asOfDate.recordId));
};

export const getUPCAsOfDates = async (selectedClient: Required<IClient>) => {
  const response = await getAvailableUpcDatesForCalc(selectedClient.recordId);
  if (response === undefined) { return []; }
  return response.map(date => ({
    borrowerId: NON_EXISTING,
    fiscalPeriod: NON_EXISTING,
    fiscalYear  : NON_EXISTING,
    periodName  : '',
    endDate     : date.recordId.toString()
  })) as IBBPeriod[];
};

export const getMostRecentlyUpdatedAsOfDate = async (selectedCollateral: IARCollateral, asOfDates: IBBPeriod[]) => {
  if (asOfDates.length === 0) { return null; }
  const [mostRecentUploadedARAging] = await getUploadedARAgingDocuments(selectedCollateral);
  const mostRecentUpdatedAsOfDate = asOfDates.reduce((mostRecentUpdatedAsOfDate, currentAsOfDate) => {
    if (mostRecentUploadedARAging !== undefined && mostRecentUploadedARAging.bbPeriodFk === currentAsOfDate.recordId) { return currentAsOfDate; }
    if (mostRecentUploadedARAging !== undefined) { return mostRecentUpdatedAsOfDate; }
    if (new Date(currentAsOfDate.updatedAt ?? '').getTime() > new Date(mostRecentUpdatedAsOfDate.updatedAt ?? '').getTime()) { return currentAsOfDate; }
    return mostRecentUpdatedAsOfDate;
  });
  return mostRecentUpdatedAsOfDate;
};

export const getMostRecentlyUpdatedUPCAsOfDate = async (selectedClient: Required<IClient>, asOfDates: IBBPeriod[]) => {
  if (asOfDates.length === 0) { return null; }
  const [mostRecentUploadedARAging] = await getUploadedARAgingDocumentsForUPC(selectedClient.recordId);
  const mostRecentUpdatedAsOfDate = asOfDates.reduce((mostRecentUpdatedAsOfDate, currentAsOfDate) => {
    if (mostRecentUploadedARAging !== undefined && formatDate(mostRecentUploadedARAging.endDate, 'YYYYMMDD') === currentAsOfDate.endDate) { return currentAsOfDate; }
    if (mostRecentUploadedARAging !== undefined) { return mostRecentUpdatedAsOfDate; }
    return mostRecentUpdatedAsOfDate;
  });
  return mostRecentUpdatedAsOfDate;
};

/**
  * This function retrieves a list of ineligible settings based on selected client and collateral.
  * @param selectedClient The selected client.
  * @param selectedCollateral The selected collateral.
  * @returns A sorted list of ineligible settings.
  */
export const getIneligibleSettings = async (selectedClient: Required<IClient>, selectedCollateral: IARCollateral) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/search/findByBorrowerIdAndARCollateralId`,
    method: GET,
    params: { borrowerId: selectedClient.recordId, arCollateralId: selectedCollateral.recordId },
  });
  const clientIneligibleSettings = response.data as IIneligibleSettingAPI[];
  return clientIneligibleSettings.sort((a, b) => a.order - b.order);
};

/**
  * This function retrieves a list of ineligible settings based on selected client.
  * @param selectedClient The selected client.
  * @returns A sorted list of ineligible settings.
 */
export const getUPCIneligibleSettings = async (selectedClient: Required<IClient>) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/search/findByBorrowerId`,
    method: GET,
    params: { borrowerId: selectedClient.recordId },
  });
  const clientIneligibleSettings = response.data as IUPCIneligibleSettingAPI[];
  return clientIneligibleSettings.sort((a, b) => a.order - b.order);
};

/**
 * This function updates an existing ineligible setting.
 * @param values The updated ineligible setting values.
 */
export const putIneligibleSetting = async (values: IIneligibleSettingAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/${values.ineligibleSettingId}`,
    method: PUT,
    data: {
      ineligibleSettingId: values.ineligibleSettingId,
      borrowerId: values.borrowerId,
      arCollateralId: values.arCollateralId,
      code: values.code,
      description: values.description,
      order: values.order,
      disabled: values.disabled,
    },
  });
  return response.data as IIneligibleSettingAPI;
};

/**
 * This function updates an existing ineligible setting.
 * @param values The updated ineligible setting values.
 */
export const putUPCIneligibleSetting = async (values: IUPCIneligibleSettingAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/${values.ineligibleSettingId}`,
    method: PUT,
    data: {
      ineligibleSettingId: values.ineligibleSettingId,
      borrowerId: values.borrowerId,
      code: values.code,
      description: values.description,
      order: values.order,
      disabled: values.disabled,
    },
  });
  return response.data as IUPCIneligibleSettingAPI;
};

/**
  * This function retrieves ineligible rules for a given ineligible setting and collateral.
  * @param selectedCollateral The selected collateral.
  * @param ineligibleSetting The ineligible setting of the rules to fetched.
  * @returns An array of mapped ineligible rules.
  */
export const getIneligibleRule = async (selectedCollateral: IARCollateral, ineligibleSetting: IIneligibleSettingAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleRule/search/findByIneligibleSettingIdAndArCollateralId`,
    method: GET,
    params: {
      ineligibleSettingId: ineligibleSetting.ineligibleSettingId,
      arCollateralId: selectedCollateral.recordId,
      pageNo: 0, pageSize: 1
    },
  });
  const ineligibleRule = ( response.data.content as IneligibleRuleAPI[] )[0];
  if (ineligibleRule === undefined) { return null; }
  return {
    ...ineligibleRule,
    dpid: ineligibleRule.dpid === undefined && ineligibleRule.dpdd !== undefined ? NOT_APPLICABLE : (ineligibleRule.dpid ?? NON_EXISTING),
    dpdd: ineligibleRule.dpdd === undefined && ineligibleRule.dpid !== undefined ? NOT_APPLICABLE : (ineligibleRule.dpdd ?? NON_EXISTING),
    crossAgePct: ineligibleRule.crossAgePct !== undefined ? ineligibleRule.crossAgePct * 100 : NON_EXISTING_PERCENT,
    concentrationPct: ineligibleRule.concentrationPct !== undefined ? ineligibleRule.concentrationPct * 100 : NON_EXISTING_PERCENT,
    creditLimit: ineligibleRule.creditLimit !== undefined ? ineligibleRule.creditLimit : NON_EXISTING,
    ...(ineligibleRule.holdType !== undefined && { holdType: ineligibleRule.holdType }),
    ...(ineligibleRule.capType !== undefined && { capType: ineligibleRule.capType }),
    ...(ineligibleRule.capValue !== undefined && ineligibleRule.capType !== undefined && { capValue: (ineligibleRule.capType === 'Amount Limit' ? ineligibleRule.capValue : ineligibleRule.capValue * 100) }),
  } as IneligibleRuleAPI;
};

/**
  * This function retrieves ineligible rules for a given ineligible setting and client.
  * @param selectedClient The selected client.
  * @param ineligibleSetting The ineligible setting of the rules to fetched.
  * @returns An array of mapped ineligible rules.
  */
export const getUPCIneligibleRule = async (selectedClient: IClient, ineligibleSetting: IUPCIneligibleSettingAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleRule/search/findByIneligibleSettingIdAndBorrowerId`,
    method: GET,
    params: {
      ineligibleSettingId: ineligibleSetting.ineligibleSettingId,
      borrowerId: selectedClient.recordId
    },
  });
  const ineligibleRule = ( response.data as UPCIneligibleRuleAPI[] )[0];
  if (ineligibleRule === undefined) { return null; }
  return {
    ...ineligibleRule,
    dpid: ineligibleRule.dpid === undefined && ineligibleRule.dpdd !== undefined ? NOT_APPLICABLE : (ineligibleRule.dpid ?? NON_EXISTING),
    dpdd: ineligibleRule.dpdd === undefined && ineligibleRule.dpid !== undefined ? NOT_APPLICABLE : (ineligibleRule.dpdd ?? NON_EXISTING),
    crossAgePct: ineligibleRule.crossAgePct !== undefined ? ineligibleRule.crossAgePct * 100 : NON_EXISTING_PERCENT,
    concentrationPct: ineligibleRule.concentrationPct !== undefined ? ineligibleRule.concentrationPct * 100 : NON_EXISTING_PERCENT,
    creditLimit: ineligibleRule.creditLimit !== undefined ? ineligibleRule.creditLimit : NON_EXISTING,
    ...(ineligibleRule.holdType !== undefined && { holdType: ineligibleRule.holdType }),
    ...(ineligibleRule.capType !== undefined && { capType: ineligibleRule.capType }),
    ...(ineligibleRule.capValue !== undefined && ineligibleRule.capType !== undefined && { capValue: (ineligibleRule.capType === 'Amount Limit' ? ineligibleRule.capValue : ineligibleRule.capValue * 100) }),
  } as UPCIneligibleRuleAPI;
};

/**
  * This function posts a new ineligible rule.
  * @param selectedCollateral The selected collateral.
  * @param values The values for the new ineligible rule.
  * @param ineligibleSetting The ineligible setting associated with the rule.
  * @returns The created ineligible rule or null on error.
  */
export const postIneligibleRule = async (selectedCollateral: IARCollateral, values: IneligibleRuleAPI, ineligibleSetting: IIneligibleSettingAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleRule/`,
    method: POST,
    data: {
      ineligibleSettingId: ineligibleSetting.ineligibleSettingId,
      arCollateralId: selectedCollateral.recordId,
      includeCredit: true,
      capAr: ineligibleSetting.code === 'CN' ? values.capAr : !['DLQ', 'AC'].includes(ineligibleSetting.code),
      arGtZero: ineligibleSetting.code === 'CN' ? values.arGtZero : !['DLQ', 'AC'].includes(ineligibleSetting.code),
      childCustomerOnly: values.childCustomerOnly,
      ...((values.dpid !== NON_EXISTING && values.dpid !== NOT_APPLICABLE && values.dpid !== undefined) && { dpid: values.dpid }),
      ...((values.dpdd !== NON_EXISTING && values.dpdd !== NOT_APPLICABLE && values.dpdd !== undefined) && { dpdd: values.dpdd }),
      ...((values.crossAgePct !== NON_EXISTING_PERCENT && values.crossAgePct !== undefined) && { crossAgePct: values.crossAgePct / 100 }),
      ...((values.concentrationPct !== NON_EXISTING_PERCENT && values.concentrationPct !== undefined) && { concentrationPct: values.concentrationPct / 100 }),
      ...((values.holdType !== undefined) && { holdType: values.holdType }),
      ...((values.creditLimit !== NON_EXISTING && values.creditLimit !== undefined) && { creditLimit: values.creditLimit }),
      ...((values.capType !== undefined) && { capType: values.capType }),
      ...(((values.capType === 'Amount Limit' ? values.capValue !== NON_EXISTING : values.capValue !== NON_EXISTING_PERCENT) && values.capValue !== undefined && values.capType !== undefined) && {
        capValue: (values.capType === 'Amount Limit' ? values.capValue : values.capValue / 100)
      }),
    },
  });
  const ineligibleRule = response.data as IneligibleRuleAPI;
  return {
    ...ineligibleRule,
    dpid: ineligibleRule.dpid === undefined && ineligibleRule.dpdd !== undefined ? NOT_APPLICABLE : ineligibleRule.dpid,
    dpdd: ineligibleRule.dpdd === undefined && ineligibleRule.dpid !== undefined ? NOT_APPLICABLE : ineligibleRule.dpdd,
    ...(ineligibleRule.crossAgePct !== undefined && { crossAgePct: ineligibleRule.crossAgePct * 100 }),
    ...(ineligibleRule.concentrationPct !== undefined && { concentrationPct: ineligibleRule.concentrationPct * 100 }),
    ...(ineligibleRule.holdType !== undefined && { holdType: ineligibleRule.holdType }),
    ...(ineligibleRule.creditLimit !== undefined && { creditLimit: ineligibleRule.creditLimit }),
    ...(ineligibleRule.capType !== undefined && { capType: ineligibleRule.capType }),
    ...(ineligibleRule.capValue !== undefined && ineligibleRule.capType !== undefined && { capValue: (ineligibleRule.capType === 'Amount Limit' ? ineligibleRule.capValue : ineligibleRule.capValue * 100) }),
  } as IneligibleRuleAPI;
};

/**
  * This function posts a new ineligible rule.
  * @param values The values for the new ineligible rule.
  * @param ineligibleSetting The ineligible setting associated with the rule.
  * @returns The created ineligible rule or null on error.
  */
export const postUPCIneligibleRule = async (values: UPCIneligibleRuleAPI, ineligibleSetting: IUPCIneligibleSettingAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleRule/`,
    method: POST,
    data: {
      ineligibleSettingId: ineligibleSetting.ineligibleSettingId,
      includeCredit: true,
      capAr: ineligibleSetting.code === 'CN' ? values.capAr : !['DLQ', 'AC'].includes(ineligibleSetting.code),
      arGtZero: ineligibleSetting.code === 'CN' ? values.arGtZero : !['DLQ', 'AC'].includes(ineligibleSetting.code),
      childCustomerOnly: values.childCustomerOnly,
      ...((values.dpid !== NON_EXISTING && values.dpid !== NOT_APPLICABLE && values.dpid !== undefined) && { dpid: values.dpid }),
      ...((values.dpdd !== NON_EXISTING && values.dpdd !== NOT_APPLICABLE && values.dpdd !== undefined) && { dpdd: values.dpdd }),
      ...((values.crossAgePct !== NON_EXISTING_PERCENT && values.crossAgePct !== undefined) && { crossAgePct: values.crossAgePct / 100 }),
      ...((values.concentrationPct !== NON_EXISTING_PERCENT && values.concentrationPct !== undefined) && { concentrationPct: values.concentrationPct / 100 }),
      ...((values.holdType !== undefined) && { holdType: values.holdType }),
      ...((values.creditLimit !== NON_EXISTING && values.creditLimit !== undefined) && { creditLimit: values.creditLimit }),
      ...((values.capType !== undefined) && { capType: values.capType }),
      ...(((values.capType === 'Amount Limit' ? values.capValue !== NON_EXISTING : values.capValue !== NON_EXISTING_PERCENT) && values.capValue !== undefined && values.capType !== undefined) && {
        capValue: (values.capType === 'Amount Limit' ? values.capValue : values.capValue / 100)
      }),
    },
  });
  const ineligibleRule = response.data as UPCIneligibleRuleAPI;
  return {
    ...ineligibleRule,
    dpid: ineligibleRule.dpid === undefined && ineligibleRule.dpdd !== undefined ? NOT_APPLICABLE : ineligibleRule.dpid,
    dpdd: ineligibleRule.dpdd === undefined && ineligibleRule.dpid !== undefined ? NOT_APPLICABLE : ineligibleRule.dpdd,
    ...(ineligibleRule.crossAgePct !== undefined && { crossAgePct: ineligibleRule.crossAgePct * 100 }),
    ...(ineligibleRule.concentrationPct !== undefined && { concentrationPct: ineligibleRule.concentrationPct * 100 }),
    ...(ineligibleRule.holdType !== undefined && { holdType: ineligibleRule.holdType }),
    ...(ineligibleRule.creditLimit !== undefined && { creditLimit: ineligibleRule.creditLimit }),
    ...(ineligibleRule.capType !== undefined && { capType: ineligibleRule.capType }),
    ...(ineligibleRule.capValue !== undefined && ineligibleRule.capType !== undefined && { capValue: (ineligibleRule.capType === 'Amount Limit' ? ineligibleRule.capValue : ineligibleRule.capValue * 100) }),
  } as UPCIneligibleRuleAPI;
};

/**
  * This function updates an existing ineligible rule.
  * @param selectedCollateral The selected collateral.
  * @param values The updated ineligible rule values.
  * @param ineligibleSetting The ineligible setting associated with the rule.
  * @returns The updated ineligible rule or null on error.
 */
export const putIneligibleRule = async (selectedCollateral: IARCollateral, values: IneligibleRuleAPI, ineligibleSetting: IIneligibleSettingAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleRule/${values.ineligibleRuleId}`,
    method: PUT,
    data: {
      ineligibleRuleId: values.ineligibleRuleId,
      ineligibleSettingId: values.ineligibleSettingId,
      arCollateralId: selectedCollateral.recordId,
      includeCredit: true,
      capAr: ineligibleSetting.code === 'CN' ? values.capAr : !['DLQ', 'AC'].includes(ineligibleSetting.code),
      arGtZero: ineligibleSetting.code === 'CN' ? values.arGtZero : !['DLQ', 'AC'].includes(ineligibleSetting.code),
      childCustomerOnly: values.childCustomerOnly,
      ...((values.dpid !== NON_EXISTING && values.dpid !== NOT_APPLICABLE && values.dpid !== undefined) && { dpid: values.dpid }),
      ...((values.dpdd !== NON_EXISTING && values.dpdd !== NOT_APPLICABLE && values.dpdd !== undefined) && { dpdd: values.dpdd }),
      ...((values.crossAgePct !== NON_EXISTING_PERCENT && values.crossAgePct !== undefined) && { crossAgePct: values.crossAgePct / 100 }),
      ...((values.concentrationPct !== NON_EXISTING_PERCENT && values.concentrationPct !== undefined) && { concentrationPct: values.concentrationPct / 100 }),
      ...((values.holdType !== undefined) && { holdType: values.holdType }),
      ...((values.creditLimit !== NON_EXISTING && values.creditLimit !== undefined) && { creditLimit: values.creditLimit }),
      ...((values.capType !== undefined) && { capType: values.capType }),
      ...(((values.capType === 'Amount Limit' ? values.capValue !== NON_EXISTING : values.capValue !== NON_EXISTING_PERCENT) && values.capValue !== undefined && values.capType !== undefined) && {
        capValue: (values.capType === 'Amount Limit' ? values.capValue : values.capValue / 100)
      }),
    },
  });
  const ineligibleRule = response.data as IneligibleRuleAPI;
  return {
    ...ineligibleRule,
    dpid: ineligibleRule.dpid === undefined && ineligibleRule.dpdd !== undefined ? NOT_APPLICABLE : ineligibleRule.dpid,
    dpdd: ineligibleRule.dpdd === undefined && ineligibleRule.dpid !== undefined ? NOT_APPLICABLE : ineligibleRule.dpdd,
    ...(ineligibleRule.crossAgePct !== undefined && { crossAgePct: ineligibleRule.crossAgePct * 100 }),
    ...(ineligibleRule.concentrationPct !== undefined && { concentrationPct: ineligibleRule.concentrationPct * 100 }),
    ...(ineligibleRule.holdType !== undefined && { holdType: ineligibleRule.holdType }),
    ...(ineligibleRule.creditLimit !== undefined && { creditLimit: ineligibleRule.creditLimit }),
    ...(ineligibleRule.capType !== undefined && { capType: ineligibleRule.capType }),
    ...(ineligibleRule.capValue !== undefined && ineligibleRule.capType !== undefined && { capValue: (ineligibleRule.capType === 'Amount Limit' ? ineligibleRule.capValue : ineligibleRule.capValue * 100) }),
  } as IneligibleRuleAPI;
};

/**
  * This function updates an existing ineligible rule.
  * @param values The updated ineligible rule values.
  * @param ineligibleSetting The ineligible setting associated with the rule.
  * @returns The updated ineligible rule or null on error.
 */
export const putUPCIneligibleRule = async (values: UPCIneligibleRuleAPI, ineligibleSetting: IUPCIneligibleSettingAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleRule/${values.ineligibleRuleId}`,
    method: PUT,
    data: {
      ineligibleRuleId: values.ineligibleRuleId,
      ineligibleSettingId: values.ineligibleSettingId,
      includeCredit: true,
      capAr: ineligibleSetting.code === 'CN' ? values.capAr : !['DLQ', 'AC'].includes(ineligibleSetting.code),
      arGtZero: ineligibleSetting.code === 'CN' ? values.arGtZero : !['DLQ', 'AC'].includes(ineligibleSetting.code),
      childCustomerOnly: values.childCustomerOnly,
      ...((values.dpid !== NON_EXISTING && values.dpid !== NOT_APPLICABLE && values.dpid !== undefined) && { dpid: values.dpid }),
      ...((values.dpdd !== NON_EXISTING && values.dpdd !== NOT_APPLICABLE && values.dpdd !== undefined) && { dpdd: values.dpdd }),
      ...((values.crossAgePct !== NON_EXISTING_PERCENT && values.crossAgePct !== undefined) && { crossAgePct: values.crossAgePct / 100 }),
      ...((values.concentrationPct !== NON_EXISTING_PERCENT && values.concentrationPct !== undefined) && { concentrationPct: values.concentrationPct / 100 }),
      ...((values.holdType !== undefined) && { holdType: values.holdType }),
      ...((values.creditLimit !== NON_EXISTING && values.creditLimit !== undefined) && { creditLimit: values.creditLimit }),
      ...((values.capType !== undefined) && { capType: values.capType }),
      ...(((values.capType === 'Amount Limit' ? values.capValue !== NON_EXISTING : values.capValue !== NON_EXISTING_PERCENT) && values.capValue !== undefined && values.capType !== undefined) && {
        capValue: (values.capType === 'Amount Limit' ? values.capValue : values.capValue / 100)
      }),
    },
  });
  const ineligibleRule = response.data as UPCIneligibleRuleAPI;
  return {
    ...ineligibleRule,
    dpid: ineligibleRule.dpid === undefined && ineligibleRule.dpdd !== undefined ? NOT_APPLICABLE : ineligibleRule.dpid,
    dpdd: ineligibleRule.dpdd === undefined && ineligibleRule.dpid !== undefined ? NOT_APPLICABLE : ineligibleRule.dpdd,
    ...(ineligibleRule.crossAgePct !== undefined && { crossAgePct: ineligibleRule.crossAgePct * 100 }),
    ...(ineligibleRule.concentrationPct !== undefined && { concentrationPct: ineligibleRule.concentrationPct * 100 }),
    ...(ineligibleRule.holdType !== undefined && { holdType: ineligibleRule.holdType }),
    ...(ineligibleRule.creditLimit !== undefined && { creditLimit: ineligibleRule.creditLimit }),
    ...(ineligibleRule.capType !== undefined && { capType: ineligibleRule.capType }),
    ...(ineligibleRule.capValue !== undefined && ineligibleRule.capType !== undefined && { capValue: (ineligibleRule.capType === 'Amount Limit' ? ineligibleRule.capValue : ineligibleRule.capValue * 100) }),
  } as UPCIneligibleRuleAPI;
};

/**
 * This function retrieves a list of ineligible rule overrides for a given ineligible rule.
 * @param ineligibleRule The ineligible rule to fetch overrides for.
 * @returns A list of ineligible rule overrides or an empty array on error.
 */
export const getIneligibleRuleOverrides = async (ineligibleRule: IneligibleRuleAPI | UPCIneligibleRuleAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/override/search/findByIneligibleRuleId`,
    method: GET,
    params: { ineligibleRuleId: ineligibleRule.ineligibleRuleId, pageNo: 0, pageSize: 99999, sortBy: 'order,ASC' },
  });
  const ineligibleRuleOverridesFromResponse = response.data.content as IneligibleRuleOverrideAPI[];
  const isRuleDelinquent = [ineligibleRule.dpid, ineligibleRule.dpdd].some(rule => rule !== undefined);
  const mappedIneligibleRuleOverrides = ineligibleRuleOverridesFromResponse.map(ineligibleRuleOverride => ({
    ...ineligibleRuleOverride,
    dpid: ineligibleRuleOverride.dpid === undefined && isRuleDelinquent ? NOT_APPLICABLE : ineligibleRuleOverride.dpid,
    dpdd: ineligibleRuleOverride.dpdd === undefined && isRuleDelinquent ? NOT_APPLICABLE : ineligibleRuleOverride.dpdd,
    ...(ineligibleRuleOverride.crossAgePct !== undefined && { crossAgePct: ineligibleRuleOverride.crossAgePct * 100 }),
    ...(ineligibleRuleOverride.concentrationPct !== undefined && { concentrationPct: ineligibleRuleOverride.concentrationPct * 100 }),
    ...(ineligibleRuleOverride.creditLimit !== undefined && { creditLimit: ineligibleRuleOverride.creditLimit }),
  }));
  return mappedIneligibleRuleOverrides as IneligibleRuleOverrideAPI[];
};

/**
 * This function posts a new ineligible rule override.
 * @param values The values for the new ineligible rule override.
 * @param ineligibleRuleId The ID of the associated ineligible rule.
 * @returns The created ineligible rule override or null on error.
 */
export const postIneligibleOverride = async (values: IneligibleRuleOverrideAPI, ineligibleRule: IneligibleRuleAPI | UPCIneligibleRuleAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/override`,
    method: POST,
    data: {
      ineligibleRuleId: ineligibleRule.ineligibleRuleId,
      order: values.order,
      ...((values.dpid !== NON_EXISTING && values.dpid !== NOT_APPLICABLE && values.dpid !== undefined) && { dpid: values.dpid }),
      ...((values.dpdd !== NON_EXISTING && values.dpdd !== NOT_APPLICABLE && values.dpdd !== undefined) && { dpdd: values.dpdd }),
      ...((values.crossAgePct !== NON_EXISTING_PERCENT && values.crossAgePct !== undefined) && { crossAgePct: values.crossAgePct / 100 }),
      ...((values.concentrationPct !== NON_EXISTING_PERCENT && values.concentrationPct !== undefined) && { concentrationPct: values.concentrationPct / 100 }),
      ...((values.creditLimit !== NON_EXISTING && values.creditLimit !== undefined) && { creditLimit: values.creditLimit }),
      excludedCustomers: values.excludedCustomers,
    }
  });
  const ineligibleRuleOverride = response.data as IneligibleRuleOverrideAPI;
  const isRuleDelinquent = [ineligibleRule.dpid, ineligibleRule.dpdd].some(rule => rule !== undefined);
  return {
    ...ineligibleRuleOverride,
    dpid: ineligibleRuleOverride.dpid === undefined && isRuleDelinquent ? NOT_APPLICABLE : ineligibleRuleOverride.dpid,
    dpdd: ineligibleRuleOverride.dpdd === undefined && isRuleDelinquent ? NOT_APPLICABLE : ineligibleRuleOverride.dpdd,
    ...(ineligibleRuleOverride.crossAgePct !== undefined && { crossAgePct: ineligibleRuleOverride.crossAgePct * 100 }),
    ...(ineligibleRuleOverride.concentrationPct !== undefined && { concentrationPct: ineligibleRuleOverride.concentrationPct * 100 }),
    ...(ineligibleRuleOverride.creditLimit !== undefined && { creditLimit: ineligibleRuleOverride.creditLimit }),
  } as IneligibleRuleOverrideAPI;
};

/**
 * This function updates an existing ineligible rule override.
 * @param values The updated ineligible rule override values.
 * @returns The updated ineligible rule override or null on error.
 */
export const putIneligibleOverrides = async (values: IneligibleRuleOverrideAPI, ineligibleRule: IneligibleRuleAPI | UPCIneligibleRuleAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/override/${values.ineligibleRuleOverrideId}`,
    method: PUT,
    data: {
      ineligibleRuleOverrideId: values.ineligibleRuleOverrideId,
      ineligibleRuleId: values.ineligibleRuleId,
      order: values.order,
      ...((values.dpid !== NON_EXISTING && values.dpid !== NOT_APPLICABLE && values.dpid !== undefined) && { dpid: values.dpid }),
      ...((values.dpdd !== NON_EXISTING && values.dpdd !== NOT_APPLICABLE && values.dpdd !== undefined) && { dpdd: values.dpdd }),
      ...((values.crossAgePct !== NON_EXISTING_PERCENT && values.crossAgePct !== undefined) && { crossAgePct: values.crossAgePct / 100 }),
      ...((values.concentrationPct !== NON_EXISTING_PERCENT && values.concentrationPct !== undefined) && { concentrationPct: values.concentrationPct / 100 }),
      ...((values.creditLimit !== NON_EXISTING && values.creditLimit !== undefined) && { creditLimit: values.creditLimit }),
      excludedCustomers: values.excludedCustomers,
    },
  });
  const ineligibleRuleOverride = response.data as IneligibleRuleOverrideAPI;
  const isRuleDelinquent = [ineligibleRule.dpid, ineligibleRule.dpdd].some(rule => rule !== undefined);
  return {
    ...ineligibleRuleOverride,
    dpid: ineligibleRuleOverride.dpid === undefined && isRuleDelinquent ? NOT_APPLICABLE : ineligibleRuleOverride.dpid,
    dpdd: ineligibleRuleOverride.dpdd === undefined && isRuleDelinquent ? NOT_APPLICABLE : ineligibleRuleOverride.dpdd,
    ...(ineligibleRuleOverride.crossAgePct !== undefined && { crossAgePct: ineligibleRuleOverride.crossAgePct * 100 }),
    ...(ineligibleRuleOverride.concentrationPct !== undefined && { concentrationPct: ineligibleRuleOverride.concentrationPct * 100 }),
    ...(ineligibleRuleOverride.creditLimit !== undefined && { creditLimit: ineligibleRuleOverride.creditLimit }),
  } as IneligibleRuleOverrideAPI;
};

/**
 * This function deletes an ineligible rule override.
 * @param ineligibleRuleOverride The ineligible rule override to be deleted.
 */
export const deleteIneligibleRuleOverride = async (ineligibleRuleOverride: IIneligibleRuleOverride) => {
  await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/override/${ineligibleRuleOverride.ineligibleRuleOverrideId}`,
    method: DELETE,
  });
};

/**
 * This function retrieves a list of ineligible rule condition groups for a given ineligible rule override.
 * @param ineligibleRuleOverride The ineligible rule override to fetch condition groups for.
 * @returns A list of ineligible rule condition groups or an empty array on error.
 */
export const getIneligibleRuleConditionGroups = async (ineligibleRuleOverride: IneligibleRuleOverrideAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/ruleConditionGroup/search/findByOverrideId`,
    method: GET,
    params: { irOverrideId: ineligibleRuleOverride.ineligibleRuleOverrideId, pageNo: 0, pageSize: 99999, sortBy: 'order,ASC' },
  });
  const ineligibleRuleConditionGroups = response.data.content as IneligibleRuleConditionGroupAPI[];
  return ineligibleRuleConditionGroups;
};

/**
 * This function posts a new ineligible rule condition group.
 * @param values The values for the new ineligible rule condition group.
 * @param ineligibleRuleOverride The associated ineligible rule override.
 * @returns The created ineligible rule condition group or null on error.
 */
export const postIneligibleRuleConditionGroup = async (values: IneligibleRuleConditionGroupAPI, ineligibleRuleOverride: IneligibleRuleOverrideAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/ruleConditionGroup`,
    method: POST,
    data: {
      irOverrideId: ineligibleRuleOverride.ineligibleRuleOverrideId,
      logicalOperator: values.logicalOperator,
      order: values.order
    },
  });
  return response.data as IneligibleRuleConditionGroupAPI;
};

/**
 * This function updates an existing ineligible rule condition group.
 * @param values The updated ineligible rule condition group values.
 * @returns The updated ineligible rule condition group or null on error.
 */
export const putIneligibleRuleConditionGroup = async (values: IneligibleRuleConditionGroupAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/ruleConditionGroup/${values.irConditionGroupId}`,
    method: PUT,
    data: {
      irConditionGroupId: values.irConditionGroupId,
      irOverrideId: values.irOverrideId,
      logicalOperator: values.logicalOperator,
      order: values.order
    },
  });
  return response.data as IneligibleRuleConditionGroupAPI;
};

/**
 * This function deletes an ineligible rule condition group.
 * @param ineligibleRuleConditionGroup The ineligible rule condition group to be deleted.
 */
export const deleteRuleConditionGroup = async (ineligibleRuleConditionGroup: IneligibleRuleConditionGroupAPI) => {
  await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/ruleConditionGroup/${ineligibleRuleConditionGroup.irConditionGroupId}`,
    method: DELETE,
  });
};

/**
 * This function retrieves a list of ineligible rule conditions for a given ineligible rule condition group.
 * @param ineligibleRuleConditionGroup The ineligible rule condition group to fetch conditions for.
 * @returns A list of ineligible rule conditions or an empty array on error.
 */
export const getIneligibleRuleCondition = async (ineligibleRuleConditionGroup: IneligibleRuleConditionGroupAPI) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/condition/search/findByIneligibleRuleConditionGroupId`,
    method: GET,
    params: { ineligibleRuleConditionGroup: ineligibleRuleConditionGroup.irConditionGroupId, pageNo: 0, pageSize: 99999, sortBy: 'order,ASC' },
  });
  const ineligibleRuleConditions = response.data.content as IneligibleRuleConditionAPI[];
  // start of field by field matching replacement logic
  return ineligibleRuleConditions.map(condition => {
    if (condition.fieldType !== 'field') { return condition; }
    return { ...condition, fieldValues: [ getFieldMatchingLabel(condition.fieldTable, condition.fieldValues[0]) ], };
  }) as IneligibleRuleConditionAPI[];
  // end of field by field matching replacement logic
};

/**
 * This function posts a new ineligible rule condition.
 * @param values The values for the new ineligible rule condition.
 * @param ineligibleRuleConditionGroup The associated ineligible rule condition group.
 * @returns The created ineligible rule condition or null on error.
 */
export const postIneligibleRuleCondition = async (values: IneligibleRuleConditionAPI, ineligibleRuleConditionGroup: IneligibleRuleConditionGroupAPI) => {
  // start of field by field matching replacement logic
  let fieldValues = values.fieldType === 'field'
    ? [ getFieldMatchingValue(values.fieldTable, values.fieldValues[0]) ]
    : values.fieldValues;
  // end of field by field matching replacement logic
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/condition`,
    method: POST,
    data: {
      ineligibleRuleConditionGroupId: ineligibleRuleConditionGroup.irConditionGroupId,
      fieldTable: values.fieldTable,
      fieldType: values.fieldType,
      field: values.field,
      operation: values.operation,
      fieldValues: fieldValues,
      logicalOperator: values.logicalOperator,
      order: values.order,
      isFieldByField: values.isFieldByField,
    },
  });
  const ineligibleRuleCondition = response.data as IneligibleRuleConditionAPI;
  // start of field by field matching replacement logic
  return {
    ...ineligibleRuleCondition,
    ...(ineligibleRuleCondition.fieldType === 'field' && {
      fieldValues: [ getFieldMatchingLabel(ineligibleRuleCondition.fieldTable, ineligibleRuleCondition.fieldValues[0]) ]
    }),
  } as IneligibleRuleConditionAPI;
  // end of field by field matching replacement logic
};

/**
 * This function updates an existing ineligible rule condition.
 * @param values The updated ineligible rule condition values.
 * @returns The updated ineligible rule condition or null on error.
 */
export const putIneligibleRuleCondition = async (values: IneligibleRuleConditionAPI) => {
  // start of field by field matching replacement logic
  let fieldValues = values.fieldType === 'field'
    ? [ getFieldMatchingValue(values.fieldTable, values.fieldValues[0]) ]
    : values.fieldValues;
  // end of field by field matching replacement logic
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/condition/${values.ineligibleRuleConditionId}`,
    method: PUT,
    data: {
      ineligibleRuleConditionId: values.ineligibleRuleConditionId,
      ineligibleRuleConditionGroupId: values.ineligibleRuleConditionGroupId,
      fieldTable: values.fieldTable,
      fieldType: values.fieldType,
      field: values.field,
      operation: values.operation,
      fieldValues: fieldValues,
      logicalOperator: values.logicalOperator,
      order: values.order,
      isFieldByField: values.isFieldByField,
    },
  });
  const ineligibleRuleCondition = response.data as IneligibleRuleConditionAPI;
  // start of field by field matching replacement logic
  return {
    ...ineligibleRuleCondition,
    ...(ineligibleRuleCondition.fieldType === 'field' && {
      fieldValues: [ getFieldMatchingLabel(ineligibleRuleCondition.fieldTable, ineligibleRuleCondition.fieldValues[0]) ]
    }),
  } as IneligibleRuleConditionAPI;
  // end of field by field matching replacement logic
};

/**
 * This function deletes an ineligible rule condition.
 * @param ineligibleRuleCondition The ineligible rule condition to be deleted.
 */
export const deleteRuleCondition = async (ineligibleRuleCondition: IneligibleRuleConditionAPI) => {
  await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/condition/${ineligibleRuleCondition.ineligibleRuleConditionId}`,
    method: DELETE,
  });
};

/**
 * This function retrieves list of field tables.
 * @returns An array of field tables.
 */
export const getFieldTables = async () => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/documentTypes`,
    method: GET,
  });
  return response.data.content as FieldTable[];
};

/**
 * This function retrieves list of fields for the selected collateral, excluding fields with 'ap' or 'vendor' prefixes.
 * @param selectedCollateral The selected collateral.
 * @returns An array of fields.
 */
export const getFieldList = async (selectedCollateral: IARCollateral) => {
  const response = await axiosInstance.request({
    url: `${API_DOMAIN}/ineligibleSetting/condition/fieldList/${selectedCollateral.recordId}`,
    method: GET,
    params: { pageSize: 99 }
  });
  return (
    response.data.content
      .filter((field: FieldList) => {
        const fieldName = field.tableField.toLowerCase();
        return !fieldName.startsWith('ap') && !fieldName.startsWith('vendor');
      })
      .filter((field: FieldList) => field.description)
      .map((field: FieldList) => ({ ...field, isUserDefined: false }))
  ) as FieldList[];
};

/**
 * This function retrieves list of fields for the parent client, excluding fields with 'ap' or 'vendor' prefixes.
 * @param parentId Record Id of the Parent Client.
 * @returns An array of fields.
 */
export const getFieldListByParentClient = async (parentClientId: number) => {
  const response = await getRuleConditionFieldListByParentId({
    parentId: parentClientId,
    pageSize: 99,
  });

  return (
    response.data.content
      .filter((field: FieldList) => {
        const fieldName = field.tableField.toLowerCase();
        return !fieldName.startsWith('ap') && !fieldName.startsWith('vendor');
      })
      .filter((field: FieldList) => field.description)
      .map((field: FieldList) => ({...field, isUserDefined: false}))
  ) as FieldList[];
};

/**
 * This function retrieves list of field values for the selected collateral and field.
 *
 * @param selectedCollateral The selected collateral.
 * @param selectedField The selected field.
 * @returns An array of field values.
 */
export const getFieldValuesByTableField = async (selectedCollateral: IARCollateral, selectedField: FieldList, parentCustomers: IARCustomer[]) => {
  try {
    if (!validateObjects(
      selectedCollateral.recordId, selectedField.documentTypeFk,
      selectedField.tableField, selectedField.isUserDefined,
    )) { throw new Error('Request Parameters are invalid.'); }
    const response = await axiosInstance.request({
      url: `${API_DOMAIN}/ineligibleSetting/condition/valueList`,
      method: GET,
      params: { arCollateralFk: selectedCollateral.recordId, documentTypeFk: selectedField.documentTypeFk, tableField: selectedField.tableField, isUserDefined: selectedField.isUserDefined }
    });
    const excludedValues = selectedField.tableField === 'custName' || selectedField.tableField === 'custSrcId'
      ? parentCustomers.map(customer => selectedField.tableField === 'custName' ? customer.custName : customer.custSrcId)
      : [];
    const fieldValues = response.data
      .filter((value: string) => !excludedValues.includes(value))
      .sort((a: string | number, b: string | number) => {
        if (typeof a === 'string' && typeof b === 'string') { return a.localeCompare(b); }
        return (a as number) - (b as number);
      }) /* sorts alphabetically */
      .map((value: string | number) => {
        if (typeof value === 'string') return {field: value, fieldName: '', type: 'Value'};
        return {field: value.toString(), fieldName: '', type: 'Value'};
      });
    return (fieldValues as FieldValue[]).slice(0, 20000); /* temporary fix, issue when there are too many options */
  } catch(error) {
    console.log('GET FIELD VALUES', error);
    return [];
  }
};

/**
 * This function retrieves list of field values for the selected collateral and field.
 *
 * @param parentId The ID of the Parent Client.
 * @param selectedField The selected field.
 * @returns An array of field values.
 */
export const getFieldValuesByTableFieldAndParentClient = async (parentId: number, selectedField: FieldList, parentCustomers: IARCustomer[]) => {
  try {
    if (!validateObjects(
      parentId,
      selectedField.documentTypeFk,
      selectedField.tableField,
      selectedField.isUserDefined,
    )) {
      throw new Error('Request Parameters is invalid.');
    }
    const response = await getRuleConditionValueListByParentId({
      parentId,
      documentTypeId: selectedField.documentTypeFk,
      tableField: selectedField.tableField,
      isUserDefined: selectedField.isUserDefined,
    });
    const excludedValues = selectedField.tableField === 'custName' || selectedField.tableField === 'custSrcId'
      ? parentCustomers.map(customer => selectedField.tableField === 'custName' ? customer.custName : customer.custSrcId)
      : [];
    const fieldValues = response.data
      .filter((value: string) => !excludedValues.includes(value) && value !== null)
      .sort((a: string | number, b: string | number) => {
        if (typeof a === 'string' && typeof b === 'string') { return a.localeCompare(b); }
        return (a as number) - (b as number);
      }) /* sorts alphabetically */
      .map((value: string | number) => {
        if (typeof value === 'string') return {field: value, fieldName: '', type: 'Value'};
        return {field: value.toString(), fieldName: '', type: 'Value'};
      });
    return (fieldValues as FieldValue[]).slice(0, 20000); /* temporary fix, issue when there are too many options */;
  } catch(error) {
    console.log('GET FIELD VALUES', error);
    return [];
  }
};

/**
 * This function retrieves list of user-defined fields for the selected client.
 *
 * @param selectedClient The selected client.
 * @returns An array of user-defined fields.
 */
export const getUserDefinedFieldList = async (selectedClient: Required<IClient>) => {
  const response = await getDocumentAttributesByBorrowerId(selectedClient.recordId);
  return response.data.map((documentAttribute: IDocumentAttributePayload) => {
    return {
      field: documentAttribute.fieldName,
      fieldName: documentAttribute.fieldName,
      fieldType: documentAttribute.fieldType,
      documentTypeFk: documentAttribute.documentTypeId,
    };
  }) as UserDefinedFieldList[];
};

/**
 * This function retrieves list of user-defined fields for the selected parent client.
 *
 * @param parentId The ID of the Parent Client.
 * @returns An array of user-defined fields.
 */
export const getUserDefinedFieldListByParentClient = async (parentBorrowerId: number) => {
  const response = await getDocumentAttributesByBorrowerId(parentBorrowerId);
  return response.data.map((documentAttribute: IDocumentAttributePayload) => ({
    field: documentAttribute.fieldName,
    fieldName: documentAttribute.fieldName,
    fieldType: documentAttribute.fieldType,
    documentTypeFk: documentAttribute.documentTypeId,
  })) as UserDefinedFieldList[];
};

export const getParentCustomersByARCollateralId = async (arCollateralId: number) => {
  return (
    await axiosInstance.request({
      url: `${API_DOMAIN}/ar/customers/search/findParentCustomerByARCollateralId`,
      method: GET,
      params: { arCollateralId }
    })
  ).data as IARCustomer[];
};

export const getUPCParentCustomersByBorrowerId = async (borrowerId: number) => {
  return (await getParentCustomerByParentBorrowerId(borrowerId)).data as IARCustomer[];
};

/**
 * This function checks if the selected collateral has payment terms.
 * @param selectedCollateral The selected collateral.
 * @returns `true` if payment terms exist; otherwise, `false`.
 */
export const checkHasPaymentTerms = async (selectedCollateral: IARCollateral) => {
  try {
    const response = await checkHasPaymentTermsRequest(selectedCollateral.recordId);
    return response.data as boolean;
  } catch (error) {
    console.log('CHECK PAYMENT TERMS ERROR: ', error);
    return true;
  }
};

/**
 * This function checks if the selected parent borrower has payment terms.
 * @param selectedParentBorrowerId The selected parent borrower id.
 * @returns `true` if payment terms exist; otherwise, `false`.
 */
export const checkHasPaymentTermsByParentClient = async (selectedParentBorrowerId: number) => {
  try {
    const response = await checkHasPaymentTermsOnParentBorrowerRequest(selectedParentBorrowerId);
    return response.data as boolean;
  } catch (error) {
    console.log('CHECK PAYMENT TERMS ERROR: ', error);
    return true;
  }
};

export const getCurrencyForIneligibleCap = async (ineligibleSettingId: number) => {
  return (
    await axiosInstance.request({
      url: `${API_DOMAIN}/currency/getCurrencyByIneligibleSetting`,
      method: GET,
      params: { ineligibleSettingId: ineligibleSettingId }
    })
  ).data as ICurrency;
};

/**
 * This function retrieves a list of customers for contras, associated with the ar collateral id.
 * @param arCollateralId The ar collateral id.
 * @returns A Promise that resolves once the customer data is retrieved.
 */
export const getCustomersByContrasByARCollateralId = async (arCollateralId: number, pageNo: number, pageSize: number, sortBy: string) => {
  return (
    await axiosInstance.request({
      url: arCustomersAPI.FIND_BY_CONTRAS,
      method: GET,
      params: { arCollateralId, pageNo, pageSize, sortBy }
    })
  ).data as { content: IARCustomerByContras[], totalElements: number };
};

/**
 * This function retrieves a list of UPC customers for contras, associated with the borrower id.
 * @param borrowerId The borrower id.
 * @returns A Promise that resolves once the customer data is retrieved.
 */
export const getUPCCustomersByContrasByBorrowerId = async (borrowerId: number, pageNo: number, pageSize: number, sortBy: string) => {
  return (
    await axiosInstance.request({
      url: arCustomersAPI.FIND_BY_CONTRAS,
      method: GET,
      params: { borrowerId, pageNo, pageSize, sortBy }
    })
  ).data as { content: IARCustomerByContras[], totalElements: number };
};

/**
 * This function retrieves a list of vendors for contras, associated with the ar collateral id.
 * @param arCollateralId The ar collateral id.
 * @returns A Promise that resolves once the vendor data is retrieved.
 */
export const getVendorsByContrasByARCollateralId = async (arCollateralId: number, pageNo: number, pageSize: number, sortBy: string) => {
  return (
    await axiosInstance.request({
      url: `${API_DOMAIN}/ar/vendors/search/findByContras`,
      method: GET,
      params: { arCollateralId, pageNo, pageSize, sortBy }
    })
  ).data as { content: IARVendorByContras[], totalElements: number };
};

/**
 * This function retrieves a list of UPC vendors for contras, associated with the borrower id.
 * @param borrowerId The borrower id.
 * @returns A Promise that resolves once the vendor data is retrieved.
 */
export const getUPCVendorsByContrasByBorrowerId = async (borrowerId: number, pageNo: number, pageSize: number, sortBy: string) => {
  return (
    await axiosInstance.request({
      url: `${API_DOMAIN}/ar/vendors/search/findByContras`,
      method: GET,
      params: { borrowerId, pageNo, pageSize, sortBy }
    })
  ).data as { content: IARVendorByContras[], totalElements: number };
};