import { Box, Typography } from '@mui/material';
import { Dispatch, SetStateAction, FC } from 'react';
import { IARIneligibles, IAmountTypes, ICustIneligible, IGrandTotal, IReportView, ISortingProps, getObjectFirstKey, IHeader, IIneligibleCapDetails, ICapDetails } from '..';
import { formatNumber } from '../../../../utility/helper';
import styles from './styles';
import IneligibleRows from './ineligible-view/row';
import IneligibleRowWithoutCustomers from './ineligible-view/row-without-customers';
import CustomerViewTable from './customer-view/table';

export interface IIneligibleDetailsProps extends ISortingProps {
  arIneligibles       : IARIneligibles[];
  totalIneligibleAmt  : number;
  expanded            : string | false;
  setExpanded         : Dispatch<SetStateAction<string | false>>;
  isLoading           : boolean;
  amountTypes         : IAmountTypes;
  custIneligibles     : ICustIneligible[];
  viewBy              : IReportView;
  grandTotal          : IGrandTotal | null;
  headers             : IHeader[];
  parentIds           : number[];
  currencyCode?       : string;
  exchangeRate?       : number;
  rfvIneligibleAmt?   : number;
  hasNoRfv?           : boolean;
  rfvDisabled?        : boolean;
  custIdsWithDetails  : {[key: string]: number[]};
  ineligibleCodes     : {[key: string]: string}[];
  queryParams         : {
    borrowerId    : number;
    bbPeriodId    : number;
    arCollateralId: number;
    endDate       : string;
  };
  isUltimateParent    : boolean;
  ineligibleCapDetails: IIneligibleCapDetails;
}

/**
 * Component for showing the report of AR (Accounts receivable) ineligible.
 * @param props Props for the ARIneligibleDetails.
 * @returns Rendered ARIneligibleDetails component
 */
const ARIneligibleDetails: FC<IIneligibleDetailsProps> = (props) => {
  return (
    <Box>
      {props.viewBy === 'Ineligible' ? 
        <>
          <Box>
            {props.arIneligibles.filter(item => item[getObjectFirstKey(item)]?.[0]).map((ineligible, index) => {
              const ineligibleDescription = getObjectFirstKey(ineligible);
              const ineligibleRecords = ineligible[ineligibleDescription];
              const ineligibleCode = ineligibleRecords.length ? ineligibleRecords[0].ineligibleCode : '';
              const capDetails: ICapDetails | undefined = props.ineligibleCapDetails[ineligibleDescription];

              return (
                <IneligibleRows
                  {...props}
                  key={ineligibleCode}
                  ineligibleRecords={ineligibleRecords}
                  ineligibleDescription={ineligibleDescription}
                  ineligibleCode={ineligibleCode}
                  capDetails={capDetails}
                />
              )
            })}
            {props.rfvIneligibleAmt !== undefined &&
              <IneligibleRowWithoutCustomers
                {...props}
                key={'RFV'}
                ineligibleCode='RFV'
                ineligibleDescription='Roll Forward Variance'
                ineligibleAmt={props.rfvIneligibleAmt}
              />}
          </Box>
          
          <Box sx={styles.totalAmount}>
            <Typography tabIndex={0} fontSize='1rem' fontWeight='bold'>TOTAL AR INELIGIBLE</Typography>
            <Typography tabIndex={0} fontSize='0.875rem' fontWeight='bold'>
              {formatNumber(
                { style: 'currency', currency: props.currencyCode ?? 'USD', currencySign: 'accounting' },
                props.totalIneligibleAmt,
                props.exchangeRate)}
            </Typography>
          </Box>
        </> :
        <CustomerViewTable {...props} />
      }
    </Box>
  );
};
export default ARIneligibleDetails;