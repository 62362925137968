import { Box, Paper, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styles from "./styles";
import LoanWatchLogo from '../../../assets/images/logo.png';
import { FC } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Component for showing the Update Success Page.
 * @param props The props for the UpdateSuccess component. See the IProps interface for more information.
 */
const RequestSuccess: FC = (props) => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  return (
    <Box sx={styles.mainContainer}>
      <img src={LoanWatchLogo} alt='LoanWatch Logo' aria-label='LoanWatch logo image' width={500} tabIndex={0} />
      <Paper elevation={0} sx={styles.paperStyle}>
        <CheckCircleIcon sx={styles.checkIcon} />
        <Typography sx={styles.modalTitle}>Email Sent!</Typography>
        <Typography sx={styles.descriptionText}>We sent an email to {email} with a link to reset your password.</Typography>
      </Paper>
    </Box>
  )
};

export default RequestSuccess;