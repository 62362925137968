import { createContext, useMemo, useState } from 'react';
import { IARCollateral, IInventoryCollateral, IInventoryValuations, IInventoryIneligibles, IInvBorrowingBase, IOtherCollateralReport, IClient, IARBorrowingBase, IARBorrowingBaseTotals } from '../interfaces/reportsInterface';
import { LayoutProps } from './authContext';

export interface IBorrowingBaseReportContext {
  selectedClient: IClient | null;
  setSelectedClient: (client: IClient | null) => void;
  isReportVisible: boolean;
  setIsReportVisible: (isReportVisible: boolean) => void;
  otherCollateralReport: IOtherCollateralReport | null;
  setOtherCollateralReport: (otherCollateralReport: IOtherCollateralReport | null) => void;
  arBorrowingBaseList: Array<IARBorrowingBase>;
  setArBorrowingBaseList: (arBorrowingBaseList: Array<IARBorrowingBase>) => void;
  arBorrowingBaseTotals: IARBorrowingBaseTotals;
  setArBorrowingBaseTotals: (arBorrowingBaseTotals: IARBorrowingBaseTotals) => void;
  invBorrowingBaseList: Array<IInvBorrowingBase>;
  setInvBorrowingBaseList: (invBorrowingBaseList: Array<IInvBorrowingBase>) => void;
}

export const BorrowingBaseReportContext = createContext<IBorrowingBaseReportContext | null>(null);

const BorrowingBaseReportProvider = (props: LayoutProps) => {
  const { children } = props;

  const [selectedClient, setSelectedClient] = useState<IClient | null>(null);
  const [isReportVisible, setIsReportVisible] = useState(true);
  const [otherCollateralReport, setOtherCollateralReport] = useState<IOtherCollateralReport | null>(null);
  const [arBorrowingBaseList, setArBorrowingBaseList] = useState<Array<IARBorrowingBase>>([]);
  const [arBorrowingBaseTotals, setArBorrowingBaseTotals] = useState<IARBorrowingBaseTotals>({ totalGrossAr: 0, totalAvailAr: 0, effectiveAdvRate: 0 });
  const [invBorrowingBaseList, setInvBorrowingBaseList] = useState<Array<IInvBorrowingBase>>([]);

  const value = useMemo(() => ({
    selectedClient,
    setSelectedClient,
    isReportVisible,
    setIsReportVisible,
    otherCollateralReport,
    setOtherCollateralReport,
    arBorrowingBaseList,
    setArBorrowingBaseList,
    arBorrowingBaseTotals, 
    setArBorrowingBaseTotals,
    invBorrowingBaseList,
    setInvBorrowingBaseList,
  }), [selectedClient,
    setSelectedClient,
    isReportVisible,
    setIsReportVisible,
    otherCollateralReport,
    setOtherCollateralReport,
    arBorrowingBaseList,
    setArBorrowingBaseList,
    arBorrowingBaseTotals, 
    setArBorrowingBaseTotals,
    invBorrowingBaseList,
    setInvBorrowingBaseList])

  return (
    <BorrowingBaseReportContext.Provider
      value={value}>
      {children}
    </BorrowingBaseReportContext.Provider>
  );
};

export default BorrowingBaseReportProvider;
