import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import styles from "./styles";
import { FC } from "react";

interface IProps {
  securityCount : number;
}

/**
 * Component for showing the Progress Indicator component.
 * @param props The props for the ProgressIndicator component. See the IProps interface for more information.
 */
const ProgressIndicator: FC<IProps> = (props) => {
  const {securityCount} = props;
  
  return (
    <Box sx={styles.dividerRow}>
      <Box sx={styles.dividerBox}>
        <Divider sx={{...styles.divider, ...(securityCount >= 1 && styles.dividerProgress)}} />
      </Box>
      <Box sx={styles.dividerBox}>
      <Divider sx={{...styles.divider, ...(securityCount >= 2 && styles.dividerProgress)}} />
      </Box>
      <Box sx={styles.dividerBox}>
      <Divider sx={{...styles.divider, ...(securityCount >= 3 && styles.dividerProgress)}} />
      </Box>
      <Box sx={styles.dividerBox}>
      <Divider sx={{...styles.divider, ...(securityCount >= 4 && styles.dividerProgress)}} />
      </Box>
      <Box sx={styles.dividerBox}>
      <Divider sx={{...styles.divider, ...(securityCount === 5 && styles.dividerProgress)}} />
      </Box>
    </Box>
  );
};

export default ProgressIndicator;