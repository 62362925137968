const styles = {
  pageContainer: {
    backgroundColor: '#F2F7F8',
  },
  hidden: {
    display: 'none'
  }
};

export default styles;
