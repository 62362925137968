const styles = {
  avatarHolder: {
    width: '5rem',
    height: '5rem',
    backgroundColor: '#154A8A',
  },
  divider: {
    borderTop: '1px solid #E5E2E2',
    marginX: '1px',
    marginTop: '1rem',
  },
  menuBox: {
    width: '23rem',
    padding: '0.5rem',
    alignItems: 'center',
  },
  name: {
    color: 'text.primary',
    fontWeight: 'bold',
  },
  signout: {
    fontWeight: 'bold',
    letterSpacing: 0,
  },
};
export default styles;
