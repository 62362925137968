import { Box, Button, Chip, FormHelperText } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import SampleLogo from '../../../../assets/images/SampleLogo.svg';
import styles from './styles';
import { FC, useState } from 'react';

interface IProps {
  isEditable:  boolean,
  userStatus: string
}

/**
 * Component for showing the contents of the user avatar in the user's profile page.
 * @param props The props for the UserAvatar component. See the IProps interface for more information.
 */
const UserAvatar: FC<IProps> = (props) => {
  const acceptableFileTypes       = ['JPEG', 'JPG', 'PNG'];
  const {isEditable, userStatus}  = props;
  const [hover, sethover]         = useState<boolean>(false);
  const [image, setImage]         = useState<string>('');
  const [fileError, setFileError] = useState<string>('');

  /**
   * This function is called whenever the image in the component is changed.
   * @param e The change event which contains the target files.
   * @returns If the uploaded file fails the necessary requirements such as the size and 
   *          file type, the function will return null and show an error.
   */
  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const file = e.target.files[0];
    const fileType = file?.type.split('/')[1] || '';

    if (!file) return;

    // Check if file type is allowed
    if(!acceptableFileTypes.includes(fileType.toUpperCase())) {
      setFileError(`File must be ${acceptableFileTypes.join(', ')}.`);
      return;
    }

    // File size must not exceed 10MB
    if (file.size > (1048576 * 10)){
      setFileError('File size must be less than 10MB.');
      return;
    }

    setFileError('');
    setImage(URL.createObjectURL(file));
  }

  /**
   * This function renders the chip shown below the user avatar component. The chip contains
   * information on the active status of the user. Additionally, this is where errors are shown
   * when the user tries to upload invalid images.
   * @returns 
   */
  const renderChip = () => {
    if (isEditable) {
      if (fileError) {
        return (
          <FormHelperText sx={{ ...styles.helperText, color: 'red' }}>
            {fileError}
          </FormHelperText>
        );
      } else {
        return (
          <FormHelperText sx={styles.helperText}>
            Allowed File type png, jpg, jpeg <br />
            Recommended Size 250 x 250 pixels
          </FormHelperText>
        );
      }
    } else {
      return (
        <Box sx={styles.centerChip}>
          <Chip
            label={userStatus}
            size="small"
            color={userStatus.toLowerCase() === 'active' ? 'success' : 'warning'}
          />
        </Box>
      );
    }
  }

  return (
    <>
      <Box sx={{minHeight: '30vh'}}>
        <Box sx={styles.avatarContainer}>
          <Button
              component="label"
              sx={styles.uploadBtn}
              variant='outlined'
              onMouseOver={()=>sethover(true)} 
              onMouseOut={()=>sethover(false)} 
          >
            <Box
              component="img"
              sx={styles.userAvatar}
              alt="Lender Logo"
              src = {image || SampleLogo}
            />
            {!hover ? '' : 
              <EditIcon sx={styles.editAvatar}></EditIcon>
            }
            <input
                type="file"
                accept="image/*"
                onChange={onImageChange}
                hidden
              />
          </Button>
          {renderChip()}
        </Box>
      </Box>
    </>
  );
};

export default UserAvatar;