import { AlertColor, Avatar, Box, Chip, IconButton, TableCell, TableRow, Tooltip } from "@mui/material"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import styles from '../../../styles';
import { useNavigate } from "react-router-dom";
import { useContext, useState } from 'react';
import { IUserRowInfo } from "../../../../../interfaces/rolesPermissionInterface";
import { checkUserPermissions, getLocalStorageItem } from "../../../../../utility/helper";
import axiosInstance from "../../../../../service/axiosInstance";
import api from "../../../../../service/api";
import { DELETE, NO_PERMISSION_MSG, PERMISSIONS } from "../../../../../utility/constants";
import ConfirmModal from "../../../../modals/confirm-modal";
import { IUsersAndRolesContext, UsersAndRolesContext } from "../../../../../context/usersAndRolesContext";

interface IProps {
  user : IUserRowInfo
  idx : number,
  buildUserData : () => void,
  setToasterMessage : (value: string) => void,
  setToasterSeverity : (value: AlertColor) => void,
  setToasterOpen : (value: boolean) => void,
}

/**
 * Component for showing the contents of the user table row.
 * @param props The props for the UsersTableRow component. See the IProps interface for more information.
 */
const UsersTableRow = ({user, idx, buildUserData, setToasterMessage, setToasterOpen, setToasterSeverity}: IProps) => {
  const {canDeleteUser} = useContext(UsersAndRolesContext) as IUsersAndRolesContext;
  const [isRowEditable,]                  = useState<boolean>(false);
  const [modalOpen, setModalOpen]         = useState<boolean>(false);
  const [modalTitle, setModalTitle]       = useState<string>('');
  const navigate                          = useNavigate();

  /**
   * This function calls the API endpoint for deleting a user by its ID.
   * @param userId The ID of the user to be deleted.
   */
  const deleteUserById = async (userId: string) => {
    try {
      const token = getLocalStorageItem('token');
      await axiosInstance.request({
        url: api.DELETE_USER,
        method: DELETE,
        headers: {
          token : token !== undefined ? token : ''
        },
        params: {userId: userId}
      })
    } catch (e) {
      console.log(e)
    }
  };

  /**
   * This function calls the API endpoint for deleting the details of a user by its ID.
   * @param userId The ID of the user to be deleted.
   */
  const deleteDetailsOfUser = async (userId: string) => {
    try {
      await axiosInstance.request({
        url: api.DELETE_USER_INFO,
        method: DELETE,
        params: {userId: userId}
      })
      setToasterMessage("User has been succesfully deleted!")
      setToasterSeverity('success');
      setToasterOpen(true)
    } catch (e) {
      console.log(e)
    }
  };

  /**
   * This function creates the props for the user avatar.
   * @param name The name of the user.
   * @returns The props for the user avatar.
   */
  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: '#154A8A',
        width: 36,
        height: 36,
        fontSize: 12
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };

  /**
   * This function navigates to the profile page of the selected user.
   */
  const selectuser = () => {
    navigate(`/users-and-roles/${user.recordId}/users-profile`, {state : {
        user : user
      }
    });
  };

  /**
   * This function calls the delete user and delete user details functions so that they can both be deleted.
   */
  const deleteUser = async () => {
    await deleteUserById(user.keycloakId);
    await deleteDetailsOfUser(user.keycloakId);
    buildUserData()
  };

  /**
   * This function closes the delete modal.
   */
  const closeModal = () => {
    setModalOpen(false);
  };

  /**
   * This function checks if the user has the permission to delete a user. 
   * If yes, the deleteUser function is called. 
   */
  const handleConfirm = async () => {
    checkPermission(deleteUser, PERMISSIONS.DELETE_USER);
  };

  /**
   * This function is called when the delete icon is clicked. 
   * It shows the modal for confirming the delete action.
   */
  const onDeleteClick = () => {
    setModalTitle(`Delete ${user.fullName}`);
    setModalOpen(true);
  };

  /**
   * This function checks if the user has a specific permission.
   * @param func The function to be called if the user has the required permission.
   * @param permission The permission to be checked.
   */
  const checkPermission = async (func: Function, permission: string) => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), permission)
    if(isPermitted){
      await func()
      return
    }
    setToasterOpen(true);
    setToasterMessage(NO_PERMISSION_MSG);
    setToasterSeverity('error');
  };

  return (
    <TableRow sx={styles.tableRow}>
      <ConfirmModal
        title={modalTitle}
        description={'Are you sure you want to delete this user?'}
        open={modalOpen}
        alignment="left"
        onClose={closeModal}
        onConfirm={handleConfirm}
        errorButton={true}
        noButtonText={'Cancel'}
        yesButtonText={'Delete'}
      />
      
      <TableCell 
        sx={{
          ...styles.tableCell,
          ...styles.tableHeadCell,
          textAlign: 'left'
        }}
      >
        {idx + 1}
      </TableCell>

      <TableCell 
        sx={{
          ...styles.tableCell,
          ...styles.tableHeadCell,
          textAlign: 'left'
        }}
      >
        <Box sx={styles.spaceX}>
          <Avatar alt={user.fullName} src={''} {...stringAvatar(user.fullName)}/>
          {user.fullName}
        </Box>
      </TableCell>

     

      <TableCell 
        sx={{
          ...styles.tableCell,
          ...styles.tableHeadCell,
          textAlign: 'center'
        }}
      >
        {user.invitedBy}
      </TableCell>

      <TableCell 
        sx={{
          ...styles.tableCell,
          ...styles.tableHeadCell,
          textAlign: 'center'
        }}
      >
        {user.role}
      </TableCell>

      <TableCell 
        sx={{
          ...styles.tableCell,
          ...styles.tableHeadCell,
          textAlign: 'center'
        }}
      >
        <Chip label={user.status} size="small" color={user.status.toLowerCase() === "active" ? "success" : "warning"} />
      </TableCell>

      <TableCell 
        sx={{
          ...styles.tableCell,
          ...styles.tableHeadCell,
          textAlign: 'center'
        }}
      >
        <Box>
          <Tooltip title='View the User Information'>
            <IconButton
              onClick={selectuser}
              sx={styles.actionsIconButton}
            >
              <PreviewIcon sx={{...styles.actionsIcon, ...(isRowEditable && styles.disabledIcon), }} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete the User'>
            <IconButton
              onClick={() => {onDeleteClick()}}
              sx={{...styles.actionsIconButton, ...(user.role === 'Super Admin' && styles.invisible), ...(!canDeleteUser && styles.hidden)}}
            >
              <DeleteOutlineIcon sx={{...styles.actionsIcon, ...(isRowEditable && styles.disabledIcon), }} />
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default UsersTableRow