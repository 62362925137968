const styles = {
  buttonTabs: {
    '.MuiTabs-scroller': {
      background: '#00000008',
    },
    '.MuiButtonBase-root.MuiTab-root': {
      borderRadius: '3px 3px 0 0',
      textTransform: 'none',
      width: '196px',
      marginRight: '5px',
      boxShadow: '0 -5px 30px -7px #E0E0E0',
      color: '#000000',
    },
    '.MuiButtonBase-root.MuiTab-root.Mui-selected': {
      background: 'white',
      color: '#000000',
    },
    '.MuiTabs-indicator': {
      display: 'none',
    },
    '.MuiTabs-flexContainer': {
      paddingTop: '10px',
    },
  },
  tabBox: {
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    borderRadius: '3px 3px 0 0',
    marginTop: '16px'
  },
  tabPanelBox: {
    p: 3,
  },
};

export default styles;
