const styles = {
  tableContainer: {
    width: '100%',
    maxHeight: '30rem',
    paddingX: '3px',
    marginBottom: '2rem',
    paddingBottom: '0.5px',
  },
  tableActionFooter: {
    textAlign: 'center',
    backgroundColor: '#EAEAEA',
    color: '#000',
    paddingRight: '0.5rem',
    paddingLeft: 0,
    borderBottom: 0,
    minWidth: '1.5rem',
    maxWidth: '1.5rem',
  },
  tableTextFooter: {
    backgroundColor: '#EAEAEA',
    color: '#000',
    textAlign: 'right',
    fontSize: '15px',
    fontWeight: 'bold',
    '&:last-of-type': {
      paddingRight: '1.5rem'
    },
    borderBottom: 0,
  },
  tableTextBodyLeft: {
    textAlign: 'left',
    minWidth: '19rem',
  },
  tableTextBodyNumber: {
    minWidth: '5.5rem',
  },
  divider: {
    marginBottom: '2rem'
  },
  varianceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '2rem',
    marginBottom: '-1rem',
  },
  varianceContainerCollateral: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.5rem'
  },
  varianceText: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '1rem',
    fontWeight: 'bold',
  },
  variance: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  tableFooterLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%'
  },
  tableRowVariance: {
    paddingBottom: '0.4rem',
  }
}
export default styles;