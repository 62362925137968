import { Dispatch, FC, SetStateAction, SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { AlertColor, Box, Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { SelectedClientContext } from '../../context/selectedClientContext';
import { IComboBoxIds, IComboBoxSetStates, IOption } from '../../interfaces/comboBox';
import ComboBox from '../../components/common/combo-box';
import images from '../../theme/images';
import styles from './styles';
import ReportsDialog, { IDialogSelectProps, IReportRoutes } from '../../components/dialog-select';
import { checkUserPermissions, getClientRequest, getOptions, getPermissionsOfUser } from '../../utility/helper';
import { AuthContext } from '../../context/authContext';
import { NO_PERMISSION_MSG, PERMISSIONS } from '../../utility/constants';
import Toaster from '../../components/toaster';
import DisabledComponentsContainer from '../../components/common/disabled-components-container';
import { useNavigate } from 'react-router-dom';

interface ICardButton {
  id        : number;
  name      : string;
  url       : IReportRoutes;
  permission: string;
  disabled? : boolean;
}

const cardButtons: ICardButton[] = [
  { id: 1,  name: 'Borrowing Base',             url: 'borrowing-base',   permission: PERMISSIONS.VIEW_BB_REPORT },
  { id: 2,  name: 'AR Ineligible',              url: 'ar-ineligible',    permission: PERMISSIONS.VIEW_INELIGIBLE_REPORT },
  { id: 3,  name: 'AR Aging',                   url: 'ar-aging',         permission: PERMISSIONS.VIEW_AR_AGING_REPORT },
  { id: 4,  name: 'AR Ineligible Compare',      url: 'ar-compare',       permission: PERMISSIONS.VIEW_INELIGILE_COMPARE_REPORT },
  { id: 5,  name: 'Roll Forward',               url: 'roll-forward',     permission: PERMISSIONS.VIEW_ROLL_FORWARD_REPORT },
  { id: 6,  name: 'AP Aging',                   url: 'ap-aging',         permission: PERMISSIONS.VIEW_AP_AGING_REPORT },
  { id: 7,  name: 'Inventory',                  url: 'inventory',        permission: PERMISSIONS.VIEW_INVENTORY_REPORT,         disabled: true },
  { id: 8,  name: 'Financial Mapping',          url: 'financial-mapping',permission: PERMISSIONS.VIEW_FINANCIAL_MAPPING_REPORT, disabled: true },
  { id: 9,  name: 'Balance Sheet',              url: 'balance-sheet',    permission: PERMISSIONS.VIEW_BALANCE_SHEET_REPORT,     disabled: true },
  { id: 10, name: 'Profit and Loss Statement',  url: 'profit-and-loss',  permission: PERMISSIONS.VIEW_PROFIT_LOST_REPORT,       disabled: true },
];

const getBackgroundImage = (name: string) => {
  const imgObj = {
    'Borrowing Base': images.BorrowingBaseReportIcon,
    'AR Ineligible': images.ARIneligibleReportIcon,
    'AR Aging': images.ARAgingReportIcon,
    'AR Ineligible Compare': images.ARIneligibleReportIcon,
    'Inventory': images.InventoryValuationReportIcon,
    'Roll Forward': images.RollForwardReportIcon,
    'AP Aging': images.APAgingReportIcon,
    'Financial Mapping': images.FinancialMappingReportIcon,
    'Balance Sheet': images.BalanceSheetReportIcon,
    'Profit and Loss Statement': images.ProfitAndLossStatementReportIcon,
  }

  return { backgroundImage: `url(${imgObj[name]})` };
}

const availableReportsInUpc = ['ar-ineligible', 'roll-forward', 'ar-aging', 'ap-aging', 'ar-compare'];

const Reports: FC = () => {
  const selectedClientContext                 = useContext(SelectedClientContext);
  const {state}                               = useContext(AuthContext);
  const [open, setOpen]                       = useState<boolean>(false);
  const [clients, setClients]                 = useState<IOption[]>([]);
  const [selectedClient, setSelectedClient]   = useState<IOption | null>(null);
  const [clientInput, setClientInput]         = useState<string>('');
  const [dialogProps, setDialogProps]         = useState<IDialogSelectProps>({ handleClose: () => setOpen(false), title: '' });
  const [cardButtonState, setCardButtonState] = useState<ICardButton[]>(cardButtons);
  const [isPermitted, setIsPermitted]         = useState<boolean>(false);
  const [isToasterOpen, setIsToasterOpen]     = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]   = useState<string>('');
  const [toasterSeverity, setToasterSeverity] = useState<AlertColor>('success');
  const isUltimateParent                      = useMemo(() => Boolean(selectedClientContext.selectedClient?.parentClient), [selectedClientContext]);
  const filteredClients                       = useMemo(() => clients.filter(client => client.parentClientFk === undefined), [clients]);
  const navigate                              = useNavigate();

  useEffect(() => {
    (async () => {
      const permitted: boolean = await getPermissions();
      if (permitted) {
        const params = { borrowerId: localStorage.getItem('selectedClientRecordId') ?? '0', invCollateralId: '0', bbPeriodId: '0', arCollateralId: '0', customerId: '0' };
        getOptions({type: 'client', params: params, getSetStatesByType: getSetStatesByType, setSelectedByType: setSelectedByType, mainRequest: getClientRequest()});
      } else {
        navigate('/forbidden');
      }
    })();
  },[]);

  useEffect(() => {
    if (selectedClient?.parentClientFk) {
      const parentClient = clients.find(client => client.recordId === selectedClient.parentClientFk);
      setSelectedClient(parentClient ?? null);

      const parentClientContext = selectedClientContext.clients.find(client => client.recordId === selectedClient.parentClientFk);
      selectedClientContext.setSelectedClient(parentClientContext ?? null);
    }
  }, [selectedClient])

  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(state.uid)
    const newList = cardButtons.filter(card => permissions.includes(card.permission))
    const permitted = permissions.includes(PERMISSIONS.VIEW_REPORTS);

    setIsPermitted(permitted)
    setCardButtonState(newList)
    return permitted;
  }

  const checkPermission = async (func: Function, permission: string, ...args: any[]) => {
    const isPermitted = await checkUserPermissions(state.uid, permission)
    if(isPermitted){
      func(...args)
      return
    }
    setToasterMessage(NO_PERMISSION_MSG);
    setToasterSeverity('error');
    setIsToasterOpen(true);
  }

  const getSetStatesByType = (_type: IComboBoxIds) => {
    return { setOptions: setClients, setSelected: setSelectedClient, setInput: setClientInput };
  };

  const setSelectedByType = (selected: IOption, type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    const mapped = selectedClientContext?.clients.find(client => client.recordId === selected?.recordId) ?? null;
    selectedClientContext?.setSelectedClient(mapped);
    setStates.setSelected(selected);
    setStates.setInput(selected.label);
  };

  const onChange = (_event: SyntheticEvent<Element, Event>, newValue: IOption | null, setSelected: Dispatch<SetStateAction<IOption | null>>) => {
    const mapped = selectedClientContext?.clients.find(client => client.recordId === newValue?.recordId) ?? null;
    selectedClientContext?.setSelectedClient(mapped);
    setSelected(newValue);
  };

  const setProps = (url: IReportRoutes) => {
    const common = { handleClose: dialogProps.handleClose, reportLink: url, forReport: true };

    const props = {
      'borrowing-base': {...common, title:'Borrowing Base', selectBBPeriod: true, bbCalcDate: true },
      'ar-ineligible': {...common, title:'AR Ineligible', selectARCollateral: !isUltimateParent, selectBBPeriod: true },
      'ar-aging': {...common, title:'AR Aging', selectARCollateral: !isUltimateParent, selectBBPeriod: true },
      'inventory': {...common, title:'Inventory', selectInvCollateral: true },
      'ar-compare': {...common, title: 'AR Compare', subtitle: 'Please select at least two (2) as of date to compare', selectARCollateral: !isUltimateParent, selectMultipleBB: true},
      'ap-aging': {...common, title:'AP Aging', selectARCollateral: !isUltimateParent, selectBBPeriod: true },
      'roll-forward': {...common, title: 'Roll Forward', selectARCollateral: !isUltimateParent, selectBBPeriod: true}
    }

    setDialogProps(props[url]);
    setOpen(true);
  };

  const getHandleChangeByType = (type: IComboBoxIds) => {
    return {
      onChange: (e: SyntheticEvent<Element, Event>, newValue: IOption | null) => onChange(e, newValue, getSetStatesByType(type).setSelected),
      onInputChange: (e: SyntheticEvent<Element, Event>, newInputValue: string) => setClientInput(newInputValue)
    }
  };

  if(!isPermitted){
    return <></>
  }

  return (
    <>
      <Box>
        <Box sx={{...styles.pageContainer, ...styles.clientContainer}}>
          <Box sx={styles.clientDropdownBox}>
            <ComboBox
              id='client'
              options={filteredClients}
              value={selectedClient}
              inputValue={clientInput}
              {...getHandleChangeByType('client')}
            />
          </Box>
        </Box>

        <Box sx={styles.headerContainer}>
          <Typography tabIndex={0} variant='h6' component='h3' sx={styles.headerTitle}>Reports</Typography>
        </Box>

        <Box sx={{...styles.pageContainer, ...styles.sectionContainer}}>
          <Typography tabIndex={0}>
            Select item below to generate report
          </Typography>

          <Box sx={styles.buttonsContainer}>
            <Grid container columnSpacing={3} rowSpacing={1.5}>
            {cardButtonState.map((card: ICardButton) => (
            <Grid item xs={6} md={4} key={card.id}>
              <Paper elevation={3}>
                <DisabledComponentsContainer isDisabled={Boolean(!selectedClient || card.disabled)}>
                  <Button
                    disabled={!selectedClient || (isUltimateParent && !availableReportsInUpc.includes(card.url)) || card.disabled}
                    fullWidth
                    sx={{...styles.cardButtonCommon, ...getBackgroundImage(card.name)}}
                    onClick={() => checkPermission(setProps, card.permission, card.url)}
                    aria-label={card.disabled ? `${card.name} button Disabled` : `${card.name} button`}
                  >
                    <Typography>{card.name}</Typography>
                  </Button>
                </DisabledComponentsContainer>
              </Paper>
            </Grid>))}
            <Modal open={open} onClose={() => setOpen(false)}>
              <ReportsDialog {...dialogProps} />
            </Modal>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Toaster
        open={isToasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setIsToasterOpen(false)}
      />
    </>
  );
};
export default Reports;