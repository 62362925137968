import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Typography, TextField, Button, InputAdornment, IconButton, AlertColor } from "@mui/material";
import { Field, Formik, FormikValues } from "formik";
import { FC, useState } from "react";
import styles from "./styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import updatePasswordSchema from "../../../schemas/updatePasswordSchema";
import ProgressIndicator from "./progress-indicator";
import PasswordRequirements from "./password-requirements";
import axiosInstance from "../../../service/axiosInstance";
import api from "../../../service/api";
import { GET, POST } from "../../../utility/constants";
import Toaster from "../../toaster";

const initialValues = {
  password: '',
  confirmPassword: ''
};

interface IProps {
  type: 'create' | 'update'
}

interface ICredentials {
  type: string;
  temporary: boolean;
  value: string;
}

/**
 * Component for showing the Update Password Form.
 * @param props The props for the UpdatePasswordForm component. See the IProps interface for more information.
 */
const UpdatePasswordForm: FC<IProps> = (props) => {
  const navigate                                      = useNavigate();
  const {type}                                        = props;
  const [searchParams]                                = useSearchParams();
  const [showPassword, setShowPassword]               = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [securityCount, setSecurityCount]             = useState<number>(0);
  const [hasUppercase, setHasUppercase]               = useState<boolean>(false);
  const [hasLowercase, setHasLowercase]               = useState<boolean>(false);
  const [hasDigit, setHasDigit]                       = useState<boolean>(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState<boolean>(false);
  const [hasMinCharacters, setHasMinCharacters]       = useState<boolean>(false);
  const [toasterOpen, setToasterOpen]                 = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]           = useState<string>('');
  const [toasterSeverity, setToasterSeverity]         = useState<AlertColor>('success');

  /**
   * This function updates the amount of requirements met by the new password.
   * @param password This is the new password
   */
  const updateSecurityCount = (password : string) => {
    const hasUppercase = /[A-Z]+/;
    const hasLowercase = /[a-z]+/;
    const hasDigit = /\d+/;
    const hasSpecialCharacter = /\W+/;

    let tempCount = 0;
    
    if (hasUppercase.test(password)) {
      tempCount += 1;
      setHasUppercase(true);
    } else {
      setHasUppercase(false);
    }
    if (hasLowercase.test(password)) {
      tempCount += 1;
      setHasLowercase(true);
    } else {
      setHasLowercase(false);
    }
    if (hasDigit.test(password)) {
      tempCount += 1;
      setHasDigit(true);
    } else {
      setHasDigit(false);
    }
    if (hasSpecialCharacter.test(password)) {
      tempCount += 1;
      setHasSpecialCharacter(true);
    } else {
      setHasSpecialCharacter(false);
    }
    if (password.length >= 8) {
      tempCount += 1;
      setHasMinCharacters(true);
    } else {
      setHasMinCharacters(false);
    }
    setSecurityCount(tempCount);
  };

  /**
   * This function shows/hides the value inputted at the password/confirm password field.
   * @param type This indicates if the field to be shown is the password field or the confirm password field.
   */
  const handleClickShowPassword = (type : string) => {
    if (type === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
    
  }; 

  /**
   * This function prevents the default behavior that happens when clicking the show/hide password icon.
   * @param event The event generated when clicking the icon.
   */
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  /**
   * This function handles the submitting of the creation of a new user, if the user is creating a new user, 
   * or the resetting the password of a user, if the user is trying to reset his/her password.
   * @param values The values to be used for the creation of a new user/resetting the password of a new user.
   */
  const handleSubmit = async (values : FormikValues) => {
    const formData = new FormData();
    formData.append("username", searchParams.get('email') ?? '');
    formData.append("password", values.password);
    const samePassword = await checkCurrentPassword(formData);

    if(samePassword && type !== 'create') {
      setToasterMessage('The new password you entered is the same as your old password. Enter a different password.');
      setToasterSeverity('error');
      setToasterOpen(true);
    } else {
      const credentials : ICredentials = {
        type: "password",
        temporary: false,
        value: values.password
      }
      const status = await resetPassword(credentials)

      if (type === 'create') { 
        await updateStatus(searchParams.get('userDetailId'), 'Active')
      }

      if (status === 200) {
        navigate(type === 'create' ? '/create-password/success' :'/forgot-password/success');
      }
    }
  }
  /**
   * This function checks if the new password inputted is same as the old password.
   * @param formData The credentials of the user.
   * @returns A boolean value indicating if the new password inputted is same as the old password.
   */
  const checkCurrentPassword = async (formData: FormData) => {
    try {
      const response = await axiosInstance.request({
        url: api.LOGIN,
        method: POST,
        data: formData
      })
      const samePassword = response.status === 200;
      return samePassword;
    } catch (e) {
      console.log(e)
      return false;
    }
  }

  /**
   * This function calls the API endpoint for resetting the password of a user.
   * @param credentials The new credentials of the user.
   * @returns The response status of the API request.
   */
  const resetPassword = async (credentials : ICredentials) => {
    try {
      const token = await createPublicAccessToken();
      const response = await axiosInstance.request({
        url: api.RESET_PASSWORD,
        method: POST,
        headers: {token : token},
        data: credentials,
        params: {userId : searchParams.get('userId')}
      })
      return response.status;
    } catch (e) {
      console.log(e)
    }
  };

  const updateStatus = async (userId: string | null, status: string) => {
    if (userId === null) return;
    try {
      const response = await axiosInstance.request({
        url: api.UPDATE_STATUS,
        method: POST,
        params: {
          recordId : userId,
          status: status
        }
      })
      return response.status;
    } catch (e) {
      console.log(e)
    }
  };

  /**
   * This function calls the API endpoint for creating a public access token that can be used to create a new user.
   * @returns The public access token.
   */
  const createPublicAccessToken = async () => {
    try {
      const response = await axiosInstance.request({
        url: api.CREATE_PUBLIC_TOKEN,
        method: GET
      })
      const token = response.data.access_token;
      return token;
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {handleSubmit(values)}}
      validationSchema={updatePasswordSchema}
    >
      {
        ({values, errors, touched, isValid, dirty, handleChange, handleBlur, submitForm}) => (
          <Box>
            <Typography sx={styles.labelText}>
              New Password
            </Typography>
            <Field
              as={TextField}
              margin="normal"
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              onChange={(event : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                updateSecurityCount(event.target.value);
                handleChange(event);
              }}
              onBlur={handleBlur}
              sx={styles.inputField}
              inputProps={{ 'data-testid': 'new-password', 
                            'aria-label':'New Password'}}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(event) => handleClickShowPassword('password')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff sx={styles.visibilityIcon}/> : <Visibility sx={styles.visibilityIcon}/>}
                    </IconButton>
                  </InputAdornment>
              }}
            />
            <ProgressIndicator securityCount={securityCount}/>
            <Typography sx={styles.labelText}>
              Confirm Password
            </Typography>
            <Field
              as={TextField}
              margin="normal"
              id="confirmPassword"
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              value={values.confirmPassword}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={styles.inputField}
              inputProps={{ 'data-testid': 'confirm-password', 
                            'aria-label':'Confirm Password'}}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(event) => handleClickShowPassword('confirmPassword')}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showConfirmPassword ? <VisibilityOff sx={styles.visibilityIcon}/> : <Visibility sx={styles.visibilityIcon}/>}
                    </IconButton>
                  </InputAdornment>
              }}
            />
            <PasswordRequirements
              hasDigit={hasDigit}
              hasLowercase={hasLowercase}
              hasUppercase={hasUppercase}
              hasMinCharacters={hasMinCharacters}
              hasSpecialCharacter={hasSpecialCharacter}
            />
            <Box sx={styles.buttonContainer}>
              <Button
                disabled={!(isValid && dirty)}
                variant="contained"
                color="primary"
                type="submit"
                sx={styles.loginButton}
                onClick={submitForm}
              >
                {type === 'create' ? 'Create' : 'Update'} Password
              </Button>
            </Box>
            <Toaster
              open={toasterOpen}
              message={toasterMessage}
              severity={toasterSeverity}
              onCloseChange={() => setToasterOpen(false)}
            />
          </Box>
        )
      }
    </Formik>
  );
};

export default UpdatePasswordForm;