import { Box, Typography } from '@mui/material';
import { forwardRef, useEffect, useRef, } from 'react';
import styles from './styles';

interface IHelperTextProps {
  text?: string | null;
}

const HelperTextComponent = forwardRef<HTMLDivElement, IHelperTextProps>((props, ref) => {
  const helperTextRef = useRef<HTMLDivElement>(null);
  
  return (
    <Box>
      <Typography tabIndex={props?.text ? 0 : -1} ref={helperTextRef} role="alert" sx={styles.errorMessage}>
        {props?.text}
      </Typography>
    </Box>
  );
});

export default HelperTextComponent;
