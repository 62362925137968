import * as Yup from 'yup';

const vendorSchema = Yup.object({
  vendorSrcId: Yup.string()
    .required('Customer ID is required').trim(),
  vendorName: Yup.string()
    .required('Customer Name is required').trim(),
});

export default vendorSchema;
