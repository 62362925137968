const styles = {
  openedIcon: {
    backgroundColor: '#fff',
  },
  filterIconButton: {
    borderRadius: 0,
    marginLeft: '0.25rem',
    padding: '0.1rem',
  },
  filterIcon: {
    color: '#154A8A',
    fontSize: '1rem',
  },
  checkIcon: {
    color: '#154A8A',
    fontSize: '1.2rem',
  },
  selectedFilterIcon: {
    color: '#3c6391',
    fontSize: '1rem',
  },
  menuContainer: {
    padding: '1rem',
  },
  searchIcon: {
    fontSize: '1.3rem',
    marginRight: '0.5rem',
  },
  searchFieldText: {
    fontSize: '0.875rem',
    height: '1.75rem',
    width: '14.6rem',
  },
  clearIconButton: {
    padding: '0.1rem',
  },
  clearIcon: {
    fontSize: '1rem',
  },
  menuList: {
    maxHeight: '9rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '14.6rem',
    padding: '8px 0 0 0',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default styles;
