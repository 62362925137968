const styles = {
  permissionLabel: {
    fontSize:'14px'
  },
  clientLabelBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  radioGroupBox: {
    display: 'flex',
    flexDirection: 'row'
  },
  permissionParentContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    margin: '0.5rem'
  },
  permissionChildContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  permissionContainer:{
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    paddingLeft: '10px',
    marginLeft: '3px',
    maxHeight: '190px',
    overflowY: 'auto',
  },
  permissionBox: {
    padding: '1rem',
    backgroundColor: '#F7F7F7',
    boxSizing: 'border-box',
    borderRadius: '10px',
    marginBottom: '10px',
    marginLeft: '15px',
    marginRight: '15px'
  },
  clientsPermissionBox: {
    maxHeight: '55vh',
    overflowY: 'auto'
  },
  clientsAccordionBox: {
    '&&&.MuiPaper-root.MuiAccordion-root.Mui-expanded' : {
      margin: '0px'
    }
  },
  subPermissionBox: {
    marginLeft: '24px',
    marginRight: '15px'
  },
  permissionList: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  gridPermission: {
    justifyContent: 'space-between',
    alignItems: 'end',
    marginLeft: '18px'
  },
  headerRoleLabel: {
    color: '#154A8A',
    fontWeight: 'bold',
    fontSize:'16px'
  },
  checkBox: {
    px: '9px',
    py: '4px'
  },
  headerCheckBox: {
    '&&&.MuiCheckbox-indeterminate' : {
      color: '#B7B7B7',
    },
  },
  accordionBox: {
    '&&&.MuiPaper-root' : {
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  },
  accordionHeader: {
    '&&& .MuiAccordionSummary-content' : {
      '& .MuiGrid-root' : {
        marginLeft: '5px'
      },
      margin: '0px',
    },
    '&&&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded' : {
      minHeight: '0px',
    },
    flexDirection:'row-reverse',
    padding: '0px'
  }
};

export default styles;
