import { FormikErrors, FormikProps, getIn } from 'formik';
import { NON_EXISTING, NON_EXISTING_PERCENT } from '../utility/constants';
import { IIneligibleSettingDetail, IIneligibleRuleOverride, IIneligibleSettingDetailError, IFormikValuesForRuleAndOverridesPerSetting, IIneligibleRuleConditionGroup, IIneligibleCondition, IIneligibleSettingAPI, IUPCIneligibleSettingDetail, IUPCIneligibleSettingAPI, IFormikValuesForUPCRuleAndOverridesPerSetting, IneligibleRuleAPIError } from '../interfaces/ineligibleSettingInterface';

interface RuleAndOverrideValidationProps {
  ineligibleSetting: IIneligibleSettingAPI | IUPCIneligibleSettingAPI;
  errors: { ineligibleSettingDetails: IIneligibleSettingDetailError[] };
  settingDetail: IIneligibleSettingDetail | IUPCIneligibleSettingDetail;
  settingIndex: number;
}

interface RuleConditionGroupValidationProps extends RuleAndOverrideValidationProps {
  override: IIneligibleRuleOverride;
  overrideIndex: number;
}

export const validateIneligibleSettings = (data: { ineligibleSettingDetails?: IIneligibleSettingDetail[] | IUPCIneligibleSettingDetail[]; }) => {
  const errors: { ineligibleSettingDetails: IIneligibleSettingDetailError[] }  = { ineligibleSettingDetails: [ { ineligibleRule: {}, ineligibleRuleOverrides: [] } ] };
  data?.ineligibleSettingDetails?.forEach((settingDetail, settingIndex) => {
    const { ineligibleSetting } = settingDetail;
    if (ineligibleSetting.disabled || settingDetail === undefined) { return; }
    errors.ineligibleSettingDetails[settingIndex] = { ineligibleRule: {}, ineligibleRuleOverrides: [] };

    const validationProps: RuleAndOverrideValidationProps = { ineligibleSetting, settingDetail, errors, settingIndex };
    handleRule(validationProps);
    handleOverride(validationProps);
  });

  const hasError = checkSettingErrors(errors);
  if (hasError) { return errors; }
  return {};
};

const handleRule = (props: RuleAndOverrideValidationProps) => {
  const { ineligibleSetting, errors, settingIndex, settingDetail } = props;
  const ineligibleRule = settingDetail.ineligibleRule;

  const conditionallySetRuleError = (params: { condition: boolean, errors: { ineligibleSettingDetails: IIneligibleSettingDetailError[] }, updatedRuleError: IneligibleRuleAPIError | undefined, settingIndex: number }) => {
    const { condition, errors, updatedRuleError, settingIndex } = params;
    if (!condition) { return; }
    errors.ineligibleSettingDetails[settingIndex].ineligibleRule = updatedRuleError;
  };

  if (ineligibleRule === undefined) { return; }

  switch (ineligibleSetting.code) {
    case 'DLQ':
      conditionallySetRuleError({
        condition: (ineligibleRule.dpid === undefined || ineligibleRule.dpid === NON_EXISTING),
        errors,
        updatedRuleError: { ...errors.ineligibleSettingDetails[settingIndex].ineligibleRule, dpid: 'DPID is required' },
        settingIndex
      });
      conditionallySetRuleError({
        condition: (ineligibleRule.dpdd === undefined || ineligibleRule.dpdd === NON_EXISTING),
        errors,
        updatedRuleError: { ...errors.ineligibleSettingDetails[settingIndex].ineligibleRule, dpdd: 'DPDD is required' },
        settingIndex
      });
      break;
    case 'XA':
      conditionallySetRuleError({
        condition: (ineligibleRule.crossAgePct === undefined || ineligibleRule.crossAgePct === NON_EXISTING_PERCENT),
        errors,
        updatedRuleError: { ...errors.ineligibleSettingDetails[settingIndex].ineligibleRule, crossAgePct: 'Cross Age is required' },
        settingIndex
      });
      break;
    case 'CN':
      conditionallySetRuleError({
        condition: (ineligibleRule.concentrationPct === undefined || ineligibleRule.concentrationPct === NON_EXISTING_PERCENT),
        errors,
        updatedRuleError: { ...errors.ineligibleSettingDetails[settingIndex].ineligibleRule, concentrationPct: 'Concentration is required' },
        settingIndex
      });
      break;
    case 'CONTRA':
      conditionallySetRuleError({
        condition: (ineligibleRule.holdType === undefined),
        errors,
        updatedRuleError: { ...errors.ineligibleSettingDetails[settingIndex].ineligibleRule, holdType: 'Hold Type is required' },
        settingIndex
      });
      break;
    case 'CL':
      conditionallySetRuleError({
        condition: (ineligibleRule.creditLimit === undefined || ineligibleRule.creditLimit === NON_EXISTING),
        errors,
        updatedRuleError: { ...errors.ineligibleSettingDetails[settingIndex].ineligibleRule, creditLimit: 'Credit Limit is required' },
        settingIndex
      });
      break;
    case 'AC':
    case 'RFV':
      break;
    default:
      conditionallySetRuleError({
        condition: (ineligibleRule.capType === undefined),
        errors,
        updatedRuleError: { ...errors.ineligibleSettingDetails[settingIndex].ineligibleRule, capType: 'Cap Type is required' },
        settingIndex
      });
      break;
  }
};

const handleOverride = (props: RuleAndOverrideValidationProps) => {
  const { ineligibleSetting, errors, settingIndex, settingDetail } = props;

  settingDetail.ineligibleRuleOverrides?.forEach((override, overrideIndex) => {
    errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.splice(overrideIndex, 1, { ineligibleRuleConditionGroup: [] });
    if (ineligibleSetting.code === 'DLQ' && (override.dpid === undefined || override.dpid === NON_EXISTING)) {
      errors.ineligibleSettingDetails[settingIndex].ineligibleRuleOverrides?.splice(overrideIndex, 1, { ...errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex], dpid: 'DPID is required' });
    }
    if (ineligibleSetting.code === 'DLQ' && (override.dpdd === undefined || override.dpdd === NON_EXISTING)) {
      errors.ineligibleSettingDetails[settingIndex].ineligibleRuleOverrides?.splice(overrideIndex, 1, { ...errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex], dpdd: 'DPDD is required' });
    }
    if (ineligibleSetting.code === 'XA' && (override.crossAgePct === undefined || override.crossAgePct === NON_EXISTING_PERCENT)) {
      errors.ineligibleSettingDetails[settingIndex].ineligibleRuleOverrides?.splice(overrideIndex, 1, { ...errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex], crossAgePct: 'Cross Age is required' });
    }
    if (ineligibleSetting.code === 'CN' && (override.concentrationPct === undefined || override.concentrationPct === NON_EXISTING_PERCENT)) {
      errors.ineligibleSettingDetails[settingIndex].ineligibleRuleOverrides?.splice(overrideIndex, 1, { ...errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex], concentrationPct: 'Concentration is required' });
    }
    if (ineligibleSetting.code === 'CL' && (override.creditLimit === undefined || override.creditLimit === NON_EXISTING)) {
      errors.ineligibleSettingDetails[settingIndex].ineligibleRuleOverrides?.splice(overrideIndex, 1, { ...errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex], creditLimit: 'Credit Limit is required' });
    }

    handleRuleConditionGroup({...props, override, overrideIndex});
  })
};

const handleRuleConditionGroup = (props: RuleConditionGroupValidationProps) => {
  const { errors, settingIndex, override, overrideIndex } = props;
  
  override?.ineligibleRuleConditionGroup.forEach((conditionGroup, conditionGroupIndex) => {
    errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex]?.ineligibleRuleConditionGroup?.splice(conditionGroupIndex, 1, { ineligibleRuleCondition: [] });
    conditionGroup?.ineligibleRuleCondition.forEach((condition, conditionIndex) => {
      errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex]?.ineligibleRuleConditionGroup?.[conditionGroupIndex]?.ineligibleRuleCondition?.splice(conditionIndex, 1, {});
      if (condition.field === undefined || condition.field === '') {
        errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex]?.ineligibleRuleConditionGroup?.[conditionGroupIndex]?.ineligibleRuleCondition?.splice(conditionIndex, 1, { ...errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex]?.ineligibleRuleConditionGroup?.[conditionGroupIndex]?.ineligibleRuleCondition?.[conditionIndex], field: 'Field is required' });
      }
      if (condition.operation === undefined || condition.operation === '') {
        errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex]?.ineligibleRuleConditionGroup?.[conditionGroupIndex]?.ineligibleRuleCondition?.splice(conditionIndex, 1, { ...errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex]?.ineligibleRuleConditionGroup?.[conditionGroupIndex]?.ineligibleRuleCondition?.[conditionIndex], operation: 'Operation is required' });
      }
      if (condition.fieldValues === undefined || condition.fieldValues.length === 0) {
        errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex]?.ineligibleRuleConditionGroup?.[conditionGroupIndex]?.ineligibleRuleCondition?.splice(conditionIndex, 1, { ...errors?.ineligibleSettingDetails?.[settingIndex]?.ineligibleRuleOverrides?.[overrideIndex]?.ineligibleRuleConditionGroup?.[conditionGroupIndex]?.ineligibleRuleCondition?.[conditionIndex], fieldValues: 'Value is required' });
      }
    });
  })
};

const checkSettingErrors = (errors: { ineligibleSettingDetails: IIneligibleSettingDetailError[] }) => {
  const settingErrors = errors.ineligibleSettingDetails as FormikErrors<IIneligibleSettingDetail>[] ?? [];
  return settingErrors.some(setting => {
    const hasIneligibleRuleErrors = setting?.ineligibleRule !== undefined && Object.keys(setting.ineligibleRule).length > 0;
    if (hasIneligibleRuleErrors) { return true; }
    let hasIneligibleRuleOverrideErrors = false;
    if (setting?.ineligibleRuleOverrides !== undefined && setting.ineligibleRuleOverrides.length > 0) {
      const overrideErrors = setting.ineligibleRuleOverrides as unknown as FormikErrors<IIneligibleRuleOverride>[];
      hasIneligibleRuleOverrideErrors = overrideErrors.some(overrideError => {
        const filteredOverrideKeys = Object.keys(overrideError).filter(key => key !== 'ineligibleRuleConditionGroup');
        if (filteredOverrideKeys.length > 0) { return true; }
        const conditionGroupErrors = overrideError.ineligibleRuleConditionGroup as FormikErrors<IIneligibleRuleConditionGroup>[] ?? [];
        return conditionGroupErrors.some(conditionGroupError => {
          const conditionErrors = conditionGroupError.ineligibleRuleCondition as FormikErrors<IIneligibleCondition>[] ?? [];
          return conditionErrors.some(conditionError => Object.keys(conditionError).length > 0);
        });
      });
    }
    return hasIneligibleRuleOverrideErrors;
  });
};

export const validateIneligibleSetting = (formik: FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>, name: string) => {
  const error = validateIneligibleSettings(formik.values);
  formik.setFieldError(name, getIn(error, name));
};
