import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid, Typography } from "@mui/material";
import { FC, SyntheticEvent, useState } from "react";
import ARIneligibleTable from "./table";
import { IARIneligible, ICapDetails, ineligibleTypeTotal } from "../../..";
import { IIneligibleDetailsProps } from "../..";
import { formatNumber } from "../../../../../../utility/helper";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styles from "./styles";
import axiosInstance from "../../../../../../service/axiosInstance";
import { reportsAPI } from "../../../../../../service/api";
import { codesWithNoDetails, GET } from "../../../../../../utility/constants";

export interface IIneligibleRowsProps extends IIneligibleDetailsProps {
  ineligibleRecords     : IARIneligible[];
  ineligibleDescription : string;
  ineligibleCode        : string;
  ineligibleTotal?      : number;
  capDetails?           : ICapDetails;
  borrowerId?           : number;
  arCollateralId?       : number;
  endDate?              : string;
  isChildCustomerOnly?  : boolean;
}

/**
 * Component for showing the Ineligible Rows
 * @param props Props for the IneligibleRows.
 * @returns Rendered IneligibleRows component.
 */
const IneligibleRows: FC<IIneligibleRowsProps> = (props) => {
  const [custReport, setCustReport] = useState<IARIneligible[]>([]);
  const [isLoading, setIsLoading]   = useState<boolean>(false);
  const [isOpen, setIsOpen]         = useState<boolean>(false);

  /**
   * This function handles change events, especially when panels expand or collapse.
   */
  const handleChange = (panel: string) => async (_event: SyntheticEvent, newExpanded: boolean) => {
    if (props.isUltimateParent) {
      if (isOpen) {
        setIsOpen(false);
      } else if (custReport.length) {
        setIsOpen(newExpanded);
      } else {
        setIsLoading(true);

        const report: IARIneligible[] | undefined = await fetchIneligibleReportByCode(
          props.borrowerId as number,
          props.endDate as string,
          props.arCollateralId as number,
          props.isChildCustomerOnly as boolean
        );

        if (report) {
          const customerIds: number[] = props.ineligibleCode === 'DLQ' || codesWithNoDetails.includes(props.ineligibleCode) ? [] :
            await fetchCustIdsWithDetails(
              props.borrowerId as number,
              props.endDate as string,
              props.arCollateralId as number,
              props.isChildCustomerOnly as boolean
            );

          const mapped = mapHasDetails(report, customerIds);
          setCustReport(mapped);
        }

        setIsLoading(false);
        setIsOpen(newExpanded);
      }
    } else if (isOpen) {
      setIsOpen(false);
    } else {
      setIsLoading(true);

      const customerIds: number[] = props.custIdsWithDetails[props.ineligibleCode] ?? [];
      const mapped = mapHasDetails(props.ineligibleRecords, customerIds);
      setCustReport(mapped);

      setIsLoading(false);
      setIsOpen(newExpanded);
    }
  };

  /**
   * Fetches an ineligible report by code.
   *
   * @param borrowerId The ID of the borrower for whom the report is being requested.
   * @param asOfDate The date as of which the report should be generated (in YYYYMMDD format).
   * @param arCollateralId The ID of the AR collateral associated with the report.
   * @param isChildCustomerOnly A flag indicating whether the report is child level or parent level.
   * @returns promise that resolves to an array of IARIneligible objects representing the ineligible report.
   */
  const fetchIneligibleReportByCode = async (borrowerId: number, asOfDate: string, arCollateralId: number, isChildCustomerOnly: boolean) => {
    try {
      const response = await axiosInstance.request({
        url: `${reportsAPI.arIneligibleReport.GET_UPC_INELIGIBLE_REPORT}/${props.ineligibleCode}`,
        method: GET,
        params: { borrowerId, asOfDate, arCollateralId, isChildCustomerOnly }
      });
      const report = response.data as IARIneligible[];
      return report;
    } catch (error) {
      console.log('INELIGIBLE REPORT BY CODE ERROR: ', error);
    }
  };

  /**
   * Fetches customer ids with details by code.
   *
   * @param borrowerId The ID of the borrower for whom the report is being requested.
   * @param asOfDate The date as of which the report should be generated (in YYYYMMDD format).
   * @param arCollateralId The ID of the AR collateral associated with the report.
   * @param isChildCustomerOnly A flag indicating whether the report is child level or parent level.
   * @returns promise that resolves to an array of ids representing the customers with details.
   */
  const fetchCustIdsWithDetails = async (borrowerId: number, asOfDate: string, arCollateralId: number, isChildCustomerOnly: boolean) => {
    try {
      const response = await axiosInstance.request({
        url: `${reportsAPI.arIneligibleReport.GET_UPC_CUST_IDS_WITH_DETAILS}/${props.ineligibleCode}`,
        method: GET,
        params: { borrowerId, asOfDate, arCollateralId, isChildCustomerOnly }
      });
      return response.data as number[];
    } catch (error) {
      console.log('INELIGIBLE DETAILS BY CODE ERROR: ', error);
      return [] as number[];
    }
  };

  /**
   * Maps through the given report and updates each item's `hasDetails` property based on certain criteria.
   *
   * @param report An array of `IARIneligible` objects representing the report items to be processed.
   * @param customerIds An array of customer IDs used to determine if an item has details.
   * @returns The updated array of `IARIneligible` objects with the `hasDetails` property set accordingly.
   */
  const mapHasDetails = (report: IARIneligible[], customerIds: number[]) => {
    return report.map((item: IARIneligible) => {
      if (item.ineligibleCode === 'DLQ') {
        item.hasDetails = true;
      } else if (codesWithNoDetails.includes(item.ineligibleCode)) {
        item.hasDetails = false;
      } else {
        item.hasDetails = customerIds.includes(item.arCustomerId);
      }

      return item;
    });
  };

  /**
   * Calculates the ineligible amount based on the provided cap amount details or ineligible records.
   * @param capDetails The cap amount details.
   * @returns The amount for the ineligible.
   */
  const getIneligibleTypeTotal = (capDetails?: ICapDetails) => {
    if (props.isUltimateParent) {
      return props.ineligibleTotal ?? 0.0;
    } else {
      const currentIneligibleTypeTotal: number = ineligibleTypeTotal(props.ineligibleRecords);
    
      if (capDetails) return currentIneligibleTypeTotal + capDetails.capAddBack;
      else return currentIneligibleTypeTotal;
    }
  };

  return (
    <Accordion
      key={props.ineligibleCode}
      expanded={isOpen}
      onChange={handleChange(props.ineligibleDescription)}
      sx={styles.accordion}
      disableGutters
      elevation={0}
      square
    >
      <AccordionSummary
        aria-controls={props.ineligibleDescription}
        id={props.ineligibleCode}
        expandIcon={
          isLoading ? 
          <CircularProgress
            color='inherit'
            size={15}
            sx={styles.loadingIcon}
          />
          :
          <ArrowDropDownIcon
            aria-label='Expand icon'
            sx={styles.accordionSummaryFontSize}
          />
        }
        sx={styles.accordionSummary}
      >
        <Box sx={styles.accordionRow}>
          <Typography fontSize='1rem' fontWeight='bold'>{props.ineligibleDescription}</Typography>
          <Typography fontSize='0.875rem'>
            {formatNumber(
              { style: 'currency', currency: props.currencyCode ?? 'USD', currencySign: 'accounting' },
              getIneligibleTypeTotal(props.capDetails),
              props.exchangeRate
            )}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>
        <Grid container>
          <Grid item xs={12}>
            <ARIneligibleTable
              isLoading={props.isLoading}
              arIneligible={custReport}
              order={props.order}
              setOrder={props.setOrder}
              orderBy={props.orderBy}
              setOrderBy={props.setOrderBy}
              amountType={props.ineligibleDescription}
              parentIds={props.parentIds}
              custIdsWithDetails={props.custIdsWithDetails}
              currencyCode={props.currencyCode}
              exchangeRate={props.exchangeRate}
              isUltimateParent={props.isUltimateParent}
              capDetails={props.capDetails}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default IneligibleRows;