import colors from '../../../utility/colors';

const styles = {
  modalBackground: {
    bgcolor: '#FFFFFF',
    borderRadius: '0.2rem',
    left: '50%',
    position: 'absolute',
    boxShadow: 15,
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },

  headingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    m: 0,
    pb: '17.5px',
    pl: '21px',
    pr: '21px',
    pt: '24px',
  },

  headingTitle: {
    color: '#1B1E21',
    fontSize: '18px',
    fontWeight: 600,
  },

  closeButton: {
    cursor: 'pointer',
  },

  divider: {
    bgcolor: '#EBEBEB',
    ml: '13.5px',
    mr: '19.5px',
    opacity: 0.5,
    width: '500px',
  },

  descriptionContainer: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'center',
    margin: '30px 21px 30px 21px',
    width: '450px'
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    mb: '21px',
    mr: '24.5px',
    mt: '15.5px',
  },

  button1: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    minWidth: '82px',
    borderColor: colors.PRIMARY,
    color: colors.PRIMARY,
    whiteSpace: 'nowrap',
  },

  button2: {
    height: '31px',
    textTransform: 'capitalize',
    minWidth: '82px',
    backgroundColor: colors.PRIMARY,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: colors.PRIMARY_DARK,
    },
  },

  cancelButton: {
    height: '31px',
    textTransform: 'capitalize',
    mb: '21px',
    mr: '24.5px',
    ml: '24.5px',
    mt: '15.5px',
  },

  errorButton: {
    height: '31px',
    textTransform: 'capitalize',
    minWidth: '82px',
    backgroundColor: colors.ERROR,
    '&:hover': {
      backgroundColor: colors.ERROR_DARK,
    },
  },
};

export default styles;
