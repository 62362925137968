const styles = {
  tableContainerCustView: {
    width: '100%',
    maxHeight: '30rem',
    paddingX: '3px',
    marginBottom: '2rem',
    paddingBottom: '0.5px',
  },
  tableActionFooterCustView: {
    textAlign: 'center',
    backgroundColor: '#EAEAEA',
    color: '#000',
    paddingRight: '0.5rem',
    paddingLeft: 0,
    borderBottom: 0,
    minWidth: '1.5rem',
    maxWidth: '1.5rem',
  },
  tableTextFooterCustView: {
    backgroundColor: '#EAEAEA',
    color: '#000',
    textAlign: 'right',
    fontSize: '15px',
    fontWeight: 'bold',
    '&:last-of-type': {
      paddingRight: '1.5rem'
    },
    borderBottom: 0,
  },
  tableTextBodyLeftCustView: {
    textAlign: 'left',
    minWidth: '19rem',
  },
  tableTextBodyNumberCustView: {
    minWidth: '5.5rem',
  },
  tableTextBodyIneligibleAmtCustView: {
    minWidth: '5.5rem',
  },
  tableContainerIneligibleView: {
    width: '100%',
    maxHeight: '30rem',
    marginBottom: '2rem',
    paddingBottom: '0.5px',
  },
  tableActionFooterIneligibleView: {
    textAlign: 'center',
    color: '#000',
    margin: 0,
    minWidth: '1.2rem',
    border: 0,
    padding: 0
  },
  tableTextFooterIneligibleView: {
    color: '#000',
    textAlign: 'right',
    fontSize: '15px',
    fontWeight: 'bold',
    '&:last-of-type': {
      paddingRight: 0
    },
    border: 0,
  },
  tableTextBodyLeftIneligibleView: {
    textAlign: 'left',
    minWidth: '19rem',
  },
  tableTextBodyNumberIneligibleView: {
    minWidth: '5.5rem',
  },
  tableTextBodyIneligibleAmtIneligibleView: {
    minWidth: '5.5rem',
  },
  borderedTop: {
    paddingTop: '1rem',
    borderTop: '2px solid #E9ECEF',
  },
  borderedBottom: {
    paddingBottom: '1rem',
    borderBottom: '2px solid #E9ECEF',
  },
  rollForwardVariance: {
    minWidth: '10rem',
    maxWidth: '10rem',
  },
  tableFooterLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%'
  },
  tableRowVariance: {
    paddingBottom: '0.4rem',
  },
  tablePadding: {
    paddingBottom: '0.4rem',
    paddingTop: 0
  }
}
export default styles;