import yup from './index';

const customRuleSchema = yup.object().shape({
  customRules: yup.array().of(
    yup.object().shape({
      expression: yup.array().of(
        yup.object().shape({
          fieldName: yup.string().trim().required('Field Name is required'),
          operator: yup.string().trim().required('Operator is required'),
          value: yup.array().of(yup.string()).min(1, 'Value is required'),
          secondOperator: yup.boolean(),
        })
      ),
      collateral: yup.object().shape({
        label: yup.string().trim().required('Collateral is required')
      }).required('Collateral is required').nullable(),
    })
  ),
  defaultCollateral: yup.object().shape({
    label: yup.string().trim().required('Collateral is required')
  }).required('Collateral is required').nullable(),
});

export default customRuleSchema;