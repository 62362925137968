import { Box, Button, Typography } from '@mui/material';
import PageNotFound from '../../assets/images/404.png';
import LoanWatchLogo from '../../assets/images/logo.png';
import styles from './styles';
const ErrorPage = () => {
  return (
    <Box>
      <Box sx={styles.pageNotFoundContainer}>
      <img src={PageNotFound} alt='404 Image' aria-label='404 Image' width={389} tabIndex={0} />
      <img style={styles.p5} src={LoanWatchLogo} alt='404 Image' aria-label='404 Image' width={182} tabIndex={1} />
      <Typography
        variant='h1'
        color='primary'
        sx={styles.bold}
      >
        We've lost this page
      </Typography>
      <Typography
        variant='subtitle1'
        color='primary'
      >
        Sorry, the page you are looking for doesn't exist or has been moved
      </Typography>
      <Button
        variant='contained'
        href='/'
      >
        Go Back Home
      </Button>
      </Box>
    </Box>
  )
}

export default ErrorPage;