const styles = {
  headerBackground: {
    backgroundColor: '#FEFEFE',
    py: 2.5,
    mb: '20px'
  },
  headerTitleText: {
    fontWeight: 'bold',
    color: '#010953',
    fontSize: '18px',
    ml: '24px',
  },
  headerContainer: {
    backgroundColor: '#FEFEFE',
    paddingRight: '1.5rem',
    paddingY: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerActionWrapper: {
    display: 'flex',
    gap: '0.5rem'
  },
  headerButtons: {
    height: '2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

export default styles;
