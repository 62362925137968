import { TableCell, tableCellClasses, TableRow } from "@mui/material";
import { styled } from '@mui/material/styles';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F7F7F7',
    color: 'inherit',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.9rem',
  },
  border: 0,
  color: 'inherit',
  paddingTop: 0,
  paddingBottom: 0,
  height: '3rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F7F7F7',
    '&:hover': {
      backgroundColor: 'rgba(235,235,235,0.5)',
      color: '#0075AD',
      textDecoration: 'underline',
    },
  },
  '&:nth-of-type(odd)': {
    '&:hover': {
      backgroundColor: 'rgba(235,235,235,0.5)',
      color: '#0075AD',
      textDecoration: 'underline',
    },
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));