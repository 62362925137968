import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import zIndex from '@mui/material/styles/zIndex';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import colors from './utility/colors';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import { ExpandMore } from '@mui/icons-material';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    w1280: true;
    w1366: true;
    w1440: true;
    w1600: true;
    w1920: true;

    // overrides for calendar field handling
    w1379: true;
    w1199: true;
    w1058: true
    w599: true;
    w340: true;
  }
}

export const theme = createTheme({
  // For more information on how to customize the MUI Theme
  // https://mui.com/material-ui/customization/default-theme/
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      w1280: 1280,
      w1366: 1366,
      w1440: 1440,
      w1600: 1600,
      w1920: 1920,

      // overrides for calendar field handling
      w1379: 1379,
      w1199: 1199,
      w1058: 1058,
      w599: 599,
      w340: 340,
    },
  },
  palette: {
    primary: {
      main: colors.PRIMARY,
      dark: colors.PRIMARY_DARK,
      light: colors.PRIMARY_LIGHT,
    },
    secondary: {
      main: colors.SECONDARY,
    },
    error: {
      main: colors.ERROR,
      dark: colors.ERROR,
      light: colors.ERROR,
    },
    warning: {
      main: colors.WARNING,
    },
    info: {
      main: colors.INFO,
    },
    success: {
      main: colors.SUCCESS,
    },
    text: {
      primary: colors.PRIMARY_TEXT,
      secondary: colors.SECONDARY_TEXT,
    },
    action: {
      disabledOpacity: 0.38,
    },
  },

  // For more information on how to override Global props and styles
  // https://mui.com/material-ui/customization/theme-components/
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '31px',
          paddingLeft: '25px',
          paddingRight: '25px',
          textTransform: 'none',
          boxShadow: 'none',
        },
        contained: {
          '&:hover': {
            backgroundColor: '#0075AD',
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            backgroundColor: '#99BFED',
            color: '#FFFFFF',
            boxShadow: 'none',
          }
        },
        outlined: {
          border: '1px solid #010953',
          backgroundColor: '#FFFFFF',
          color: '#154A8A',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-input.Mui-disabled': { WebkitBoxShadow: '0 0 0 1000px #EAECEF inset', WebkitTextFillColor: '#000' },
          '& .MuiOutlinedInput-root.Mui-focused fieldset': { border: '3px solid #A6CBF3' },
          '& .MuiOutlinedInput-input': { WebkitBoxShadow: "0 0 0 1000px white inset" },
          'input::-ms-reveal': { display: "none" },
          '& .MuiSelect-icon.Mui-disabled': { backgroundColor: '#EAECEF' },
          '& .MuiIconButton-root.MuiAutocomplete-popupIndicator.Mui-disabled': { backgroundColor: '#EAECEF' },
        },
      },
    },
    MuiSelect:{
      defaultProps: {
        IconComponent: ExpandMore,
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: '14px',
        },
        clearIndicator: {
          visibility: 'visible',
        },
      },
      defaultProps: {
        popupIcon: <ExpandMore />,
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '.Mui-disabled': { backgroundColor: '#F7F7F7' },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': { WebkitBoxShadow: '0 0 0 1000px #EAECEF inset', WebkitTextFillColor: '#000' },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #A6CBF3' },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(0, 0, 0, 0)',
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
        },
        root: {
          fontSize: '14px',
          color: 'black',
        }
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            border: '3px solid #A6CBF3'
          },
        },
        head: {
          fontWeight: 'bold',
        },
        footer: {
          left: 0,
          bottom: 0,
          zIndex: 2,
          position: 'sticky',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          border: 'none',
          color: '#154A8A',
          fontSize: '16px',
          'select.MuiTablePagination-select.MuiNativeSelect-select.MuiNativeSelect-standard.MuiInputBase-input':
            {
              paddingLeft: '4px',
              paddingRight: '16px',
              paddingTop: 0,
              paddingBottom: 0,
            },
          '.MuiTablePagination-selectIcon': {
            color: '#154A8A',
            fontSize: '1rem',
          },
          'div.MuiInputBase-root.MuiInputBase-colorPrimary ': {
            marginLeft: 0,
            marginRight: '17.5px',
          },
          '.Mui-disabled .MuiSvgIcon-root': {
            color: '#A6A8AA',
          },
          'div.MuiTablePagination-actions': {
            marginLeft: '17.5px',
            display: 'flex',
          },
          '.MuiTablePagination-actions button': {
            margin: 0,
          },
          '.MuiTablePagination-actions .MuiButtonBase-root': {
            padding: 0,
          },
        },
      },
      defaultProps: {
        SelectProps: {
          inputProps: {
            'aria-label': 'rows per page',
            IconComponent: ExpandMoreIcon,
          },
          native: true,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          '.MuiTabs-indicator': {
            backgroundColor: '#010953',
            height: '4px',
            borderRadius: '8px',
          }
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#000000',
          textTransform: 'none',
          '&.Mui-selected': {
            color: '#000000',
          },
        },
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          color: '#fff',
          zIndex: zIndex.drawer + 1,
        }
      }
    }
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
