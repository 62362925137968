const styles = {
  childrenContainer: {
    paddingX: '1.2rem',
    marginTop: '-2rem',
    marginBottom: '1rem'
  },
  headerActionWrapper: {
    px: '1.5rem',
    display: 'flex',
    gap: '0.5rem'
  },
  headerButtons: {
    height: '2rem',
    whiteSpace: 'nowrap'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleContainer: {
    backgroundColor: '#FEFEFE',
    py: 2.5
  },
  title: {
    fontWeight: 'bold',
    color: '#010953',
    fontSize: '18px',
    ml: '24px',
  },
}
export default styles;