import { Box, Button } from "@mui/material"
import { IUsersAndRolesContext, UsersAndRolesContext } from "../../../../context/usersAndRolesContext"
import { useContext } from "react"
import styles from "./styles"
import PermissionBox from "../../users-and-roles-table/permission-box"
import axiosInstance from "../../../../service/axiosInstance"
import { rolePermissionAPI } from "../../../../service/api"
import { GET, NO_PERMISSION_MSG, PERMISSIONS, POST } from "../../../../utility/constants"
import { IAddPermissionModalProps, IPermissionAPI, IRole, IRoleAPI } from "../../../../interfaces/rolesPermissionInterface"
import { checkUserPermissions, getLocalStorageItem } from "../../../../utility/helper"
import ConfirmModal from "../../../modals/confirm-modal"

const AddPermission = ({buildRolesAndPermissions, setShowAddPermissionModal, setSelectedIndex, permissionList, isEditing, formik, setToasterMessage, setToasterOpen, setToasterSeverity, createdRoleFormik}: IAddPermissionModalProps) => {

  const {setIsDirty, showPrompt, setShowPrompt} = useContext(UsersAndRolesContext) as IUsersAndRolesContext;

  /** 
   * This function calls the API endpoint for retrieving all of the roles.
   * @returns The available roles in the application. See IRoleAPI interface for more information.
   */
  const getAllRoles = async () => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.GET_ALL_ROLES,
        method: GET,
        headers: {token : token !== undefined ? token : ''}
      })
      const roles : IRoleAPI[] = response.data;
      return roles;
    } catch (error) {
      console.log('GET ALL ROLES ERROR:',error);
    }
  }

  /**
   * This function calls the API endpoint for creating a new role.
   * @param roleName the name of the role to be created.
   * @returns the name of the successfully created role.
   */
  const createRole = async (roleName : string) => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.CREATE_ROLE,
        method: POST,
        headers: {token : token !== undefined ? token : ''},
        data: {name: roleName}
      })
      return response.data;
    } catch (error) {
      console.log('CREATE ROLE ERROR:',error);
    }
  }

  /** 
   * This function calls the API endpoint for retrieving all of the permissions.
   * @returns The available permissions in the application. See IPermissionAPI interface for more information.
   */
  const getAllPermissions = async () => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.GET_ALL_PERMISSIONS,
        method: GET,
        headers: {token : token !== undefined ? token : ''}
      })
      const permissions : IPermissionAPI[] = response.data;
      return permissions;
    } catch (error) {
      console.log('GET ALL PERMISSIONS ERROR:',error);
    }
  }

  /**
   * This function calls the API endpoint for adding permissions to the role.
   * @param roleId The id of the role to be added permissions on.
   * @param permissions The permissions to be added to the role.
   * @returns The permissions to added to the role.
   */
  const addPermissionToRole = async (roleId : string, permissions : IPermissionAPI[]) => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.ADD_PERMISSION_TO_ROLE,
        method: POST,
        params: {roleId: roleId},
        headers: {token : token !== undefined ? token : ''},
        data: permissions
      })
      return response;
    } catch (error) {
      console.log('ADD PERMISSION TO ROLE ERROR:',error);
    }
  }

  /**
   * This function closes the add permission modal.
   */
  const handleClose = () => {
    setIsDirty(false)
    setShowPrompt(false)
    setShowAddPermissionModal(false)
  };

  /**
   * 
   * @param values The values of the add role and add permission modal.
   */
  const handleSubmit = async (values: { roleList: IRole[]} | undefined) => {
    if (values !== undefined) {
      /**
       * First, the role is created.
       */
      await createRole(values.roleList[0].roleName);
      const role : IRole = {
        roleName: values.roleList[0].roleName,
        permissions: values.roleList[0].permissions
      }
      const roleToBeFound = values.roleList[0].roleName;
      const permissionsToBeFound = values.roleList[0].permissions;
      const roles = await getAllRoles();
      const permissions = await getAllPermissions();

      /**
       * Second, the permissions are added to the role.
       */
      if (roles !== undefined && permissions !== undefined) {
        const selectedRole = roles.filter(role => role.name === roleToBeFound);
        const permissionsToBeAdded = permissions.filter(permission => permissionsToBeFound.includes(permission.name));
        const response = await addPermissionToRole(selectedRole[0].id, permissionsToBeAdded);

        /**
         * If everything is successfull, the success toasters are shown and the roles and permissions table data is updated.
         * Otherwise, the error toaster is shown indicating that an error has occured.
         */
        if (response?.status === 200) {
          const updatedRoleList = await buildRolesAndPermissions();
          formik.setFieldValue('roleList', updatedRoleList);
          const roleNames = updatedRoleList.map(role => role.roleName);
          const index = roleNames.indexOf(role.roleName);    
          createdRoleFormik.resetForm();
          setSelectedIndex(index);
          setToasterOpen(true);
          setToasterMessage(`${values.roleList[0].roleName} role has been created!`);
          setToasterSeverity('success');
        } else {
          setToasterOpen(true);
          setToasterMessage('Failed to save data. Please check your inputs!');
          setToasterSeverity('error');
        }
        setShowAddPermissionModal(false)
      }
    }
  };

  /**
   * This function checks if the user has a specific permission.
   * @param func The function to be called if the user has the required permission.
   * @param permission The permission to be checked.
   * @param args The arguments to be given to the function.
   */
  const checkPermission = async (func: Function, permission: string, args: any) => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), permission);
    if(isPermitted){
      func(args)
      return
    }
    setShowAddPermissionModal(false);
    setToasterOpen(true);
    setToasterMessage(NO_PERMISSION_MSG);
    setToasterSeverity('error');
  }

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.fixedheightBox}>
        <PermissionBox 
          permissionList={permissionList}
          index={0}
          formik={createdRoleFormik ?? formik}
          isEditing={isEditing}
        />
      </Box>
      <Box sx={styles.bottomActionsButtonContainer}>
        <Button
          disabled={!isEditing}
          variant='outlined'
          sx={styles.cancelButton}
          onClick={() => {handleClose()}}
        >
          Cancel
        </Button>
        <Button
          disabled={!isEditing}
          variant='contained'
          type='submit'
          sx={styles.saveButton}
          onClick={() => {checkPermission(handleSubmit, PERMISSIONS.ADD_ROLE, createdRoleFormik?.values)}}
        >
          Save As Default
        </Button>
      </Box>
      <ConfirmModal
        open={showPrompt}
        onClose={() => {createdRoleFormik.resetForm(); handleClose();}}
        onConfirm={() => {setShowPrompt(false);}}
        onButtonClose={() => {setShowPrompt(false);}}
        promptChecker
        title={`Confirm Navigation`}
        description={`You have unsaved changes. Are you sure you want to leave this page?`}
        yesButtonText={`Keep Editing`}
        noButtonText={`Discard Changes`}
        confirmOnly
      />
    </Box>
  )
}

export default AddPermission
