import * as Yup from 'yup';

const customerSchema = Yup.object({
  creditLimit: Yup.number()
    .nullable(true)
    .typeError('Credit Limit must be a number'),
  dbRating: Yup.string()
    .nullable(true),
  custSrcId: Yup.string()
    .required('Customer ID is required').trim(),
  custName: Yup.string()
    .required('Customer Name is required').trim(),
});
export default customerSchema;
