import { ActionType, CalcIneligibleActions } from '../actions/calcIneligibleActions';
import { IIneligibleSettingAPI, IUPCIneligibleSettingAPI } from '../interfaces/ineligibleSettingInterface';

export interface CalcIneligibleState {
  title: string;
  content: string;
  hasInitiatedSubscription: boolean;
  isLoading: boolean;
  show: boolean;
  link: string;
  cancelled: boolean;
  failed: boolean;
  selectedClientId: number | null;
  selectedCollateralId: number | null;
  isParentClientLevel: boolean;
  ineligibleSettings: Array <IIneligibleSettingAPI | IUPCIneligibleSettingAPI>;
}

export const initialState: CalcIneligibleState = {
  title: "",
  content: "",
  hasInitiatedSubscription: false,
  isLoading: false,
  show: false,
  link: "/",
  cancelled: false,
  failed: false,
  selectedClientId: null,
  selectedCollateralId: null,
  isParentClientLevel: false,
  ineligibleSettings: [],
};

const calcIneligibleReducer = (state: CalcIneligibleState, action: CalcIneligibleActions): CalcIneligibleState => {
  // Turned into an if statement since it only has 2 cases as of the moment
  if (action.type === ActionType.CalculateIneligible) {
    return {
      ...state,
      ...action.payload
    }
  } else {
    return state;
  }
};

export default calcIneligibleReducer;