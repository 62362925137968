const styles = {
  labelAndTextField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '1rem',
  },
  gridContainer: {
    mt: 0.5,
    px: '0.875rem',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonGroup: {
    alignSelf: 'flex-end',
    display: 'flex',
    gap: '0.5rem',
    marginBottom: '1rem',
    marginTop: '2rem',
  },
  cancelButton: {
    textTransform: 'none',
    height: '2rem',
    width: '5rem',
  },
  saveButton: {
    textTransform: 'none',
    height: '2rem',
    width: '5rem',
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#99BFED',
    },
  },
  fieldWidth: {
    width: '18rem',
    height: '30px',
    fontSize: '14px',
    marginBottom: '19px',
    position:'relative',
    bottom:'2px',
    '& .MuiOutlinedInput-root': {
      // width: '100px',
      fontSize: '14px',
      backgroundColor: 'white',
      height: '30px',
      borderRadius: '5px',
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
    },
    '& .Mui-error': {
      fontSize: '12px',
      marginTop: '1px',
      marginX: '0',
      whiteSpace: 'nowrap',
    },
  },
  fieldPosition: {
    position: 'relative',
    bottom: '2px',
  },
  numberField: {
    width: '18rem',
    position: 'relative',
    bottom: '8px',
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    height: '30px',
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      // width: '100px',
      fontSize: '14px',
      backgroundColor: 'white',
      height: '30px',
      borderRadius: '5px',
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
    },
    '& .Mui-error': {
      fontSize: '12px',
      marginTop: '1px',
      marginX: '0',
      whiteSpace: 'nowrap',
    },
  },
  requiredText: {
    marginBottom: '19px',
    fontSize: '0.875rem',
    marginRight: '0.1rem',
    whiteSpace: 'nowrap',
  },
  asterisk:{
    color: '#AE2633'
  },
  halfWidth: {
    width: '6rem',
    height: '30px',
    marginBottom: '19px',
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      // width: '100px',
      fontSize: '14px',
      backgroundColor: 'white',
      height: '30px',
      borderRadius: '5px',
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
    '& .Mui-error': {
      fontSize: '12px',
      marginTop: '1px',
      marginX: '0',
    },
  },
  smallField: {
    width: '4rem',
    height: '30px',
    fontSize: '14px',
    marginBottom: '19px',
    '& .MuiOutlinedInput-root': {
      // width: '100px',
      fontSize: '14px',
      backgroundColor: 'white',
      height: '30px',
      borderRadius: '5px',
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
    },
    '& .Mui-error': {
      fontSize: '12px',
      marginTop: '1px',
      marginX: '0',
    },
  },
  formLabel: {
    marginTop: '2rem',
  },
  divider: {
    marginBottom: '2rem',
    marginTop: '1rem',
  },
  stateAndZipContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '2.75rem',
  },
  stateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginBottom: '1.5rem',
  },
  zipContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginBottom: '1.5rem',
  },
  hidden: {
    display: 'none'
  }
};

export default styles;
