const styles = {
  avatarContainer:{
    display: 'flex', 
    flexDirection: 'column',
    width: '100%'
  },
  centerChip:{
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px'
  },
  editAvatar:{
    height: 25,
    color: '#fff',
    position:'absolute',
    width: 25
  },
  helperText:{
    color: '#A6A8AA',
    textAlign: 'center'
  },
  uploadBtn:{
    alignSelf: 'center',
    borderColor: '#d2d2d2',
    '&:hover':{
      backgroundColor: '#9EA6AD',
      opacity: 0.5
    }
  },
  userAvatar:{
    height: 150,
    width: 130,
    maxHeight: { xs: 130, md: 130 },
    maxWidth: { xs: 130, md: 130 }
  },
};

export default styles;