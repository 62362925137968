import { Box, Button, FormHelperText} from '@mui/material';
import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import styles from './styles';
import { LenderSettingsContext } from '../../../context/lenderSettingsContext';
import { BankLogoContext } from '../../../context/bankLogoContext';

export interface LenderAvatarUploadProps {
  onChange?: (file: File) => void;
  logo: string;
  isDynamic?: boolean;
}

const LenderAvatarUpload: React.FC<LenderAvatarUploadProps> = ({ onChange, logo, isDynamic }) => {
  const { canUploadCompanyLogo }  = React.useContext(LenderSettingsContext);
  const { state }                 = React.useContext(BankLogoContext);
  const [hover,sethover]          = React.useState(false);
  const [image, setImage]         = React.useState(state.logoDataUrl);
  const [fileError, setFileError] = React.useState('');
  const [disabled, setDisabled]   = React.useState(false);

  React.useEffect(() => {
    setDisabled(!(canUploadCompanyLogo && Boolean(isDynamic)));
  }, [canUploadCompanyLogo, isDynamic])

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptableFileTypes = ['JPEG', 'JPG', 'PNG'];
    if (!e.target.files?.length) return;

    const file = e.target.files[0];
    const fileType = file?.type.split('/')[1] ?? '';

    // Check if file type is allowed
    if(!acceptableFileTypes.includes(fileType.toUpperCase())) {
      setFileError(`File must be ${acceptableFileTypes.join(', ')}.`);
      return;
    }

    // File size must not exceed 10MB
    if (file.size > (1048576 * 10)){
      setFileError('File size must be less than 10MB.');
      return;
    }

    (onChange as (file: File) => void)(file);

    setFileError('');
    setImage(URL.createObjectURL(file));
  }

  const getHelperText = () => {
    if (disabled) {
      return null;
    } else if (!fileError) {
      return (
        <FormHelperText tabIndex={0} sx={styles.helperText}>
          Allowed File type png, jpg, jpeg <br/>
          Recommended Size 200 x 64 pixels
        </FormHelperText>
      )
    } else {
      return (
        <FormHelperText tabIndex={0} sx={styles.textError}>
          {fileError}
        </FormHelperText>
      )
    }
  }

  return (
    <Box sx={styles.lenderAvatarContainer}>
      <Button
          component='label'
          sx={styles.uploadBtn}
          variant='outlined'
          onMouseOver={()=>sethover(true)} 
          onMouseOut={()=>sethover(false)} 
          disabled={disabled}
      >
        <Box
          component='img'
          sx={styles.lenderAvatar}
          alt='Lender Logo'
          src = {image ?? logo}
        />
        {!hover ? '' : 
          <EditIcon sx={styles.editAvatar}></EditIcon>
        }
        <input
            aria-label='upload-image-input'
            type='file'
            accept='image/*'
            onChange={onChange && onImageChange}
            hidden
          />
      </Button>
      {getHelperText()}
    </Box>
  );
};

export default LenderAvatarUpload;