import * as Yup from 'yup';

const hasUppercase = /[A-Z]+/;
const hasLowercase = /[a-z]+/;
const hasDigit = /\d+/;
const hasSpecialCharacter = /\W+/;

const updatePasswordSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Your password must be at least 8 characters')
    .required('Password is required')
    .matches(hasUppercase, 'Must have at least 1 uppercase character')
    .matches(hasLowercase, 'Must have at least 1 lowercase character')
    .matches(hasDigit, 'Must have at least 1 numerical number')
    .matches(hasSpecialCharacter, 'Must have at least 1 special character'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please confirm your password')
});

export default updatePasswordSchema