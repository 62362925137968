import NavigationWarningProvider from '../../context/navigationWarningContext';
import CreateReservesProvider, { CreateReservesContext } from '../../context/createReservesContext';
import ReserveHeader from '../../components/reserve/header';
import ReservesTable from '../../components/reserve/table';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { ICreateReservesContext } from '../../interfaces/reservesInterface';
import styles from './styles';

const ReservePage = () => {
  const {viewReserves} = useContext(CreateReservesContext) as ICreateReservesContext;
  return (
    <Box sx={{...(!viewReserves && styles.hidden)}}>
      <ReserveHeader/>
      <ReservesTable/>
    </Box>
  )
}

const Reserve = () => {
  return (
    <NavigationWarningProvider>
      <CreateReservesProvider>
        <ReservePage />
      </CreateReservesProvider>
    </NavigationWarningProvider>
  )
}

export default Reserve;