const styles = {
  mainContainer: {
    width: '100%',
    height: '500px'
  },
  fixedheightBox: {
    border: '1px solid #E0E0E0',
    borderRadius: '3px',
    maxHeight: '450px',
    overflowY: 'auto'
  },
  gridContainer: {
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  rightAlignedText: {
    textAlign: 'right',
    marginRight: '5px'
  },
  gridField: {
    width: '220px',
    height: '30px',
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      width: '100%',
      height: '30px',
      backgroundColor: 'white',
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
    },
    '& .Mui-error': {
      fontSize: '11px',
      marginTop: '1px',
      marginX: '0',
    }
  },
  hidden: {
    display: 'none',
  },
  bottomActionsButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: '15px',
    pr: '40px'
  },
  cancelButton: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    width: '82px',
  },
  saveButton: {
    height: '31px',
    textTransform: 'capitalize',
    width: '165px',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },
  label: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    marginTop: '0.5rem',
   '& .MuiFormLabel-asterisk': {
      color: '#db3131',
    },
    color: '#000',
  },
  permissionParentContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    margin: '0.5rem'
  },
  permissionChildContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
};

export default styles;
