import React, { Dispatch, FC, HTMLAttributes, SetStateAction, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AlertColor, Autocomplete, AutocompleteRenderGetTagProps, AutocompleteRenderInputParams, Box, Button, Chip, CircularProgress, Grid, IconButton, LinearProgress, Paper, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import GeneralBreadcrumbs from '../../../components/breadcrumb';
import ComboBox from '../../../components/common/combo-box';
import MenuButton from '../../../components/common/menu-button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import ReportsTitle from '../../../components/reports-title';
import styles from './styles';
import { checkFileNameIfExisting, checkUserPermissions, formatCurrency, formatDate, getARCollateralRequest, getBBPeriodRequest, getClientRequest, getCurrencies, getFileNameArCompare, getOptionLabel, getOptions, getParamsByType, isOptionEqualToValue, getUpcToCurrencyIds, checkRatesByToCurrencyIds } from '../../../utility/helper';
import { IComboBoxIds, IComboBoxSetStates, IOption } from '../../../interfaces/comboBox';
import { IColumn } from '../../../components/common/table-with-left-pinned-header';
import ARComparisonTable from './table';
import { AxiosResponse } from 'axios';
import { AMERICAN_DATE_FORMAT, API_DOMAIN, GET, NO_PERMISSION_MSG, PERMISSIONS, POST } from '../../../utility/constants';
import LaunchIcon from '@mui/icons-material/Launch';
import { useCalendarFieldBreakpoints } from '../../../utility/hooks';
import Toaster from '../../../components/toaster';
import { AuthContext } from '../../../context/authContext';
import { ICurrency } from '../../../interfaces/multiCurrencyInterface';
import axiosInstance from '../../../service/axiosInstance';
import DisabledComponentsContainer from '../../../components/common/disabled-components-container';
import { multiCurrencyAPI, reportsAPI } from '../../../service/api';
import { IExportReportWithPayload } from '../../../interfaces/exportReportInterface';
import { ExportReportContext } from '../../../context/exportReportContext';

export interface IAsOfDateToCurrencyIds {
  asOfDate: string;
  toCurrencyIds: number[] | undefined;
}

export interface IAsOfDateExchangeRate {
  [key: string]: { 
    [key: number]: number,
  }
}

const ARIneligibleCompare: FC = () => {
  const navigate              = useNavigate();
  const theme                 = useTheme();
  const belowMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const belowSmallBreakpoint  = useMediaQuery(theme.breakpoints.down('sm'));
  const calendarFieldBP       = useCalendarFieldBreakpoints();
  const selectedClientContext = useContext(SelectedClientContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const borrowerId                      = searchParams.get('clientId') ?? '0';
  const arCollateralId                  = searchParams.get('arCollateralId') ?? '0';
  const bbPeriodIds                     = searchParams.get('bbPeriodIds') ?? '0';
  const endDate                         = searchParams.get('endDate') ?? '0';

  const {state}                         = useContext(AuthContext)
  const { setShow,
          exports,
          setExports }                  = useContext(ExportReportContext);

  const [clients, setClients]                           = useState<IOption[]>([]);
  const [currencies, setCurrencies]                     = useState<IOption[]>([]);
  const [selectedClient, setSelectedClient]             = useState<IOption | null>(null);
  const [clientInput, setClientInput]                   = useState<string>('');
  const [arCollaterals, setARCollaterals]               = useState<IOption[]>([]);
  const [selectedARCollateral, setSelectedARCollateral] = useState<IOption | null>(null);
  const [arCollateralInput, setARCollateralInput]       = useState<string>('')
  const [bbPeriods, setBBPeriods]                       = useState<IOption[]>([]);
  const [_selectedBBPeriod, setSelectedBBPeriod]        = useState<IOption | null>(null);
  const [selectedBBPeriods, setSelectedBBPeriods]       = useState<IOption[]>([]);
  const [selectedCurrency, setSelectedCurrency]         = useState<IOption | null>(null);
  const [bbPeriodInput, setBBPeriodInput]               = useState<string>('');
  const [currencyInput, setCurrencyInput]               = useState<string>('');
  const [columns, setColumns]                           = useState<IColumn[]>([]);
  const [rows, setRows]                                 = useState<any[]>([]);
  const [firstLoad, setFirstLoad]                       = useState<boolean>(true);
  const [isDisabled, setIsDisabled]                     = useState<boolean>(false);
  const [generate, setGenerate]                         = useState<boolean>(false);
  const [isLoading, setIsLoading]                       = useState<boolean>(false);
  const [displayNoTable, setDisplayNoTable]             = useState<boolean>(false);
  const [exporting, setExporting]                       = useState<boolean>(false);
  const [canExport, setCanExport]                       = useState<boolean>(false);
  const [isToasterOpen, setIsToasterOpen]               = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]             = useState<string>('');
  const [toasterSeverity, setToasterSeverity]           = useState<AlertColor>('success');
  const [canViewReport, setCanViewReport]               = useState<boolean>(false);
  const [clientCurrency, setClientCurrency]             = useState<ICurrency>();
  const [asOfDatesWithToCurrencyIds, setAsOfDatesWithToCurrencyIds] = useState<IAsOfDateToCurrencyIds[]>([]);
  const [asOfDateRatesMap, setAsOfDateRatesMap]         = useState<IAsOfDateExchangeRate>({});
  
  const filteredWithoutUPCRelation = useMemo(() =>
    clients.filter(client => (!client.parentClient && !client.parentClientFk) || client.parentClient)
  , [clients]);

  const isUltimateParent = useMemo(() =>
    selectedClientContext.selectedClient?.parentClient
  , [selectedClientContext.selectedClient]);

  const parsedBorrowerId = useMemo(() => parseInt(borrowerId), [borrowerId]);

  const params = { borrowerId, invCollateralId: '0', bbPeriodId: '0', arCollateralId, customerId: '0' };

  useEffect(() => {
    (async () => {
      const canViewReport = await getPermission();
      if (canViewReport) {
        await fetchCurrency();
        await getOptions({ type: 'client', params, getSetStatesByType, setSelectedByType, mainRequest: getClientRequest()});
      }
    })();
  }, [])

  const getPermission = async () => {
    const isPermitted = await checkUserPermissions(state.uid, PERMISSIONS.EXPORT_INELIGILE_COMPARE_REPORT)
    const canView = await checkUserPermissions(state.uid, PERMISSIONS.VIEW_INELIGILE_COMPARE_REPORT)

    if (!isPermitted) {
      setToasterMessage('User is not permitted to export ineligible compare report');
      setToasterSeverity('error');
      setIsToasterOpen(true);
    } else {
      setCanExport(isPermitted)
    }

    if (!canView) {
      setToasterMessage('User is not permitted to view the AR Compare Report');
      setToasterSeverity('error');
      setIsToasterOpen(true);
    } else {
      setCanViewReport(canView)
    }

    return Boolean(canView);
  }

  useEffect(() => {
    const parsedBorrowerId = parseInt(borrowerId);
    if (parsedBorrowerId) getOptions({ type: 'arCollateral', params, getSetStatesByType, setSelectedByType, mainRequest: getARCollateralRequest(parsedBorrowerId), requestForDefault: true});
  }, [borrowerId])

  useEffect(() => {
    const parsedBorrowerId = parseInt(borrowerId);
    const parsedARCollateralId = parseInt(arCollateralId);

    if (parsedBorrowerId && parsedARCollateralId) {
      getOptions({ type: 'bbPeriod', params, getSetStatesByType, setSelectedByType, mainRequest: getBBPeriodRequest(parsedBorrowerId), requestForDefault: false, filter: 'ineligible'});
    }
  }, [borrowerId, arCollateralId])

  useEffect(() => {
    const parsed = getParsedPeriodIds();
    const filtered = bbPeriods.filter((bbPeriod: IOption) => parsed.includes(bbPeriod.recordId));
    setSelectedBBPeriods(filtered);
  }, [bbPeriods])

  useEffect(() => {
    (async () => {
      const parsedBorrowerId = parseInt(borrowerId);
      const parsedARCollateralId = parseInt(arCollateralId);
      const parsedBBPeriodIds = getParsedPeriodIds();
      const parsedEndDates = getParsedEndDates();

      if (parsedBorrowerId && parsedARCollateralId && parsedBBPeriodIds.length > 1) {
        setIsLoading(true);
        await getData(parsedBBPeriodIds);
        setIsLoading(false);
      }
      // getUpcData is not called here because it always needs exchangeRates unlike collateral level where it is not yet implemented
    })();
  }, [generate, clientCurrency, isUltimateParent])

  /**
   * Effect hook that triggers fetching an ineligible compare report based on certain conditions and dependencies.
   *
   * @param isUltimateParent Indicates if the current context is an ultimate parent.
   * @param firstLoad Indicates if this is the first load of the component or data.
   * @param ineligibleViewReport An array containing the ineligible view report data.
   * @param collateralRatesMap A map of collateral rates where the key is the currency ID and the value is the rate.
   */
  useEffect(() => {
    if (isUltimateParent && firstLoad && Object.keys(asOfDateRatesMap).length > 0) {
      const parsedEndDates = getParsedEndDates();
      getUpcData(parsedEndDates);
    };
  }, [isUltimateParent, firstLoad, asOfDateRatesMap])

  useEffect(() => {
    if (isUltimateParent && !firstLoad && Object.keys(asOfDateRatesMap).length > 0) {
      const parsedEndDates = getParsedEndDates();
      getUpcData(parsedEndDates);
    };
  }, [generate])

  useEffect(() => {
    const isEmptyDropdown = (selectedARCollateral === null && !isUltimateParent) || selectedClient === null || selectedBBPeriods.length < 2;

    if (firstLoad || isEmptyDropdown) setIsDisabled(true);
    else setIsDisabled(false);
  }, [firstLoad, selectedARCollateral, selectedClient, selectedBBPeriods])

  /**
   * Effect hook that triggers fetching UPC as of dates based on the ultimate parent status and other dependencies.
   *
   * @param parsedBorrowerId The ID of the borrower used for fetching UPC as of dates.
   * @param isUltimateParent Indicates if the current context is an ultimate parent.
   * @param bbPeriods An array of periods related to the borrower.
   */
  useEffect(() => {
    if (isUltimateParent && bbPeriods.length === 0) {
      fetchUpcAsOfDatesByBorrowerId(parsedBorrowerId);
    }
  }, [parsedBorrowerId, isUltimateParent, bbPeriods])

  /**
   * Effect hook that triggers fetching client currency based on the selected client and other dependencies.
   *
   * @param selectedClient The currently selected client object.
   * @param isUltimateParent Indicates if the current context is an ultimate parent.
   * @param selectedCurrency The currently selected currency, or null if no currency is selected.
   */
  useEffect(() => {
    if (isUltimateParent && selectedClient?.currencyId && selectedCurrency === null) {
      fetchClientCurrencyByBorrowerCurrencyId(parseInt(selectedClient.currencyId));
    }
  }, [selectedClient, isUltimateParent, selectedCurrency])

  const handleNavigate = useCallback(() => navigate('/reports'), []);
  const paperComponent = useCallback((props: HTMLAttributes<HTMLElement>) => (<Paper sx={styles.dropdown}>{props.children}</Paper>), []);

  const handleGenerate = useCallback(() => {
    setGenerate(!generate);
    setIsDisabled(true);
    setFirstLoad(false);
    setDisplayNoTable(false);
  }, [generate])

  
  const fetchCurrency = async () => {
    const currencies        = await getCurrencies()
    const clientCurrencyId  = await getClientCurrencyId()

    if(currencies && clientCurrencyId){
      const temp = currencies.currencies.find(currency => currency.recordId === parseInt(clientCurrencyId))
      setClientCurrency(temp)
    }
  }

  /**
   * Fetches currency information by ID from the multi-currency API.
   * @param currencyId The ID of the currency to retrieve.
   * @returns A Promise that resolves to the currency data.
   */
  const getCurrencyById = async (currencyId: number) => {
    try {
      const response = await axiosInstance.request({
        url: `${multiCurrencyAPI.GET_CURRENCY_BY_ID}/${currencyId}`,
        method: GET,
      });
      return response.data as ICurrency;
    } catch (error) {
      console.log('GET CURRENCY BY ID ERROR: ', error);
    }
  }

  const getClientCurrencyId = async () => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/borrowers/${selectedClientContext.selectedClient?.recordId}`,
        method: GET
      });

      return response.data.currencyId;
    } catch (error) {
      console.log('GET CLIENT CURRENCY ID : ', error);
    }
  }

  /**
   * Fetches UPC to currency IDs for a given borrower ID and updates the state if the data is retrieved.
   *
   * @param borrowerId The ID of the borrower for whom UPC to currency IDs are fetched.
   */
  const fetchUpcToCurrencyIdsByBorrowerIdAndAsOfDates = async (borrowerId: number, asOfDates: string[]) => {
    const fetchedToCurrencyIds = await Promise.all(asOfDates.map(asOfDate => getUpcToCurrencyIds(borrowerId, asOfDate)));

    // use index to get associated asOfDate
    const currencyIds = fetchedToCurrencyIds.map((ids, index) => ({
      asOfDate: asOfDates[index],
      toCurrencyIds: ids,
    }));

    if (currencyIds) {
      setAsOfDatesWithToCurrencyIds(currencyIds);
    }
  };

  /**
   * Effect hook that triggers fetching UPC to currency IDs based on the selected client and other dependencies.
   *
   * @param isUltimateParent Indicates if the current context is an ultimate parent.
   * @param selectedClient The currently selected client object.
   * @param toCurrencyIds An array of currency IDs to determine if fetching is needed.
   */
  useEffect(() => {
    const upcSelected = Boolean(isUltimateParent && selectedClient);
    const hasEndDate = bbPeriodIds !== 'undefined' && bbPeriodIds !== '0';

    const asOfDates = bbPeriodIds.split(',');

    if (upcSelected && asOfDatesWithToCurrencyIds.length === 0 && hasEndDate) {
      fetchUpcToCurrencyIdsByBorrowerIdAndAsOfDates(selectedClient?.recordId as number, asOfDates);
    }
  }, [selectedClient, isUltimateParent, asOfDatesWithToCurrencyIds])

  /**
   * Fetches UPC as of dates for a given borrower ID and updates the state and search parameters if data is retrieved.
   * 
   * @param borrowerId The ID of the borrower for whom UPC as of dates are fetched.
   */
    const fetchUpcAsOfDatesByBorrowerId = async (borrowerId: number) => {
      const bbPeriodOpts: IOption[] | undefined = await getAvailableUpcDatesForReport(borrowerId);
      if (bbPeriodOpts) {
        setBBPeriods(bbPeriodOpts);
        searchParams.delete('bbPeriodId');
  
        if (parseInt(endDate) !== 0) {
          selectUpcAsOfDateOnLoad(bbPeriodOpts);
        }
      }
    };

  /**
   * Selects a UPC as of date from the given options based on the end date and updates the selected period.
   *
   * @param bbPeriodOpts An array of option objects representing available UPC as of dates.
   */
  const selectUpcAsOfDateOnLoad = (bbPeriodOpts: IOption[]) => {
    const selected = bbPeriodOpts.find(bbPeriod => formatDate(bbPeriod.label, 'YYYYMMDD') === endDate);
    setSelectedBBPeriod(selected ?? null);
  };

  /**
   * Fetches the client currency for a given currency ID and updates the selected client currency.
   *
   * @param currencyId The ID of the currency for which client currency is fetched.
   */
  const fetchClientCurrencyByBorrowerCurrencyId = async (currencyId: number) => {
    const clientCurrency = await getCurrencyById(currencyId);
    selectClientCurrencyOnLoad(clientCurrency);
  };

  /**
   * Updates the selected currency based on the provided client currency.
   *
   * @param clientCurrency The client currency object or undefined if no currency is provided.
   */
  const selectClientCurrencyOnLoad = (clientCurrency: ICurrency | undefined) => {
    if (clientCurrency) {
      const clientCurrencyOpt: IOption = {
        recordId: clientCurrency.recordId,
        label: `${clientCurrency.currencyCode} - ${clientCurrency.currencyName}`,
        default: true
      };
      setSelectedCurrency(clientCurrencyOpt);
    } else {
      setSelectedCurrency(null);
    }
  };

  useEffect(() => {
    if (isUltimateParent && selectedClient?.currencyId && selectedBBPeriods && asOfDatesWithToCurrencyIds.length) {
      fetchUpcExchangeRates(selectedClient);
    }
  }, [selectedClient, selectedBBPeriods, asOfDatesWithToCurrencyIds, isUltimateParent])

  /**
   * Effect hook that triggers fetching UPC exchange rates based on the selected client, period, and other dependencies.
   *
   * @param selectedClient The currently selected client object.
   * @param selectedBBPeriod The selected business period object.
   * @param toCurrencyIds An array of currency IDs to fetch exchange rates for.
   * @param isUltimateParent Indicates if the current context is an ultimate parent.
   */
  const fetchUpcExchangeRates = async (selectedClient: IOption) => {
    const exchangeRates = await Promise.all(asOfDatesWithToCurrencyIds.map(async (asOfDateToCurrencyIds: IAsOfDateToCurrencyIds) => {
      const rates = await checkRatesByToCurrencyIds(
        selectedClient.recordId,
        parseInt(selectedClient.currencyId as string),
        asOfDateToCurrencyIds.toCurrencyIds!,
        formatDate(asOfDateToCurrencyIds.asOfDate, 'YYYYMMDD')
      );
      const ratesMap = rates!.reduce((acc, exchangeRate) => {
        if (!acc[exchangeRate.toCurrencyId]) {
          acc[exchangeRate.toCurrencyId] = exchangeRate.currencyRate
        }
        return acc
      }, {});

      return {
        asOfDate: asOfDateToCurrencyIds.asOfDate,
        rates: ratesMap
      }
    }));

    const collateralRatesMap = exchangeRates.reduce((acc, exchangeRates) => {
      if (!acc[exchangeRates.asOfDate]) {
        acc[exchangeRates.asOfDate] = exchangeRates.rates;
      }
      return acc;
    }, {});
    setAsOfDateRatesMap(collateralRatesMap ?? []);
  };
  
  /**
   * This function gets the available as of dates for reports.
   * @param borrowerId The record id of the Ultimate Parent Client.
   */
  const getAvailableUpcDatesForReport = async (borrowerId: number) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_AVAILABLE_UPC_DATES,
        method: GET,
        params: { parentClientId: borrowerId }
      });

      const endDates: string[] = response.data;
      const bbPeriodOpts: IOption[] = endDates.map((endDate, index) => ({
        recordId: parseInt(formatDate(endDate, 'YYYYMMDD')),
        label: formatDate(endDate, AMERICAN_DATE_FORMAT),
        default: index === 0
      }))
      return bbPeriodOpts;
    } catch (error) {
      console.log('GET AVAILABLE UPC AS OF DATES FOR REPORTS ERROR: ', error);
    }
  };


  const getParsedPeriodIds = () => {
    if (bbPeriodIds !== '0') return bbPeriodIds.split(',').map((str) => parseInt(str)).sort((a: number, b: number) => b - a);
    else return [0];
  };

  const getParsedEndDates = () => {
    if (bbPeriodIds !== '0') return bbPeriodIds.split(',');
    else return ['0'];
  }

  const getSetStatesByType = (type: IComboBoxIds) => {
    switch (type) {
      case 'client': return { setOptions: setClients, setSelected: setSelectedClient, setInput: setClientInput };
      case 'arCollateral': return { setOptions: setARCollaterals, setSelected: setSelectedARCollateral, setInput: setARCollateralInput };
      case 'currency': return { setOptions: setCurrencies, setSelected: setSelectedCurrency, setInput: setCurrencyInput }
      default: return { setOptions: setBBPeriods, setSelected: setSelectedBBPeriod, setInput: setBBPeriodInput };
    }
  };

  const setSelectedByType = (selected: IOption, type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    if (type === 'client') selectedClientContext?.setSelectedClient(selected);
    setStates.setSelected(selected);
    setStates.setInput(selected.label);
    
    const key = getParamsByType(type, params).key;
    updateSearchParams(key, `${selected.recordId}`);
  };

  const onChange = (_event: SyntheticEvent<Element, Event>, newValue: IOption | null, type: IComboBoxIds, setSelected: Dispatch<SetStateAction<IOption | null>>) => {
    if (type === 'client') {
      resetDates();
      resetStates('arCollateral', getSetStatesByType('arCollateral'));
      const selected = selectedClientContext.clients.find(client => client.recordId === newValue?.recordId);
      selectedClientContext?.setSelectedClient(selected ?? null);
    }

    if (type === 'arCollateral') resetDates();
    updateSearchParams(getParamsByType(type, params).key, `${newValue?.recordId ?? 0}`);
    setSelected(newValue);
    resetTable();
  };

  const onInputChange = (_event: SyntheticEvent<Element, Event>, newInputValue: string, type: IComboBoxIds, setInput: Dispatch<SetStateAction<string>>) => {
    updateSearchParams(getParamsByType(type, params).key, '0');
    setInput(newInputValue);
  };

  const resetStates = (type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    setStates.setOptions([]);
    setStates.setSelected(null);
    setStates.setInput('');
    updateSearchParams(getParamsByType(type, params).key, '0');
  };

  const resetTable = () => {
    setFirstLoad(false);
    setIsDisabled(false);
    setDisplayNoTable(false);
    setColumns([]);
    setRows([]);
  };

  const resetDates = () => {
    updateSearchParams('bbPeriodIds', '0');
    setBBPeriods([]);
    setSelectedBBPeriods([]);
    setBBPeriodInput('');
  };

  const updateSearchParams = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const getData = async (parsedBBPeriodIds: number[]) => {
    try {
      await getDetails(parsedBBPeriodIds);
    } catch {
      setDisplayNoTable(true);
    }
  }

  const getUpcData = async (parsedEndDates: string[]) => {
    try {
      await getUpcDetails(parsedEndDates);
    } catch {
      setDisplayNoTable(true);
    }
  }

  const getDetails = async (parsedBBPeriodIds: number[]) => {
    setColumns([]);
    setRows([]);

    const detailRequests: Array<Promise<AxiosResponse | null>> = parsedBBPeriodIds.map((periodId: number) => {
      return getARAmountTypeTotals(periodId, {}) // rates to be implemented for independent clients
    });

    try {
      const response = await Promise.all(detailRequests);
      if (response.every(res => res === null)) throw new Error();
      const rowList: any[] = [];
      const forDiffAve: any[] = [];
      const keyList: string[] = [];

      parsedBBPeriodIds.forEach((bbPeriodId: number, index: number) => {
        const row = response[index]?.data;
        if (row) forDiffAve.push(row);

        const navLink = `/reports/ar-ineligible?clientId=${borrowerId}&bbPeriodId=${bbPeriodId}&arCollateralId=${arCollateralId}`;
        const iconLink = {
          link: <Tooltip title='Go to AR Ineligible Report'>
                  <Link tabIndex={-1} to={navLink} target='_blank'>
                    <IconButton tabIndex={-1} size='small' aria-label='Expand icon' sx={styles.linkButton}>
                      <LaunchIcon color='primary' fontSize='inherit'/>
                    </IconButton>
                  </Link>
                </Tooltip>
        }
        mapRows(bbPeriodId, row, iconLink, rowList, keyList);
      })
      
      const commonKeys = new Set(keyList);
      mapColumns(commonKeys);
      
      if (parsedBBPeriodIds.length > 2) getAverageRow(commonKeys, rowList, forDiffAve);
      else getDiffRow(commonKeys, rowList, forDiffAve);
      
      setRows(rowList);
    } catch (error) {
      console.log('TOTALS ERROR : ', error);
      throw new Error();
    }
  };

  const getUpcDetails = async (parsedEndDates: string[]) => {
    setColumns([]);
    setRows([]);

    const detailRequests: Array<Promise<AxiosResponse | null>> = parsedEndDates.map((endDate: string) => {
      const collateralRatesMap = asOfDateRatesMap[endDate];
      return getUpcARAmountTypeTotals(endDate, collateralRatesMap);
    });

    try {
      const response = await Promise.all(detailRequests);
      if (response.every(res => res === null)) throw new Error();
      const rowList: any[] = [];
      const forDiffAve: any[] = [];
      const keyList: string[] = [];

      parsedEndDates.forEach((endDate: string, index: number) => {
        const row = response[index]?.data;
        if (row) forDiffAve.push(row);

        const navLink = `/reports/ar-ineligible?clientId=${borrowerId}&bbPeriodId=${endDate}&endDate=${endDate}&currencyId=${selectedCurrency?.recordId}`;
        const iconLink = {
          link: <Tooltip title='Go to AR Ineligible Report'>
                  <Link tabIndex={-1} to={navLink} target='_blank'>
                    <IconButton tabIndex={-1} size='small' aria-label='Expand icon' sx={styles.linkButton}>
                      <LaunchIcon color='primary' fontSize='inherit'/>
                    </IconButton>
                  </Link>
                </Tooltip>
        }
        
        mapUpcRows(endDate, row, iconLink, rowList, keyList);
      })
      
      const commonKeys = new Set(keyList);
      mapColumns(commonKeys);
      
      if (parsedEndDates.length > 2) getAverageRow(commonKeys, rowList, forDiffAve);
      else getDiffRow(commonKeys, rowList, forDiffAve);
      
      setRows(rowList);
      setDisplayNoTable(false);
    } catch (error) {
      console.log('TOTALS ERROR : ', error);
      throw new Error();
    }
  }

  const mapColumns = (commonKeys: Set<string>) => {
    const headers: IColumn[] = [{ field: 'endDate', headerName: 'As of Date' }];
    commonKeys.forEach((header: string) => {
      if (header === 'endDate' || header === 'grandTotal') return;
      const headerName = header.split(' - ')[1];
      headers.push({ field: header, headerName });
    })
    headers.push(
      { field: 'link', headerName: 'AR Ineligible Report'},
      { field: 'grandTotal', headerName: 'TOTAL AR INELIGIBLE'}
    );
    setColumns(headers);
  };

  const mapRows = (bbPeriodId: number, row: any, iconLink: { link: React.ReactElement }, rowList: any[], keyList: string[]) => {
    if (row) {
      const mapped = {...row};
      const keys: string[] = Object.keys(row);
      keyList.push(...keys);

      keys.forEach((key: string) => {
        if (key === 'endDate') mapped.endDate = formatDate(row.endDate, AMERICAN_DATE_FORMAT);
        else mapped[key] = formatCurrency(row[key], clientCurrency?.currencyCode);
      })

      const rowWithLink = { ...mapped, ...iconLink, };
      rowList.push(rowWithLink);
    } else {
      const endDate = selectedBBPeriods.find((bbPeriod: IOption) => bbPeriod.recordId === bbPeriodId)?.label ?? '0';
      const rowWithLink = {
        endDate: formatDate(endDate, AMERICAN_DATE_FORMAT),
        ...iconLink,
      }
      rowList.push(rowWithLink);
    }
  };

  const mapUpcRows = (asOfDate: string, row: any, iconLink: { link: React.ReactElement }, rowList: any[], keyList: string[]) => {
    if (row) {
      const mapped = {...row};
      const keys: string[] = Object.keys(row);
      keyList.push(...keys);

      keys.forEach((key: string) => {
        if (key === 'endDate') mapped.endDate = formatDate(row.endDate, AMERICAN_DATE_FORMAT);
        else mapped[key] = formatCurrency(row[key], clientCurrency?.currencyCode);
      })

      const rowWithLink = { ...mapped, ...iconLink, };
      rowList.push(rowWithLink);
    } else {
      const endDate = selectedBBPeriods.find((bbPeriod: IOption) => bbPeriod.endDate === endDate)?.label ?? '0';
      const rowWithLink = {
        endDate: formatDate(endDate, AMERICAN_DATE_FORMAT),
        ...iconLink,
      }
      rowList.push(rowWithLink);
    }
  }

  const getAverageRow = (commonKeys: Set<string>, rowList: any[], forDiffAve: any[]) => {
    const averageRow: any = {
      endDate: 'Average',
      link: <IconButton size='small' aria-label='placeholder' sx={{...styles.linkButton, visibility: 'hidden' }}>
              <LaunchIcon color='primary' fontSize='inherit'/>
            </IconButton>

    };

    commonKeys.forEach((key) => {
      if (key === 'endDate') return;

      const total = forDiffAve.reduce((total, current) => {
        if (current[key]) return total + current[key];
        else return total + 0;
      }, 0)

      const average = total / getParsedPeriodIds().length;
      averageRow[key] = formatCurrency(average, clientCurrency?.currencyCode);
    });

    rowList.push(averageRow);
  };

  const getDiffRow = (commonKeys: Set<string>, rowList: any[], forDiffAve: any[]) => {
    const diffRow: any = {
      endDate: 'Difference',
      link: <IconButton size='small' aria-label='placeholder' sx={{...styles.linkButton, visibility: 'hidden' }}>
              <LaunchIcon color='primary' fontSize='inherit'/>
            </IconButton>

    };

    commonKeys.forEach((key: string) => {
      if (key === 'endDate') return;
      const total: number = getDifference(forDiffAve, key);
      diffRow[key] = formatCurrency(total, clientCurrency?.currencyCode);
    });

    rowList.push(diffRow);
  };

  const getDifference = (forDiffAve: any[], key: string) => {
    if (forDiffAve.length === 1) {
      return forDiffAve[0][key] || 0;
    } else {
      return (forDiffAve[0][key] || 0) - (forDiffAve[1][key] || 0);
    }
  };

  const getARAmountTypeTotals = (bbPeriodId: number, collateralRatesMap: {}) => {
    return axiosInstance.request({
      url: reportsAPI.arCompareReport.GET_AR_COMPARE_REPORT,
      method: POST,
      params: { borrowerId, arCollateralId, bbPeriodId },
      data: collateralRatesMap
    }).catch(_ => null);
  };

  const getUpcARAmountTypeTotals = (endDate: string, collateralRatesMap: {}) => {
    return axiosInstance.request({
      url: reportsAPI.arCompareReport.GET_UPC_AR_COMPARE_REPORT,
      method: POST,
      params: {
        parentClientId: parseInt(borrowerId),
        asOfDate: endDate,
      },
      data: collateralRatesMap
    }).catch(_ => null);
  }

  const getTable = (columns: IColumn[], rows: any[]) => {
    if (columns.length && rows.length) {
      const hasEndDate = hasEndDates(rows);
      if (!hasEndDates(rows)) { 
        return (
          <Paper sx={styles.paperContainer}>
            <Typography tabIndex={0} align='center'>No data available</Typography>
          </Paper>
        )
      }
      return (<ARComparisonTable columns={columns} rows={rows} currency={clientCurrency?.currencyCode}/>);
    } else if (displayNoTable) {
      return (
        <Paper sx={styles.paperContainer}>
          <Typography tabIndex={0} align='center'>No data available</Typography>
        </Paper>
      )
    } else {
      return null;
    }
  };

  const getHandleChangeByType = (type: IComboBoxIds) => {
    return { 
      onChange: (e: SyntheticEvent<Element, Event>, newValue: IOption | null) => onChange(e, newValue, type, getSetStatesByType(type).setSelected),
      onInputChange: (e: SyntheticEvent<Element, Event>, newInputValue: string) => onInputChange(e, newInputValue, type, getSetStatesByType(type).setInput)
    }
  };

  /**
   * Retrieves collateral options and selected value based on the ultimate parent status.
   * @returns an object with 'options' array and 'value' representing selected collateral.
   */
  const getArCollateralOptionsAndValue = () => {
    const allOpt = {recordId: 0, label: 'All', default: true};

    if (!isUltimateParent) {
      return {
        options: arCollaterals,
        value: selectedARCollateral,
      }
    } else {
      return {
        options: [allOpt],
        value: allOpt,
      }
    }
  };

  const getLimitTags = () => {
    if (calendarFieldBP.lg || calendarFieldBP.md || calendarFieldBP.sm) return 1;
    else return 2;
  };

  const getMultipleProps = () => {
    return {
      renderTags: (value: IOption[], getTagProps: AutocompleteRenderGetTagProps) =>
                    value.map((option: IOption, index: number) => (<Chip size='small' label={option.label} sx={styles.chip} {...getTagProps({ index })} />)),
      renderInput: (params: AutocompleteRenderInputParams) => (<TextField {...params} inputProps={{ ...params.inputProps, 'aria-label': 'As of Date Dropdown'}} placeholder={selectedBBPeriods.length > 0 ? undefined : 'Please Select'}/>),
      onChange: (_: SyntheticEvent<Element, Event>, newVal: readonly IOption[]) => {
        const mutableValue = [...newVal];
        const params = mutableValue.length > 0 ? mutableValue.map((period: IOption) => period.recordId).join(',') : '0';
        updateSearchParams('bbPeriodIds', params);
        setSelectedBBPeriods(mutableValue);
        resetTable();
      },
      onInputChange: (_: SyntheticEvent<Element, Event>, newInput: string) => setBBPeriodInput(newInput)
    }
  };

  const hasEndDates = (row: any[]) => row.reduce((acc, curr) => Object.hasOwn(curr, 'endDate') && acc, true);

  /**
   * This function checks if the user has the permission to export the report on time of exporting.
   * 
   * @param func Function that is used to export the report.
   * @param args Parameters need to run the export function.
   */
  const checkPermission = (func: Function, ...args: any[]) => {
    (async () => {
      try {
        const isPermitted = await checkUserPermissions(state.uid, PERMISSIONS.EXPORT_AR_AGING_REPORT)
        if (isPermitted) {
          func(...args)
        } else if (isPermitted === false) {
          showToaster('error', NO_PERMISSION_MSG);
        } else {
          throw new Error();
        }
      } catch (error) {
        showToaster('error', 'Failed in checking permission to export the report!');
        console.log('CHECK PERMISSION EXPORT: ', error);
      }
    })();
  };

  /**
   * This function displays a toaster notification with the specified severity and message.
   * @param severity The severity level of the notification (e.g., 'success', 'error', 'warning', 'info').
   * @param message The message to be displayed in the notification.
   */
  const showToaster = (severity: AlertColor, message: string) => {
    setIsToasterOpen(true);
    setToasterMessage(message);
    setToasterSeverity(severity);
  };

  /**
   * This function determine which filetype of the AR Aging report should export to.
   * @param filetype Determine which file type of report should export to.
   */
  const handleSelect = (filetype: 'Excel' | 'PDF') => {
    const reportName = 'AR Ineligible Compare';

    const fileName: string = getFileNameArCompare(
      filetype,
      reportName,
      selectedClient?.label
    );

    const fileNameToExport: string = checkFileNameIfExisting(exports, fileName, reportName);

    const additionalPayload = {
      setExporting,
      apiURL: getExportUrl(filetype),
      payloadData: getPayloadData(fileNameToExport)
    }
    updateExports(fileNameToExport, fileName, additionalPayload);
  }

  /**
   * Generates the export URL based on the specified file type.
   * @param filetype The type of file to export ('Excel' or 'PDF').
   * @returns The export URL for the specified file type.
   */
  const getExportUrl = (filetype: 'Excel' | 'PDF') => {
    return reportsAPI.arCompareReport[`EXPORT_${filetype.toUpperCase()}_UPC`]
  }

  /**
   * This function generates a Payload to be used on exported file.
   * 
   * @returns A Payload for the export file
   */
  const getPayloadData = (filename: string) => {
    const endDates = getParsedEndDates();

    return {
      borrowerId: selectedClient?.recordId,
      borrowerName: selectedClient?.label,
      currencyId: selectedCurrency?.recordId,
      rows,
      columns,
      filename,
      endDate: endDates.toString(), // for file upload only
    }
  }

  const updateExports = (filename: string, originalFileName: string, additionalPayload: any) => {
    const fileToExport: IExportReportWithPayload = {
      filename,
      status: 'loading',
      originalFileName,
      ...additionalPayload,
    }
    setExports([...exports, fileToExport]);
    setShow(true);
  }

  /**
   * Determines whether the export functionality should be disabled based on certain conditions.
   * @returns True if export should be disabled, otherwise false.
   */
  const getDisabledExport = () => {
    const initialConds = isLoading || exporting || displayNoTable;
    
    if (isUltimateParent) {
      return initialConds || rows.length === 0;
    }

    return true;
  };

  return(
    <Box sx={{...(!canViewReport && styles.hidden)}}>
      <Box sx={styles.pageContainer}>
        <Grid container flexDirection='row' justifyContent='space-between' alignItems='center' columnSpacing={1}>
          <Grid item xs={12} sm={6} lg={8} xl={8.3}>
            <Box sx={styles.breadcrumbsContainer}>
              <GeneralBreadcrumbs selectedText='AR Ineligible Compare' breadcrumbLinks={[{ linkText: 'Reports', route: '/reports' }]}/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3.7} sx={styles.clientDropdownContainer}>
            <Box sx={styles.clientDropdownBox}>
              <ComboBox
                id='client'
                options={filteredWithoutUPCRelation}
                value={selectedClient}
                inputValue={clientInput}
                {...getHandleChangeByType('client')}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={styles.headerContainer}>
        <Typography tabIndex={0} variant='h6' component='h3' sx={styles.headerTitle}>AR Ineligible Compare</Typography>
        <Box sx={styles.headerActionWrapper}>
          {canExport &&
          <MenuButton 
            label='Export'
            options={[
              { label: 'Excel', handleSelect: () => checkPermission(handleSelect, 'Excel') },
              { label: 'PDF', handleSelect: () => checkPermission(handleSelect, 'PDF') }
            ]}
            buttonProps={{
              endIcon: exporting ? <CircularProgress size={15} /> : <FileDownloadOutlinedIcon />,
              disabled: getDisabledExport(),
              size: 'medium',
              variant: 'outlined',
              'aria-label': 'Download the Report',
              sx: styles.headerButtons
            }}
          />
          }
          <Button
            size='medium'
            variant='outlined'
            aria-label='Go back'
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={handleNavigate}
            sx={styles.headerButtons}
          >
            {belowMediumBreakpoint ? null : 'Go back'}
          </Button>
        </Box>
      </Box>

      <Box sx={styles.pageContainer}>
        <Grid container columnSpacing={2} sx={styles.dropdownsContainer}>
          <Grid item xs={12} sm={3} lg={3}>
            <ComboBox
              id='arCollateral'
              {...getArCollateralOptionsAndValue()}
              inputValue={arCollateralInput}
              disabled={isUltimateParent}
              {...getHandleChangeByType('arCollateral')}
            />
          </Grid>
          <Grid item xs={12} sm={3} lg={3}>
            <Box sx={styles.labelContainer}>
              <Typography tabIndex={0} component='label' htmlFor='multiple-date-picker' sx={styles.label}>
                As of Date
              </Typography>
            </Box>
            <Autocomplete
              multiple
              id={`multiple-bb-period-dropdown`}
              disablePortal
              disableCloseOnSelect
              getOptionLabel={getOptionLabel}
              limitTags={getLimitTags()}
              isOptionEqualToValue={isOptionEqualToValue}
              options={bbPeriods}
              value={selectedBBPeriods}
              inputValue={bbPeriodInput}
              PaperComponent={paperComponent}
              size='small'
              componentsProps={{
                popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
                clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
              }}
              {...getMultipleProps()}
              sx={styles.dropdown}
            />
          </Grid>
          { isUltimateParent ? (
            <Grid item xs={12} sm={3} lg={3}>
              <ComboBox
                id='currency'
                options={currencies}  
                value={selectedCurrency}
                inputValue={currencyInput}
                disabled={isUltimateParent}
                {...getHandleChangeByType('currency')}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={3} lg={3} />
          ) }
          <Grid item xs={12} sm={4} lg={3}>
            <Grid container justifyContent={belowSmallBreakpoint ? 'center' : 'flex-end'}>
              <DisabledComponentsContainer isDisabled={isDisabled}>
                <Button
                  variant='contained'
                  disabled={isDisabled}
                  aria-label={isDisabled ? 'Generate Report button disabled' : 'Generate Report'}
                  onClick={handleGenerate}
                  sx={styles.generateReportButton}
                >
                  Generate Report
                </Button>
              </DisabledComponentsContainer>
            </Grid>
          </Grid>
        </Grid>

        <ReportsTitle
          clientName={selectedClient?.label ?? ''}
          reportName='AR Ineligible Comparison Report'
          bbPeriod=''
        />

        {isLoading ? <LinearProgress /> : getTable(columns, rows)}
      </Box>
      <Toaster
        open={isToasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setIsToasterOpen(false)}
      />
    </Box>
  )
}
export default ARIneligibleCompare;