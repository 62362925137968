import { Box, Button, CircularProgress, Container, InputLabel, Paper, TextField, Link, InputAdornment, IconButton, Typography } from '@mui/material';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import LoanWatchLogo from '../../assets/images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionType, setLoginToken } from '../../actions/authActions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthContext } from '../../context/authContext';
import signInSchema from '../../schemas/signInSchema';
import api from '../../service/api';
import { PERMISSIONS, POST } from '../../utility/constants';
import styles from './styles'
import { getLocalStorageItem, getPermissionsOfUser } from '../../utility/helper';
import { AuthState } from '../../reducer/authReducer';
import axiosInstance from '../../service/axiosInstance';
import InlineError from './inline-error';

const initialValues = {
  email: '',
  password: '',
  general: ''
};

interface FormikValues {
  email: string;
  password: string;
  general: string
};

/**
 * Component for showing the Sign In Page.
 */
const SignIn: FC = () => {
  const {dispatch}                      = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate                        = useNavigate();
  const location                        = useLocation();
  const emailErrorRef = useRef<HTMLDivElement>(null);

  /**
   * This useEffect hook automatically logs the user out if the the user accessed this page while logged in.
   */
  useEffect(() => {
    if(getLocalStorageItem('token') !== null && location.pathname === '/sign-in') {
      dispatch({
        type: ActionType.LOGOUT,
      });
    }
  }, [])

  /**
   * This function shows/hides the value inputted at the password field.
   */
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }; 

  /**
   * This function prevents the default behavior that happens when clicking the show/hide password icon.
   * @param event The event generated when clicking the icon.
   */
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  /**
   * This function handles the logging in of the user based on the given credentials.
   * @param values These are the values which contain the email and password of the user.
   * @param action These are the formik helpers in the sign in form.
   */
  const handleSubmit = async (values: FormikValues, action: FormikHelpers<FormikValues>) => {
    const {email, password} = values;
    const {setErrors} = action;

    const formData = new FormData();
    formData.append('username', email);
    formData.append('password', password);

    try {
      const response = await axiosInstance.request({
        url: api.LOGIN,
        method: POST,
        data: formData
      })

      const token : AuthState = {
        token: response.data.loginToken.access_token,
        refreshToken: response.data.loginToken.refresh_token,
        uid: response.data.user.id,
        email: response.data.user.email,
        firstName: response.data.user.firstName,
        lastName: response.data.user.lastName
      }

      navigateToLandingPage(response.data.user.id, response.data.loginToken.access_token, token);
      
    } catch (e : any) {
      console.log('LOGIN ERROR', e)
      setErrors({
        email: ' ',
        password: ' ',
        general: e.response.data
      });

      setTimeout(() => {
        emailErrorRef.current?.focus();
      }, 500);
    }
  }

  /**
   * This function navigates the user to a landing page based on the user's permissions.
   * @param userId The ID of the user.
   * @param token The login token of the user.
   */
  const navigateToLandingPage = async (userId, accessToken, tokenObject) => {
    const permissions = await getPermissionsOfUser(userId, accessToken)
    dispatch(setLoginToken(tokenObject));
    
    if (permissions.includes(PERMISSIONS.VIEW_CLIENT) || permissions.includes(PERMISSIONS.VIEW_ASSIGNED_CLIENT)) {
      navigate('/clients');
    } else if (permissions.includes(PERMISSIONS.VIEW_LENDER_SETTINGS)) {
      navigate('/general-settings');
    } else if (permissions.includes(PERMISSIONS.VIEW_USERS_AND_ROLES)) {
      navigate('/users-and-roles');
    } else {
      navigate('/');
    }
  }

  return (
    <Box sx={styles.pageBox} tabIndex={0} aria-label='Meeting image'>
      <Container sx={styles.loginForm}>
        <img src={LoanWatchLogo} alt='LoanWatch Logo' aria-label='LoanWatch logo image' width={500} tabIndex={0} />
        <Paper elevation={0} sx={styles.paperStyle}>
          <Box component={'h2'}
            tabIndex={0}
            sx={styles.modalTitle}>
            Login to your Account
          </Box>
          <Box sx={styles.modalInstructions}>
            Enter Your Email Address and Password
          </Box>
          <Box sx={styles.modalInstructionsRequired}>
            <Typography display='inline' sx={styles.red}>*</Typography>
            indicates a required field.
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={signInSchema}
            onSubmit={handleSubmit}
          >
            {
              formik => (
                <Form>
                  <Container  sx={styles.formContainer}>
                    <InputLabel 
                      tabIndex={0}
                      data-testid='emailLabel'
                      sx={styles.inputLabel}
                    >
                      EMAIL ADDRESS
                      <Typography sx={styles.red}>*</Typography>
                    </InputLabel>
                    <Container disableGutters={true} sx={styles.inputContainer}>
                      <TextField
                        margin='normal'
                        name='email'
                        inputProps={{ 'data-testid': 'email',
                                      'aria-label': 'Email Address Textfield', 
                        }}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        sx={styles.inputField}
                        InputLabelProps={{shrink:false}}
                        InputProps={{placeholder:'Email Address'}}
                      />
                    </Container>
                    <ErrorMessage
                      name='email' 
                      render={msg => <InlineError errorMessage={msg} ref={emailErrorRef} />}
                    />
                    <InputLabel 
                      sx={styles.inputLabel}
                      tabIndex={0}
                    >
                      PASSWORD
                      <Typography sx={styles.red}>*</Typography>
                    </InputLabel>
                    <Container disableGutters={true} sx={styles.inputContainer}>
                      <TextField
                        fullWidth
                        margin='normal'
                        name='password'
                        inputProps={{ 'data-testid': 'password',
                                      'aria-label':'Password Textfield',
                        }}
                        type={showPassword ? 'text' : 'password'}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        sx={styles.inputField}
                        InputLabelProps={{shrink:false}}
                        InputProps={{
                          placeholder:'Password',
                          endAdornment:
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='Encryption icon'
                                data-testid='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge='end'
                              >
                                {!showPassword ? <VisibilityOff sx={styles.visibilityIcon}/> : <Visibility sx={styles.visibilityIcon}/>}
                              </IconButton>
                            </InputAdornment>
                        }}
                      />
                    </Container>
                    <Box>
                      <ErrorMessage name='password' render={msg => <InlineError errorMessage={msg} />} />
                    </Box>
                    <Container sx={styles.subtextContainer} disableGutters={true}>                     
                      <Link
                        data-testid='forgot-password'
                        underline='none'
                        href='/forgot-password'
                        sx={styles.linkText}
                      >
                        Forgot Password?
                      </Link>
                    </Container>
                    <Box textAlign='center'>
                        <ErrorMessage name='general' render={msg => <InlineError errorMessage={msg} ref={emailErrorRef} />} />
                      </Box>
                    <Box textAlign='center' sx={styles.buttonContainer}>
                      <Button
                        data-testid='login-btn'
                        type='submit'
                        variant='contained'
                        color='primary'
                        sx={styles.loginButton}
                      >
                        {
                          formik.isSubmitting ?
                              <CircularProgress color='inherit' size={24} />
                            :
                            'Log in to Continue'
                        }
                      </Button>
                    </Box>
                  </Container>
                </Form>
              )
            }
          </Formik>
        </Paper>
      </Container>
    </Box>
  )
}

export default SignIn;