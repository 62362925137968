import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import styles from './styles';

interface ThreeOptionsModalProps {
  open: boolean;
  onOption1: () => void;
  onOption2: () => void;
  onCancel: () => void;
  onButtonClose?: () => void;
  title: string;
  description: string;
  // error Button determines if Yes Button will be red
  errorButton?: boolean;
  option1Text?: string;
  option2Text?: string;
  cancelButtonText?: string;
  promptChecker?: boolean;
  confirmOnly?: boolean;
  alignment? : string;
}

const ThreeOptionsModal = ({
  open,
  onOption1,
  onOption2,
  onCancel,
  onButtonClose,
  title,
  description,
  promptChecker = false,
  errorButton = false,
  option1Text = 'Yes',
  option2Text = 'No',
  cancelButtonText = 'Cancel',
  confirmOnly = false,
  alignment
}: ThreeOptionsModalProps) => {
  return (
    <Modal open={open}>
      <Box sx={styles.modalBackground}>
        <Box sx={styles.headingContainer}>
          <Typography sx={styles.headingTitle}>{title}</Typography>
          <Close fontSize="small" onClick={onButtonClose} sx={styles.closeButton} />
        </Box>
        <Divider variant="middle" sx={styles.divider} />
        <Box sx={{...styles.descriptionContainer}}>
          <Typography>{description}</Typography>
        </Box>
        <Divider variant="middle" sx={styles.divider} />
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Button sx={styles.cancelButton} onClick={onCancel}>{cancelButtonText}</Button>
          <Box sx={styles.buttonContainer}>
            <Button 
              variant="outlined" 
              sx={styles.button1} 
              onClick={onOption1}>
              {option1Text}
            </Button>
            <Button
              variant="contained"
              sx={errorButton ? styles.errorButton : styles.button2}
              onClick={onOption2}
            >
              {option2Text}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ThreeOptionsModal;
