import { API_DOMAIN, GET, NO_PERMISSION_MSG, PATCH, PERMISSIONS, POST, SEARCH_FILTER } from '../../../utility/constants';
import { Box, Button, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { DraggableListItem } from '../../../interfaces/draggableList';
import { IOption } from '../../../interfaces/comboBox';
import Prompt from '../../../components/modals/prompt';
import styles from './styles';
import { IModalProps, IParentChildSetupContext, IParentWithChild } from '../../../interfaces/parentChildSetupInterface';
import ParentSection, { handleDefaultFirst } from './parent-section/parentSection';
import ConfirmModal from '../../../components/modals/confirm-modal';
import CircleIcon from '@mui/icons-material/Circle';
import OrphanSection from './orphan-section/orphanSection';
import { ParentChildSetupContext } from '../../../context/parentChildSetupContext';
import axiosInstance from '../../../service/axiosInstance';
import { AxiosResponse } from 'axios';
import { CustomerSettingsContext, ICustomerSettingsContext } from '../../../context/customerSettingsContext';
import { checkUserPermissions, clearNewCustomersByIds, getLocalStorageItem } from '../../../utility/helper';
import { IClearModalProps, IToasterProps } from '..';
import DisabledComponentsContainer from '../../../components/common/disabled-components-container';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { arCustomersAPI, arVendorsAPI } from '../../../service/api';
import { IARCustomer, IARVendor } from '../../../interfaces';

export interface ITempData {
  recordId?         : number
  arCollateralId?   : number,
  borrowerId        : string,
  custName?         : string,
  custSrcId?        : string,
  dbRating?         : string,
  creditLimit?      : number,
  custCountry?      : string,
  custAddress1?     : string,
  custAddress2?     : string,
  custCity?         : string,
  custState?        : string,
  custPostalCode?   : string,
  custPhone?        : string,
  custDescription?  : string,
  isUpcParent?      : boolean,
  archive?          : boolean,
}
interface IProps extends IToasterProps {
  selectedARCollateral: IOption | null
  borrowerId?: string,
  setIsParentView: Dispatch<SetStateAction<boolean>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

/**
 * This component renders a page for parent view.
 * 
 * @param props IProps
 */
const ParentViewClientSetting: FC<IProps> = (props) => {
  const {
    parentsWithChildrenList,
    setParentsWithChildrenList,
    orphansList,
    setOrphansList,
    newOrphansList,
    setNewOrphansList,
    newParentsWithChildrenList,
    setNewParentsWithChildrenList,
    deletedParentIds,
    setDeletedParentIds,
    setParentPage,
    setOrphansPage
  }                                                           = useContext(ParentChildSetupContext) as IParentChildSetupContext;
  const {canEditParentChildRelationship, setToaster }         = useContext(CustomerSettingsContext) as ICustomerSettingsContext;
  const {selectedClient}                                      = useContext(SelectedClientContext);
  const [isDirty, setIsDirty]                                 = useState<boolean>(false)
  const [modalProps, setModalProps]                           = useState<IModalProps>({isParent: false, isOpen: false, parentId: 0, parentName: '', children: [], from: 0, to: 0})
  const [clearModal, setClearModal]                           = useState<IClearModalProps>({ isOpen: false, type: 'customer' });
  const [showConfirmModal, setShowConfirmModal]               = useState<boolean>(false)
  const [isDragging, setIsDragging]                           = useState<boolean>(false)

  /**
   * This useEffect is used to check if there's a changed on orphanList or parentList. If so, it will set the isDirty state to true
   */
  useEffect(() => {
    if (newParentsWithChildrenList.length || newOrphansList.length) {
      setIsDirty(true)
    }
  }, [newOrphansList, newParentsWithChildrenList]);

  useEffect(() => {
    fetchCustomers();
  }, [props.selectedARCollateral?.recordId])

  const fetchCustomers = async () => {
    try {
      await Promise.all([
        axiosInstance.request({
          url: selectedClient?.parentClient ? arCustomersAPI.FIND_BY_BORROWER_ID : arCustomersAPI.FIND_BY_AR_COLLATERAL_ID,
          method: GET,
          params: selectedClient?.parentClient ? 
            {borrowerId: selectedClient?.recordId, sortBy: 'custName,ASC'}
            : {arCollateralId: props.selectedARCollateral?.recordId, sortBy: 'custName,ASC'}
        }),
        axiosInstance.request({
          url: selectedClient?.parentClient ? arVendorsAPI.FIND_BY_PARENT_BORROWER_ID : arVendorsAPI.FIND_BY_AR_COLLATERAL_ID,
          method: GET,
          params: selectedClient?.parentClient ? 
            {parentBorrowerId: selectedClient?.recordId}
            : {arCollateralId: props.selectedARCollateral?.recordId}
        }),
        axiosInstance.request({
          url: selectedClient?.parentClient ? arCustomersAPI.FIND_AR_AMOUNT_MAP_BY_PARENT_BORROWER_ID : arCustomersAPI.FIND_AR_AMOUNT_MAP_BY_AR_COLLATERAL_ID,
          method: GET,
          params: selectedClient?.parentClient ? 
          {parentBorrowerId: selectedClient?.recordId}
          : {arCollateralId: props.selectedARCollateral?.recordId}
        })
      ]).then(([customerResponse, vendorResponse, arAmountResponse]) => {
        const arAmountMap = arAmountResponse.data;
        const customerList: IARCustomer[] = customerResponse.data.content.map((o:any) => ({
          recordId             : o.recordId,
          borrowerId           : o.borrowerId,
          arCollateralId       : o.arCollateralId,
          parentARCustomerId   : o.parentARCustomerId,
          parentARCustomerName : o.parentCustName,
          custSrcId            : o.custSrcId,
          custName             : o.custName,
          custAddress1         : o.custAddress1,
          custAddress2         : o.custAddress2,
          custCountry          : o.custCountry,
          custCity             : o.custCity,
          custState            : o.custState,
          custPostalCode       : o.custPostalCode,
          custPhone            : o.custPhone,
          creditLimit          : o.creditLimit,
          asOfDate             : o.asOfDate,
          custDescription      : o.custDescription,
          parentCustSrcId      : o.parentCustSrcId,
          dbRating             : o.dbRating,
          isPrimary            : o.primaryDebtor,
          upcParentCustName    : o.upcParentCustName,
          upcParentCustSrcId   : o.upcParentCustSrcId,
          upcParentCustId      : o.recordId,
          isUpcParent          : o.upcParent,
          arAmount             : arAmountMap[o.recordId],
          isNew                : o.isNew,
        }));

        const vendorList = vendorResponse.data;

        handleRelationship(customerList, vendorList);

      })
    }catch(error){
      console.log('GET ALL CUSTOMER EDIT PAGE: ', error)
    }
  }

  const handleRelationship = (customerList: IARCustomer[], vendorList: IARVendor[]) => {
    const field = selectedClient?.parentClient ? 'upcParentCustSrcId' : 'parentCustSrcId';
    // find Ids of Parent Customers that cannot be deleted
    const vendorLinkedParentIds: number[] = [
      ...new Set(vendorList
        .filter(vendor => vendor?.arCustomerId !== undefined)
        .map(filteredVendor => Number(filteredVendor.arCustomerId))
        )];

    const parentSrcIds: string[] = [
        ...new Set(customerList
          .filter(cust => cust[field] !== undefined)
          .map(filteredCust => String(filteredCust[field]))
          )];

    const parentIds: String[] = customerList
          .filter(cust => cust.custSrcId !== undefined && parentSrcIds.includes(cust.custSrcId))
          .map(filteredCust => String(filteredCust.recordId))

    const parentObjects: IParentWithChild[] = customerList
          .filter(cust => cust.custSrcId !== undefined && parentSrcIds.includes(cust.custSrcId))
          .map(filteredCust => ({
            ...filteredCust,
            id: filteredCust.recordId!, 
            name: filteredCust.custName!,
            isExpanded: false,
            isUnlinked: !vendorLinkedParentIds.includes(filteredCust.recordId!),
            isNew: filteredCust.isNew,
            ...findChildren(customerList, filteredCust?.custSrcId),
          })).sort((a, b) => a.name.localeCompare(b.name));

    setParentsWithChildrenList([...parentObjects]);
    setParentPage({pageNo: 1, isLoading: false, isLastPage: false, searchKey: '', searchFilter: SEARCH_FILTER.ALL})
    
    // handle orphans
    const tempOrphanList: DraggableListItem[] = [];
    
    customerList.filter(o => o[field] === undefined && !parentIds.includes(String(o.recordId)) && !o.isUpcParent).map((orphan: any) => tempOrphanList.push({
      recordId: orphan.recordId,
      value: orphan.recordId,
      name: orphan.custName,
      srcId: orphan.custSrcId,
      checked: false,
      disabled: false,
      listName: 'orphans',
      deletable: !vendorLinkedParentIds.includes(orphan.recordId),
      createdAt: orphan.createdAt,
      amount: orphan.arAmount,
      isNew: orphan.isNew,
    }));

    setOrphansList([...tempOrphanList].sort((a, b) => a.name.localeCompare(b.name)));
    setOrphansPage({pageNo: 1, isLoading: false, isLastPage: false, searchKey: ''}); 
  }

  /**
   * This function discard all changes on both parentList and orphanList
   */
  const handleReset = () => {
    setNewOrphansList([])
    setNewParentsWithChildrenList([])
    setIsDirty(false)
  }

  /**
   * This function redirect the user to customer settings page if there are no changes made.
   * If isDirty state is true, it will show the confirm prompt modal.
   */
  const handleCancel = () => {
    if(isDirty){
      setShowConfirmModal(true)
      return
    }
    handleReset()
    props.setIsParentView(false)
  }

  /**
   * This function removed a parent on the parentLists.
   * 
   * @param parentId The ID of the parent to be remove.
   * @param tempId The temporary ID of an unsave parent.
   */
  const handleRemoveParent = (parentId: number, tempId?: number) => {
    const removedParent = parentsWithChildrenList.find(parent => (parent.id === parentId && parent.tempId === tempId))
    const newList = parentsWithChildrenList.filter(parent => (parent.id !== parentId && (tempId === undefined || parent.tempId !== tempId)))
    if(removedParent){
      const newOrphans = removedParent.children.map(child => {
        return {...child, checked: false}
      })
      setOrphansList([...orphansList, ...newOrphans].sort((a, b) => a.name.localeCompare(b.name)))
      setNewOrphansList([...newOrphansList, ...(removedParent?.children)]) 
      if(!removedParent.tempId){
        setDeletedParentIds([...deletedParentIds, removedParent.id])
      }else{
       const tempList = newParentsWithChildrenList.filter(o => o.tempId !== tempId)
       setNewParentsWithChildrenList(tempList)
      }
    }
    setParentsWithChildrenList(newList)
  }

  /**
   * This function remove a child of a parent on parentLists and put it on the orphansList.
   * 
   * @param childId The ID of the child to be removed.
   * @param parentId The ID of the child's parent to be removed.
   * @param tempId The temporary ID of the child's parent it it's an unsave parent.
   */
  const handleRemoveChild = (childId: number, parentId: number, tempId?: number) => {
    let removedChild: DraggableListItem[] = []
    let newChildren: DraggableListItem[] = []
    let updatedParent: IParentWithChild | undefined = undefined

    const newList = parentsWithChildrenList.map(parent => {
      if (parent.id === parentId && parent.tempId === tempId) {
        removedChild = parent.children.filter(child => child.recordId === childId).map(child2 => ({...child2, checked: false}))
        newChildren = parent.children.filter(child => child.recordId !== childId)
        const newParent = {
          ...parent,
          defaultChildId: removedChild[0].default ? newChildren[0].recordId.toString() : parent.defaultChildId,
          children: newChildren
        }
        updatedParent = newParent
        return newParent
      }
      return parent
    })

    //Handles delete parent when last child is removed
    if (modalProps.isLastChild) {
      const newListWithoutParent = parentsWithChildrenList.filter(parent => (parent.id !== parentId && (tempId === undefined || parent.tempId !== tempId)))

      //Handle Existing parent
      if (!tempId && parentId !== 0) {
        setDeletedParentIds([...deletedParentIds, parentId])
      } else{
        //Handle newly created parent
        const updatedNewParentList = newParentsWithChildrenList.filter(parent => (parent.tempId !== tempId))
        setNewParentsWithChildrenList(updatedNewParentList)
      }
      setParentsWithChildrenList(newListWithoutParent)
    } else{
      const updatedNewList = newParentsWithChildrenList.filter(parent => parent.id !== parentId && parent.tempId !== tempId)
      setNewParentsWithChildrenList([...updatedNewList, ...(updatedParent ? [updatedParent] : [])])
      setParentsWithChildrenList(newList)
    }

    setOrphansList([...orphansList, ...removedChild].sort((a, b) => a.name.localeCompare(b.name)))
    setNewOrphansList([...newOrphansList, ...removedChild])
  }

  /**
   * This function is used to arrange the children of a parent customer default first
   * 
   * @param customerList A lsit of IARCustopmer from API call
   * @param parentCustSrcId The custSrcId of a Parent customer
   * @returns The defaultChildId and children fields need for IParentWithChild object list  
   */
  const findChildren = (customerList: IARCustomer[], parentCustSrcId?: string) => {
    const field = selectedClient?.parentClient ? 'upcParentCustSrcId' : 'parentCustSrcId';
    const custChildren: DraggableListItem[] = customerList.filter(child => child[field] !== undefined && child[field] === parentCustSrcId)
    .map(child => ({
            recordId: child.recordId!,
            value: child.recordId!.toString(),
            name: child.custName!,
            checked: false,
            disabled: false,
            default: child.isPrimary!,
            listName: parentCustSrcId!,
            srcId: child.custSrcId!,
            parentSrcId: parentCustSrcId!,
            amount: child.arAmount,
            isNew: child.isNew,
            canDelete: true,
    }))

    const defId = custChildren?.find((child) => child.default === true)?.recordId.toString() ?? '';

    return {
      defaultChildId: defId,
      children: handleDefaultFirst(custChildren, defId)
    }
  }

  /**
   * This function checks the current user's permission to do a specific functionality.
   * 
   * @param func The function that the user want's to use.
   * @param permission The permission need to use the specified functionality.
   */
  const checkPermission = async (func: Function, permission: string) => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), permission)
    if(isPermitted){
      func()
      return
    }
    props.setIsToasterOpen(true)
    props.setToasterMessage(NO_PERMISSION_MSG)
    props.setToasterSeverity('error')
  }

  /**
   * This function handles the posting, updating, and deleting of AR Customer relationship on the database.
   */
  const handleSave = async () => {
    try {
      props.setIsLoading(true)
      // remove parents that has zero children
      const filteredNewParents = newParentsWithChildrenList.filter(parent => parent.children.length > 0);

      //save all new orphans
      const newOrphansCall = newOrphansList.map(orphan => {
        if(selectedClient?.parentClient){
          return axiosInstance.request({
            url: `${API_DOMAIN}/ar/customers/updateUpcParent/${orphan.recordId}`,
            method: PATCH,
            params: {
              upcParentCustName: undefined,
              upcParentCustSrcId: undefined,
              upcParentCustId: undefined,
              isUpcParent: false
            }
        })
        } return axiosInstance.request({
          url: `${API_DOMAIN}/ar/customers/updateParent/${orphan.recordId}?primaryDebtor=${false}`,
          method: PATCH,
          params: {
            parentId: undefined,
            parentCustName: undefined,
            parentCustSrcId: undefined,
          }
        })
      })

      
      //save new/updated parent
      for(const element of filteredNewParents){
        const tempData: ITempData = {
          borrowerId      : props.borrowerId ?? '',
          custName        : element.name ?? element.custName,
          custSrcId       : element.custSrcId,
          dbRating        : element.dbRating,
          creditLimit     : element.creditLimit,
          custCountry     : element.custCountry,
          custAddress1    : element.custAddress1,
          custAddress2    : element.custAddress2,
          custCity        : element.custCity,
          custState       : element.custState,
          custPostalCode  : element.custPostalCode,
          custPhone       : element.custPhone,
          custDescription : element.custDescription,
          isUpcParent     : selectedClient?.parentClient,
          archive         : false,
        } 

        element.id && ( tempData.recordId = element.id )

        !selectedClient?.parentClient && (tempData.arCollateralId = props.selectedARCollateral?.recordId)
        

        await axiosInstance.request({
          url: `${API_DOMAIN}/ar/customers`,
          method: POST,
          data: {...tempData}
        }).then((response) => {
          const {children} = element
          const addCall = children.map(child => {
            if(selectedClient?.parentClient){
              return axiosInstance.request({
                url: `${API_DOMAIN}/ar/customers/updateUpcParent/${child.recordId}`,
                method: PATCH,
                params: {
                  upcParentCustName: response.data.custName,
                  upcParentCustSrcId: response.data.custSrcId,
                  upcParentCustId: response.data.recordId, 
                  isUpcParent: false
                }
              })
            }else{
              return axiosInstance.request({
                url: `${API_DOMAIN}/ar/customers/updateParent/${child.recordId}`,
                method: PATCH,
                params: {
                  parentId: response.data.recordId,
                  parentCustName: response.data.custName,
                  parentCustSrcId: response.data.custSrcId,
                }
              })
            }
          })
          newChildrenCall.push(...addCall)
        }).catch((error) => {
          console.log('CREATE PARENT ERROR : ', error)
        })
      }
      const newChildrenCall:Promise<AxiosResponse<any, any>>[] = [] 
      const deletedParentCall = deletedParentIds.map(id => axiosInstance.delete(`${API_DOMAIN}/ar/customers/${id}`))
      
      newOrphansCall &&
        await Promise.all(newOrphansCall)
        .catch((error) => {
          console.log('UPDATE ORPHANS ERROR : ',error)
        })

      //update children
      newChildrenCall &&
        await Promise.all(newChildrenCall)
        .catch((error) => {
          console.log('UPDATE CHILDREN ERROR : ',error)
      })

      //delete unused/removed parent
      deletedParentCall &&
        await Promise.all(deletedParentCall)
        .catch((error) => {
          console.log('DELETE PARENT ERROR : ', error)
        })

      props.setIsLoading(false)
      props.setIsToasterOpen(true)
      props.setToasterMessage('Changes saved')
      props.setToasterSeverity('success')
      handleReset()
      props.setIsParentView(false)
    } 
    catch (error) {
      console.log('ERROR SAVING CHANGES : ', error)
      props.setIsToasterOpen(true)
      props.setToasterMessage('Request failed. Please try again.')
      props.setToasterSeverity('error')
    } 
  }

  /**
   * This function clears all new orphans in the Orphan section
   */

  const handleClearOrphans = async () => {
    // manually clear orphans without refetching the customers
    
    try {
      const orphanIds = orphansList.map(orphan => orphan.recordId);
      await clearNewCustomersByIds(orphanIds);
      const clearedOrphans = orphansList.map((orphan: DraggableListItem) => ({
        ...orphan,
        isNew: false,
      }));
      
      setOrphansList(clearedOrphans);
      
      setToaster({ open: true, message: 'Successfully cleared all customers!', severity: 'success' });
    } catch (error) {
      console.log('CLEAR ALL ORPHANS ', error)
    }
  }
  /**
   * This function generate a spcific message for the warning modal based on the modalProps values.
   * 
   * @returns A dynamic message specified with modalProps values.
   */
  const getConfirmModalDescription = () => {
    if (modalProps.isParent) {
      return 'The relationship between this parent and its children will be removed. Are you sure you want to delete this parent?';
    } else if (modalProps.isLastChild) {
      return 'If the last child is removed, the Parent will be removed. Are you sure you want to remove this child from the Parent?';
    } else {
      return `You are about to remove ${modalProps.children.length > 1 ? 'these children' : 'this child'}. Are you sure?`
    }
  }
 
  return <>
    {canEditParentChildRelationship ? 
      <>   
        <Box sx={styles.saveBar}>
          <Box width={styles.maxWidth}>
            <Typography tabIndex={0} sx={styles.title}>
              Edit Parent Child Relationship
            </Typography>
          </Box>
          <Box sx={styles.saveCancelButton}>
            <Button
              data-testid={`parent-view-cancel-button`}
              onClick={() => handleCancel()}
              variant='outlined'
              >
                Cancel
            </Button>
            <DisabledComponentsContainer isDisabled={!isDirty}>
              <Button
                data-testid={`parent-view-save-button`}
                onClick={() => checkPermission(handleSave, PERMISSIONS.EDIT_PARENT_CHILD_RELATIONSHIP)}
                variant='contained'
                disabled={!isDirty}
                aria-label={!isDirty ? 'Save button disabled' : 'Save'}
                >
                  Save
              </Button>
            </DisabledComponentsContainer>
          </Box>
        </Box>
        <Grid container sx={styles.gridSection}>
          {/* PARENT CHILD SECTION */}
          <ParentSection
            selectedARCollateral={props.selectedARCollateral}
            modalProps={modalProps}
            setModalProps={setModalProps}
            setIsDragging={setIsDragging}
            isDragging={isDragging}
          />
          {/* ORPHANS SECTION */}
          <OrphanSection
            selectedARCollateral={props.selectedARCollateral}
            modalProps={modalProps}
            setModalProps={setModalProps}
            setClearModal={setClearModal}
            setIsDragging={setIsDragging}
          />

          <ConfirmModal
            title={modalProps.isParent ? `Delete ${modalProps.parentName}` : 'Remove'}
            description={getConfirmModalDescription()} 
            open={modalProps.isOpen} 
            onClose={() => {
              setModalProps({
                ...modalProps,
                isOpen: false
              })
            }} 
            onConfirm={() => {
              if(modalProps.isParent){
                handleRemoveParent(modalProps.parentId, modalProps.tempId)
              }else{
                handleRemoveChild(modalProps.children[0].recordId, modalProps.parentId, modalProps.tempId)
              }
            }}        
            yesButtonText={modalProps.isParent? 'Delete' : 'Remove'}
            noButtonText={'Cancel'}
            errorButton={modalProps.isParent}
          >{modalProps.isParent && 
              <Box sx={{...styles.overflowBox, maxHeight: '10rem'}}>
                  {modalProps.children.map((child, idx) =>
                    <ListItem 
                      key={child.recordId} 
                      dense 
                      disablePadding
                    >
                      <ListItemIcon sx={{...styles.flexCenter, justifyContent: 'end'}}>
                        <CircleIcon sx={styles.circleIcon}/>
                      </ListItemIcon>
                      <ListItemText>{child.name}</ListItemText>
                    </ListItem>            
                  )}
              </Box>
          }
          </ConfirmModal>
          <Prompt when={isDirty} isEditing={() => {}}/>
          <ConfirmModal
            open={showConfirmModal}
            onButtonClose={() => setShowConfirmModal(false)}
            onClose={() => {
              setShowConfirmModal(false)
              props.setIsParentView(false)
            }}
            onConfirm={() => setShowConfirmModal(false)}
            promptChecker
            title={`Confirm Navigation`}
            description={`You have unsaved changes. Are you sure you want to leave this page?`}
            yesButtonText={`Keep Editing`}
            noButtonText={`Discard Changes`}
            confirmOnly
          />
          <ConfirmModal
            title={'Clear All New Customers'}
            description={'Are you sure you want to clear all new added customers?'} 
            open={clearModal.isOpen} 
            onClose={() => {
              setClearModal({
                ...clearModal,
                isOpen: false
              });
            }} 
            onConfirm={() => {
              handleClearOrphans();
            }}        
            yesButtonText={'Clear'}
            noButtonText={'Cancel'}
          />
        </Grid>
      </>  :
      <Box sx={styles.noPermissionBox}>
        You do not have the permissions to view this page.
      </Box>
    }
  </>
}

export default ParentViewClientSetting