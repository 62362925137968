import {Typography, Autocomplete, TextField, Box, Chip} from '@mui/material';
import styles from './styles';
import { ClientComboBoxProps } from '../../../../interfaces/clientListInterface';
import { FC, useContext } from 'react';
import { ClientContext } from '../../../../context/clientContext';

/**
 * Component for showing the contents of the client combo box component.
 * @param props The props for the ClientComboBox component. See the ClientComboBoxProps interface for more information.
 */
const ClientComboBox: FC<ClientComboBoxProps> = (props) => { 
  const {
    options,
    label,
    id,
    value,
    placeholder,
    setValue,
    input,
    setInput,
    disabled,
    alignment
  } = props;
  const { clientInfo } = useContext(ClientContext);

  /**
   * This function returns a boolean value depending on the parent status of the option.
   * @param option The name of the option
   * @returns True, if the option is a parent client, otherwise, false.
   */
  const isSelectedFieldNameParentFieldValue = (option: string) => {
    if (id === 'client') {
      const parentClients = clientInfo.filter(client => client.parentClient).map(client => client.borrowerName);
      return parentClients.includes(option);
    } else {
      return false;
    }
  };

  return (
    <>
      <Typography tabIndex={0} component='label' htmlFor={`combo-box-${id}`} sx={{...styles.label, ...(alignment === 'left' ? {marginRight: '16px'} : {marginBottom: '8px'})}}>
        {label}
      </Typography>
      <Autocomplete
        blurOnSelect
        fullWidth
        id={`combo-box-${id}`}
        data-testid='autocomplete-search'
        inputValue={input}
        noOptionsText={'No Results'}
        value={value}
        onChange={(_event: any, newValue: string | null) => setValue(newValue)}
        onInputChange={(_event, newInputValue) => {
          setInput(newInputValue);
        }}
        options={options}
        renderOption={(prop, option) => {
          return (
            <Box component='li' {...prop} sx={{fontSize:'14px'}}>
              <Box tabIndex={0} sx={styles.labelChipContainer}>{option}</Box>
              <Box sx={{ ...styles.parentChipContainer, ...(!isSelectedFieldNameParentFieldValue(option) && styles.hidden) }}>
                <Chip size='small' sx={styles.parentIdentifierChip} />
              </Box>
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField 
            {...params} 
            placeholder={placeholder}
            inputProps={{...params.inputProps, 'aria-label':'Clients dropdown', 'data-testid':`combo-box-${id}`}}
            InputProps={{
              ...params.InputProps,
              endAdornment:(
                <>
                  <Box sx={{ ...styles.inputParentChipContainer, ...(!isSelectedFieldNameParentFieldValue(input) && styles.hidden) }}>
                    <Chip size='small' sx={styles.parentIdentifierChip} />
                  </Box>
                  {params.InputProps.endAdornment}
                </>
                
              )
            }}
          />
        )}
        size='small'
        disabled={disabled}
        sx={styles.clientComboBox}
        componentsProps={{
          popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
          clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
        }
      />
    </>
  );
};

export default ClientComboBox;