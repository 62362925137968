import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from "../../utility/constants";

const styles = {
  drawerOpenBox: {
    minHeight: 'calc(100vh - 107px)',
    pt: 1,
    ml: {
      sm: `${DRAWER_WIDTH}px`,
    },
    mt: '64px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F2F7F8',
    transition: 'all 0.3s ease',
  },
  drawerClosedBox: {
    minHeight: 'calc(100vh - 107px)',
    pt: 1,
    ml: {
      sm: `${MINI_DRAWER_WIDTH}px`,
    },
    mt: '64px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F2F7F8',
    transition: 'all 0.3s ease',
  },
  container: {
    '&.MuiContainer-root': {
      padding: 0,
      backgroundColor: '#F2F7F8',
      minHeight: 'inherit',
      boxShadow: '0 2px 0px 0.5px #E0E0E0',
    }
  }
};

export default styles;