import { Box, Button, Divider, FormLabel, Grid, TextField } from "@mui/material"
import { Form } from "formik"
import { IUsersAndRolesContext, UsersAndRolesContext } from "../../../../context/usersAndRolesContext"
import { useContext } from "react"
import ConfirmModal from "../../../modals/confirm-modal"
import styles from "./styles"
import { IAddRoleModalProps } from "../../../../interfaces/rolesPermissionInterface"
import { CONTAINS_ALPHANUMERIC_ONLY } from "../../../../utility/constants"

const AddRole = ({setShowAddRoleModal, setShowAddPermissionModal, createdRoleFormik}: IAddRoleModalProps) => {

  const {setIsDirty, showPrompt, setShowPrompt} = useContext(UsersAndRolesContext) as IUsersAndRolesContext;

  const handleClose = () => {
    setIsDirty(false)
    setShowPrompt(false)
    setShowAddRoleModal(false)
  };

  const getErrorAndHelper = (touched: any, error: any) => {
    return error.roleName;
  };

  const handleSubmit = async () => {
    setIsDirty(false)
    setShowAddRoleModal(false);
    setShowAddPermissionModal(true);
  };

  return (
    <Box sx={styles.mainContainer}>
      <Form >
        <Box
          sx={styles.gridContainer}
        >
          {/* role */}
          <Divider style={{width: '100%'}}/>
            <Box sx={styles.formContainer}>
              <Box sx={styles.rightAlignedText}>
                <FormLabel
                  component='label'
                  sx={styles.label}
                  htmlFor='role'>
                  Role Name:
                </FormLabel>
              </Box>
              <Box sx={{paddingY: '20px'}}>
                <TextField
                  id='role'
                  aria-label='Row Field'
                  name='roleList[0].roleName'
                  value={createdRoleFormik.values.roleList[0].roleName}
                  sx={styles.gridField}
                  onChange={(event: any) => {
                    if (
                      CONTAINS_ALPHANUMERIC_ONLY.test(
                        event?.target?.value
                      ) ||
                      event?.target?.value === ''
                    ) {
                      createdRoleFormik.handleChange(event)
                    }
                  }}
                  error={Boolean(createdRoleFormik.errors.roleList ? createdRoleFormik.errors.roleList[0] : false)}
                  helperText={getErrorAndHelper(
                    createdRoleFormik.touched.roleList ? createdRoleFormik.touched.roleList[0] : false,
                    createdRoleFormik.errors.roleList ? createdRoleFormik.errors.roleList[0] : false
                  )}
                />
              </Box>
            </Box>
          <Divider style={{width: '100%'}}/>
        </Box>
        <Grid item xs={12}>
          <Box sx={styles.bottomActionsButtonContainer}>
            <Button
              variant='outlined'
              sx={styles.cancelButton}
              onClick={() => {
                createdRoleFormik.resetForm();
                setShowAddRoleModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!createdRoleFormik.dirty || !createdRoleFormik.isValid}
              variant='contained'
              sx={styles.saveButton}
              onClick={() => {handleSubmit()}}
            >
              Save
            </Button>
          </Box>
        </Grid>
        <ConfirmModal
          open={showPrompt}
          onClose={() => {createdRoleFormik.resetForm(); handleClose();}}
          onConfirm={() => {setShowPrompt(false);}}
          onButtonClose={() => {setShowPrompt(false);}}
          promptChecker
          title={`Confirm Navigation`}
          description={`You have unsaved changes. Are you sure you want to leave this page?`}
          yesButtonText={`Keep Editing`}
          noButtonText={`Discard Changes`}
          confirmOnly
        />
      </Form> 
    </Box>
  )
}

export default AddRole
