const styles = {
  snackBar: {
    '&.MuiSnackbar-root': {
      right: '0',
    },
  },
  alert: {
    borderRadius: '4px 0 0 4px',
    marginTop: '64px',
    paddingX: '21px',
    paddingY: '15px',
    width: '100%',
    '.MuiAlert-icon': {
      display: 'none',
    },
    '.MuiAlert-action': {
      display: 'none',
    },
    '.MuiAlert-message': {
      fontSize: '12px',
      paddingY: '0',
      whiteSpace: 'pre-wrap',
    },
    '.MuiAlertTitle-root': {
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: '3px',
      paddingY: '0',
    },
  },
  info: {
    backgroundColor: '#D0DBE8',
    color: '#010953',
  },
  success: {
    backgroundColor: '#D4EDDA',
    border: '1px solid #C3E6CB',
    color: '#155724',
  },
  warning: {
    backgroundColor: '#0091D6',
  },
  error: {
    backgroundColor: '#EDD4D4',
    border: '1px solid #E6C3C3',
    color: '#571515',
  }
}

export default styles;
