const styles = {
  modal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1070px',
    height: '100vh',
    maxHeight: '970px',
    overflow: 'auto',
    p: '1.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: 'background.paper',
    borderRadius: '0.3rem',
    boxShadow: 24,
  },

  modalTitle: {
    color: '#154A8A',
    fontWeight: 'bold',
    fontSize: '18px'
  },

  placeholderBox: {
    width: '196px'
  },

  checkBoxGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBotton: '10px'
  },

  checkBoxLabel: {
    '.MuiTypography-root': {
      fontSize: '14px',
      marginLeft: '5px',
      color: '#707070'
    },
  },

  modalOpenButton: {
    py: '2px',
    textTransform: 'none',
    px: '1rem',
  },

  closeIcon: {
    color: '#7C7D7D',
    fontSize: '1em',
  },

  modalTitleCursor: {
    cursor: 'move',
  },

  modalFieldLabelName: {
    paddingTop: '4px',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
  },

  modalFieldLabelRequired: {
    '&::after' : {
      content: '"*"',
      color:'red'
    }
  },

  asterisk:{
    color: '#AE2633'
  },

  paddingBottom: {
    paddingBottom: '16px'
  },

  rowMaxHeight: {
    height: '52px',
    maxHeight: '52px',
    alignItems: 'flex-start'
  },

  rowMaxHeightClients: {
    height: '40px',
    maxHeight: '40px',
    marginTop: "-20px"
  },

  checkBox: { 
    width: "20px", 
    padding:0 
  },

  stateFields: {
    display: 'flex', 
    flexDirection:'row', 
    alignItems: 'flex-start',
    height: '52px',
    maxHeight: '52px',
  },

  smallField: {
    width: '124px',
    minWidth: '124px',
    '& .Mui-error': {
      fontSize: '12px',
      marginTop: '1px',
      marginX: '0',
    },
  },

  gridColumnParent: {
    width: '450px',
    display: 'flex',
    alignItems: 'flex-end'
  },

  mainSetting: {
    border: '1px solid #EBEBEB',
    display: 'flex',
    justifyContent: 'center',
    marginY: '0.75rem',
    padding: '1.8rem',
    width: '100%',
    height: '810px'
  },

  buttonBoxContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: '1rem',
  },

  saveButton: {
    height: '31px',
    width: '82px',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    },
  },

  cancelButton: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    width: '82px',
  },

  textfieldNumber: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      textAlign: 'left',
    },
  },

  textfieldCurrency: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      textAlign: 'right',
    },
  },

  field: {
    width: '17rem',
    // height: '30px', temporary disabled since there is no design yet for when the error message is too long
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      // width: '100px',
      fontSize: '14px',
      // backgroundColor: 'white',
      height: '30px',
      // borderRadius: '5px',   
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
    },
    '& .Mui-error': {
      fontSize: '12px',
      marginTop: '1px',
      marginX: '0',
    },
    // '& .MuiFormHelperText-root.Mui-error':{
    //   minWidth: 'max-content'
    // }
  },

  field2: {
    width: '21rem',
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      height: '30px',
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
    },
    '& .Mui-error': {
      fontSize: '12px',
      marginTop: '1px',
      marginX: '0',
    },
  },

  autocompleteField: {
    width: '17rem',
    fontSize: '14px',
    '&&&& .MuiAutocomplete-input': {
      padding: '0px 0px 5px 4px'
    },
    '&&&& .MuiAutocomplete-input::placeholder': {
      color: '#707070',
      opacity: '100%'
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      backgroundColor: 'white',
      height: '30px',
      margin: '0px'
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center',
      position: 'absolute',
      top: '-4px',
      left: '-4px',
    },
    '& .MuiInputLabel-root.Mui-focused': { 
      color: '#707070',
    },
    '&&&& .MuiAutocomplete-endAdornment': {
      right: '7px'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: 0,
      lineHeight: '14px',
      paddingTop: '2px',
    },
  },

  autocompleteField2: {
    width: '21rem'
  },

  placeholder: {
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      backgroundColor: 'white',
      height: '30px',
      color: '#707070'
    },
    color: '#707070'
  },

  defaultValueColor:{
    color: '#707070'
  },

  w20: {
    width: '20em',
  },

  hidden: {
    display: 'none',
  },

  invisible: {
    visibility: 'hidden',
  },

  autocompleteHeight: {
    maxHeight: 200,
  },

  dropdownOptionsFontSize: {
    fontSize: '14px',
  },

  noteText: {
    color: '#154A8A',
    fontWeight: 'bold'
  },

  red: {
    color: '#D1062E',
    marginRight: '2px'
  },

  modalInstructionsRequired: {
    marginLeft: '50px',
    marginRight: '50px',
    fontSize: '14px',
    marginBottom: '15px'
  },
};

export default styles;
