import { DRAWER_WIDTH } from "../../utility/constants";

const styles = {
  nav: {
    width: {
    },
    flexShrink: {
      sm: 0
    },
  },
  drawerFlexbox: {
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'space-between', 
    height: '90vh',
    width: DRAWER_WIDTH,
  },
  mobileDrawer: {
    display: {
      xs: 'block',
      sm: 'none'
    },
    zIndex: 0,
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: DRAWER_WIDTH
    }
  },
  webDrawer: {
    display: { 
      xs: 'none', 
      sm: 'block' 
    },
    '& .MuiDrawer-paper': { 
      boxSizing: 'border-box', 
      borderTop: 0,
      zIndex: 0,
      top: '64px',
      backgroundColor: '#F7FCFF'
    },
  },
  menu: {
    '& .MuiListItemText-root': {
      color: 'primary.main'
    },
    padding: 0,
    marginTop: '1rem'
  },
  link: {
    textDecoration:'none'
  },
  listItemIcon: {
    '& .MuiSvgIcon-root': {
      color: '#fff'
    }
  },
  listItemText:{
    '& .MuiTypography-root': {
      color: '#fff' 
    }
  },
  listItem: {
    backgroundColor: '#154A8A'
  }
};

export default styles;