const styles = {
  gridContainerForHeader: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingX: '10px',
    marginTop: '0.5rem',
    width: '100%'
  },
  gridContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingX: '10px',
    width: '100%'
  },
  rightAlignedText: {
    textAlign: 'right',
  },
  gridField: {
    width: '100%',
    height: '30px',
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      width: '100%',
      height: '30px',
      backgroundColor: 'white',
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
    },
    '& .Mui-error': {
      fontSize: '11px',
      marginTop: '1px',
      marginX: '0',
    }
  },
  gridSelect: {
    '@media (max-width: 1535px)': {
      fontSize: '13px',
    },
    '@media (max-width: 1400px)': {
      fontSize: '11px',
    }
  },
  limitGridSelect: {
    '& .MuiOutlinedInput-input': {
      width: '161.5px',
      paddingY: '0',
      textAlign: 'left',
    },
  },
  gridMenuItem: {
    fontSize: '14px',
  },
  hidden: {
    display: 'none',
  },
  bottomActionsButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: '4rem',
  },
  cancelButton: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    width: '82px',
  },
  saveButton: {
    height: '31px',
    textTransform: 'capitalize',
    width: '82px',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },
  label: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    marginTop: '0.5rem',
   '& .MuiFormLabel-asterisk': {
      color: '#db3131',
    },
    color: '#000',
  },
  container:{
    border: '1px solid #EBEBEB',
  },  
  form: {
    width: '100%'
  },
  addButton: {
    py: '2px',
    px: '1rem',
    textTransform: 'none',
  },
  addRowButton: {
    width: '45px',
    py: '2px',
    px: '1rem',
    textTransform: 'none',
    marginRight: '10px',
  },
  gridItemActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  gridVerticalBar: {
    border: '1px solid #154A8A',
    margin: '0px',
  },
  deleteIcon: {
    color: '#154A8A',
  }
};

export default styles;
