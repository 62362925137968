import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { IClientVariances, IUpcClientRollForward, IUpcCollateralRollForward, IUpcFullReport } from "../../../../interfaces/rollForwardReportInterface";
import { Accordion, AccordionDetails, AccordionSummary, AlertColor, Box, Typography } from "@mui/material";
import ReportsTitle from "../../../../components/reports-title";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styles from "./styles";
import RollForwardTable from "../table";
import { formatNumber } from "../../../../utility/helper";

export interface IUpcRfBodyProps {
  clientRfReport      : IUpcClientRollForward;
  setClientRfReport?  : Dispatch<SetStateAction<IUpcClientRollForward | undefined>>;
  idToNameMap         : {[key: string]: string};
  bbPeriodLabel       : string;
  showToaster         : (severity: AlertColor, message: string) => void;
  currencyLabel       : string;
  collateralRatesMap  : {[key: number]: number;}
  clientVariances     : IClientVariances;
  setClientVariances  : Dispatch<SetStateAction<IClientVariances>>;
}

const ClientRfReports: FC<IUpcRfBodyProps> = (props) => {
  const { clientRfReport, setClientRfReport, idToNameMap, bbPeriodLabel, showToaster, currencyLabel, collateralRatesMap, clientVariances, setClientVariances } = props;

  /**
   * This is a memoized value for the entries of the Client Roll Forward Report.
   */
  const rfEntries: [string, IUpcCollateralRollForward][] = useMemo(() => 
    Object.entries(clientRfReport)
  , [clientRfReport]);

  /**
   * This is a memoized value for defaulting to USD label when currencyLabel is an empty string.
   */
  const defaultCurrencyLabel = useMemo(() => currencyLabel !== '' ? currencyLabel : 'USD', [currencyLabel]);
  
  /**
   * This sets the client variances state based on the variances from each of the collaterals.
   * @param shallow The shallow copy to be added to the client variances object.
   */
  const handleInitialClientVariances = (collateralVariance: number[]) => {
    setClientVariances(prevState => [...prevState, collateralVariance]);
  };

  /**
   * This function gets the total variance of all clients.
   * @param clientVariances The collection of different clients and variances.
   * @returns The total variance of all clients.
   */
  const getTotalVariance = (clientVariances: IClientVariances) => {
    return clientVariances.reduce((acc, curr) => acc + curr[2], 0);    
  }

  /**
   * This function gets the total for the individual client variance.
   * @param clientVariances The collection of different clients and variances.
   * @param borrowerId The borrower id of the Client.
   * @returns The total variance of an individual client.
   */
  const getClientVariance = (clientVariances: IClientVariances, borrowerId: number) => {
    const collateralVariances = clientVariances.filter((clientVariance: number[]) => clientVariance[0] === borrowerId)
    return collateralVariances.reduce((acc, curr) => acc + curr[2], 0);
  }

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={{...styles.varianceContainer, ...styles.totalVarianceContainer}}>
        <Typography
          tabIndex={0}
          sx={styles.varianceText}
          component='label'
          htmlFor='total-variance'
        >
          {'Total Roll Forward Variance'}
        </Typography>
        <Typography
          tabIndex={0}
          sx={styles.variance}
          component='label'
          id='total-variance'
          aria-label={
            formatNumber({
              style: 'currency',
              currency: defaultCurrencyLabel,
              currencySign: 'accounting'
            }, getTotalVariance(clientVariances))
          }
        >
          {formatNumber({
            style: 'currency',
            currency: defaultCurrencyLabel,
            currencySign: 'accounting'
          }, getTotalVariance(clientVariances))}
        </Typography>
      </Box>
      {rfEntries.map(rfEntry => {
        const borrowerId = rfEntry[0];
        const borrowerName = idToNameMap[rfEntry[0]];
        const rfCollateralEntries: [string, IUpcFullReport][] = Object.entries(rfEntry[1]);
        const clientVariance = getClientVariance(clientVariances, parseInt(borrowerId));

        return (
          <Box key={borrowerId} sx={styles.bodyContainer}>
            <Box sx={styles.varianceContainer}>
              <Typography
                tabIndex={0}
                sx={styles.varianceText}
                component='label'
                htmlFor='client-variance'
              >
                {'Client Total Roll Forward Variance'}
              </Typography>
              <Typography
                tabIndex={0}
                sx={styles.variance}
                component='label'
                id='client-variance'
                aria-label={
                  formatNumber({
                    style: 'currency',
                    currency: defaultCurrencyLabel,
                    currencySign: 'accounting'
                  }, clientVariance)
                }
              >
                {formatNumber({
                  style: 'currency',
                  currency: defaultCurrencyLabel,
                  currencySign: 'accounting'
                }, clientVariance)}
              </Typography>
            </Box>
            <ReportsTitle
              clientName={borrowerName}
              reportName='Roll Forward Report'
              bbPeriod={bbPeriodLabel}
            />
            {rfCollateralEntries.map(rfCollateralEntry => {
              const arCollateralId = rfCollateralEntry[0];
              const arCollateralName = idToNameMap[`${borrowerId} - ${arCollateralId}`];
              const rfReport = rfCollateralEntry[1];
              const exchangeRate = collateralRatesMap[rfReport.currencyId] ?? 1;

              return (
                <Accordion
                  key={arCollateralId}
                  sx={styles.accordion}
                  disableGutters
                  elevation={0}
                  square
                >
                  <AccordionSummary
                    aria-controls={arCollateralId}
                    id={arCollateralId}
                    expandIcon={
                      <ArrowDropDownIcon
                        aria-label='Expand icon'
                        sx={styles.accordionSummaryFontSize}
                      />
                    }
                    sx={styles.accordionSummary}
                  >
                    <Box sx={styles.accordionRow}>
                      <Typography fontSize='1rem' fontWeight='bold'>
                        {arCollateralName}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={styles.accordionDetails}>
                    <RollForwardTable
                      rfCalcSummary={rfReport.rfCalcSummary}
                      rollForwards={rfReport.rollForwards}
                      beginningBalance={rfReport.beginningBalance}
                      showToaster={showToaster}
                      currencyLabel={defaultCurrencyLabel}
                      isUltimateParent={true}
                      clientVariances={clientVariances}
                      setClientVariances={setClientVariances}
                      handleInitialClientVariances={handleInitialClientVariances}
                      exchangeRate={exchangeRate}
                      clientRfReport={clientRfReport}
                      setClientRfReport={setClientRfReport}
                    />
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}

export default ClientRfReports;