import { ActionType, FileImportProcessActions } from '../actions/fileImportProcessActions';
import { IDocumentAttribute } from '../interfaces/dataMap';
import { IUploadedFile } from '../interfaces/fileimport';

export interface FileImportProcessState {
  content: string,
  isLoading: boolean,
  show: boolean,
  link: string,
  cancelled: boolean,
  selectedClientId: number | null,
  documents: IUploadedFile[],
  setUploadedFiles: React.Dispatch<React.SetStateAction<IUploadedFile[]>>,
  userDefinedFields: IDocumentAttribute[],
  hideOnly: boolean,
}

export const initialState: FileImportProcessState = {
  content: "",
  isLoading: false,
  show: false,
  link: "/",
  cancelled: false,
  selectedClientId: null,
  documents: [],
  setUploadedFiles: function (): void {
    throw new Error('Function not implemented.');
  },
  userDefinedFields: [],
  hideOnly: false,
};

const fileImportProcessReducer = (state: FileImportProcessState, action: FileImportProcessActions): FileImportProcessState => {
  // Turned into an if statement since it only has 2 cases as of the moment
  if (action.type === ActionType.FileImportProcess) {
    return {
      ...state,
      ...action.payload
    }
  } else {
    return state;
  }
};

export default fileImportProcessReducer;