import { Box, Typography } from '@mui/material';
import styles from '../styles'
import { FC } from 'react';

interface IProps {
  title: string
}
/**
 * Component for showing the Page Header of the Users and Roles page..
 * @param props The props for the PageHeader component. See the IProps interface for more information.
 */
const PageHeader: FC<IProps> = (props) => {
  const {title} = props;

  return (
    <Box sx={styles.titleContainer}>
      <Typography data-testid='UsersAndRolesHeader' variant='h6' component='h3' sx={styles.title}>
        {title}
      </Typography>
    </Box>
  );
};

export default PageHeader;
