import { AlertColor, Box, Button, FormLabel, Grid, MenuItem, Select, TextField } from "@mui/material"
import { Form, Formik } from "formik"
import { IUsersAndRolesContext, UsersAndRolesContext } from "../../../../context/usersAndRolesContext"
import { FC, useContext, useEffect, useState } from "react"
import Prompt from "../../../modals/prompt"
import styles from "./styles"
import userProfileSchema from "../../../../schemas/userProfile"
import { IRoleAPI, IUserRowInfo } from "../../../../interfaces/rolesPermissionInterface"
import { checkUserPermissions, getLocalStorageItem } from "../../../../utility/helper"
import axiosInstance from "../../../../service/axiosInstance"
import api, { rolePermissionAPI } from "../../../../service/api"
import { DELETE, GET, NO_PERMISSION_MSG, PERMISSIONS, PUT } from "../../../../utility/constants"
import Toaster from "../../../toaster"

interface IProps {
  isEditable:    boolean,
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>,
  userData:      IUserRowInfo;
  invitedById:   number,
  rebuildData:   () => void
};

const tempRoles: IRoleAPI[] = [
  { 
    id: '',
    name: '',
    realmRoles: []
   }
];

/**
 * Component for showing the user information part of the user profile page.
 * @param props The props for the UserInformation component. See the IProps interface for more information.
 */
const UserInformation: FC<IProps> = (props) => {
  const {isEditable, setIsEditable, 
         userData, invitedById, rebuildData}  = props;
  const {setIsDirty,
         canUpdateUser,
         canDeactivateUser,
         canReactivateUser}                   = useContext(UsersAndRolesContext) as IUsersAndRolesContext;
  const [roleList, setRoleList]               = useState<IRoleAPI[]>(tempRoles)
  const [elementId, setElementId]             = useState<string>();
  const [toasterOpen, setToasterOpen]         = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]   = useState<string>('');
  const [toasterSeverity, setToasterSeverity] = useState<AlertColor>('success');
  const [_isEditing, setIsEditing]            = useState<boolean>(false);

   /**
   * This useEffect hook retrieves all of the available roles upon showing the modal.
   */
  useEffect(() => {
    getAllRoles();
  }, []);

  /**
   * This useEffect hook retrieves and sets the element ID.
   */
  useEffect(() => {
    if(elementId){
      document.getElementById(`${elementId}`)?.focus()
      setElementId(undefined)
    }
  }, [elementId]);

  /**
   * This function sets the dirty status of the form.
   * @param node The formik reference object containing the dirty status.
   */
  const formikRef = (node: any) => {
    if (node !== null) {
      setIsDirty(node.dirty)
    }
  };

  /**
   * This function retrieves the error status of a field.
   * @param touched The touched status for the fields in the formik form.
   * @param error The errors for the fields in the formik form.
   * @returns A boolean value that is true if the field is touched and has an error.
   */
  const getErrorAndHelper = (touched: any, error: any) => {
    return touched && error;
  };

  /**
   * This function enables editing and is called when the user clicks a field.
   * @param id The element ID.
   */
  const handleOnFieldClick = (id: string) => {
    if (!isEditable && canUpdateUser && userData.role !== 'Super Admin') {
      setIsEditable(true);
      setElementId(id);
    }
  };

  /** 
   * This function calls the API endpoint for retrieving all of the roles.
   * @returns The available roles in the application. See IRoleAPI interface for more information.
   */
  const getAllRoles = async () => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.GET_ALL_ROLES,
        method: GET,
        headers: {
          token : token !== undefined ? token : ''
        }
      })
      const roles : IRoleAPI[] = response.data;
      const filteredRoles = userData.role !== 'Super Admin' ? roles.filter(role => role.name !== 'Super Admin') : roles;
      setRoleList(filteredRoles);
      return roles;
    } catch (error) {
      console.log('GET ALL ROLES ERROR:',error);
    }
  };

  /**
   * This function calls the API endpoint for updating a user.
   * @param userId The ID of the user.
   * @param firstName The first name of the user.
   * @param lastName The last name of the user.
   * @param email The email of the user.
   */
  const updateUser = async (userId: string, firstName: string, lastName: string, email: string) => {
    try {
      const token = getLocalStorageItem('token');
      await axiosInstance.request({
        url: api.UPDATE_USER,
        method: PUT,
        params: {userId: userId},
        data: {
          firstName: firstName,
          lastName: lastName,
          email: email
        },
        headers: {
          token : token !== undefined ? token : ''
        }
      })
    } catch (error) {
      console.log('UPDATE USER ERROR:',error);
    }
  };

  /**
   * This function calls the API endpoint for updating the details of a user.
   * @param userId The ID of the user.
   * @param recordId The ID of the user's details.
   * @param status The status of the user.
   * @param invitedById The ID of the details of the user who invited the user.
   */
  const updateUserDetails = async (userId: string, recordId: number, status: string, invitedById: number) => {
    try {
      await axiosInstance.request({
        url: api.UPDATE_USER_INFO,
        method: PUT,
        params: {userId: userId},
        data: {
          recordId: recordId,
          keycloakId: userId,
          status: status,
          invitedBy: invitedById !== -1 ? invitedById : null
        }
      })
    } catch (error) {
      console.log('UPDATE USER DETAILS ERROR:',error);
    }
  };

  /**
   * This function calls the API endpoint for removing a role from the user.
   * @param userId The ID of the user.
   * @param roleId The ID of the role of the user.
   */
  const removeRole = async (userId : string, roleId : string) =>{
    try {
      const token = getLocalStorageItem('token');
      await axiosInstance.request({
        url: api.REMOVE_ROLE_FROM_USER,
        method: DELETE,
        params: {userId: userId, roleId: roleId},
        headers: {
          token : token !== undefined ? token : ''
        }
      })
    } catch (error) {
      console.log('REMOVE ROLE ERROR:',error);
    }
  };

  /**
   * This function calls the API endpoint for adding a role to the user.
   * @param userId The ID of the user.
   * @param roleId The ID of the role of the user.
   */
  const addRole = async (userId : string, roleId : string) =>{
    try {
      const token = getLocalStorageItem('token');
      await axiosInstance.request({
        url: api.ADD_ROLE_TO_USER,
        method: PUT,
        params: {userId: userId, roleId: roleId},
        headers: {
          token : token !== undefined ? token : ''
        }
      })
    } catch (error) {
      console.log('UPDATE ROLE ERROR:',error);
    }
  };  

  /**
   * This function handles the submission of the updated user data.
   * @param values The objecting containing the updated user values.
   */
  const handleSubmit = async (values : IUserRowInfo) => {
    const oldRole = roleList.filter(role => role.name === userData.role);
    const newRole = roleList.filter(role => role.name === values.role);
    await updateUser(userData.keycloakId, values.firstName, values.lastName, values.email);
    await updateUserDetails(userData.keycloakId, userData.recordId, values.status, invitedById);
    if (oldRole.length > 0) {
      await removeRole(userData.keycloakId, oldRole[0].id);
    };
    await addRole(userData.keycloakId, newRole[0].id)
    setIsDirty(false);
    setIsEditable(false);
    setToasterOpen(true);
    setToasterMessage('The changes in the user profile have been saved!');
    setToasterSeverity('success');
    rebuildData();
  };

  /**
   * This function checks if the user has a specific permission.
   * @param func The function to be called if the user has the required permission.
   * @param permission The permission to be checked.
   * @param args The arguments to be given to the function.
   */
  const checkPermission = async (func: Function, permission: string, args: any) => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), permission)
    if(isPermitted){
      await func(args)
      return
    }
    setToasterOpen(true);
    setToasterMessage(NO_PERMISSION_MSG);
    setToasterSeverity('error');
  };

  return (
    <Grid container>
      <Formik
        enableReinitialize
        initialValues={userData}
        validationSchema={userProfileSchema}
        innerRef={formikRef}
        onSubmit={async (values) => {checkPermission(handleSubmit, PERMISSIONS.UPDATE_USER, values)}}
      >
        {(formik) => (
          <Form>
            <Toaster
              open={toasterOpen}
              message={toasterMessage}
              severity={toasterSeverity}
              onCloseChange={() => setToasterOpen(false)}
            />
            <Prompt when={formik.dirty} isEditing={setIsEditing} />
            <Grid
              container
              rowSpacing={3}
              columnSpacing={1}
              sx={styles.gridContainer}
            >
              <Grid item xs={5} lg={3} sx={styles.leftAlignedText}>
                <FormLabel
                  component='label'
                  required={false}
                  sx={styles.label}
                  htmlFor='firstName'>
                  First Name:
                </FormLabel>
              </Grid>
              <Grid item xs={7} lg={9}>
                <TextField
                  disabled={!isEditable}
                  id='firstName'
                  aria-label='First Name Field'
                  name='firstName'
                  value={formik.values.firstName}
                  sx={styles.gridField}
                  onChange={(event: any) => {
                    formik.handleChange(event)
                  }}
 onClick={(e) => handleOnFieldClick(e.currentTarget.id)}                  
                  error={getErrorAndHelper(
                    formik.touched.firstName,
                    Boolean(formik.errors.firstName)
                  )}
                  helperText={getErrorAndHelper(
                    formik.touched.firstName,
                    formik.errors.firstName
                  )}
                />
              </Grid>

              <Grid item xs={5} lg={3} sx={styles.leftAlignedText}>
                <FormLabel
                  component='label'
                  required={false}
                  sx={styles.label}
                  htmlFor='lastName'>
                  Last Name:
                </FormLabel>
              </Grid>
              <Grid item xs={7} lg={9}>
                <TextField
                  disabled={!isEditable}
                  id='lastName'
                  aria-label='Last Name Field'
                  name='lastName'
                  value={formik.values.lastName}
                  sx={styles.gridField}
                  onChange={(event: any) => {
                    formik.handleChange(event)
                  }}
                   onClick={(e) => handleOnFieldClick(e.currentTarget.id)}                  
                  error={getErrorAndHelper(
                    formik.touched.lastName,
                    Boolean(formik.errors.lastName)
                  )}
                  helperText={getErrorAndHelper(
                    formik.touched.lastName,
                    formik.errors.lastName
                  )}
                />
              </Grid>

              <Grid item xs={5} lg={3} sx={styles.leftAlignedText}>
                <FormLabel
                  component='label'
                  required={false}
                  sx={styles.label}
                  htmlFor='email'>
                  E-mail Address:
                </FormLabel>
              </Grid>
              <Grid item xs={7} lg={9}>
                <TextField
                  disabled={!isEditable}
                  id='email'
                  aria-label='Email Field'
                  name='email'
                  value={formik.values.email}
                  sx={styles.gridField}
                  onChange={(event: any) => {
                    formik.handleChange(event)
                  }}
                   onClick={(e) => handleOnFieldClick(e.currentTarget.id)}             
                  error={getErrorAndHelper(
                    formik.touched.email,
                    Boolean(formik.errors.email)
                  )}
                  helperText={getErrorAndHelper(
                    formik.touched.email,
                    formik.errors.email
                  )}
                />
              </Grid>

              <Grid item xs={5} lg={3} sx={styles.leftAlignedText}>
                <FormLabel
                  component='label'
                  required={false}
                  sx={styles.label}
                  htmlFor='invitedBy'>
                  Invited By:
                </FormLabel>
              </Grid>
              <Grid item xs={7} lg={9}>
                <TextField
                  disabled
                  id='invitedBy'
                  aria-label='Invited By Field'
                  name='invitedBy'
                  value={formik.values.invitedBy}
                  sx={styles.gridField}
                  onChange={(event: any) => {
                    formik.handleChange(event)
                  }}
                   onClick={(e) => handleOnFieldClick(e.currentTarget.id)}                  
                  error={getErrorAndHelper(
                    formik.touched.lastName,
                    Boolean(formik.errors.invitedBy)
                  )}
                  helperText={getErrorAndHelper(
                    formik.touched.invitedBy,
                    formik.errors.invitedBy
                  )}
                />
              </Grid>

              <Grid item xs={5} lg={3} sx={styles.leftAlignedText}>
                <FormLabel
                  component='label'
                  required={false}
                  sx={styles.label}
                  htmlFor='roles'>
                  Roles:
                </FormLabel>
              </Grid>
              <Grid item xs={7} lg={9}>
                <Select
                  disabled={!isEditable}
                  displayEmpty
                  name='role'
                  aria-label='role'
                  onChange={(event) => {
                    formik.handleChange(event)
                  }}
                  onClick={(e) => handleOnFieldClick((e.target as HTMLElement).id)}
                  sx={{
                    ...styles.gridField,
                    ...styles.gridSelect,
                    ...styles.limitGridSelect,
                  }}
                  value={formik.values.role}>
                  {formik.values.role === '' &&
                    <MenuItem value='' sx={{ ...styles.gridMenuItem, ...styles.hidden }} disabled>
                      Please Select
                    </MenuItem>
                  }
                  {
                    roleList.map((role, idx) =>
                      <MenuItem key={role.id} value={role.name} sx={styles.gridMenuItem}>
                        {role.name}
                      </MenuItem>
                    )
                  }
                </Select>
              </Grid>

              {/* status */}
              <Grid item xs={5} lg={3} sx={styles.leftAlignedText}>
                <FormLabel
                  component='label'
                  required={false}
                  sx={styles.label}
                  htmlFor='status'>
                  Status:
                </FormLabel>
              </Grid>
              <Grid item xs={7} lg={9}>
                <Select
                  disabled={!isEditable || (!canDeactivateUser && formik.values.status === 'Active') || (!canReactivateUser && formik.values.status === 'Inactive')}
                  displayEmpty
                  name='status'
                  aria-label='status'
                  onChange={(event) => {
                    formik.handleChange(event)
                  }}
                  onClick={(e) => handleOnFieldClick((e.target as HTMLElement).id)}
                  sx={{
                    ...styles.gridField,
                    ...styles.gridSelect,
                    ...styles.limitGridSelect,
                  }}
                  value={formik.values.status}>
                  <MenuItem value={'Active'} sx={styles.gridMenuItem}>
                    Active
                  </MenuItem>
                  <MenuItem value={'Inactive'} sx={styles.gridMenuItem}>
                    Inactive
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box sx={styles.bottomActionsButtonContainer}>
                <Button
                  disabled={!isEditable}
                  variant='outlined'
                  sx={styles.cancelButton}
                  onClick={() => {
                    formik.resetForm();
                    setIsEditable(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!formik.dirty || !formik.isValid  || !isEditable} 
                  variant='contained'
                  type='submit'
                  sx={styles.saveButton}
                  onClick={() => {}}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}

export default UserInformation
