import { Box, Grid, Typography, Button, Paper, TableSortLabel, TablePagination, TextField, Autocomplete, IconButton, FormLabel, AlertColor, Table, TableBody, Skeleton } from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import { useContext, useState, useEffect, useCallback, SyntheticEvent, FC } from "react";
import GeneralBreadcrumbs from "../../../components/breadcrumb";
import ComboBox from "../../../components/common/combo-box";
import WarningModal from "../../../components/file-import/modals/warning-modal";
import { SelectedClientContext } from "../../../context/selectedClientContext";
import { IOption } from "../../../interfaces/comboBox";
import { ICurrency, IRate } from "../../../interfaces/multiCurrencyInterface";
import exchangeRateSchema from "../../../schemas/exhangeRateSchema";
import { multiCurrencyAPI } from "../../../service/api";
import axiosInstance from "../../../service/axiosInstance";
import { API_DOMAIN, ONE_MILLION, ZERO } from "../../../utility/constants";
import { getCurrencies, getLocalStorageItem } from "../../../utility/helper";
import styles from "./styles";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Dayjs } from "dayjs";
import { getLabelDisplayedRows, getLabelRowsPerPage } from "../../../components/client-settings/tabs/tab-panel-contents/pagination";
import HelperTextComponent from "../../../components/common/helper-text-component";
import DisabledComponentsContainer from "../../../components/common/disabled-components-container";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import Toaster from "../../../components/toaster";
import { useNavigate } from "react-router-dom";
import CurrencyRow from "./currency-row";
import { SkeletonRow, SkeletonTableHead } from "../../../components/skeleton";

interface IPaging {
  pageNo:    number;
  pageSize:  number;
  totalSize: number;
}

interface ISorting {
  sortKey:       string;
  sortDirection: string;
}

export interface IToaster {
  isOpen: boolean
  message: string,
  severity: AlertColor,
}

interface PaginationButtonProps {
  page                : number;
  handlePreviousClick : () => void;
  count               : number;
  rowsPerPage         : number;
  handleNextClick     : () => void
}

/**
 * This function returns the Previous Icon Button.
 * @param param0 The parameters of an Icon Button
 * @returns The Previous Icon Button with aria-label and components container.
 */
export const PreviousIconButton = ({ page, onClick }) => {
  const isDisabled = page === 0;

  return (
      <DisabledComponentsContainer isDisabled={isDisabled}>
          <IconButton aria-label={isDisabled ? 'Previous page icon button disabled' : 'Previous page icon'} onClick={onClick} disabled={isDisabled} sx={{ margin: 0 }}>
              <KeyboardArrowLeft />
          </IconButton>
      </DisabledComponentsContainer>
  );
};

/**
 * This function returns the Next Icon Button.
 * @param param0 The parameters of an Icon Button
 * @returns The Next Icon Button with aria-label and components container.
 */
export const NextIconButton = ({ page, count, rowsPerPage, onClick }) => {
  const isDisabled = page === Math.ceil(count / rowsPerPage) - 1;

  return (
      <DisabledComponentsContainer isDisabled={isDisabled}>
          <IconButton aria-label={isDisabled ? 'Next page icon button disabled' : 'Next page icon'} onClick={onClick} disabled={isDisabled} sx={{ margin: 0 }}>
              <KeyboardArrowRight />
          </IconButton>
      </DisabledComponentsContainer>
  );
};

/**
 * This function gets the button component for the Pagination Buttons.
 * @param type The type of the Pagination Button. Either 'prev' or 'next'.
 * @param paginationProps The props for the pagination button of the table.
 * @returns A component for the Pagination buttons.
 */
const getPaginationButtonsComponent = (type: 'prev' | 'next', paginationProps: PaginationButtonProps) => {
  const { page, handlePreviousClick, count, rowsPerPage, handleNextClick } = paginationProps;

  if (type === 'prev') {
    return () => <PreviousIconButton page={page} onClick={handlePreviousClick} />
  } else {
    return () => <NextIconButton page={page} count={count} rowsPerPage={rowsPerPage} onClick={handleNextClick} />
  }
};

/**
 * Renders a Component for handling exchange rate
 */
const ExchangeRate: FC = () => {
  const navigate                                    = useNavigate();
  const selectedClientContext                       = useContext(SelectedClientContext);
  const [isoList, setIsoList]                       = useState<ICurrency[]>();
  const [selectedDate, setSelectedDate]             = useState<Dayjs | null>();
  const [filteredRates, setFilteredRates]           = useState<IRate[]>();
  const [openModal, setOpenModal]                   = useState<boolean>(false);
  const [invalidIso, setInvalidIso]                 = useState<string>();
  const [currency, setCurrency]                     = useState<ICurrency|null>(null);
  const [isDirty, setIsDirty]                       = useState<boolean>(false);
  const [clients, setClients]                       = useState<IOption[]>([]);
  const [selectedClient, setSelectedClient]         = useState<IOption | null>(null);
  const [clientInput, setClientInput]               = useState<string>('');
  const [clientCurrency, setClientCurrency]         = useState<ICurrency>();
  const [pageProps, setPageProps]                   = useState<IPaging>({pageNo: 0, pageSize: 10, totalSize: 0});
  const [sortProps, setSortProps]                   = useState<ISorting>({sortKey: 'toCurrencyId', sortDirection: 'DESC'})
  const [toasterProps, setToasterProps]             = useState<IToaster>({isOpen: false, message: '', severity: 'success'})
  const [currencyHelperText, setCurrencyHelperText] = useState<string>();
  const [isLoading, setIsLoading]                   = useState<boolean>(true)


  /**
   * This function determine if there is changed on the formik form.
   * 
   * @param node Formik values used on form.
   */
  const formikRef = (node : any) => {
    if (node !== null) {
      setIsDirty(node.dirty)
    }
  };

  /**
   * This useFormik is used to easily access formik properties.
   */
  const formik = useFormik({
    initialValues: {
      asOfDate: undefined,
      exchangeRate: '',
      currencyCode: undefined
    },
    validateOnBlur: true,
    validationSchema: exchangeRateSchema,
    onSubmit: (values) => {
        axiosInstance.post(
          `${API_DOMAIN}/rate/addRate`,
          {
            borrowerId: selectedClient?.recordId,
            fromCurrencyId: clientCurrency?.recordId,
            toCurrencyId: values.currencyCode,
            currencyRate: values.exchangeRate,
            asOfDate: selectedDate?.utc(true), //toDate
            userName: `${getLocalStorageItem('firstName')} ${getLocalStorageItem('lastName')}`
            }
                  )
        .then(() => {
          formik.resetForm()
          setToasterProps({
            isOpen: true,
            message: 'Exchange Rate Added',
            severity: 'success'
          })
          setIsDirty(false)
          setCurrency(null)
          setSelectedDate(null)
        })
        .catch((error) => {
          console.log('POST RATE : ', error)
        })
    },
    innerRef: formikRef
  });
  

  /**
   * This useEffect fetches the current selected client data.
   */
  useEffect(() => {
    const fetchedClients: IOption[] = selectedClientContext.clients
      .filter(client => !client.parentClientFk)
      .map(client => {
        const option: IOption = {
          recordId: client.recordId ?? 0,
          label: client.borrowerName ?? '',
          default: Boolean(client.default)
        };
        return option;
      });
    setClients(fetchedClients);

    const selectedClient: IOption | undefined = fetchedClients.find(client =>
      client.recordId === selectedClientContext.selectedClient?.recordId
    );
    setSelectedClient(selectedClient ?? null);
  }, [selectedClientContext.clients, selectedClientContext.selectedClient])


  /**
   * This useEffect triggers the function getCurrencyId of the selectedClient,
   * also triggers when selectedClient variable value was changed.
   */
  useEffect(() => {
    initialPageFetch();
  }, [selectedClientContext.selectedClient])

  /**
   * This useEffect triggers the fetchRate function to get the paginated response base on the pageProps variables values.
   * also triggers when pageProps variable was changed.
   */
  useEffect(() => {
    fetchRatesOnChangeOfSelectedDate();
  },[pageProps.pageNo, pageProps.pageSize, sortProps, selectedDate])

  const initialPageFetch = async () => {
    setIsLoading(true);

    if (selectedClientContext.selectedClient?.currencyId && selectedClientContext.selectedClient?.recordId) {
      const currencyId = parseInt(selectedClientContext.selectedClient.currencyId);
      const recordId = selectedClientContext.selectedClient.recordId;

      await fetchCurrency(currencyId);
      await fetchRate(recordId, currencyId);
    }

    setIsLoading(false);
  };

  const fetchRatesOnChangeOfSelectedDate = async () => {
    setIsLoading(true);
    if (selectedClientContext.selectedClient?.currencyId && selectedClientContext.selectedClient?.recordId) {
      const currencyId = parseInt(selectedClientContext.selectedClient.currencyId);
      const recordId = selectedClientContext.selectedClient.recordId;

      await fetchRate(recordId, currencyId);
    }
    setIsLoading(false);
  };

  /**
   * This useCallback is used to handle changes on the form when the user select a value on any text field's dropdown.
   */
  const handleChange = useCallback((e: SyntheticEvent<Element, Event>, newValue: IOption | null) => {
    setSelectedClient(newValue) 

    const newContextValue = selectedClientContext.clients.find(client =>
      client.recordId === newValue?.recordId
    );
    selectedClientContext?.setSelectedClient(newContextValue ?? null)
    formik.resetForm();
    setSelectedDate(null);
    setIsDirty(false)
    setCurrency(null)
    navigate(`/clients/${newValue?.recordId}/settings/exchangeRate`)
  }, [selectedClientContext]);

  /**
   * This useCallback is used to handle input change on the foorm when the user type something on any text field.
   */
  const handleInputChange = useCallback((_e: SyntheticEvent<Element, Event>, newInputValue: string) => setClientInput(newInputValue), []);
  
  /**
   * This function fetch all currencies (ISO) and it also set's the value for selected client's currencyId.
   */
  const fetchCurrency = async (currencyId: number) => {
    try {
      const response = await getCurrencies(currencyId);
      setIsoList(response?.currencies);
      setClientCurrency(response?.clientCurrency);
    } catch (error) {
      console.log('GET CURRENCY : ', error);
    }
  }

  /**
   * This function fetches all of the exchange rate for the selected client's currencyid
   */
  const fetchRate = async (borrowerId: number, currencyId: number) => {
    try {
      const response = await axiosInstance.request({
        url: multiCurrencyAPI.GET_ALL_RATES,
        params: {
          borrowerId,
          fromCurrencyId: currencyId,
          asOfDate: selectedDate?.format('YYYYMMDD'),
          pageNo: pageProps.pageNo,
          pageSize: pageProps.pageSize,
          sortBy: `${sortProps.sortKey}, ${sortProps.sortDirection}`
        }
      });

      const temp: IRate[] = response.data.content;
      setPageProps({...pageProps, totalSize: response.data.totalElements})

      if (selectedDate) {
        const formattedDate = selectedDate.format('YYYY-MM-DD');
        const borrowerId = selectedClient?.recordId;
        const filtered = temp?.filter(rate => rate.asOfDate.toString().includes(formattedDate) && rate.borrowerId === borrowerId);
        setFilteredRates(filtered);
        handleCurrencyHelperText(filtered);
      } else {
        setFilteredRates(temp);
      }
    } catch (error) {
      console.log('GET RATES : ', error);
    }
  }

  /**
   * This function check if the currency that being created does exist on the selected asOfDate.
   * 
   * @param code ISO code of the currency to be added.
   * @returns Boolean, false if the given currency code exists on a specific as of date.
   */
  const checkUnique = (code?: string) => {
    if(code && selectedDate){
      return filteredRates?.some(rate => rate.toCurrency?.currencyCode === code)
    }
    return false
  }

  /**
   * This function handle the process for displaying the ISO code and the Name of the selected currency.
   * 
   * @param recordId The ID of the selected currency.
   * @returns The display string for the currency text field.
   */
  const getCurrency = (recordId: number) => {
    const currency = isoList?.find(iso => iso.recordId === recordId)
    return `${currency?.currencyCode} - ${currency?.currencyName}`
  }

  /**
   * This function returns a currency object base on the given currency recordId.
   * it is used to set the state variable currency's value
   * 
   * @param recordId The ID of the selected currency.
   * @returns An ICurrency object.
   */
  const getCurrencyObject = (recordId?: string) => {
    const currency = isoList?.find(iso => iso.recordId.toString() === recordId)
    return currency
  }

  /**
   * This function is used to determine if the row component will have a lighter or a darker background color.
   * 
   * @param index Index ID of the row component.
   * @returns Boolean, true if the index is odd
   */
  const isOdd = (index: number) => index%2!==0;

  /**
   * This function determine if the sort direction will display desc or asc
   * 
   * @param title The field that being sorted.
   * @returns An IconComponent props.
   */
  const getIconComponent = (title: string) => {
    if (sortProps.sortKey === title) {
      return;
    } else {
      return { IconComponent: handleIcon };
    }
  };

  /**
   * This function return either default sort icon or an arrow icon following the sort direction of the field that is being sorted.
   */
  const handleIcon = useCallback(() => <UnfoldMoreIcon sx={styles.iconDefaultSort}/>, []);

  /**
   * This function handle changes on the sorting functionality of the exchange rate table.
   * 
   * @param title The field that being sorted.
   */
  const handleSort = (title: string) => {
    if(sortProps.sortKey === title){
      const newSortDirection = sortProps.sortDirection === 'DESC' ? 'ASC' : 'DESC';
      setSortProps({...sortProps, sortDirection: newSortDirection});
    }else{
      setSortProps({sortKey: title, sortDirection: 'ASC'})
    }
    setPageProps({...pageProps, pageNo: 0})
  };

  /**
   * This function handles the clicking of the previous button in the table pagination.
   */
  const handlePreviousClick = () => {
    if (pageProps.pageNo > 0) {
      setPageProps({...pageProps, pageNo: pageProps.pageNo - 1});
    }
  };

  /**
   * This function handles the clicking of the next button in the table pagination.
   */
  const handleNextClick = () => {
    if (pageProps.pageNo < Math.ceil(pageProps.totalSize / pageProps.pageSize) - 1) {
      setPageProps({...pageProps, pageNo: pageProps.pageNo + 1});
    }
  };

  /**
   * This function gets the helper text for common fields.
   * @param name The name of the field.
   * @returns The helper text component if there are errors, or null if none.
   */
  const getCommonHelperText = (name: string) => {
    return formik.touched[name] && formik.errors[name]
      ? <HelperTextComponent text={formik.errors[name]} />
      : null
  };

  /**
   * This function handles the update on error message if the selected date was changed.
   * @param filtered A list of Rates from API call
   */
  const handleCurrencyHelperText = (filtered: IRate[]) => {
    if (formik.values.currencyCode) {
      const code: number = formik.values.currencyCode
      const notUnique = filtered?.some(rate => rate.toCurrency?.recordId === code)
      if (notUnique) {
        setCurrencyHelperText('This exchange rate is already saved');
        setInvalidIso(getCurrency(code));
        setOpenModal(true);
      } else {
        setCurrencyHelperText(undefined);
      }
    }
  }

  /**
   * This function checks if the currency texfield has an error
   * @returns A boolean value
   */
  const getCurrencyError = () => {
    return (formik.touched.currencyCode || Boolean(currencyHelperText)) 
    && (Boolean(formik.errors.currencyCode) || Boolean(invalidIso))
  }

  /**
   * This function returns a loading component
   */
  const getLoadingContent = () => (
    <Table sx={styles.table} data-testid='other-loans-loader'>
      <SkeletonTableHead />
      <TableBody>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </TableBody>
    </Table>
  );

  const getEmptyTableContent = () => (
    <Grid container sx={styles.gridContainer}>
      <Typography tabIndex={0} sx={styles.textFont}>
        {selectedDate ? 'Selected As of Date does not have any exchange rate' : 'No Data Available'}
      </Typography>
    </Grid>
  );

  const getRateContent = () => (
    <>
      <Grid 
        container
        justifyContent={'space-between'}
        sx={styles.header}
      >
        <Grid item xs={3.5}>
          <Box sx={styles.currencyNameContainer}>
            <TableSortLabel
              aria-label='ISO Currency Code sort'
              active={sortProps.sortKey === 'toCurrencyId'}
              direction={sortProps.sortDirection === 'DESC' ? 'desc' : 'asc'}
              onClick={() => handleSort('toCurrencyId')}
              {...getIconComponent('toCurrencyId')}
            >
              <Typography sx={styles.userNameSpace}>
                <Box component="span" fontWeight='fontWeightBold' fontSize={16}>ISO Currency Code</Box>
              </Typography>
            </TableSortLabel>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={styles.rateContainer}>
            <Typography tabIndex={0}>
              <Box component="span" fontWeight='fontWeightBold' fontSize={16}>{`1 ${clientCurrency?.currencyCode}`}</Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={styles.asOfDateContainer}>
            <TableSortLabel
              aria-label='As of Date sort'
              active={sortProps.sortKey === 'asOfDate'}
              direction={sortProps.sortDirection === 'DESC' ? 'desc' : 'asc'}
              onClick={() => handleSort('asOfDate')}
              {...getIconComponent('asOfDate')}
            >
              <Typography sx={styles.asOfDateHeader}>
                <Box component="span" fontWeight='fontWeightBold' fontSize={16}>As of Date</Box>
              </Typography>
            </TableSortLabel>
          </Box>
        </Grid>
        <Grid item xs={2.5}>
          <Box sx={styles.userContainer}>
            <TableSortLabel
              aria-label='user sort'
              active={sortProps.sortKey === 'userName'}
              direction={sortProps.sortDirection === 'DESC' ? 'desc' : 'asc'}
              onClick={() => handleSort('userName')}
              {...getIconComponent('userName')}
            >
              <Typography>
                <Box component="span" fontWeight='fontWeightBold' fontSize={16}>User</Box>
              </Typography>
            </TableSortLabel>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={styles.actionsContainer}>
            <Typography tabIndex={0}>
              <Box component="span" fontWeight='fontWeightBold' fontSize={16}>Actions</Box>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {
        filteredRates?.map((rate, idx) => (
          <CurrencyRow 
            key={rate.recordId}
            clientCurrency={clientCurrency} 
            selectedClient={selectedClient} 
            rate={rate} 
            idx={idx} 
            isoList={isoList} 
            filteredRates={filteredRates}
            getCurrency={getCurrency} 
            getCurrencyObject={getCurrencyObject} 
            isOdd={isOdd} 
            fetchRate={fetchRate}
            setToasterProps={setToasterProps}    
          />
        ))
      }
    </>
  );

  const getTableContent = () => {
    if (isLoading) {
      return getLoadingContent();
    } else if (filteredRates?.length) {
      return getRateContent();
    } else {
      return getEmptyTableContent();
    }
  };

  const getClientCurrencyContent = () => {
    if (isLoading) {
      return (<Skeleton variant="text" sx={styles.skeletonClientCurrency} />);
    } else if (clientCurrency) {
      return (
        <Typography tabIndex={0} sx={styles.textFont}>
          <Box component="span" fontWeight='fontWeightBold' fontSize={16}>= </Box> 
          {`1 ${clientCurrency.currencyCode} - ${clientCurrency.currencyName}`}
        </Typography>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Box component="span" sx={styles.breadCrumbBox}>
        <Grid container sx={styles.headerContainer}>
          <Grid item xs={12} md={6} lg={8} xl={8.3}>
            <GeneralBreadcrumbs 
              selectedText='Exchange Rate' 
              breadcrumbLinks={[
                { linkText: 'Clients', route: '/clients' },
                { linkText: 'Client Settings', route: `/clients/${selectedClientContext?.selectedClient?.recordId}/settings` }
                ]} />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
            <Box sx={styles.clientBox}>
              <ComboBox
                id='client'
                options={clients}
                value={selectedClient}
                inputValue={clientInput}
                onChange={handleChange}
                onInputChange={handleInputChange}
              />
            </Box>
          </Grid>
        </Grid>   
      </Box>
      <Box sx={styles.titleContainer}>
        <Typography tabIndex={0} variant='h6' component='h3' sx={styles.title} aria-label="exchange-rate-label">
          Exchange Rate
        </Typography>
      </Box>      
      <form onSubmit={formik.handleSubmit} onChange={() => {
        if(formik.touched && formik.dirty) setIsDirty(true) 
      }}>
        <Box>
          {/* as of date */}
          <Grid container sx={styles.topContainer}>
            <Box sx={styles.dateLabel}>
            <FormLabel
                tabIndex={0} 
                required={true}
                htmlFor='as-of-date'
                sx={styles.formLabel}>
                  As of Date
              </FormLabel>
            </Box>
            <Box sx={styles.dateField}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={selectedDate ?? null}
                onChange={(value) => {
                  if(value?.format() === 'Invalid Date'){
                    formik.setFieldError('asOfDate', 'Invalid Date');
                    formik.setTouched({...formik.touched, asOfDate: true})
                  }else{
                    formik.setFieldError('asOfDate', undefined);
                    formik.setTouched({...formik.touched, asOfDate: false})
                    setSelectedDate(value)
                    setPageProps({...pageProps, pageNo: 0})
                  }

                  formik.setFieldValue('asOfDate', value)
                }}
                renderInput={(props) =>
                  <TextField
                    {...props}
                    id='asOfDate'
                    inputProps={{
                      ...props.inputProps,
                      'aria-label':'Calendar icon', 'aria-labelledby': 'asOfDate'
                    }}
                    error={formik.touched.asOfDate && Boolean(formik.errors.asOfDate)}
                    helperText={getCommonHelperText('asOfDate')}
                    onBlur={formik.handleBlur}
                    sx={styles.textField}
                  />}
              />
            </LocalizationProvider>
            </Box>
          </Grid>
          {/* exchange rate - ISO code */}
          <Grid container alignItems={'center'} marginTop={3}>
            <Box sx={styles.dateLabel}>
              <FormLabel
                tabIndex={0} 
                required={true}
                htmlFor='exchange-rate'
                sx={styles.formLabel}>
                  Exchange Rate
              </FormLabel>
            </Box>
            <Box sx={{...styles.dateField, gap: '0.5rem'}}>
              {/* exchange rate */}
              <TextField
                id='exchangeRate'
                value={formik.values.exchangeRate}
                onChange={(e) => {
                  const newValue = e.target.value;
    
                  // Allow only numbers and decimal
                  if (/^\d*\.?\d*$/.test(newValue)) {
                    // Ensure the value is within min and max range
                    const numericValue = parseFloat(newValue);
                    if (!isNaN(numericValue) && numericValue >= ZERO && numericValue <= ONE_MILLION) {
                      // Limit decimal places to 8
                      const limitedDecimalValue = newValue.includes('.') 
                        ? newValue.slice(0, newValue.indexOf('.') + 9)  // 8 decimal places
                        : newValue;
                      formik.setFieldValue('exchangeRate', limitedDecimalValue);
                    }
                    
                    if (newValue === "") {
                      formik.setFieldValue('exchangeRate', "");
                    }
                  }
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.exchangeRate && Boolean(formik.errors.exchangeRate)}
                helperText={getCommonHelperText('exchangeRate')}
                type="text"
                inputProps={{
                  inputMode: 'numeric',
                  'aria-label':'Exchange Rate',
                  'aria-labelledby': 'exchangeRate',
                }}
                sx={styles.exchangeRateField}
              />
              {/* currency code */}
              <Autocomplete
                id="currencyCode"
                size="small"
                value={currency}
                options={isoList ?? []}
                getOptionLabel={(option) => `${option.currencyCode} - ${option.currencyName}`}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    title={`${option.currencyCode} - ${option.currencyName}`}
                    {...props}
                  >
                    {`${option.currencyCode} - ${option.currencyName}`}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={getCurrencyError()}
                    helperText={formik.touched.currencyCode && (formik.errors.currencyCode || currencyHelperText)}
                    inputProps={{
                      ...params.inputProps,
                      'aria-label':'Currency Code',
                      'aria-labelledby': 'currencyCode',
                    }}
                    sx={styles.textField}
                  />
                )}   
                onBlur={formik.handleBlur}
                onChange={(_event, value) => {
                  if(checkUnique(value?.currencyCode)){
                    setInvalidIso(getCurrency(value?.recordId ?? 0))
                    setCurrencyHelperText('This exchange rate is already saved')
                    setOpenModal(true)
                  }else{
                    setInvalidIso(undefined)
                    setCurrencyHelperText(undefined)
                  }
                  const selected = getCurrencyObject(value?.recordId.toString() ?? '')
                  setCurrency(selected ?? null)
                  formik.setFieldValue('currencyCode', value?.recordId)
                }}
                sx={styles.autoComplete}
                aria-label='currency-code'
                componentsProps={{
                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                }
              />
              {/* = 1 client currency */}
              {getClientCurrencyContent()}
              {/* submit button */}
              <DisabledComponentsContainer isDisabled={!(formik.isValid && isDirty && !checkUnique(formik.values.currencyCode) && !invalidIso)}>
                <Button
                  disabled={!(formik.isValid && isDirty && !checkUnique(formik.values.currencyCode) && !invalidIso)}
                  aria-label={!(formik.isValid && isDirty && !checkUnique(formik.values.currencyCode) && !invalidIso) ? 'Add Currency Rate button disabled' : 'Add Currency Rate'}
                  variant='contained'
                  color='primary'
                  type='submit'
                  sx={styles.saveButton}
                >
                  Add Currency Rate
                </Button>
              </DisabledComponentsContainer>
            </Box>
          </Grid>
        </Box>
      </form>
      <Paper
        variant='outlined'
        sx={styles.paper}
      >
        {getTableContent()}
      </Paper>
      {filteredRates &&
        <TablePagination
          component='div'
          count={pageProps.totalSize}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={pageProps.pageSize}
          page={pageProps.pageNo}
          onPageChange={(_e, page) => setPageProps({...pageProps, pageNo: page })}
          onRowsPerPageChange={(e) => setPageProps({...pageProps, pageSize: parseInt(e.target.value), pageNo: 0})}
          labelDisplayedRows={getLabelDisplayedRows()}
          labelRowsPerPage={getLabelRowsPerPage()}
          backIconButtonProps={{
            component: getPaginationButtonsComponent('prev', { page: pageProps.pageNo, handlePreviousClick, count: pageProps.totalSize, rowsPerPage: pageProps.pageSize, handleNextClick })
          }}
          nextIconButtonProps={{
            component: getPaginationButtonsComponent('next', { page: pageProps.pageNo, handlePreviousClick, count: pageProps.totalSize, rowsPerPage: pageProps.pageSize, handleNextClick })
          }}
          SelectProps={{ 
            inputProps: {
              'aria-label': 'Expand below icon',
              'aria-labelledby': 'Expand below icon',
            },
            id:'expandBelowIcon'
           }}
      />
      }
      <WarningModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        issueMessages={[`There is an existing ${invalidIso} exchange rate. The currency code cannot be added since it has already been saved.`]}
        issueType="error"
        displayOnly
      />
      <Toaster
        open={toasterProps.isOpen}
        message={toasterProps.message}
        severity={toasterProps.severity}
        onCloseChange={() => setToasterProps({...toasterProps, isOpen: false})}
      />
    </>
  )
}

export default ExchangeRate