const styles = {
  modal: {
    position      : 'absolute' as const,
    top           : '50%',
    left          : '50%',
    transform     : 'translate(-50%, -50%)',
    overflow      : 'hidden',
    bgcolor       : 'background.paper',
    boxShadow     : 24,
    padding       : '1.5rem',
    alignItems    : 'center',
    justifyContent: 'center',
    maxWidth      : '1280px'
  },
  title: {
    color: '#154A8A',
    fontWeight: 'bold',
    marginRight: 'auto',
  },
  stepperContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mappingContainer: {
    width: '100%',
    display: 'flex'
  }
};
export default styles;