const styles = {
  fieldsContainer: {
    marginTop: '1rem',
    marginBottom: '1.5rem',
  },
  fieldLabel: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  field: {
    fontSize: '14px',
    marginTop: '0.5rem',
    backgroundColor: 'white',
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  generateReportButton: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '10rem',
    height: '2rem',
    marginTop: '2.05rem'
  }
};

export default styles;