import Box from '@mui/material/Box';
import IneligibleSettingsProvider from '../../context/ineligibleSettingsContext';
import IneligibleSettingsFormContainer from '../../components/ineligible-settings/form-container';
import styles from './styles';

/**
 * Component responsible for rendering the Ineligible Settings page.
 * @returns The rendered Ineligible Settings page.
 */
const IneligibleSettings = () => {
  return (
    <IneligibleSettingsProvider>
      <Box sx={styles.pageStyle}>
        <IneligibleSettingsFormContainer />
      </Box>
    </IneligibleSettingsProvider>
  );
};

export default IneligibleSettings;
