import { createContext, useMemo, useState } from 'react';
import { IClient, ILoanBalancesContext } from '../interfaces/loanBalancesInterface';
import { LayoutProps } from './authContext';

export const LoanBalancesContext = createContext<ILoanBalancesContext | null >(null);

const LoanBalancesProvider = (props: LayoutProps) => {
  const { children } = props;
  const [clients, setClients] = useState<IClient[]>([]);
  const [selectedClient, setSelectedClient] = useState<IClient>({ recordId: -1, borrowerName: 'Loading...' });
  const [lastSelectedClient, setLastSelectedClient] = useState<IClient | null>(null);  // for storing last selected client for confirm navigation modal
  const [totalLoanBalances, setTotalLoanBalances] = useState(0);
  const [showPrompt, setShowPrompt] = useState(false); // confirmation modal for manual navigation warning  

  const [ viewLoanBalances, setViewLoanBalances ] = useState<boolean>(false);
  const [ addLoanBalances, setAddLoanBalances ] = useState<boolean>(false);
  const [ updateLoanBalances, setUpdateLoanBalances ] = useState<boolean>(false);
  const [ deleteArchiveLoanBalances, setDeleteArchiveLoanBalances ] = useState<boolean>(false);

  const value = useMemo(() => ({
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    lastSelectedClient,
    setLastSelectedClient,
    totalLoanBalances,
    setTotalLoanBalances,
    showPrompt,
    setShowPrompt,
    viewLoanBalances,
    setViewLoanBalances,
    addLoanBalances,
    setAddLoanBalances,
    updateLoanBalances,
    setUpdateLoanBalances,
    deleteArchiveLoanBalances,
    setDeleteArchiveLoanBalances
  }), [
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    lastSelectedClient,
    setLastSelectedClient,
    totalLoanBalances,
    setTotalLoanBalances,
    showPrompt,
    setShowPrompt,
    viewLoanBalances,
    setViewLoanBalances,
    addLoanBalances,
    setAddLoanBalances,
    updateLoanBalances,
    setUpdateLoanBalances,
    deleteArchiveLoanBalances,
    setDeleteArchiveLoanBalances
  ])

  return (
    <LoanBalancesContext.Provider
      value={value}
    >
      {children}
    </LoanBalancesContext.Provider>
  );
};

export default LoanBalancesProvider;
