import colors from "../../../utility/colors";

const styles = {
  modalBackground: {
    bgcolor: '#FFFFFF',
    borderRadius: '5px',
    left: '50%',
    position: 'absolute',
    boxShadow: 15,
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '730px',
    display: 'flex',
    flexDirection: 'column',
  },

  headingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    m: 0,
    pb: '10px',
    pl: '21px',
    pr: '21px',
    pt: '24px',
  },

  headingTitle: {
    color: '#1B1E21',
    fontSize: '18px',
  },

  closeButton: {
    cursor: 'pointer',
    color: '#808080',
    stroke: '#808080',
    strokeWidth: 1,
    fontSize: 20,
    alignSelf: 'flex-start',
  },

  divider: {
    bgcolor: '##E9ECEF',
    borderBottomWidth: 2,
    opacity: '0.5',
  },

  rightButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: 'auto',
    my: '16px',
    mx: '20px',
  },

  okayButton: {
    minWidth: '82px',
    height: '31px',
    ml: 1,
    textTransform: 'capitalize',
    backgroundColor: colors.PRIMARY,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: colors.PRIMARY_DARK,
    },
  },

  buttonContainer: {
    display: 'flex', 
    justifyContent: 'space-between'
  },

  pasteTextButton: {
    position: 'relative',
    left: '5px',
    bottom: '13px',
    minWidth: '82px',
    ml: 1,
    textTransform: 'capitalize',
    backgroundColor: colors.PRIMARY,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: colors.PRIMARY_DARK,
    },
  },

  resetButton: {
    position: 'relative',
    right: '12px',
    bottom: '13px',
    minWidth: '82px',
    height: '31px',
    color: colors.PRIMARY,
    border: '1px solid #010953',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },

  cancelButton: {
    minWidth: '82px',
    height: '31px',
    color: colors.PRIMARY,
    border: '1px solid #010953',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },

  messageText: {
    marginY: '20px',
    fontSize: '16px',
    color: '#000000',
    alignSelf: 'center',
    textAlign: 'center',
  },

  comboBox: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },

  dropFileIconBox: {
    textAlign      : 'center',
    fontSize       : '18px',
    color          : '#010953',
    paddingY       : '1rem',
  },

  dropFileContainer: {
    border         : 'dashed',
    margin         : 'auto',
    width          : '100%',
    borderColor    : '#707070',
    borderRadius   : '20px',
    borderWidth    : 'medium',
    zIndex : '2'
  },

  dropFile: {
    textAlign      : 'center',
    fontSize       : '14px',
    fontWeight     : 'bold',
    color          : '#010953',
    paddingY       : '0.75rem',
  },

  fileExtensions: {
    position: 'relative',
    top: '5px',
    fontSize       : '12px',
    color          : '#868E96'
  },
  
  dropFileIcon: {
    fontSize       : '50px',
    paddingY       : '5px'
  },

  dropFileButton: {
    textAlign      : 'center',
  },

  optionsContainer: {
    display:'flex', 
    margin:'0.89rem',
    backgroundColor: '#E0E0E0',
  },

  optionsChildContainer: {
    flex: '0.33',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },

  optionLabel: {
    fontWeight: 'bold', 
    paddingBottom: '3px'
  },

  uploadTabContainer: {
    flex: '1',
    backgroundColor: '#FFFFFF',
    margin: '10px 10px 10px 0'
  },

  highlightedText: {
    fontWeight: 600,
    color: colors.PRIMARY_TEXT,
    padding: '0 5px',
  },

  textArea: {
    width: '95%', 
    margin: '13px', 
    position: 'relative', 
    bottom: '13px',
  },

  yellow: {
    backgroundColor: 'yellow',
  },

  instructionLabel: {
    margin: '7px'
  },

  importButton: {
    paddingX: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
			cursor: 'pointer',
      backgroundColor: 'transparent',
		},

		'&:hover .MuiTypography-root': {
      color: colors.PRIMARY_LIGHT,
      textDecoration: 'underline',
    },
  },

  addButton: {
    paddingX: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
			cursor: 'pointer',
      backgroundColor: 'transparent',
		},

		'&:hover .MuiTypography-root': {
      color: colors.PRIMARY_LIGHT,
      textDecoration: 'underline',
    },
  }
};

export default styles;
