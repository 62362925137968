const styles = {
  filterBox: {
    display: 'block',
    position: 'relative',
    paddingTop: '21.6px',
  },
  filterGridContainer: {
    marginBottom: '20px',
  },
  generateReportBtn: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginTop: '0.2rem',
    width: '10rem',
    height: '36.6px',
    ':hover': {
      backgroundColor: '#0069D9',
    },
  },
  generateReportBtnContainer: {
    width: '100%',
    height: '100%',
  },
  clientDropdown: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  clientBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  breadCrumbBox: {
    paddingRight: '24px',
    borderRadius: '0.3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paperContainer: {
    paddingY: '1rem',
    boxShadow: '0px 3px 6px #00000029',
  },
  labelCommon: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginTop: '0.12rem'
  },
  toggleButtonGroup: {
    width: '100%',
    height: '2.25rem'
  },
  toggleButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  viewByContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
  },
  totalAmountText: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '1rem',
    fontWeight: 'bold',
  },
  totalAmount: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  totalAmountContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  hidden: {
    display: 'none'
  },
  varianceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '2rem',
    marginBottom: '-1rem',
  },
  varianceText: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '1rem',
    fontWeight: 'bold',
  },
  variance: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
}
export default styles;