import images from "../../theme/images";

const styles = {
    paperStyle: {
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '10px',
        width: '470px',
        aspectRatio: '514/528',
        borderRadius: '8px'
    },

    defaultMargin: {
        margin: "10px auto"
    },

    pageBox: {
        display: 'flex',
        width: '100vw',
        height: '100vh',
        backgroundImage: `linear-gradient(to right, rgba(255,255,255, 0.9) 0 100%), URL(${images.loginPageBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '1'
    },

    loginForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    modalTitle: {
        textAlign: 'center',
        paddingBottom: '15px',
    },

    modalInstructions: {
        fontWeight: 'bold',
        marginLeft: '59px',
        marginRight: '59px',
        fontSize: '14px'
    },

    red: {
        color: '#D1062E',
        marginRight: '2px',
        lineHeight: '14px',
    },

    modalInstructionsRequired: {
        marginLeft: '59px',
        marginRight: '59px',
        fontSize: '14px'
    },

    formContainer : {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        width: 'auto',
        marginLeft: '35px',
        marginRight: '35px'
    },

    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
    },

    inputField: {
        '& .MuiFormHelperText-root.Mui-error': {
            marginLeft:0,
        },
        '& .MuiOutlinedInput-notchedOutline' : {
            borderColor: '#D4DCFF',
            color: 'black'
        },
        '&&&&& .MuiInputBase-root' : {
            backgroundColor: '#F9FAFF',
        },
        '&&&&& .MuiOutlinedInput-input' : {
            backgroundColor: '#F9FAFF',
            WebkitBoxShadow: "0 0 0 1000px #F9FAFF inset",
        },
        width: '100%',
        maxWidth: '352px',
        marginTop: '0px',
        marginBottom: '0px',
    },
    
    inputLabel: {
        display: 'flex',
        fontSize: '12px',
        letterSpacing: '1px',
        lineHeight: '14px',
        color: '#212529',
        fontWeight: '500',
        marginBottom: '16px',
        marginTop: '16px',
        fontFamily: 'Helvetica, Arial, sans-serif', 
    },

    subtextContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '17px',
        marginTop: '16px'
    },

    checkBox: { 
        '& .MuiSvgIcon-root': { 
            fontSize: '15px',
        },
        padding: '0px 4px 0px 9px' 
    },

    checkBoxLabel: {
        '&	.MuiFormControlLabel-label': {
            fontSize: '12px',
            letterSpacing: '0.5px'
        },
    },

    linkText: {
        fontSize: '12px',
        fontFamily: 'Roboto,Helvetica',
        alignSelf: 'center',
    },

    buttonContainer: {
        marginTop: '17px'
    },

    loginButton: {
        width: '100%',
        height: '55px',
        marginBottom: '60px',
    },

    errorIcon: {
        paddingLeft: '8px',
        color: '#AE2633',
    },

    invisible: {
        display: 'none',
    },

    errorMessage: {
        color: '#D1062E',
        marginLeft: '0px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: '400',
        fontSize: '0.75rem',
        lineHeight: '1.66'
    },
    
    visibilityIcon: {
        color: '#767676'
    }

}

export default styles;