const styles = {
  description: {
    paddingLeft: '6rem',
    textAlign: 'left'
  },
  tableContainer: {
    padding: '1rem',
  },
  tableSpacer: {
    height: '1rem',
  },
  tableHead: {
    backgroundColor: '#F7F7F7',
    color: 'inherit',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    border: 0,
    height: '2.5rem',
    '&:nth-child(2)': {
      pl: '0.5rem',
    },
    '&:last-child': {
      p: 0,
      paddingRight: '3rem',
    },
  },
  tableHeadSelectTag: {
    backgroundColor: '#F7F7F7',
    color: 'inherit',
    fontSize: '0.9rem',
    border: 0,
    height: '2.5rem',
    p: 0,
  },
  tableHeadNumber: {
    textAlign: 'right',
  },
  tableHeadRow: {
    boxShadow: '0px 3px 6px #00000029',
  },
  iconDefaultSort: {
    color: '#767272',
    fontSize: '16px'
  },
  tableContainerEmpty: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '520px',
  },
};

export default styles;