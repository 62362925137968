import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react"
import { IParentCustomer, IToasterProps } from "../.."
import VendorRow from "../vendor-row"
import CustomerRow from "../customer-row"
import { Box, LinearProgress } from "@mui/material"
import { IARCustomer, IARVendor } from "../../../../interfaces"

export interface IProps extends IToasterProps {
  parent: IParentCustomer
  filteredParentCustomer?: IParentCustomer[],
  arCustomerList: IARCustomer[],
  arVendorList: IARVendor[],
  setParentCustomers: Dispatch<SetStateAction<IParentCustomer[] | undefined>>
  setFilteredParentCustomers: Dispatch<SetStateAction<IParentCustomer[] | undefined>>
}

const ScrollableSection = (props:IProps) => {
  const childrenLength = props.parent.children?.length ?? 0;
  const [currentItems, setCurrentItems] = useState<number>(10);
  const parentObserver                  = useRef<any>()

  // EVENT LISTENER - FOR INFINITE SCROLL

  /**
   * This useCallback is used to hanlde infinite scrolling.
   */
  const lastRowParentElementRef = useCallback((node: any) => {
    if (parentObserver.current) parentObserver.current.disconnect()
    
    parentObserver.current = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting && currentItems !== childrenLength) {
        setCurrentItems(prevState => prevState+10)
      }
    })

    if (node) parentObserver.current.observe(node)
  }, [])

  return (
  <>
    <Box>
    {props.parent.children?.slice(0, currentItems).map((item, index) => (
        <CustomerRow
          key={item.recordId}
          index={index}
          item={item}
          filteredParentCustomer={props.filteredParentCustomer}
          setParentCustomers={props.setParentCustomers}
          setFilteredParentCustomers={props.setFilteredParentCustomers}
          isToasterOpen={props.isToasterOpen}
          setIsToasterOpen={props.setIsToasterOpen}
          toasterMessage={props.toasterMessage}
          setToasterMessage={props.setToasterMessage}
          toasterSeverity={props.toasterSeverity}
          setToasterSeverity={props.setToasterSeverity}
          arCustomerList={props.arCustomerList}
          arVendorList={props.arVendorList}
        />
      ))
    }
    {props.parent.vendors?.slice(0, currentItems).map((item, index) => (
        <VendorRow
          key={item.recordId}
          index={index}
          item={item}
          filteredParentCustomer={props.filteredParentCustomer}
          setParentCustomers={props.setParentCustomers}
          setFilteredParentCustomers={props.setFilteredParentCustomers}
          isToasterOpen={props.isToasterOpen}
          setIsToasterOpen={props.setIsToasterOpen}
          toasterMessage={props.toasterMessage}
          setToasterMessage={props.setToasterMessage}
          toasterSeverity={props.toasterSeverity}
          setToasterSeverity={props.setToasterSeverity}
          arCustomerList={props.arCustomerList}
          arVendorList={props.arVendorList}
        />
      ))
    }
    </Box>
    { childrenLength > currentItems ?
      <LinearProgress ref={lastRowParentElementRef} /> : <></>
    }
  </>
  )

}

export default ScrollableSection