import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './styles';
import { AuthContext } from '../../../context/authContext';

const HeaderName: React.FC = () => {
  const { state } = useContext(AuthContext);
  return (
    <Box sx={styles.container}>
      <Typography variant='body1' noWrap sx={styles.name}>
        {state.firstName} {state.lastName}
      </Typography>
      <Typography variant='subtitle2' noWrap sx={styles.username}>
        {state.email}
      </Typography>
    </Box>
  );
};

export default HeaderName;
