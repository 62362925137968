import { Close } from "@mui/icons-material";
import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import styles from './styles';

interface ISelectedChildClientMessageModal {
  isOpen: boolean;
  parentName: string;
  handleNavigateToClientsPage: () => void;
  handleClose: () => void;
  handleButtonClose?: () => void;
  headingTitle?: string;
  firstMessage?: string;
  secondMessage?: string;
}

export const SelectedChildClientMessageModal = (props : ISelectedChildClientMessageModal) => {
  return (
    <Modal open={props.isOpen}>
      <Box sx={styles.modalBackground}>
        <Box sx={styles.headingContainer}>
          <Typography sx={styles.headingTitle}>{props.headingTitle ? props.headingTitle : 'Ineligible Settings'}</Typography>
          <Close fontSize='small' onClick={props.handleButtonClose ? props.handleButtonClose : props.handleNavigateToClientsPage} sx={styles.closeButton} />
        </Box>
        <Divider sx={styles.divider} />
        <Typography sx={styles.firstMessage}>
          {props.firstMessage ? props.firstMessage : 'Ineligible settings are only applicable to the Parent Client level.'}
        </Typography>
        <Typography sx={styles.secondMessage}>
          {props.secondMessage ? props.secondMessage : `Click proceed to switch the selected Client's Parent if you want to view or change the settings.`}
        </Typography>
        <Typography sx={styles.parentNameText}>Name of Parent: <b>{props.parentName}</b></Typography>
        <Divider sx={styles.divider} />
        <Box sx={styles.rightButtonsContainer}>
          <Button variant='outlined' sx={styles.clientsButton} onClick={props.handleNavigateToClientsPage}>
            Go back to Clients Page
          </Button>
          <Button variant='contained' sx={styles.okayButton} onClick={props.handleClose}>
            Proceed
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};