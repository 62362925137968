import { createContext, useMemo, useState } from "react";
import { LayoutProps } from "./authContext";
import { IPaging, IParentChildSetupContext, IParentWithChild } from "../interfaces/parentChildSetupInterface";
import { DraggableListItem } from "../interfaces/draggableList";
import { SEARCH_FILTER } from "../utility/constants";

export const ParentChildSetupContext = createContext<IParentChildSetupContext | null>(null);

const ParentChildSetupProvider = (props: LayoutProps) => {
  const { children } = props;
  const [orphansList, setOrphansList]                               = useState<DraggableListItem[]>([])
  const [newOrphansList, setNewOrphansList]                         = useState<DraggableListItem[]>([])
  const [selectedOrphans, setSelectedOrphans]                       = useState<DraggableListItem[]>([])
  const [parentsWithChildrenList, setParentsWithChildrenList]       = useState<IParentWithChild[]>([])
  const [newParentsWithChildrenList, setNewParentsWithChildrenList] = useState<IParentWithChild[]>([])
  const [removedOrphanIds, setRemovedOrphanIds]                     = useState<number[]>([])
  const [deletedParentIds, setDeletedParentIds]                     = useState<number[]>([])
  const [parentPage, setParentPage]                                 = useState<IPaging>({pageNo: 1, isLoading: false, isLastPage: false, searchKey: '', searchFilter: SEARCH_FILTER.ALL});
  const [orphansPage, setOrphansPage]                               = useState<IPaging>({pageNo: 1, isLoading: false, isLastPage: false, searchKey: ''})
  
  const value = useMemo(() => ({
    orphansList,
    setOrphansList,
    newOrphansList,
    setNewOrphansList,
    selectedOrphans,
    setSelectedOrphans,
    parentsWithChildrenList,
    setParentsWithChildrenList,
    newParentsWithChildrenList,
    setNewParentsWithChildrenList,
    removedOrphanIds,
    setRemovedOrphanIds,
    deletedParentIds,
    setDeletedParentIds,
    parentPage, setParentPage,
    orphansPage, setOrphansPage,
  }), [
    orphansList,
    setOrphansList,
    newOrphansList,
    setNewOrphansList,
    selectedOrphans,
    setSelectedOrphans,
    parentsWithChildrenList,
    setParentsWithChildrenList,
    newParentsWithChildrenList,
    setNewParentsWithChildrenList,
    removedOrphanIds,
    setRemovedOrphanIds,
    deletedParentIds,
    setDeletedParentIds,
    parentPage, setParentPage,
    orphansPage, setOrphansPage,
  ])

  return (
    <ParentChildSetupContext.Provider
      value={value}>
      {children}
    </ParentChildSetupContext.Provider>
  );
};

export default ParentChildSetupProvider;