import { AlertColor, Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import ConfirmModal from '../../components/modals/confirm-modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GeneralBreadcrumbs from '../../components/breadcrumb';
import PageHeader from '../../components/users-and-roles/header';
import UserAvatar from '../../components/users-and-roles/user-profile/user-avatar';
import UserInformation from '../../components/users-and-roles/user-profile/user-information';
import UsersAndRolesProvider, { IUsersAndRolesContext, UsersAndRolesContext } from '../../context/usersAndRolesContext';
import styles from './styles';
import { FC, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../service/axiosInstance';
import api, { rolePermissionAPI } from '../../service/api';
import { DELETE, GET, NO_PERMISSION_MSG, PERMISSIONS } from '../../utility/constants';
import { IRoleAPI, IUserDetailsAPI, IUserRowInfo, IUserAPI } from '../../interfaces/rolesPermissionInterface';
import { checkUserPermissions, getLocalStorageItem, getPermissionsOfUser } from '../../utility/helper';
import Toaster from '../../components/toaster';

const tempData:IUserRowInfo = {
  keycloakId: '',
  recordId:   -1,
  email:      '',
  firstName:  '',
  lastName:   '',
  fullName:   '',
  role:       'Super Admin',
  status:     'Active',
  invitedBy:  ''
}

/**
 * Component for showing the user profile page.
 */
const UserProfilePage: FC = () => {
  const {id}                                      = useParams();
  const navigate                                  = useNavigate();
  const [user, setUser]                           = useState<IUserRowInfo>(tempData);
  const [isEditable, setIsEditable]               = useState<boolean>(false);
  const [invitedById, setInvitedById]             = useState<number>(-1);
  const [modalOpen, setModalOpen]                 = useState<boolean>(false);
  const [modalTitle, setModalTitle]               = useState<string>('');
  const [toasterOpen, setToasterOpen]             = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]       = useState<string>('');
  const [toasterSeverity, setToasterSeverity]     = useState<AlertColor>('success');
  const {canUpdateUser, setCanUpdateUser,
         canDeleteUser, setCanDeleteUser,
         setCanDeactivateUser,
         setCanReactivateUser} = useContext(UsersAndRolesContext) as IUsersAndRolesContext;

  /**
   * This useEffect hook calls the buildUserData function and the getPermissions method.
   */
  useEffect(() => {
    buildUserData();
    getPermissions();
  }, []);

  /**
   * This function retrieves the permissions of the user and sets their state.
   */
  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(getLocalStorageItem('uid'));
    setCanUpdateUser(permissions.includes(PERMISSIONS.UPDATE_USER));
    setCanDeactivateUser(permissions.includes(PERMISSIONS.DEACTIVATE_USER));
    setCanReactivateUser(permissions.includes(PERMISSIONS.REACTIVATE_USER));
    setCanDeleteUser(permissions.includes(PERMISSIONS.DELETE_USER));
  };

  /** 
   * This function calls the API endpoint for retrieving the details of a user by it ID of the user details.
   * @returns The details of the user. See IUserDetailsAPI interface for more information.
   */
  const getDetailsOfUserByRecordId = async (recordId: number) => {
    try {
      const response = await axiosInstance.request({
        url: api.GET_USER_INFO_BY_ID,
        method: GET,
        params: {recordId: recordId}
      })
      const details : IUserDetailsAPI = response.data;
      return details;
    } catch (e) {
      console.log(e)
    }
  }

  /** 
   * This function calls the API endpoint for retrieving a single user by its ID.
   * @returns The user. See IUserAPI interface for more information.
   */
  const getUserById = async (userId: string) => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: api.USER_BY_UID,
        method: GET,
        headers: {
          token : token !== undefined ? token : ''
        },
        params: {userId: userId}
      })
      const users : IUserAPI = response.data;
      return users;
    } catch (e) {
      console.log(e)
    }
  }

  /** 
   * This function calls the API endpoint for retrieving the role of a user by its ID.
   * @returns The role of the user. See IRoleAPI interface for more information.
   */
  const getRoleOfUser = async (userId: string) => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.GET_ROLES_OF_USER,
        method: GET,
        headers: {
          token : token !== undefined ? token : ''
        },
        params: {userId: userId}
      })
      const role : IRoleAPI = response.data[0];
      return role;
    } catch (e) {
      console.log(e)
    }
  }
/**
   * This function creates a user object from the provided data. The resulting object can be readily used by the table component.
   * @param userDetails Additional details about the user.
   * @param user The information of the user.
   * @param role The role of the user.
   * @param invitedByInfo The details of the account that invited the user.
   * @returns A single user object that can be used by the users table component to show data. See IUserRowInfo for more information.
   */
  const createUserObject = (userDetails: IUserDetailsAPI | undefined, user: IUserAPI, role: IRoleAPI | undefined, invitedByInfo: IUserAPI | undefined) => {
    const userData : IUserRowInfo = {
      recordId: userDetails !== undefined ? userDetails.recordId : -1,
      keycloakId: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.firstName + ' ' + user.lastName,
      invitedBy: invitedByInfo !== undefined ? invitedByInfo.firstName + ' ' + invitedByInfo.lastName : '',
      role: role !== undefined ? role.name : '',
      status: userDetails !== undefined ? userDetails.status : ''
    } 
    return userData;
  }

  /**
   * This function retrieves the who invited the selected user.
   * @param invitedBy The details of the user who invited the selected user. See IUserDetailsAPI for more information.
   * @returns The user who invited the selected user. See IUserAPI for more information.
   */
  const getInvitedByInfo = async (invitedBy: IUserDetailsAPI | undefined) => {
    let invitedByInfo : IUserAPI | undefined;
    if (invitedBy !== undefined) {
      invitedByInfo = await getUserById(invitedBy.keycloakId);
    }
    return invitedByInfo;
  }

  /**
   * This function retrieves the details of the user who invited the selected user.
   * @param userDetails The details of the selected user.
   * @returns The details of the user who invited the selected user. See IUserDetailsAPI for more information. 
   */
  const getInvitedBy = async (userDetails: IUserDetailsAPI) => {
    let invitedBy : IUserDetailsAPI | undefined
    if (userDetails?.invitedBy !== null && userDetails?.invitedBy !== undefined) {
      invitedBy = await getDetailsOfUserByRecordId(userDetails?.invitedBy);
    }
    return invitedBy;
  }


  /**
   * This function retrieves and processes the user data to be shown in the users table.
   */
  const buildUserData = async () => {
    if (id !== undefined) {
      const userDetails = await getDetailsOfUserByRecordId(parseInt(id));
      if (userDetails !== undefined) {
        const user = await getUserById(userDetails.keycloakId);
        const role = await getRoleOfUser(userDetails.keycloakId);
        if (user !== undefined) {
          const invitedBy = await getInvitedBy(userDetails);
          const invitedByInfo = await getInvitedByInfo(invitedBy);
          const userData = createUserObject(userDetails, user, role, invitedByInfo);
          setUser(userData);
          if (invitedBy !== undefined) {
            setInvitedById(invitedBy.recordId);
          } else {
            setInvitedById(-1)
          }
        } 
      }
    }
  };

  /**
   * This function calls the API endpoint for deleting a user by its ID.
   * @param userId The ID of the user to be deleted.
   */
  const deleteUserById = async (userId: string) => {
    try {
      const token = getLocalStorageItem('token');
      await axiosInstance.request({
        url: api.DELETE_USER,
        method: DELETE,
        headers: {
          token : token !== undefined ? token : ''
        },
        params: {userId: userId}
      })
    } catch (e) {
      console.log(e)
    }
  };

  /**
   * This function calls the API endpoint for deleting the details of a user by its ID.
   * @param userId The ID of the user to be deleted.
   */
  const deleteDetailsOfUser = async (userId: string) => {
    try {
      await axiosInstance.request({
        url: api.DELETE_USER_INFO,
        method: DELETE,
        params: {userId: userId}
      })
      setToasterMessage("User has been succesfully deleted!")
      setToasterSeverity('success');
      setToasterOpen(true)
    } catch (e) {
      console.log(e)
    }
  };

  /**
   * This function calls the delete user and delete user details functions so that they can both be deleted.
   */
  const deleteUser = async () => {
    await deleteUserById(user.keycloakId);
    await deleteDetailsOfUser(user.keycloakId);
    navigate('/users-and-roles');
  };

  /**
   * This function checks if the user has the permission to delete a user. 
   * If yes, the deleteUser function is called. 
   */
  const handleConfirm = async () => {
    checkPermission(deleteUser, PERMISSIONS.DELETE_USER);
  }

  /**
   * This function is called when the delete icon is clicked. 
   * It shows the modal for confirming the delete action.
   */
  const onDeleteClick = () => {
    setModalTitle(`Delete ${user.fullName}`);
    setModalOpen(true);
  }

  /**
   * This function closes the delete modal.
   */
  const closeModal = () => {
    setModalOpen(false);
  };

  /**
   * This function checks if the user has a specific permission.
   * @param func The function to be called if the user has the required permission.
   * @param permission The permission to be checked.
   */
  const checkPermission = async (func: Function, permission: string) => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), permission)
    if(isPermitted){
      await func()
      return
    }
    setToasterOpen(true);
    setToasterMessage(NO_PERMISSION_MSG);
    setToasterSeverity('error');
  };

  return (
    <Box>
      <PageHeader title={'User Profile'}/>
      <Box>
        <GeneralBreadcrumbs
          selectedText='User Profile'
          breadcrumbLinks={[
            { linkText: 'Roles and Permission', route: '/users-and-roles' },
          ]}
        />
      </Box>

      {
        user.recordId !== -1 &&
        <Box component={Paper} sx={styles.whiteContainer}>
          <Grid container sx={{justifyContent: 'end'}}>
            <Button 
              startIcon= {<EditOutlinedIcon sx={{...styles.actionsIcon, ...(isEditable && styles.disabled)}}/>}
              aria-label='Edit button- Edit User Profile'
              onClick={()=> {setIsEditable(true)}}
              disabled={isEditable}
              sx={{...((user.role === 'Super Admin' || !canUpdateUser) && styles.hidden)}}
            >
            <Typography sx={{...styles.buttonText, ...(isEditable && styles.disabled)}}>
                Edit
            </Typography>
            </Button>
            <Divider variant="middle" sx={{...styles.verticalDivider, ...((user.role === 'Super Admin' || !canDeleteUser || (canDeleteUser && !canUpdateUser)) && styles.hidden)}}/>
            <Button 
              startIcon= {<DeleteOutlineOutlinedIcon sx={styles.actionsIcon}/>}
              aria-label='Delete button- Delete User Profile'
              onClick={()=> {onDeleteClick()}}
              sx={{...((user.role === 'Super Admin' || !canDeleteUser) && styles.hidden)}}
            >
            <Typography sx={styles.buttonText}>
                Delete
            </Typography>
            </Button>
          </Grid>
          
          <Grid container>
            <Grid sx={{width: '30%'}}>
              <UserAvatar
                isEditable={isEditable}
                userStatus={user.status}
              />
            </Grid>
            <Grid sx={{width: '70%'}}>
              <UserInformation 
                setIsEditable={setIsEditable}
                isEditable={isEditable}
                userData={user}
                invitedById={invitedById}
                rebuildData={buildUserData}
              />
            </Grid>
          </Grid>
        </Box>
      }
      <ConfirmModal
        title={modalTitle}
        description={'Are you sure you want to delete this user?'}
        open={modalOpen}
        alignment="left"
        onClose={closeModal}
        onConfirm={handleConfirm}
        errorButton={true}
        noButtonText={'Cancel'}
        yesButtonText={'Delete'}
      />
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setToasterOpen(false)}
      />
    </Box>
  )
}

const UserProfile = () => {
  return (
    <UsersAndRolesProvider>
      <UserProfilePage />
    </UsersAndRolesProvider>
  )
}

export default UserProfile;