import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertColor, Box, Button, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip} from '@mui/material';
import axiosInstance from '../../../../../service/axiosInstance';
import { GET, API_DOMAIN, NON_EXISTING_PERCENT, NON_EXISTING, PUT, POST, DELETE, PERMISSIONS } from '../../../../../utility/constants';
import { IARCollateral, IARBorrowerInput } from '../../../../../interfaces';
import NoDataPlaceholder from '../../../../common/no-data-placeholder';
import styles from './styles';
import { SkeletonRow, SkeletonTableHead } from '../../../../skeleton';
import requests from '../../../../../service/requests';
import Toaster from '../../../../toaster';
import { ClientSettingsPaginationContext, IClientSettingsPaginationContext } from '../../../../../context/clientSettingsPaginationContext';
import AccountsReceivableSettingRow from './row';
import AccountReceivableSettingModal from './modal';
import { checkUserPermissions, getCurrencies, getLocalStorageItem, getPermissionsOfUser } from '../../../../../utility/helper';
import { ClientSettingsContext, IClientSettingsContext } from '../../../../../context/clientSettingsContext';
import { ICurrency } from '../../../../../interfaces/multiCurrencyInterface';
import DisabledComponentsContainer from '../../../../common/disabled-components-container';
import { SelectedClientContext } from '../../../../../context/selectedClientContext';

export interface IAccountsReceivableSettingsTabProps {
  onSettingsChange: (settingsCount: number) => void;
  isLoading       : boolean;
}

export interface IAccountsReceivableSetting {
  arCollateral: IARCollateral;
  arBorrowerInput: IARBorrowerInput;
}

/**
 * This function get all the active AR collateral of the child clients of a parent client.
 * @param parentClientFk The Identifier used to get specific AR Collaterals.
 * @returns A list of active AR collateral or a null value.
 */
export const getActiveChildCollaterals = async (parentClientFk: number) => {
  const activeCollaterals = await axiosInstance.request({
    url: `${API_DOMAIN}/ar/collaterals/search/findChildClients`,
    method: GET,
    params: { parentClientFk: parentClientFk, pageNo: 0, pageSize: 9999, sortBy: 'arCollateralName,ASC', isArchived: false }
  }).then(response => response.data.content as IARCollateral[]);
  const mappedCollaterals = await Promise.all(activeCollaterals.map(async (collateral: IARCollateral) => {
    const response = await axiosInstance.request({
      url: `${API_DOMAIN}/ar/borrowerInputs/search/findByARCollateralId`,
      method: GET,
      params: { arCollateralId: collateral.recordId, pageNo: 0, pageSize: 9999, isCurrent: true }
    });
    if (response.data.content.length === 0){ return null; }
    return collateral;
  }));
  return mappedCollaterals.filter(collateral => collateral !== null) as IARCollateral[];
};


/**
 * This function get all the active AR collateral of a specific borrower.
 * @param borrowerId The Identifier used to get specific AR Collaterals.
 * @returns A list of active AR collateral or a null value.
 */
export const getActiveCollaterals = async (borrowerId: number) => {
  const activeCollaterals = await axiosInstance.request({
    url: `${API_DOMAIN}/ar/collaterals/search/findIsArchived`,
    method: GET,
    params: { borrowerId: borrowerId, pageNo: 0, pageSize: 9999, sortBy: 'arCollateralName,ASC', isDefault: true, isArchived: false }
  }).then(response => response.data.content as IARCollateral[]);
  const mappedCollaterals = await Promise.all(activeCollaterals.map(async (collateral: IARCollateral) => {
    const response = await axiosInstance.request({
      url: `${API_DOMAIN}/ar/borrowerInputs/search/findByARCollateralId`,
      method: GET,
      params: { arCollateralId: collateral.recordId, pageNo: 0, pageSize: 9999, isCurrent: true }
    });
    if (response.data.content.length === 0){ return null; }
    return collateral;
  }));
  return mappedCollaterals.filter(collateral => collateral !== null) as IARCollateral[];
};

/**
 * This function get the default AR Collateral of a specific borrower.
 * @param borrowerId The Identifier used to get specific AR Collaterals.
 * @returns an AR collateral object or a null value.
 */
export const getDefaultCollateral = async (borrowerId: number) => {
  const defaultCollaterals = (
    await axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals/search/findIsDefault`,
      method: GET,
      params: { borrowerId: borrowerId, pageNo: 0, pageSize: 9999, sortBy: 'recordId,ASC', isDefault: true, }
    })
  ).data.content as IARCollateral[]
  return defaultCollaterals[0] ?? null;
};

/**
 * Component for showing the Accounts Recievalble Tab.
 */
const AccountsReceivableSettingsTab: React.FC<IAccountsReceivableSettingsTabProps> = (props) => {
  const TABS: { label: 'Active' | 'Archived'}[]                 = [ { label: 'Active' }, { label: 'Archived' } ];

  const navigate                                                = useNavigate();
  const { borrowerId }                                          = useParams();
  const { selectedClient }                                      = useContext(SelectedClientContext);
  const { page, setPage, setCount, rowsPerPage,}                = useContext(ClientSettingsPaginationContext) as IClientSettingsPaginationContext;
  const { canViewAr, setCanViewAr, setCanChangeDefaultAr,
          canAddAr, setCanAddAr, setCanUpdateAr, 
          setCanArchiveDeleteAr,
          canViewCustomerSettings, setCanViewCustomerSettings, setARCollateralCount, setInventoryCount, setOtherLoansCount } = useContext(ClientSettingsContext) as IClientSettingsContext;
  const [selectedTabIndex, setSelectedTabIndex]                 = useState(TABS.findIndex(tab => tab.label === 'Active'));
  const [arSettings, setARSettings]                             = useState<IAccountsReceivableSetting[]>([]);
  const [isAddARSettingModalOpen, setIsAddARSettingModalOpen]   = useState(false);
  const [isLoading, setIsLoading]                               = useState(true);
  const [toaster, setToaster]                                   = useState<{ isOpen: boolean, message: string, severity: AlertColor }>({ isOpen: false, message: '', severity: 'success' });
  const [isoList, setIsoList]                                   = useState<ICurrency[]>([])
  const [existingARCollaterals, setExistingARCollaterals]       = useState<IARCollateral[]>([]);

  /**
   * This use effect is responsible for getting the AR Collateral datas, the currenct users permission and the currencies.
   */
  useEffect(() => {
    getPermissions();
    getAllCurrencies();
  }, []);

  useEffect(() => {
    getAllExistingCollateralNames();
  }, [borrowerId])

  useEffect(() => {
    if (!hasSelectedBorrower()) { return; }
    getARSettings();
  }, [page, rowsPerPage, borrowerId, selectedTabIndex, selectedClient]);

  /**
   * This use effect reset the page variable to 0 if selectedTabIndex changes its value.
   */
  useEffect(() => {
    setPage(0);
  }, [selectedTabIndex]);

  /**
   *This function fetch all the currency and set the IsoLists value.
   */
  const getAllCurrencies = async () => {
   const res = await getCurrencies();
   res && setIsoList(res.currencies);
  };

  /**
   * This function check if the current user has the following permission to interact with a certain functionality within the Accounts Recievable tab.
   */
  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(getLocalStorageItem('uid'));
    setCanViewAr(permissions.includes(PERMISSIONS.VIEW_AR));
    setCanChangeDefaultAr(permissions.includes(PERMISSIONS.CHANGE_DEFAULT_AR));
    setCanAddAr(permissions.includes(PERMISSIONS.ADD_AR));
    setCanUpdateAr(permissions.includes(PERMISSIONS.UPDATE_AR));
    setCanArchiveDeleteAr(permissions.includes(PERMISSIONS.DELETE_ARCHIVE_AR));
    setCanViewCustomerSettings((permissions.includes(PERMISSIONS.VIEW_CUSTOMER_LIST) || permissions.includes(PERMISSIONS.VIEW_PARENT_LIST)));
  };

  /**
   * This function returns the axios request for retrieving archived AR collaterals of the client
   * @param tab The type of the tab.
   * @returns The axiosRequest for retrieving collaterals.
   */
  const getARCollaterals = (tab: 'Archived' | 'Active') => {
    return tab === 'Archived' ? axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals/search/findIsArchived`,
      method: GET,
      params: { borrowerId: selectedClient?.recordId, pageNo: page, pageSize: rowsPerPage, isArchived: true }
    }) : axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals/search/findActiveAndDefaultCollateralsFirst`,
      method: GET,
      params: { borrowerId: selectedClient?.recordId, pageNo: page, pageSize: rowsPerPage }
    });
  };

  /**
   * This function returns the axios request for retrieving archived AR collaterals of the clients under a parent client
   * @param tab the type of the tab.
   * @returns The axiosRequest for retrieving collaterals.
   */
  const getARCollateralsOfChildClients = () => {
    return axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals/search/findChildClients`,
      method: GET,
      params: { parentClientFk: selectedClient?.recordId, pageNo: page, pageSize: rowsPerPage, isArchived: false }
    });
  }

  /**
   * This function gets all existing AR Collateral Names regardless of active/archive state
   * @param borrowerId The borrowerId where the AR Collaterals are under.
   */
  const getAllExistingCollateralNames = async () => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/ar/collaterals/search/findByBorrowerId`,
        method: GET,
        params: { borrowerId }
      });
      const existingCollaterals: IARCollateral[] = response.data.content;
      setExistingARCollaterals(existingCollaterals);
    } catch (error) {
      console.log('GET ALL EXISTING COLLATERAL NAMES ERROR: ', error);
    }
  };

  /**
   * This function get both archived and active AR Collaterals.
   * @returns a list of account recievable settings.
   */
  const getARSettings = async () => {
    setIsLoading(true);
    const arCollateralRequestPerTab = {
      'Archived': getARCollaterals('Archived'),
      'Active':  selectedClient?.parentClient === true ? getARCollateralsOfChildClients() : getARCollaterals('Active'),
    };
    const arCollateralsRequest = await arCollateralRequestPerTab[TABS[selectedTabIndex].label];
    const arCollaterals = arCollateralsRequest.data.content as IARCollateral[];
    const getARCollateralConfigs: { url: string, method: string}[] = arCollaterals.map((collateral: IARCollateral) => ({
      url: `${API_DOMAIN}/ar/borrowerInputs/search/findByARCollateralId`,
      method: GET,
      params: { arCollateralId: collateral.recordId, pageNo: 0, pageSize: 1, sortBy: 'recordId,DESC', isCurrent: true }
    }));
    const borrowerInputResponses = await requests(getARCollateralConfigs.map((config) => axiosInstance.request(config)));
    const arSettings = borrowerInputResponses.reduce((previousARSettings, currentBorrowerInputResponse, index) => {
      const borrowerInput = currentBorrowerInputResponse.data.content[0] as IARBorrowerInput;
      if (borrowerInput === undefined) { return previousARSettings; }
      const currentARSetting = {
        arCollateral: arCollaterals[index],
        arBorrowerInput: {
          ...borrowerInput,
          rateOfAdvance: borrowerInput.rateOfAdvance * 100,
          ...(borrowerInput.arSubLimit !== undefined
            ? { arSubLimit: borrowerInput.amountLimitType === 'Percentage' ? borrowerInput.arSubLimit * 100 : borrowerInput.arSubLimit }
            : { arSubLimit: NON_EXISTING_PERCENT })
        }
      };
      return [ ...previousARSettings, currentARSetting];
    }, [] as IAccountsReceivableSetting[]);
    setARSettings(arSettings);
    setCount(arCollateralsRequest.data.totalElements);
    TABS[selectedTabIndex].label === 'Active' && props.onSettingsChange(arCollateralsRequest.data.totalElements);
    setIsLoading(false);
  };

  /**
   * This function gets an AR Information from specific page of the AR Collateral list.
   * @param index page no of the AR Collateral list response.
   * @returns an AR collateral settings object or a null.
   */
  const getActiveARSettingByIndex = async (index: number) => {
    const arCollateralsRequest = await axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals/search/findIsArchived`,
      method: GET,
      params: { borrowerId, pageNo: index, pageSize: 1, isArchived: false },
    });
    const arCollaterals = arCollateralsRequest.data.content as IARCollateral[];
    if (arCollaterals.length === 0) { return null; }
    const borrowerInputs = (
      await axiosInstance.request({
        url: `${API_DOMAIN}/ar/borrowerInputs/search/findByARCollateralId`,
        method: GET,
        params: { arCollateralId: arCollaterals[0].recordId, pageNo: 0, pageSize: 1, sortBy: 'recordId,DESC', isCurrent: true }
      })
    ).data.content;
    if (borrowerInputs.length === 0) { return null; }
    const borrowerInput = borrowerInputs[0];
    const arSetting = {
      arCollateral: arCollaterals[0],
      arBorrowerInput: {
        ...borrowerInput,
        rateOfAdvance: borrowerInput.rateOfAdvance * 100,
        ...(borrowerInput.arSubLimit !== undefined
          ? { arSubLimit: borrowerInput.amountLimitType === 'Percentage' ? borrowerInput.arSubLimit * 100 : borrowerInput.arSubLimit }
          : { arSubLimit: NON_EXISTING_PERCENT })
      }
    } as IAccountsReceivableSetting;
    return arSetting;
  };

  /**
   * This function create a new AR Collateral.
   * @param arSetting Object that contains data of the AR Collateral to be created.
   * @returns The newly created AR Collateral.
   */
  const createARSetting = async (arSetting: IAccountsReceivableSetting) => {
    const canAdd = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.ADD_AR);
    if (!canAdd) { setToaster({ isOpen: true, message: 'You do not have the necessary permissions for this action.', severity: 'error'}); return }
    const numericBorrowerId = parseInt(borrowerId as string);
    const { arBorrowerInput } = arSetting;
    setIsLoading(true);
    const defaultCollateral = await getDefaultCollateral(numericBorrowerId);
    const createdCollateral = (
      await axiosInstance.request({
        url: `${API_DOMAIN}/ar/collaterals`,
        method: POST,
        data: {
          arCollateralDTO: {
            borrowerId: numericBorrowerId,
            arCollateralName: arBorrowerInput.arCollateralName,
            default: defaultCollateral === null,
            collateralId: arSetting.arCollateral.collateralId 
          },
          arBorrowerInputDTO: {
            borrower: { recordId: numericBorrowerId },
            current: true,
            arCollateralName: arBorrowerInput.arCollateralName,
            ...(arBorrowerInput.arSubLimit !== NON_EXISTING_PERCENT && {
              arSubLimit: arBorrowerInput.amountLimitType === 'Percentage' ? arBorrowerInput.arSubLimit / 100 : arBorrowerInput.arSubLimit
            }),
            countryName: arBorrowerInput.countryName,
            ...(arBorrowerInput.rateOfAdvance !== NON_EXISTING_PERCENT && {
              rateOfAdvance: arBorrowerInput.rateOfAdvance / 100
            }),
            currency: arBorrowerInput.currency,
            sublimitType: arBorrowerInput.sublimitType,
            amountLimitType: arBorrowerInput.amountLimitType,
            invoiceDateBucket1: arBorrowerInput.invoiceDateBucket1,
            invoiceDateBucket2: arBorrowerInput.invoiceDateBucket2,
            invoiceDateBucket3: arBorrowerInput.invoiceDateBucket3,
            invoiceDateBucket4: arBorrowerInput.invoiceDateBucket4,
            invoiceDateBucket5: arBorrowerInput.invoiceDateBucket5,
            invoiceDateBucket6: arBorrowerInput.invoiceDateBucket6,
            dueDateBucket1: arBorrowerInput.dueDateBucket1,
            dueDateBucket2: arBorrowerInput.dueDateBucket2,
            dueDateBucket3: arBorrowerInput.dueDateBucket3,
            dueDateBucket4: arBorrowerInput.dueDateBucket4,
            dueDateBucket5: arBorrowerInput.dueDateBucket5,
            dueDateBucket6: arBorrowerInput.dueDateBucket6,
          }
        },
      })
    ).data as IARCollateral;
    setToaster({ isOpen: true, message: `${arBorrowerInput.arCollateralName} has been added`, severity: 'success'});
    setSelectedTabIndex(TABS.findIndex(tab => tab.label === 'Active'));
    setIsAddARSettingModalOpen(false);
    getARSettings();
    
    const currentExistingCollaterals = [...existingARCollaterals];
    currentExistingCollaterals.push(createdCollateral);
    setExistingARCollaterals(currentExistingCollaterals);
  };

  /**
   * This function update an exisiting AR Collateral.
   * @param arSetting Object that contains data of the AR Collateral to be updated.
   * @returns The updated AR Collateral.
   */
  const updateARSetting = async (arSetting: IAccountsReceivableSetting) => {
    const canUpdate = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.UPDATE_AR);
    if(!canUpdate) { setToaster({ isOpen: true, message: 'You do not have the necessary permissions for this action.', severity: 'error'}); return; }
    const { arBorrowerInput } = arSetting;
    setIsLoading(true); 
    const updatedCollateral = (await axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals`,
      method: PUT,
      params: {
        arCollateralId: arSetting.arCollateral.recordId,
        arBorrowerInputId: arBorrowerInput.recordId,
      },
      data: {
        arCollateralDTO: {
          recordId: arSetting.arCollateral.recordId,
          borrowerId: arSetting.arCollateral.borrowerId,
          arCollateralName: arBorrowerInput.arCollateralName,
          default: arSetting.arCollateral.default,
          collateralId: arSetting.arCollateral.collateralId 
        },
        arBorrowerInputDTO: {
          recordId: arBorrowerInput.recordId,
          borrower: { recordId: arSetting.arCollateral.borrowerId },
          current: true,
          arCollateralName: arBorrowerInput.arCollateralName,
          ...(arBorrowerInput.arSubLimit !== NON_EXISTING_PERCENT && {
            arSubLimit: arBorrowerInput.amountLimitType === 'Percentage' ? arBorrowerInput.arSubLimit / 100 : arBorrowerInput.arSubLimit
          }),
          countryName: arBorrowerInput.countryName,
          ...(arBorrowerInput.rateOfAdvance !== NON_EXISTING_PERCENT && {
            rateOfAdvance: arBorrowerInput.rateOfAdvance / 100
          }),
          currency: arBorrowerInput.currency,
          sublimitType: arBorrowerInput.sublimitType,
          amountLimitType: arBorrowerInput.amountLimitType,
          invoiceDateBucket1: arBorrowerInput.invoiceDateBucket1,
          invoiceDateBucket2: arBorrowerInput.invoiceDateBucket2,
          invoiceDateBucket3: arBorrowerInput.invoiceDateBucket3,
          invoiceDateBucket4: arBorrowerInput.invoiceDateBucket4,
          invoiceDateBucket5: arBorrowerInput.invoiceDateBucket5,
          invoiceDateBucket6: arBorrowerInput.invoiceDateBucket6,
          dueDateBucket1: arBorrowerInput.dueDateBucket1,
          dueDateBucket2: arBorrowerInput.dueDateBucket2,
          dueDateBucket3: arBorrowerInput.dueDateBucket3,
          dueDateBucket4: arBorrowerInput.dueDateBucket4,
          dueDateBucket5: arBorrowerInput.dueDateBucket5,
          dueDateBucket6: arBorrowerInput.dueDateBucket6,
        }
      },
    })).data as IARCollateral;
    setToaster({ isOpen: true, message: `${arBorrowerInput.arCollateralName} has been edited`, severity: 'success'});
    getARSettings();

    const currentExistingCollaterals = [...existingARCollaterals];
    const mapped = currentExistingCollaterals.map(collateral => {
      if (collateral.recordId === updatedCollateral.recordId) {
        return updatedCollateral;
      }
      return collateral;
    });
    setExistingARCollaterals(mapped);
  };

  /**
   * This function delete an AR Collateral.
   * @param arSetting Object that contains data of the AR Collateral to be deleted.
   * @returns The Id of deleted AR Collateral.
   */
  const deleteARSetting = async (arSetting: IAccountsReceivableSetting) => {
    const canDelete = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.DELETE_ARCHIVE_AR);
    if (!canDelete) { setToaster({ isOpen: true, message: 'You do not have the necessary permissions for this action.', severity: 'error'}); return; }
    setIsLoading(true);
    await axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals`,
      method: DELETE,
      params: {
        arCollateralId: arSetting.arCollateral.recordId,
        arBorrowerInputId: arSetting.arBorrowerInput.recordId,
      }
    });

    const currentExistingCollaterals = [...existingARCollaterals];
    const filtered = currentExistingCollaterals.filter(collateral => collateral.recordId !== arSetting.arCollateral.recordId);
    setExistingARCollaterals(filtered);

    if (!arSetting.arCollateral.default) {
      setToaster({ isOpen: true, message: `${arSetting.arBorrowerInput.arCollateralName} has been deleted`, severity: 'success'});
      getARSettings();
      return;
    }
    const firstARSetting = await getActiveARSettingByIndex(0);
    if (firstARSetting === null) {
      setToaster({ isOpen: true, message: `${arSetting.arBorrowerInput.arCollateralName} has been deleted`, severity: 'success'});
      getARSettings();
      return;
    }
    await changeDefaultARSetting(firstARSetting);
    setToaster({ isOpen: true, message: `${arSetting.arBorrowerInput.arCollateralName} has been deleted`, severity: 'success'});
    getARSettings();
  };

  /**
   * This function update the AR Collateral information and make it archived.
   * @param arSetting Object that contains data of the AR Collateral to be archive.
   */
  const archiveARSetting = async (arSetting: IAccountsReceivableSetting) => {
    const canArchive = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.DELETE_ARCHIVE_AR);
    if (!canArchive) { setToaster({ isOpen: true, message: 'You do not have the necessary permissions for this action.', severity: 'error'}); return; }
    setIsLoading(true);
    await axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals/archive`,
      method: PUT,
      params: {
        arCollateralId: arSetting.arCollateral.recordId,
        arBorrowerInputId: arSetting.arBorrowerInput.recordId,
      }
    });
    if (!arSetting.arCollateral.default) {
      setToaster({ isOpen: true, message: `${arSetting.arBorrowerInput.arCollateralName} has been archived`, severity: 'success'});
      getARSettings();
      return;
    }
    await axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals/${arSetting.arCollateral.recordId}/default`,
      method: PUT,
      params: { isDefault: false }
    });
    const firstARSetting = await getActiveARSettingByIndex(0);
    if (firstARSetting === null) {
      setToaster({ isOpen: true, message: `${arSetting.arBorrowerInput.arCollateralName} has been archived`, severity: 'success'});
      getARSettings();
      getAccountsReceivableCountFromAPI();
      return;
    }
    await changeDefaultARSetting(firstARSetting);
    setToaster({ isOpen: true, message: `${arSetting.arBorrowerInput.arCollateralName} has been archived`, severity: 'success'});
    getARSettings();
    getAccountsReceivableCountFromAPI();
  };

  /**
   * This function update the AR Collateral information and make it unarachived.
   * @param arSetting Object that contains data of the AR Collateral to be unarchived.
   */
  const restoreARSetting = async (arSetting: IAccountsReceivableSetting) => {
    const canRestore = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.DELETE_ARCHIVE_AR);
    if (!canRestore) { setToaster({ isOpen: true, message: 'You do not have the necessary permissions for this action.', severity: 'error'}); return; }
    const numericBorrowerId = parseInt(borrowerId as string);
    setIsLoading(true);
    await axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals/archive`,
      method: PUT,
      params: {
        arCollateralId: arSetting.arCollateral.recordId,
        arBorrowerInputId: arSetting.arBorrowerInput.recordId,
        isArchived: false
      }
    });
    const defaultCollateral = await getDefaultCollateral(numericBorrowerId);
    if (defaultCollateral !== null) {
      setToaster({ isOpen: true, message: `${arSetting.arBorrowerInput.arCollateralName} has been restored`, severity: 'success'});
      getARSettings();
      getAccountsReceivableCountFromAPI();
      return;
    }
    await changeDefaultARSetting(arSetting);
    setToaster({ isOpen: true, message: `${arSetting.arBorrowerInput.arCollateralName} has been restored`, severity: 'success'});
    getARSettings();
    getAccountsReceivableCountFromAPI();
  };


  
  /**
   * This function update an AR Collateral settings to be the default collateral of the current borrower.
   * @param arSetting Object that contains data of the AR Collateral to be updated.
   * @param showToaster Toaster state, decide whether the toaster should show.
   */
  const changeDefaultARSetting = async (arSetting: IAccountsReceivableSetting, showToaster?: boolean) => {
    const canUpdate = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.UPDATE_AR);
    if (!canUpdate) { setToaster({ isOpen: true, message: 'You do not have the necessary permissions for this action.', severity: 'error'}); return; }
    setIsLoading(true);
    const defaultCollaterals = (
      await axiosInstance.request({
        url: `${API_DOMAIN}/ar/collaterals/search/findIsDefault`,
        method: GET,
        params: { borrowerId: borrowerId, pageNo: 0, pageSize: 9999, sortBy: 'recordId,ASC', isDefault: true, }
      })
    ).data.content as IARCollateral[]
    if (defaultCollaterals.length > 0) {
      const configs = defaultCollaterals.map(collateral => ({
        url: `${API_DOMAIN}/ar/collaterals/${collateral.recordId}/default`,
        method: PUT,
        params: { isDefault: false }
      }));
      await requests(configs.map(config => axiosInstance.request(config)));
    }
    await axiosInstance.request({
      url: `${API_DOMAIN}/ar/collaterals/${arSetting.arCollateral.recordId}/default`,
      method: PUT,
      params: { isDefault: true }
    });
    showToaster && setToaster({ isOpen: true, message: `${arSetting.arBorrowerInput.arCollateralName} has been set as default.`, severity: 'success'});
    getARSettings();
  };

  /**
   * This function navigates the user to other pages.
   * @param path Page's path the user will be redirected at.
   */
  const navigateToARPath = async (path: string) => {
    const numericBorrowerId = parseInt(borrowerId as string);
    const defaultCollateral = await getDefaultCollateral(numericBorrowerId);
    if (defaultCollateral !== null) { navigate(`${defaultCollateral.recordId}/${path}`); return; }
    const activeCollaterals = selectedClient?.parentClient === true ? await getActiveChildCollaterals(numericBorrowerId) : await getActiveCollaterals(numericBorrowerId);
    if (activeCollaterals.length === 0) { return; }

    if (selectedClient?.parentClient) {
      navigate(`-1/${path}`);
    } else {
      navigate(`${activeCollaterals[0].recordId}/${path}`);
    }
  };

  /**
   * This function set the props for Archived/Active tab
   * @param label Tab label
   * @param index Tab index
   * @returns a props for Archived/Active tab
   */
  const getTabProps = (label: string, index: number) => {
    return { label: label, key: index, id: `ar-collateral--${index}`, 'aria-controls': `ar-collateral-panel-${index}`, disabled: (isLoading ? true : (selectedClient?.parentClient && label === 'Archived' ? true : false)) };
  };

  /**
   * This function check if there is a selected borrower.
   * @returns boolean
   */
  const hasSelectedBorrower = () => !(borrowerId === undefined || borrowerId === 'undefined');

  /**
   * This function iterate through the given list of headers and generate a props to be render.
   * @returns a list of Props containing header data and styles.
   */
  const getTableHeaders = () => {
    const headers = [
      { text: 'Collateral ID', width: '10%', sx: { ...styles.tableHeadCell } },
      { text: 'Advance Rate', width: '10%', sx: { ...styles.tableHeadCell, ...styles.rightAlignedText } },
      { text: 'Amount Limit', width: '10%', sx: { ...styles.tableHeadCell, ...styles.rightAlignedText } },
      { text: 'Country', width: '16%', sx: styles.tableHeadCell },
      { text: 'Currency', width: '16%', sx: styles.tableHeadCell },
      { text: 'Actions', sx: { ...styles.tableHeadCell, ...styles.centerAlignedText } }
    ];
    if (TABS[selectedTabIndex].label === 'Active' && selectedClient?.parentClient !== true) {
      return ([
        { text: 'Default', width: '10%', sx: styles.centerAlignedText },
        { text: 'Collateral Name', width: '15%', sx: styles.tableHeadCell },
        ...headers
      ]);
    }
    if (selectedClient?.parentClient === true) {
      return ([
        { text: 'Collateral Name', width: '15%', sx: styles.tableHeadCell },
        { text: 'Client Name', width: '10%', sx: styles.tableHeadCell },
        ...headers
      ]);
    }
    return ([
      { text: 'Collateral Name', width: '25%', sx: styles.tableHeadCell },
      ...headers
    ]);
  };

  /**
   * This function render eithier Loading Skeleton or a Table containing AR Collaterals
   * @returns a JSX.Element
   */
  const getTableContent = () => {
    if (isLoading) {
      return (
        <Table sx={styles.table} data-test-id='accounts-receivable-settings-loader'>
          <SkeletonTableHead />
          <TableBody>
            <SkeletonRow />
            <SkeletonRow />
            <SkeletonRow />
          </TableBody>
        </Table>
      );
    };
    if (!canViewAr) { return <NoDataPlaceholder messageText='You do not have the permission to view this page.' />; }
    if (arSettings.length === 0 && TABS[selectedTabIndex].label === 'Active') { return <NoDataPlaceholder messageText='There are no AR Collaterals added yet.' />; }
    if (arSettings.length === 0 && TABS[selectedTabIndex].label === 'Archived') { return <NoDataPlaceholder messageText='There are no archives. Please add an archive to start.' />; }
    else{
      return (
        <Table aria-label='Accounts Receivable Settings Table' sx={styles.table}>
          <TableHead>
            <TableRow sx={styles.tableHeadRow}>
              {
                getTableHeaders().map(tableHeader => {
                  const { text, ...rest } = tableHeader;
                  return <TableCell key={`headerCell-${text}`} tabIndex={0} { ...rest }>{ text }</TableCell>;
                })
              }
            </TableRow>
          </TableHead>
          <TableBody sx={styles.tableBody}>
            {
              arSettings.map(arSetting => (
                <AccountsReceivableSettingRow
                  isoList={isoList}
                  key={arSetting.arBorrowerInput.arCollateralName}
                  arSetting={arSetting}
                  existingArCollateralNames={existingARCollaterals.map(arCollateral => arCollateral.arCollateralName)}
                  onARSettingChangeDefault={(arSetting) => changeDefaultARSetting(arSetting, true)}
                  onARSettingDelete={(arSetting) => deleteARSetting(arSetting)}
                  onARSettingArchive={(arSetting) => archiveARSetting(arSetting)}
                  onARSettingRestore={(arSetting) => restoreARSetting(arSetting)}
                  onARSettingSave={(arSetting) => updateARSetting(arSetting)}
                />
              ))
            }
          </TableBody>
        </Table>
      );
    }
  };

  const getAccountsReceivableCountFromAPI = async () => {
    const nonNullBorrowerId = borrowerId as string;
    const numericBorrowerId = parseInt(nonNullBorrowerId);
    const collaterals = await getActiveCollaterals(numericBorrowerId);
    setARCollateralCount(collaterals.length);
  };

  return (
    <>
      <Toaster
        open={toaster.isOpen}
        message={toaster.message}
        severity={toaster.severity}
        onCloseChange={() => setToaster({  isOpen: false, message: toaster.message, severity: toaster.severity })}
      />
      { !hasSelectedBorrower() && <NoDataPlaceholder /> }
      <Box sx={{ ...((!hasSelectedBorrower()) && styles.hidden) }}>
        <Box sx={styles.topContainer}>
          <Box sx={styles.tabContainer}>
            <Tabs
              sx={styles.tabs}
              value={selectedTabIndex}
              onChange={(_event, value) => { setIsLoading(true); setSelectedTabIndex(value); }}
              aria-label='Accounts Receivable Settings Tabs'
            >
              { TABS.map((tab, index) => <Tab tabIndex={0} {...getTabProps(tab.label, index)} />) }
            </Tabs>
          </Box>
          {selectedClient?.parentClientFk ?
            <Tooltip title={'Customer Settings is not available for child clients'}>
              <span>
                <Button
                  disabled
                  aria-label={isLoading || arSettings.length === 0 ? 'Customer Settings button disabled' : 'Customer Settings'}
                  variant='contained' 
                  sx={{ ...styles.button, ...styles.navigationButtons, ...(!canViewCustomerSettings && styles.hideButton) }}>
                  Customer Settings
                </Button>
              </span>
            </Tooltip>
            : <DisabledComponentsContainer isDisabled={isLoading || arSettings.length === 0}>
            <Button
              disabled={isLoading || arSettings.length === 0}
              aria-label={isLoading || arSettings.length === 0 ? 'Customer Settings button disabled' : 'Customer Settings'}
              onClick={() => navigateToARPath('customers')}
              variant='contained' 
              sx={{ ...styles.button, ...styles.navigationButtons, ...(!canViewCustomerSettings && styles.hideButton) }}>
              Customer Settings
            </Button>
          </DisabledComponentsContainer>
          }
          <DisabledComponentsContainer isDisabled={isLoading || arSettings.length === 0}>
            <Button
              disabled={isLoading || selectedClient?.parentClient === true}
              aria-label={isLoading ? 'Add AR Collateral button disabled' : 'Add AR Collateral'}
              variant='contained'
              sx={{...styles.button, ...(!canAddAr && styles.hideButton)}}
              onClick={() => setIsAddARSettingModalOpen(true)}>
              + Add AR Collateral
            </Button>
          </DisabledComponentsContainer>
        </Box>
        <TableContainer sx={styles.tableContainer}>
          { getTableContent() }
        </TableContainer>
        <AccountReceivableSettingModal
          arSetting={{
            arCollateral: {
              archive: true,
              arCollateralName: '',
              borrower: { recordId: parseInt(borrowerId as string), uuid: '' },
              borrowerId: parseInt(borrowerId as string),
              canDelete: 1,
              default: false,
              recordId: NON_EXISTING,
              collateralId: ''
            },
            arBorrowerInput: {
              amountLimitType: '',
              archive: false,
              arCollateralName: '',
              arSubLimit: NON_EXISTING_PERCENT,
              countryName: '',
              currency: '150',
              current: true,
              rateOfAdvance: NON_EXISTING_PERCENT,
              recordId: NON_EXISTING,
              sublimitType: '',
              invoiceDateBucket1: 0,
              invoiceDateBucket2: 30,
              invoiceDateBucket3: 60,
              invoiceDateBucket4: 90,
              dueDateBucket1: 0,
              dueDateBucket2: 30,
              dueDateBucket3: 60,
              dueDateBucket4: 90,
            }
          }}
          isoList={isoList}
          isModalOpen={isAddARSettingModalOpen}
          isEditable='always'
          existingArCollateralNames={existingARCollaterals.map(collateral => collateral.arCollateralName)}
          onCloseModal={() => setIsAddARSettingModalOpen(false)}
          onARSettingSave={(arSetting) => createARSetting(arSetting)}
        />
      </Box>
    </>
  );
};

export default AccountsReceivableSettingsTab;
