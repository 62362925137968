
import React, { useEffect, useState, useCallback, useRef, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Box, Button, Divider, Modal, Typography, Stepper, Step, StepLabel, StepContent, Autocomplete, TextField, IconButton, AlertColor } from '@mui/material';
import { Close, UploadFileRounded } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import Papa, { ParseResult } from "papaparse";
import * as XLSX from 'xlsx';
import styles from '../styles';
import { FieldList } from '../../../../interfaces/ineligibleSettingInterface';
import colors from '../../../../utility/colors';
import Toaster from '../../../toaster';

interface IUploadModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  setUploadedValues?: (value: string[]) => void;
  fieldList?: FieldList[];
}

const AcceptedFileType = {
  csv: '.csv',
  xls: '.xls',
  xlsx: '.xlsx',
};

/**
 * Component for handling file uploads with drag-and-drop and file input functionality.
 * @param props - The component's props.
 */
const UploadDropzone = (props: {
    fileType: string[],
    setActiveStep: Dispatch<SetStateAction<number>>, 
    setValues: Dispatch<SetStateAction<string[][]>>,
    values: string[][],
    selectedFile: File | null,
    setSelectedFile: Dispatch<SetStateAction<File | null>>,
    fileUploaded: boolean,
    setFileUploaded: Dispatch<SetStateAction<boolean>>
   }) => {

  const { fileType, setActiveStep, setValues,  selectedFile, setSelectedFile, fileUploaded, setFileUploaded } = props;

  const [_sendRequest, setSendRequest]          = useState(false);
  const [toasterOpen, setToasterOpen]           = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]     = useState<string>('');
  const [toasterSeverity, setToasterSeverity]   = useState<AlertColor>('success');

  /**
   * This function handles the logic for processing dropped files.
   * @param acceptedFiles An array of accepted File objects.
   */
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      // Check if the file type is accepted
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      const acceptedFileTypes = Object.values(AcceptedFileType);

      if (!acceptedFileTypes.includes(`.${fileExtension}`)) {
        console.log('Invalid file type');
        setToasterOpen(true);
        setToasterMessage('Invalid file type');
        setToasterSeverity('error');
        return file;
      }

      if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        handleExcelFileFormat(file);
      } else if (fileExtension === 'csv') {
        const reader = new FileReader();
        reader.onload = function () {
          parseCSV(file);
        };
        reader.readAsDataURL(file);
      }
      setSelectedFile(file);
    });
  }, []);

  /**
   * This function handles the file input change event.
   * @param event The change event from the file input element.
   */
  const onChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    if (!target.files?.length) return;
    const file = target.files[0];

    // Check if the file type is accepted
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const acceptedFileTypes = Object.values(AcceptedFileType);
    if (!acceptedFileTypes.includes(`.${fileExtension}`)) {
      console.log('Invalid file type');
      setToasterOpen(true);
      setToasterMessage('Invalid file type');
      setToasterSeverity('error');
      return;
    }

    setSelectedFile(file);

    if (fileExtension === 'xlsx' || fileExtension === 'xls') { handleExcelFileFormat(file); }
    else {  parseCSV(file); }
    setSendRequest(true);
  };

  /**
   * This function handles the processing of an Excel file.
   * @param file The Excel file to be processed.
   */
  const handleExcelFileFormat = (file: File) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, {type: 'array'});

      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];

      const jsonDataUnknown = XLSX.utils.sheet_to_json(worksheet, {header: 1});
      if (!Array.isArray(jsonDataUnknown) || !jsonDataUnknown.every(row => Array.isArray(row))) {
        console.log('Unexpected format from XLSX');
      }

      const jsonData = jsonDataUnknown as unknown[][];
      const stringData = jsonData.map(row => row.map(value => value !== null && value !== undefined ? String(value) : "")); 
      setValues(stringData);
      setActiveStep(1);
      setFileUploaded(true);
    };
    reader.readAsArrayBuffer(file);
  };

  /**
   * This function parses a CSV file and sets the parsed data in the component state.
   * @param file The CSV file to be parsed.
   */
  const parseCSV = (file: File) => {
    Papa.parse(file, {
      header: false,
      delimiter: ",",
      skipEmptyLines: true,
      complete: function (results: ParseResult<string[]>) {
        setValues(results.data);
        setActiveStep(1);
        setFileUploaded(true);
      },
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  /**
   * A ref to the file input element.
   */
  const fileRef = useRef<HTMLInputElement>(null);

  /**
   * This function opens the file dialog when the "Browse File" button is clicked.
   */
  const handleBrowse = useCallback(() => fileRef.current?.click(), [fileRef]);

  /**
   * This function resets the file upload state and clears selected files.
   */
  const handleResetUpload = useCallback(() => {
    setSelectedFile(null);
    setFileUploaded(false);
    setActiveStep(0);
    setValues([[]]);
  }, []);

  /**
   * This function determines if the accepted file types are an array or a single string.
   * @param fileType The accepted file types.
   * @returns A string representation of accepted file types.
   */
  const isFileTypeArray = (fileType: any) =>  Array.isArray(fileType) ? fileType?.join(',') : AcceptedFileType.csv;

  /**
   * Represents the accepted file formats as a string.
   */
  const acceptedFormats = typeof fileType === 'string' ? fileType : isFileTypeArray(fileType);

  return (
    <div {...getRootProps({onClick: event => event.stopPropagation()})} data-testid='dropzone-container'>
      <Box sx={styles.dropFileIconBox}>
        {
          selectedFile || fileUploaded ? (
            <Box sx={styles.dropFileButton}>
              <UploadFileRounded tabIndex={0} />
              <Box 
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
              >
                <Typography tabIndex={0} sx={styles.dropFile}>
                  {selectedFile?.name}
                </Typography>
                <IconButton
                  data-testid='clear-file-button'
                  onClick={handleResetUpload}
                >
                  <Close />
                </IconButton>
              </Box>

            </Box>
          ) : (
            <Box sx={styles.dropFileButton}>
              <input {...getInputProps()} data-testid='upload-list-input' onChange={onChange} type="file" accept={acceptedFormats} ref={fileRef}/>
              {
                isDragActive ?(
                  <>
                    <p>Drop the files here ...</p>
                    <Typography sx={styles.fileExtensions}>
                      Supported files: xls, xlsx, csv
                    </Typography>
                  </>
                ) : (
                  <Box>
                    {
                      <Typography tabIndex={0} sx={styles.dropFile}> Drop files here <br/> - OR - </Typography>
                    }
                    <Button 
                      variant="contained" 
                      style={{ textTransform: 'none' }} 
                      onClick={handleBrowse}
                    >
                      Browse File
                    </Button>
                    <Typography tabIndex={0} sx={styles.fileExtensions}>
                      Supported files: xls, xlsx, csv
                    </Typography>
                  </Box>
              )}
            </Box>
          )
        }
      </Box>
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setToasterOpen(false)}
      />
    </div>
  )
}

/**
 * Component allows users to upload and manage lists of data. It supports importing data from files (CSV) or manually adding lists.
 * @param props The component's props.
 * @returns The UploadListModal component.
 */
const UploadListModal = ({ fieldList, ...props}: IUploadModalProps) => {
  const [activeStep, setActiveStep]         = useState<number>(0);
  const [values, setValues]                 = useState<string[][]>([[]]);
  const [selectedHeader, setSelectedHeader] = useState<string>('');
  const [columnValues, setColumnValues]     = useState<string[]>([]);
  const [addList, setAddList] = useState<boolean>(false);
  const [textFieldForAddList, setTextFieldForAddList] = useState<string>('');
  const [activeButton, setActiveButton] = useState<string>('import');
  const [filteredPastedTexts, setFilteredPastedTexts] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileUploaded, setFileUploaded] = useState(false);

  /**
   * This hook resets selectedHeader when the values change.
   */
  useEffect(() => {
    values[0].length === 0 && setSelectedHeader('');
    values[0].length === 1 && setSelectedHeader(values[0][0]);
  }, [values]);

  /**
   * This hook updates columnValues when selectedHeader changes.
   */
  useEffect(() => {
    getColumnValues(selectedHeader);
  }, [selectedHeader]);

  /**
   * This function handles closing the modal.
   */
  const handleClose = useCallback(() => {
    setValues([[]]);
    setColumnValues([]);
    setSelectedFile(null);
    setFileUploaded(false);
    setSelectedHeader('')
    setActiveStep(0);
    props.handleClose();
    setTextFieldForAddList('');
  }, []);

  /**
   * This function retrieves column values based on the selected header.
   * @param selectedHeader The selected header.
   */
  const getColumnValues = (selectedHeader : string) => {
    const fieldIndex = values[0].findIndex(field => field === selectedHeader);
    const cleanedValue = values.filter((value : string[], index : number) => index !== 0);
    const columnValues = cleanedValue.map((value : string[]) => value[fieldIndex]);
    setColumnValues(columnValues);
  }

  /**
   * This function handles the confirmation of uploaded data.
   */
  const handleConfirm = () => {
    const trimmedValue = textFieldForAddList.trim();
    if (props.setUploadedValues) {
      let valuesToUpload: string[];
      if (selectedHeader) {
          valuesToUpload = columnValues;
      } else if (trimmedValue !== '') {
          valuesToUpload = trimmedValue.split('\n');
      } else {
          valuesToUpload = filteredPastedTexts;
      }
      props.setUploadedValues(valuesToUpload);
    }
    setSelectedHeader('');
    props.handleClose();
    setTextFieldForAddList('');
  };

  const descriptions = fieldList?.map(field => field.description) ?? [];
  const restrictedDescriptions = ["Invoice Date", "Amount", "Due Date", "Phone Number", "Payment Terms (Days)"];

  /**
   * This function handles the conversion of pasted data from the clipboard.
   * Sets the resulting data in the component's state.
   * @param event The clipboard event.
   */
  const handleInputPaste = (event: React.ClipboardEvent) => {
    let pastedData = event.clipboardData.getData('text');
    setTextFieldForAddList(pastedData);
    event.preventDefault();
  };

  /**
   * This function handles the change event for the input field.
   * It checks if the input value matches certain patterns and updates the component's state accordingly.
   * @param event The change event.
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (descriptions.some(description => restrictedDescriptions.includes(description))) {
      const numericPattern = /^[0-9-/, ]*$/;
      if (numericPattern.test(inputValue)) {
        setTextFieldForAddList(inputValue);
      }
    } else if (inputValue.trim() !== '') {
      setTextFieldForAddList(inputValue);
    } else {
      setTextFieldForAddList(inputValue);
    }
  };

  /**
   * This function handles the pasting of text from the clipboard.
   * It reads the clipboard text, processes and filters it, and sets the resulting data in the component's state.
   */
  const handlePasteText = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      const separatedPastedTexts = clipboardText.split('\r\n');

      const allValues = separatedPastedTexts.map(line => line.trim()).flat()
      const filteredPastedTexts = allValues.filter(text => text !== '');

      setFilteredPastedTexts(filteredPastedTexts);
      setTextFieldForAddList(filteredPastedTexts.join('\n'));
    } catch (error) {
      console.error('Failed to read clipboard text:', error);
    }
  };

  /**
   * This function resets the text field used for adding lists.
   * It clears the input field in the component's state.
   */
  const resetButton = () => {
    setTextFieldForAddList('');
  };

  /**
   * This function checks whether the upload button should be disabled based on the current state.
   * If adding a list, it checks if the input field is empty.
   * If selecting a header, it checks if a header is selected.
   * @returns True if the button should be disabled, `false` otherwise.
   */
    const isUploadButtonDisabled = () => {
    if (addList) {
      return textFieldForAddList === '';
    }
    return selectedHeader === '';
  };

  /**
   * This function determines what content to display in the modal based on the current state.
   * It renders either the input field for adding lists or a stepper for selecting a field.
   * @returns The React component to display in the modal.
   */
  const showAddList = () => {
    if (addList) {
      return (
        <>
          <Typography tabIndex={0} sx={styles.instructionLabel}>
            <span style={styles.highlightedText}>Instructions: </span>
            Enter or paste values here
          </Typography>
            <Box>
              <TextField
                id="outlined-multiline-static"
                inputProps={{'data-testid':'uploadlist-textarea'}}
                multiline
                rows={8.6}
                sx={styles.textArea}
                value={textFieldForAddList}
                onChange={handleInputChange}
                onPaste={handleInputPaste}
              />
              <Box sx={styles.buttonContainer}>
                <Button variant='contained' sx={styles.pasteTextButton} onClick={handlePasteText}>Paste Text</Button>
                <Button variant='outlined' sx={styles.resetButton} onClick={resetButton}>Clear</Button>
              </Box>
            </Box>
        </>
      );
    }
    return (
      <Stepper activeStep={activeStep} orientation="vertical" sx={{ m: 2 }}>
        { steps.map((step, index) => (
          <Step key={step.label} expanded={true}>
            <StepLabel tabIndex={0}>
              {step.label}
            </StepLabel>
            <StepContent>
              <Box sx={{ display: 'flex' }}>
                { step.component }
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    );
  };

  /**
   * An array of steps representing the different stages in the modal.
   */
  const steps = [
    {
      label: 'Upload',
      component:
        <Box sx={styles.dropFileContainer}>
          <UploadDropzone
            fileType={['csv']}
            setActiveStep={setActiveStep}
            setValues={setValues}
            values={values}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            fileUploaded={fileUploaded}
            setFileUploaded={setFileUploaded}
          />
        </Box>
    },
    {
      label: 'Select Field',
      component: 
        <Autocomplete 
          blurOnSelect
          disablePortal
          disableClearable
          options={values[0]}
          inputValue={selectedHeader}
          disabled={values[0].length === 0}
          noOptionsText={'No Field Names available'}
          sx={styles.comboBox}
          renderInput={(params) => <TextField {...params} placeholder='Field Name' variant='outlined'/>}
          value={selectedHeader}
          onChange={(_event, value) => {
            setSelectedHeader(value);
          }}
          size={'small'}
          tabIndex={values[0].length === 0 ? 0 : -1}
          componentsProps={{
            popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 }
          }}
        />,
    },
  ];

  return (
    <Modal open={props.isOpen}>
      <Box sx={styles.modalBackground}>
        <Box sx={styles.headingContainer}>
          <Typography tabIndex={0} sx={styles.headingTitle}>Upload List</Typography>
          <Close fontSize='small' onClick={handleClose} sx={styles.closeButton} />
        </Box>
        <Divider sx={styles.divider} />
        <Box sx={styles.optionsContainer}>
          <Box sx={styles.optionsChildContainer}>
            <Typography tabIndex={0} sx={styles.optionLabel}>Options</Typography>
              <Button 
                sx={styles.importButton} 
                onClick={() => {
                  setAddList(false);
                  setActiveButton('import');
                }}
                disableRipple> 
                <Typography sx={activeButton === 'import' ? { color: colors.PRIMARY_LIGHT } : { color: '#000000' }}>Import from file</Typography>
              </Button>
              <Button sx={styles.addButton} 
                onClick={() => {
                  setAddList(true);
                  setActiveButton('add');
                }}
                disableRipple>
                <Typography sx={activeButton === 'add' ? { color: colors.PRIMARY_LIGHT } : { color: '#000000' }}>Add List</Typography>
              </Button>
          </Box>
          <Box sx={styles.uploadTabContainer}>  
            {showAddList()}
          </Box>
        </Box>
        <Divider sx={styles.divider} />
        <Box sx={styles.rightButtonsContainer}>
          <Button 
            variant='outlined' 
            sx={styles.cancelButton} 
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            sx={styles.okayButton}
            onClick={handleConfirm}
            disabled={ isUploadButtonDisabled() }
          >
            Okay
          </Button>
          </Box>
      </Box>
    </Modal>
  );
};

export default UploadListModal;