import { Box, Container, Grid, Tab, Table, TableBody, Tabs, Typography } from "@mui/material";
import { FC, useState } from "react";
import { TabProps } from "../../../../interfaces/clientListInterface";
import { SkeletonRow, SkeletonTableHead } from "../../../skeleton";
import styles from './styles';
import TabPanel from "./tab-panel";
import TableComponent from "./tab-panel-contents";

/**
 * This method generates the props for the tabs in the Clients table.
 * @param index The index of the tab.
 * @returns The props for the tab.
 */
const tabProps = (index: number) => {
  return {
    id: `client-settings-tab-${index}`,
    'aria-controls': `client-settings-tabpanel-${index}`,
  };
};

/**
 * Component for showing the contents of the clients table component in the clients page.
 * @param props The props for the ClientsTable component. See the TabProps interface for more information.
 */
const ClientsTable: FC<TabProps> = (props) => {
  const { setTab, clientName, lineBusiness, isLoading, refreshRow, setRefreshRow, page, rowsPerPage } = props;
  const [value, setValue] = useState(0);

  /**
   * This function is called whenever the user clicks a new tab in order to change the current tab.
   * @param _event The event generated upon clicking the tab
   * @param newValue The value of the tab clicked
   */
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    if (newValue===0) {
      setTab('active')
    } else {
      setTab('archived')
    }
  };

  /**
   * This function is called in order to know if there is a client name or line of business filter.
   * @returns a boolean value which is true whenever there is a client name or line of business filter.
   */
  const hasClientNameOrLineBusinessFilters = () => {
    const hasClientNameFilter = clientName !== 'All' && clientName !== null;
    const hasLineBusinessFilter = lineBusiness !== 'All' && lineBusiness !== null;
    return hasClientNameFilter || hasLineBusinessFilter;
  };

  /**
   * This function renders the tab panel content for the active tab.
   * @returns The table panel content for the active tab
   */
  const getTabPanelContentForActive = () => {
    if(isLoading){
      return(
      <Table sx={styles.tableSize}>
        <SkeletonTableHead />
        <TableBody>
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
        </TableBody>
      </Table>)
    }
    const hasSearchFilters = hasClientNameOrLineBusinessFilters();
    if (hasSearchFilters && props.sortedClientInfo.length === 0) {
      return (
        <Box sx={styles.verbiageContainer}>
          <Typography sx={styles.verbiage}>There are no clients matching the criteria.</Typography>
        </Box>
      );
    }
    if (props.sortedClientInfo.length > 0) {
      return (
        <TableComponent
          type='active'
          isLoading={isLoading}
          clientInfo={props.sortedClientInfo}
          refreshRow={refreshRow}
          setRefreshRow={setRefreshRow}
          totalElements={props.totalActiveElements}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={props.onPageChange}
          onRowsPerPageChange={props.onRowsPerPageChange}
        />
      );
    }
    return (
      <Box sx={styles.verbiageContainer}>
        <Typography sx={styles.verbiage}>There are no clients added yet. Click <b>Add New Client</b> button to add.</Typography>
      </Box>
    );
  };

  /**
   * This function renders the tab panel content for the archived tab.
   * @returns The table panel content for the archived tab
   */
  const getTabPanelContentForArchived = () => {
    if(isLoading){
      return(<Table sx={styles.tableSize}>
        <SkeletonTableHead />
        <TableBody>
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
        </TableBody>
      </Table>)
    }
    const hasSearchFilters = hasClientNameOrLineBusinessFilters();
    if (hasSearchFilters && props.sortedArchivedClientInfo.length === 0) {
      return (
        <Box sx={styles.verbiageContainer}>
          <Typography sx={styles.verbiage}>There are no clients matching the criteria.</Typography>
        </Box>
      );
    }
    if (props.sortedArchivedClientInfo.length > 0) {
      return (
        <TableComponent
          type='archived'
          isLoading={isLoading}
          clientInfo={props.sortedArchivedClientInfo}
          refreshRow={refreshRow}
          setRefreshRow={setRefreshRow}
          totalElements={props.totalArchiveElements}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={props.onPageChange}
          onRowsPerPageChange={props.onRowsPerPageChange}

        />
      );
    }
    return (
      <Box sx={styles.verbiageContainer}>
        <Typography sx={styles.verbiage}>There are no archived clients.</Typography>
      </Box>
    );
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container alignItems="center" pt={1} pb={2}>
          <Box sx={styles.tabBox}>
            <Box>
              <Tabs
                sx={styles.buttonTabs}
                value={value}
                onChange={handleChange}
                
              >
                <Tab
                  aria-label= 'Active Tab'
                  label={`Active`}
                  data-testid='active-tab'
                  {...tabProps(0)}
                />
                <Tab
                  aria-label= 'Archived Tab'
                  tabIndex={0}
                  label={`Archived`}
                  data-testid='archived-tab'
                  {...tabProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              { getTabPanelContentForActive() }
            </TabPanel>
            <TabPanel value={value} index={1}>
              { getTabPanelContentForArchived() }
            </TabPanel>
          </Box>
        </Grid>
    </Container>
  );
};

export default ClientsTable;