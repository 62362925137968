import colors from "../../../utility/colors";

const styles = {
  outerBox: {
    zIndex: '1',
    position: 'relative',
  },
  hidden: {
    display: 'none',
  },
  overrideHeader: {
    paddingBottom: '10px'
  },
  overrideHeaderPaper: {
    paddingBottom: '25px'
  },
  overrideHeaderPaperForFirstIdx: {
    paddingTop: '0px'
  },
  overrideHeaderTextsContainer: {
    backgroundColor: '#F5F5F5',
    justifyContent: 'space-between',
    paddingY: '10px',
  },
  overrideCriteriaTitleGrid: {
    padding: '0 10px 0 20px',
  },
  overrideSettingsTitleGrid: {
    padding: '0 20px 0 10px',
  },
  overrideConditionGroupGrid: {
    flexWrap: 'nowrap',
    alignItems: 'end'
  },
  overrideConditionGroupBox: {
    padding: '10px 10px 10px 20px',
  },
  overrideConditionBox: {
    padding: '13px 5px',
    backgroundColor: '#D0DBE8',
    borderRadius: '4px'
  },
  overrideAddNewGroupGrid: {
    justifyContent: 'center'
  },
  overrideAddNewGroupButton: {
    backgroundColor: '#010953',
    position: 'absolute',
    display: 'relative',
    overflow: 'visible',
    marginTop: '-12px'
  },
  overrideConditionOperator: {
    position: 'absolute',
    overflow: 'visible',
    marginTop: '-12px'
  },
  overrideSpecialSettingsGrid: {
    padding: '5px 0 10px 10px',
    borderRadius: '4px',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overrideSpecialSettingsGridContainer: {
    height: '100%',
  },
  removeOverrideGroupIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '5px',
    marginBottom: '5px',
  },
  removeOverrideGroupIconButton: {
    padding: '4px',
    height: 'min-content',
    '& svg': {
      fontSize: '20px',
      color: '#154A8A',
      stroke: '#154A8A',
      strokeWidth: 1,
    }
  },
  overrideSpecialSettingsInnerGrid: {
    height: 'min-content',
    alignSelf: 'flex-end',
    marginRight: '20px',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  customerListViewGrid: {
    position: 'absolute',
    display: 'relative',
    overflow: 'visible',
    marginBottom: '-20px',
    alignSelf: 'flex-end'
  },
  customerListViewGridForOtherSettings: {
    position: 'absolute',
    display: 'relative',
    overflow: 'visible',
    marginBottom: '-20px',
    alignSelf: 'flex-start'
  },
  autocompleteField: {
    backgroundColor: 'white',
    fontSize: '14px',
    borderRadius: '5px',
    '&&&& .MuiAutocomplete-input': {
      padding: '1px 0px 6px 0px',
    },
    '&&&& .MuiAutocomplete-input::placeholder': {
      color: '#949494',
      opacity: '100%'
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      padding: '5px 9px 0px 9px'
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center',
      position: 'absolute',
      top: '-10px',
      left: '-4px',
    },
    '& .MuiInputLabel-shrink': {
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center',
      position: 'absolute',
      top: '2px',
      left: '-4px',
      color: '#000000',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#949494',
    },
    '&&&& .MuiAutocomplete-endAdornment': {
      right: '3px'
    },
    '& .MuiFormHelperText-root':{
      position: 'absolute',
      top: '30px'
    }
  },
  datePicker: {
    'input[type="date"]::-webkit-datetime-edit-text,input[type="date"]::-webkit-datetime-edit-month-field,input[type="date"]::-webkit-datetime-edit-day-field,input[type="date"]::-webkit-datetime-edit-year-field': {
      color: '#888'
    },
    'input[type="date"].MuiInputBase-inputAdornedEnd::-webkit-datetime-edit-text,input[type="date"].MuiInputBase-inputAdornedEnd::-webkit-datetime-edit-month-field,input[type="date"].MuiInputBase-inputAdornedEnd::-webkit-datetime-edit-day-field,input[type="date"].MuiInputBase-inputAdornedEnd::-webkit-datetime-edit-year-field': {
      color: 'black'
    },
  },
  autocompleteValuesField: {
    fontSize: '14px',
    borderRadius: '5px',
    paddingBottom: '3px',
    '&&&& .MuiOutlinedInput-notchedOutline': {
      '&:hover': {
        border: '1px solid black',
      },
    },
    '&&&& .MuiAutocomplete-root': {
      paddingRight: '0px',
    },
    '&&&& .MuiOutlinedInput-root':{
      minHeight: '24px',
      maxHeight: '250px',
      paddingLeft: '5px',
      paddingRight: 0,
      display: 'flex',
      alignItems: 'baseline',
      '&:focus-within': {
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.5)',
      },
    },
    '&&&& .MuiAutocomplete-input': {
      padding: '1px 0 7px 12px',
    },
    '&&&& .MuiAutocomplete-input::placeholder': {
      color: '#949494',
      opacity: '100%',
    },
    '& .MuiAutocomplete-tag': {
      maxWidth: '158px',
      marginY: 0,
      marginLeft: '3px',
      marginRight: 0,
    },
    '& .Mui-focused .MuiAutocomplete-tag': {
      maxWidth: '200px',
      marginY: '3px',
      marginLeft: '3px',
      marginRight: 0,
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      fontSize: '14px',
      padding: '5px 9px 0px 9px',
      overflowY: 'auto',
      overflowX: 'visible',
      position: 'absolute',
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      zIndex: '4',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center',
      position: 'absolute',
      top: '-10px',
      left: '-4px',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      zIndex: '5',
    },
    '& .MuiInputLabel-shrink': {
      fontSize: '14px',
      display: 'flex',
      alignSelf: 'center',
      position: 'absolute',
      top: '3px',
      left: '-4px',
    },
    '&&&& .MuiAutocomplete-endAdornment': {
      display: 'none',
      fontSize: '14px',
    },
    '& .MuiFormHelperText-root':{
      position: 'absolute',
      top: '30px',
    },
  },
  upDownButton: {
    'input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    'input[type=number]': {
      MozAppearance: 'textfield',
    }
  },
  addConditionGrid: {
    alignItems: 'center',
    flexWrap: 'nowrap'
  },
  addConditionButton: {
    width: '67px',
    backgroundColor: '#010953',
  },
  removeConditionIconButton: {
    padding: '4px',
    marginLeft: '4px',
    marginBottom: '4px',
    height: 'min-content',
    '& svg': {
      fontSize: '23px',
      color: '#0075AD',
      stroke: '#0075AD',
      strokeWidth: 0,
    },
    '& svg:hover': {
      color: '#0091D6',
      stroke: '#0091D6',
    }
  },
  removeConditionIcon: {
    // color: '#154a8a',
  },
  customSwitch: {
    padding: '2px 0px',
    height: 34,
    width: 67,
    '& .Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: '#010953',
        opacity: '100',
        '&:before':{
          color: 'white',
          content: '"AND"'
        },
        '&:after':{
          content: '""'
        }
      },
      '&:hover + .MuiSwitch-track': {
        backgroundColor: '#0075ad',
        border: '1px solid #0075ad',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: 'white',
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#010953',
      mt: '2.5px',
      height: 12,
      width: 12,
    },
    '& .MuiSwitch-track': {
      backgroundColor: "#fff",
      border: '1px solid #010953',
      borderRadius: '4px',
      opacity: '100%',
      '&:before, &:after': {
        fontSize: '14px',
        position: 'absolute',
        top: '52%',
        transform: 'translateY(-50%)',
      },
      '&:before': {
        content: "''",
        left: 12,
      },
      '&:after': {
        content: "'OR'",
        color: '#010953',
        right: 12,
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      '& + .MuiSwitch-track': {
        opacity: 1
      },
      transform: 'translateX(40px)',
    },
    '& .Mui-disabled': {
      '& + .MuiSwitch-track':{
        backgroundColor: '#b4c1d1',
        border: '1px solid #7d93ad',
        opacity: 1
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#697E8D',
        mt: '2.5px',
        height: 12,
        width: 12,
      },
    },
  },
  saveButton: {
    height: '32px',
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },
  cancelButton: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  textField: {
    backgroundColor: 'white',
    borderRadius: '4px',
    maxWidth: '140px',
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      '&::-webkit-inner-spin-button': {
        display: 'none',
      },
      '&::-webkit-outer-spin-button': {
        display: 'none',
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      '&::after':{
        content:'" *"',
        color: '#AE2633',
      },
    },
    '& .MuiInputAdornment-root': {
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
  },
  numberAlignment: {
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      textAlign: 'right',
    }
  },
  textFieldInputLabel: {
    color: '#000000',
    opacity: '100%'
  },
  addOverrideButtonContainer: {
    zIndex: '1',
    width: '100%',
    marginBottom: '0.5rem',
    position: 'relative'
  },
  addOverrideButtonDivider: {
    zIndex: '2',
    width: '100%',
    bottom: '50%',
    backgroundColor: '#010953',
    borderBottomWidth: 0.1,
  },
  addOverrideButton: {
    zIndex: '3',
    backgroundColor: '#D0DBE8'
  },
  paperContainer: {
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 1,
    width: '100%',
    '& .MuiAutocomplete-listbox': {
      maxHeight: 'none',
    }
  },
  uploadListButton: {
    width: '100%',
    justifyContent: 'flex-start',
    paddingLeft: '10px',
    paddingY: '5px',
    backgroundColor: '#010953'
  },
  uploadListButtonText: {
    paddingX: '5px'
  },
  conditionRowGrid: {
    justifyContent: 'space-evenly',
    marginY: '5px'
  },
  conditionFieldListBox: {
    maxHeight: 200,
  },
  overrideField: {
    fontSize: '14px'
  },
  specialSettingsContainer: {
    display: 'flex',
    gap: '1rem',
    padding: '16px',
    backgroundColor: '#D0DBE8',
    borderRadius: '4px',
  },
  otherSettingsContainer: {
    padding: '16px',
    backgroundColor: '#D0DBE8',
    borderRadius: '4px',
  },
  exactValueSuggestionContainer: {
    overflowWrap: 'anywhere'
  },
  accordion: {
    overflowY: 'hidden',
    borderTop: '1px solid #dddddd',
    borderBottom: '1px solid #dddddd',
    borderRadius: 0,
    '&::before': {
      display: 'none', /* removes unnecessary border */
    }
  },
  accordionSummary: {
    fontSize: '14px',
    '&.Mui-expanded': {
      borderBottom: '1px solid #dddddd',
    }
  },
  accordionDetails: {
    paddingX: '0px',
    maxHeight: '200px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#B7B7B7',
    }
  },
  option:{ 
    bgColor: colors.WHITE, 
    '&&&& :hover': {
      bgColor: "#EBEBEB",
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectedOption: {
    bgcolor: '#EDF1F6'
  },
  skeletonLoaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor : '#FEFEFE99',
    padding: '1rem',
    borderRadius: '4px',
    marginBottom: '1rem',
  },
  skeletonLoader: {
    marginBottom: '5px',
    backgroundColor : '#D0DBE8',
    height: '40px',
    borderRadius: '4px',
    width: '75%',
  },
  shortSkeletonLoader: {
    marginBottom: '5px',
    backgroundColor : '#D0DBE8',
    height: '40px',
    borderRadius: '4px',
    width: '23%',
  },
  textForAutoComplete:{
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  autoCompleteChipTag:{
    textOverflow: 'ellipsis',
    overflow:'hidden',
    whiteSpace: 'nowrap',
    backgroundColor: '#D0DBE8',
    border: 'none',
  },
  labelChipContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  parentChipContainer: {
    width: '100px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  parentIdentifierChip: {
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"P"',
    },
    '& .MuiChip-label:hover:after': {
      content: '"Parent"',
    }
  },
  loadingIconForFieldName: {
    marginBottom: '5px'
  },
  loadingIconForFieldValues: {
    marginTop: '4px',
    position: 'absolute',
    right: '10px',
  },
}

export default styles;