import colors from "../../../../../../../utility/colors";

const styles = {
  actionIconContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  collapseIcon: {
    color: '#154A8A'
  },
  custNameContainer: {
    width: '16rem'
  },
  custNameWithParent: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  tableRowSpacer: {
    paddingTop: '1rem'
  },
  tableActionBody: {
    textAlign: 'center',
    color:'inherit',
    paddingRight: 0,
    paddingLeft: '1rem',
    border: 0,
    minWidth: '1.5rem',
    maxWidth: '1.5rem',
  },
  tableTextBody: {
    border: 0,
    textAlign: 'right',
    '&:last-of-type': {
      paddingRight: '1.5rem'
    },
    color:'inherit'
  },
  tableTextBodyLeft: {
    textAlign: 'left',
    minWidth: '15rem',
    maxWidth: '15rem',
  },
  tableTextBodyNumber: {
    paddingRight: '1.95rem',
    minWidth: '5rem',
    maxWidth: '5rem',
  },
  tableTextCustSrcId: {
    textAlign: 'left',
    minWidth: '10rem',
    maxWidth: '10rem',
  },
  tableTextCustomer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  },
  detailsTableHeader: {
    border: 0,
    paddingTop: '0.5rem',
    paddingBottom: 0
  },
  detailsTableText: {
    border: 0,
    paddingY: 0,
    fontSize: '14px'
  },
  detailsTableTextRight: {
    textAlign: 'right',
    paddingRight: '1.95rem'
  },
  detailDatesContainer: {
    display: 'flex',
    width: '100%',
  },
  detailsDate: {
    padding: 0,
    width: '25%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer'
  },
  detailsDueDays: {
    marginLeft: '1rem'
  },
  parentIdentifierChip: {
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px'
  },
};
export default styles;