import { Autocomplete, AutocompleteRenderInputParams, Box, Button, CircularProgress, Divider, IconButton, Modal, Paper, TextField, Typography } from "@mui/material";
import { FC, HTMLAttributes, useContext, useEffect, useState } from "react";
import { Close } from '@mui/icons-material';
import styles from "./styles";
import { FieldArray, Form, Formik } from "formik";
import axiosInstance from "../../../../../../service/axiosInstance";
import { DOCTYPE, PROMPT, PUT } from "../../../../../../utility/constants";
import ConfirmModal from "../../../../../../components/modals/confirm-modal";
import { fileImportAPI } from "../../../../../../service/api";
import { IDocument, IUploadedFile } from "../../../../../../interfaces/fileimport";
import { IDataMapTabProps } from "..";
import { FileImportContext, IFileImportContext } from "../../../../../../context/fileImportContext";
import { getTooltip } from "../../../../../../utility/helper";

export interface ISheetSelectionModal extends IDataMapTabProps {
  open                        : boolean;
  onClose                     : () => void;
  excelDocsWithUnselectedSheet: IUploadedFile[];
}

/**
 * This component wraps its children with a Paper component and applies custom styling for use in dropdown lists.
 * @param props The HTML attributes and event handlers for the PaperComponent.
 * @returns The PaperComponent with custom styling.
 */
const PaperComponent = (props: HTMLAttributes<HTMLElement>) => (<Paper sx={styles.dropdownList}>{props.children}</Paper>);

/**
 * Component of the Sheet Selection Modal.
 * @param props The props for the Sheet Selection Modal.
 * @returns A component of the Sheet Selection Modal.
 */
const SheetSelectionModal: FC<ISheetSelectionModal> = (props) => {
  const { open, onClose,
          excelDocsWithUnselectedSheet,
          showToaster }                     = props;
  const { uploadedFiles, setUploadedFiles } = useContext(FileImportContext) as IFileImportContext;
  const [documents, setDocuments]           = useState<IUploadedFile[]>([]);
  const [showPrompt, setShowPrompt]         = useState<boolean>(false);

  /**
   * This useEffect updates the documents state with mapped documents from excelDocsWithUnselectedSheet,
   * where each document includes a selectedSheet property with the value of the first sheet name from sheetNames if it exists.
   */
  useEffect(() => {
    const mappedDefaultSheet = excelDocsWithUnselectedSheet.map(document => {
      const firstSheet = document.sheetNames ? document.sheetNames[0] : undefined;
      return { ...document, selectedSheet: firstSheet }
    })
    setDocuments(mappedDefaultSheet);
  } , [excelDocsWithUnselectedSheet])

  /**
   * This function sends a request to save selected sheets for uploaded documents or cancels the operation
   * based on the specified action.
   * @param values An object containing the array of uploaded documents.
   * @param action The action to perform ('submit' to save selected sheets, 'cancel' to cancel the operation).
   */
  const handleSubmit = async (values: {documents: IUploadedFile[]}, action: 'submit' | 'cancel') => {
    try {
      onClose();
      
      const payload: { [key: number]: string } = getSelectedSheets(values, action);
      
      const response = await axiosInstance.request({
        url: fileImportAPI.document.SELECT_SHEETS,
        method: PUT,
        data: payload
      });
      const selected: IDocument[] = response.data;
      const updated: IUploadedFile[] = uploadedFiles.map(uploaded => {
        const select = selected.find(document => document.recordId === uploaded.recordId);
        if (select) {
          const isUserDefinedDocType = uploaded.documentType && !Object.values(DOCTYPE).includes(uploaded.documentType);
          
          uploaded.selectedSheet = select.selectedSheet;
          uploaded.sheetNames = select.sheetNames;
          uploaded.updatedAt = select.updatedAt;

          if (isUserDefinedDocType) {
            uploaded.mapped = true;
            uploaded.dataMappingStatus = 'Completed';
          }
        }
        return uploaded;
      });
      setUploadedFiles(updated);
      showToaster('success', 'Sheet has been selected.');
    } catch (error) {
      console.log('SHEET SELECTION ERROR: ', error);
      showToaster('error', 'Error in saving selected sheets!');
    }
  };

  /**
   * This function retrieves the selected sheets for submission or the default sheets for cancellation
   * based on the specified action and array of uploaded documents.
   * @param values An object containing the array of uploaded documents.
   * @param action The action to perform ('submit' to get selected sheets, 'cancel' to get default sheets).
   * @returns An object mapping document record IDs to selected or default sheet names.
   */
  const getSelectedSheets = (values: {documents: IUploadedFile[]}, action: 'submit' | 'cancel') => {
    const docs = values['documents'];
    const payload: { [key: number]: string } = {};

    if (action === 'submit') {
      docs.forEach(doc => { if (doc.selectedSheet && doc.recordId) payload[doc.recordId] = doc.selectedSheet });
    } else {
      docs.forEach(doc => {
        const firstSheet = doc.sheetNames ? doc.sheetNames[0] : undefined;
        if (firstSheet && doc.recordId) payload[doc.recordId] = firstSheet;
      });
    }
    
    return payload;
  }

  return (
    <Modal open={open} onClose={() => setShowPrompt(true)}>
      <Box sx={styles.modalContainer}>
        <Formik
          enableReinitialize
          initialValues={{documents}}
          onSubmit={async (values: {documents: IUploadedFile[]}) => await handleSubmit(values, 'submit')}
        >
          {formik => (
            <Form>
              <Box sx={styles.headingContainer}>
                <Typography tabIndex={0} sx={styles.headingTitle}>{'Excel File'}</Typography>
                <IconButton aria-label="Close icon" onClick={() => setShowPrompt(true)}>
                  <Close sx={styles.closeIcon}/>
                </IconButton>
              </Box>

              <Divider sx={styles.divider} />

              <Box sx={styles.bodyContainer}>
                <Box sx={styles.sheetSelectionsContainer}>
                  <FieldArray name="selectedSheets">
                    {({form}) => (
                      <>
                        {form.values.documents.map((document: IUploadedFile, index: number) => {
                          const id = `id-${document.filename.toLowerCase().replace(' ', '-')}`;

                          return (
                            <Box sx={styles.mainContainer} key={document.recordId}>
                              <Box sx={styles.fileLabelContainer}>
                                <Typography tabIndex={0} component='label' htmlFor={id} sx={styles.fileLabel} onMouseEnter={getTooltip}>
                                  {'File Name:'}
                                </Typography>
                                <Typography tabIndex={0} component='label' sx={styles.fileName} onMouseEnter={getTooltip}>
                                  {document.filename}
                                </Typography>
                              </Box>
                              <Box sx={styles.fileLabelContainer}>
                                <Typography tabIndex={0} component='label' htmlFor={id} sx={styles.fileLabel} onMouseEnter={getTooltip}>
                                  {'Document Type:'}
                                </Typography>
                                <Typography tabIndex={0} component='label' sx={styles.fileName} onMouseEnter={getTooltip}>
                                  {document.documentType}
                                </Typography>
                              </Box>
                              {Boolean(document.arCollateralFk) &&
                              <Box sx={styles.fileLabelContainer}>
                                <Typography tabIndex={0} component='label' htmlFor={id} sx={styles.fileLabel} onMouseEnter={getTooltip}>
                                  {'Collateral Name:'}
                                </Typography>
                                <Typography tabIndex={0} component='label' sx={styles.fileName} onMouseEnter={getTooltip}>
                                  {document.arCollateralName}
                                </Typography>
                              </Box>}
                              <Box sx={styles.dropdownContainer}>
                                <Autocomplete
                                  id={id}
                                  disablePortal
                                  options={document.sheetNames ?? []}
                                  onChange={(_, value) => {
                                    if (value) form.setFieldValue(`documents.${index}.selectedSheet`, value)
                                    // necessary for payload optionals & isDirty
                                    else form.setFieldValue(`documents.${index}.selectedSheet`, undefined);
                                  }}
                                  value={document.selectedSheet}
                                  renderInput={(params: AutocompleteRenderInputParams) => (
                                    <TextField
                                      {...params}
                                      placeholder='Select Sheet Name'
                                      name={`documents.${index}`}
                                      value={document.selectedSheet}
                                    />
                                  )}
                                  PaperComponent={PaperComponent}
                                  size='small'
                                  aria-label={`Sheet Selection Dropdown for ${document.filename}`}
                                  componentsProps={{
                                    popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
                                    clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
                                  }}
                                  sx={styles.dropdown}
                                />
                              </Box>
                            </Box>
                          )
                        })}
                      </>
                    )}
                  </FieldArray>
                </Box>
              </Box>

              <Divider sx={styles.divider} />

              <Box sx={styles.buttonsContainer}>
                <Button
                  variant='outlined'
                  onClick={() => setShowPrompt(true)}
                  sx={styles.buttonCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  type='submit'
                  disabled={formik.values.documents.some(document => !document.selectedSheet) || formik.isSubmitting}
                  sx={styles.buttonSave}
                >
                  {formik.isSubmitting ? <CircularProgress size={15} /> : 'Save'}
                </Button>
              </Box>
              <ConfirmModal
                open={showPrompt}
                onClose={() => {
                  setShowPrompt(false);
                  handleSubmit(formik.values, 'cancel');
                }}
                onConfirm={() => setShowPrompt(false)}
                onButtonClose={() => setShowPrompt(false)}
                promptChecker
                title={PROMPT.NAV_PROMPT.title}
                description={`${PROMPT.NAV_PROMPT.description} \n\r (First sheet of the files will be the default selected)`}
                yesButtonText={PROMPT.NAV_PROMPT.keepEditing}
                noButtonText={PROMPT.NAV_PROMPT.discardChanges}
                confirmOnly
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
export default SheetSelectionModal;