import yup from './index';

const inventoryIneligiblesSchema = yup.object().shape({
  
  inventoryIneligibles: yup
    .array()
    .of(yup.object().shape({
      description: yup.string()
        .required('Description is required')
        .trim(),
      amount: yup.number()
        .required('Ineligible Amount is required')
        .typeError('Ineligible Amount must be a number'),
    }))
    .uniquePropertyDisregardCase('description', 'Description must be unique')
});
export default inventoryIneligiblesSchema;