import colors from "../../../../../../utility/colors";

const styles = {
  column30: {
    minWidth: '13rem',
    maxWidth: '13rem',
  },
  column20: {
    minWidth: '10rem',
    maxWidth: '10rem',
  },
  column10: {
    minWidth: '5em',
    maxWidth: '5rem',
  },
  iconColor: {
    color: '#154A8A'
  },
  paddingRight: {
    paddingRight: '5px'
  },
  disabledIcon: {
    color: colors.DISABLED
  },
  cursor: {
    cursor: 'pointer',
  },
  rightAlign: {
    textAlign: 'left'
  },
  hidden: {
    display: 'none'
  },
  parentIdentifierChip: {
    marginLeft: '15px',
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"Parent"',
    },
  },
}

export default styles;