import { DragIndicator, IndeterminateCheckBoxOutlined, } from '@mui/icons-material';
import { Box, Checkbox, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, FormControl, Typography } from '@mui/material';
import { useState, DragEvent, useEffect, useRef } from 'react'
import { DraggableListItem } from '../../interfaces/draggableList';
import styles from './styles';

interface DraggableListProps {
  list: DraggableListItem[]
  multiple?: boolean
  name?: string
  tempId?: number,
  inParent?: boolean,
  inEditViewCustomer?: boolean,
  onItemDragOver: (list: DraggableListItem[]) => void
  onItemChecked?: (list: DraggableListItem[]) => void
  onItemDrop?:(list: DraggableListItem[], from: string, to: string, tempId?: number) => void
  onItemRemove?:(list: DraggableListItem[], from: string, to: string) => void
  onItemDragStart?: (items: string[]) => void 
}

const DraggableList = (props: DraggableListProps) => {

  const { list, multiple, tempId, name, inParent, inEditViewCustomer, onItemDragOver, onItemChecked, onItemDrop, onItemRemove, onItemDragStart } = props;

  const [draggedItem, setDraggedItem] = useState<DraggableListItem | null>(null);
  const [draggedItems, setDraggedItems] = useState<DraggableListItem[]>([]);
  const [isDragging, setIsDragging]   = useState<boolean>(false);
  const [lastDraggedValue,setLastDraggedValue] = useState<boolean>(false);
  const ghostRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (name) {
      list.forEach(item => {
        item.listName = name;
        return item;
      });
    }
  }, [])

  const onDragStart = (event: DragEvent<HTMLButtonElement>, index: number) => {
    if (!event.currentTarget.parentElement || !event.currentTarget.parentNode?.parentElement) return
    if (multiple) {
      const selectedList = list.filter(item => item.checked);
      const finalList = selectedList.length
                          ? selectedList
                          : [list[index]]
      setDraggedItems([...finalList]);
      event.dataTransfer.setData("text/plain", JSON.stringify(finalList));

      if(ghostRef.current !== null){
        event.dataTransfer.setDragImage(ghostRef.current, -20, 25);
      }
    } else {
      setDraggedItem(list[index]);
      event.dataTransfer.setData("text/plain", JSON.stringify(list[index]));
    }

    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", event.currentTarget.parentElement.outerHTML);

    if (onItemDragStart) onItemDragStart([])
  };

  const onDragOver = (item: DraggableListItem , index: number) => {
    if (!draggedItem && !multiple) return;
    if (!draggedItems?.length && multiple) return;
    
    if (item?.listName != name) return;
    
    let items = [...list].filter((item) => 
      multiple 
        ? item !== draggedItems.find(dItem => dItem === item) 
        : item !== draggedItem
    );

    if (draggedItem && !multiple) {
      const draggedOverItem = list[index];
      if (draggedItem === draggedOverItem) {
        return;
      }
      items.splice(index, 0, draggedItem);
    } else if (draggedItems.length && multiple) {
      if (draggedItems.find(dItem => dItem === list[index])) {
        return;
      }
      draggedItems.forEach((dItem) => {
        items.splice(index, 0, dItem)
      })
    }

    onItemDragOver([...items]);
  };

  const onDrop = (event: DragEvent<HTMLLIElement>) => {
    if (!onItemDrop) return;
    const eventList = event.dataTransfer.getData("text/plain"); // dropped `list` in string format (JSON.stringify(...))
    if(eventList === '') return;
    const formattedList = JSON.parse(eventList);
    const tranferredListName = getTransferredListName(formattedList);
   
    onItemDrop([...formattedList], tranferredListName ?? '', name ?? '', tempId);

    event.dataTransfer.clearData();
  }

  const getTransferredListName = (list: DraggableListItem[] | DraggableListItem) => {
    if (multiple) {
      if (Array.isArray(list) && list.length) return list[0].listName
    } else if (!Array.isArray(list) && typeof list === "object") return list.listName
  }

  const checkItem = (index: number) => {
    if (!onItemChecked) return
    list[index].checked = !list[index].checked
    onItemChecked([...list])
  }

  const resetDragState = () => {
    setIsDragging(false);
    setDraggedItem(null);
    setDraggedItems([]);
  }

  const isOdd = (index: number) => index%2!==0;

  const getTooltip = (item: DraggableListItem) => {
    let message: string;
    if (multiple) {
      message = '';
    } else {
      message = item.checked ? 'Uncheck to hide the column' : 'Check to display the column';
    }

    return message;
  }

  const handleItemRemove = (item: DraggableListItem) => () => {
    if (onItemRemove) onItemRemove([item], item.listName ?? '', 'orphan');
  }

  const getDraggingTooltip = () => {
    if (!isDragging && props.multiple) {
      if (props.inParent) return 'Drag and drop to remove from this parent';
      else return 'Drag and drop to associate with a parent';
    } else {
      return 'Drag and drop to reorder';
    }
  }

  return (
    <>
    <Box>
      <FormControl component="fieldset" sx={{width: '100%'}}>
          <List
            onDragOver={(event) => event.preventDefault()} // prevent ghost image to bounce back
          >
            {list.map((item, index) => (
              <ListItem
                tabIndex={-1}
                dense
                sx={
                  !multiple
                    ? styles.listItem
                    : inEditViewCustomer
                      ? {
                        bgcolor: item.isVendor ? 'background.paper' : '#F7F7F7'
                      }
                      : {
                        bgcolor: isOdd(index) ? 'background.paper' : '#F7F7F7'
                      }
                  }
                disablePadding
                key={item.recordId}
                onDragOver={() => { !item.fixed && onDragOver(item, index)}}
                onDrop={(event) => {
                  onDrop(event);
                  event.preventDefault();
                }}
                data-testid={`draggable-list-item-${index}`}
              >
                <ListItemButton
                  tabIndex={-1}
                  className="drag"
                  disableRipple
                  disableTouchRipple
                >
                  {
                    (!item.fixed) ?
                    <Tooltip 
                        title={getDraggingTooltip()} 
                        PopperProps={{sx: isDragging ? styles.noTooltip : null}}>
                      <IconButton
                        draggable
                        edge="end"
                        aria-label="Drag and drop icon"
                        onDragStart={(event) => {
                          setIsDragging(true);
                          setLastDraggedValue(list[index].checked);
                          onDragStart(event, index);
                          list[index].checked = true;
                        }}
                        onDragEnd={() => {
                          if(lastDraggedValue) return;
                          list[index].checked = !list[index].checked;
                          resetDragState();
                        }}
                        sx={{px: '0px', pr: '0.5rem'}}
                      >
                        <DragIndicator/>
                      </IconButton> 
                    </Tooltip>
                    : <Box width={'28px'}></Box>
                  }
                  {multiple && inParent ?
                  <Box sx={{width: '0.6rem'}}></Box>
                  :<Tooltip title={getTooltip(item)}>
                    <ListItemIcon
                      sx={styles.checkBox}
                    >
                      <Checkbox
                        aria-label='Checkbox'
                        edge="end"
                        checked={item.checked}
                        tabIndex={0}
                        disableRipple
                        onClick={() => checkItem(index)}
                        disabled={item.disabled}
                        data-testid={`draggable-list-checkbox-${index}`}
                        inputProps={{ 'aria-label' : item.name }}
                      />
                    </ListItemIcon>
                  </Tooltip>
                  }
                  <Box sx={{width: '100%'}}>
                    <ListItemText tabIndex={0} id={`${item.recordId}`} primary={item.name} />
                    {props.multiple &&
                      <Typography tabIndex={0} sx={{fontSize: '12px', color: '#707070'}}>
                        {`${item.isVendor ? 'Vendor ID' : 'Customer ID'}: ${item.srcId}`}
                      </Typography>
                    }
                  </Box>
                  {multiple && inParent && item.isVendor &&
                    <IconButton
                      aria-label='Remove icon'
                      sx={{color: '#154A8A'}}
                      onClick={handleItemRemove(item)}
                    >
                    <IndeterminateCheckBoxOutlined/>
                  </IconButton> 
                  }
                </ListItemButton>
              </ListItem>
            ))}
          </List>
      </FormControl>
    </Box>
    {/* ghost image */}
    {
    props.multiple &&
    <Box ref={ghostRef} style={{display: 'fixed', position: 'absolute', top: '-200px', left: '-200px', maxWidth: '200px', zIndex: '20'}}>
      {list.filter(o => o.checked).splice(0,4).map((item, idx) => <>
        <Box
          sx={{backgroundColor: '#154A8A', borderRadius: '20px', m: '4px', px: '8px', py: '4px', color: '#fff', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
        >{draggedItems.length > 3 && idx === 3 ? `+${draggedItems.length - 3} more customer` : item.name}</Box>
      </>
      )}
    </Box>
    }
    </>
  )
}

export default DraggableList;
