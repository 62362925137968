import { Grid } from '@mui/material';
import { FC } from 'react';
import TableWithLeftPinnedHeader, { IColumn } from '../../../../components/common/table-with-left-pinned-header';
import { formatCurrency } from '../../../../utility/helper';
import styles from './styles';

interface IARComparisonTableProps {
  columns : IColumn[];
  rows    : any[];
  currency: string | undefined;
}

const ARComparisonTable: FC<IARComparisonTableProps> = (props) => {
  return (
    <Grid container>
      <Grid item sx={styles.compareTable}>
        <TableWithLeftPinnedHeader
          columns={props.columns}
          rows={props.rows}
          styles={{
            tableContainer: styles.tableContainer,
            table: styles.table,
            tableHeadCell: styles.tableHeadCell,
            tableCell: styles.tableCell,
            tableHead: styles.tableHead,
            tableBody: styles.tableBody,
            tableRow: styles.tableRow,
          }}
          placeholder={formatCurrency(0, props.currency)}
        />
      </Grid>
    </Grid>
  )
};

export default ARComparisonTable;
