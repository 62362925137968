import { FC, useContext } from 'react';
import { Box, Grid, Typography, Autocomplete, TextField, Chip } from '@mui/material';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { InventoryWorksheetContext } from '../../../context/inventoryWorksheetContext';
import { INavigationWarningContext, NavigationWarningContext } from '../../../context/navigationWarningContext';
import { IClientInfo, IInventoryWorksheetContext } from '../../../interfaces/inventoryWorksheetInterface';
import { IInventoryWorksheetProps } from '../../../pages/inventory-worksheet';
import GeneralBreadcrumbs from '../../breadcrumb';
import styles from './styles';

const InventoryWorksheetBreadcrumbs: FC<IInventoryWorksheetProps> = (props) => {
  const { isDirty }                           = useContext(NavigationWarningContext) as INavigationWarningContext;
  const { setSelectedClient }                 = useContext(SelectedClientContext);
  const {
    clients, selectedClient,
    setLastSelectedClient, setShowPrompt
  }                                           = useContext(InventoryWorksheetContext) as IInventoryWorksheetContext;

  const handleClientChange = (_event : any, newValue: IClientInfo | null) => {
    if (newValue === null) { return; }
    setLastSelectedClient(newValue);
    if (isDirty) {
      setShowPrompt(true);
      return;
    }
    setSelectedClient(newValue);
  };

  /**
   * This function returns a boolean value depending on the parent status of the option.
   * @param option The name of the option
   * @returns True, if the option is a parent client, otherwise, false.
   */
  const isSelectedFieldNameParentFieldValue = (option: string) => {
    const parentClients = clients.filter(client => client.parentClient).map(client => client.borrowerName);
    return parentClients.includes(option);
  };

  return (
    <Box component='span' sx={styles.breadCrumbBox}>
      <Grid container sx={styles.headerContainer} spacing={0}>
        <Grid item xs={12} md={6} lg={8} xl={8.3}>
        {props.path === 'clients' ?
          <GeneralBreadcrumbs
            selectedText='Inventory Worksheet'
            breadcrumbLinks={[
              { linkText: 'Clients', route: '/clients' },
              { linkText: 'Client Settings', route: `/clients/${selectedClient.recordId}/settings` },
            ]}
          /> :
          <GeneralBreadcrumbs
            selectedText='Inventory'
            breadcrumbLinks={[
              { linkText: 'Reports', route: '/reports' },
            ]}
          />
        }
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
          <Box sx={styles.clientBox}>
            <Typography tabIndex={0} sx={styles.fieldText} component='label' htmlFor='client-name-box'>
              Client Name:
            </Typography>
            <Autocomplete
              id='client-name-box'
              aria-label='Client Name Dropdown'
              disablePortal
              options={clients.filter(client => !isSelectedFieldNameParentFieldValue(client.borrowerName))}
              getOptionLabel={clientOption => clientOption.borrowerName}
              renderOption={(prop, option) => {
                return (
                  <Box component='li' {...prop} sx={{fontSize:'14px'}} key={option.recordId}>
                    <Box tabIndex={0} sx={styles.labelChipContainer}>{option.borrowerName}</Box>
                    <Box sx={{ ...styles.parentChipContainer, ...(!isSelectedFieldNameParentFieldValue(option.borrowerName) && styles.hidden) }}>
                      <Chip size='small' sx={styles.parentIdentifierChip} />
                    </Box>
                  </Box>
                );
              }}
              onChange={handleClientChange}
              renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, 'aria-label': 'Client Name Dropdown' }} />}
              size='small'
              fullWidth
              sx={styles.clientComboBox}
              value={selectedClient}
              componentsProps={{
                popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InventoryWorksheetBreadcrumbs;
