import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Modal,
  Grid,
  IconButton,
  Paper,
  TextField,
  Checkbox,
  Tooltip,
  Stack,
  AlertColor,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EventIcon from '@mui/icons-material/Event';
import { API_DOMAIN, GET, ISO_DATE_FORMAT, PERMISSIONS, POST } from '../../../utility/constants';
import LoadingBackdrop from '../../common/loading-backdrop';
import { IARCollateral, IARCollateralCalc, IBBPeriod, IOtherLoan } from '../../../interfaces';
import styles from './styles';
import axiosInstance from '../../../service/axiosInstance';
import { checkUserPermissions, formatDate, formatDateToLocal, generateBorrowingBaseReport, getDateObject, getLocalStorageItem } from '../../../utility/helper';
import { IInventoryCollateral } from '../../../interfaces/reportsInterface';
import { setCalcIneligibleLoader } from '../../../actions/calcIneligibleActions';
import { CalcIneligibleContext } from '../../../context/calneligibleContext';
import Toaster from '../../toaster';
import DisabledComponentsContainer from '../../common/disabled-components-container';

interface BBCalcPayload {
  borrowerId?: number
  arCollateralId: number;
  bbPeriodId: number;
  disabled?: boolean;
}

/**
 * This is a functional component that renders a Calendar (Event) icon wrapped with a tooltip.
 * When hovered over, the tooltip displays the message "Select As Of Date".
 */
const CalendarIconWithTooltip = () => {
  return (
    <Tooltip title='Select As Of Date'>
      <EventIcon />
    </Tooltip>
  )
}

const CalculateBorrowingBaseButton = (props: { borrowerId?: number, borrowerName?: string, disabled?: boolean }) => {
  const {borrowerId, borrowerName, disabled} = props;

  const controller = new AbortController();

  const [isSelectPeriodModalOpen, setIsSelectPeriodModalOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<Date | null>(null);
  const [bbPeriods, setBBPeriods] = useState<IBBPeriod[]>([]);
  const [latestPeriodId, setLatestPeriodId] = useState<number | null>(null)

  const [arCollaterals, setARCollaterals] = useState<IARCollateralCalc[]>([]);
  const [arCollateralsDisplay, setARCollateralsDisplay] = useState<IARCollateralCalc[]>([]);
  const [invCollaterals, setInvCollaterals] = useState<IInventoryCollateral[]>([]);
  const [otherLoanCollaterals, setOtherLoanCollaterals] = useState<IOtherLoan[]>([]);

  const [selectedARCollateralIds, setSelectedARCollateralIds] = useState<BBCalcPayload[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasCalculatedData, setHasCalculatedData] = useState(false);

  const [toasterOpen, setToasterOpen] = useState<boolean>(false);
  const [toasterMessage, setToasterMessage] = useState<string>('');
  const [toasterSeverity, setToasterSeverity] = useState<AlertColor>('success');

  const { dispatch } = useContext(CalcIneligibleContext);

  /**
   * This useEffect hook updates the selected period ID when there are changes to `bbPeriods`.
   */
  useEffect(() => {
    if (!bbPeriods.length) return;
    setLatestSelectedPeriodId();
  }, [bbPeriods])

  /**
   * This useEffect hook filters AR collateral data when the `selectedPeriod` changes.
   */
  useEffect(() => {
    filterARCollateralFromSelectedPeriod();
  }, [selectedPeriod])

  /**
   * This useEffect hook checks collateral calculated data when `arCollateralsDisplay` changes.
   */
  useEffect(() => {
    checkCollateralCalculatedData();
  }, [arCollateralsDisplay])

  /**
   * This function handles the action when opening a modal or popup for the user to select a period.
   * It fetches necessary data for the borrower specified by the `borrowerId`.
   * It also sets the loading state while the data is being fetched and resets it once the fetching is complete.
   */
  const handleOpen = async () => {
    if (borrowerId) {
      setIsLoading(true);
      try {
        await Promise.all([
          getBBPeriods(borrowerId),
          getBorrowerARCollaterals(borrowerId),
          getBorrowerInvCollateral(borrowerId),
          getBorrowerOtherLoanCollateral(borrowerId),
        ]);
      } catch (ex) {
        console.log(ex);
      } finally {
        setIsLoading(false);
      }
    }
    setIsSelectPeriodModalOpen(true);
  }

  /**
   * This function handles the closing action for the select period modal or popup.
   * It resets the relevant state to their default values.
   */
  const handleClose = () => {
    setIsSelectPeriodModalOpen(false);
    setSelectedPeriod(null);
    setBBPeriods([]);
    setSelectedARCollateralIds([]);
  };

  /**
   * This function is used to set the ID of the most recent period based on the `endDate` property.
   * The periods are first sorted by their end dates in descending order, then the record ID of the most recent period
   * is set to `setLatestPeriodId`. If there's no record ID for the most recent period, a default value of `null` is used.
   */
  const setLatestSelectedPeriodId = () => {
    const sortedPeriodsByLatest = [...bbPeriods].sort((curr, prev) => {
      return new Date(prev.endDate).getTime() - new Date(curr.endDate).getTime();
    });
    setLatestPeriodId(sortedPeriodsByLatest[0].recordId ?? null);
  }

/**
 * This function is designed to filter and sort AR collaterals based on a given selected period.
 * 
 * 1. If no period is selected, the method exits early.
 * 2. It filters out AR collaterals based on specific conditions:
 *    a. If the calculated as of date (`calcAsOfDate`) is missing.
 *    b. The collateral's latest transaction period matches the selected period.
 *    c. The collateral's latest transaction period is within the bounds of the selected period and the calculated as of date.
 * 3. The filtered AR collaterals are then sorted by two date properties in descending order: `latestTransactionPeriod` and `calcAsOfDate`.
 * 4. Finally, the method populates `arCollateralCalcDisplay` with the latest available as of date for each collateral and ignores any duplicates.
 */
  const filterARCollateralFromSelectedPeriod = () => {
    if (!selectedPeriod) return;
    // return true if the calculated as of date is missing (means ar is not yet calculated or no data)
    // remove collaterals that is greater than the selected period and
    // collaterals that shows calcAsOfDate is later than latest period
    const filteredARCollateral = arCollaterals.filter(arCollateral => {
      if (!arCollateral?.calcAsOfDate) return true;
      return (
        (new Date(arCollateral.latestTransactionPeriod).toDateString() === selectedPeriod.toDateString()) ||
        (
          (new Date(arCollateral.latestTransactionPeriod) <=  selectedPeriod) &&
          (new Date(arCollateral.latestTransactionPeriod) >= new Date(arCollateral.calcAsOfDate))
        )
      )
    });

    /**
     * This function sorts collaterals on both latest calculated and latest available period
     */
    const sortedARCollateralByLatest = [...filteredARCollateral].sort((curr, prev) => {
      return (
        (new Date(prev.latestTransactionPeriod).getTime() - new Date(curr.latestTransactionPeriod).getTime()) ||
        (new Date(prev.calcAsOfDate).getTime() - new Date(curr.calcAsOfDate).getTime())
      )
    });

    // This part uses the latest available as of date per collateral and ignore others
    const arCollateralCalcDisplay: IARCollateralCalc[] = [];
    const arColllateralCalcCmpId: number[] = [];
    sortedARCollateralByLatest.forEach(arCollateralCalc => {
      if (!arColllateralCalcCmpId.includes(arCollateralCalc.recordId)) {
        arCollateralCalcDisplay.unshift(arCollateralCalc);
        arColllateralCalcCmpId.push(arCollateralCalc.recordId);
      }
    })
    
    setARCollateralsDisplay([...arCollateralCalcDisplay]);
  }

  /**
   * This function is responsible for handling changes in the selection state of ineligible AR collaterals.
   * It checks if a given AR collateral ID and bbPeriod ID combination already exists in the selected IDs list:
   * 1. If the combination is found, it removes it from the list.
   * 2. If not found, the new combination is added to the list.
   * After either action, the updated list is set to `setSelectedARCollateralIds`.
   *
   * @param arCollateralId - The ID of the AR collateral to check.
   * @param bbPeriodId - The associated bbPeriod ID for the AR collateral.
   */
  const onIneligibleChecked = (arCollateralId: number, bbPeriodId: number) => {
    const selectedIds = [...selectedARCollateralIds];
    if (selectedIds.find(bbPayload => bbPayload.arCollateralId === arCollateralId)) {
      const index = selectedIds.findIndex(bbPayload => bbPayload.arCollateralId === arCollateralId);
      selectedIds.splice(index, 1);
      setSelectedARCollateralIds(selectedIds);
      return;
    }
    selectedIds.push({arCollateralId, bbPeriodId});
    setSelectedARCollateralIds(selectedIds);
  }

  /**
   * This function is responsible for fetching BB periods based on a borrower's ID.
   * The periods are retrieved from the API and sorted by the `endDate` in descending order.
   *
   * @param borrowerId - The unique identifier of the borrower.
   */
  const getBBPeriods = async (borrowerId: number) => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/bbPeriods/search/findByBorrowerId?borrowerId=${borrowerId}&pageNo=0&pageSize=999&sortBy=endDate,DESC`,
        method: GET,
      })
      setBBPeriods(response.data.content);
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  }

  /**
   * This function retrieves AR collaterals for a borrower.
   * It fetches both calculated and active AR collaterals from the API.
   * The collaterals are then mapped, filtered and set for the UI display.
   *
   * @param borrowerId - The unique identifier of the borrower.
   */
  const getBorrowerARCollaterals = async (borrowerId: number) => {
    try {
      const calculatedCollaterals = await axiosInstance.request({
        url: `${API_DOMAIN}/ar/collaterals/search/calc/findByBorrowerId?borrowerId=${borrowerId}`,
        method: GET,
      });
      const activeCollaterals = await axiosInstance.request({
        url: `${API_DOMAIN}/ar/collaterals/search/findIsArchived?borrowerId=${borrowerId}&pageNo=0&pageSize=9999&isDefault=true&isArchived=false`,
        method: GET,
      }).then(response => response.data.content as IARCollateral[]);

      const mappedCollaterals = await Promise.all(calculatedCollaterals.data.map(async (calcCollateral: IARCollateralCalc) => {
        const response = await axiosInstance.request({
          url: `${API_DOMAIN}/ar/borrowerInputs/search/findByARCollateralId?arCollateralId=${calcCollateral.recordId}&pageNo=0&pageSize=9999&sortBy=recordId,DESC&isCurrent=true`,
          method: GET,
        });
        if (!activeCollaterals.some(collateral => collateral.recordId === calcCollateral.recordId)) { return null; }
        if (response.data.content.length === 0){ return null; }
        return calcCollateral;
      }));
      const filteredCollaterals = mappedCollaterals.filter(collateral => collateral !== null);
      setARCollaterals(filteredCollaterals);
    } catch (ex) { 
      console.log(ex); 
      throw ex;
    }
  }

  /**
   * This function fetches INV collaterals for a borrower.
   * The collaterals are sorted by `recordId` in ascending order.
   *
   * @param borrowerId - The unique identifier of the borrower.
   */
  const getBorrowerInvCollateral = async (borrowerId: number) => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/inv/inputs/search/findByBorrowerId?borrowerId=${borrowerId}&pageNo=0&pageSize=9999&sortBy=recordId,ASC`,
        method: GET,
      });
      setInvCollaterals(response.data.content);
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  }

/**
 * This function fetches other loan collaterals for a borrower.
 * It only fetches collaterals that are set as default and not archived.
 *
 * @param borrowerId - The unique identifier of the borrower.
 */
  const getBorrowerOtherLoanCollateral = async (borrowerId: number) => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/ol/collaterals/search/findIsArchived?borrowerId=${borrowerId}&pageNo=0&pageSize=9999&isDefault=true&isArchived=false`,
        method: GET,
      });
      setOtherLoanCollaterals(response.data.content);
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  }

  /**
   * This function performs the AR collateral calculations.
   * It first checks if the user has the permission to perform the calculation.
   * If permitted, it sets up payloads for the calculation API calls and sends multiple requests.
   */
  const calculateARCollaterals = async () => {
    const canCalculateBB = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.CALCULATE_BB);
    if (canCalculateBB) {
      const payload = {
        title: 'Calculating Borrowing Base Report',
        content: `Borrowing Base Report - ${borrowerName}`,
        hasInitiatedSubscription: false,
        isLoading: true,
        show: true,
        link: `/reports/borrowing-base?clientId=${borrowerId}&bbPeriodId=${latestPeriodId}&calcDate=${
          formatDate(selectedPeriod?.toISOString() ?? '', ISO_DATE_FORMAT)
        }`,
        cancelled: false,
        failed: false,
        selectedClientId: null,
        selectedCollateralId: null,
        isParentClientLevel: false,
        ineligibleSettings: [],
      };
      dispatch(setCalcIneligibleLoader({...payload}));
      setIsSelectPeriodModalOpen(false);
  
      const bbCalcPayload = selectedARCollateralIds.map(bbCalcPayload => {
        bbCalcPayload.borrowerId = borrowerId
        return bbCalcPayload;
      })
  
      const bbCalcDate = {
        borrowerId,
        endDate: formatDate(selectedPeriod?.toISOString() ?? '', ISO_DATE_FORMAT),
      };
  
      Promise.all([
        axiosInstance
          .request({
            url: `${API_DOMAIN}/ar/calculateIneligibles/multiple`,
            method: POST,
            signal: controller.signal,
            data: bbCalcPayload
          }),
        axiosInstance
          .request({
            url: `${API_DOMAIN}/bbCalcDates`,
            method: POST,
            signal: controller.signal,
            data: bbCalcDate
          }),
        ])
        .then(async (responses) => {

          await generateBorrowingBaseReport({
            borrowerId: borrowerId ?? 0,
            endDate: bbCalcDate.endDate,
            bbCalcDateId: responses[1].data.bbCalcDateId,
          });
          payload.title = 'Calculation Completed';
          payload.isLoading = false;
          dispatch(setCalcIneligibleLoader({...payload}));
        })
        .catch((error) => {
          console.log('error : ', error);
        });
    } else {
      setToasterOpen(true);
      setToasterMessage(
        'You do not have the necessary permissions for this action.'
      );
      setToasterSeverity('error');
      setIsSelectPeriodModalOpen(false);
    }
    
  };

  /**
   * This function is used to check if there's any calculated data available for the AR collaterals on display.
   * If there's any calculated data, `setHasCalculatedData` is called with `true`, else it's called with `false`.
   */
  const checkCollateralCalculatedData = () => {
    if (!arCollateralsDisplay.length) return setHasCalculatedData(false);
    const calcAsOfDates = arCollateralsDisplay.map(arDisplay => arDisplay.calcAsOfDate);
    const filteredPeriods = calcAsOfDates.filter(period => period != undefined);
    setHasCalculatedData(!!filteredPeriods.length);
  }
  
  /**
   * This function returns a tooltip text based on the conditions of the AR collateral.
   * It's specifically designed to handle the scenarios when checkboxes are disabled due to data constraints.
   *
   * @param  isEmptyData - Indicates if there's available data for the AR collateral.
   * @param unmatchedDate - Indicates if the selected as of date doesn't match.
   * @returns The tooltip text based on the conditions provided.
   */
  const disabledCheckboxTooltipText = (isEmptyData: boolean, unmatchedDate: boolean) => {
    if (isEmptyData) {
      return "No Available Data for this AR Collateral";
    } else if (!isEmptyData && unmatchedDate) {
      return "Selected As Of Date Does not Match";
    } else return "";
  }

  return (
    <>
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setToasterOpen(false)}
      />
      <Paper elevation={3} sx={styles.card}>
        <DisabledComponentsContainer isDisabled={disabled ?? false}>
          <Button fullWidth
            onClick={handleOpen} sx={styles.cardButton}
            aria-label={disabled ? 'Calculate Borrowing Base button disabled' : 'Calculate Borrowing Base'}
            disabled={disabled}
          >
            Calculate Borrowing Base
          </Button>
        </DisabledComponentsContainer>
      </Paper>
      <Modal
        open={isSelectPeriodModalOpen}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Grid xs={12} container sx={selectedPeriod ? styles.modalContainerExpanded : styles.modalContainer}>
          <Grid item xs={12}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Typography tabIndex={0} id='modal-modal-title' sx={styles.headerText}>
                Select Period
              </Typography>
              <Tooltip title='Close the modal'>
                <IconButton onClick={handleClose} aria-label="Close icon">
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} borderTop={1} borderColor='#E5E2E2'></Grid>
          <Grid item xs={12}>
            <Grid alignItems='center' py={2}>
              <Grid item xs={12} sx={styles.periodContainer}>
                <Stack sx={{width: '5rem', ml: '1rem'}}>
                  <Typography tabIndex={0} id='period-selection-title' component='label' sx={styles.bodyText}>
                    As of Date
                  </Typography>
                </Stack>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <Tooltip title='Select As Of Date'>
                    
                  </Tooltip> */}
                  <DatePicker
                      value={selectedPeriod}
                      // shouldDisableDate={disableDate}
                      onChange={() => {}} // moved onChange callback to onAccept, but onChange callback is required, so I put empty callback instead
                      onAccept={(newPeriod: Date | null) => { // used onAccept to prevent state changes when month or year changes (triggers onChange callback), requested in LW-2800
                        if (!newPeriod) return;
                        setSelectedPeriod(new Date(newPeriod.toISOString()));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          data-testid={'calc-bb-date-picker-text'}
                          inputProps={{ ...params.inputProps, placeholder: 'Select As of Date', 'aria-label': 'Calendar icon' }}
                          onDrop={(event) => event.preventDefault()}
                          onClick={() => setIsCalendarOpen(true)}
                          sx={styles.periodInput}
                        />
                      )}
                      open={isCalendarOpen}
                      onOpen={() => setIsCalendarOpen(true)}
                      onClose={() => setIsCalendarOpen(false)}
                      components={{ OpenPickerIcon: CalendarIconWithTooltip,  }}
                    />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          {
            selectedPeriod && 
            <Grid sx={styles.collateralContainer} container xs={12}>
              {/* headers */}
              <Grid 
                container
                flexDirection={'row'}
                textAlign={'center'}
                alignItems={'center'}
                xs={12}
                pt={3}
              >
                <Grid item xs={3}></Grid>
                <Grid item xs={3.5}>
                  <Typography tabIndex={0} id='calculated-ineligible-date-header' component='label'>
                    Date of Last Ineligible Calculation
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography tabIndex={0} id='recent-as-of-date-header' component='label'>
                    Recent As of Date
                  </Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography tabIndex={0} id='update-ineligibles-header' component='label'>
                    Update Ineligible Calculation
                  </Typography>
                </Grid>
              </Grid>

              {/* ar collateral header */}
              <Grid container flexDirection={'row'} textAlign={'center'} xs={12} py={2}>
                <Grid item xs={3}>
                  <Typography tabIndex={0} sx={styles.groupHeaderTitle} id='ar-collateral-header' component='label'>
                    AR Collateral
                  </Typography>
                </Grid>
                <Grid item xs={9}></Grid>
              </Grid>

              {/* ar collateral values */}
              {
                arCollateralsDisplay.map((arCollateral) => {
                  const isDisabled = (
                    !arCollateral?.calcAsOfDate ||
                    !arCollateral?.latestTransactionPeriod
                  ) || (
                    getDateObject(arCollateral?.latestTransactionPeriod).toDateString() !== selectedPeriod.toDateString()
                  );

                    return (
                    <Grid 
                      container
                      key={`${arCollateral.recordId}-arCollateralCalcItem`}
                      flexDirection={'row'}
                      textAlign={'center'}
                      alignItems={'center'}
                      xs={12}
                    >
                      <Grid item xs={3}>
                        <Typography tabIndex={0} id='ar-collateral-header' component='label'>
                          {arCollateral.arCollateralName}
                        </Typography> 
                      </Grid>
                      <Grid item xs={3.5}>
                        <Typography tabIndex={0} id='ar-collateral-header' component='label'>
                          {arCollateral?.calcAsOfDate ? formatDateToLocal(arCollateral?.latestCalcDate, 'MM-DD-YYYY') : "--"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography tabIndex={0} id='ar-collateral-header' component='label'>
                          {arCollateral?.latestTransactionPeriod ? formatDate(arCollateral?.latestTransactionPeriod, 'MM-DD-YYYY') : "--"}
                        </Typography>
                      </Grid>
                      <Grid item xs={2.5}>
                        <Tooltip title={disabledCheckboxTooltipText(
                          !arCollateral?.calcAsOfDate || !arCollateral?.latestTransactionPeriod,
                          getDateObject(arCollateral?.latestTransactionPeriod).toDateString() != selectedPeriod.toDateString()
                        )}>
                          <span>
                            <Checkbox 
                              tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                  e.preventDefault();
                                  if (!isDisabled) {
                                    onIneligibleChecked(arCollateral.recordId, arCollateral?.latestBbPeriodId);
                                  }
                                }
                              }}
                              aria-disabled={isDisabled}
                              aria-checked={!!selectedARCollateralIds.find(bbPayload => bbPayload.arCollateralId === arCollateral.recordId)}
                              sx={{
                                ...styles.disabledCheckbox,
                                opacity: isDisabled ? 0.5 : 1,
                                cursor: isDisabled ? 'not-allowed' : 'pointer'
                              }}
                              checked={!!selectedARCollateralIds.find(bbPayload => bbPayload.arCollateralId === arCollateral.recordId)}
                              onChange={() => {
                                if (!isDisabled) {
                                  onIneligibleChecked(arCollateral.recordId, arCollateral?.latestBbPeriodId);
                                }
                              }}
                              id='ar-collateral-header'
                            />
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )
                })
              }

              {/* inv collateral header */}
              <Grid container flexDirection={'row'} textAlign={'center'} xs={12} py={2}>
                <Grid item xs={3}>
                  <Typography tabIndex={0} sx={styles.groupHeaderTitle} id='inv-collateral-header' component='label'>
                    Inventory Collateral
                  </Typography>
                </Grid>
                <Grid item xs={9}></Grid>
              </Grid>

              {/* inv collateral values */}
              {
                invCollaterals.map(invCollateral => 
                  <Grid
                    key={invCollateral.recordId} 
                    container
                    flexDirection={'row'}
                    textAlign={'center'}
                    alignItems={'center'}
                    xs={12}
                  >
                    <Grid item xs={3} py={1}>
                      <Typography tabIndex={0} id='inv-collateral-name' component='label'>
                        {invCollateral.invCollateralName}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              }

              {/* other loan collateral header */}
              <Grid container flexDirection={'row'} textAlign={'center'} xs={12} py={2}>
                <Grid item xs={3}>
                  <Typography tabIndex={0} sx={styles.groupHeaderTitle} id='ol-collateral-header' component='label'>
                    Other Loan Collateral
                  </Typography>
                </Grid>
                <Grid item xs={9}></Grid>
              </Grid>

              {
                otherLoanCollaterals.map(otherLoanCollateral => 
                  <Grid
                    key={otherLoanCollateral.recordId}
                    container
                    flexDirection={'row'}
                    textAlign={'center'}
                    alignItems={'center'}
                    xs={12}
                  >
                    <Grid item xs={3} py={1}>
                      <Typography tabIndex={0} id='ol-collateral-name' component='label'>
                        {otherLoanCollateral.collateralName}
                      </Typography>
                    </Grid>
                    <Grid item xs={3.5}>
                      <Typography id='ol-collateral' component='label'></Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography id='ol-collateral' component='label'></Typography>
                    </Grid>
                    <Grid item xs={2.5}></Grid>
                  </Grid>
                )
              }
            </Grid>
          }
          {
            selectedPeriod && 
            <>
              <Grid item xs={12} borderTop={1} borderColor='#E5E2E2'></Grid>
              <Grid item xs={12} p={2}>
                <Grid container justifyContent='flex-end' gap={1}>
                  <Button
                    variant='outlined'
                    size='small'
                    disableElevation
                    onClick={handleClose}
                    // sx={styles.cancelButton}
                  >
                    Cancel
                  </Button>
                  <DisabledComponentsContainer isDisabled={arCollateralsDisplay.length === 0 || !hasCalculatedData}>
                    <Button
                      variant='contained'
                      size='small'
                      disableElevation
                      disabled={arCollateralsDisplay.length === 0 || !hasCalculatedData}
                      aria-label={arCollateralsDisplay.length === 0 || !hasCalculatedData ? 'Proceed button disabled' : 'Proceed'}
                      onClick={() => calculateARCollaterals()}
                      // sx={styles.proceedButton}
                    >
                      Proceed
                    </Button>
                  </DisabledComponentsContainer>
                </Grid>
              </Grid>
            </>
          }
        </Grid>
      </Modal>
      <LoadingBackdrop
        isLoading={isLoading}
      />
    </>
  )
}

export default CalculateBorrowingBaseButton;