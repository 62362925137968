const styles = {
  linkForViewApplicableCustomers: {
    color: '#0075AD',
    fontSize: '11px',
  },
  disabledLinkForViewApplicableCustomers: {
    color: '#ccc',
    fontSize: '11px',
    cursor: 'auto',
  },
  modalContainer: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fbfbfb',
    borderRadius: '0.3rem',
    boxShadow: '0 0 24px -8px rgba(0,0,0,0.75)',
    width: '1200px',
  },
  cancelButton: {
    height: '31px',
    width: '82px',
    margin: '0.5rem',
    textTransform: 'capitalize',
  },
  saveButton: {
    height: '31px',
    width: '140px',
    margin: '0.5rem',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },
  modalHeading: {
    fontSize: '18px',
    color: '#154A8A',
    fontWeight: 'bold',
  },
  closeIconButton: {
    '&.MuiIconButton-root .MuiSvgIcon-root': {
      width: '0.8em',
      height: '0.8em',
    }
  },
  divider: {
    border: '0.1px solid #e1e1e1',
    marginY: 0.5,
  },
  gridContainerForHeadingAndCloseButton: {
    marginX: '1rem',
    marginTop: '1rem',
  },
  gridContainerForFilterFields: {
    marginX: '1rem',
    marginBottom: '10px',
  },
  gridContainerForMainContent: {
    marginX: '1rem',
  },
  gridContainerForCancelAndSaveButtons: {
    marginX: '1rem',
    marginBottom: '1rem',
    paddingTop: '14px',
  },
  inputForViewByFilter: {
    width: 'fit-content',
    fontSize: '14px',
    height: '36.6px',
    marginRight: '10px',
    '&.MuiOutlinedInput-root': {
      backgroundColor: 'white',
      fontSize: '14px',
      height: '36.6px',
    },
    '& .MuiOutlinedInput-input': {
      paddingY: '0',
      textAlign: 'left',
      width: '100px'
    },
    '@media (max-width: 1400px)': {
      fontSize: '11px'
    },
    '@media (max-width: 1535px)': {
      fontSize: '13px'
    },
  },
  menuItemForViewByFilter: {
    fontSize: '14px'
  },
  inputForAsOfDate: {
    width: 'fit-content',
    fontSize: '14px',
    height: '36.6px',
    marginRight: '10px',
    '& .MuiOutlinedInput-input': {
      paddingY: '0',
      textAlign: 'left',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      fontSize: '14px',
      height: '36.6px',
      width: '180px'
    },
    '@media (max-width: 1400px)': {
      fontSize: '11px'
    },
    '@media (max-width: 1535px)': {
      fontSize: '13px'
    },
  },
  inputForSearchBasedOnType: {
    height: '36.6px',
    borderRadius: '4px 0px 0px 4px',
    fontSize: '14px',
    '&.MuiOutlinedInput-root': {
      backgroundColor: 'white',
      fontSize: '14px',
      height: '36.6px',
    },
    '& .MuiOutlinedInput-input': {
      paddingY: '0',
      textAlign: 'left',
      width: '60px'
    },
    '@media (max-width: 1400px)': {
      fontSize: '11px'
    },
    '@media (max-width: 1535px)': {
      fontSize: '13px'
    },
  },
  menuItemForSearchBasedOnType: {
    fontSize: '14px'
  },
  inputForSearch: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
      borderRadius: '0px 4px 4px 0px',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  containerForFilterFields: {
    display: 'flex'
  },
  containerForSearchFields: {
    display: 'flex'
  },
  viewByLabel: {
    fontSize: '14px',
    marginY: '5px',
  },
  noDataPlaceholderContainer: {
    backgroundColor: '#f8f8f8',
  },
  viewByCustomerTableContainer: {
    maxHeight: '23rem',
    width: 'calc(100% - 20px)' /* reduction is from padding left and right's 10 px */,
    paddingX: '10px',
  },
  viewByCustomerTableHead: {
    boxShadow: '0px 3px 6px #00000029',
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  viewByCustomerTableHeadRow: {
    
  },
  viewByCustomerTableHeadCell: {
    backgroundColor: '#F7F7F7',
    borderBottom: 0,
    fontSize: '15px'
  },
  viewByCustomerTableRow: {
    '&:nth-of-type(2n)': {
      backgroundColor   : '#F7F7F7',
    },
  },
  viewByCustomerTableCell: {
    maxWidth: '25ch',
    border: 0,
    paddingY: 0,
  },
  viewByCustomerTableCellText: {
    fontSize: '14px',
    color: 'inherit',
  },
  viewByCustomerTableCellForCheckbox: {
    border: 0,
    paddingY: 0,
    textAlign: 'center',
  },
  viewByParentMainContainer: {
    maxHeight: '23rem',
    padding: '15px',
  },
  viewByParentSubContainer: {
    maxHeight: 'inherit',
    overflowY: 'scroll',
  },
  viewByParentPaperContainer: {

  },
  viewByParentAccordionSummary: {
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)'
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginLeft: '10px',
    height: '56px',
  },
  viewByParentAccordionSummaryTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  viewByParentAccordionSummaryCustomerNameText: {
    fontSize: '15px',
  },
  viewByParentAccordionSummaryCustomerIDText: {
    fontSize: '12px',
    color: '#707070',
  },
  viewByParentAccordionDetails: {
    padding: '0px',
  },
  viewByParentListItem: {
    bgcolor: 'background.paper',
    display: 'flex',
    fontWeight: 'bold',
    py: '10px',
    width: 'relative'
  },
  viewByParentTableHeadCell: {
    border: 'none',
    '&:nth-of-type(1)': { /* customer name */
      padding: '16px 16px 16px 48px',
    },
    '&:nth-of-type(2)': { /* customer id */

    },
    '&:nth-of-type(3)': { /* selected */
      padding: '16px 48px 16px 16px',
    },
  },
  viewByParentTableHeadCellForSelected: {
    textAlign: 'center',
    border: 'none',
  },
  viewByParentListItemTextContainer: {

  },
  containerForParentListHeadingLabelContainer: {
    padding: '15px 50px',
    backgroundColor: '#F0F0F0',
    borderBottom: '1px solid #ebebeb',
    position: 'sticky',
    top: 0,
    zIndex: 3,
    height: '26px',
  },
  containerForParentListHeadingLabel: {
    fontWeight: 'bold',
    fontSize: '15px',
  },
  viewByParentTableContainer: {
    width: '100%',
  },
  viewByParentTableHead: {
    backgroundColor: '#F6F6F6',
  },
  viewByParentTableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor   : '#FEFEFE',
    },
    '&:nth-of-type(even)': {
      backgroundColor   : '#F6F6F6',
    },
  },
  viewByParentTableCell: {
    maxWidth: '25ch',
    border: 0,
    paddingY: 0,
    '&:nth-of-type(1)': { /* customer name */
      padding: '16px 16px 16px 48px',
    },
    '&:nth-of-type(2)': { /* customer id */

    },
  },
  viewByParentTableCellForSelected: {
    textAlign: 'center',
    border: 0,
    padding: '0 48px 0 16px',
  },
  viewByParentTableCellTextForCustomerName: {
    fontSize: '14px',
    color: 'inherit',
  },
  viewByParentTableCellTextForCustomerID: {
    fontSize: '14px',
    color: 'inherit',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingY: '10px',
  },
}

export default styles;
