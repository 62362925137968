import yup from './index';

const createReserveSchema = yup.object().shape({
  
  reserves: yup
    .array()
    .of(yup.object().shape({
      description: yup.string()
        .required('Description is required')
        .trim(),
      amount: yup.number()
        .required('Reserve Amount is required')
        .typeError('Reserve Amount must be a number'),
    }))
    .uniqueProperty('description', 'Description must be unique')
});

export default createReserveSchema;