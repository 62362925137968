import { Box, Paper } from "@mui/material";
import ResetPasswordForm from "../../../components/forgot-password/password-reset-request";
import LoanWatchLogo from '../../../assets/images/logo.png';
import styles from "./styles";
import { FC, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/authContext";
import { getLocalStorageItem } from "../../../utility/helper";
import { ActionType } from "../../../actions/authActions";

/**
 * Component for showing the Reset Password page.
 */
const ResetPassword: FC = () => {
  const { dispatch } = useContext(AuthContext);

  /**
   * This useEffect hook automatically logs the user out if the the user accessed this page while logged in.
   */
  useEffect(() => {
    if(getLocalStorageItem('token') !== null) {
      dispatch({
        type: ActionType.LOGOUT,
      });
    }
  }, []);

  return (
    <Box sx={styles.mainContainer}>
      <img src={LoanWatchLogo} alt='LoanWatch Logo' aria-label='LoanWatch logo image' width={500} tabIndex={0} />
      <Paper elevation={0} sx={styles.paperStyle}>
          <Box tabIndex={0} component={'h2'} sx={styles.modalTitle}>
            Password reset request
          </Box>
          <Box tabIndex={0} component={'p'} sx={styles.descriptionText}>
            Please provide the email address that you used when you signed up for your LoanWatch account.
          </Box>
          <Box tabIndex={0} component={'p'} sx={styles.descriptionText}>
            We will send you an email that will allow you to reset your password.
          </Box>
          <ResetPasswordForm />
      </Paper>
    </Box>
  )
};

export default ResetPassword;