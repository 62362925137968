const styles = {
  mainContainer: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F7F7F7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  paperStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '10px',
    width: '340px',
    borderRadius: '10px',
    paddingLeft: '40px',
    paddingRight: '40px',
    marginTop: '50px'
  },
  modalTitle: {
    textAlign: 'center',
    paddingTop: '15px',
    paddingBottom: '30x',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  descriptionText: {
    color: '#707070', 
    fontSize: '14px',
  },
  labelText: {
    fontSize: '14px',
    paddingTop: '15px',
    paddingBottom: '5px'
  },
  inputField: {
    '& .MuiFormHelperText-root.Mui-error': {
        marginLeft:0,
    },
    '& .MuiOutlinedInput-notchedOutline' : {
        borderColor: '#D4DCFF',
        color: 'black'
    },
    '&&&&& .MuiInputBase-root' : {
        backgroundColor: '#F9FAFF',
    },
    '&&&&& .MuiOutlinedInput-input' : {
        backgroundColor: '#F9FAFF',
        WebkitBoxShadow: "0 0 0 1000px #F9FAFF inset",
        height: '17px'
    },
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '30px',
    marginTop: '15px',
  },
  loginButton: {
    fontSize: '14px',
    fontWeight: 'normal',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
};

export default styles;