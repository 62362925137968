import { useState, useEffect, useContext, FC } from 'react';
import { Grid, TableContainer, Paper, Table, TableBody, TableRow, TableFooter, TablePagination, TableHead, IconButton, Tooltip, AlertColor } from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { IAccountingSystems, ILineOfBusinesses, IReportFrequencies, INaicsCodes, TableComponentProps, IClientInfo } from '../../../../../interfaces/clientListInterface';
import { StyledTableCell, StyledTableRow } from './styled-components';
import Row from './row';
import styles from './styles';
import { API_DOMAIN, GET } from '../../../../../utility/constants';
import CustomizeColumnsModal from '../../customize-columns-modal';
import { ClientContext } from '../../../../../context/clientContext';
import { DraggableListItem } from '../../../../../interfaces/draggableList';
import Toaster from '../../../../toaster';
import { ICurrency } from '../../../../../interfaces/multiCurrencyInterface';
import { getCurrencies } from '../../../../../utility/helper';
import axiosInstance from '../../../../../service/axiosInstance';
import { getLabelDisplayedRows, getLabelRowsPerPage } from '../../../../client-settings/tabs/tab-panel-contents/pagination';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import DisabledComponentsContainer from '../../../../common/disabled-components-container';

interface PaginationButtonProps {
  page                : number;
  handlePreviousClick : () => void;
  count               : number;
  rowsPerPage         : number;
  handleNextClick     : () => void
}

/**
 * This function returns the Previous Icon Button.
 * @param param0 The parameters of an Icon Button
 * @returns The Previous Icon Button with aria-label and components container.
 */
export const PreviousIconButton = ({ page, onClick }) => {
  const isDisabled = page === 0;

  return (
      <DisabledComponentsContainer isDisabled={isDisabled}>
          <IconButton aria-label={isDisabled ? 'Previous page icon button disabled' : 'Previous page icon'} onClick={onClick} disabled={isDisabled} sx={styles.previousPageIcon}>
              <KeyboardArrowLeft />
          </IconButton>
      </DisabledComponentsContainer>
  );
};

/**
 * This function returns the Next Icon Button.
 * @param param0 The parameters of an Icon Button
 * @returns The Next Icon Button with aria-label and components container.
 */
export const NextIconButton = ({ page, count, rowsPerPage, onClick }) => {
  const isDisabled = page === Math.ceil(count / rowsPerPage) - 1;

  return (
      <DisabledComponentsContainer isDisabled={isDisabled}>
          <IconButton aria-label={isDisabled ? 'Next page icon button disabled' : 'Next page icon'} onClick={onClick} disabled={isDisabled} sx={styles.nextPageIcon}>
              <KeyboardArrowRight />
          </IconButton>
      </DisabledComponentsContainer>
  );
};

/**
 * This function gets the button component for the Pagination Buttons.
 * @param type The type of the Pagination Button. Either 'prev' or 'next'.
 * @param paginationProps The props for the pagination button of the table.
 * @returns A component for the Pagination buttons.
 */
const getPaginationButtonsComponent = (type: 'prev' | 'next', paginationProps: PaginationButtonProps) => {
  const { page, handlePreviousClick, count, rowsPerPage, handleNextClick } = paginationProps;

  if (type === 'prev') {
    return () => <PreviousIconButton page={page} onClick={handlePreviousClick} />;
  } else {
    return () => <NextIconButton page={page} count={count} rowsPerPage={rowsPerPage} onClick={handleNextClick} />
  }
};

/**
 * Component for showing the contents of the table component.
 * @param props The props for the TableComponent component in the clients page. See the TableComponentProps interface for more information.
 */
const TableComponent: FC<TableComponentProps> = (props) => {
  const {
    type,
    refreshRow,
    setRefreshRow,
    page,
    rowsPerPage,
    totalElements
  } = props;
  const clientContext                             = useContext(ClientContext);
  const [clientInfo, setClientInfo] = useState<IClientInfo[]>(props.clientInfo);
  const [accountingSystems, setAccountingSystems] = useState<IAccountingSystems[]>([]);
  const [lineOfBusinesses, setLineOfBusinesses]   = useState<ILineOfBusinesses[]>([]);
  const [reportFrequencies, setReportFrequencies] = useState<IReportFrequencies[]>([]);
  const [naicsCodes, setNaicsCodes]               = useState<INaicsCodes[]>([]);
  const [currencies, setAllCurrencies]            = useState<ICurrency[]>([]);
  const [open, setOpen]                           = useState<boolean>(false);
  const [toasterOpen, setToasterOpen]             = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]       = useState<string>('');
  const [toasterSeverity, setToasterSeverity]     = useState<AlertColor>('success');
  const [selectedColumns, setSelectedColumns]     = useState<DraggableListItem[]>(clientContext.list.filter(item => item.checked));

  /**
   * This useEffect hook calls the function that retrieves the dropdown values.
   */
  useEffect(() => {
    getDropdownValues();
    fetchCurrencies();
  }, []);

  /**
   * This useEffect hook sets the values for the client information for the table.
   */
  useEffect(() => {
    setClientInfo(props.clientInfo);
  }, [props.clientInfo]);
  
  /**
   * This useEffect hook sets the selected columns to be shown in the row.
   */
  useEffect(() => {
    const columns = clientContext.list.filter(item => item.checked);
    setSelectedColumns(columns);
  }, [clientContext?.list]);

  const handlePreviousClick = () => {
    if (page > 0) {
      props.onPageChange(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < Math.ceil(props.totalElements / rowsPerPage) - 1) {
      props.onPageChange(page + 1);
    }
  };

  /**
   * This function calls the getCurrencies function in order to retrieve the list of available currencies.
   */
  const fetchCurrencies = async () => {
    const res = await getCurrencies()

    if(res){
      setAllCurrencies(res.currencies)
    }
  }

  /**
   * This function retrieves the dropdown values for the accounting systems, 
   * NAICS codes, line of business, report frequencies, and frequency dropdowns.
   */
  const getDropdownValues = async () => {
    await getAccountingSystems();
    await getLineOfBusinesses();
    await getReportFrequencies();
    await getNaicsCode();
  };

  /**
   * This function sets the values for the accounting systems dropdown.
   */
  const getAccountingSystems = async () => {
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/accountingSystems?pageSize=15`,
        method: GET
      })
  
      setAccountingSystems(response.data.content);
    } catch (error) {
      console.log(error);
      setAccountingSystems([]);
    }
  };

  /**
   * This function sets the values for the line of business dropdown.
   */
  const getLineOfBusinesses = async () => {
    try {
      const response = await axiosInstance.request({
        url: '../../../../../../mock-api/line-of-business.json',
        method: GET,
        baseURL: ''
      })
  
      setLineOfBusinesses(response.data);
    } catch (error) {
      console.log(error);
      setLineOfBusinesses([]);
    }
  };

  /**
   * This function sets the values for the reporting frequency dropdown.
   */
  const getReportFrequencies = async () => {
    try {
      const response = await axiosInstance.request({
        url: '../../../../../../mock-api/report-frequencies.json',
        method: GET,
        baseURL: ''
      })
  
      setReportFrequencies(response.data);
    } catch (error) {
      console.log(error);
      setReportFrequencies([]);
    }
  };

  /**
   * This function sets the values for the NAICS Codes dropdown.
   */
  const getNaicsCode = async () => {
    try {
      const response = await axiosInstance.request({
        url: '../../../../../../mock-api/naics_codes.json',
        method: GET,
        baseURL: ''
      })
      
      setNaicsCodes(response.data);
    } catch (error) {
      console.log(error);
      setNaicsCodes([]);
    }
  };

  /**
   * This function is called whenever the current page of the client table is changed.
   * @param event The event generated upon changing the page.
   * @param newPage The value of the new page.
   */
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    props.onPageChange(newPage);
  };

  /**
   * This function is called whenever the rows per page of the client table is changed.
   * @param event The event generated upon changing the number of rows per page.
   */
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    props.onRowsPerPageChange(parseInt(event.target.value));
  };

  /**
   * This sorts the line of businesses alphabetically.
   */
  const sortLineOfBusinesses = () => {
    const sortedLineOfBusiness: ILineOfBusinesses[] = [...lineOfBusinesses].sort(
      (a: ILineOfBusinesses, b: ILineOfBusinesses) => {
        const nameA = a.lineOfBusinessName.toUpperCase();
        const nameB = b.lineOfBusinessName.toUpperCase();
  
        if (nameA > nameB) {
          return 1;
        } else if (nameA < nameB) {
          return -1;
        } else {
          return 0;
        }
      }
    );
    return sortedLineOfBusiness;
  }

  /**
   * This sorts the accounting systems alphabetically.
   */
  const sortedAccountingSystems: IAccountingSystems[] = [
    ...accountingSystems,
  ].sort((a: IAccountingSystems, b: IAccountingSystems) => {
    const nameA = a.accountingSystemName.toUpperCase();
    const nameB = b.accountingSystemName.toUpperCase();

    if (nameA > nameB) {
      return 1;
    } else if (nameA < nameB) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <>
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setToasterOpen(false)}
      />
      <Grid container>
        <CustomizeColumnsModal open={open} setOpen={setOpen}/>
        <TableContainer component={Paper} elevation={0} data-testid='clients-table-component'>
          <Table sx={styles.tableSize}>
            <TableHead>
              <TableRow>
                {
                  selectedColumns.map(column => (
                    <StyledTableCell tabIndex={0} key={column.recordId} sx={{...(column.name === 'Line Limit' && styles.rightAlign)}}>{column.name}</StyledTableCell>
                  ))
                }
                <StyledTableCell tabIndex={0} align='center' sx={styles.actionCell}>
                  Actions
                  <Tooltip title='Customize the columns'>
                    <IconButton sx={styles.customizeColumnsIcon} onClick={() => setOpen(true)} aria-label='Customize columns icon'>
                      <ViewColumnIcon/>
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientInfo.map((row, index) => (
                <Row
                  type={type}
                  key={row.recordId}
                  row={row}
                  sortedAccountingSystems={sortedAccountingSystems}
                  sortedLineOfBusiness={sortLineOfBusinesses()}
                  reportFrequencies={reportFrequencies}
                  naicsCodes={naicsCodes}
                  currencies={currencies}
                  refreshRow={refreshRow}
                  setRefreshRow={setRefreshRow}
                  page={page}
                  length={clientInfo.length} 
                  isToasterOpen={false} 
                  setIsToasterOpen={setToasterOpen} 
                  toasterMessage={''} 
                  setToasterMessage={setToasterMessage} 
                  toasterSeverity={'success'} 
                  setToasterSeverity={setToasterSeverity}                  
                />
              ))}
            </TableBody>
            <TableFooter>
              <StyledTableRow></StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          <TablePagination
            component='div'
            rowsPerPageOptions={[5, 10, 25, 100]}
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={getLabelDisplayedRows()}
            labelRowsPerPage={getLabelRowsPerPage()}
            backIconButtonProps={{component: getPaginationButtonsComponent('prev', { page, handlePreviousClick, count: totalElements, rowsPerPage, handleNextClick })}}
            nextIconButtonProps={{component: getPaginationButtonsComponent('next', { page, handlePreviousClick, count: totalElements, rowsPerPage, handleNextClick })}}
            SelectProps={{ 
              inputProps: {
                'aria-label': 'Expand below icon',
                'aria-labelledby': 'Expand below icon',
              },
              id:'expandBelowIcon'
             }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TableComponent;