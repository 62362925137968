const styles = {
  bottomActionsButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: '4rem',
  },
  cancelButton: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    width: '82px',
  },
  gridContainer: {
    alignItems: 'center',
    justifyContent: 'start',
    paddingX: '10px',
    width: '50%'
  },
  gridField: {
    fontSize: '14px',
    height: '30px',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      fontSize: '14px',
      height: '30px',
      width: '100%',
    },
    '& .MuiOutlinedInput-input': {
      paddingX: '10px',
      paddingY: '5px',
    },
    '& .Mui-error': {
      fontSize: '11px',
      marginTop: '1px',
      marginX: '0',
    }
  },
  gridSelect: {
    width: 'fit-content',
    '@media (max-width: 1535px)': {
      fontSize: '13px',
    },
    '@media (max-width: 1400px)': {
      fontSize: '11px',
    }
  },
  limitGridSelect: {
    '& .MuiOutlinedInput-input': {
      width: '161.5px',
      paddingY: '0',
      textAlign: 'left',
    },
    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
      '-webkit-text-fill-color': 'black'
    }
  },
  gridMenuItem: {
    fontSize: '14px',
  },
  hidden: {
    display: 'none',
  },
  leftAlignedText: {
    textAlign: 'left',
  },
  label: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    marginTop: '0.5rem',
   '& .MuiFormLabel-asterisk': {
      color: '#db3131',
    },
    color: '#000',
  },
  saveButton: {
    height: '31px',
    textTransform: 'capitalize',
    width: '82px',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },

};

export default styles;
