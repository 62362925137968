import { AlertColor, Box, IconButton, ListItem, ListItemText, Tooltip, Typography, } from "@mui/material"
import styles from "../styles";
import { IARCustomer } from "../../../../interfaces";
import PreviewIcon from '@mui/icons-material/Preview';
import { Dispatch, FC, SetStateAction, useCallback, useContext, useState } from "react";
import { IParentCustomer, IToasterProps } from "../..";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../../context/customerSettingsContext";
import CustomerModal, { IARCustomerFormValues } from "../../customer-modal";
import { SelectedClientContext } from "../../../../context/selectedClientContext";
import NewTag from "../../../../components/common/new-tag";

export interface IProps extends IToasterProps {
  item: IARCustomer;
  index: number;
  arCustomerList: IARCustomer[];
  setParentCustomers: Dispatch<SetStateAction<IParentCustomer[] | undefined>>;
  filteredParentCustomer?: IParentCustomer[];
  setFilteredParentCustomers: Dispatch<SetStateAction<IParentCustomer[] | undefined>>;
  getAllCustomers: () => void;
}

/**
 * This component render a Customer/Child Row component.
 * 
 * @param param IProps
 */
const CustomerRow: FC<IProps> = (props: IProps) => {
  const {canViewParentInformation}   = useContext(CustomerSettingsContext) as ICustomerSettingsContext;
  const {selectedClient}             = useContext(SelectedClientContext);
  const [open, setOpen]              = useState<boolean>(false);


  /**
   * This useCallback set the modal state to open.
   */
  const handleOpenModal   = useCallback(() => setOpen(true), []);


  /**
   * This function handle the error while saving the changes made on AR Customer
   */
  const handleError = () => {
    showToaster('error', 'Request failed. Please try again.')
  };

  /**
   * This function dynamically set the value of toaster props.
   * 
   * @param severity The severity of the toaster.
   * @param message The Message of the toaster.
   */
  const showToaster = (severity: AlertColor, message: string) => {
    props.setIsToasterOpen(true);
    props.setToasterSeverity(severity);
    props.setToasterMessage(message);
  };

  /**
   * This function handle the business logics when the changes is successfully saved.
   * 
   * @param values Form Values
   */
  const handleSuccess = (values: IARCustomerFormValues) => {
    showToaster('success', 'Changes in Customer Settings have been saved.')

    props.setParentCustomers(props.filteredParentCustomer?.map(parent => {
      return {
        ...parent,
        children: parent.children?.map(child => {
          if(child.recordId === props.item.recordId){
            return {
              ...child,
              custSrcId           : values.custSrcId,
              custName            : values.custName,
              custAddress1        : values.address1,
              custAddress2        : values.address2,
              custCountry         : values.country,
              custCity            : values.city,
              custState           : values.state,
              custPostalCode      : values.zipCode,
              custPhone           : values.phoneNumber,
              custDescription     : values.description,
              parentCustSrcId     : values.parentCustSrcId,
              parentCustName      : values.parentCustName,
              parentARCustomerId  : values.parentARCustomerId,
              upcParentCustSrcId  : values.upcParentCustSrcId,
              upcParentCustName   : values.upcParentCustName,
              upcParentCustId     : values.upcParentCustId
            }
          }return child
        })
      }
    }))

    props.setFilteredParentCustomers(props.filteredParentCustomer?.map(parent => {
      return {
        ...parent,
        children: parent.children?.map(child => {
          if(child.recordId === props.item.recordId){
            return {
              ...child,
              custSrcId         : values.custSrcId,
              custName          : values.custName,
              custAddress1      : values.address1,
              custAddress2      : values.address2,
              custCountry       : values.country,
              custCity          : values.city,
              custState         : values.state,
              custPostalCode    : values.zipCode,
              custPhone         : values.phoneNumber,
              custDescription   : values.description,
              parentCustSrcId   : values.parentCustSrcId,
              parentCustName    : values.parentCustName,
              parentARCustomerId: values.parentARCustomerId,
              upcParentCustSrcId  : values.upcParentCustSrcId,
              upcParentCustName   : values.upcParentCustName,
              upcParentCustId     : values.upcParentCustId
            }
          }return child
        })
      }
    }))

    props.getAllCustomers();
  };

  /**
   * This function checks if the component should have a dark or light background color.
   * 
   * @param index The index of the component
   * @returns A boolean that determine if the component's index is odd or not.
   */  
  const isOdd = (index: number) => index%2!==0;

  return (
    <>
      <ListItem
        sx={{ bgcolor: isOdd(props.index) ? 'background.paper' : '#F7F7F7' }}
        key={props.index}
      >
          <Box>
            <Box sx={styles.newTagBox}>
              { props.item.isNew && (
                <Typography><NewTag expanding /></Typography>
              )}
            </Box>
          </Box>
          <Box sx={{...styles.halfWidth, pl: '1.55rem'}}>
            <ListItemText tabIndex={0} id={`${props.item.recordId}`} primary={props.item.custName}/>
          </Box>
          <Box tabIndex={0} sx={styles.halfWidth}>
            { props.item?.custSrcId ?? '-'}
          </Box>
          <Box sx={{...styles.smallWidth, ...(!canViewParentInformation && styles.invisible)}}>
            <Tooltip title='View Customer Details'>
              <IconButton size='small' onClick={handleOpenModal}>
                <PreviewIcon sx={styles.iconAction} />
              </IconButton>
            </Tooltip>
          </Box>
      </ListItem>
      <CustomerModal 
        open={open}
        setOpen={setOpen}
        customer={props.item}
        customerList={props.arCustomerList}
        handleSuccess={handleSuccess}
        handleFailed={handleError}
        onSettings
      />
    </>
  )
}

export default CustomerRow