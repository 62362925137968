import React, { useEffect, useState, useContext, useRef, FC } from 'react';
import { Formik, Form, Field, FieldArray, getIn, FormikProps, FormikErrors, FormikTouched } from 'formik';
import NumberFormat, { InputAttributes } from 'react-number-format';
import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, Backdrop, CircularProgress, FormLabel, Tooltip, AlertColor } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { API_DOMAIN, GET, NO_PERMISSION_MSG, PERMISSIONS, POST, PUT } from '../../../utility/constants';
import { checkUserPermissions, getLocalStorageItem, getPermissionsOfUser, isObjectsEqual, trimOnBlur } from '../../../utility/helper';
import { usePrompt } from '../../../utility/prompt';
import inventoryValuationSchema from '../../../schemas/inventoryWorksheetSchema';
import { InventoryWorksheetContext } from '../../../context/inventoryWorksheetContext';
import { NavigationWarningContext, INavigationWarningContext } from '../../../context/navigationWarningContext';
import { IInventoryWorksheetContext, IFormikValue, IInventoryWorksheet, IInventoryWorksheetAPI, INumberFormatProps, IResetFormForInvWorksheet } from '../../../interfaces/inventoryWorksheetInterface'
import Toaster from '../../toaster';
import ConfirmModal from '../../modals/confirm-modal';
import styles from './styles';
import { IInventoryWorksheetProps } from '../../../pages/inventory-worksheet';
import { formatDecimals } from '../../../pages/file-import/landing-page/table';
import DisabledComponentsContainer from '../../common/disabled-components-container';
import HelperTextComponent from '../../common/helper-text-component';
import axiosInstance from '../../../service/axiosInstance';

type SetFieldValue = (field: string, value: any) => Promise<void | FormikErrors<{
  inventoryWorksheets: IInventoryWorksheet[];
}>>;

type IFormikProps = FormikProps<{
  inventoryWorksheets: IInventoryWorksheet[];
}>;

const CurrencyFormat = React.forwardRef<NumberFormat<InputAttributes>, INumberFormatProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      type='tel'
      decimalScale={2}
      fixedDecimalScale={true}
      prefix='$'
    />
  );
});

const PercentFormat = React.forwardRef<NumberFormat<InputAttributes>, INumberFormatProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            name: other.name,
          },
        });
      }}
      thousandSeparator
      type='tel'
      decimalScale={2}
      fixedDecimalScale={true}
      suffix='%'
      isAllowed={values => values.floatValue === undefined || (values.floatValue >= -100.00 && values.floatValue <= 100.00)}
    />
  );
});

const InventoryWorksheetTable :FC<IInventoryWorksheetProps>= (props) => {
  const { path }                                  = props;
  const headers                                   = ['Inventory Type', 'Valuation Amount', 'Deduction Amount', 'Remaining', 'Value Rate', 'Inventory Value', 'Action'];
  const { isDirty, setIsDirty }                   = useContext(NavigationWarningContext) as INavigationWarningContext;
  const { selectedClient,
    selectedInventory,
    setTotalInventoryWorksheetAmount,
    isLoading,
    setIsLoading,
    inventoryWorksheets,
    setInventoryWorksheets,
    setCanViewInventoryWorksheet,
    canAddInventoryWorksheets,
    setCanAddInventoryWorksheets,
    canUpdateInventoryWorksheets,
    setCanUpdateInventoryWorksheets,
    canDeleteInventoryWorksheets,
    setCanDeleteInventoryWorksheets }             = useContext(InventoryWorksheetContext) as IInventoryWorksheetContext;
  const focusedInputRef                           = useRef<HTMLInputElement>(null);
  const [
    selectedInputHeaderName,
    setSelectedInputHeaderName
  ]                                               = useState('');
  const [allowAddingRow, setAllowAddingRow]       = useState(true);
  const [isToasterOpen, setIsToasterOpen]         = useState(false);
  const [toasterMessage, setToasterMessage]       = useState('');
  const [toasterSeverity, setToasterSeverity]     = useState<AlertColor>('success');
  const [deleteModal, setDeleteModal]             = useState({isOpen: false, isNew: false, deleteRecordId: '', deleteName: '', deleteIndex: -1, });
  const [formikValues, setFormikValues]           = useState<IFormikValue | null>(null);
  const [isFormikResetting, setIsFormikResetting] = useState(false);

  useEffect(() => {
    if (isLoading) { return; }
    !isFormikResetting && setIsFormikResetting(true);
    if (path === 'clients') {
      getPermissions();
    }
    fetchInventoryWorksheets();
  }, [selectedInventory]);

  useEffect(() => {
    if (selectedInputHeaderName === '') { return; }
    if (focusedInputRef) focusedInputRef.current?.focus();
    setSelectedInputHeaderName('');
  }, [selectedInputHeaderName]);

  useEffect(() => {
    if (formikValues === null || formikValues.inventoryWorksheets === undefined) { return; }
    const { savedFormikValues, emptyFormikValues } = getSavedAndEmptyFormikValues(formikValues.inventoryWorksheets);
    calcTotalAmount(savedFormikValues);
    emptyFormikValues.length > 0 ? setAllowAddingRow(false) : setAllowAddingRow(true);
  }, [formikValues]);

  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(getLocalStorageItem('uid'));
    setCanViewInventoryWorksheet(permissions.includes(PERMISSIONS.VIEW_INVENTORY_WORKSHEET))
    setCanAddInventoryWorksheets(permissions.includes(PERMISSIONS.ADD_INVENTORY_WORKSHEET))
    setCanUpdateInventoryWorksheets(permissions.includes(PERMISSIONS.UPDATE_INVENTORY_WORKSHEET))
    setCanDeleteInventoryWorksheets(permissions.includes(PERMISSIONS.DELETE_INVENTORY_WORKSHEET))
  }

  const fetchInventoryWorksheets = () => {
    if (selectedClient.recordId === undefined || selectedClient.recordId === -1) {
      setInventoryWorksheets([]);
      return;
    }
    if (selectedInventory.invCollateralFk === undefined || selectedInventory.invCollateralFk === -1) {
      setInventoryWorksheets([]);
      return;
    }
    setIsLoading(true);

    axiosInstance.request({
      url: `${API_DOMAIN}/inv/valuations/search/findByInvCollateralId?invCollateralId=${selectedInventory.invCollateralFk}&pageNo=0&pageSize=9999&sortBy=recordId,ASC`,
      method: GET,
    })
      .then((response) => {
        const { data } = response;
        const mappedInventoryWorksheets: IInventoryWorksheet[] = data.content
          .map((row: IInventoryWorksheetAPI) => {
          const valuationAmount = row.valuationAmount ? row.valuationAmount : 0;
          const deduction = row.deductions ? row.deductions : 0;
          const parsedInventoryWorksheet = {
            recordId: row.recordId ? row.recordId?.toString() : '',
            borrowerFk: row.borrowerFk,
            invCollateralFk: row.invCollateralFk,
            inventoryType: row.inventoryType,
            valuationAmount: row.valuationAmount?.toFixed(2),
            deductions: row.deductions?.toFixed(2),
            valueRate: row.valueRate?.toFixed(2),
            inventoryValue: row.inventoryValue?.toFixed(2),
            obsoleteAt: '',
            current: true,
            remaining: (parseFloat(valuationAmount.toString()) - parseFloat(deduction.toString())).toFixed(2),
          };
          return parsedInventoryWorksheet;
        });

        setIsDirty(false);
        setInventoryWorksheets(mappedInventoryWorksheets);
      })
      .catch((error) => console.log('INVENTORY WORKSHEETS GET ERROR: ', error))
      .finally(() => setIsLoading(false));
  };

  const deleteInventoryWorksheet = (recordId: string, isFetchingAfter?: boolean) => {
    const dateOfObsolete = new Date();
    const [deletedInventoryWorksheet] = inventoryWorksheets.filter(worksheet => worksheet.recordId === recordId);
    const deleteRequestConfig = {
      url: `${API_DOMAIN}/inv/valuations/${deletedInventoryWorksheet.recordId}`,
      method: PUT,
      data: {
        recordId: parseInt(deletedInventoryWorksheet.recordId),
        borrowerFk: deletedInventoryWorksheet.borrowerFk,
        invCollateralFk: deletedInventoryWorksheet.invCollateralFk,
        inventoryType: deletedInventoryWorksheet.inventoryType,
        valuationAmount: parseFloat(deletedInventoryWorksheet.valuationAmount),
        deductions: parseFloat(deletedInventoryWorksheet.deductions),
        valueRate: parseFloat(deletedInventoryWorksheet.valueRate),
        inventoryValue: parseFloat(deletedInventoryWorksheet.inventoryValue),
        current: false,
        obsoleteAt: dateOfObsolete.toJSON(),
      }
    };

    axiosInstance.request(deleteRequestConfig)
      .then((_response) => {
        const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
        setToasterMessage(`${itemName} has been deleted`);
        setToasterSeverity('success');
        setIsToasterOpen(true);
        if (isFetchingAfter) { fetchInventoryWorksheets(); }
      })
      .catch((error) => console.log('INVENTORY WORKSHEETS DELETE ERROR: ', error));
  };

  const calcTotalAmount = (inventoryWorksheets: IInventoryWorksheet[]) => {
    let total = 0;
    for (const item of inventoryWorksheets) {
      total += parseFloat(item.inventoryValue);
    }
    setTotalInventoryWorksheetAmount(total);
  };

  const calculateFields = (inventoryWorksheets: IInventoryWorksheet[], setFieldValue: SetFieldValue, index: number, event?: React.ChangeEvent<HTMLInputElement>) => {
    if (inventoryWorksheets[index].valuationAmount === '' && inventoryWorksheets[index].deductions === '') { return {}; }
    if (inventoryWorksheets[index].valueRate === '') { return {}; }

    const name = event?.target.name ?? '';
    const newValue = event?.target.value ?? '';
    const valuationAmount = name.includes('valuationAmount') ? newValue : inventoryWorksheets[index].valuationAmount;
    const deductions = name.includes('deductions') ? newValue : inventoryWorksheets[index].deductions;
    const valueRate = name.includes('valueRate') ? newValue : inventoryWorksheets[index].valueRate;

    const remainingAmount = (
      parseFloat(valuationAmount) -
      parseFloat(deductions)
    );
    
    const remaining = remainingAmount !== undefined ? remainingAmount?.toString() : '';
    const inventoryValue = remaining !== undefined ? (remainingAmount * (parseFloat(valueRate) / 100)).toString() : '';

    setFieldValue(`inventoryWorksheets[${index}].remaining`, remaining);
    setFieldValue(`inventoryWorksheets[${index}].inventoryValue`, inventoryValue);
    return {
      remaining, inventoryValue
    }
  }

  const calculateRows = (worksheetValues: IInventoryWorksheet[], setFieldValue: SetFieldValue) => {
    const newWorksheetValues = worksheetValues.map((worksheet, index) => {
      const { remaining, inventoryValue } = calculateFields(worksheetValues, setFieldValue, index);
      return {
        ...worksheet,
        remaining: remaining ?? '0',
        inventoryValue: inventoryValue ?? '0',
      };
    });

    return newWorksheetValues;
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>, handleChange: React.ChangeEventHandler<HTMLInputElement>, formik: IFormikProps, index: number) => {
    handleChange(event);
    calculateFields(formik.values.inventoryWorksheets, formik.setFieldValue, index, event);
  };

  const handleBlur = (event: React.FocusEvent, formik: IFormikProps, index: number, name: string, value?: string) => {
    trimOnBlur(event, formik, name, value);
  }

  const handleDelete = (values: IFormikValue, index: number) => {
    const isWorksheetToDeleteNew = values.inventoryWorksheets[index].recordId === undefined;
    if (isWorksheetToDeleteNew) {
      setDeleteModal({
        isOpen: true,
        isNew: isWorksheetToDeleteNew,
        deleteRecordId: '',
        deleteName: values.inventoryWorksheets[index].inventoryType,
        deleteIndex: index,
      });
      return;
    }
    setDeleteModal({
      isOpen: true,
      isNew: isWorksheetToDeleteNew,
      deleteRecordId: values.inventoryWorksheets[index].recordId,
      deleteName: values.inventoryWorksheets[index].inventoryType,
      deleteIndex: index,
    });
  };

  const handleConfirmDelete = async (formikValues: IInventoryWorksheet[], remove: (index: number) => void) => {
    const canDelete = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.DELETE_INVENTORY_WORKSHEET);
    if (path === 'clients') {
      if (canDelete) {
        handleNewDeleteModal(remove);
    
        const { hasUnsavedChanges, presentFormikValues } = getPresentFormikValues(formikValues);
        const handled = handlePresentFormikValues(hasUnsavedChanges, presentFormikValues);
        if (handled) return;
        setInventoryWorksheets(presentFormikValues);
      } else {
        setToasterMessage(NO_PERMISSION_MSG);
        setToasterSeverity('error');
        setIsToasterOpen(true);
      }
    } else {
      handleNewDeleteModal(remove);
      const { hasUnsavedChanges, presentFormikValues } = getPresentFormikValues(formikValues);
      const handled = handlePresentFormikValues(hasUnsavedChanges, presentFormikValues);
      if (handled) return;
      setInventoryWorksheets(presentFormikValues);
    }
  };

  const handleNewDeleteModal = (remove: (index: number) => void) => {
    if (deleteModal.isNew) {
      remove(deleteModal.deleteIndex);
      const itemName = deleteModal.deleteName ? deleteModal.deleteName : 'Item';
      setToasterMessage(`${itemName} has been deleted`);
      setToasterSeverity('success');
      setIsToasterOpen(true);
    }
  };

  const handlePresentFormikValues = (hasUnsavedChanges: boolean, presentFormikValues: IInventoryWorksheet[]) => {
    if (hasUnsavedChanges && deleteModal.isNew) {
      setFormikValues({ inventoryWorksheets: presentFormikValues });
      return true;
    }
    if (hasUnsavedChanges && !deleteModal.isNew) {
      deleteInventoryWorksheet(deleteModal.deleteRecordId);
      setFormikValues({ inventoryWorksheets: presentFormikValues });
      return true;
    }
    if (!hasUnsavedChanges && !deleteModal.isNew) {
      deleteInventoryWorksheet(deleteModal.deleteRecordId, true);
      setFormikValues({ inventoryWorksheets: presentFormikValues });
      return true;
    }
    return false;
  };

  const handleCancel = (resetForm: IResetFormForInvWorksheet) => {
    if (formikValues === null) { return; }
    const { savedFormikValues } = getSavedAndEmptyFormikValues(formikValues.inventoryWorksheets);
    setIsDirty(false);
    resetForm({ values: { inventoryWorksheets: savedFormikValues } });
    setInventoryWorksheets(savedFormikValues);
    setAllowAddingRow(true);
  };

  const handleSave = async (worksheetsToSave: IInventoryWorksheet[], setSubmitting: (isSubmitting: boolean) => void, setFieldValue: SetFieldValue) => { 
    setSubmitting(true);

    const calculatedWorksheets = calculateRows(worksheetsToSave, setFieldValue);

    const worksheetsToEdit = calculatedWorksheets
      .filter((worksheet) => {
        const isWorksheetNew = worksheet.hasOwnProperty('recordId');
        return isWorksheetNew;
      })
      .filter((worksheet) => {
        const [originalWorksheet] = inventoryWorksheets.filter(currentWorksheet => currentWorksheet.recordId === worksheet.recordId);
        if (originalWorksheet === undefined) { return false; }
        const isWorksheetNotEdited = !isObjectsEqual(worksheet, originalWorksheet);
        return isWorksheetNotEdited;
      });
    
      let addedName: string = '';
      let updatedName: string = '';

    // 1st part of update request (hide values to update)
    const dateOfObsolete = new Date();
    const editRequestPutConfigs = worksheetsToEdit.map(worksheet => 
      axiosInstance.request({
        url: `${API_DOMAIN}/inv/valuations/${worksheet.recordId}`,
        method: PUT,
        data: {
          recordId: parseInt(worksheet.recordId),
          borrowerFk: worksheet.borrowerFk,
          invCollateralFk: worksheet.invCollateralFk,
          inventoryType: worksheet.inventoryType,
          valuationAmount: parseFloat(worksheet.valuationAmount),
          deductions: parseFloat(worksheet.deductions),
          valueRate: parseFloat(worksheet.valueRate),
          inventoryValue: parseFloat(worksheet.inventoryValue),
          obsoleteAt: dateOfObsolete.toJSON(),
          current: false,
        }
      }));

    // 2nd part of update request (set isCurrent to true for updatedValues)
    const editRequestPostConfigs = worksheetsToEdit.map((worksheet, index) => {
      if(index === 0){
        updatedName = worksheet.inventoryType
      }

      return axiosInstance.request({
        url: `${API_DOMAIN}/inv/valuations`,
        method: POST,
        data: {
          borrowerFk: worksheet.borrowerFk,
          invCollateralFk: worksheet.invCollateralFk,
          inventoryType: worksheet.inventoryType,
          valuationAmount: parseFloat(worksheet.valuationAmount),
          deductions: parseFloat(worksheet.deductions),
          valueRate: parseFloat(worksheet.valueRate),
          inventoryValue: parseFloat(worksheet.inventoryValue),
          current: true,
        },
      });
    });

    // add new inventory worksheet
    const postRequestConfigs = calculatedWorksheets
      .filter(worksheet => !worksheet.hasOwnProperty('recordId'))
      .map((worksheet, index) => {
        if(index === 0){
          addedName = worksheet.inventoryType
        }

        return axiosInstance.request({
          url: `${API_DOMAIN}/inv/valuations`,
          method: POST,
          data: {
            borrowerFk: selectedClient.recordId,
            invCollateralFk: selectedInventory.invCollateralFk,
            inventoryType: worksheet.inventoryType,
            valuationAmount: parseFloat(worksheet.valuationAmount),
            deductions: parseFloat(worksheet.deductions),
            valueRate: parseFloat(worksheet.valueRate),
            inventoryValue: parseFloat(worksheet.inventoryValue),
            current: true,
          },
        });
      });

    const canEdit = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.UPDATE_INVENTORY_WORKSHEET);
    const canAdd = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.ADD_INVENTORY_WORKSHEET);

    const requestsConfigs = [...editRequestPutConfigs, ...editRequestPostConfigs, ...postRequestConfigs];
    const updatedToasterMessage = getUpdateToasterMessage(postRequestConfigs, editRequestPostConfigs, addedName, updatedName);
    if (path === 'clients') {
      if ((!canAdd && postRequestConfigs.length > 0) || (!canEdit && editRequestPostConfigs.length > 0)) {
        setToasterMessage(NO_PERMISSION_MSG);
        setToasterSeverity('error');
        setIsToasterOpen(true);
      } else {
        Promise.all(requestsConfigs)
        .then((_responses) => {
          setIsDirty(false);
          fetchInventoryWorksheets();
          setToasterMessage(updatedToasterMessage);
          setToasterSeverity('success');
          setIsToasterOpen(true);
          setSubmitting(false);
        })
        .catch((error) => console.log('INVENTORY WORKSHEET REQUESTS ERROR: ', error));
      }
    } else {
      Promise.all(requestsConfigs)
      .then((_responses) => {
        setIsDirty(false);
        fetchInventoryWorksheets();
        setToasterMessage(updatedToasterMessage);
        setToasterSeverity('success');
        setIsToasterOpen(true);
        setSubmitting(false);
      })
      .catch((error) => console.log('INVENTORY WORKSHEET REQUESTS ERROR: ', error));
    }
    
  };

  const getDisplayedError = (errors: FormikErrors<IFormikValue>, touched: FormikTouched<IFormikValue>, name: string, index: number) => {
    const fieldError = getIn(errors, `inventoryWorksheets[${index}].${name}`);
    const isFieldTouched = getIn(touched, `inventoryWorksheets[${index}].${name}`);

    if (fieldError && isFieldTouched) { return { error: true, helperText: <HelperTextComponent text={fieldError} /> }; }

    return null;
  };

  const addNewRow = (push: any) => {
    const newWorksheet = { inventoryType: '', valuationAmount: '', deductions: '', remaining: '', valueRate: '', inventoryValue: '' };
    push(newWorksheet);
    setAllowAddingRow(false);
  };

  const getUpdateToasterMessage = (addItems: any, updateItems: any, addedName: string, updatedName: string) => {
    const addLength = addItems.length;
    const updateLength = updateItems.length;
    if (addLength > 0 && updateLength > 0) {
      return 'Changes in Inventory Worksheet have been saved';
    }
    if (addLength > 0 && updateLength <= 0) {
      const phrase = addLength > 1 ? 'Items have been' : `${addedName} has been`;
      return `${phrase} added`;
    }
    if (addLength <= 0 && updateLength > 0) {
      const phrase = updateLength > 1 ? 'Items have been' : `${updatedName} has been`;
      return `${phrase} updated`;
    }
    return '';
  };

  const getPresentFormikValues = (formikValues: IInventoryWorksheet[]) => {
    let hasUnsavedChanges = false;
    const presentFormikValues = formikValues.filter((currentValue: IInventoryWorksheet, currentIndex: number) => {
      if (currentIndex === deleteModal.deleteIndex) { return false; }
      if (currentValue.recordId === deleteModal.deleteRecordId) { return false; }

      const hasNewRecord = currentValue.recordId === undefined;
      const hasUpdatedRecord = inventoryWorksheets.some(worksheet => {
        if (worksheet.recordId !== currentValue.recordId) { return false; }
        return !isObjectsEqual(currentValue, worksheet);
      });
      if (hasNewRecord || hasUpdatedRecord) { hasUnsavedChanges = true; }

      return true;
    });

    return { hasUnsavedChanges, presentFormikValues };
  };

  const getSavedAndEmptyFormikValues = (formikValuesForInvWorksheets: IInventoryWorksheet[]) => {
    const [savedFormikValues, emptyFormikValues] = formikValuesForInvWorksheets.reduce((separatedFormikValues: IInventoryWorksheet[][], worksheet: IInventoryWorksheet) => {
      let currentSavedFormikValues = [...separatedFormikValues[0]];
      let currentEmptyFormikValues = [...separatedFormikValues[1]];

      const isWorksheetSaved = worksheet.recordId !== undefined && worksheet.recordId !== '';
      if (isWorksheetSaved) {
        const [savedWorksheet] = inventoryWorksheets.filter(originalWorksheet => originalWorksheet.recordId === worksheet.recordId);
        savedWorksheet !== undefined && currentSavedFormikValues.push(savedWorksheet);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      const isWorksheetEmpty = (
        (worksheet.inventoryType === undefined || worksheet.inventoryType === '') &&
        (worksheet.valuationAmount === undefined || worksheet.valuationAmount === '') &&
        (worksheet.deductions === undefined || worksheet.deductions === '') &&
        (worksheet.valueRate === undefined || worksheet.valueRate === '')
      );
      if (isWorksheetEmpty) {
        currentEmptyFormikValues.push(worksheet);
        return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
      }

      return [[...currentSavedFormikValues], [...currentEmptyFormikValues]];
    }, [[], []]);

    return { savedFormikValues, emptyFormikValues };
  };

  const formikRef = (node: FormikProps<IFormikValue>) => {
    if (node === null || isLoading) { return; }
    setFormikValues(node.values);
    !isObjectsEqual(formikValues, node.values) && setIsDirty(node.dirty);
    if (!isFormikResetting) { return; }
    node.resetForm();
    setIsFormikResetting(false);
  };

  const showAddRow = () => {
    if (path === 'clients') {
      if (canAddInventoryWorksheets) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  const isFieldDisabled = (index: number) => {
    return (path === 'clients' && (formikValues?.inventoryWorksheets[index]?.recordId === undefined ? !canAddInventoryWorksheets : !canUpdateInventoryWorksheets));
  }

  const units: string[] = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
  const teens: string[] = ["", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
  const tens: string[] = ["", "", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
  const thousands: string[] = ["", "thousand", "million"];

  const threeDigitToWords = (num: number): string => {
    if (num === 0) return "";
    if (num < 10) return units[num];
    if (num < 20) return teens[num - 10];
    if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + units[num % 10] : "");
    if (num < 1000) {
        return units[Math.floor(num / 100)] + " hundred" + (num % 100 !== 0 ? " and " + threeDigitToWords(num % 100) : "");
    }
    return "";
  }

  const numberToWords = (n: number): string => {
    if (n === 0) return "zero";

    let wordRepresentation = "";
    let i = 0;
    while (n > 0) {
        if (n % 1000 !== 0) {
            const thousandsPart = i === 1 && n % 1000 >= 100 ? "ten" : threeDigitToWords(n % 1000);
            wordRepresentation = thousandsPart + (thousands[i] ? " " + thousands[i] : "") + " " + wordRepresentation;
        }
        n = Math.floor(n / 1000);
        i++;
    }
    return wordRepresentation.trim();
  }

  const numberToFullWords = (n: number): string => {
    const wholePart = Math.floor(n);
    const decimalPart = Math.round((n - wholePart) * 100);

    const wholeWords = numberToWords(wholePart);
    const decimalWords = formatDecimals(decimalPart.toString());

    return `${wholeWords} point ${decimalWords}`;
  }

  const getActionButtons = (formik: FormikProps<IFormikValue>) => {
    if (selectedInventory && selectedInventory.invCollateralName !== '') {
      return (
        <Box sx={styles.buttonsContainer}>
          {(formik.dirty) ? (
            <Button
              onClick={() => handleCancel(formik.resetForm)}
              variant='outlined'
              sx={styles.saveButton}
            >
              <Typography variant='body2' component='p'>
                Cancel
              </Typography>
            </Button>
          ) : null}
          <DisabledComponentsContainer isDisabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}>
            <Button
              disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading}
              aria-label={!(formik.isValid && formik.dirty) || formik.isSubmitting || isLoading ? 'Save button disabled' : 'Save'}
              variant='contained'
              sx={styles.saveButton}
              type='submit'
            >
              <Typography variant='body2' component='p'>
                Save
              </Typography>
            </Button>
          </DisabledComponentsContainer>
        </Box>
      )
    }
  }

  const getTabIndex = (index: number) => {
    return isFieldDisabled(index) ? 0 : null;
  }

  const handleKeyDownOnRows = (header: string, push: (obj: any) => void) => (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') { return; }
    setSelectedInputHeaderName(header);
    addNewRow(push);
  }

  usePrompt('You have unsaved changes. Are you sure you want to leave this page?', isDirty);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={{ inventoryWorksheets }}
      validationSchema={inventoryValuationSchema}
      onSubmit={(values, { setSubmitting, setFieldValue }) => handleSave(values.inventoryWorksheets, setSubmitting, setFieldValue)}
    >
      {
        formik => (
          <Form onSubmit={formik.handleSubmit}>
            <Box sx={styles.outmostContainer}>
              {
                selectedInventory && selectedInventory.invCollateralName !== '' ? (
                  <TableContainer sx={styles.tableContainer}>
                    <Table sx={styles.table}>
                      <TableHead>
                        <TableRow sx={{...styles.tableHeadRow}}>
                          <TableCell sx={{ ...styles.tableHeadCell }}>
                            <FormLabel
                              tabIndex={0}
                              htmlFor='inventory-type'
                              sx={styles.tableHeaderText}
                              >
                              Inventory Type<span style={styles.asterisk}> *</span>
                            </FormLabel>
                          </TableCell>
                          <TableCell sx={{ ...styles.tableHeadCell, ...styles.rightAlignedText, }} >
                            <FormLabel
                              tabIndex={0}
                              htmlFor='valuation-amount'
                              sx={styles.tableHeaderText}
                              >
                              Valuation Amount<span style={styles.asterisk}> *</span>
                            </FormLabel>
                          </TableCell>
                          <TableCell sx={{ ...styles.tableHeadCell, ...styles.rightAlignedText, }} >
                            <FormLabel
                              tabIndex={0}
                              htmlFor='deduction-amount'
                              sx={styles.tableHeaderText}
                              >
                              Deduction Amount<span style={styles.asterisk}> *</span>
                            </FormLabel>
                          </TableCell>
                          <TableCell sx={{ ...styles.tableHeadCell, ...styles.rightAlignedText, }} >
                            <FormLabel
                              tabIndex={0}
                              htmlFor='remaining'
                              sx={styles.tableHeaderText}
                              >
                              Remaining
                            </FormLabel>
                          </TableCell>
                          <TableCell sx={{ ...styles.tableHeadCell, ...styles.rightAlignedText, }} >
                            <FormLabel
                              tabIndex={0}
                              htmlFor='value-rate'
                              sx={styles.tableHeaderText}
                              >
                              Value Rate<span style={styles.asterisk}> *</span>
                            </FormLabel>
                          </TableCell>
                          <TableCell sx={{ ...styles.tableHeadCell, ...styles.rightAlignedText, }} >
                            <FormLabel
                              tabIndex={0}
                              htmlFor='inventory-value'
                              sx={styles.tableHeaderText}
                              >
                              Inventory Value
                            </FormLabel>
                          </TableCell>
                          <TableCell sx={{ ...styles.tableHeadCell, ...styles.centerAlignedText, ...((!canDeleteInventoryWorksheets && path === 'clients') && styles.hidden) }}>
                            <FormLabel tabIndex={0} sx={styles.tableHeaderText} >
                              Action
                            </FormLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <FieldArray name='inventoryWorksheets'>
                        {({ push, remove }) => (
                          <TableBody sx={styles.tableBody}>
                            {formik.values.inventoryWorksheets.map((invWorksheet, index) => {
                              return (
                                <TableRow sx={styles.tableRow} key={invWorksheet.recordId}>
                                  <TableCell sx={styles.tableCell}>
                                    <Field
                                      disabled={isFieldDisabled(index)}
                                      tabIndex={getTabIndex(index)}
                                      inputRef={selectedInputHeaderName === 'Inventory Type' && focusedInputRef}
                                      id='inventory-type'
                                      aria-label={`Inventory Type ${formik.values.inventoryWorksheets[index].inventoryType} `}
                                      name={`inventoryWorksheets[${index}].inventoryType`}
                                      as={TextField}
                                      sx={styles.textField}
                                      variant='outlined'
                                      inputProps={{ 'aria-label': 'Inventory Type' }}
                                      {...getDisplayedError(formik.errors, formik.touched, 'inventoryType', index)}
                                      onBlur={(event: any) => handleBlur(event, formik, index, `inventoryWorksheets[${index}].inventoryType`, formik.values.inventoryWorksheets[index].inventoryType)}
                                    />
                                  </TableCell>
                                  <TableCell sx={styles.tableCell}>
                                    <Field
                                      disabled={isFieldDisabled(index)}
                                      tabIndex={getTabIndex(index)}
                                      inputRef={selectedInputHeaderName === 'Valuation Amount' && focusedInputRef}
                                      id='valuation-amount'
                                      aria-label={`Valuation Amount ${formik.values.inventoryWorksheets[index].valuationAmount} `}
                                      name={`inventoryWorksheets[${index}].valuationAmount`}
                                      as={TextField}
                                      sx={{ ...styles.textField, ...styles.rightAlignedText }}
                                      variant='outlined'
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(event, formik.handleChange, formik, index)}
                                      onBlur={(event: React.FocusEvent) => handleBlur(event, formik, index, `inventoryWorksheets[${index}].valuationAmount`, formik.values.inventoryWorksheets[index].valuationAmount)}
                                      InputProps={{ inputComponent: CurrencyFormat as any }}
                                      inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Valuation Amount' }}
                                      {...getDisplayedError(formik.errors, formik.touched, 'valuationAmount', index)}
                                    />
                                  </TableCell>
                                  <TableCell sx={styles.tableCell}>
                                    <Field
                                      disabled={isFieldDisabled(index)}
                                      tabIndex={getTabIndex(index)}
                                      inputRef={selectedInputHeaderName === 'Deduction Amount' && focusedInputRef}
                                      id='deduction-amount'
                                      aria-label={`Deduction Amount ${formik.values.inventoryWorksheets[index].deductions}`}
                                      name={`inventoryWorksheets[${index}].deductions`}
                                      as={TextField}
                                      sx={{ ...styles.textField, ...styles.rightAlignedText }}
                                      variant='outlined'
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(event, formik.handleChange, formik, index)}
                                      onBlur={(event: React.FocusEvent) => handleBlur(event, formik, index, `inventoryWorksheets[${index}].deductions`, formik.values.inventoryWorksheets[index].deductions)}
                                      InputProps={{ inputComponent: CurrencyFormat as any }}
                                      inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Deduction Amount' }}
                                      {...getDisplayedError(formik.errors, formik.touched, 'deductions', index)}
                                    />
                                  </TableCell>
                                  <TableCell sx={styles.tableCell}>
                                    <Field
                                      inputRef={selectedInputHeaderName === 'Remaining' && focusedInputRef}
                                      id='remaining'
                                      aria-valuetext={`Remaining ${numberToFullWords(Number(formik.values.inventoryWorksheets[index].remaining))}`}
                                      name={`inventoryWorksheets[${index}].remaining`}
                                      as={TextField}
                                      sx={{ ...styles.textField, ...styles.rightAlignedText }}
                                      tabIndex={0}
                                      disabled
                                      variant='outlined'
                                      InputProps={{ inputComponent: CurrencyFormat as any }}
                                      inputProps={{ sx: styles.rightAlignedText, 'aria-label': `Remaining ${formik.values.inventoryWorksheets[index].remaining}` }}
                                      {...getDisplayedError(formik.errors, formik.touched, 'remaining', index)}
                                    />
                                  </TableCell>
                                  <TableCell sx={styles.tableCell}>
                                    <Field
                                      disabled={isFieldDisabled(index)}
                                      tabIndex={getTabIndex(index)}
                                      inputRef={selectedInputHeaderName === 'Value Rate' && focusedInputRef}
                                      id='value-rate'
                                      aria-label={`Value Rate ${formik.values.inventoryWorksheets[index].valueRate}`}
                                      name={`inventoryWorksheets[${index}].valueRate`}
                                      as={TextField}
                                      sx={{ ...styles.textField, ...styles.rightAlignedText }}
                                      variant='outlined'
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFieldChange(event, formik.handleChange, formik, index)}
                                      onBlur={(event: React.FocusEvent) => handleBlur(event, formik, index, `inventoryWorksheets[${index}].valueRate`, formik.values.inventoryWorksheets[index].valueRate)}
                                      InputProps={{ inputComponent: PercentFormat as any }}
                                      inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Value Rate' }}
                                      {...getDisplayedError(formik.errors, formik.touched, 'valueRate', index)}
                                    />
                                  </TableCell>
                                  <TableCell sx={styles.tableCell}>
                                    <Field
                                      inputRef={selectedInputHeaderName === 'Inventory Value' && focusedInputRef}
                                      id='inventory-value'
                                      aria-label={`Inventory Value $ ${formik.values.inventoryWorksheets[index].inventoryValue} `}
                                      name={`inventoryWorksheets[${index}].inventoryValue`}
                                      as={TextField}
                                      sx={{ ...styles.textField, ...styles.rightAlignedText }}
                                      tabIndex={0}
                                      disabled
                                      variant='outlined'
                                      InputProps={{ inputComponent: CurrencyFormat as any }}
                                      inputProps={{ sx: styles.rightAlignedText, 'aria-label': 'Inventory Value' }}
                                      {...getDisplayedError(formik.errors, formik.touched, 'inventoryValue', index)}
                                    />
                                  </TableCell>
                                  <TableCell align='center' sx={{...styles.actionTableCell, ...((!canDeleteInventoryWorksheets && path ==='clients') && styles.hidden)}}>
                                    <Tooltip title='Delete the item'>
                                      <IconButton
                                        onClick={() => handleDelete(formik.values, index)}
                                        sx={styles.deleteIconButton}
                                        color='primary'
                                        aria-label='Delete icon'
                                      >
                                        <DeleteOutlined />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                            {/* rows that adds new form rows */}
                            {selectedInventory && selectedInventory.invCollateralName !== '' && allowAddingRow && showAddRow() && (
                              <TableRow key={formik.values.inventoryWorksheets.length}>
                                {headers.map((header, index) => (
                                  <TableCell sx={styles.tableCell} key={header}>
                                    {index < headers.length - 1 ? (
                                      <TextField
                                        sx={{ ...styles.textField}}
                                        value=''
                                        onClick={() => {
                                          setSelectedInputHeaderName(header);
                                          addNewRow(push);
                                        }}
                                        onFocus={() => {
                                          setSelectedInputHeaderName(header);
                                          addNewRow(push);
                                        }}
                                        onKeyDown={handleKeyDownOnRows(header, push)}
                                        inputProps={{ 'aria-label': 'Press enter to add new entry' }}
                                      />
                                    ) : null}
                                  </TableCell>
                                ))}
                              </TableRow>
                            )}
                            <ConfirmModal
                              open={deleteModal.isOpen}
                              onClose={() => { setDeleteModal({ ...deleteModal, isOpen: false })} }
                              onConfirm={() => { handleConfirmDelete(formik.values.inventoryWorksheets, remove)} }
                              title={`Delete ${deleteModal.deleteName}`}
                              description='Are you sure you want to delete this item?'
                              errorButton
                              yesButtonText='Delete'
                              noButtonText='Cancel'
                            />
                          </TableBody>
                        )}
                      </FieldArray>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography tabIndex={0} sx={styles.centerAlignedText}>No data available</Typography>
                )
              }
              {getActionButtons(formik)}
            </Box>
            <Toaster
              open={isToasterOpen}
              message={toasterMessage}
              severity={toasterSeverity}
              onCloseChange={() => setIsToasterOpen(false)}
            />
            <Backdrop
              sx={styles.loadingBackdrop}
              open={isLoading}
            >
              <CircularProgress sx={styles.loadingIcon} />
            </Backdrop>
          </Form>
        )
      }
    </Formik>
  );
};

export default InventoryWorksheetTable;
