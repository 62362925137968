import { Box, Card, Typography } from "@mui/material";
import styles from "./styles";
import { useContext } from "react";
import { APAgingReportContext } from "../../../../../context/apAgingReportContext";
import { IAPAgingReportContext } from "../../../../../interfaces/apAgingReportInterface";
import { SelectedClientContext } from "../../../../../context/selectedClientContext";
import { formatDate } from "../../../../../utility/helper";
import { ICurrency, IRate } from "../../../../../interfaces/multiCurrencyInterface";
import { IARCollateral, IClient } from "../../../../../interfaces";
import { IAccountsReceivableSetting } from "../../../../../components/client-settings/tabs/tab-panel-contents/accounts-receivable";
import { isSelectedClientUPC } from "..";

export interface IAPAgingReportHeaderProps {
  childClient?: IClient
  selectedExchangeRate: IRate | null;
}

const APAgingReportHeader: React.FC<IAPAgingReportHeaderProps> = (props) => {
  const { childClient, selectedExchangeRate  } = props;
  const { selectedClient } = useContext(SelectedClientContext);
  const { selectedCollateral, selectedAsOfDate, arCollaterals, currencies, hasGeneratedReport }  = useContext(APAgingReportContext) as IAPAgingReportContext;

  const getReportHeader = () => {
    const clientNameText = (isSelectedClientUPC(selectedClient) ? childClient : selectedClient)?.borrowerName ?? '';
    const asOfDateText = selectedAsOfDate !== null ? `As of ${formatDate(selectedAsOfDate.endDate, 'MMMM DD, YYYY')}` : '';
    return (
      <Card sx={styles.reportHeader}>
        <Typography tabIndex={0} variant='h6' sx={styles.reportTitleText}> { clientNameText } </Typography>
        <Typography tabIndex={0} variant='h6' sx={styles.reportTitleText}> AP Aging Report </Typography>
        <Typography tabIndex={0} variant='h6' sx={styles.reportTitleSubText}> { asOfDateText } </Typography>
      </Card>
    );
  };

  const getExchangeRateContainer = () => {
    if ([selectedClient, selectedExchangeRate].some(value => value === null)) { return <></>; }
    const nonNullSelectedClient = selectedClient as Required<IClient>;
    const arSetting = arCollaterals.find(arCollateral => arCollateral.arCollateral.recordId === (selectedCollateral as Required<IARCollateral>).recordId) as IAccountsReceivableSetting;
    if (arSetting === undefined) { return <></>; }
    const nonNullSelectedExchangeRate = selectedExchangeRate as IRate;
    const clientCurrency = currencies.find(currency => currency.recordId.toString() === nonNullSelectedClient.currencyId) as ICurrency;
    const collateralCurrency = currencies.find(currency => currency.recordId.toString() === arSetting.arBorrowerInput.currency) as ICurrency;
    if (clientCurrency === undefined || collateralCurrency === undefined) { return <></>; }
    return (
      <Box sx={styles.reportHeaderSubContainer}>
        <Typography sx={styles.exchangeRateText}>
          <Box component="span" fontWeight='bold' fontSize={14}>Exchange Rate </Box>
          <Box component="span" fontSize={14}>{`${nonNullSelectedExchangeRate.currencyRate} ${collateralCurrency.currencyCode} = 1 ${clientCurrency.currencyCode}`}</Box>
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ ...styles.reportHeaderContainer, ...(isSelectedClientUPC(selectedClient) && !hasGeneratedReport && styles.hidden ) }}>
      { getReportHeader() }
      { getExchangeRateContainer() }
    </Box>
  );
};

export default APAgingReportHeader;