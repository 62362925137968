import { useRef, useCallback, useContext, useEffect, useState, FC, ChangeEvent, useMemo } from "react";
import { Box, Button, CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import Dropzone from 'react-dropzone'
import styles from '../styles'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import axiosInstance from "../../../../../../service/axiosInstance";
import { DELETE, DOCTYPE, FILE_SIZE_LIMIT, GET, POST, PUT } from "../../../../../../utility/constants";
import dayjs from "dayjs";
import { formatFileSize, getFileExtension, getSheetNames, getUniqueKeys } from "../../../../../../utility/helper";
import { IUploadTabProps } from "..";
import ConfirmModal from "../../../../../../components/modals/confirm-modal";
import images from "../../../../../../theme/images";
import { FileImportContext, IFileImportContext } from "../../../../../../context/fileImportContext";
import { IUploadedFile } from "../../../../../../interfaces/fileimport";
import FileRow from "./file-row";
import { bbPeriodAPI, fileImportAPI } from "../../../../../../service/api";
import { IBBPeriod } from "../../../../../../interfaces";
import DisabledComponentsContainer from "../../../../../../components/common/disabled-components-container";

export interface IUploadingSectionProps extends IUploadTabProps {
  refreshAsOfDates: () => Promise<void>;
}

type FileOrigin = 'browse' | 'drop';

const acceptedFileTypes = {
  csv : '.csv',
  xls : '.xls',
  xlsx: '.xlsx',
};

const needsChecking = ['AR Aging', 'AP Aging', 'GL Transaction'];

/**
 * Component for the Uploading Section of the Upload Step.
 * @param props The props for the Uploading Section of the Upload Step.
 * @returns The component for the Uploading Section of the Upload Step.
 */
const UploadingSection: FC<IUploadingSectionProps> = (props) => {
  const { selectedDocumentType, setSelectedDocumentType,
          selectedARCollateral, setSelectedARCollateral,
          selectedBBPeriod, setSelectedBBPeriod,
          setSelectedDates, refreshAsOfDates }          = props;
  const { 
    selectedClient, hasLoadingRecent,
    recentUploadedFiles,
    uploadedFiles, setUploadedFiles
  }                                                     = useContext(FileImportContext) as IFileImportContext;
  const [uploadError, setUploadError]                   = useState<string>('');
  const [preventOnDrop, setPreventOnDrop]               = useState(true);
  const [confirmModal, setConfirmModal]                 = useState<boolean>(false);
  const [checking, setChecking]                         = useState<boolean>(false);
  const [hasExisting, setHasExisting]                   = useState<false | IUploadedFile[] | undefined>(false);
  const [hasPrevious, setHasPrevious]                   = useState<boolean>(false);
  const [fileOrigin, setFileOrigin]                     = useState<FileOrigin>('browse');
  const [files, setFiles]                               = useState<File[]>([]);
  const [fileAction, setFileAction]                     = useState<'append' | 'replace' | ''>('');
  const isUltimateParent                                = useMemo(() => Boolean(selectedClient?.parentClient), [selectedClient]);
  
  /**
   * This useEffect hook checks if it is allowed to drop files in the uploading section.
   * This is varying based on the document type selected.
   */
  useEffect(() => {
    const selectedDocType = Boolean(selectedClient && selectedDocumentType);
    const selectedAll = Boolean(selectedClient && selectedDocumentType && selectedARCollateral);
    const selectedAllWithBBPeriod = Boolean(selectedClient && selectedDocumentType && selectedARCollateral && selectedBBPeriod);
    const doctypeName = selectedDocumentType?.name;
    const isUserDefinedDocType = doctypeName && !Object.values(DOCTYPE).includes(doctypeName);

    const areSelectionsComplete = {
      'AR Aging': selectedAllWithBBPeriod,
      'AP Aging': selectedAllWithBBPeriod,
      'Customer List': isUltimateParent ? selectedDocType : selectedAll,
      'Vendor List': isUltimateParent ? selectedDocType : selectedAll,
      'GL Transaction': selectedAllWithBBPeriod,
    }

    const enabled = isUserDefinedDocType ? selectedAllWithBBPeriod : areSelectionsComplete[doctypeName ?? ''];
    setPreventOnDrop(!enabled || hasLoadingRecent);
  }, [selectedClient, selectedDocumentType, selectedARCollateral, selectedBBPeriod, hasLoadingRecent, isUltimateParent]);

  /**
   * This function handles the click of the Browse Button on the Uploading Section.
   * @param documentType The document type of the current file.
   * @returns A promise for handling the browse files.
   */
  const handleBrowseClick = (documentType: string) => async () => {
    setFileAction('');
    setHasExisting(false);
    setHasPrevious(false);
    setFileOrigin('browse');
    if (needsChecking.includes(documentType)) {
      setChecking(true);
      const isExisting = await checkIfExisting(documentType);
      setChecking(false);
      if (!isExisting) handleBrowse();
    } else {
      handleBrowse();
    }
  };
  
  /**
   * This function checks if the there are existing files with the same document type on the server.
   * Applicable only for AR Aging, AP Aging, and GL Transaction as of the moment.
   * Triggers a modal of choosing to append or replace when there is an existing file.
   * @param documentType The document type of the current file.
   * @returns A boolean value if there is an existing file with the same document type.
   */
  const checkIfExisting = async (documentType: string) => {
    const hasFiles: IUploadedFile[] | undefined = checkFileExistence(
      documentType,
      selectedARCollateral?.recordId,
      selectedBBPeriod?.recordId,
    );
    const hasDuplicates: boolean = await checkDuplicateTransactions(
      documentType,
      selectedClient?.recordId,
      selectedARCollateral?.recordId,
      selectedBBPeriod?.recordId,
    );
    setHasPrevious(hasDuplicates);

    if (hasDuplicates || hasFiles?.length) {
      setHasExisting(hasFiles);
      setConfirmModal(true);
    } else {
      setHasExisting(false);
    }

    return Boolean(hasDuplicates || hasFiles?.length);
  };

  /**
   * This function checks existing files with the same document type on the current state of the uploadedFiles.
   * @param arCollateralId The AR Collateral record id of the current file.
   * @param bbPeriodId The As of Date record id of the current file.
   * @param documentType The document type of the current file.
   * @returns 
   */
  const checkFileExistence = (documentType: string, arCollateralId?: number, bbPeriodId?: number) => {
    const existence = uploadedFiles.filter((uploadedFile: IUploadedFile) =>
      uploadedFile.documentType === documentType &&
      uploadedFile.arCollateralFk === arCollateralId &&
      uploadedFile.bbPeriodFk === bbPeriodId
    );

    if (existence.length) return existence;
    else return undefined;
  };

  /**
   * This function asynchronously checks if there are previous transactions
   * under the same AR Collateral and As of Date on the server.
   * Applicable only for AR Aging, AP Aging, and GL Transaction as of the moment.
   * @param borrowerId The Borrower record id of the current file.
   * @param arCollateralId The AR Collateral record id of the current file.
   * @param bbPeriodId The As of Date record id of the current file.
   * @param documentType The document type of the current file.
   * @returns A promise containing a boolean value of the checking.
   */
  const checkDuplicateTransactions = async (documentType: typeof DOCTYPE[keyof typeof DOCTYPE], borrowerId?: number, arCollateralId?: number, bbPeriodId?: number) => {
    try {
      const dupesEndpts = {
        'AR Aging': fileImportAPI.transactions.HAS_DUPLICATE_AR,
        'AP Aging': fileImportAPI.transactions.HAS_DUPLICATE_AP,
        'GL Transaction': fileImportAPI.transactions.HAS_DUPLICATE_GL
      }

      const response = await axiosInstance.request({
        url: dupesEndpts[documentType],
        method: GET,
        params: { borrowerId, arCollateralId, bbPeriodId }
      })
  
      return response.data;
    } catch (error) {
      console.log(`DUPLICATE ${documentType.toUpperCase()} TRANSACTIONS ERROR: `, error);
    }
  };

  /**
   * A ref for accessing and interacting with an HTML input element.
   */
  const fileRef = useRef<HTMLInputElement>(null);

  /**
   * This function opens the file input dialog by programmatically clicking the associated input element.
   * This also resets the file action whenever to upload a new file
   */
  const handleBrowse = () => {
    fileRef.current?.click();
  };

  /**
   * This function clears any upload error message and resets the file input value to an empty string and
   * is used to cancel or reset a file upload operation.
   */
  const handleCancel = useCallback((event: any) => {
    event.stopPropagation();
    setUploadError('');
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  }, []);

  /**
   * This function takes an array of files, converts them to an appropriate format, and initiates the upload process.
   * @param files An array of files to be uploaded.
   */
  const handleSubmit = async (files: File[]) => {
    const filesWithError = files.some((file: File) => {
      const error: string = validateFile(file);
      setUploadError(error);
      return error.length;
    });

    if (!filesWithError) {
      const filesToUpload: IUploadedFile[] = files
        .filter((file: File) => {
          const fileExt = getFileExtension(file.name);
          return Object.keys(acceptedFileTypes).includes(fileExt);
        })
        .filter((file: File) => {
          const error: string = validateFile(file);
          return !error.length;
        })
        .map(convertFileToInitialDocument);
      const addedFilesToUpload: IUploadedFile[] = [...uploadedFiles, ...filesToUpload];
      setUploadedFiles(addedFilesToUpload);
  
      const promises = mapUploadPromises(filesToUpload, addedFilesToUpload);
      await Promise.all(promises);
      resetDropdowns();
    }
  };

  /**
   * Converts a File object to an initial document representation for uploading.
   * @param file The File object to convert.
   * @returns An object representing the initial document for uploading.
   */
  const convertFileToInitialDocument = (file: File) => {
    const uploadFile: IUploadedFile = {
      file,
      recordId          : 0,
      borrowerFk        : selectedClient?.recordId ?? 0,
      documentTypeFk    : selectedDocumentType?.recordId ?? 0,
      documentType      : selectedDocumentType?.name,
      arCollateralFk    : isUltimateParent ? undefined : selectedARCollateral?.recordId ?? 0,
      arCollateralName  : isUltimateParent ? undefined : selectedARCollateral?.label,
      endDate           : selectedBBPeriod?.label,
      filename          : file.name,
      processed         : false,
      mapped            : false,
      archive           : false,
      shouldReplace     : fileAction === 'replace',
      isLoading         : true,
      isNewlyUploaded   : true,
      isUploaded        : false,
      hasPrevious       : hasPrevious,
    }
    return uploadFile;
  };

  /**
   * This function takes an array of files to upload and maps each file to an asynchronous
   * function that performs file validation and other related actions.
   * @param filesToUpload An array of files to upload.
   * @param addedFilesToUpload An array of previously uploaded files.
   * @returns An array of Promises representing the upload operations.
   */
  const mapUploadPromises = (filesToUpload: IUploadedFile[], addedFilesToUpload: IUploadedFile[]) => {
    return filesToUpload.map(async (uploadFile: IUploadedFile) => {
      const finished: IUploadedFile | undefined = await checkBbPeriod(uploadFile);
      if (finished) {
        const updated: IUploadedFile[] = updateUploadedFiles(addedFilesToUpload, finished);

        if (finished.shouldReplace && Array.isArray(hasExisting)) {
          await deleteUploadedFiles(updated, hasExisting);
        }
      }
    });
  };

  /**
   * This function checks if the provided File object's size exceeds the specified file size limit.
   * @param file The File object to validate.
   * @returns An error message if the file size exceeds the limit; otherwise, an empty string.
   */
  const validateFile = (file?: File) => {
    if (file && file.size > FILE_SIZE_LIMIT) {
      return `File (${formatFileSize(file.size, 2)}) is too large. Maximum is ${formatFileSize(FILE_SIZE_LIMIT, 2)}.`;
    };
    return '';
  };
  
  /**
   * This function checks the selected period and performs document upload based on various conditions.
   * @param uploadFile The file to upload and associate with the BB period.
   * @returns A Promise that resolves with the uploaded file information, or null if no upload is performed.
   */
  const checkBbPeriod = async (uploadFile: IUploadedFile) => {
    if (selectedBBPeriod?.recordId !== -1 && selectedBBPeriod?.recordId !== undefined) {
      uploadFile.bbPeriodFk = selectedBBPeriod?.recordId;
      return await uploadDocument(uploadFile)
    } else if (selectedBBPeriod?.recordId === -1 && selectedClient?.recordId !== undefined) {
      const date = new Date(selectedBBPeriod?.label);
      const endDate =  dayjs(date).format('YYYY-MM-DD');
      const fiscalYear = date.getFullYear();
      const fiscalPeriod = date.getMonth()+1;
      const periodName =  dayjs(date).format('YYYY-MM');

      const bbPeriod : IBBPeriod = {
        endDate     : endDate,
        fiscalYear  : fiscalYear,
        fiscalPeriod: fiscalPeriod,
        periodName  : periodName,
        borrowerId  : selectedClient?.recordId
      }

      const responseBbPeriod = await createBbPeriod(bbPeriod);
      if (responseBbPeriod) {
        uploadFile.bbPeriodFk = responseBbPeriod.recordId;
        return await uploadDocument(uploadFile);
      }
    } else {
      return await uploadDocument(uploadFile)
    }
	}

  /**
   * This function sends a request to create a new BB period with the provided data.
   * @param bbPeriod The data for the BB period to be created.
   * @returns A Promise that resolves with the created BB period data.
   */
  const createBbPeriod = async (bbPeriod: IBBPeriod) => {
    try {
      const response = await axiosInstance.request({
        url: bbPeriodAPI.MAIN_ENDPOINT,
        method: POST,
        data: bbPeriod
      })

      return response.data;
    } catch (error) {
      console.log('CREATE BB PERIOD ERROR: ', error);
    }
  };
  
  /**
   * This function sends a request to upload a document, associates it with provided data, and
   * performs additional processing for Excel files.
   * @param uploadFile The file to be uploaded and associated with data.
   * @returns A Promise that resolves with the uploaded file information.
   */
  const uploadDocument = async (uploadFile: IUploadedFile) => {
		try {
      const payload = {
        file          : uploadFile.file,
        filename      : uploadFile.filename,
        borrowerFk    : uploadFile.borrowerFk,
        documentTypeFk: uploadFile.documentTypeFk,
        shouldReplace : uploadFile.shouldReplace,
        hasPrevious   : uploadFile.hasPrevious,
        arCollateralFk: uploadFile.arCollateralFk,
        bbPeriodFk    : uploadFile.bbPeriodFk,
      }

      const response = await axiosInstance.request({
        url: fileImportAPI.uploadFile.UPLOAD_FILE,
        method: POST,
        data: payload,
        headers: { 'content-type': 'multipart/form-data' }
      });
      const uploaded = response.data;

      uploadFile.recordId = uploaded.recordId;
      uploadFile.createdAt = uploaded.createdAt;
      uploadFile.updatedAt = uploaded.updatedAt;
      uploadFile.isUploaded = true;

      // Setting the sheet names for the document is separated from upload because having arrays in the multipart/form-data pose errors
      // Even when stringifying the array, non-desirable results maybe produced because sheet names of excel files allows quotation marks and commas
      const fileExtension = getFileExtension(response.data.filename ?? "");
      if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        uploadFile.isExcelFile = true;
        await getSheetNames(uploadFile)?.then(async (result: string[]) => {
          const updateSheetNamesResponse = await axiosInstance.request({
            url: `${fileImportAPI.document.MAIN_ENDPOINT}/${response.data.recordId}`,
            method: PUT,
            data: { ...response.data, sheetNames: result }
          });  

          uploadFile.sheetNames = updateSheetNamesResponse.data.sheetNames;
        });
      }
		} catch (error) {
      uploadFile.isUploaded = false;
			console.log('ERROR IN UPLOADING A FILE: ', error);
		} finally {
      uploadFile.isLoading = false;
    }

    return uploadFile;
	}

  /**
   * This function deletes existing uploaded files and updates the list of uploaded files
   * based on the provided arrays of updated and existing files.
   * @param updated An array of updated uploaded files.
   * @param hasExisting An array of existing uploaded files to be deleted.
   * @returns A Promise that resolves when the deletion process is complete.
   */
  const deleteUploadedFiles = async (updated: IUploadedFile[], hasExisting: IUploadedFile[]) => {
		try {
      const preparedFiles = hasExisting.map((uploaded: IUploadedFile) => {
        uploaded.isOnDeletion = true;
        return uploaded;
      });
      const loading = updated.map((uploaded: IUploadedFile) => {
        const toBeDeleted = preparedFiles.find((prepared: IUploadedFile) => prepared.recordId === uploaded.recordId);
        if (toBeDeleted) return toBeDeleted;
        else return uploaded;
      });
      setUploadedFiles(loading);

      const promises = hasExisting.map(async (existingDocument: IUploadedFile) => {
        const response = await axiosInstance.request({
          url: `${fileImportAPI.document.MAIN_ENDPOINT}/${existingDocument.recordId}`,
          method: DELETE,
        });
          
        if (response.status === 200) {
          return existingDocument.recordId;
        }
      });

      const deletedIds: (number | undefined)[] = await Promise.all(promises);
      const filteredRecent = updated.filter(uploaded => !deletedIds.includes(uploaded.recordId));
      setUploadedFiles(filteredRecent);
    } catch (error) { 
      console.log('AUTO-DELETE DUPLICATE UPLOADED AGING FILE: ', error); 
    } finally {
      setHasExisting(false);
    }
	};

  /**
   * This function updates the list of uploaded files after a file has finished processing.
   * @param addedFilesToUpload An array of files that were added for upload.
   * @param finished The file that has finished processing.
   * @returns The updated array of uploaded files.
   */
  const updateUploadedFiles = (addedFilesToUpload: IUploadedFile[], finished: IUploadedFile) => {
    const updated = addedFilesToUpload.map((uploadFile: IUploadedFile) => {
      if (uploadFile.recordId === finished.recordId) {
        const isUserDefinedDocType = finished.documentType && !Object.values(DOCTYPE).includes(finished.documentType);

        if (isUserDefinedDocType) {
          finished.isParsing = false;

          if (!finished.isExcelFile) {
            finished.dataMappingStatus = 'Completed';
            finished.mapped = true;
          } else {
            finished.dataMappingStatus = 'Not Started';
            finished.mapped = false;
          }
        } else {
          finished.isParsing = true;
          finished.dataMappingStatus = 'Not Started';
        }

        return finished
      } else return uploadFile;
    });
    setUploadedFiles(updated);
    return updated;
  };

  /**
   * This function resets the selected values in dropdowns and triggers the refresh of
   * associated data, such as refreshing the "As of Dates" based on the selected values.
   */
  const resetDropdowns = () => {
    setSelectedDocumentType(null);
    setSelectedARCollateral(null);
    setSelectedBBPeriod(null);
    setSelectedDates([]);
    refreshAsOfDates();
  };

  /**
   * This function returns the appropriate upload box content based on the provided parameters
   * and application state, including whether a file is actively dragged, if there is an upload error,
   * and whether dropping files is prevented.
   * @param isDragActive Indicates if a file is actively dragged over the upload area.
   * @param uploadError The error message related to file upload, if any.
   * @param preventOnDrop Indicates whether dropping files is prevented.
   * @returns The React node representing the upload box content.
   */
  const getUploadBox = (isDragActive: boolean, uploadError: string, preventOnDrop: boolean, selectedDocumentType: string) => {
    if (isDragActive) return (<Typography>Drop the files here ...</Typography>);
    else if (uploadError) return (
      <Box>
        <Typography sx={styles.errorMessage}>{uploadError}</Typography>
        <Box mt={1}>
          <Button variant="outlined" onClick={handleCancel} sx={styles.errorActionButton}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleBrowseClick(selectedDocumentType)} disabled={preventOnDrop} sx={styles.errorActionButton}>
            Upload Another File
          </Button>
        </Box>
      </Box>);
    else return (
      <Box>
        <Typography tabIndex={0} sx={styles.dropFile}> Drop files here <br/> - OR - </Typography>
        <DisabledComponentsContainer isDisabled={preventOnDrop}>
          <Button
            variant="contained"
            onClick={handleBrowseClick(selectedDocumentType)}
            disabled={preventOnDrop}
            aria-label={preventOnDrop ? 'Browse file button disabled' : 'Browse file'}
            sx={styles.browseFileButton}
          >
            {checking ? <CircularProgress size={15} /> : 'Browse File'}
          </Button>
        </DisabledComponentsContainer>
      </Box>)
  };

  /**
   * This functions handles the dropping of files in the dropzone.
   * @param selectedDocumentType The name of the selected document type.
   * @returns A promise for handling the submission of dropped files.
   */
  const handleOnDrop = (selectedDocumentType: string) => async (acceptedFiles: File[]) => {
    setFileOrigin('drop');
    setFileAction('');
    setHasExisting(false);
    setHasPrevious(false);
  
    if (needsChecking.includes(selectedDocumentType)) {
      const files = [acceptedFiles[0]];
      setFiles(files);

      const isExisting = await checkIfExisting(selectedDocumentType);
      if (!isExisting) handleSubmit(files);
    } else {
      setFiles(acceptedFiles);
      handleSubmit(acceptedFiles);
    }
  };

  return (
    <>
    <Grid container sx={styles.instructionContainer}>
      <Dropzone
        onDrop={handleOnDrop(selectedDocumentType?.name ?? '')}
        disabled={preventOnDrop}
      >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <Grid item md={4} sx={styles.dropFileContainer} {...getRootProps({ onClick: event => event.stopPropagation() })}>
          <Box tabIndex={0} sx={styles.dropFileIconBox}>
            <UploadFileRoundedIcon tabIndex={0} sx={styles.dropFileIcon} />
            <Box sx={styles.dropFileButton}>
              <Box component="label" htmlFor="file-upload">
                <input
                  {...getInputProps()}
                  data-testid='file-upload-input'
                  id="file-upload"
                  name="files"
                  type="file"
                  accept={Object.values(acceptedFileTypes).join(',')}
                  hidden
                  ref={fileRef}
                  multiple={!needsChecking.includes(selectedDocumentType?.name ?? '')}
                  onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                    const files = Array.from(event.currentTarget.files ?? []);
                    await handleSubmit(files);
                    if (fileRef.current) fileRef.current.value = '';
                  }}
                  disabled={preventOnDrop}
                />
                {getUploadBox(isDragActive, uploadError, preventOnDrop, selectedDocumentType?.name ?? '')}
              </Box>
              <Typography tabIndex={0} sx={styles.fileExtensions}>
                Supported files: xls, xlsx, csv
              </Typography>
            </Box>
          </Box>
        </Grid>)}
      </Dropzone>
      <Grid item md={6} sx={styles.uploadedFilesBg}>
        <Box sx={styles.uploadedFilesContainer}>
          <Typography tabIndex={0} sx={styles.uploadedFilesTitle}>
            Uploaded Files
          </Typography>
          {recentUploadedFiles.length
            ?
            <Grid container sx={styles.uploadedFiles}>
              {recentUploadedFiles.map((uploadedFile: IUploadedFile) => 
                <FileRow key={`${getUniqueKeys(uploadedFile.filename)}`} uploadedFile={uploadedFile} {...props} />)}
            </Grid>
            :
            <Box sx={styles.uploadedFilesEmptyState}>
              <img tabIndex={0} src={images.FileImportUploadEmpty} alt='File Upload Empty State' width={200} />
              <Typography tabIndex={0}>There are currently no files uploaded.</Typography>
            </Box>}
        </Box>
      </Grid>
    </Grid>
    <ConfirmModal
      title='File Upload'
      description='There is an existing aging file uploaded under the selected As of Date and Collateral. Do you want to replace or append the file?'
      open={confirmModal}
      alignment='left'
      noButtonText='Cancel'
      yesButtonText='Proceed'
      disabledYesButton={fileAction === ''}
      onClose={() => setConfirmModal(false)}
      onConfirm={fileOrigin === 'browse' ? handleBrowse : async () => await handleSubmit(files)}
      children={
        <FormControl sx={styles.fileActionRadioGroup}>
          <RadioGroup
            name='append-replace-file-radio-button-groups'
            value={fileAction}
            onChange={(_event: ChangeEvent<HTMLInputElement>, value: string) => setFileAction(value as 'append' | 'replace' | '')}
          >
            <FormControlLabel value='append' control={<Radio size='small' />} label='Append File' />
            <FormControlLabel value='replace' control={<Radio size='small' />} label='Replace File' />

            {/* This selection is added to prevent component on changing from an uncontrolled state to controlled */}
            <FormControlLabel value='' control={<Radio size='small' hidden />} label='No Selection' sx={styles.noSelectedRadioButton} />
          </RadioGroup>
        </FormControl>
      }
    />
    </>
  );
}

export default UploadingSection;