import { Field } from "formik";
import { ChangeEvent, FC, KeyboardEvent, SyntheticEvent, forwardRef, useMemo, useRef, useState } from "react"
import styles from "../../styles";
import { ICustomProps } from "../../../../../../../interfaces";
import NumberFormat, { InputAttributes } from "react-number-format";
import { Box, ClickAwayListener, FormLabel, MenuItem, MenuList, Paper, Popper, TextField } from "@mui/material";
import { BucketSpreadProps } from "..";

export interface IBucketFields extends BucketSpreadProps {
  name: string;
  bucketNumber: number;
  type?: string
}
/**
 * This function format a textfield's value into an integer
 */
const IntegerFormat = forwardRef<NumberFormat<InputAttributes>, ICustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({ target: { value: values.value, name: other.name, }, });
        }}
        thousandSeparator
        type='tel'
        allowNegative={false}
        min={0} // minimum will be dynamic based on the previous bucket defined
        max={2147483647}
      />
    );
  }
);

/**
 * Component for DateBucket Field
 * @param props IBucketFields
 * @returns a JSX.Element
 */
const BucketField: FC<IBucketFields> = (props) => {
  const { name, bucketNumber, formik, isDetailsEnabled, getFormikValues } = props;

  /**
   * This useMemo returns a specific formik value
   */
  const formikValues = useMemo(() => getFormikValues(formik, name), [formik, name]);

  /**
   * This useMemo returns default options for bucket field
   */
  const bucketDefaultOptions = useMemo(() => {
    const defaultBuckets = ['0','30','60','90'];
    if (bucketNumber === 1) return defaultBuckets;
    else return [...defaultBuckets,'NA'];
  }, [bucketNumber]);

  const [open, setOpen]                 = useState<boolean>(false);
  const [hasPrevValue, setHasPrevValue] = useState<boolean>(formikValues.value !== undefined);
  const anchorRef                       = useRef<HTMLInputElement>(null);

  /**
   * This function closes menu list when a value is clicked
   * @param event SyntheticEvent
   * @param value value from selected option
   */
  const handleMenuItemClick = (event: Event | SyntheticEvent, value: string) => {
    if (value === 'NA') formik.setFieldValue(name, '');
    else formik.setFieldValue(name, value);
    handleClose(event);
  };

  /**
   * This function closes the menu list containing options for date buckets
   * @param event SyntheticEvent
   */
  const handleClose = (event: Event | SyntheticEvent) => !anchorRef.current?.contains(event.target as HTMLElement) && setOpen(false);

  /**
   * This function handle keydown event from the user
   * @param event keyboardEvent
   */
  const handleListKeyDown = (event: KeyboardEvent) => ['Tab', 'Escape'].includes(event.key) && setOpen(false);

  return (
    <>
      <Box sx={styles.bucketContainer}>
        <FormLabel
          tabIndex={0}
          htmlFor={formikValues.id}
          sx={styles.bucketNumber}
        >
          {bucketNumber}
          {bucketNumber === 1 && <span style={styles.asterisk}>*</span>}
        </FormLabel>
        <Field
          as={TextField}
          autoComplete='off'
          disabled={!isDetailsEnabled}
          inputProps={{ sx: styles.inputProps, 'data-testid' : `${props.bucketType}-${props.bucketNumber}-field` }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setHasPrevValue(true);
            let inputValue = event.target.value
            if (inputValue.length > 3) { inputValue = inputValue.slice(0, 3); }
            if (inputValue === '' || inputValue === 'NA') {
              formik.setFieldValue(name, undefined);
              return;
            }
            formik.setFieldValue(name, parseInt(inputValue));
            formik.setFieldError(name, undefined);
            setOpen(false)
          }}
          onFocus={() => setOpen(true)}
          sx={styles.bucketField}
          type='number'
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: IntegerFormat as any,
            inputRef: anchorRef,
            placeholder: formikValues.value === undefined && !hasPrevValue ? undefined : 'NA',
            sx: styles.InputProps,
            "data-testid": `${props.bucketType}-${props.bucketNumber}`
          }}
          {...formikValues}
        />
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement='bottom'
        disablePortal
        sx={styles.bucketPopper}
      >
        <Paper sx={styles.bucketPaper}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              id='bucket-dates-menu'
              aria-labelledby='bucket-dates'
              onKeyDown={handleListKeyDown}
            >
              {
                bucketDefaultOptions.map(bucket =>
                  <MenuItem key={bucket} onClick={(event) => handleMenuItemClick(event, bucket)} sx={styles.bucketMenuItems}>
                    {bucket}
                  </MenuItem>
                )
              }
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default BucketField