const styles = {
  accordion: {
    display: 'block',
    position: 'relative',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  accordionSummaryFontSize: {
    fontSize: '1.5rem',
    color: '#154A8A'
  },
  accordionSummary: {
    backgroundColor: '#F2F7F8',
    display: 'flex',
    borderBottom: '2px solid #E9ECEF',
    alignItems: 'flex-end',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
      ariaLabel: 'Collapse icon'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      marginLeft: 1,
    },
    paddingX: '0.5rem',
  },
  accordionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  accordionDetails: {
    backgroundColor: '#F2F7F8',
    paddingX: 0
  },
  bodyContainer: {
    marginTop: '2rem'
  },
  varianceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  varianceText: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '1rem',
    fontWeight: 'bold',
  },
  variance: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  totalVarianceContainer: {
    marginBottom: '1rem'
  },
  mainContainer: {
    marginBottom: '4rem',
  }
}
export default styles;