import { useState, useEffect, useContext } from 'react';
import { API_DOMAIN, GET, PERMISSIONS } from '../../../../utility/constants';
import { Box, Container, Grid, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styles from './styles';
import { ClientContext } from '../../../../context/clientContext';
import ClientsHeader from '../header';
import ClientComboBox from '../combo-box';
import AddNewClient from '../../../borrowers/modal/AddNewClient';
import ClientsTable from '../tabs';
import { IClientInfo } from '../../../../interfaces/clientListInterface';
import { checkUserPermissions, getPermissionsOfUser } from '../../../../utility/helper';
import { AuthContext } from '../../../../context/authContext';
import axiosInstance from '../../../../service/axiosInstance';
import api, { borrowerAPI } from '../../../../service/api';
import { usePrompt } from '../../../../utility/prompt';
import { SelectedClientContext } from '../../../../context/selectedClientContext';
import ConfirmModal from '../../../modals/confirm-modal';
// import { IAccessUserAPI } from '../../../../interfaces/rolesPermissionInterface';
import { IUserAPI } from '../../../../interfaces/rolesPermissionInterface';
import { useNavigate } from 'react-router-dom';

/**
 * Component for showing the contents of the clients table options.
 */
const ClientTableOptions = () => {
  const {
    dirty, 
    canViewClients, setCanViewClients,
    setCanAddClient,
    setCanUpdateClient,
    setCanArchiveDeleteClient,
    canViewAssignedClients, setCanViewAssignedClients,
    setCanViewClientSettings,
    modalProps,
    setModalProps,
    setUsers
  }                                                         = useContext(ClientContext);
  const clientContext                                       = useContext(ClientContext);
  const selectedClientContext                               = useContext(SelectedClientContext);
  const { state }                                           = useContext(AuthContext);
  const [searchValue, setSearchValue]                       = useState<string>('');
  const [tab, setTab]                                       = useState<string>('active');
  const [clientInfo, setClientInfo]                         = useState<IClientInfo[]>([]);
  const [_archivedClientInfo, setArchivedClientInfo]        = useState<IClientInfo[]>([]);
  const [clientName, setClientName]                         = useState<string | null>('All');
  const [lineBusiness, setLineBusiness]                     = useState<string | null>('All');
  const [lineOfBusinesses, setLineOfBusinesses]             = useState<string[]>([]);
  const [borrowerInput, setBorrowerInput]                   = useState<string>('');
  const [lineBusinessInput, setLineBusinessInput]           = useState<string>('');
  const [isLoading, setIsLoading]                           = useState<boolean>(true);
  const [refreshRow, setRefreshRow]                         = useState<boolean>(false);
  const [page, setPage]                                     = useState<number>(0);
  const [clientNames, setClientNames]                       = useState<string[]>([]);  
  const [archivedClientNames, setArchivedClientNames]       = useState<string[]>([]);  
  const [rowsPerPage, setRowsPerPage]                       = useState(10);
  const [totalActiveElements, setTotalActiveElements]       = useState(0);
  const [totalArchiveElements, setTotalArchiveElements]     = useState(0);
  const navigate                                            = useNavigate();

  /**
   * This useEffect hook retrieves the options for the line of business dropdown.
   */
  useEffect(() => {
    getLineOfBusiness();    
  }, []);

  /**
   * This useEffect calls the getPermissions and getAllUsers functions.
   */
  useEffect(() => {
    if (state.token !== null) {
      getPermissions(state.token);
      getAllUsers(state.token);
    } else {
      console.log('NO TOKEN SET')
    }
  }, [state.token])

  /**
   * This function also retrieves all of the users in order for them to be shown in the User Name dropdown field. 
   * @param token The login token of the current user.
   */
  const getAllUsers = async (token : string) => {
    try {
      const response = await axiosInstance.request({
        url: api.ALL_USERS,
        method: GET,
        headers: {
          token : token
        },
      });
      const userData : IUserAPI[] = response.data;
      const userNames : string [] = userData.map(user => user.firstName + ' ' + user.lastName);
      setUsers(userNames);
    } catch (e) {
      console.log(e)
    }
  };

  /**
   * This function retrieves the permissions of the user and sets their state.
   * @param token 
   */
  const getPermissions = async (token : string) => {
    try {
      const permissions = await getPermissionsOfUser(state.uid, token);

      if (permissions.includes(PERMISSIONS.VIEW_CLIENT) || permissions.includes(PERMISSIONS.VIEW_ASSIGNED_CLIENT)) {
        setCanViewClients(permissions.includes(PERMISSIONS.VIEW_CLIENT));
        setCanViewAssignedClients(permissions.includes(PERMISSIONS.VIEW_ASSIGNED_CLIENT));
      } else {
        navigate('/forbidden')
      }

      setCanViewClientSettings(permissions.includes(PERMISSIONS.VIEW_CLIENT_SETTINGS));
      setCanAddClient(permissions.includes(PERMISSIONS.ADD_CLIENT));
      setCanUpdateClient(permissions.includes(PERMISSIONS.UPDATE_CLIENT));
      setCanArchiveDeleteClient(permissions.includes(PERMISSIONS.DELETE_ARCHIVE_CLIENT));
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * This useEffect hook calls the refreshClients function whenever the refreshRow value changes.
   */
  useEffect(() => {
    refreshClients();
  }, [refreshRow]);

  /**
   * This useEffect resets the page, search value filter, client name filter, line of business filter whenever the tab is changed.
   * It also calls the refreshClients function.
   */
  useEffect(() => {
    setPage(0);
    setSearchValue('');
    setClientName('All');
    setLineBusiness('All');
    refreshClients();
  }, [tab]);

  /**
   * This useEffect hook calls the getFilteredListOfClients function whenever 
   * the search value filter, client name filter, line of business filter changes.
   */
  useEffect(() => {
    getFilteredListOfClients(tab);
  }, [searchValue, clientName, lineBusiness]);

  /**
   * This useEffect hook retrieves calls the getFilteredListOfClients if there are 
   * active search filters when the page or rows per page are changed. Otherwise,
   * the getAllClients and getAllActiveClients functions are called.
   */
  useEffect(() => {
    const hasSearchFilters = searchValue !== '' || (clientName !== 'All' && clientName !== null) || lineBusiness !== 'All';
    if (hasSearchFilters){
      getFilteredListOfClients(tab);
      return;
    }
    getAllClients();
    getAllActiveClients();
  }, [page, rowsPerPage]);

  /**
   * This useEffect hook resets the page number to 0 whenever the rowsPerPage value changes.
   */
  useEffect(() => {
    setPage(0);
  }, [rowsPerPage]);

  /**
   * This function calls the API endpoint for retrieving the clients based on various criterias like the
   * line of business, borrower name, the active status of the client, and the CRM name of the client.
   * @param tab The value for the selected tab.
   */
  const getFilteredListOfClients = async (tab: string) => {
    setIsLoading(true);
    const isArchived = tab !== 'active';
    const paramForClientName = clientName === 'All' || clientName === null ? searchValue: clientName;
    const paramForLineOfBusiness = lineBusiness === 'All' || lineBusiness === null ? '': lineBusiness;
    const canViewAssignedClientsOnly = await checkUserPermissions(state.uid, PERMISSIONS.VIEW_ASSIGNED_CLIENT, state.token);
    const paramForCRMName = canViewAssignedClientsOnly ? `${state.firstName} ${state.lastName}` : ''
    try {
      const response = await axiosInstance.request({
        url: `${API_DOMAIN}/borrowers/search/findByCriteria?isVisible=true&isArchive=${isArchived}&borrowerName=${paramForClientName}`+  (paramForLineOfBusiness !== '' ? (`&lineOfBusiness=${paramForLineOfBusiness}`) : '') + `&pageNo=${page}&pageSize=${rowsPerPage}&sortBy=borrowerName,ASC&` + (paramForCRMName !== '' ? (`crmName=${paramForCRMName}`) : ''),
        method: GET,
      });
      const clientListResponse = response.data.content as IClientInfo[];
      if (isArchived) {
        setArchivedClientInfo(clientListResponse);
        setTotalArchiveElements(response.data.totalElements as number);
        return;
      }
      setClientInfo(clientListResponse);
      setTotalActiveElements(response.data.totalElements as number);
    } catch (error) {
      console.log(error);
    } finally {
      (clientName !== null && clientName !== 'All') && setPage(0);
      setIsLoading(false);
    }
  };

  /**
   * This function refreshes the values of the clients by calling various functions that connect to the
   * API endpoint to retrieve the client data.
   */
  const refreshClients = async () => {
    await getFilteredListOfClients(tab);
    await getArchivedClients();
    await getAllActiveClients();
    await getAllArchivedClients();
  };

   /**
   * This function sets the values for the line of business dropdown.
   */
  const getLineOfBusiness = async () => {
    try {
      const response = await axiosInstance.request({
        url: '../../../../mock-api/line-of-business.json',
        method: GET,
        baseURL: ''
      })
      const lineOfBusinessNames = response.data.map((data: any) => data.lineOfBusinessName);
      setLineOfBusinesses(lineOfBusinessNames);
    } catch (error) {
      console.log(error);
      setLineOfBusinesses([]);
    }
  }

  /**
   * This function calls the functions that retrieve the values for both active and archived clients from the database.
   */
  const getAllClients = async () => {
    setIsLoading(true);
    await getClients();
    await getArchivedClients();
    await getAllActiveClients();
    await getAllArchivedClients();
    setIsLoading(false);
  };

  /**
   * This function calls the API endpoints for retrieving the paged archived clients.
   */
  const getArchivedClients = async () => {
    let requestParams;
    const canViewAssignedClientsOnly = await checkUserPermissions(state.uid, PERMISSIONS.VIEW_ASSIGNED_CLIENT, state.token);
    if (canViewAssignedClientsOnly) {
      requestParams = {isVisible: true, isArchive: true, pageNo: page, pageSize: rowsPerPage, sortBy: 'borrowerName,ASC', crmName: `${state.firstName} ${state.lastName}`}
    } else {
      requestParams = {isVisible: true, isArchive: true, pageNo: page, pageSize: rowsPerPage, sortBy: 'borrowerName,ASC'}
    }
    try {
      const response = await axiosInstance.request({
        url: borrowerAPI.FIND_BY_CRITERIA,
        method: GET,
        params: requestParams,
      })
      setArchivedClientInfo(response.data.content);
      setTotalArchiveElements(response.data.totalElements as number);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * This function calls the API endpoints for retrieving the paged active clients.
   */
  const getClients = async () => {
    let requestParams;
    const canViewAssignedClientsOnly = await checkUserPermissions(state.uid, PERMISSIONS.VIEW_ASSIGNED_CLIENT, state.token);
    if (canViewAssignedClientsOnly) {
      requestParams = {isVisible: true, isArchive: false, pageNo: page, pageSize: rowsPerPage, sortBy: 'borrowerName,ASC', crmName: `${state.firstName} ${state.lastName}`}
    } else {
      requestParams = {isVisible: true, isArchive: false, pageNo: page, pageSize: rowsPerPage, sortBy: 'borrowerName,ASC'}
    }
    try {
      const response = await axiosInstance.request({
        url: borrowerAPI.FIND_BY_CRITERIA,
        method: GET,
        params: requestParams
      })
      setClientInfo(response.data.content);
      setTotalActiveElements(response.data.totalElements as number);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * This function calls the API endpoints for retrieving all the archived clients.
   * This function ignores use the pagination parameters set by the user and retrieves all the clients.
   */
  const getAllArchivedClients = async () => {
    let requestParams;
    const canViewAssignedClientsOnly = await checkUserPermissions(state.uid, PERMISSIONS.VIEW_ASSIGNED_CLIENT, state.token);
    if (canViewAssignedClientsOnly) {
      requestParams = {isVisible: true, isArchive: true, sortBy: 'borrowerName,ASC', crmName: `${state.firstName} ${state.lastName}`}
    } else {
      requestParams = {isVisible: true, isArchive: true, sortBy: 'borrowerName,ASC'}
    }
    try {
      const response = await axiosInstance.request({
        url: borrowerAPI.FIND_BY_CRITERIA,
        method: GET,
        params: requestParams,
      })
      getArchivedClientNames(response.data.content);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * This function calls the API endpoints for retrieving all the active clients.
   * This function ignores use the pagination parameters set by the user and retrieves all the clients.
   */
  const getAllActiveClients = async () => {
    let requestParams;
    const canViewAssignedClientsOnly = await checkUserPermissions(state.uid, PERMISSIONS.VIEW_ASSIGNED_CLIENT, state.token);
    if (canViewAssignedClientsOnly) {
      requestParams = {isVisible: true, isArchive: false, sortBy: 'borrowerName,ASC', crmName: `${state.firstName} ${state.lastName}`}
    } else {
      requestParams = {isVisible: true, isArchive: false, sortBy: 'borrowerName,ASC'}
    }
    try {
      const response = await axiosInstance.request({
        url: borrowerAPI.FIND_BY_CRITERIA,
        method: GET,
        params: requestParams,
      })
      clientContext.setClientInfo(response.data.content);
      selectedClientContext.setClients(response.data.content);
      getClientNames(response.data.content);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * This function retrieves the values for the clients when the user has added a new client.
   * @param isAdded This is true if the user added a new client. Default value is false.
   */
  const onAddNewClientModalDismiss = (isAdded: boolean = false) => {
    if (isAdded) {
      getClients();
      getAllActiveClients();
    }
  };

  /**
   * This function sets the name of the new line of business selected in the dropdown.
   * @param value The name of the new line of business object.
   */
  const setLineOfBusinessesInput = (value : React.SetStateAction<string>) => {
    setPage(0);
    setLineBusinessInput(value);
  };

  /**
   * This function sets the value of the new line of business selected in the dropdown.
   * @param value The value of the new line of business object.
   */
  const setLineOfBusinessesValue = (value : React.SetStateAction<string | null>) => {
    setPage(0);
    setLineBusiness(value);
  };

  /**
   * This function sets the name of the new client selected in the dropdown.
   * @param value The name of the new client.
   */
  const setClientInput = (value : React.SetStateAction<string>) => {
    setBorrowerInput(value);
  };

  /**
   * This function sets the value of the new client selected in the dropdown.
   * @param value The value of the new client.
   */
  const setClientValue = (value : React.SetStateAction<string | null>) => {
    setClientName(value);
  };

  /**
   * This function sets the new state for the search value.
   * @param e The event generated when user inputs something in the search field.
   */
  const searchOnChange = (e : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.target.value);
    setPage(0);
  };

  /**
   * This sorts the line of businesses alphabetically.
   */
  const sortedLineOfBusiness: string[] = [...lineOfBusinesses].sort(
    (a: string, b: string) => {
      const nameA = a.toUpperCase();
      const nameB = b.toUpperCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    }
  );
  

  /**
   * This retrieves all of the client names of the active clients.
   * @param allClientInfo the active clients to retrieve the names from
   */
  const getClientNames = (allClientInfo: IClientInfo[]) => {
    const clientNames: string[] = allClientInfo.map((client) =>
      client.borrowerName ? client.borrowerName : ''
    );
    setClientNames(clientNames);
  }
  

  /**
   * This retrieves all of the client names of the archived clients.
   * @param allArchivedClientInfo the archived clients to retrieve the names from
   */
  const getArchivedClientNames = (allArchivedClientInfo: IClientInfo[]) => {
    const archivedClientNames: string[] = allArchivedClientInfo.map((client) =>
    client.borrowerName ? client.borrowerName : ''
  );
    setArchivedClientNames(archivedClientNames);
  }
  

  /**
   * This function sets the new page number for the clients table.
   * @param page The new page number.
   */
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  /**
   * This function sets the new number of rows per page for the clients table.
   * @param page The new rows per page.
   */
  const handleRowsPerPageChange = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
  };

  /**
   * This function retrieves the information showed on the current table depending on whether the user is on
   * the active and archived tab.
   * @param type The current tab that the user is in.
   * @returns The information for the current table.
   */
  const getTableInfo = (type : 'active' | 'archived') => {
    let info : IClientInfo[] = [];
    if (type === 'active') {
      info = clientInfo;
    } else {
      info = _archivedClientInfo;
    }
    return info;
  }

  /**
   * This custom hook shows a confirm navigation modal if the form is dirty when the user tries to navigate away from the page/modal.
   */
  usePrompt(`You have unsaved changes. Are you sure you want to leave this page?`, dirty)

  return (
    <Box sx={styles.pageStyle}>
      <Box sx={styles.breadcrumbsBox}>
        <Box sx={styles.clientComboBoxContainer}>
        </Box>
      </Box>
      {
        (canViewClients || canViewAssignedClients) ? (
          <>
            <ClientsHeader />
            <Container maxWidth='xl'>
              <Box sx={styles.comboBoxStyle}>
                <Grid container columnSpacing={1} rowSpacing={2}>
                  <Grid item xs={10.5} md={3.5} sx={styles.searchStyle}>
                    <TextField 
                      id={`input-with-id`}
                      inputProps={{'data-testid':`search-field`}}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start' tabIndex={0} aria-label='Search icon'>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      aria-label='Search Clients'
                      onChange={searchOnChange}
                      value={searchValue}
                      placeholder='Search'
                      size='small'        
                      sx={styles.searchField}
                      />
                    </Grid>
                    <Grid item xs={10} md={3.5} sx={{position: "relative", bottom: "0.35rem"}}>
                      <ClientComboBox
                        options={(tab === 'active' ? ['All', ...clientNames] : ['All', ...archivedClientNames])}
                        label='Client Name:'
                        id='client'
                        value={clientName}
                        placeholder='Please Select'
                        setValue={setClientValue}
                        input={borrowerInput}
                        setInput={setClientInput}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={10} md={3.5} sx={{position: "relative", bottom: "0.35rem"}}>
                      <ClientComboBox
                        options={['All', ...sortedLineOfBusiness]}
                        label='Line of Business'
                        id='lineOfBusiness'
                        value={lineBusiness}
                        placeholder='Please Select'
                        setValue={setLineOfBusinessesValue}
                        input={lineBusinessInput}
                        setInput={setLineOfBusinessesInput}
                        disabled={false}
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <Grid sx={styles.addClientStyle}>
                      <AddNewClient onDismiss={onAddNewClientModalDismiss} />
                    </Grid>
                </Box>
              </Box>
              <Box sx={styles.tableStyle}>
                <ClientsTable
                  tab={tab}
                  setTab={setTab} 
                  isLoading={isLoading}
                  clientName={searchValue !== '' ? searchValue : clientName}
                  lineBusiness={lineBusiness}
                  sortedArchivedClientInfo={getTableInfo('archived')}
                  sortedClientInfo={getTableInfo('active')}
                  refreshRow={refreshRow}
                  setRefreshRow={setRefreshRow}
                  totalActiveElements={totalActiveElements}
                  totalArchiveElements={totalArchiveElements}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </Box>
            </Container>
          </>
        ) : null
      }
      <ConfirmModal 
        title={modalProps.title}
        description={modalProps.description}
        open={modalProps.open}
        alignment="left"
        onClose={() => {
          setModalProps({
            ...modalProps,
            open: false,
          });
        }}
        onConfirm={() => {
          modalProps.handleConfirm();
        }}
        errorButton={false}
        noButtonText={'Cancel'}
        yesButtonText={modalProps.confirmText}
      />
    </Box>
  );
};

export default ClientTableOptions;
