import { createContext, useMemo, useState } from 'react';
import { LayoutProps } from './authContext';

export interface IFileImportPermissionsContext {
  viewFileImport: boolean,
  setViewFileImport:(value: boolean) => void,
  importFile: boolean,
  setImportFile:(value: boolean) => void,
  downloadFile: boolean,
  setDownloadFile:(value: boolean) => void,
  archiveFile: boolean,
  setArchiveFile:(value: boolean) => void,
}

export const FileImportPermissionsContext = createContext<IFileImportPermissionsContext | null>(null);

const FileImportPermissionProvider = (props: LayoutProps) => { 
  const { children } = props;
  const [ viewFileImport, setViewFileImport] = useState<boolean>(false);
  const [ importFile, setImportFile] = useState<boolean>(false);
  const [ downloadFile, setDownloadFile] = useState<boolean>(false);
  const [ archiveFile, setArchiveFile] = useState<boolean>(false);

  const value = useMemo(() => ({ 
    viewFileImport,
    setViewFileImport,
    importFile,
    setImportFile,
    downloadFile,
    setDownloadFile,
    archiveFile,
    setArchiveFile
  }), [
    viewFileImport,
    setViewFileImport,
    importFile,
    setImportFile,
    downloadFile,
    setDownloadFile,
    archiveFile,
    setArchiveFile
  ])


  return (
    <FileImportPermissionsContext.Provider
      value={value}
    >
      {children}
    </FileImportPermissionsContext.Provider>
  );
};

export default FileImportPermissionProvider;