const styles = {
	pageStyle: {
		backgroundColor: '#F2F7F8',
	},

	boxColor: {
		backgroundColor: '#F2F7F8',
	},
	comboBox: {
		backgroundColor: 'white',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#E0E0E0',
		},
		'& .MuiOutlinedInput-root': {
			color: '#000',
			padding: '15px',
			fontSize: '14px',
			height: '36.6px',
			backgroundColor: 'white',
			'& .MuiOutlinedInput-input': {
				width: '100%',
				padding: '0px',
			},
		},
		'& .Mui-disabled': {
			backgroundColor: '#F7F7F7',
		},
	},
	subtitleContainer: {
		display        : 'flex',
		justifyContent : 'space-between',
	},
	subtitle: {
		fontWeight     : 'bold',
		color          : '#154A8A',
		fontSize       : '16px',
		alignSelf      : 'end',
	},
	title: {
		fontWeight     : 'bold',
		color          : '#010953',
		fontSize       : '18px',
		ml             : '24px',
	},
	titleContainer: {
		backgroundColor: '#FEFEFE',
		py             : 2.5
	},
	searchField: {
		backgroundColor: 'white',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#E0E0E0',
		},
		'& .MuiOutlinedInput-root': {
			color: '#000',
			padding: '15px',
			fontSize: '14px',
			height: '36.6px',
			backgroundColor: 'white',
			'& .MuiOutlinedInput-input': {
				width: '100%',
				padding: '0px',
			},
		},
		'& .Mui-disabled': {
			backgroundColor: '#F7F7F7',
		},
		width          : '100%',
	},
	comboBoxStyle: {
		display        : 'flex',
	},
	searchStyle: {
		alignSelf      : 'center',
		marginTop      : '1.89rem',
		marginLeft     : '16px',
		flexBasis      : 'auto',
	},
	modalOpenButton: {
		py             : '2px',
		textTransform  : 'none',
		px             : '1rem',
	},
	filterGridContainer: {
		backgroundColor: 'white',
		marginY        : '16px',
		paddingBottom  : '1rem'
	},
	filterChildBoxMargin: {
		margin         : '1rem',
	},

	dropdownTitle: {
		display: 'block',
		fontSize: '14px',
		whiteSpace: 'nowrap',
		marginBottom: '8px'
	},

	clientDropdownTitle: {
		display: 'block',
		fontSize: '14px',
		whiteSpace: 'nowrap',
		marginRight: '16px'
	},

	breadcrumbsBox: {
		paddingRight: '24px',
		borderRadius: '0.3rem',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	
	clientDropdown: {
		display: 'flex', 
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},

	clientBox: {
		width: '100%',
		maxWidth: '398.72px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},

	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	tableContainer: {
		padding: '1rem',
		mx: '24px',
		my: '16px'
	},

	tableSpacer: {
		height: '1rem',
	},

	tableHead: {
		backgroundColor : '#F7F7F7',
		color           : 'inherit',
		fontWeight      : 'bold',
		whiteSpace      : 'nowrap',
		border          : 0,
		height          : '2.5rem',
	},
	
};

export default styles;
  