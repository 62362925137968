import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

interface IDisabledComponentsContainerProps {
  isDisabled: boolean;
};

const DisabledComponentsContainer: FC<PropsWithChildren<IDisabledComponentsContainerProps>> = ({children, isDisabled}) => {

  return (
    <Box tabIndex={isDisabled ? 0 : -1}>
      {children}
    </Box>
  );
};

export default DisabledComponentsContainer;
