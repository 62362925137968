import colors from "../../../utility/colors"

const styles = {
	
	breadcrumbsBox: {
		paddingRight: '24px',
		borderRadius: '0.3rem',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

	clientDropdown: {
		display: 'flex', 
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
  
	clientBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  clientDropdownTitle: {
    display: 'block',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '16px'
  },

  comboBox: {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },

  inputParentChipContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
	
	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

  labelChipContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  parentChipContainer: {
    width: '100px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  parentIdentifierChip: {
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"P"',
    },
    '& .MuiChip-label:hover:after': {
      content: '"Parent"',
    }
  },
  hidden: {
    display: 'none',
  }

}

export default styles