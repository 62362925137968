import { Box, Paper, Typography, LinearProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Collapse, Link } from "@mui/material"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { DraggableListItem } from "../../../../interfaces/draggableList"
import SearchParentChild from "../../search-parent-child"
import styles from "../styles"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { IOption } from "../../../../interfaces/comboBox"
import CustomerModal, { IARCustomerFormValues } from "../../customer-modal"
import { EditVendorCustomerSetupContext } from "../../../../context/EditVendorCustomerSetupProvider"
import { IEditVendorCustomerSetupContext, IModalProps, IParentWithChild } from "../../../../interfaces/editVendorCustomerSetupProvider"
import DraggableTable from "../../draggable-table"
import ParentTag from "../../../../components/common/parent-tag"
import NewTag from "../../../../components/common/new-tag"
import { formatCurrency, IClearModalProps } from "../.."

const filters = ['All', 'New', 'Parent', 'Child']

interface IProps {
  selectedARCollateral: IOption | null
  borrowerId?: string,
  isDirty: boolean,
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>,
  modalProps: IModalProps,
  setModalProps: React.Dispatch<React.SetStateAction<IModalProps>>
  setClearModal: React.Dispatch<React.SetStateAction<IClearModalProps>>
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>,
  isDragging: boolean
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>,

}

/**
 * This component renders the Parent section of the parent view page.
 * 
 * @param props IProps
 */
const CustomerSection = (props: IProps) => {
  const {parentsWithChildrenList,
         setParentsWithChildrenList,
         setSelectedUnlinkedVendors,
         unlinkedVendorsList,
         setUnlinkedVendorsList,
         newParentsWithChildrenList,
         setNewParentsWithChildrenList,
         newUnlinkedVendorsList,
         setNewUnlinkedVendorsList,
         selectedUnlinkedVendors,
         parentPage,
         setParentPage,
        } = useContext(EditVendorCustomerSetupContext) as IEditVendorCustomerSetupContext;

  const selectedARCollateralId                                = props.selectedARCollateral?.recordId
  const [showParentModal, setShowParentModal]                 = useState<boolean>(false);
  const [newParentId, setNewParentId]                         = useState<number>(0);
  const [parentDisplay, setParentDisplay]                     = useState<IParentWithChild[]>([]);
  const parentObserver                                        = useRef<any>();
  
  // HOOKS

  /**
   * This useEffect triggers the function that generates which Parents should be display in the list.
   */
  useEffect(() => {
    getParentDisplay()
},[parentPage.searchKey, parentPage.searchFilter, parentPage.pageNo, parentsWithChildrenList])

  // NON API FUNTIONS

  /**
   * This function dynamically handles the update on the relationship of a Parent ARCustomer and an ARCustomer
   * 
   * @param list A list of ARCustomer formatted as DraggableListItem | Children
   * @param removeFromId The ID where the dragged ARCustomer came from.
   * @param insertToId The ID where the dragged ARCustomer was drop to.
   * @param tempId The temporary ID of a parent, if any.
   * @returns Null if the ARCustomer was dragged and droped on a same parent.
   */
  const handleUpdate = (list: DraggableListItem[], removeFromId?: number, insertToId?: number, tempId?: number) => {
    if(removeFromId === insertToId) return;

    const children: DraggableListItem[] = list.map(item => ({...item, checked: false, listName: insertToId?.toString()}))

    const updateList = getUpdateList(children, removeFromId, insertToId);
    const newList = updateList.newList;
    const updatedNewParent = updateList.updatedNewParent;
    const updatedOldParent = updateList.updatedOldParent;

    handleNanId(children, removeFromId);

    const isFoundNew = newParentsWithChildrenList.find(parent => parent.id === insertToId && parent.tempId === tempId)
    const isFoundOld = newParentsWithChildrenList.find(parent => parent.id === removeFromId && parent.tempId === tempId)
    if(isFoundNew || isFoundOld){
      handleExistingCustomers(updatedNewParent, updatedOldParent, removeFromId, insertToId, tempId);
    } else{
      handleNewCustomers(children, removeFromId, insertToId, tempId);
   }

   setParentsWithChildrenList(newList)
   setSelectedUnlinkedVendors([])
  }

  /**
   * This function updates the list when there is a change on the ParentList.
   * 
   * @param children A list of ARCustomer formatted as DraggableListItem | Children
   * @param removeFromId The ID where the dragged ARCustomer came from.
   * @param insertToId The ID where the dragged ARCustomer was drop to.
   * @returns The updated ParentList
   */
  const getUpdateList = (children: DraggableListItem[], removeFromId?: number, insertToId?: number) => {
    const initialValue: IParentWithChild = {
      children: [],
      id: 0,
      name: "",
      defaultChildId: "",
      isExpanded: false
    };

    let updatedNewParent: IParentWithChild = initialValue;
    let updatedOldParent: IParentWithChild = initialValue;
   
    const newList = parentsWithChildrenList.map(parent => {
      //insert to parent
      if(insertToId === parent.id && parent.id !== removeFromId){
        const defChildId = parent.children.length < 1 ? children[0].recordId.toString() : parent.defaultChildId
        updatedNewParent = {
          ...parent,
          defaultChildId: defChildId,
          children: [...parent.children, ...children]
        }
        return updatedNewParent
      }
      //remove from parent
      else if(parent.id === removeFromId && parent.id !== insertToId){
        const filteredChildren = parent.children.filter((originalObj) =>
        !children.some((filterObj) => filterObj.recordId === originalObj.recordId));
        updatedOldParent = {
          ...parent,
          defaultChildId: checkIfIdExists(children, parent.defaultChildId) ? filteredChildren[0].recordId.toString() : parent.defaultChildId,
          children: filteredChildren
        }
        return updatedOldParent
      }

      return parent
    })

    return { updatedNewParent, updatedOldParent, newList }
  }

  /**
   * This function removes an ARCustomer from the orphan.
   * 
   * @param children A list of ARCustomer formatted as DraggableListItem | Orphans
   * @param removeFromId The ID of the list where the dragged ARCustomer came from, Nan if its from the unlinkedVendorsList.
   */
  const handleNanId = (children: DraggableListItem[], removeFromId?: number) => {
    if(isNaN(removeFromId!)){
      const filteredOrphan = unlinkedVendorsList.filter((originalObj) =>
      !children.some((filterObj) => filterObj.recordId === originalObj.recordId));
      setUnlinkedVendorsList(filteredOrphan)
      setNewUnlinkedVendorsList([...newUnlinkedVendorsList, ...children])
    }
  };

  /**
   * This function triggers when the ARCustomer was dragged from and drop on different but both exisitng (saved) Parent ARCustomer.
   * 
   * @param updatedNewParent The updated parent object in which the dragged ARCustomer was droped to.
   * @param updatedOldParent The updated parent object in which the dragged ARCustomer came from.
   * @param removeFromId The ID of the old Parent.
   * @param insertToId The ID of the new Parent.
   * @param tempId The Temporary ID of the parent if its not saved yet.
   */
  const handleExistingCustomers = (updatedNewParent: IParentWithChild, updatedOldParent: IParentWithChild, removeFromId?: number, insertToId?: number, tempId?: number) => {
    const newParentList = newParentsWithChildrenList.map(parent => {
      if((parent.id === insertToId && parent.id !== removeFromId) && parent.tempId === tempId){
        return updatedNewParent
      }else if((parent.id === removeFromId && parent.id !== insertToId) && parent.tempId === tempId){
        return updatedOldParent
      }
      return parent
    })
    setNewParentsWithChildrenList(newParentList)
  };

  /**
   * This function handles the updates when one of the involving parent is an unsave parent.
   * 
   * @param children A list of ARCustomer formatted as DraggableListItem
   * @param removeFromId The ID of the old Parent.
   * @param insertToId The ID of the new Parent.
   * @param tempId The temporary ID of an unsave parent.
   */
  const handleNewCustomers = (children: DraggableListItem[], removeFromId?: number, insertToId?: number, tempId?: number) => {
    const newParent = parentsWithChildrenList.find(parent => parent.id === insertToId && parent.tempId === tempId)
    const oldParent = parentsWithChildrenList.find(parent => parent.id === removeFromId && parent.tempId === tempId)
    const updatedNewList = newParentsWithChildrenList

    if(newParent){
      const defChildId = newParent.children.length < 1 ? children[0].recordId.toString() : newParent.defaultChildId;
      const newParentWithChild = {
        ...newParent,
        defaultChildId: defChildId,
        children: [...newParent.children, ...children]
      }
      updatedNewList.push(newParentWithChild)
    }

    if(oldParent){
      const filteredChildren = oldParent.children.filter((originalObj) =>
      !children.some((filterObj) => filterObj.recordId === originalObj.recordId));
      const newParentWithChild = {
        ...oldParent,
        defaultChildId: checkIfIdExists(children, oldParent.defaultChildId) ? filteredChildren[0].recordId.toString() : oldParent.defaultChildId,
        children: filteredChildren
      }
      updatedNewList.push(newParentWithChild)
    }

    setNewParentsWithChildrenList([...updatedNewList]);
  };

  /**
   * This function is used to determine if there is an existing primaryCustomer for a specific parent children.
   * 
   * @param list A list of ARCustomer formatted as DraggableListItem.
   * @param idToCheck The the parent's defaultChildId.
   * @returns A boolean value that ditermine if there is an exisitng primaryCustomer
   */
  const checkIfIdExists = (list: DraggableListItem[], idToCheck: string) => {
    return list.some(item => item.recordId.toString() === idToCheck);
  };
   
  /**
   * This function set the state of a specific parent to expanded.
   * 
   * @param parentId The ID of the parent to expand.
   * @param tempId The temporary ID of the parent to expand.
   */
  const handleExpand = (parentId: number, tempId?: number) => {
    setParentDisplay(
      parentDisplay.map(parent => 
        parentId === parent.id && tempId === parent.tempId? {...parent, isExpanded: !parent.isExpanded} : parent
      ))
    }
    
  /**
   * This function checks if the component should have a dark or light background color.
   * 
   * @param index The index of the component
   * @returns A boolean that determine if the component's index is odd or not.
   */
  const isOdd = (index: number) => index%2!==0

  const getParentDisplay = () => {
    const filteredList:IParentWithChild[] = []

    parentsWithChildrenList.forEach(parent => {
      if(parentPage.searchFilter === 'Parent' && parent.name.toLowerCase().includes(parentPage.searchKey.toLowerCase())){
        filteredList.push(parent)
      }else if(parentPage.searchFilter === 'Child' && parent.children.some(child => child.name.toLowerCase().includes(parentPage.searchKey.toLowerCase()))){
        filteredList.push({
          ...parent,
          isExpanded: true,
          children: parent.children.filter(child => child.name.toLowerCase().includes(parentPage.searchKey.toLowerCase()))
        })
      }else if (parentPage.searchFilter === 'All' && (parent.name.toLowerCase().includes(parentPage.searchKey.toLowerCase()) || parent.children.some(child => child.name.toLowerCase().includes(parentPage.searchKey.toLowerCase())))){
        const isExpanded = parent.children.some(child => child.name.toLowerCase().includes(parentPage.searchKey.toLowerCase()))  
        filteredList.push({...parent, isExpanded: parentPage.searchKey === '' ? false : isExpanded })
      }else if (parentPage.searchFilter === 'New' && (parent.isNew) && (parent.name.toLowerCase().includes(parentPage.searchKey.toLowerCase()) || parent.children.some(child => child.name.toLowerCase().includes(parentPage.searchKey.toLowerCase())))){
        const isExpanded = parent.children.some(child => child.name.toLowerCase().includes(parentPage.searchKey.toLowerCase()))  
        filteredList.push({...parent, isExpanded: parentPage.searchKey === '' ? false : isExpanded })
      }
    })

    const displayList = filteredList.slice(0, parentPage.pageNo * 10)

    setParentDisplay(displayList)
    setParentPage(prevState => ({...prevState, isLastPage: filteredList.length === displayList.length}))
  }

  // EVENT LISTENER - FOR INFINITE SCROLL

  /**
   * This function handle the infinite scrolling of the parent section.
   * 
   * @param node A component in which this function is attached as ref.
   */
  const lastRowParentElementRef = useCallback((node: any) => {
    if (parentPage.isLoading) return
    if (parentObserver.current) parentObserver.current.disconnect()
    
    parentObserver.current = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting && !parentPage.isLastPage) {
        setParentPage((prevState) => ({
          ...parentPage, 
          isLoading: true,
          pageNo: prevState.pageNo+1
        }))
      }
    })

    if (node) parentObserver.current.observe(node)
  }, [])

  /**
   * This function creates a new Parent AR Customer and add it on the parentList locally.
   * 
   * @param values Form Values
   */
    const handleSave = (values: IARCustomerFormValues) => {
      try {
          const filteredOrphan = unlinkedVendorsList.filter((originalObj) =>
          !selectedUnlinkedVendors.some((filterObj) => filterObj.recordId === originalObj.recordId));
          const newParent: IParentWithChild = ({
            tempId          : newParentId+1,
            id              : 0,
            name            : values?.parentCustName?.trimEnd() ?? '',
            defaultChildId  : '',
            isExpanded      : false,
            children        : selectedUnlinkedVendors,
            custName        : values?.parentCustName?.trimEnd(),
            custSrcId       : values?.parentCustSrcId,
            dbRating        : values?.dbRating,
            creditLimit     : values?.creditLimit,
            custCountry     : values?.country,
            custAddress1    : values?.address1,
            custAddress2    : values?.address2,
            custCity        : values?.city,
            custState       : values?.state,
            custPostalCode  : values?.zipCode,
            custPhone       : values?.phoneNumber,
            custDescription : values?.description
          })
          const filteredNewOrphans = newUnlinkedVendorsList.filter(originalObj => !selectedUnlinkedVendors.some((filterObj) => filterObj.recordId === originalObj.recordId))
          setParentsWithChildrenList([newParent, ...parentsWithChildrenList].sort((a, b) => a.name.localeCompare(b.name)))
          setNewParentsWithChildrenList([...newParentsWithChildrenList, newParent])
          setNewUnlinkedVendorsList(filteredNewOrphans)
          setUnlinkedVendorsList(filteredOrphan)
          setNewParentId(newParentId+1)
          setSelectedUnlinkedVendors([])
      } catch (error) {
        console.log('CREATE PARENT AR CUSTOMER: ', error)
      }
    }

  return (
    <>
      <Box component={Paper} sx={styles.sectionBox}>
        <Box sx={styles.clearButtonBox}>
          <Link 
            component={'button'} 
            sx={styles.clearButton}
            onClick={() => {
              props.setClearModal({
                isOpen: true,
                type: 'customer',
              });
            }}
          >
            Clear All New Customers
          </Link>
        </Box>
        <Box sx={{ marginBottom: '1rem' }}>
          <SearchParentChild
            filters={filters}
            onChange={value => {
              setParentPage({
                ...parentPage,
                pageNo: 1,
                searchKey: value,
                isLoading: true
              })
            }}
            onSelect={selected => {
              setParentPage({
                ...parentPage,
                pageNo: 1,
                searchFilter: selected,
                isLoading: true
              })
            }}
          />
        </Box>
        <TableContainer sx={styles.sectionTableContainer}>
          <Table stickyHeader>
            <TableHead sx={styles.sectionTableHead}>
              <TableRow sx={styles.sectionTableHeaderRow}>
                <TableCell sx={styles.sectionTableSelectCell} />
                <TableCell sx={styles.parentNameHeaderCell}>
                  <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'left' }}>
                    Customer Name
                  </Typography>
                </TableCell>
                <TableCell sx={styles.parentNameHeaderCell}>
                  <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'right' }}>
                    AR Amount
                  </Typography>
                </TableCell>
                <TableCell sx={styles.parentNameHeaderCell}>
                  <Typography sx={{...styles.sectionTableHeaderText, textAlign: 'right',}}>
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={styles.tableSpacer}></TableRow>
            </TableBody>
            <TableBody>
            { parentDisplay.map((parent, idx) => (
                <>
                <TableRow
                  data-testId={`parent-row-${idx}`}
                  sx={{...styles.parentRow, bgcolor: isOdd(idx) ? 'background.paper' : '#F7F7F7'}}
                  onClick={() => handleExpand(parent.id, parent.tempId)}
                  onDrop={(event) => {
                    const eventList = event.dataTransfer.getData("text/plain")
                    const formattedList = JSON.parse(eventList)
                    handleUpdate(formattedList, Number(formattedList[0].listName), parent.id, parent.tempId)
                  }}
                  onDragOver={(event) => { event.preventDefault() }}
                >
                  <TableCell sx={styles.parentSelectCell}>
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={styles.newTagBox}>
                        { parent.isNew && (
                          <NewTag />
                        )}
                      </Box>
                      { parent.isExpanded ? <KeyboardArrowUp sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> : <KeyboardArrowDown sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />}
                    </Box>
                  </TableCell>
                  <TableCell sx={styles.parentNameCell}>
                    <Box sx={styles.customerRowNameCellBox}>
                      <Box sx={styles.customerRowNameIdBox}>
                        <Typography
                          data-testId={`parent-name-typography-${parent.id}`}
                        >
                          {`${parent.name} (${parent.children.length})`}
                        </Typography>
                        <Typography tabIndex={0} sx={{fontSize: '12px', color: '#707070'}}>
                          {`${'Customer ID'}: ${parent.custSrcId}`}
                        </Typography>
                      </Box>
                      {(parent?.isCustParent || parent?.isUpcParent) && <ParentTag />}
                    </Box>
                  </TableCell>
                  <TableCell sx={{...styles.parentTableCell, textAlign: 'right' }}>
                    <Typography
                      sx={{ pl: '0.25rem'}}
                    >
                      {`${parent.arAmount ? formatCurrency(parent.arAmount) : `-`}`}
                    </Typography>
                  </TableCell>
                  <TableCell sx={styles.parentTableCell} />
                </TableRow>
                <TableRow sx={{ bgcolor: isOdd(idx) ? 'background.paper' : '#F7F7F7' }}>
                  <TableCell colSpan={100} sx={{ p: 0, borderBottom: 'none' }} data-testId={`parent-row-children-list-${idx}`}>
                    <Collapse in={parent.isExpanded}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={styles.childrenHeaderSelectCell}></TableCell>
                            <TableCell sx={styles.childrenHeaderNameCell}>
                              <Typography sx={styles.childrenHeaderTypography}>
                                Accounts Name
                              </Typography>
                            </TableCell>
                            <TableCell sx={styles.childrenHeaderCell}>
                              <Typography sx={styles.childrenHeaderTypography}>
                                Accounts Amount
                              </Typography>
                            </TableCell>
                            <TableCell sx={styles.childrenHeaderCell}>
                              <Typography sx={styles.childrenHeaderTypography}>
                                Action
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        { parent.children.length ? (
                          <DraggableTable
                          list={parent.children.map(item => ({ ...item, fixed: true }))}
                          multiple={true}
                          inParent={true}
                          inEditViewCustomer={true}
                          name={`${parent.id}`}
                          tempId={parent.tempId}
                          onItemRemove={(list, fromList, toList) => {
                            props.setModalProps({
                              ...props.modalProps,
                              tempId: parent.tempId,
                              isParent: false,
                              isOpen: true,
                              parentId: parent.id,
                              children: list,
                              from: Number(fromList),
                              to: Number(toList)
                            })
                          }}
                          onItemDragOver={(list) => { }}
                          onItemDragStart={() => {
                            props.setModalProps({
                              ...props.modalProps,
                              tempId: parent.tempId
                            })
                          }}
                          onItemDrop={(list, from, to, tempId) => handleUpdate(list, Number(from), Number(to), tempId)}
                        />
                        ): (
                          <TableBody>
                            <TableRow sx={{ bgcolor: isOdd(idx) ? 'background.paper' : '#F7F7F7' }}>
                              <TableCell colSpan={100} sx={{ borderBottom: 'none' }}>
                                <Typography tabIndex={0} sx={{ ...styles.flexCenter }}>
                                  Drag and Drop Vendor Name Here.
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
                </>
              ))}
            </TableBody>
            {/* Linear Progress */}
            
            {!parentPage.isLastPage ?
              <TableBody>
                <TableRow>
                  <TableCell colSpan={100}>
                      <LinearProgress ref={lastRowParentElementRef} />
                  </TableCell>
                </TableRow>
              </TableBody>: <></>
            }
          </Table>
        </TableContainer>
      </Box>
      {/* Create Parent Modal */}
      <CustomerModal 
        open={showParentModal}
        setOpen={setShowParentModal} 
        newParentId={newParentId} 
        setNewParentId={setNewParentId}
        customerList={parentsWithChildrenList}
        onSave={handleSave}
        isParent
      />
    </>
  )
}

export default CustomerSection