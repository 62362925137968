import CalculateBorrowingBaseButton from '../../../assets/images/CalculateBorrowingBase.svg';

const styles = {
  cancelButton: {
    textTransform: 'none',
    width: '5rem',
  },
  card: {
    height: '4rem',
  },
  cardButton: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    textAlign: 'left',
    overflow: 'hidden',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#154A8A',
    height: '100%',
    paddingLeft: '5.2rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '3.5% 50%',
    backgroundSize: '4rem',
    backgroundImage: `url(${CalculateBorrowingBaseButton})`,
  },
  comboBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginTop: '0.5rem',
  },
  dropdown: {
    fontSize: '14px',
    backgroundColor: 'white',
    width: '17rem',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  dropdownContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    width: '100%',
  },
  dropdownLabel: {
    whiteSpace: 'nowrap',
    marginRight: '1rem'
  },
  modalContainer: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30rem',
    bgcolor: '#fff',
    borderRadius: '0.3rem',
    boxShadow: '0 0 24px -8px rgba(0,0,0,0.75)',
    padding: '1.5rem',
  },
  proceedButton: {
    textTransform: 'none',
    width: '8rem',
  },
};
export default styles;
