import { Accordion, AccordionSummary, Box, Chip, Typography } from "@mui/material";
import { FC } from "react";
import { IIneligibleDetailsProps } from "../..";
import { formatNumber } from "../../../../../../utility/helper";
import styles from "./styles";

export interface IIneligibleRowWithoutCustomersProps extends IIneligibleDetailsProps {
  ineligibleDescription : string;
  ineligibleCode        : string;
  ineligibleAmt         : number;
}

/**
 * Component for showing the Ineligible Rows Without Customer Details
 * @param props Props for the Ineligible Rows Without Customer Details.
 * @returns Rendered Ineligible Rows Without Customer Details component.
 */
const IneligibleRowWithoutCustomers: FC<IIneligibleRowWithoutCustomersProps> = (props) => {
  return (
    <Accordion
      key={props.ineligibleCode}
      sx={styles.accordion}
      disableGutters
      elevation={0}
      square
    >
      <AccordionSummary
        tabIndex={-1}
        aria-controls={props.ineligibleDescription}
        id={props.ineligibleCode}
        sx={styles.accordionSummary}
      >
        <Box sx={styles.accordionRow}>
          <Box sx={styles.ineligibleDescriptionContainer}>
            <Typography tabIndex={0} fontSize='1rem' fontWeight='bold'>{props.ineligibleDescription}</Typography>
            {(props.ineligibleCode === 'RFV' && Boolean(props.hasNoRfv)) &&
              <Chip label='No Data' size='small' sx={styles.noDataIdentifierChip} />}
          </Box>
          <Typography tabIndex={0} fontSize='0.875rem'>
          {(props.ineligibleCode === 'RFV' && Boolean(props.hasNoRfv)) ? '--' :
            
            formatNumber(
              { style: 'currency', currency: props.currencyCode ?? 'USD', currencySign: 'accounting' },
              props.ineligibleAmt,
              props.exchangeRate
            )}
          </Typography>
        </Box>
      </AccordionSummary>
    </Accordion>
  )
}

export default IneligibleRowWithoutCustomers;