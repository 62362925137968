import { Box } from "@mui/material";
import styles from "../../styles";
import { FC } from "react";

export interface IProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  tabName: string
}

/**
 * Component for showing the tab panel in the users and roles page.
 * @param props The props for the TabPanel component. See the IProps interface for more information.
 */
const TabPanel: FC<IProps> = (props) => {
  const { ...allProps } = props;

  return (
    <div
      role="tabpanel"
      hidden={allProps.value !== allProps.index}
      id={`${allProps.tabName}-${allProps.index}`}
      aria-labelledby={`${allProps.tabName}-${allProps.index}`}
    >
      {allProps.value === allProps.index && <Box sx={styles.tabPanelBox}>{allProps.children}</Box>}
    </div>
  );
};

export default TabPanel;