import { useCallback, useContext, useMemo } from "react";
import { IAccountsReceivableSetting } from "../../../../components/client-settings/tabs/tab-panel-contents/accounts-receivable";
import { ARAgingReportContext } from "../../../../context/arAgingReportContext";
import { SelectedClientContext } from "../../../../context/selectedClientContext";
import { IClient, IARCollateral, IBBPeriod } from "../../../../interfaces";
import { IARAgingReportContext } from "../../../../interfaces/arAgingReportInterface";
import { ICurrency, IRate } from "../../../../interfaces/multiCurrencyInterface";
import { AMERICAN_DATE_FORMAT, NON_EXISTING } from "../../../../utility/constants";
import { formatDate } from "../../../../utility/helper";
import ARAgingReportHeader from "./report-header";
import ARAgingReportTable from "./report-table";
import { Box, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import styles from "./styles";
import ARAgingReportPageHeader from "../page-header";
import ARAgingFieldsContainer from "./fields-container";
import ARAgingChildClientDetailContainer from "./client-detail-container";
import WarningModal from "../../../../components/file-import/modals/warning-modal";
import { useNavigate } from "react-router-dom";

export const isSelectedClientUPC = (selectedClient: IClient | null) => selectedClient?.parentClient ?? false;

export const formatNumberBasedOnRate = (number: number, currency: ICurrency, exchangeRate: IRate | null) => {
  if (number === 0) { return number; }
  const rate = exchangeRate !== null ? exchangeRate.currencyRate : 1;
  const convertedNumber = number / rate ;
  const formattingOptions = { style: 'currency', currency: currency.currencyCode, currencySign: 'accounting' } as Intl.NumberFormatOptions;
  return new Intl.NumberFormat('en-US', formattingOptions).format(convertedNumber);
};

export const formatNumberBasedOnCurrency = (number: number, currency: ICurrency) => {
  if (number === 0) { return number; }
  const formattingOptions = { style: 'currency', currency: currency.currencyCode, currencySign: 'accounting' } as Intl.NumberFormatOptions;
  return new Intl.NumberFormat('en-US', formattingOptions).format(number);
};

export const convertNumberBasedOnRate = (number: number, exchangeRate: IRate | null) => {
  if (number === 0) { return number; }
  const rate = exchangeRate !== null ? exchangeRate.currencyRate : 1;
  return number / rate ;
};

const ARAgingMainContainer = () => {
  const { selectedClient, clients } = useContext(SelectedClientContext);
  const {
    selectedCollateral, selectedAsOfDate, selectedCurrency,
    arCollaterals, exchangeRates, isLoading, permissions,
    reports, buckets, hasGeneratedReport, filterText,
    showNoExchangeRate, setShowNoExchangeRate
  }  = useContext(ARAgingReportContext) as IARAgingReportContext;

  const navigate = useNavigate();

  const totalAmount = useMemo(() => reports.reduce((totalAmount, currentReport) => totalAmount + currentReport.total, 0), [reports]);

  const selectedExchangeRate = useMemo(() => {
    if ([selectedClient, selectedCollateral, selectedAsOfDate, selectedCurrency].some(field => field === null)) { return null; }
    const nonNullSelectedClient = selectedClient as Required<IClient>;
    const nonNullSelectedCollateral = selectedCollateral as IARCollateral;
    const nonNullSelectedAsOfDate = selectedAsOfDate as IBBPeriod;
    const nonNullSelectedCurrency = selectedCurrency as ICurrency;
    const selectedARSetting = arCollaterals.find(arCollateral => arCollateral.arCollateral.recordId === nonNullSelectedCollateral.recordId);
    if (selectedARSetting === undefined) { return null; }
    const selectedARBorrowerInput = (selectedARSetting as IAccountsReceivableSetting).arBorrowerInput;
    if (nonNullSelectedClient.currencyId === selectedARBorrowerInput.currency) { return null; }
    if (nonNullSelectedCurrency.recordId.toString() === selectedARBorrowerInput.currency) { return null; }
    const exchangeRate = exchangeRates.find(exchangeRate =>
      exchangeRate.toCurrencyId.toString() === selectedARBorrowerInput.currency &&
      formatDate(exchangeRate.asOfDate as string, AMERICAN_DATE_FORMAT) === formatDate(nonNullSelectedAsOfDate.endDate, AMERICAN_DATE_FORMAT)
    );
    return exchangeRate ?? null;
  }, [selectedClient, selectedCollateral, selectedAsOfDate, selectedCurrency, arCollaterals, exchangeRates]);

  const childClients = useMemo(() => {
    if (!selectedClient?.parentClient) { return []; }
    const currentChildClients = clients.filter(childClient => childClient.parentClientFk === selectedClient.recordId);
    const defaultClientIndex = currentChildClients.findIndex(currentChildClient => currentChildClient.default);
    currentChildClients.splice(0, 0, currentChildClients.splice(defaultClientIndex, 1)[0]);
    return currentChildClients;
  }, [selectedClient, clients]);

  const getExchangeRateForCollateral = useCallback((arSetting: IAccountsReceivableSetting) => {
    if (selectedAsOfDate === null) { return null; }
    const exchangeRate = exchangeRates.find(exchangeRate =>
      exchangeRate.toCurrencyId.toString() === arSetting.arBorrowerInput.currency &&
      formatDate(exchangeRate.asOfDate as string, AMERICAN_DATE_FORMAT) === formatDate(selectedAsOfDate.endDate, AMERICAN_DATE_FORMAT)
    );
    return exchangeRate ?? null;
  }, [exchangeRates, selectedAsOfDate]);

  const getUPCGrandTotalPerBucket = useCallback(() => {
    const formattedReportsByCollateralRate = reports.map(report => {
      const arSetting = arCollaterals.find(arSetting => arSetting.arCollateral.recordId === report.arCollateralId);
      if (arSetting === undefined) { return report; }
      const exchangeRate = getExchangeRateForCollateral(arSetting);
      const convertedCalcAgingBucket = Object.fromEntries(
        Object.entries(report.calcAgingBucket).map(([bucket, amount]) => [bucket, convertNumberBasedOnRate(amount, exchangeRate) ])
      );
      return ({
        ...report,
        total: convertNumberBasedOnRate(report.total, exchangeRate),
        calcAgingBucket: convertedCalcAgingBucket,
      });
    });
    return formattedReportsByCollateralRate.reduce((totalAmountByBucket, currentReport) => {
      const totalAmountByBucketToUpdate = { ...totalAmountByBucket };
      Object.entries(currentReport.calcAgingBucket).forEach(([bucket, amount]) => {
        if (totalAmountByBucketToUpdate[bucket] === undefined) { totalAmountByBucketToUpdate[bucket] = amount }
        else { totalAmountByBucketToUpdate[bucket] = totalAmountByBucketToUpdate[bucket] + amount; }
      });
      return totalAmountByBucketToUpdate;
    }, {} as {[bucket: string]: number});
  }, [arCollaterals, getExchangeRateForCollateral, reports]);

  const getWarningModalForExchangeRate = () => {
    return (
      <WarningModal
        open={showNoExchangeRate}
        onClose={() => setShowNoExchangeRate(false)}
        onConfirm={() => navigate(`/clients/${selectedClient?.recordId}/settings/exchangeRate`)}
        noDataIncluded
        issueType='error'
        issueMessages={["There is no exchange rate yet for the selected 'As of Date' and collateral currency. Click Proceed to visit Exchange Rate page"]}
      />
    )
  };

  const getTotalAmountContent = () => {
    if (!hasGeneratedReport || selectedCurrency === null || reports.length === 0) { return <></>; }
    const formattedTotalAmount = isSelectedClientUPC(selectedClient)
      ? formatNumberBasedOnCurrency(Object.values(getUPCGrandTotalPerBucket()).reduce((grandTotal, bucketTotal) => grandTotal + bucketTotal, 0), selectedCurrency as ICurrency)
      : formatNumberBasedOnRate(totalAmount, selectedCurrency as ICurrency, selectedExchangeRate);
    return (
      <Box sx={styles.reportHeaderSubContainer}>
        <Typography tabIndex={0} sx={styles.totalAmountText} component='label' htmlFor='totalAmount'>Total Amount</Typography>
        <Typography tabIndex={0} sx={styles.totalAmount} component='label' id='totalAmount'>
          { formattedTotalAmount }
        </Typography>
      </Box>
    );
  };

  const getUPCGrandTotalContent = () => {
    if (filterText !== '') { return <></>; }

    const getBucketLabel = (label: string) => {
      const position = label.search('Over');
      if (position === NON_EXISTING) { return `${label} Days`; }
      const regexResult = RegExp(/(\d+)/).exec(label);
      const numberPart = parseInt(regexResult ? regexResult[0] : '', 10);
      return `${numberPart + 1} and Over`;
    };

    const totalPerBucket = getUPCGrandTotalPerBucket();
    const grandTotal = Object.values(totalPerBucket).reduce((grandTotal, bucketTotal) => grandTotal + bucketTotal, 0);
    const formattedGrandTotal = formatNumberBasedOnCurrency(grandTotal, selectedCurrency as ICurrency);

    return (
      <Table size='small' sx={styles.tableForGrandTotal}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ ...styles.tableFooterText, ...styles.widthActionButton }}></TableCell>
            <TableCell sx={{ ...styles.tableFooterText, ...styles.widthCustomerId }}></TableCell>
            <TableCell sx={{...styles.tableFooterText, ...styles.widthCustomerName }}></TableCell>
            <TableCell tabIndex={0} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }} align='right'> Total </TableCell>
            {
              buckets.map(bucket => {
                return (
                  <TableCell tabIndex={0} align='right' key={bucket} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }}>
                    { getBucketLabel(bucket) }
                  </TableCell>
                );
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ ...styles.tableFooterText, ...styles.widthActionButton }}></TableCell>
            <TableCell tabIndex={0} sx={{ ...styles.tableFooterText, ...styles.widthCustomerId }}> Grand Total </TableCell>
            <TableCell sx={{...styles.tableFooterText, ...styles.widthCustomerName }}></TableCell>
            <TableCell tabIndex={0} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }} align='right'>
              { formattedGrandTotal !== 0 ? formattedGrandTotal : <></> }
            </TableCell>
            {
              buckets.map(bucket => {
                const formattedBucketTotal = formatNumberBasedOnCurrency(totalPerBucket[bucket], selectedCurrency as ICurrency);
                return (
                  <TableCell tabIndex={0} align='right' key={bucket} sx={{ ...styles.tableFooterText, ...styles.widthBuckets, ...styles.tableCellNumber }}>
                    { formattedBucketTotal !== 0 ? formattedBucketTotal : <></> }
                  </TableCell>
                );
              })
            }
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const getHeaderAndTableContent = () => {
    if (isSelectedClientUPC(selectedClient) && (isLoading || !hasGeneratedReport)) { return <></>; }
    if (isSelectedClientUPC(selectedClient)) {
      return  (
        <>
          { childClients.map(childClient => <ARAgingChildClientDetailContainer childClient={childClient} /> ) }
          { getUPCGrandTotalContent() }
        </>
      );
    }
    return (
      <>
        <ARAgingReportHeader selectedExchangeRate={selectedExchangeRate} />
        { !isLoading && hasGeneratedReport && <ARAgingReportTable selectedExchangeRate={selectedExchangeRate} /> }
      </>
    );
  };

  return (
    <Box sx={{ ...(!permissions?.viewARAgingReport && styles.hidden) }}>
      <ARAgingReportPageHeader selectedExchangeRate={selectedExchangeRate}  />
      <Box sx={styles.body}>
        <ARAgingFieldsContainer childClients={childClients} />
        { getWarningModalForExchangeRate() }
        { getTotalAmountContent() }
        { getHeaderAndTableContent() }
        { isLoading && <LinearProgress /> }
      </Box>
    </Box>
  );
};

export default ARAgingMainContainer;