import { useState, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Autocomplete, Box, Button, CircularProgress, Grid, IconButton, Modal, Paper, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AMERICAN_DATE_FORMAT, API_DOMAIN, GET, POST} from '../../../../utility/constants';
import { useIsFirstRender } from '../../../../utility/hooks';
import { usePrompt } from '../../../../utility/prompt';
import { checkRate, formatDate, isObjectsEqual } from '../../../../utility/helper';
import axiosInstance from '../../../../service/axiosInstance';
import { IARCollateral, IBBPeriod, IClient } from '../../../../interfaces';
import { CollateralDropdownProps, IIneligibleSettingsContext, IIneligibleSettingsPermissions, IUploadedDocumentsForBBPeriod } from '../../../../interfaces/ineligibleSettingInterface';
import { SelectedClientContext } from '../../../../context/selectedClientContext';
import { IneligibleSettingsContext } from '../../../../context/ineligibleSettingsContext';
import { CalcIneligibleContext } from '../../../../context/calneligibleContext';
import { setCalcIneligibleLoader } from '../../../../actions/calcIneligibleActions';
import ConfirmModal from '../../../modals/confirm-modal';
import { getActiveCollaterals } from '../../../client-settings/tabs/tab-panel-contents/accounts-receivable';
import { getErrors } from '../../form-container/ar-form-container';
import styles from './styles';
import WarningModal from '../../../file-import/modals/warning-modal';
import DisabledComponentsContainer from '../../../common/disabled-components-container';
import { getAsOfDates, getMostRecentlyUpdatedAsOfDate } from '../../../../api/ineligible-settings';
import { CalcIneligibleState } from '../../../../reducer/calcIneligibleReducer';

/**
 * This function fetches the uploaded AR aging documents for the selected collateral.
 * @param selectedCollateral The selected AR collateral.
 * @returns An array of uploaded documents from selected collateral.
 */
export const getUploadedARAgingDocuments = async (selectedCollateral: IARCollateral)  => {
  try {
    const response = await axiosInstance
      .request({
        url   : `${API_DOMAIN}/uploadedFile/search/findProcessedARAgingByARCollateralId`,
        method: GET,
        params: {arCollateralId: selectedCollateral.recordId}
      })
    if (response.data === undefined) { throw new Error(); }
    const uploadedDocuments: IUploadedDocumentsForBBPeriod[] = response.data;
    return uploadedDocuments;
  } catch (error) { console.log('GET UPLOADED AR AGING DOCUMENTS ERROR: ', error); return []; }
}

/**
 * Component for rendering collateral header on Ineligible Settings page.
 */
const ARCollateralHeader: React.FC<CollateralDropdownProps> = (props) => {
  const { formik  }                                                                   = props;
  const { selectedClient, setSelectedClient }                                         = useContext(SelectedClientContext);
  const {
    fetchedIneligibleSettingDetails,
    isIneligibleDetailsLoading, setIsIneligibleDetailsLoading,
    lastSelectedClient,
    isConfirmNavigationModalOpen, setIsConfirmNavigationModalOpen,
    isFormikDirty,
    setIsInvalidIneligibleSettingsMessageModalOpen,
    selectedCollateral, setSelectedCollateral,
    asOfDates, setAsOfDates,
    mostRecentlyUpdatedAsOfDate, setMostRecentlyUpdatedAsOfDate,
    permissions,
  }                                                                                   = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;
  const { dispatch: dispatchForCalcIneligible }                                       = useContext(CalcIneligibleContext);
  const [searchParams, setSearchParams]                                               = useSearchParams();
  const arCollateralId                                                                = searchParams.get('arCollateralId') ?? '0';
  const [lastSelectedCollateral, setLastSelectedCollateral]                           = useState<IARCollateral | null>(null);
  const [selectedAsOfDate, setSelectedAsOfDate]                                       = useState<IBBPeriod | null>(null);
  const [collateralInput, setCollateralInput]                                         = useState('');
  const [asOfDateInput, setAsOfDateInput]                                             = useState('');
  const [collaterals, setCollaterals]                                                 = useState<IARCollateral[]>([]);
  const [isPeriodSelectionModalOpen, setIsPeriodSelectionModalOpen]                   = useState(false);
  const [showNoExchangeRate, setShowNoExchangeRate]                                   = useState(false);
  const [isCollateralInputLoading, setIsCollateralInputLoading]                       = useState(false);
  const isFirstRender                                                                 = useIsFirstRender();
  const navigate = useNavigate();

  /**
   * This effect is responsible for handling changes in the 'selectedClient' state.
   * It triggers the 'getCollaterals' function.
   */
  useEffect(() => {
    if (selectedClient === null) { return; }
    getCollaterals();
  }, [selectedClient]);

  /**
   * This effect is responsible for handling changes in the 'selectedCollateral' state.
   * It resets the form using 'formik.resetForm', gets available as of dates using 'getAsOfDate',
   * and checks if 'selectedCollateral' is equal to 'lastSelectedCollateral'. If they are equal,
   * it does nothing; otherwise, it updates 'lastSelectedCollateral'.
   */
  useEffect(() => {
    formik.resetForm();
    if (isObjectsEqual(selectedCollateral, lastSelectedCollateral)) { return; }
    setLastSelectedCollateral(selectedCollateral);
  }, [selectedCollateral]);

  useEffect(() => {
    if (!isPeriodSelectionModalOpen) { return; }
    async function fetchSelectedAsOfDate() {
      const asOfDates = await loadAsOfDates();
      const mostRecentlyUpdatedAsOfDate = await loadMostRecentlyUpdatedAsOfDate(asOfDates);
      setSelectedAsOfDate(mostRecentlyUpdatedAsOfDate);
    }
    fetchSelectedAsOfDate();
  }, [isPeriodSelectionModalOpen]);

  /**
   * This function initiates the calculation of the ineligible report.
   */
  const calculateIneligibles = async () =>{
    const nonNullableSelectedClient = selectedClient as NonNullable<IClient> & Required<IClient>;
    const nonNullableSelectedCollateral = selectedCollateral as NonNullable<IARCollateral> & Required<IARCollateral>;
    const nonNullableSelectedAsOfDate = selectedAsOfDate as NonNullable<IBBPeriod> & Required<IBBPeriod>;
    const payload: CalcIneligibleState = {
      title: 'Calculating Ineligible Report',
      content: `Ineligible Report - ${nonNullableSelectedClient.borrowerName}`,
      hasInitiatedSubscription: false,
      isLoading: true,
      show: true,
      link: `/reports/ar-ineligible?clientId=${nonNullableSelectedClient.recordId}&bbPeriodId=${nonNullableSelectedAsOfDate.recordId}&arCollateralId=${nonNullableSelectedCollateral.recordId}`,
      cancelled: false,
      failed: false,
      selectedClientId: nonNullableSelectedClient.recordId,
      selectedCollateralId: nonNullableSelectedCollateral.recordId,
      isParentClientLevel: false,
      ineligibleSettings: fetchedIneligibleSettingDetails.map(detail => detail.ineligibleSetting),
    };
    dispatchForCalcIneligible(setCalcIneligibleLoader({ ...payload }));
    setIsPeriodSelectionModalOpen(false);
    try {
      await axiosInstance.request({
        url: `${API_DOMAIN}/ar/calculateIneligibles/ineligibleSettings`,
        method: POST,
        params: {
          borrowerId: nonNullableSelectedClient.recordId,
          bbPeriodId: nonNullableSelectedAsOfDate.recordId,
          arCollateralId: nonNullableSelectedCollateral.recordId,
        }
      });
      dispatchForCalcIneligible(setCalcIneligibleLoader({ ...payload, title: 'Calculation Finished', isLoading: false }));
    } catch (error) {
      dispatchForCalcIneligible(setCalcIneligibleLoader({ ...payload,  isLoading: false, failed: true, link: '' }));
    }
  }

  /**
   * This function retrieves the list of collaterals for the selected client.
   * If there are no collaterals, it sets the selected collateral to null and clears any loading state.
   */
  const getCollaterals = async () => {
    setIsCollateralInputLoading(true);
    formik.resetForm(); // to clear initial previous errors
    const nonNullSelectedClient = selectedClient as Required<IClient>;
    const collaterals = await getActiveCollaterals(nonNullSelectedClient.recordId);
    setCollaterals(collaterals);
    if (collaterals.length === 0) {
      setSelectedCollateral(null);
      setIsIneligibleDetailsLoading(false);
      setIsCollateralInputLoading(false);
      return;
    }

    if (isFirstRender && parseInt(arCollateralId)) {
      const collateralIndex = collaterals.findIndex(collateral => collateral.recordId === parseInt(arCollateralId));
      setSelectedCollateral(collaterals[collateralIndex]);
      setIsCollateralInputLoading(false);
    } else {
      const collateral = collaterals.find(collateral => collateral.default) ?? collaterals[0];
      setSelectedCollateral(collateral);
      updateSearchParams('arCollateralId', `${collateral.recordId}`);
      setIsCollateralInputLoading(false);
    }
  };

  const loadAsOfDates = async () => {
    if (asOfDates !== 'UNLOADED') { return asOfDates; }
    const loadedAsOfDates = await getAsOfDates(selectedClient as Required<IClient>, selectedCollateral as IARCollateral);
    setAsOfDates(loadedAsOfDates);
    return loadedAsOfDates;
  };

  const loadMostRecentlyUpdatedAsOfDate = async (asOfDates: IBBPeriod[]) => {
    if (mostRecentlyUpdatedAsOfDate !== 'UNLOADED') { return mostRecentlyUpdatedAsOfDate; }
    const loadedMostRecentlyUpdatedAsOfDate = await getMostRecentlyUpdatedAsOfDate(selectedCollateral as IARCollateral, asOfDates);
    setMostRecentlyUpdatedAsOfDate(loadedMostRecentlyUpdatedAsOfDate);
    return loadedMostRecentlyUpdatedAsOfDate;
  };

  /**
   * This function handles the closing of the period selection modal.
   */
  const handleClose = () => {
    setIsPeriodSelectionModalOpen(false);
  };

  /**
   * This function handles the action when the user decides to discard changes.
   * It closes the confirm navigation modal, resets the form, and reverts to the previous client and collateral selections
   */
  const handleDiscardChanges = () => {
    setIsConfirmNavigationModalOpen(false);
    formik.resetForm();
    setSelectedClient(lastSelectedClient);
    setSelectedCollateral(lastSelectedCollateral);
  };

  /**
   * This function updates the search parameters in the URL.
   * @param key The key to update in the search parameters
   * @param value The new value to set for the key
   */
  const updateSearchParams = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  /**
   * This function handles the action when the user proceeds with the calculation of ineligibles.
   * It checks the existence of an exchange rate and then initiates the calculation process.
   */
  const handleProceed = async () => {
    const nonNullSelectedClient = selectedClient as Required<IClient>;
    const nonNullSelectedCollateral = selectedCollateral as NonNullable<IARCollateral>;
    const nonNullSelectedAsOfDate = selectedAsOfDate as NonNullable<IBBPeriod>;
    const hasExchangeRate = await checkRate(nonNullSelectedCollateral.recordId, nonNullSelectedClient.recordId, formatDate(nonNullSelectedAsOfDate.endDate, AMERICAN_DATE_FORMAT));
    if(!hasExchangeRate) return setShowNoExchangeRate(true)
    calculateIneligibles()
  };

  /**
   * This function checks if all ineligible settings are disabled.
   * @returns True if all ineligible settings are disabled, otherwise false
   */
  const isAllIneligiblesDisabled = () => fetchedIneligibleSettingDetails.every(detail => detail.ineligibleSetting.disabled);

  /**
   * This customized hook prompts user if there are unsaved changes
   */
  usePrompt(`You have unsaved changes. Are you sure you want to leave this page?`, isFormikDirty);

  return (
    <Paper sx={styles.paperStyle}>
      <Modal
        open={isPeriodSelectionModalOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Grid container sx={styles.modalContainer}>
          <Grid item xs={12} marginBottom={2}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Typography tabIndex={0} id='modal-modal-title' variant='h6' component='h2'>
                Select Period
              </Typography>
              <IconButton aria-label='Close icon' onClick={handleClose}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} borderTop={1} borderColor='#E5E2E2'></Grid>
          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <Grid item xs={12} sx={{display: 'flex', justifyContent: 'spacebetw-between'}}>
                <Typography 
                  tabIndex={0}
                  component='label'
                  variant='h6'
                  align='center'
                  htmlFor={`collateral-combo-box`}
                  sx={styles.labelStyle}
                >
                  AR Collateral
                </Typography>
                <Autocomplete
                  id={`collateral-combo-box`}
                  blurOnSelect
                  disablePortal
                  getOptionLabel={(arCollateral) => arCollateral.arCollateralName}
                  inputValue={collateralInput}
                  options={collaterals}
                  value={selectedCollateral}
                  size='small'
                  fullWidth
                  sx={styles.comboBox}
                  disabled
                  renderInput={(params) => <TextField {...params} placeholder={'Please Select'} />}
                  aria-label='Collateral Dropdown'
                  componentsProps={{clearIndicator:{'aria-label':'Remove the selected item'}}}
                />
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <Grid item xs={12} sx={{display: 'flex', justifyContent: 'spacebetw-between'}}>
                <Typography 
                  tabIndex={0}
                  component='label'
                  variant='h6'
                  align='center'
                  htmlFor={`asOfDate-combo-box`}
                  sx={styles.labelStyle}
                  pr='1.1rem'
                >
                  As of Date
                </Typography>
                <Autocomplete
                  id={`asOfDate-combo-box`}
                  blurOnSelect
                  disablePortal
                  getOptionLabel={(asOfDates) => formatDate(asOfDates.endDate, AMERICAN_DATE_FORMAT)}
                  inputValue={asOfDateInput}
                  options={asOfDates !== 'UNLOADED' ? asOfDates : []}
                  value={selectedAsOfDate}
                  size='small'
                  fullWidth
                  sx={styles.comboBox}
                  disabled={selectedClient === null}
                  loading={[asOfDates, mostRecentlyUpdatedAsOfDate].includes('UNLOADED')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={'Please Select'}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            { [asOfDates, mostRecentlyUpdatedAsOfDate].includes('UNLOADED') ? <CircularProgress color='inherit' size={15} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  onInputChange={(_event, newInputValue) => setAsOfDateInput(newInputValue)}
                  onChange={(_event, newValue: IBBPeriod | null) => setSelectedAsOfDate(newValue)}
                  aria-label='Collateral Dropdown'
                  componentsProps={{clearIndicator:{'aria-label':'Remove the selected item'}}}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='flex-end'>
              <Button
                variant='outlined'
                size='small'
                disableElevation
                onClick={handleClose}
                sx={styles.cancelButton}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                size='small'
                disableElevation
                disabled={selectedAsOfDate === null || selectedCollateral === null}
                //check exchangeRate existence here
                onClick={() => handleProceed()}
                sx={styles.saveButton}
              >
                Proceed
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Box sx={styles.rowBox}>
        <Typography 
          tabIndex={0}
          component='label'
          variant='h6'
          align='center'
          htmlFor='collateral-combo-box'
          sx={styles.textStyle}
        >
          Collateral
        </Typography>
        <Autocomplete
          id={`collateral-combo-box`}
          blurOnSelect
          disablePortal
          getOptionLabel={(arCollateral) => arCollateral.arCollateralName}
          inputValue={collateralInput}
          options={collaterals}
          value={selectedCollateral}
          size='small'
          fullWidth
          sx={styles.comboBox}
          disabled={selectedClient === null || isIneligibleDetailsLoading || selectedClient?.parentClient}
          loading={isCollateralInputLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={'Please Select'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    { isCollateralInputLoading ? <CircularProgress color='inherit' size={15} /> : null }
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
          onInputChange={(_event, newInputValue) => setCollateralInput(newInputValue)}
          onChange={(_event, newValue: IARCollateral | null) => {
            setLastSelectedCollateral(newValue);
            if (isFormikDirty) {
              setIsConfirmNavigationModalOpen(true);
              return;
            }
            setSelectedCollateral(newValue);
            updateSearchParams('arCollateralId', `${newValue?.recordId}`)
          }}
          aria-label='Collateral Dropdown'
          componentsProps={{
            popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
            clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
          }}
        />
        <DisabledComponentsContainer isDisabled={isIneligibleDetailsLoading || selectedCollateral === null || isFormikDirty || isAllIneligiblesDisabled() || !(permissions as IIneligibleSettingsPermissions).canCalculateIneligibles}>
          <Button
            variant='outlined'
            disabled={isIneligibleDetailsLoading || selectedCollateral === null || isFormikDirty || isAllIneligiblesDisabled() || !(permissions as IIneligibleSettingsPermissions).canCalculateIneligibles}
            aria-label={isIneligibleDetailsLoading || selectedCollateral === null || isFormikDirty || isAllIneligiblesDisabled() || !(permissions as IIneligibleSettingsPermissions).canCalculateIneligibles ? 'Calculate Ineligibles button disabled' : 'Calculate Ineligibles'}
            sx={styles.calculateButton}
            onClick={async () => {
              const hasError = await getErrors(formik);
              if (hasError) { setIsInvalidIneligibleSettingsMessageModalOpen(true); return; }
              setIsPeriodSelectionModalOpen(true);
            }}
          >
            Calculate Ineligibles
          </Button>
        </DisabledComponentsContainer>
      </Box>
      <ConfirmModal
        open={isConfirmNavigationModalOpen}
        onConfirm={() => setIsConfirmNavigationModalOpen(false)}
        onClose={() => handleDiscardChanges()}
        onButtonClose={() => setIsConfirmNavigationModalOpen(false)}
        promptChecker={true}
        title='Confirm Navigation'
        description='You have unsaved changes. Are you sure you want to leave this page?'
        yesButtonText='Keep Editing'
        noButtonText='Discard Changes'
        confirmOnly
      />
      <WarningModal
        open={showNoExchangeRate}
        onClose={() => setShowNoExchangeRate(false)}
        onConfirm={() => navigate(`/clients/${selectedClient?.recordId}/settings/exchangeRate`)}
        noDataIncluded
        issueType='error'
        issueMessages={["There is no exchange rate yet for the selected 'As of Date' and collateral currency. Click Proceed to visit Exchange Rate page"]}
      />
    </Paper>
  )
};

export default ARCollateralHeader;
