const styles = {
  dateLabel: {
    display: 'flex', 
    width: '10%', 
    justifyContent: 'end'
  },
  dateField: {
    display: 'flex', 
    width: '90%', 
    alignItems: 'center',
    paddingLeft: '1rem',
    boxSizing: 'border-box',
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      padding: '0.51rem 1rem',
      fontSize: '14px'
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      marginTop: '2.3rem',
      marginLeft: '0.2rem'
    },
  },
  textFont: {
    fontSize: '14px'
  },
  saveButton: {
    marginLeft: '2rem',
    height: '31px',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    },
  },
  exchangeRateField: {
    '& .MuiOutlinedInput-input': {
      padding: '0.51rem 1rem',
      fontSize: '14px',
      textAlign: 'end'
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      marginTop: '2.3rem',
      marginLeft: '0.2rem'
    },
    '& .input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& .input[type="number"]': {
      MozAppearance: 'textfield',
    },
    width: '13%', 
  },
  header: {
    backgroundColor: '#F7F7F7',
    boxShadow: '0px 3px 6px #00000029',
    py: '1rem',
    mb: '1rem'
  },
  paper: {
    marginTop: '2rem', 
    padding: '2rem 1rem'
  },
  gridContainer: {
    my: '5rem', 
    justifyContent: 'center'
  },
  topContainer: {
    marginBottom: '1rem', 
    alignItems: 'center'
  },
  asOfDateHeader: {
    marginLeft: '1rem',
  },
  currencyNameContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '2.6rem',
  },
  rateContainer: {
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center',
    height: '2.6rem',
    paddingRight: '1rem',
  },
  asOfDateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2.6rem',
  },
  userContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2.6rem',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2.6rem',
  },
  paddingX: {
    px: '2.5rem'
  },
  userNameSpace: {
    ml: '2rem'
  },
  breadCrumbBox: {
    paddingRight: '24px',
    borderRadius: '0.3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clientDropdown: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  clientBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    color: '#010953',
    fontSize: '18px',
    ml: '24px',
  },
  titleContainer: {
    backgroundColor: '#FEFEFE',
    py: 2.5,
    mb: '2rem'
  },
  autoComplete: {
    width: '25%',
    background: 'white' 
  },
  iconDefaultSort: {
    color: '#767272',
    fontSize: '16px'
  },
  formLabel: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    marginTop: '0.5rem',
   '& .MuiFormLabel-asterisk': {
      color: '#db3131',
    },
    color: '#000',
  },
  viewActionIconButton: {
    marginRight: '10px',
  },
  actionIcon: {
    fontSize: 25,
    color: '#154A8A',
    padding: '0',
    ':hover': {
      cursor: 'pointer',
    },
  },
  table: {
    '&.MuiTable-root': {
      minWidth: '1046px',
    },
  },
  skeletonClientCurrency: {
    fontSize: '1.5rem',
    width: '11.5rem'
  }
}

export default styles