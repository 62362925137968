import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { FC, useState, MouseEvent, useRef, useCallback, Dispatch, SetStateAction, useMemo } from "react";
import { IARIneligible, ICapDetails } from "../../../..";
import { getComparator, getHeaderTooltip, Order, stableSort } from "../../../../../../../utility/helper";
import styles from "./styles";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ARIneligibleTableRow from "./details";
import IneligibleCapDetails from "./cap-details";

export interface IARIneligibleTableProps extends ITableSortProps {
  isLoading           : boolean;
  arIneligible        : IARIneligible[];
  amountType          : string;
  parentIds           : number[];
  custIdsWithDetails  : {[key: string]: number[]};
  currencyCode?       : string;
  exchangeRate?       : number;
  isUltimateParent    : boolean;
  capDetails?         : ICapDetails;
}

export interface IARIneligibleNumberedBoolean {
  isDefaultBorrower: number;
  isDefaultCollateral: number;
  isUpcParentCustomer: number;
}

interface ITableSortProps {
  order     : Order;
  setOrder  : Dispatch<SetStateAction<Order>>;
  orderBy   : keyof IARIneligible;
  setOrderBy: Dispatch<SetStateAction<keyof IARIneligible>>;
}

interface IEnhancedTableProps {
  onRequestSort : (event: MouseEvent<unknown>, property: keyof IARIneligible) => void;
  order         : Order;
  orderBy       : keyof IARIneligible;
  handleSort    : () => void;
}

interface IHeadCell {
  id    : keyof IARIneligible;
  label : string;
}

/**
 * This is an array of header cells with their associated id and label.
 */
const headCells: readonly IHeadCell[] = [
  { id: 'custSrcId',  label: 'Customer ID' },
  { id: 'custName',   label: 'Customer Name' },
  { id: 'amount',     label: 'Ineligible Amount' },
];

/**
 * This component renders the table header (<TableHead>) for an enhanced
 * table.
 *
 * @param props - Props for EnhancedTableHead.
 * @returns - The rendered EnhancedTableHead component.
 */
const EnhancedTableHead: FC<IEnhancedTableProps> = (props) => {

  /**
   * Renders the default sort icon indicating the potential for sorting but
   * not an active sort direction.
   *
   * @returns - The UnfoldMoreIcon component.
   */
  const handleIcon = useCallback(() => <UnfoldMoreIcon sx={styles.iconDefaultSort}/>, []);

  /**
   * This method creates a handler for sorting the table based on the given
   * property.
   * @param property - The property of the IARIneligible type to be sorted.
   *
   * @returns - The handler function that invokes `onRequestSort` and
   * `handleSort` from props with appropriate parameters.
   */
  const createSortHandler = (property: keyof IARIneligible) => (event: MouseEvent<unknown>) => {
    props.onRequestSort(event, property);
    props.handleSort();
  };

  /**
   * This method creates a determines the aria label based on the head cell id and the order
   * @param headCell - The headCell of the table.
   *
   * @returns - The aria label for the TableSortLabel component.
   */

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{...styles.tableHeader, ...styles.colSpacer}}></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={props.orderBy === headCell.id ? props.order : false}
            sx={{...(headCell.id === 'amount' && styles.amountTableHeader || styles.tableHeader)}}
            colSpan={headCell.id === 'custSrcId' ? 2 : 1}
          >
            <Tooltip title={getHeaderTooltip(props.orderBy, props.order, headCell)}>
              <TableSortLabel
                active={props.orderBy === headCell.id}
                direction={props.orderBy === headCell.id ? props.order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                aria-label={`${headCell.label} Sort`}
                IconComponent={(props.orderBy === headCell.id) ? undefined : handleIcon}
              >
                {headCell.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
        <TableCell sx={{...styles.tableHeader, ...styles.colSpacer}}></TableCell>
      </TableRow>
    </TableHead>
  );
}

/**
 * Component for rendering the showing the main AR Ineligible table.
 *
 * @param props - Props required to render the ARIneligibleTable component.
 *
 * @returns - The rendered ARIneligibleTable component.
 */
const ARIneligibleTable: FC<IARIneligibleTableProps> = (props) => {
  const [page, setPage]               = useState<number>(0);
  const [triggerSort, setTriggerSort] = useState<boolean>(false);
  const rowsPerPage                   = useMemo(() => 10, []);

  const hasMore = useMemo(() => {
		return props.arIneligible.slice(0, page * rowsPerPage + rowsPerPage).length < props.arIneligible.length;
	}, [props.arIneligible, page, rowsPerPage]);

  const forSortableColumns = useMemo(() => {
    return props.arIneligible.map(ineligible => {
      return ({
        ...ineligible,
        upcParentCustomerId: ineligible.upcParentCustomerId ?? 0,
        upcParentCustomerSrcId: ineligible.upcParentCustomerSrcId ?? 0,
        upcParentCustName: ineligible.upcParentCustName ?? '',
        parentArCustomerId: ineligible.parentArCustomerId ?? 0,
        comments: ineligible.comments ?? '',
        startDate: ineligible.startDate ?? '',
        isDefaultBorrower: ineligible.isDefaultBorrower ? 1 : 0,
        isDefaultCollateral: ineligible.isDefaultCollateral ? 1 : 0,
        isUpcParentCustomer: ineligible.isUpcParentCustomer ? 1 : 0,
        hasDetails: ineligible.hasDetails ? 1 : 0,
      })
    })
  }, [props.arIneligible])

  const observer                      = useRef<any>();

  /**
   * This useCallback is used to set up an intersection observer on the
   * last table row for infinite loading.
   */
  const lastRowElementRef             = useCallback((node: any) => {
    if (props.isLoading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      (entries[0].isIntersecting && hasMore) && setPage((prevValue) => prevValue + 1);
    })

    if (node) observer.current.observe(node);
  }, [props.isLoading, hasMore]);


  /**
   * This callback handler is used to set the order and orderBy properties
   * based on user interactions.
   */
  const handleRequestSort = useCallback((_event: MouseEvent<unknown>, property: keyof IARIneligible) => {
    const isDesc = props.orderBy === property && props.order === 'desc';
    props.setOrder(isDesc ? 'asc' : 'desc');
    props.setOrderBy(property);
  }, [props.orderBy, props.order])

  /**
   * This callback handler toggles the triggerSort state, effectively
   * signaling that the table sorting should be triggered.
   */
  const handleSort = useCallback(() => setTriggerSort(!triggerSort), [triggerSort]);

  return (
    <>
    <TableContainer sx={styles.tableContainer}>
      <Table stickyHeader aria-label='Ineligible Customers'>
        <EnhancedTableHead
          order={props.order}
          orderBy={props.orderBy}
          onRequestSort={handleRequestSort}
          handleSort={handleSort}
        />
        <TableBody>
          {stableSort(forSortableColumns, getComparator(props.order, props.orderBy, 'others'))
            .slice(0, page * rowsPerPage + rowsPerPage)
            .map((row, index, array) => {
              const lastRow = array.length === index + 1;
              return (
                <ARIneligibleTableRow
                  key={row.id}
                  row={row}
                  lastRow={lastRow}
                  lastRowElementRef={lastRowElementRef}
                  triggerSort={triggerSort}
                  {...props}
                />
              )
          })}
        </TableBody>
      </Table>
    </TableContainer>

    {props.capDetails &&
      <IneligibleCapDetails
        capDetails={props.capDetails}
        {...props}
      />}
    </>
  )
}
export default ARIneligibleTable;