import colors from "../../../utility/colors";

const styles = {
  breadcrumbsContainer: {
    marginLeft: '-1.5rem'
  },
  clientDropdownContainer: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  clientDropdownBox: {
    width: '100%',
    maxWidth: '398.72px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownsContainer: {
    marginTop: '1rem',
    marginBottom: '1.5rem',
  },
  generateReportButton: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '10rem',
    height: '2rem',
    marginTop: '2.05rem'
  },
  headerActionWrapper: {
    display: 'flex',
    gap: '0.5rem'
  },
  headerButtons: {
    height: '2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  headerContainer: {
    backgroundColor: '#FEFEFE',
    paddingX: '1.5rem',
    paddingY: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerTitle: {
    color: colors.PRIMARY_DARK,
    fontSize: '18px',
    fontWeight: 'bold',
  },
  pageContainer: {
    paddingX: '1.5rem',
  },
  paperContainer: {
    paddingY: '1rem',
    boxShadow: '0px 3px 6px #00000029',
  },
  varianceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  varianceText: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    marginRight: '1rem',
    fontWeight: 'bold',
  },
  variance: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  rateLabel: {
    justifyContent: 'end',
    width: '100%',
    mb: '1rem',
  },
  textFont: {
    font: '14px'
  },
}
export default styles;