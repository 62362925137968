import colors from "../../../../utility/colors";

const styles = {
  actionContainer: {
    display: 'flex'
  },
  bodyContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    marginX: '1rem',
  },
  bodyChildren: {
    flex: 1,
    border: '1px solid red'
  },
  buttonsContainerDisp: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '0.25rem',
    paddingX: '1rem',
    paddingBottom: '1rem'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.25rem',
    paddingX: '1rem',
    paddingBottom: '1rem'
  },
  buttonCancel: {

  },
  buttonProceed: {
    marginLeft: '0.5rem'
  },
  closeIcon: {
    fontSize: '1rem',
    fontWeight: 600
  },
  divider: {
    marginY: '0.5rem',
    marginX: '1rem'
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingX: '1.5rem',
    paddingY: '0.8rem',
    backgroundColor: '#ffe49c',
  },
  headingContainerError: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingX: '1.5rem',
    paddingY: '0.8rem',
    backgroundColor: '#ffcccb',
  },
  headingTitle: {
    color: '#64531b',
    fontSize: '1.25rem',
    fontWeight: 600
  },
  headingTitleError: {
    color: '#AE2633',
    fontSize: '1.25rem',
    fontWeight: 600
  },
  headingTitleAndIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  modalContainer: {
    bgcolor: `${colors.WHITE}`,
    borderRadius: '0.2rem',
    position: 'absolute',
    left: '50%',
    top: '50%',
    boxShadow: 15,
    transform: 'translate(-50%, -50%)',
    width: '40rem',
    maxHeight: '20rem',
    display: 'flex',
    flexDirection: 'column',
  },
  warningIcon: {
    color: '#AE2633',
    fontSize: '2.5rem',
    marginRight: '0.3rem'
  },
  errorIcon: {
    color: '#AE2633',
    fontSize: '2.5rem',
    marginRight: '0.3rem'
  },
  issueMessageContainer: {
    display: 'flex',
    placeContent: 'flex-start',
    paddingLeft: '1rem'
  },
  issueMessage: {
    whiteSpace: 'pre-line'
  },
  issueMessageSmaller: {
    fontSize: '0.9375rem',
  },
  bulletIcon: {
    fontSize: '0.5rem',
    marginTop: '0.4rem',
    marginRight: '1rem'
  },
};
export default styles;