const styles = {
  listText: {
    fontSize: '12px',
  },
  disabledText: {
    color: '#767676',
  },
  bullet: {
    width: '12px',
    color: '#154A8A',
    height: '12px',
    paddingRight: '5px'
  },
  disabledBullet: {
    color: '#767676'
  }
};

export default styles;