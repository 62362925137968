import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './styles';
import packageJson from '../../../package.json';

const Footer: FC = () => {
  return (
      <Box
        sx={styles.footerContainer}
      >
        <Typography tabIndex={0} sx={styles.footerVersion}>
          {`v${packageJson.version}`}
        </Typography>
      </Box>
  );
};

export default Footer;