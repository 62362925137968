import colors from "../../../../../../utility/colors";

const styles = {
  accordion: {
    display: 'block',
    position: 'relative',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    backgroundColor: '#F2F7F8',
    display: 'flex',
    borderBottom: '2px solid #E9ECEF',
    alignItems: 'flex-end',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
      ariaLabel: 'Collapse icon'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      marginLeft: 1,
    },
    paddingLeft: '2rem',
    paddingRight: '0.5rem',
  },
  accordionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  noDataIdentifierChip: {
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    marginLeft: '0.5rem'
  },
  ineligibleDescriptionContainer: {
    display: 'flex'
  }
}
export default styles;