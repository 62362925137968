const styles = {
  accordionSummary: {
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)'
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: '0px'
  },
  justifyBetween: {
    justifyContent: 'space-between'
  },
  textFlex: {
    display: 'flex',
    justifyContent: 'center'
  },
  noPadding: {
    padding: '0px'
  },
  fullWidth: {
    width: '100%'
  },
  halfWidth: {
    width: '45%'
  },
  smallWidth: {
    display: 'flex',
    justifyContent: 'center',
    width: '10%'
  },
  listItem: {
    bgcolor: '#EAECEF',
    display: 'flex',
    fontWeight: 'bold',
    py: '10px',
    width: 'relative'
  },
  firstColItem: {
    alignItems: 'center',
    justifyContent: 'start'
  },
  firstColumn: {
    boxSizing: 'border-box',
    justifyContent: 'start',
    paddingLeft: '60px'
  },
  iconAction: {
    color: '#154A8A',
  },
  parentBox: {
    display: 'flex', 
    fontWeight: 'bold', 
    boxShadow: '0px 3px 6px #00000029', 
    padding: '15px 16px', 
    my: '15px', 
    backgroundColor: '#F7F7F7'
  },
  overflowBox: {
    overflowY: 'auto', 
    maxHeight: '15rem'
  },
  paddingLeft: {
    pl: '1.55rem'
  },
  iconDefaultSort: {
    color: '#767272',
    fontSize: '16px'
  },
  settingsContainer: {
    border: '1px solid #EBEBEB',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '0.2rem',
    paddingBottom: '1.8rem',
    marginY: '1rem',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '0.5rem',
    marginRight: '8rem',
  },
  textfieldNumber: {
    width: '12.7rem',
    '& .MuiOutlinedInput-input': {
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      textAlign: 'right',
      fontSize: '0.8125rem',
      'WebkitBoxShadow': 'none',
    },
    '& .Mui-error': {
      fontSize: '11px',
      marginTop: '1px',
      marginX: '0',
    },
  },
  label: {
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    marginTop: '0.5rem',
    marginRight: '0.5rem',
   '& .MuiFormLabel-asterisk': {
      color: '#db3131',
    },
    color: '#000',
  },
  asterisk:{
    color: '#AE2633'
  },
  textfieldString: {
    width: '12.7rem',
    marginBottom: '12px',
    '& .MuiOutlinedInput-input': {
      fontSize: '0.8125rem',
      'WebkitBoxShadow': 'none',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      marginTop: '2.3rem',
      marginLeft: '0.2rem'
    },
  },
  buttonGroup: {
    alignSelf: 'flex-end',
    display: 'flex',
    gap: '1rem',
  },
  cancelButton: {
    textTransform: 'none',
    height: '2rem',
  },
  saveButton: {
    textTransform: 'none',
    height: '2rem',
    width: '10rem',
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#99BFED',
    },
  },
  contactTitle: {
    borderBottom: 'solid 1px #E0E0E0',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginLeft: '22%',
    marginBottom: '0.9rem',
    paddingBottom: '0.5rem',
  },
  contactInputContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '0.5rem',
  },
  contactLabel: {
    fontSize: '0.875rem',
    marginRight: '1rem',
    whiteSpace: 'nowrap',
  },
  textFieldCommon: {
    width: '21.3rem',
    '.MuiOutlinedInput-input': {
      'WebkitBoxShadow': 'none',
      fontSize: '13px',
    }
  },
  textFieldSmall: {
    width: '6.8rem',
    '.MuiOutlinedInput-input': {
      'WebkitBoxShadow': 'none',
      fontSize: '13px',
    }
  },
  contactInformation: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  contactInfoContainer: {
    marginRight: '0.85rem'
  },
  stateAndZipContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '3.1rem',
    justifyContent: 'flex-end',
  },
}

export default styles