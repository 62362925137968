import { Autocomplete, AutocompleteRenderInputParams, Chip, Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme } from '@mui/material';
import { IAlignment } from '../../../../../../../../../interfaces';
import { FC, HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldArray } from 'formik';
import { ICalculationMapping, IRollForwardCategory } from '../../../../../../../../../interfaces/fileimport';
import styles from '../styles';

export interface ICalculationMappingTableProps {
  rfCategories        : IRollForwardCategory[];
  calculationMappings : ICalculationMapping[];
  isLoading           : boolean
}

interface IHeadCell {
  id        : string;
  label     : string;
  alignment : IAlignment;
  style     : SxProps<Theme>;
}

 const headCells: readonly IHeadCell[] = [
  {id: 'glTrxType', label: 'Type', alignment: 'left', style: {...styles.cellHeader, ...styles.cellType}},
  {id: 'amtSign', label: 'Amount', alignment: 'center', style: {...styles.cellHeader, ...styles.cellAmt}},
  {id: 'rfCategory', label: 'Roll Forward Category', alignment: 'left', style: {...styles.cellHeader, ...styles.cellCategory}},
]

/**
 * This function defines a Paper component with custom styles.
 * @param props The HTML attributes and children of the Paper component.
 * @returns A JSX element representing the Paper component with custom styles.
 */
const PaperComponent = (props: HTMLAttributes<HTMLElement>) => (<Paper sx={styles.dropdownList}>{props.children}</Paper>);

/**
 * Component for the Calculation Mapping Table of the Calculation Mapping Step.
 * @param props The props for the Calculation Mapping Table of the Calculation Mapping Step.
 * @returns A component for the Calculation Mapping Table of the Calculation Mapping Step.
 */
const CalculationMappingTable: FC<ICalculationMappingTableProps> = (props) => {
  const { rfCategories, calculationMappings,
          isLoading }                                 = props;
  const [pageNumber, setPageNumber]                   = useState<number>(0);
  const rowsPerPage                                   = useMemo(() => 10, []);
  const [hasMore, setHasMore]                         = useState<boolean>(false);

  /**
   * This effect updates the 'hasMore' state based on the current length of a sliced array of 'calculationMappings'
   * and the total length of the 'calculationMappings' array. It depends on the 'calculationMappings' and 'pageNumber'
   * variables.
   */
  useEffect(() => {
    const currentLength = calculationMappings.slice(0, pageNumber * rowsPerPage + rowsPerPage).length;
    const totalLength   = calculationMappings.length;

    if (currentLength >= totalLength) setHasMore(false);
    else setHasMore(true);
  }, [calculationMappings, pageNumber]);

  /**
   * Ref to hold the IntersectionObserver instance for infinite scrolling.
   */
  const observer          = useRef<any>();

  /**
   * Callback function to set up the IntersectionObserver on the last row element.
   */
  const lastRowElementRef = useCallback((node: any) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) setPageNumber((prevValue) => prevValue + 1);
    })

    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  /**
   * This function returns the label for the amount sign based on the provided sign value.
   * @param amtSign The amount sign, either 'positive' or 'negative'.
   * @returns The label for the amount sign ('Positive (+)' or 'Negative (-)').
   */
  const getAmountSignLabel = (amtSign: string) => {
    if (amtSign === 'positive') return 'Positive (+)';
    else return 'Negative (-)';
  };

  /**
   * This function finds a roll-forward category by its ID.
   * @param rfCategoryId The ID of the roll-forward category to find.
   * @returns The roll-forward category with the specified ID, or null if not found.
   */
  const findRfCategoryById = (rfCategoryId?: number) => {
    return rfCategories.find(category => category.rollForwardCategoryId === rfCategoryId) ?? null;
  };
  
  return (
    <TableContainer sx={{ width: '100%' }}>
      <Table stickyHeader aria-label='Calculation Mapping Table' sx={styles.table}>
        <TableHead sx={styles.rowHeader}>
          <TableRow>
          {headCells.map((header: IHeadCell) => {
            return (<TableCell align={header.alignment} key={header.id} sx={header.style}>{header.label}</TableCell>)})}
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray name="calculationMappings">
            {({form}) => (
              <>
              {form.values.calculationMappings
                .slice(0, pageNumber * rowsPerPage + rowsPerPage)
                .map((calculationMapping: ICalculationMapping, index: number, array: ICalculationMapping[]) => {
                  const isLastRow = array.length - 1 === index;

                  return (
                    <TableRow
                      key={calculationMapping.calculationMappingId}
                      ref={isLastRow ? lastRowElementRef : undefined}
                      aria-label={`${calculationMapping.glTrxType}-${calculationMapping.amtSign}`}
                    >
                      <TableCell tabIndex={0} sx={{...styles.tableCell, ...styles.cellType}}>
                        {calculationMapping.glTrxType}
                      </TableCell>
                      <TableCell tabIndex={0} align='center' sx={{...styles.tableCell, ...styles.cellAmt}}>
                        <Chip
                          tabIndex={0}
                          label={getAmountSignLabel(calculationMapping.amtSign)}
                          variant={calculationMapping.amtSign === 'negative' ? 'outlined' : 'filled'}
                          color='primary'
                        />
                      </TableCell>
                      <TableCell sx={{...styles.tableCell, ...styles.cellCategory}}>
                        <Autocomplete
                          getOptionLabel={(option: IRollForwardCategory) => option.categoryName}
                          options={rfCategories} // get rf categories
                          onChange={(_, value: IRollForwardCategory | null) => { 
                            if (value) form.setFieldValue(`calculationMappings.${index}.rfCategoryId`, value.rollForwardCategoryId)
                            // necessary for payload optionals & isDirty
                            else form.setFieldValue(`calculationMappings.${index}.rfCategoryId`, undefined);
                          }}
                          value={findRfCategoryById(calculationMapping.rfCategoryId)}
                          renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField
                              {...params}
                              placeholder='Select Field'
                              name={`calculationMappings.${index}`}
                              value={findRfCategoryById(calculationMapping.rfCategoryId)}
                            />
                          )}
                          PaperComponent={PaperComponent}
                          size='small'
                          aria-label='Roll Forward Category Selection Dropdown'
                          componentsProps={{
                            popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
                            clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
                          }}
                          sx={styles.dropdown}
                        />
                      </TableCell>
                    </TableRow>
                  )})}
              </>
            )}
          </FieldArray>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CalculationMappingTable