const styles = {
  actionsIcon: {
    fontSize: 25,
    color: '#154A8A',
    padding: '0',
    ':hover': {
      cursor: 'pointer',
    },
  },
  button: {
    height: '31px',
    textTransform: 'capitalize',
    width: '82px',
  },
  buttonContainer: {
    display: 'flex',
    gap: 1,
    justifyContent: 'end',
    mt: '15.5px',
  },
  buttonText: {
    color: '#010953',
    fontSize: '14px',
    fontWeight: 'normal',
    marginLeft: '5px'
  },
  disabled:{
    color: '#A6A8AA'
  },
  verticalDivider:{ 
    borderColor: '#154A8A',
    borderLeftWidth: '1px', 
    my: '5px',
    mx: '10px' 
  },
  whiteContainer: {
    border: '1px solid #E0E0E0',
    mx: '1rem',
    padding: '1rem',
    marginBottom: '0rem'
  },
  hidden: {
    display: 'none'
  },
  invisible: {
    visibility: 'hidden'
  }
}

export default styles