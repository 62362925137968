import colors from "../../utility/colors";

const styles = {
  cancelButton: {
    textTransform: 'none',
    width: '5rem',
  },
  dialog: {
    backgroundColor: colors.WHITE,
    borderRadius: '0.5rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '31rem',
    padding: '2rem',
  },
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '0.5rem',
    paddingBottom: '0.5rem',
    marginBottom: '0.5rem',
    borderBottom: `1px solid ${colors.NEUTRAL}`,
  },
  dropdown: {
    fontSize: '14px',
    backgroundColor: 'white',
    width: '17rem',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      fontSize: '14px',
      backgroundColor: 'white',
    },
    '& .Mui-disabled': {
      backgroundColor: '#F7F7F7',
    },
  },
  dropdownContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    width: '100%',
  },
  dropdownLabel: {
    whiteSpace: 'nowrap',
    marginRight: '1rem'
  },
  dropdownTitleContainer: {
    borderBottom: `1px solid ${colors.NEUTRAL}`,
    paddingBottom: '1rem',
    marginBottom: '1rem',
  },
  info: {
    paddingX: '0.5rem',
    paddingY: 0,
    backgroundColor: `#FFFCF4`,
  },
  infoIcon: {
    fontSize: '1rem',
    marginTop: '0.1rem'
  },
  infoMessage: {
    fontSize: '12px',
  },
  ineligibleLink: {
    color: colors.PRIMARY,
    cursor: 'pointer',
  },
  loadingIcon: {
    marginRight: '2rem'
  },
  proceedButton: {
    textTransform: 'none',
    width: '8rem',
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#99BFED',
    },
  },
  warningMessage: {
    marginTop: '-0.5rem',
    marginBottom: '1rem',
  },
}
export default styles;