import { useContext } from 'react';
import { Card } from '@mui/material';
import { FileImportProcessContext } from '../../../context/fileImportProcessContext';
import { IUploadedFile } from '../../../interfaces/fileimport';
import styles from './styles';
import SubscribingComponent from './subscribing-component';
import { StompSessionProvider } from 'react-stomp-hooks';
import { WEB_SOCKET_DOMAIN } from '../../../utility/constants';

export interface FileImportLoaderProps {
  content: string;
  link: string;
  show: boolean;
  newDocuments: IUploadedFile[];
}

/**
 * A component for rendering the file import loader.
 * @param props The props to configure the component.
 * @returns The rendered ImportFileLoader component.
 */
const ImportFileLoader = (props: FileImportLoaderProps) => {
  const { state, dispatch } = useContext(FileImportProcessContext);

  return (
    <Card variant="outlined" sx={state.hideOnly ? styles.hidden : styles.container}>
      <StompSessionProvider url={`${WEB_SOCKET_DOMAIN}/calc`}>
        <SubscribingComponent
          state={state}
          dispatch={dispatch}
          {...props}
        />
      </StompSessionProvider>
    </Card>
  )
}

export default ImportFileLoader;