import loginPageBg from '../assets/images/loginPageBg.jpg';
import BorrowingBaseReportIcon from '../assets/images/BorrowingBaseReportIcon.svg';
import ARAgingReportIcon from '../assets/images/ARAgingReportIcon.svg';
import APAgingReportIcon from '../assets/images/APAgingReportIcon.svg';
import ARIneligibleReportIcon from '../assets/images/ARIneligibleReportIcon.svg';
import InventoryValuationReportIcon from '../assets/images/InventoryValuationReportIcon.svg';
import RollForwardReportIcon from '../assets/images/RollForwardReportIcon.svg';
import BalanceSheetReportIcon from '../assets/images/BalanceSheetReportIcon.svg';
import FinancialMappingReportIcon from '../assets/images/FinancialMappingReportIcon.svg';
import ProfitAndLossStatementReportIcon from '../assets/images/ProfitAndLossStatementReportIcon.svg';
import FileImportUploadEmpty from '../assets/images/FileImportUploadEmpty.svg';
import IneligibleAdjustmentsIcon from '../assets/images/IneligibleAdjustments.svg';
import LoanBalanceIcon from '../assets/images/LoanBalance.svg';
import ReservesIcon from '../assets/images/Reserves.svg';
import CustomerSettingsEmpty from '../assets/images/CustomerSettingsEmpty.svg';

export default {
  loginPageBg,
  BorrowingBaseReportIcon,
  ARAgingReportIcon,
  APAgingReportIcon,
  ARIneligibleReportIcon,
  InventoryValuationReportIcon,
  RollForwardReportIcon,
  BalanceSheetReportIcon,
  FinancialMappingReportIcon,
  ProfitAndLossStatementReportIcon,
  FileImportUploadEmpty,
  IneligibleAdjustmentsIcon,
  LoanBalanceIcon,
  ReservesIcon,
  CustomerSettingsEmpty,
};
