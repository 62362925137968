import { useState, Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { ExpandMore, ExpandLess, CloseSharp, CheckCircleRounded } from '@mui/icons-material';
import { useStompClient } from 'react-stomp-hooks';
import { CalculationLoaderProps, IIneligibleSettingForCalculationLoading } from '..';
import { CalcIneligibleState } from '../../../../../reducer/calcIneligibleReducer';
import { CalculateIneligible, setCalcIneligibleLoader } from '../../../../../actions/calcIneligibleActions';
import { CalcARStatus } from '../../../../../interfaces/calcARStatus';
import colors from '../../../../../utility/colors';
import styles from '../styles';
import ErrorIcon from '@mui/icons-material/Error';
import { generateRandomString } from '../../../../../utility/helper';

export interface SubscribingComponentProps extends CalculationLoaderProps {
  state: CalcIneligibleState;
  dispatch: Dispatch<CalculateIneligible>;
  setCalcARStatus: Dispatch<SetStateAction<CalcARStatus | null>>;
  ineligibles: IIneligibleSettingForCalculationLoading[];
}

const SubscribingComponent: FC<SubscribingComponentProps> = (props) => {
  const { state, dispatch, setCalcARStatus, ineligibles } = props;
  const [isAccordionExpanded, setIsAccordionExpanded] = useState<boolean>(true);
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);
  const stompClient = useStompClient();

  const listItemRef = (node: HTMLLIElement) => {
    if (node !== null) {
      node.scrollIntoView({
        behavior  : 'smooth',
        block     : 'end',
      });
    }
  }

  const isCurrent = (item: IIneligibleSettingForCalculationLoading, index: number, array: IIneligibleSettingForCalculationLoading[]) => {
    if (index === 0) {
      return item.isLoading;
    }

    return item.isLoading && !array[index - 1].isLoading;
  }

  const hideLoader = () => {
    dispatch(setCalcIneligibleLoader({
      ...state,
      show: false
    }));
  }

  const cancelCalculation = () => {
    dispatch(setCalcIneligibleLoader({
      ...state,
      cancelled: true
    }))
  }

  useEffect(() => { /* for upc-level ineligible calculation */
    if (!state.isParentClientLevel) { return; }
    subscribeToStompClientForUPC();
  }, [state, stompClient]); /* resubscribe when reloaded */

  useEffect(() => { /* for collateral-level ineligible calculation */
    if (state.isParentClientLevel) { return; }
    if (!state.isLoading) { return; }
    const generatedId = generateRandomString(10);
    const subscription = stompClient?.subscribe('/topic/status', (message) => {
      const body = message?.body;
      if (!body) { return; }

      const calcARStatus = JSON.parse(body);
      if (state.selectedClientId != calcARStatus.borrowerId) return;
      setCalcARStatus(calcARStatus);
    }, { id: generatedId });
    if (!subscription?.id) { return; }
    setSubscriptionId(subscription?.id);
  }, [state.isLoading, stompClient]);

  useEffect(() => {
    if (state.title === "Calculation Finished") {
      if (subscriptionId !== null) {
        stompClient?.unsubscribe(subscriptionId);
        setSubscriptionId(null);
      }
    }
  }, [state.title, subscriptionId]);

  const subscribeToStompClientForUPC = () => {
    const titlesForFinishedCalculation = ['Calculation Finished', 'Calculation Failed'];
    const isInitiallyDispatched = !state.hasInitiatedSubscription && !titlesForFinishedCalculation.includes(state.title);
    if (!isInitiallyDispatched) { return; }
    const generatedId = generateRandomString(10);
    const subscription = stompClient?.subscribe('/topic/status', (message) => {
      const body = message?.body;
      if (!body) { return; }

      const calcARStatus = JSON.parse(body);
      
      if (state.selectedClientId != calcARStatus.borrowerId) return;
      setCalcARStatus(calcARStatus)
    }, { id: generatedId });

    if (!subscription?.id) { return; }
    setSubscriptionId(subscription?.id);
    dispatch(setCalcIneligibleLoader({ ...state, hasInitiatedSubscription: true, isLoading: true }));
  };

  const getHeaderLabelStatus = () => {
    if (state.failed) { return 'Calculation Failed'; }
    if (state.isLoading) {
      return `Calculating ${state.title.toLowerCase().includes('borrowing') ? 'Report' : 'Ineligibles'}`;
    }
    return 'Calculation Completed';
  };


  /**
   * Gets the status icon for an ineligible item based on its properties.
   * @param item The ineligible item to determine the status icon for.
   * @returns The status icon element.
   */
  const getStatusIcon = (item: IIneligibleSettingForCalculationLoading) => {
    if (state.failed) { return <ErrorIcon htmlColor={colors.ERROR} sx={styles.iconLeftSpacing}/>; }
    if (state.isLoading && item.isLoading) {
      return (
        <CircularProgress
          tabIndex={0}
          aria-label='Loading icon'
          variant="indeterminate"
          disableShrink
          sx={{...styles.spinner, ...styles.iconLeftSpacing}}
          size={20}
          thickness={5}
        />
      );
    }
    return <CheckCircleRounded tabIndex={0} aria-label='Green checked icon' htmlColor={colors.SUCCESS} sx={styles.iconLeftSpacing}/>;
  };

  return <Box>
    <Box sx={styles.header}>
      <Typography tabIndex={0}>{getHeaderLabelStatus()}</Typography>
      <Box>
        <IconButton aria-label='Expand icon' sx={styles.action} onClick={() => setIsAccordionExpanded(!isAccordionExpanded)}>
          {
            !isAccordionExpanded ? <ExpandLess htmlColor={colors.WHITE} /> : <ExpandMore htmlColor={colors.WHITE} />
          }
        </IconButton>
        <IconButton aria-label='Close icon' sx={styles.action} onClick={(event) => {
          event.stopPropagation();
          hideLoader();
        }}>
          <CloseSharp htmlColor={colors.WHITE} />
        </IconButton>
      </Box>
    </Box>

    <Accordion
        defaultExpanded={true}
        expanded={isAccordionExpanded}
        sx={styles.contentWrapper}
    >
      <AccordionSummary
        aria-controls="panelcl-content"
        id="panelcl-header"
        hidden
        sx={{ ...styles.accordionSummary, ...(!state.isLoading && styles.hidden) }}
      >
        <Box sx={{...styles.content, ...styles.contentHeader }}>
          <Typography tabIndex={0} sx={styles.smallFont}>{ state.title ? state.title : 'Calculation Started...' }</Typography>
          <Button variant='text' sx={{...styles.smallFont, p: 0, display:'none'}} onClick={() => cancelCalculation()}>CANCEL</Button>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={styles.accordionDetails}>
        <Box>
          <Box sx={{...styles.content}}>
            {state.selectedClientId ?
            <List dense={true} sx={{width: 1}}>
              {ineligibles.map((item, index, array) => 
                <ListItem
                  key={item.code}
                  ref={isCurrent(item, index, array) ? listItemRef : null}
                  secondaryAction={getStatusIcon(item)}
                >
                  <ListItemText tabIndex={0}>{item.description}</ListItemText>
                </ListItem>
              )}
            </List>
            : <ListItem
            secondaryAction={
              state.isLoading ? 
              <CircularProgress
                tabIndex={0}
                aria-label='Loading icon'
                variant="indeterminate"
                disableShrink
                sx={{...styles.spinner, ...styles.iconLeftSpacing}}
                size={20}
                thickness={5}
              />
            : <CheckCircleRounded tabIndex={0} aria-label='Green checked icon' htmlColor={colors.SUCCESS} sx={styles.iconLeftSpacing}/>
            }
          >
            <ListItemText tabIndex={0}>{state.content}</ListItemText>
          </ListItem>
            }
          </Box>
        </Box>
      </AccordionDetails>

      </Accordion>
      { (!state.isLoading && state.link.length > 0) && <Box sx={styles.reportLink}>
          <Link to={state.link} onClick={hideLoader}>
            <Typography>Check Report Here</Typography>
          </Link>
        </Box>
      }
  </Box>;
}

export default SubscribingComponent;