import { FieldArrayRenderProps } from 'formik';
import { Box, Button, Divider } from '@mui/material';
import { IIneligibleRuleOverride, IIneligibleSettingsContext, IIneligibleSettingsPermissions } from '../../../../interfaces/ineligibleSettingInterface';
import styles from '../styles';
import { useContext } from 'react';
import { IneligibleSettingsContext } from '../../../../context/ineligibleSettingsContext';

interface IProps {
  ineligibleRuleId: number,
  initialOverride:     IIneligibleRuleOverride,
  lastOverride:        IIneligibleRuleOverride | undefined,
  ruleOverrideHelpers: FieldArrayRenderProps,
}

/**
 * Component for rendering add criteria button.
 * @param props The component props.
 * @returns The JSX element representing the add criteria button component.
 */
const AddCriteriaButton = ({ineligibleRuleId, initialOverride, lastOverride, ruleOverrideHelpers}: IProps) => {
  const { permissions } = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  return (
    <Box sx={styles.addOverrideButtonContainer}>
      <Divider absolute sx={styles.addOverrideButtonDivider}
      />
      <Button
        variant='outlined'
        sx={{ ...styles.cancelButton, ...styles.addOverrideButton, ...(!(permissions as IIneligibleSettingsPermissions).canAddCriteriaAndSettings && styles.hidden) }}
        onClick={() => {
          ruleOverrideHelpers.push({
            ...initialOverride,
            ineligibleRuleId,
            order: lastOverride === undefined ? initialOverride.order : lastOverride.order + 1
          });
        }}
      >+ Add Criteria
      </Button>
    </Box>
  )
}

export default AddCriteriaButton