import { AlertColor, Box, IconButton, ListItem, ListItemText, Tooltip, Typography, } from "@mui/material"
import styles from "../styles";
import { IARCustomer, IARVendor } from "../../../../interfaces";
import { Dispatch, FC, SetStateAction, useContext } from "react";
import { IParentCustomer, IToasterProps } from "../..";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../../context/customerSettingsContext";
import { formatDate } from "../../../../utility/helper";
import NewTag from "../../../../components/common/new-tag";

export interface IProps extends IToasterProps {
  item: IARCustomer
  index: number
  arCustomerList: IARCustomer[]
  arVendorList: IARVendor[],
  setParentCustomers: Dispatch<SetStateAction<IParentCustomer[] | undefined>>
  filteredParentCustomer?: IParentCustomer[],
  setFilteredParentCustomers: Dispatch<SetStateAction<IParentCustomer[] | undefined>>
}

/**
 * This component render a Customer/Child Row component.
 * 
 * @param param IProps
 */
const CustomerRow: FC<IProps> = (props: IProps) => {
  const {canViewParentInformation}   = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  return (
    <>
      <ListItem
        sx={{ bgcolor: '#F7F7F7' }}
        key={props.index}
      >
        <Box>
          <Box sx={styles.newTagBox}>
            { props.item.isNew && (
              <Typography><NewTag expanding /></Typography>
            )}
          </Box>
        </Box>
        <Box sx={{...styles.halfWidth, pl: '1.55rem'}}>
          <ListItemText tabIndex={0} id={`${props.item.recordId}`} primary={props.item.custName}/>
        </Box>
        <Box tabIndex={0} sx={styles.halfWidth}>
          {props.item.custSrcId ?? '-'}
        </Box>
        <Box tabIndex={0} sx={styles.halfWidth}>
          {props.item.createdAt ? formatDate(props.item.createdAt, 'MM/DD/YYYY'): '-'}
        </Box>
        <Box sx={{...styles.smallWidth, ...(!canViewParentInformation && styles.invisible)}} />
      </ListItem>
    </>
  )
}

export default CustomerRow;