const styles = {
  title: {
    fontWeight     : 'bold',
    color          : '#010953',
    fontSize       : '18px',
    ml             : '24px',
  },
  titleContainer: {
    backgroundColor: '#FEFEFE',
    py: '11px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
	contentStyle: {
		display: 'flex',
    overflow: 'auto',
	},
  rowBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    mr: '1rem',
  },
  cancelButton: {
    height: '31px',
    width: '82px',
    margin: '0.5rem',
    textTransform: 'capitalize',
  },
  saveButton: {
    height: '31px',
    width: '82px',
    margin: '0.5rem',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },
  hidden: {
    display: 'none'
  },
  noDataPlaceHolderContainer: {
    marginX: '8px',
    marginTop: '8px',
    width: '100%',
  },
  noDataPlaceHolderText: {
    fontSize: '16px',
  }
}

export default styles;
