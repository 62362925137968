import { useState, useContext, FC, useEffect } from 'react';
import { Button, Typography, Modal, Grid, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { CustomizeColumnsModalProps } from '../../../../interfaces/clientListInterface';
import styles from './styles'
import { DraggableListItem } from '../../../../interfaces/draggableList';
import DraggableList from '../../../draggable-list';
import { ClientContext } from '../../../../context/clientContext';
import { clientColumnList } from '../../../../utility/constants';
import _ from 'lodash';
import { getLocalStorageItem, setLocalStorageItem } from '../../../../utility/helper';

const ModalButton = styled(Button)({
  textTransform: 'none',
  minWidth: '82px',
});

/**
 * Component for showing the contents of the customize columns modal component in the clients page.
 * @param props The props for the CustomizeColumnsModal component. See the CustomizeColumnsModalProps interface for more information.
 */
const CustomizeColumnsModal: FC<CustomizeColumnsModalProps> = (props) => {
  const {open, setOpen} = props;
  const clientContext   = useContext(ClientContext);
  const [list, setList] = useState<DraggableListItem[]>(clientColumnList);
  const [initialList, setInitialList] = useState<DraggableListItem[]>([]);

  /**
   * This useEffect hook sets the initial list so that when 
   * the modal is closed without saving, the changes will be reverted.
   */
  useEffect(() => {
      open === true && setInitialList(_.cloneDeep(list));
  }, [open]);
  
   /**
   * This useEffect hook sets the list's value from the stored clientColumnList. 
   * This prevents the colum list from resetting it's values when the page is refreshed.
   */
  useEffect(() => {
    const storedList = getLocalStorageItem('clientColumnList');
    if (storedList) {
      setList(JSON.parse(storedList)); 
    }
  }, []); 
  
  /**
   * This function is called in order to close the modal.
   */
  const handleClose = () => {
    setOpen(false);
    clientContext?.setList(initialList);
    setList(initialList);
  };
  
  /**
   * This function is called whenever a column header is dragged to a new position.
   * @param listItems the columns of the clients table.
   */
  const onDragOver = (listItems: DraggableListItem[]) => {
    setList(listItems)
  }

  /**
   * This function is called whenever a column header is checked in order to show the header in the table.
   * @param listItems the columns of the clients table.
   */
  const onItemChecked = (listItems: DraggableListItem[]) => {
    setList(listItems)
  }

  /**
   * This function is called in order to save the new column headers.
   */
  const handleSave = () => {
    clientContext?.setList(list)
    setLocalStorageItem("clientColumnList", JSON.stringify(clientContext.list))
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Grid container sx={styles.modal} xs={12}>
        <Grid item xs={12} marginBottom={2}>
          <Grid container justifyContent='flex-end' alignItems='center'>
            <IconButton sx={styles.closeButton} aria-label='close-modal-button'>
              <CloseIcon fontSize='inherit' onClick={handleClose}/>
            </IconButton>
          </Grid>
          <Grid container justifyContent='flex-start' alignItems='center'>
            <Typography id='modal-modal-title' variant='h6' sx={styles.modalTitle}>
              Customize Columns
            </Typography>
          </Grid>
          <Grid container justifyContent='flex-start' alignItems='center'>
            <Typography id='modal-modal-description' variant='body1' sx={styles.modalDescription}>
              Drag and drop to reorder columns.
            </Typography>
            {/*  temporary disabled since there is no set limit of the number of columns */}
            {/* <Alert severity="error" color='warning' sx={{...styles.warningBox, ...(!getCheckedLists() && {display: 'none'})}}>
              You have exceeded the limit.
            </Alert> */}
          </Grid>
        </Grid>
        <Grid item xs={12} borderTop={1} borderColor='#E5E2E2'></Grid>
        <Grid item xs={12} sx={{display:'flex'}}>
          <Grid sx={styles.listContainer} container xs={12} alignItems='center'>
            <Box sx={{width: '100%'}}>
              <DraggableList 
                list={list}
                onItemDragOver={onDragOver}
                onItemChecked={onItemChecked}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <Grid container justifyContent='flex-end' gap={1}>
            <ModalButton
              variant='outlined'
              size='small'
              disableElevation
              onClick={handleClose}
            >
              Cancel
            </ModalButton>
            <ModalButton
              // disabled={getCheckedLists()} temporary disabled since there is no set limit of the number of columns
              variant='contained'
              size='small'
              disableElevation
              onClick={handleSave}
            >
              Save Changes
            </ModalButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default CustomizeColumnsModal;
