
import { Backdrop } from '@mui/material';
import loaderIcon from '../../../assets/images/loader-icon.gif';
import styles from './styles';

interface ILoadingBackdropProps {
  isLoading: boolean;
}

const LoadingBackdrop: React.FC<ILoadingBackdropProps> = (props) => {
  return (
    <Backdrop
      sx={styles.loadingBackdrop}
      open={props.isLoading}
      data-testid='loading-backdrop'
    >
      <img src={loaderIcon} alt='loader-icon' style={styles.loaderIcon} loading='lazy' />
    </Backdrop>
  );
};

export default LoadingBackdrop;
