import React, { createContext, Dispatch, useEffect, useMemo, useReducer } from "react";
import { CalculateIneligible } from "../actions/calcIneligibleActions";
import calcIneligibleReducer, { CalcIneligibleState, initialState } from '../reducer/calcIneligibleReducer';
import { setLocalStorageItem } from "../utility/helper";

export interface CalcIneligibleContextInterface {
  state     : CalcIneligibleState;
  dispatch  : Dispatch<CalculateIneligible>;
}

export const CalcIneligibleContext = createContext<CalcIneligibleContextInterface>({
  state     : initialState,
  dispatch  : () => undefined
});

export interface LayoutProps  { 
  children: React.ReactNode;
}

const CalcIneligibleContextProvider = (props: LayoutProps) => {
  const children = props.children;

  const [state, dispatch] = useReducer(calcIneligibleReducer, initialState);

  useEffect(() => {
    if (state.show) {
      setLocalStorageItem('calcIneligibleLoader', JSON.stringify(state));
    } else {
      localStorage.removeItem('calcIneligibleLoader');
    }
  }, [state.title, state.show]);

  const value = useMemo(() => ({state, dispatch}), [state, dispatch]);

  return (
    <CalcIneligibleContext.Provider value={value}>
      {
        children
      }
    </CalcIneligibleContext.Provider>
  )
}

export default CalcIneligibleContextProvider;