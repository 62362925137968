import * as Yup from 'yup';

const parentSchema = Yup.object({
  creditLimit: Yup.number()
    .nullable(true)
    .typeError('Credit Limit must be a number'),
  dbRating: Yup.string()
    .nullable(true),
  parentCustSrcId: Yup.string()
    .required('Parent ID is required').trim(),
  parentCustName: Yup.string()
    .required('Parent Name is required').trim(),
});
export default parentSchema;
