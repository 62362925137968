import { TableContainer, TableHead, TableRow, TableCell, TableBody, Table } from '@mui/material';
import { FC } from 'react';
import { IARIneligibleTableProps } from '..';
import { IARIneligible, ICapDetails } from '../../../../..';
import { formatNumber } from '../../../../../../../../utility/helper';
import styles from './styles';

export interface IIneligibleCapDetailsProps extends IARIneligibleTableProps {
  capDetails: ICapDetails; // redeclaring capDetails as non-undefined type (Invariance)
}

const IneligibleCapDetails: FC<IIneligibleCapDetailsProps> = (props) => {
  const { capDetails, currencyCode, arIneligible } = props;
  const { capAmount, totalIneligible, capAddBack, newIneligibleAmount } = capDetails;

  /**
   * Formats a currency amount.
   * @param amount The amount to be formatted.
   * @returns The formatted currency string.
   */
  const formatCurrency = (amount: number) => {
    return formatNumber(
      { style: 'currency', currency: currencyCode ?? 'USD', currencySign: 'accounting' },
      amount
    );
  };

  /**
   * Determines the style for a table cell containing new ineligible amounts based on the number of reports.
   * @param reports An array of AR ineligible reports.
   * @returns The style for the table cell.
   */
  const getNewAmountPaddingRight = (reports: IARIneligible[]) => {
    if (reports.length > 12) return styles.tableCellNewIneligibleAmountWithScroll;
    else return styles.tableCellNewIneligibleAmount;
  }

  return (
    <TableContainer>
      <Table aria-label='Ineligible Cap Details'>
        <TableHead>
          <TableRow>
            <TableCell sx={styles.leftColSpacer}></TableCell>
            <TableCell
              align='right'
              sx={{...styles.tableCell}}
            >
              Cap Amount
            </TableCell>
            <TableCell
              align='right'
              sx={{...styles.tableCell}}
            >
              Total Ineligible
            </TableCell>
            <TableCell
              align='right'
              sx={{...styles.tableCell}}
            >
              Add Back
            </TableCell>
            <TableCell
              align='right'
              sx={{...styles.tableCell, ...getNewAmountPaddingRight(arIneligible)}}
            >
              New Ineligible Amount
            </TableCell>
            <TableCell sx={styles.rightColSpacer}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={styles.leftColSpacer}></TableCell>
            <TableCell
              align='right'
              sx={{...styles.tableCell}}
            >
              {formatCurrency(capAmount)}
            </TableCell>
            <TableCell
              align='right'
              sx={{...styles.tableCell}}
            >
              {formatCurrency(totalIneligible)}
            </TableCell>
            <TableCell
              align='right'
              sx={{...styles.tableCell}}
            >
              {formatCurrency(capAddBack)}
            </TableCell>
            <TableCell
              align='right'
              sx={{...styles.tableCell, ...getNewAmountPaddingRight(arIneligible)}}
            >
              {formatCurrency(newIneligibleAmount)}
            </TableCell>
            <TableCell sx={styles.rightColSpacer}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default IneligibleCapDetails;