import yup from './index';
import { IDataMapField, IDocumentAttribute } from '../interfaces/dataMap';

const addNewFieldSchema = (
  mappingFields: IDataMapField[],
  userDefinedFields: IDocumentAttribute[]
) => {
  const usedFieldNames = [
    ...mappingFields.map((field) => field.name),
    ...userDefinedFields.map((field) => field.fieldName),
  ];

  return yup.object({
    fieldName: yup
      .string()
      .required('Field Name is required')
      .trim()
      .notOneOf(usedFieldNames, 'Field Name must be unique'),
    dataType: yup
      .object()
      .required('Data Type is required')
      .nullable(),
  });
};

export default addNewFieldSchema;