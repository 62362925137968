import { FC } from "react"
import { SkeletonRow, SkeletonTableHead } from "../../../components/skeleton"
import { Table, TableBody } from "@mui/material"

/**
 * Component for showing the Loader Table of the Users and Roles page..
 */
const LoaderTable: FC = () => {
  return(
    <Table>
      <SkeletonTableHead />
      <TableBody>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </TableBody>
    </Table>
  )
}

export default LoaderTable