import { AlertColor, Box, Paper, Skeleton, Table, TableBody, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material"
import { IParentCustomer } from ".."
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { IARCustomer } from "../../../interfaces"
import axiosInstance from "../../../service/axiosInstance"
import { GET, POST } from "../../../utility/constants"
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import styles from "./styles"
import Toaster from "../../../components/toaster"
import { StyledTableCell } from "../../../components/skeleton"
import { IOption } from "../../../interfaces/comboBox"
import { arCustomersAPI, borrowerAPI } from "../../../service/api"
import CustomerAccordion from "./customer-Accordion"
import CustomerModal from "../customer-modal"
import images from "../../../theme/images"

export interface IProps {
  selectedARCollateralId: number;
  isParentView: boolean;
  rowsPerPage: number;
  page: number;
  arCollateralId: number;
  selectedClient: IOption | null;
  childClients?: IOption[];
  arCollaterals: IOption[];
  totalElements: number;
  setTotalElements: Dispatch<SetStateAction<number>>;
  searchKey: string | undefined;
  setSearchKey: Dispatch<SetStateAction<string | undefined>>;
  filter: string;
  setFilter: Dispatch<SetStateAction<string>>;
  tabIndex: number;
  clearButtonClicked: boolean;
}

interface IHeader {
  title: string,
  direction: string,
  id: number
}

const headerList: IHeader[] = [
  {title: 'Parent Name', direction: 'ASC', id: 0}, 
  {title: 'Parent ID', direction: 'ASC', id: 1}
]

const ParentList = (props: IProps) => {
  const [sortId, setSortId]                                   = useState<number>(0)
  const [sortDirection, setSortDirection]                     = useState<string>('ASC')
  const [isToasterOpen, setIsToasterOpen]                     = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]                   = useState<string>('Changes in Customer Settings have been saved.');
  const [toasterSeverity, setToasterSeverity]                 = useState<AlertColor>('success');
  const [isLoading, setIsLoading]                             = useState<boolean>(false);
  const [open, setOpen]                                       = useState<boolean>(false);
  const [item, setItem]                                       = useState<IParentCustomer>();
  const [parentCustomers, setParentCustomers]                 = useState<IParentCustomer[]>();
  const [filteredParentCustomers, setFilteredParentCustomers] = useState<IParentCustomer[]>();
  const [orphans, setOrphans]                                 = useState<IParentCustomer>();
  const [arCustomerList, setARCustomerList]                   = useState<IARCustomer[]>([])
  
  const handleIcon                                            = useCallback(() => <UnfoldMoreIcon sx={styles.iconDefaultSort}/>, []);
  const handleToasterClose                                    = useCallback(() => setIsToasterOpen(false), []);

  /**
   * This useEffect triggers the function that fetches all of the parent AR Customers
   */
  useEffect(() => {
    if (!props.isParentView) {
      getAllCustomers()
    }
  }, [props.isParentView, props.selectedARCollateralId, props.rowsPerPage, props.page, props.selectedClient, props.clearButtonClicked])

  /**
   * This useEffect triggers the function that gets all the customer when pagination or tab changes.
   */
  useEffect(() => {
    if(props.tabIndex === 1 && parentCustomers){
      filterParent(props.searchKey, props.filter);
      return;
    }
  },[props.page, props.rowsPerPage, props.filter, props.searchKey, props.tabIndex, parentCustomers])

  /**
   * This function is used to filter the parentList state under parent list view base on the search component.
   * 
   * @param filterString The search value in search component.
   * @param filterType The search filter in search compoenent.
   */
  const filterParent = (filterString: string | undefined, filterType: string) => {
    if (filterType === 'New') {
      const tempData = parentCustomers?.filter(parent => parent.isNew && parent.custName?.toLocaleLowerCase().includes(filterString ?? ''));
      setFilteredParentCustomers(tempData);
      return;
    }
    if (filterString) {
      const filteredParents: IParentCustomer[] = []
      parentCustomers?.forEach((parent: IParentCustomer) => {
        getFilteredParents(filterType, filterString, filteredParents, parent);
      });
      
      setFilteredParentCustomers(filteredParents)
      return
    }
    const tempData = parentCustomers?.slice(getStartIndex(), getEndIndex()).map((parent) => { return {...parent, isExpanded: false}});
    setFilteredParentCustomers(tempData);
  };

  /**
   * This function is used to iterate inside a parent to processed its children based on the search component value.
   * 
   * @param filterString The search value in search component.
   * @param filterType The search filter in search compoenent.
   * @param filteredParents A reference list of the default parentList
   * @param parent A parent from parentList
   */
  const getFilteredParents = (filterType: string, filterString: string, filteredParents: IParentCustomer[], parent: IParentCustomer) => {
    const lowercaseFilterString = filterString.toLowerCase();
    const filterTypes = getFilterType(filterType, parent, lowercaseFilterString);

    if (filterTypes.byParent) {
      filteredParents.push({
        ...parent, isExpanded: true
      })
    } else if (filterTypes.custName) {
      const filteredChildren = parent.children?.filter(child => child.custName?.toLowerCase().includes(lowercaseFilterString))

      if (filteredChildren && filteredChildren.length > 0) {
        filteredParents.push({
          ...parent, isExpanded: true, children: filteredChildren
        })
      }
    } else if (filterTypes.custSrcId) {
      const filteredChildren = parent.children?.filter(child => child.parentCustSrcId?.toLowerCase().includes(lowercaseFilterString))

      if (filteredChildren && filteredChildren.length > 0) {
        filteredParents.push({
          ...parent, isExpanded: true, children: filteredChildren
        })
      }
    } else if (filterTypes.all) {
      const isMatched = checkIfAllMatched(parent, filterString, lowercaseFilterString);
      if (isMatched) {
        filteredParents.push({
          ...parent, isExpanded: true
        })
      }
    }
  }

  /**
   * This function takes a filter type from search component and determine which filter is used.
   * 
   * @param filterType The filter type display on the search component.
   * @param parent A parent from the parent list.
   * @param lowercaseFilterString The string value of the search component on lower case.
   * @returns A list of boolean that determines which filterType is used by the search component.
   */
  const getFilterType = (filterType: string, parent: IParentCustomer, lowercaseFilterString: string) => {
    return {
      byParent: (filterType === "Parent Name" && parent.custName?.toLowerCase().includes(lowercaseFilterString)) ||
                (filterType === "Parent ID" && parent.custSrcId?.toString().includes(lowercaseFilterString)),
      custName: filterType === "Customer Name",
      custSrcId: filterType === "Customer ID",
      all: filterType === "All",
      new: filterType === "New",
    }
  };

  /**
   * This function takes the input value on the search component and used it to search on both parent and children level.
   * 
   * @param parent A parent from the parent list.
   * @param filterString The value input display on the search component.
   * @param lowercaseFilterString The string value of the search component on lower case.
   * @returns A boolean that determine if the input value on the search component is found on both parent and children level.
   */
  const checkIfAllMatched = (parent: IParentCustomer, filterString: string, lowercaseFilterString: string) => {
    const matchedParentCustName = parent.custName?.toLowerCase().includes(lowercaseFilterString);
    const matchedParentCustSrcId = parent.custSrcId?.toString().includes(filterString);
    const matchedChildrenCustName = parent.children?.some(child => child.custName?.toLowerCase().includes(lowercaseFilterString));
    const matchedChildrenCustSrcId = parent.children?.some(child => child.parentCustSrcId?.toLowerCase().includes(lowercaseFilterString));
    return matchedParentCustName || (matchedParentCustSrcId || (matchedChildrenCustName || matchedChildrenCustSrcId));
  };

  /**
   * This function is used to get all of the customer under the selected collateral, This is used to get all existing customer.
   */
  const getAllCustomers = async () => {

    try {
      const allCustRes = await axiosInstance.request({
        url: props.selectedClient?.parentClient ? arCustomersAPI.FIND_BY_BORROWER_ID : arCustomersAPI.FIND_BY_AR_COLLATERAL_ID,
        method: GET,
        params: props.selectedClient?.parentClient ? {borrowerId: props.selectedClient?.recordId} : {arCollateralId: props.selectedARCollateralId}
      });

      const customerList: IARCustomer[] = allCustRes.data.content.map((o:any) => ({
        recordId             : o.recordId,
        borrowerId           : o.borrowerId,
        arCollateralId       : o.arCollateralId,
        parentARCustomerId   : o.parentARCustomerId,
        custSrcId            : o.custSrcId,
        custName             : o.custName,
        custAddress1         : o.custAddress1,
        custAddress2         : o.custAddress2,
        custCountry          : o.custCountry,
        custCity             : o.custCity,
        custState            : o.custState,
        custPostalCode       : o.custPostalCode,
        custPhone            : o.custPhone,
        creditLimit          : o.creditLimit,
        asOfDate             : o.asOfDate,
        custDescription      : o.custDescription,
        parentCustSrcId      : o.parentCustSrcId,
        parentCustName       : o.parentCustName,
        dbRating             : o.dbRating,
        isPrimary            : o.primaryDebtor,
        createdAt            : o.createdAt,
        upcParentCustSrcId   : o.upcParentCustSrcId,
        upcParentCustName    : o.upcParentCustName,
        upcParentCustId      : o.upcParentCustId,
        isUpcParent          : o.isUpcParent,
        isNew                : o.isNew,
      }))
      
      setARCustomerList(customerList)
      handleRelationship(customerList)
    } catch (error) {
      console.log('ALL CUSTOMERS', error);
    }
  };    

  /**
   * This function assign each child to its parent
   * 
   * @param customerList A list of IARcustomer objects from API Call
   */
  const handleRelationship = (customerList: IARCustomer[]) => {
    const field = props.selectedClient?.parentClient ? 'upcParentCustSrcId' : 'parentCustSrcId';
    const parentIds: string[] = [
      ...new Set(customerList
        .filter(cust => cust[field] !== undefined)
        .map(filteredCust => String(filteredCust[field]))
        )];

    const parentObjects: IParentCustomer[] = customerList
          .filter(cust => cust.custSrcId !== undefined && parentIds.includes(cust.custSrcId))
          .map(filteredCust => ({
            ...filteredCust,
            isExpanded: false,
            children: customerList.filter(child => child[field] !== undefined && child[field] === filteredCust.custSrcId)
          })).sort((a, b) => sortByHeaderId(sortId, a, b, sortDirection))

    setOrphans({
      recordId: -1,
      isExpanded: false,
      custName: 'No Parents',
      children: customerList.filter(child => (
        !parentObjects.some(o => o.recordId?.toString() === child.recordId?.toString())
        && child[field] === undefined
      )),
      custSrcId: undefined
    })
    setParentCustomers(parentObjects)
    props.setTotalElements(parentObjects.length+1)
    setIsLoading(false)
  }

  // NON API FUCNTIONS

  /**
   * This function handle the sorting of column base on their header.
   * 
   * @param headerId The ID of the specified column header.
   */
  const handleSort = (headerId: number) => {
    let newSortDirection: string = 'DESC';
    console.log('here here')
  
    if (sortId === headerId) {
      newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      setSortId(headerId);
    }
  
    const sortedCustomers: IParentCustomer[] | undefined = parentCustomers?.sort((a, b) => sortByHeaderId(headerId, a, b, newSortDirection)).slice(getStartIndex(), getEndIndex());
    setSortDirection(newSortDirection);
    setFilteredParentCustomers(sortedCustomers);
  };

  /**
   * This function determine if the sort is either on parentName or parentId level.
   * 
   * @param headerId The ID of the header that is sorted.
   * @param a The first element on compareFn of sort method.
   * @param b The second element on compareFn of sort method
   * @param newSortDirection The sort Derection
   * @returns The comparison for specific field of the list.
   */
  const sortByHeaderId = (headerId: number, a: IParentCustomer, b: IParentCustomer, newSortDirection: string) => {
    if (headerId < 1) {
      const nameA = a?.custName ?? '';
      const nameB = b?.custName ?? '';
      return newSortDirection === 'ASC' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    } else {
      const idA = a?.custSrcId ?? '';
      const idB = b?.custSrcId ?? '';
      return newSortDirection === 'ASC' ? idA.localeCompare(idB) : idB.localeCompare(idA);
    }
  }

  /**
   * This function used to changed the icon of the sorted column.
   * 
   * @param headerId The ID of the specified column header.
   * @returns A sort Icon
   */
  const getIconComponent = (headerId: number) => {
    if (sortId === headerId) {
      return;
    } else {
      return { IconComponent: handleIcon };
    }
  };

  /**
   * This function is used to get the start index used for the pagination
   */
  const getStartIndex = () => {
    if(props.page > 0 && props.rowsPerPage !== -1){
      return props.page * props.rowsPerPage;
    } return 0
  };

  /**
   * This function is used to get the end index used for the pagination
   */
  const getEndIndex = () => {
    const startIndex = getStartIndex()

    if(props.rowsPerPage !== -1){
      return startIndex+props.rowsPerPage
    } return filteredParentCustomers?.length
  };

  const getOrphanComponent = () => {
    const isLastPage = ((props.page+1) * props.rowsPerPage) >= (props.totalElements)

    if(Boolean(orphans?.children?.length) && (isLastPage || props.rowsPerPage === -1)){
      return (
        <CustomerAccordion 
          idx={filteredParentCustomers?.length ? filteredParentCustomers?.length+2 : 0} 
          parent={orphans!} 
          orphans={orphans}
          setOrphan={setOrphans}
          setOpen={setOpen} 
          setItem={setItem} 
          setFilteredParentCustomers={setFilteredParentCustomers}
          setParentCustomers={setParentCustomers}
          setIsToasterOpen={setIsToasterOpen}
          isToasterOpen={isToasterOpen}
          setToasterMessage={setToasterMessage}
          toasterMessage={toasterMessage}
          setToasterSeverity={setToasterSeverity}    
          toasterSeverity={toasterSeverity}
          arCustomerList={arCustomerList}
          getAllCustomers={getAllCustomers}
        />
      )
    }
  }

  /**
   * This if statement renders a "No Data Available" when parentsList is empty.
   */
  if(!filteredParentCustomers?.length && !orphans?.children?.length){
    return (
      <TableContainer component={Paper} sx={styles.tableContainerEmpty}>
        <Box
          component={'img'}
          sx={{
            maxHeight: '250px',
          }}
          src={images.CustomerSettingsEmpty}
        />
        <Typography tabIndex={0} align='center'>No data available</Typography>
      </TableContainer>
    )
  }

  if(isLoading){
    return (
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead>
            <StyledTableCell> <Skeleton variant='rectangular' height={40}/></StyledTableCell>
            <StyledTableCell> <Skeleton variant='rectangular' height={40}/></StyledTableCell>
            <StyledTableCell> <Skeleton variant='rectangular' height={40}/></StyledTableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
              <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
              <StyledTableCell> <Skeleton variant='rectangular'/></StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <>
      <Box
        sx={styles.parentBox}
      >
        <Box sx={styles.newTagBox} />
        {headerList.map((header, idx) => 
          <TableSortLabel 
            tabIndex={0}
            key={header.id}
            active={sortId === header.id}
            direction={sortDirection === 'DESC' ? 'desc' : 'asc'}
            onClick={() => handleSort(header.id)}
            aria-label={`${header.title} sort`}
            {...getIconComponent(header.id)}
            sx={{...styles.halfWidth, pl: idx !== 0 ? '0.8rem' : '1.5rem'}}
          >
            <Box>
              {header.title}
            </Box>
          </TableSortLabel>
        )
        }
        <Box tabIndex={0} sx={{...styles.smallWidth}}>
            Action
        </Box>
      </Box>
      {filteredParentCustomers?.map((parent, idx) => 
        <CustomerAccordion 
          key={parent.recordId}
          idx={idx} 
          parent={parent} 
          filteredParentCustomers={filteredParentCustomers}
          setOpen={setOpen} 
          setItem={setItem} 
          setFilteredParentCustomers={setFilteredParentCustomers}  
          setParentCustomers={setParentCustomers}
          setIsToasterOpen={setIsToasterOpen}
          isToasterOpen={isToasterOpen}
          setToasterMessage={setToasterMessage}
          toasterMessage={toasterMessage}
          setToasterSeverity={setToasterSeverity}    
          toasterSeverity={toasterSeverity}  
          arCustomerList={arCustomerList}
          getAllCustomers={getAllCustomers}
        />
      )}
      {getOrphanComponent()}
      <CustomerModal 
        open={open} 
        setOpen={setOpen}
        customer={item}
        customerList={arCustomerList}
        handleSuccess={(values) => {
          setIsToasterOpen(true)
          setToasterMessage('Changes in Customer Settings have been saved.')
          setToasterSeverity('success')
          getAllCustomers()
        }}
        handleFailed={(e) => {
          setIsToasterOpen(true)
          setToasterMessage('Failed to save changes, Please try again')
          setToasterSeverity('error')
        }}
        isParent        
        onSettings
      />
      <Toaster
        open={isToasterOpen}
        severity={toasterSeverity}
        message={toasterMessage}
        onCloseChange={handleToasterClose}
      />
    </>
  )
}

export default ParentList