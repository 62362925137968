import { Button, Paper, SxProps, Theme, Modal, AlertColor, Box, } from '@mui/material';
import { API_DOMAIN, NON_EXISTING, PERMISSIONS, POST } from '../../../utility/constants';
import { CalcIneligibleContext } from '../../../context/calneligibleContext';
import { setCalcIneligibleLoader } from '../../../actions/calcIneligibleActions';
import styles from './styles';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CalcIneligibleDialog from '../../../components/dialog-select';
import { calculateIneligibles, checkUserPermissions, getIneligibleSettings, getLocalStorageItem } from '../../../utility/helper';
import Toaster from '../../../components/toaster';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { IClient } from '../../../interfaces';
import axiosInstance from '../../../service/axiosInstance';
import { IIneligibleSettingAPI } from '../../../interfaces/ineligibleSettingInterface';
import { getUPCIneligibleSettings } from '../../../api/ineligible-settings';
import { useNavigate } from 'react-router-dom';
import { SelectedChildClientMessageModal } from '../../../components/ineligible-settings/modals/selected-child-client-message';

interface ICalculateIneligibleProps {
  disabled?             : boolean;
  calculateButtonStyles?: SxProps<Theme>;
  hideFileImportLoader? : () => void;
}

/**
 * A component for calculate ineligibles button.
 * @param props The props for configuring calculate ineligibles button.
 * @returns A React component that renders calculate ineligibles button.
 */
const CalculateIneligiblesButton: FC<ICalculateIneligibleProps> = (props) => {
  const navigate                                                            = useNavigate();
  const controller                                                          = new AbortController();
  const { selectedClient, clients, setSelectedClient }                      = useContext(SelectedClientContext);
  const { state, dispatch }                                                 = useContext(CalcIneligibleContext);
  const [open, setOpen]                                                     = useState<boolean>(false);
  const [toasterOpen, setToasterOpen]                                       = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]                                 = useState<string>('');
  const [toasterSeverity, setToasterSeverity]                               = useState<AlertColor>('success');
  const [paramsForCalculateUPC, setParamsForCalculateUPC]                   = useState({} as { selectedClient: IClient, selectedEndDate: string });
  const [isCalculatingIneligibles, setIsCalculatingIneligibles]             = useState(false);
  const [childModalOpen, setChildModalOpen]                                 = useState(false);
  const isUltimateParent                                                    = useMemo(() => Boolean(selectedClient?.parentClient), [selectedClient]);
  const isChildClient                                                       = useMemo(() => Boolean(selectedClient?.parentClientFk), [selectedClient]);

  const parent = useMemo(() => {
    if (isChildClient) {
      const parent = clients.find(client => client.recordId === selectedClient?.parentClientFk);
      if (parent) return parent;
    }
  }, [selectedClient, clients]);

  useEffect(() => {
    if (!state.isParentClientLevel || Object.keys(paramsForCalculateUPC).length === 0) { return; }
    if (state.hasInitiatedSubscription && !isCalculatingIneligibles) {
      const nonNullableSelectedClient = paramsForCalculateUPC.selectedClient as NonNullable<IClient> & Required<IClient>;
      const formattedDate = paramsForCalculateUPC.selectedEndDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
      calculateUPC(nonNullableSelectedClient, formattedDate);
    }
    const titlesForFinishedCalculation = ['Calculation Finished', 'Calculation Failed'];
    titlesForFinishedCalculation.includes(state.title) && setIsCalculatingIneligibles(false);
  }, [state, paramsForCalculateUPC]);

  /**
   * This callback function handles opening the modal.
   */
  const handleOpen = () => {
    if (isChildClient) {
      setChildModalOpen(true);
    } else {
      setOpen(true);
    }
  };

  /**
   * This callback function handles closing the modal.
   */
  const handleClose = () => {
    if (isChildClient) {
      setChildModalOpen(false);
    } else {
      setOpen(false);
    }
  };
  /**
   * This function attempts to calculate an ineligible report.
   *
   * @param borrowerName The name of the borrower.
   * @param arCollateralName The name of the AR collateral.
   * @param borrowerId The ID of the borrower.
   * @param bbPeriodId -he ID of the BB period.
   * @param arCollateralId The ID of the AR collateral.
   */
  const tryCalculate = useCallback(async (borrowerName: string, arCollateralName: string, borrowerId: number, bbPeriodId: number, arCollateralId: number) => {
    const canCalculateIneligible = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.CALCULATE_INELIGIBLE);
    if (canCalculateIneligible) {
      await calculate(borrowerName, arCollateralName, borrowerId, bbPeriodId, arCollateralId);
      return;
    }
    setToasterOpen(true);
    setToasterMessage('You do not have the necessary permissions for this action.');
    setToasterSeverity('error');
    setOpen(false);
  }, [controller])

  /**
   * This function initiates the calculation of an ineligible report.
   *
   * @param borrowerName The name of the borrower.
   * @param arCollateralName The name of the AR collateral.
   * @param borrowerId The ID of the borrower.
   * @param bbPeriodId The ID of the BB period.
   * @param arCollateralId The ID of the AR collateral.
   */
  const calculate = async (borrowerName: string, arCollateralName: string, borrowerId: number, bbPeriodId: number, arCollateralId: number) => {
    const ineligibleSettings = (await getIneligibleSettings(borrowerId, arCollateralId)).data as IIneligibleSettingAPI[];
    const payload = {
      title: 'Calculating Ineligible Report',
      content: `Ineligible Report - ${borrowerName} - ${arCollateralName}`,
      hasInitiatedSubscription: false,
      isLoading: true,
      show: true,
      link: `/reports/ar-ineligible?clientId=${borrowerId}&bbPeriodId=${bbPeriodId}&arCollateralId=${arCollateralId}&fromSettings=true`,
      cancelled: false,
      failed: false,
      selectedClientId: borrowerId,
      selectedCollateralId: arCollateralId,
      isParentClientLevel: false,
      ineligibleSettings: ineligibleSettings,
    };
    dispatch(setCalcIneligibleLoader({...payload}));
    setOpen(false);
    try {
      await calculateIneligibles(borrowerId, bbPeriodId, arCollateralId);
      dispatch(setCalcIneligibleLoader({ ...payload, title: 'Calculation Finished', isLoading: false }));
    } catch (error) {
      dispatch(setCalcIneligibleLoader({ ...payload,  isLoading: false, failed: true, link: '' }));
      console.log('CALCULATE ERROR: ', error);
    }
  };

  /**
   * This function initiates the calculation of the UPC ineligible report.
   * @param selectedClient The selected client to be calculated (Ultimate Parent Client).
   * @param selectedEndDate The selected end date in YYYYMMDD format.
   */
  const initiateCalculationForUPC = async (selectedClient: IClient, selectedEndDate: string) => {
    const nonNullableSelectedClient = selectedClient as NonNullable<IClient> & Required<IClient>;
    const ineligibleSettings = await getUPCIneligibleSettings(nonNullableSelectedClient);
    const initiatorPayLoad = { /* to initiate subscription to stomp client, before actual loading starts */
      title: 'Calculating Ineligible Report',
      content: `Ineligible Report - ${nonNullableSelectedClient.borrowerName}`,
      hasInitiatedSubscription: false,
      isLoading: true,
      show: true,
      link: `/reports/ar-ineligible?clientId=${nonNullableSelectedClient.recordId}&endDate=${selectedEndDate}`,
      cancelled: false,
      failed: false,
      selectedClientId: nonNullableSelectedClient.recordId,
      selectedCollateralId: NON_EXISTING,
      isParentClientLevel: true,
      ineligibleSettings: ineligibleSettings,
    };
    setParamsForCalculateUPC({ selectedClient, selectedEndDate });
    dispatch(setCalcIneligibleLoader({ ...initiatorPayLoad }));
    setOpen(false);
  };

  /**
   * This function triggers the calculation of the UPC ineligible report.
   * @param selectedClient The selected client to be calculated (Ultimate Parent Client).
   * @param selectedEndDate The selected end date in YYYYMMDD format.
   */
  const calculateUPC = async (selectedClient: IClient, selectedEndDate: string) => {
    const nonNullableSelectedClient = selectedClient as NonNullable<IClient> & Required<IClient>;
    const formattedDate = selectedEndDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
    await axiosInstance.request({
      url: `${API_DOMAIN}/ar/calculateIneligibles/ineligibleSettingsForUPC`,
      method: POST,
      params: {
        parentBorrowerId: nonNullableSelectedClient.recordId,
        endDate: formattedDate
      }
    });
    setIsCalculatingIneligibles(true);
  };

  return (
    <>
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setToasterOpen(false)}
      />
      <Paper
        elevation={props.calculateButtonStyles === undefined ? 3 : 0}
        sx={props.calculateButtonStyles === undefined ? styles.card : null}
      >
        <Button
          fullWidth
          onClick={handleOpen}
          sx={props.calculateButtonStyles === undefined ? styles.cardButton : props.calculateButtonStyles}
          aria-label='Calculate Ineligibles button' disabled={props.disabled}
        >
          Calculate Ineligibles
        </Button>
      </Paper>
      <Modal open={open} onClose={handleClose}>
        <Box>
        {isUltimateParent ? 
          <CalcIneligibleDialog
            handleClose={handleClose}
            title='Calculate Ineligible'
            selectBBPeriod
            forCalculate
            calculateUPC={initiateCalculationForUPC}
            hideFileImportLoader={props.hideFileImportLoader}
          />
        :
          <CalcIneligibleDialog
            handleClose={handleClose}
            title='Calculate Ineligible'
            selectARCollateral
            selectBBPeriod
            forCalculate
            calculateBB={tryCalculate}
            hideFileImportLoader={props.hideFileImportLoader}
          />
        } 
        </Box>
      </Modal>
      <SelectedChildClientMessageModal
        isOpen={childModalOpen}
        parentName={parent?.borrowerName ?? ''}
        handleNavigateToClientsPage={() => {
          handleClose();
          navigate('/clients');
          props.hideFileImportLoader?.();
        }}
        handleClose={() => {
          setSelectedClient(parent ?? null);
          handleClose();
          setOpen(true);
        }}
        handleButtonClose={() => {
          handleClose();
        }}
        headingTitle='Calculate Ineligibles'
        firstMessage='Calculation of ineligibles are only applicable to the Parent Client level.'
        secondMessage={`Click proceed to switch the selected Client's Parent if you want to calculate ineligibles.`}
      />
    </>
  );
};

export default CalculateIneligiblesButton;
