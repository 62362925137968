import colors from "../../../../../../../../utility/colors";

const styles = {
  tableRow: {
    '&&:nth-of-type(even)': {
      backgroundColor: '#F7F7F7',
    },
    ':hover': {
      cursor: 'pointer',
    },
  },
  tableCell: {
    border: '0',
    height: '2rem',
  },
  leftAlignedText: {
    textAlign: 'start',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bodyContainer: {
    paddingX: '1rem',
    paddingTop: '1rem', 
    boxShadow: `0px 3px 6px #00000029`,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  noteContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  fontNote: {
    fontSize: '0.875rem',
    marginBottom: '0.5rem'
  },
  sheetNameDropdown: {
    '& .MuiOutlinedInput-root': {
      fontSize: '0.875rem',
    },
    width: '18rem',
  },
  sheetNameDropdownList: {
    fontSize: '0.875rem',
    width: '100%',
  },
  comboBoxStyle: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '0.8rem',
  },
  gridContainer: {
    maxWidth: '1232px',
  },
  valuesTableContainer: {
    maxHeight: '17.6rem',
    maxWidth: '1232px',
  },
  nextAndCancelButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    mt: '10px',
    width: '100%',
  },
  cancelButton: {
    height: '31px',
    mr: 1,
    textTransform: 'capitalize',
    width: '82px',
  },
  nextButton: {
    height: '31px',
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      backgroundColor: '#99BFED',
      color: '#FFFFFF',
    }
  },
  emptyContainer: {
    padding: '1rem',
    borderRadius: '3px',
    border: '1px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verbiage: {
    fontSize: '14px',
    textAlign: 'center',
  },
  excludeCheckBox: {
    
  },
  checkBoxCell: {
    padding: 0,
    width: '2rem',
  },
  excludeTitle: {
    fontSize: '14px',
    color: colors.PRIMARY,
    fontWeight: 'bold',
    marginTop: '0.5rem'
  },
  tablePaginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  note: {
    color: '#154A8A',
  },
}
export default styles;